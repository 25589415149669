var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"h-recipe-filter"},[_c('div',{staticClass:"searchFilter"},[_c('SearchFilterDropDown',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('h6',{class:{
            filterEnabledDropDownHeader: _vm.filters.nutritionValues,
          }},[_vm._v(" Nutrition values ")])]},proxy:true},{key:"content",fn:function(){return _vm._l((_vm.RANGE_FILTER_MAPPING),function(rangeFilter,index){return _c('div',{key:rangeFilter.prop,staticClass:"rangeFilter"},[_c('label',{class:{
              'filterEnabled text-bold':
                _vm.filters.nutritionValues &&
                _vm.filters.nutritionValues[rangeFilter.prop],
            }},[_vm._v(" "+_vm._s(rangeFilter.title)+": ")]),_c('div',{staticClass:"px-2"},[_c('input',{ref:"rangeFilter",refInFor:true,attrs:{"data-prop":rangeFilter.prop,"type":"text"}})]),(index !== _vm.RANGE_FILTER_MAPPING.length - 1)?_c('hr',{staticClass:"rangeFilter-hr"}):_vm._e()])})},proxy:true}])})],1),_vm._l((_vm.optionFilters),function(optionFilter){return _c('div',{key:optionFilter.prop,staticClass:"searchFilter"},[_c('SearchFilterDropDown',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('h6',{class:{
            filterEnabledDropDownHeader: _vm.filters[optionFilter.prop],
          }},[_vm._v(" "+_vm._s(optionFilter.title)+" ")])]},proxy:true},{key:"content",fn:function(){return [_c('SerachFilterOptions',{attrs:{"options":optionFilter.options},on:{"input":function($event){return _vm.filterOptionChanged(optionFilter.prop, $event)}},model:{value:(_vm.filters[optionFilter.prop]),callback:function ($$v) {_vm.$set(_vm.filters, optionFilter.prop, $$v)},expression:"filters[optionFilter.prop]"}})]},proxy:true}],null,true)})],1)})],2)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div class="h-drawer-max79">
    <Drawer :direction="'right'" :exist="true" ref="modalDialog">
      <div class="h-drawer-card">
        <div class="h-drawer-card-header h-align-items-center h-space-between h-padding-bottom-l">
          <h5 class="h-h3 h-primary_shade_1">All appointments notes</h5>
          <div>
            <button
              type="button"
              class="close"
              aria-hidden="true"
              @click="close"
              :disabled="loading"
            >
              <img src="../../../public/assets/images/Collapse-drawer.svg" alt="" />
            </button>
          </div>
        </div>
        <div class="h-drawer-card-body">
          <div :class="{ 'd-none': loading }">
            <div v-for="item in meetingsInfo" :key="item.meetingDate">
              <div class="h-d-flex">
                <h4 class="h-w40 h-h4 h-primary_shade_1 h-margin-bottom-s">
                  Date: {{ toLocalDate(item.meetingDate) }}
                </h4>
                <h4 class="h-h4 h-primary_shade_1 h-margin-bottom-s">
                  Time: {{ $dateUtils.toLocalTime(item.meetingDate) }}
                </h4>
              </div>
              <div
                v-if="item.meetingNotes"
                class="h-appointment_note h-primary_shade_1 h-margin-bottom-xm"
                v-html="item.meetingNotes"
              ></div>
              <h4 v-if="!item.meetingNotes" class="h-h4 h-secondary_shade_1 h-margin-bottom-xm">
                No Note content
              </h4>
              <hr class="h-hr h-padding-top-xm h-margin-bottom-xm" />
            </div>
            <div class="row"></div>
          </div>
          <Loader v-if="loading" />
        </div>
        <div class="h-drawer-card-footer">
          <button type="button" class="h-btn h-btn-dangerous" @click="close" :disabled="loading">
            Close
          </button>
        </div>
      </div>
    </Drawer>
  </div>
</template>

<script>
import * as cheerio from 'cheerio';
import Loader from '@/components/Loader';

export default {
  data() {
    return {
      loading: false,
      errors: {},
      meetingsInfo: [],
    };
  },
  methods: {
    extract(data, sectionStartText, sectionEndText) {
      const $ = cheerio.load(data);
      // Retrieve all note span text
      let result = '';
      let getOtherLine = false;
      $('p').each(function note(i, e) {
        const paragraphText = $(this).text();
        if (paragraphText.includes(sectionStartText) || getOtherLine) {
          if (!paragraphText.includes(sectionEndText)) {
            result += paragraphText;
            getOtherLine = true;
          } else {
            getOtherLine = false;
          }
        }
      });
      return result;
    },
    show(meetings = null) {
      this.loading = false;
      // It was requested that only the 3 newest appointmets were included (Nov 18th, 2021)
      // 29Mar22 - Removing the slice to allow every meeting notes to be retrieved and displayed.
      this.meetingsInfo = meetings.sort((a, b) => {
        const c = new Date(a.meetingDate);
        const d = new Date(b.meetingDate);
        return d - c;
      });
      this.errors = {};
      this.errors.client = null;

      this.$refs.modalDialog.open();
      this.$refs.modalDialog.active = true;
      this.loading = false;
    },
    close(force) {
      if (!force && this.loading) return;
      this.$refs.modalDialog.close();
      this.$refs.modalDialog.active = false;
    },
    toLocalDate(date) {
      const options = { year: 'numeric', month: 'short', day: 'numeric' };
      return new Date(date).toLocaleDateString('en-US', options);
    },
  },

  components: {
    Loader,
  },
  props: {
    client: {
      type: Object,
    },
  },
};
</script>

<template>
  <tfoot>
    <tr>
      <th colspan="1000" class="text-center h-ingredient">Add ingredient</th>
    </tr>
    <tr>
      <td style="border-left: 0.5rem solid var(--secondary_shade_1) !important">
        <InputField
          type="number"
          :min="0"
          v-model="qty"
          :error="errors.qty"
          @input="
            () => {
              errors.qty = null;
            }
          "
        />
      </td>
      <td>
        <InputField
          type="select"
          :disabled="!usdaIngredient"
          v-model="unit"
          :choices="ingredientUnitTypes"
          :error="errors.unit"
          @input="
            () => {
              errors.unit = null;
            }
          "
        />
      </td>
      <td>
        <div class="col-12 input-row allow-drop-down-wrap">
          <select
            id="new-ingredient-name"
            class="h-input"
            :class="{ 'is-invalid': errors.usdaIngredient }"
            placeholder="Type to search..."
            data-noresults-text="No ingredients found."
            autocomplete="off"
          ></select>
          <div class="invalid-feedback" v-if="errors.usdaIngredient">
            {{ errors.usdaIngredient }}
          </div>
        </div>
      </td>
      <td class="text-right actions">
        <a href="javascript:void(0)" @click="addIngredient">
          <i class="healenticons-plus cursor-pointer h-accent" title="Add"></i>
        </a>
      </td>
    </tr>
  </tfoot>
</template>


<script>
import InputField from '@/components/InputField';

export default {
  data() {
    return {
      qty: null,
      unit: null,
      errors: {},

      searchResults: [],
      usdaIngredient: null,
    };
  },

  computed: {
    ingredientUnitTypes() {
      if (!this.usdaIngredient) {
        return [[null, '--- Ingredient not selected ---']];
      }
      const result = Object.keys(this.usdaIngredient.units_and_grams);
      result.unshift([null, '--- Select ---']);
      return result;
    },
  },

  methods: {
    addIngredient() {
      // validating
      if (this.qty <= 0) {
        this.errors.qty = 'Must be > 0.';
      } else {
        console.log('this.qty', this.qty);
      }
      if (!this.unit) {
        this.errors.unit = 'This field required.';
      }
      if (!this.usdaIngredient) {
        this.errors.usdaIngredient = 'This field required.';
        if (this.errors.usdaIngredient) {
          $('#new-ingredient-name').addClass('is-invalid');
        }
      }
      const isInvalid = !!Object.keys(this.errors).find((key) => {
        console.log(key, this.errors[key]);
        return this.errors[key];
      });
      if (isInvalid) {
        this.$forceUpdate();
        return;
      }

      // adding
      const ingredient = {
        base: this.usdaIngredient.name,
        ndb: this.usdaIngredient.ndb,
        qty: this.qty,
        qty_g: this.qty * this.usdaIngredient.units_and_grams[this.unit],
        servings: 1,
        unit: this.unit,
        usda: this.usdaIngredient,
      };

      this.reset();
      this.$emit('addIngredient', ingredient);
    },

    reset() {
      this.qty = null;
      this.unit = null;
      this.usdaIngredient = null;
      $('#new-ingredient-name').removeClass('is-invalid');
      $('#new-ingredient-name').val('');
      this.errors = {};
    },
  },

  mounted() {
    const that = this;
    $('#new-ingredient-name').autoComplete({
      minLength: 2,
      events: {
        search(qry, callback, origJQElement) {
          that.$api.searchIngredient(qry).then((result) => {
            that.searchResults = result.usda_ingredients;
            callback(
              result.usda_ingredients.map((usdaIngredient) => {
                return {
                  value: usdaIngredient.ndb,
                  text: usdaIngredient.name,
                };
              }),
            );
          });
        },
      },
    });

    $('#new-ingredient-name').on('autocomplete.select', (evt, item) => {
      $('#new-ingredient-name').removeClass('is-invalid');
      this.usdaIngredient = that.searchResults.find((ingredient) => {
        return ingredient.ndb === item.value;
      });
      if (this.usdaIngredient.units_and_grams[this.unit] === undefined) {
        this.unit = null;
      }
      this.errors.usdaIngredient = null;
    });
  },

  components: {
    InputField,
  },
};
</script>

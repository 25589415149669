<template>
  <div class="h-drawer-max500">
    <Drawer :direction="'right'" :exist="true" ref="modalDialog">
      <div class="h-drawer-card">
        <div class="h-drawer-card-header">
          <div class="h-main-drawer-card-header h-margin-bottom-l">
            <h3 class="h-h3 h-primary_shade_1">
              <slot name="modal-title">{{ isEditMode ? "Edit Form" : "New Form" }}</slot>
            </h3>
            <button
              type="button"
              class="close"
              aria-hidden="true"
              @click="close"
              :disabled="loading"
            >
              <img src="/assets/images/Collapse-drawer.svg" alt="" />
            </button>
          </div>
        </div>
        <div class="h-drawer-card-body">
          <div v-if="!loading">
            <div>
              <label class="h-label">Title</label>
              <InputField
                id="name"
                type="text"
                v-model="title"
                :class="{ 'error-class': formSubmitted && !title }"
              />
            </div>
            <!-- JSON Code Text Area -->
            <div class="h-margin-top-l h-form-questionsJSON">
              <label class="h-label">Form Structure:</label>
              <InputField
                id="json-textarea"
                type="textarea"
                v-model="questionsJSON"
                class="textarea-editor"
              />
            </div>
            <div class="h-margin-top-l h-margin-bottom-xl widgetListWrapper">
              <InputField
                type="toggle"
                id="formStatus"
                :label="formStatusLabel"
                class="h-fix-checkbox"
                v-model="status"
              />
            </div>
          </div>

          <Loader v-else />
        </div>

        <div class="h-drawer-card-footer">
          <button type="button" class="h-btn h-btn-accent" @click="confirm" :disabled="loading">
            {{ isEditMode ? "Update" : "Save" }}
          </button>

          <button
            type="button"
            class="h-btn h-btn-dangerous h-margin-left-s"
            @click="close"
            :disabled="loading"
          >
            Cancel
          </button>
        </div>
      </div>
    </Drawer>
  </div>
</template>

<script>
import Loader from '@/components/Loader';
import InputField from '@/components/InputField';

export default {
  props: {
    editFormData: {
      type: Object,
      default: () => null,
    },
  },
  data() {
    return {
      loading: false,
      error: '',
      title: '',
      questionsJSON: '',
      status: true,
      isEditMode: false,
      formSubmitted: false,
    };
  },
  watch: {
    editFormData(newVal) {
      if (newVal) {
        this.title = newVal.title;
        this.status = newVal.status === 'Active';
        this.questionsJSON = newVal.questionsJSON
          ? JSON.stringify(JSON.parse(newVal.questionsJSON), null, 2)
          : '';
        this.isEditMode = true;
      } else {
        this.resetForm();
        this.isEditMode = false;
      }
    },
  },
  computed: {
    formStatusLabel() {
      return `Form status (${this.status ? 'active' : 'inactive'})`;
    },
  },

  methods: {
    resetForm() {
      this.title = '';
      this.status = false;
      this.questionsJSON = '';
    },
    show() {
      this.loading = true;
      this.formSubmitted = false;
      this.$refs.modalDialog.open();
      this.loading = false;
    },

    close(force) {
      if (!force && this.loading) return;
      this.$refs.modalDialog.close();
    },

    confirm() {
      this.loading = true;
      this.formSubmitted = true;
      if (!this.title.trim()) {
        $.notify('Please enter a title', {
          position: 'top center',
          className: 'error',
        });
        this.loading = false;
        return;
      }
      const formData = {
        title: this.title,
        status: this.status ? 'Active' : 'Inactive',
        questionsJSON: this.questionsJSON,
      };
      if (this.isEditMode) {
        formData.created = this.editFormData.created;
        this.$emit('update-form', this.editFormData.id, formData);
      } else {
        const currentDate = new Date().toISOString().slice(0, 10);
        formData.created = currentDate;
        this.$emit('new-form-added', formData);
      }

      this.resetForm();
      this.close(true);
      this.loading = false;
    },
  },

  components: {
    Loader,
    InputField,
  },
};
</script>

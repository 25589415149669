<template>
  <div>
    <div
      class="h-d-flex h-align-item-center h-cursor-pointer"
      @click="$refs.healthStatusListEditDialog.show()"
      data-tooltip="Health status items"
    >
      <i
        class="fas fa-cog fa-2x cursor-pointer h-secondary_shade_1"
        data-tooltip="Health status items"
      ></i>
      <h4 class="h-h4 h-secondary_shade_1 h-margin-left-s" data-tooltip="Health status items">
        Edit health items
      </h4>
    </div>

    <HealthStatusListEditDialog
      ref="healthStatusListEditDialog"
      :items="getWellnessItems()"
      :holderId="holder.id"
      :cpMode="cpMode"
      @itemsUpdated="itemsUpdated"
    />
  </div>
</template>

<style scoped lang="stylus">
.checklist {
  .status {
    padding-left: 26px;
  }

  .min-width {
    width: 1%;
  }
}

.edit-button {
  min-width: 10em;
}
</style>

<script>
import moment from 'moment';
import HealthStatusListEditDialog from './HealthStatusListEditDialog';
import settings from '@/settings.js';

export default {
  props: {
    holder: {
      type: Object,
    },
    cpMode: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      moment,
    };
  },

  methods: {
    getRecordValue(item, record) {
      if (!record) return '-';

      if (item.data_type === 'bool') {
        return record.record.value ? 'Yes' : 'No';
      }

      if (!item.unit || item.unit === 'yes' || item.unit === 'no') {
        return record.record.value;
      }
      return `${record.record.value} ${item.unit}`;
    },

    getWellnessItems(isActive) {
      return this.holder.checklist.filter((item) => {
        if (this.cpMode) return true;
        return isActive === undefined || item.is_active === isActive;
      });
    },

    itemTargetToStr(item) {
      if (settings.twoMetricValues.indexOf(item.title) !== -1) {
        if (
          item.target.hasOwnProperty('value_1') &&
          item.target.value_1 !== null &&
          item.target.hasOwnProperty('value_2') &&
          item.target.value_2 !== null
        ) {
          return `${item.target.value_1}/${item.target.value_2} ${item.unit}`;
        }
        return null;
      }
      if (item.target.hasOwnProperty('value_1') && item.target.value_1 !== null) {
        return `${item.target.value_1} ${item.unit}`;
      }
      return null;
    },

    itemsUpdated(items) {
      this.holder.checklist = items;
      this.$emit('itemsUpdated', items);
      this.$forceUpdate();
    },

    getTrendOptions(item) {
      const result = {
        chart: {
          width: 150,
        },
        series: [
          {
            data: item.trend.map((entry) => {
              return entry.avg;
            }),
            name: item.title,
            color: '#7777FF',
            connectNulls: true,
          },
        ],
        xAxis: {
          categories: item.trend.map((entry) => {
            return moment(entry.date).format('MMM Do');
          }),
        },
      };
      return result;
    },
  },

  components: {
    HealthStatusListEditDialog,
  },
};
</script>

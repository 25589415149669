<template>
  <div class="h-factor-progress">
    <div class="h-d-flex h-space-between h-margin-bottom-xs">
      <span
        class="h-h6 h-primary_shade_1"
        :data-tooltip="
          `${
            notEnoughData
              ? 'No enough data'
              : isNull
              ? 'No data found in the specified range'
              : 'Min value of the range'
          }`
        "
        >{{ notEnoughData ? '--' : formatNumber(min) }}</span
      >
      <span
        class="h-h6 h-primary_shade_1"
        :data-tooltip="
          `${
            notEnoughData
              ? 'No enough data'
              : isNull
              ? 'No data found in the specified range'
              : 'Max value of the range'
          }`
        "
        >{{ notEnoughData ? '--' : formatNumber(max) }}</span
      >
    </div>

    <div class="h-progress-container">
      <div class="h-progress-bar"></div>
      <div
        v-if="!isNull && !notEnoughData"
        class="h-progress-point"
        :style="{
          'margin-left': pointPosition,
          background: color
        }"
        data-tooltip="Last value provided by the patient"
      ></div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: [Number, String],
      required: true,
    },
    color: {
      type: String,
    },
    min: {
      type: Number,
    },
    max: {
      type: Number,
    },
    isNull: {
      type: Boolean,
      default: false,
    },
    notEnoughData: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    formatNumber(num) {
      if (num === null) {
        return '--';
      }
      if (Math.floor(num) === num) {
        return num.toString();
      }
      return num.toFixed(1);
    },
  },
  computed: {
    percentage() {
      if (this.isNull || this.notEnoughData) {
        return 0;
      }
      return ((this.value - this.min) / (this.max - this.min)) * 100;
    },
    pointPosition() {
      if (this.isNull || this.notEnoughData) {
        return '0%';
      }
      return this.percentage > 0 ? `${this.percentage}%` : '0%';
    },
  },
};
</script>

<template>
  <div class="h-drawer-max79">
    <SlottedMessageInDrawer
      ref="slottedMessage"
      title="Communication note"
      :closeDisabled="loading"
      modalSize="xl"
    >
      <template v-slot:modal-body>
        <div v-if="!loading && note">
          <div>
            <label class="h-label" :for="`note.providerName:${note.id}`">
              Date
            </label>
            <InputField :id="`note.date:${note.date}`" type="date" v-model="note.date" />
          </div>

          <div class="h-margin-top-l">
            <label class="h-label" :for="`note.providerName:${note.id}`">
              Physician Name
            </label>
            <InputField
              :id="`note.providerName:${note.id}`"
              type="text"
              v-model="note.providerName"
            />
          </div>

          <div class="h-margin-top-l">
            <label class="h-label" :for="`note.note:${note.id}`">
              Content:
            </label>
            <InputField
              :id="`note.note:${note.id}`"
              type="textarea"
              v-model="note.note"
              :rows="4"
            />
          </div>

          <div class="h-margin-top-l">
            <InputField
              :label="`Notify ${client.surgeon ? client.surgeon.name : 'Surgeon'}`"
              :id="`note.surgeonNotified:${note.id}`"
              type="checkbox"
              v-model="note.surgeonNotified"
            />
          </div>
        </div>

        <Loader v-else />
      </template>

      <template v-slot:modal-footer>
        <button
          type="button"
          class="h-btn h-btn-accent"
          @click="save"
          :disabled="loading || !note || !note.date"
        >
          {{ !modeEdit ? 'Create' : 'Update' }}
        </button>

        <button
          type="button"
          class="h-btn h-btn-dangerous h-margin-left-s"
          @click="close"
          :disabled="loading"
        >
          Cancel
        </button>
      </template>
    </SlottedMessageInDrawer>
  </div>
</template>

<script>
import Loader from '@/components/Loader';
import SlottedMessageInDrawer from '@/components/dialogs/SlottedMessageInDrawer';
import InputField from '@/components/InputField';

export default {
  data() {
    return {
      loading: false,
      note: null,
      modeEdit: false,
    };
  },

  methods: {
    show(note) {
      if (note) {
        this.note = JSON.parse(JSON.stringify(note));
        this.modeEdit = true;
      } else {
        this.note = {
          id: this.$uuid.v4(),
          date: null,
          providerName: '',
          note: '',
          surgeonNotified: false,
        };
        this.modeEdit = false;
      }
      this.loading = false;
      this.$refs.slottedMessage.show();
    },

    async save() {
      this.loading = true;
      const response = await this.$api.saveClientCommunicationNote({
        client_id: this.client.id,
        communication_entry: this.note,
      });
      this.$emit('update', response.communication_entries);
      this.close(true);
    },

    close(force) {
      this.$refs.slottedMessage.close(force);
    },
  },

  components: {
    Loader,
    SlottedMessageInDrawer,
    InputField,
  },

  props: {
    client: {
      type: Object,
      required: true,
    },
  },
};
</script>

<template>
  <div class="h-drawer-max79">
    <Drawer ref="Settings" :direction="'right'" :exist="true">
      <div class="h-drawer-card">
        <div class="h-drawer-card-header">
          <div class="h-main-drawer-card-header h-margin-bottom-l">
            <h3 class="h-h3 h-primary_shade_1">Settings</h3>
            <button
              type="button"
              class="close"
              aria-hidden="true"
              @click="close"
              :disabled="loading"
            >
              <img src="/assets/images/Collapse-drawer.svg" alt="" />
            </button>
          </div>
        </div>
        <div class="h-drawer-card-body h-padding-0">
          <div v-if="!loading">
            <router-link
              :to="{ name: 'Profile' }"
              class="settings-item"
              :class="{ active: isRouteActive('Profile') }"
            >
              <i class="healenticons-icon-metro-profile"></i>
              My Profile
            </router-link>

            <div class="dropdown-divider h-hide-on-tool"></div>

            <router-link :to="{ name: 'Logout' }" class="settings-item">
              <i class="healenticons-logout-1"></i>
              Logout
            </router-link>
          </div>

          <Loader v-if="loading" />
        </div>

        <div class="h-drawer-card-footer">
          <button type="button" class="h-btn h-btn-dangerous " @click="close" :disabled="loading">
            Close
          </button>
        </div>
      </div>
    </Drawer>
  </div>
</template>

<script>
import Loader from '@/components/Loader';

export default {
  data() {
    return {
      loading: false,
    };
  },
  watch: {
    $route() {
      this.close();
    },
  },
  methods: {
    show() {
      this.loading = false;
      this.$refs.Settings.open();
    },

    close(force) {
      this.$refs.Settings.close();
    },
    isRouteActive(routeName) {
      return this.$route.name === routeName;
    },
  },

  components: {
    Loader,
  },
};
</script>

<template>
  <div>
    <div class="h-padding-left-l h-padding-right-l h-space-between h-align-items-center w-auto">
      <h3 v-if="!clientId" class="h-h3 h-primary_shade_1" style="width: -webkit-fill-available">
        Therapy PT
      </h3>
      <div class="w-100">
        <div v-if="!readOnly && (isTemplateMode || clientId)" class="text-right">
          <button
            type="button"
            class="h-btn h-btn-accent"
            @click="$refs.therapyPtDialog.show(entries)"
          >
            <div class="h-align-items-center">
              <i class=" h-secondary_shade_5 healenticons-edit h-padding-right-s"></i>Edit
            </div>
          </button>
        </div>

        <div v-if="!readOnly && !isTemplateMode && !clientId">
          <button
            type="button"
            class="h-btn h-btn-accent"
            :disabled="!doctorHasChanges"
            @click="saveDoctorChanges"
          >
            Save changes
          </button>
        </div>
      </div>
    </div>
    <div v-if="!loading" class="h1-table">
      <table class="table dataTable">
        <thead class="thead-light">
          <tr>
            <th v-if="!readOnly && !isTemplateMode && !clientId" class="text-left">
              On/Off
            </th>
            <th class="text-left w-1 text-nowrap">
              <div class="h-d-flex h-align-items-center">
                <span>SDA</span>
                <i
                  class="
                  h-font20 h-line-height-1
                  h-bold h-accent healenticons-with-alerts
                  h-cursor-pointer h-padding-left-xs"
                  data-tooltip="Start day after"
                ></i>
              </div>
            </th>
            <th class="text-left w-1 text-nowrap">
              <div class="h-d-flex h-align-items-center">
                <span>EDA</span>
                <i
                  class="
                  h-font20 h-line-height-1
                  h-bold h-accent healenticons-with-alerts
                  h-cursor-pointer h-padding-left-xs"
                  data-tooltip="End day after"
                ></i>
              </div>
            </th>
            <th>Title</th>
            <th class="text-right">Sets</th>
            <th class="text-right">Hold</th>
            <th class="text-right">Reps.</th>
            <th class="text-left">Side</th>
            <th class="text-right">Times per day</th>
            <th class="text-right">Every X days</th>
            <th v-if="clientId" class="text-center">Trend</th>
          </tr>
        </thead>

        <tbody v-if="entries && entries.length !== 0">
          <tr v-for="entry of entries" :key="entry.id">
            <td v-if="!readOnly && !isTemplateMode && !clientId" class="text-left">
              <InputField
                :id="`careProtocolItem-doctorTherapyPt::${entry.id}`"
                type="checkbox"
                checkboxSwitchType="success"
                v-model="entry.enabled"
                @change="
                  () => {
                    doctorHasChanges = true
                  }
                "
              />
            </td>
            <td class="text-right">{{ entry.start_days_after }}</td>
            <td class="text-right">{{ entry.end_days_after }}</td>
            <td>{{ entry.therapy_pt.title }}</td>
            <td class="text-right">{{ entry.sets }}</td>
            <td class="text-right">{{ entry.hold }}</td>
            <td class="text-right">{{ entry.repetition }}</td>
            <td class="text-left">{{ entry.side }}</td>
            <td class="text-right">{{ entry.times_per_day }}</td>
            <td class="text-right">{{ entry.every_x_days }}</td>
            <td v-if="clientId" class="w-1 text-center">
              <SparklineChart v-if="entry.trend.length" :options="getTrendOptions(entry)" />
              <span v-else>-</span>
            </td>
          </tr>
        </tbody>

        <tbody v-else>
          <tr>
            <td colspan="1000" class="text-center">No entries</td>
          </tr>
        </tbody>
      </table>
    </div>
    <Loader v-else />

    <TherapyPtEntriesDialog
      v-if="!loading"
      ref="therapyPtDialog"
      :clientId="clientId"
      :careProtocolId="careProtocolId"
      :careProtocolMode="careProtocolMode"
      @entriesSaved="entriesSaved"
    />

    <GlobalWait
      ref="globalWait"
      title="Saving therapy PT list"
      message="Saving therapy PT list..."
    />
  </div>
</template>

<style lang="stylus" scoped>
.edit-button {
  min-width: 10em;
}
</style>

<script>
import SparklineChart from '@/components/charts/SparklineChart';
import Loader from '@/components/Loader';
import TherapyPtEntriesDialog from './TherapyPtEntriesDialog';

import GlobalWait from '@/components/GlobalWait';
import InputField from '@/components/InputField';

export default {
  props: [
    'clientId',
    'careProtocolId',
    'initialEntries',
    'careProtocolMode',
    'readOnly',
    'isTemplateMode',
    'doctor',
  ],

  data() {
    return {
      loading: true,
      entries: [],
      doctorHasChanges: false,
    };
  },

  methods: {
    async entriesSaved(entriesToSave, entriesReturned) {
      try {
        this.loading = true;
        const response = await this.$api.getTherapyPtEntries({
          clientId: this.clientId,
          careProtocolId: this.careProtocolId,
          userId: this.doctor ? this.doctor.id : null,
        });

        this.entries = JSON.parse(JSON.stringify(response.therapy_pt_entries || entriesReturned));
      } catch (error) {
        console.error('Error fetching updated entries:', error);
      } finally {
        this.loading = false;
      }
    },

    async saveDoctorChanges() {
      this.$refs.globalWait.start();
      const disabledItems = this.entries
        .filter((item) => {
          return item.enabled === false;
        })
        .map((item) => {
          return item.id;
        });
      const response = await this.$api.setTherapyPtEntries(
        {
          clientId: this.clientId,
          careProtocolId: this.careProtocolId,
          userId: this.doctor ? this.doctor.id : null,
        },
        {
          disabledItems,
        },
      );
      this.entries = response.therapy_pt_entries;
      this.doctorHasChanges = false;
      this.$refs.globalWait.stop();
    },

    getTrendOptions(item) {
      return {
        chart: {
          width: 150,
        },
        series: [
          {
            data: item.trend.map((entry) => {
              return entry.sum;
            }),
            name: item.therapy_pt.title,
            color: '#7777FF',
            // type: "column"
          },
        ],
        xAxis: {
          categories: item.trend.map((entry) => {
            return entry.date;
          }),
        },
      };
    },
  },

  async mounted() {
    if (!this.initialEntries) {
      this.entries = (
        await this.$api.getTherapyPtEntries({
          clientId: this.clientId,
          careProtocolId: this.careProtocolId,
          userId: this.doctor ? this.doctor.id : null,
        })
      ).therapy_pt_entries;
    } else {
      this.entries = JSON.parse(JSON.stringify(this.initialEntries));
    }
    this.loading = false;
  },

  components: {
    Loader,
    GlobalWait,
    InputField,
    TherapyPtEntriesDialog,
    SparklineChart,
  },
};
</script>

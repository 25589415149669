<template>
  <div class="h-drawer-max500">
    <Drawer :direction="'right'" :exist="true" ref="modalDialog">
      <div class="h-drawer-card">
        <div class="h-drawer-card-header">
          <div class="h-main-drawer-card-header h-margin-bottom-l">
            <h3 class="h-h3 h-primary_shade_1">
              <slot name="modal-title">Clone Surgery Checklist</slot>
            </h3>
            <button
              type="button"
              class="close"
              aria-hidden="true"
              @click="close"
              :disabled="loading"
            >
              <!-- <font-awesome-icon
                    :icon="['fas', 'sign-out-alt']"
                    style="height: 1.5vw; width: 1.5vw; color: #c3c4cb"
                  /> -->
              <img src="/assets/images/Collapse-drawer.svg" alt="" />
            </button>
          </div>
        </div>
        <div class="h-drawer-card-body h-padding-left-0 h-padding-right-0">
          <div :class="{ 'd-none': loading }">
            <div
              class="
                h-space-between
                h-align-items-center
                h-margin-bottom-l
                h-padding-left-l
                h-padding-right-l
              "
              ref="searchInputsWrapper"
            >
              <div class="h-row">
                <div class="w-33">
                  <label class="h-label"> Protocol </label>
                  <select
                    class="h-input"
                    v-model="selectedProtocol"
                  >
                    <option
                      v-if="protocols.length !== 0"
                      value=""
                    >
                      ---Select protocol---
                    </option>
                    <option
                      v-if="protocols.length === 0"
                      value=""
                    >
                      ---No protocols---
                    </option>
                    <option
                      v-for="protocol of protocols"
                      :key="protocol.id"
                      :value="protocol.id"
                    >
                      {{ getFullProtocolName(protocol) }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="h-margin-top-l">
                <input
                  ref="textSearch"
                  placeholder="Search"
                  type="search"
                  class="h-input"
                  @input="search"
                />
              </div>
            </div>
            <div
              class="
                d-inline
                h-primary_shade_1
                h-padding-left-l h-padding-right-l
              "
            >
              Total selected:
              <strong>{{ selectedEntries.length }}</strong> (<a
                href="javascript:void(0)"
                @click="clearSelection"
                >unselect all</a
              >)
            </div>
            <div class="h1-table">
              <table
                ref="theTable"
                class="table dataTable h-table-wrap"
                style="
                  max-height: calc(100vh - 215rem);
                  min-height: calc(100vh - 215rem);
                "
              >
                <thead class="thead-light">
                  <tr>
                    <th width="5%" class="text-center">
                      Select<br />
                      <a href="javascript:void(0)" @click="selectAll">All</a> /
                      <a href="javascript:void(0)" @click="selectNothing"
                        >Nothing</a
                      >
                    </th>
                    <th width="15%">Name</th>
                    <th width="5%">Type</th>
                    <th width="5%">Hospital</th>
                    <th width="5%">Care protocol</th>
                    <th width="1%">Active</th>
                  </tr>
                </thead>
              </table>
            </div>
          </div>

          <Loader v-if="loading" />
        </div>

        <div class="h-drawer-card-footer">
          <button
            type="button"
            class="h-btn h-btn-accent"
            @click="cloneItems"
            :disabled="loading"
          >
            Clone
          </button>

          <button
            type="button"
            class="h-btn h-btn-dangerous h-margin-left-s"
            @click="close"
            :disabled="loading"
          >
            Cancel
          </button>
        </div>
      </div>
    </Drawer>
  </div>
  <!-- /.modal -->
</template>


<script>
import Loader from '@/components/Loader';

let dialogId = 0;

export default {
  props: ['protocolId'],

  data() {
    dialogId += 1;
    return {
      loading: false,
      selectedEntries: [],
      protocols: [],
      selectedProtocol: '',
      dataTable: null,
      dialogId,
    };
  },

  watch: {
    selectedProtocol(value) {
      this.dataTable.column(4).search(value).draw();
    },
  },

  beforeDestroy() {
    if (this.dataTable) {
      this.dataTable.destroy(true);
      this.dataTable = null;
    }
  },

  methods: {
    getFullProtocolName(protocol) {
      if (protocol.hospital?.name) {
        return `${protocol.name} [${protocol.hospital.name}]`;
      }
      return protocol.name;
    },

    search(e) {
      this.dataTable.search(e.target.value).draw();
    },

    selectAll() {
      $(this.$refs.theTable)
        .find('input[type=checkbox]')
        .each((i, entry) => {
          const entryId = parseInt($(entry).attr('data-entryId'), 10);
          const index = this.selectedEntries.indexOf(entryId);
          if (index === -1) {
            this.selectedEntries.push(entryId);
          }
        });
      $(this.$refs.theTable).find('input[type=checkbox]').prop('checked', true);
    },

    selectNothing() {
      $(this.$refs.theTable)
        .find('input[type=checkbox]')
        .each((i, entry) => {
          const entryId = parseInt($(entry).attr('data-entryId'), 10);
          const index = this.selectedEntries.indexOf(entryId);
          if (index !== -1) {
            this.selectedEntries.splice(index, 1);
          }
          console.log();
        });
      $(this.$refs.theTable)
        .find('input[type=checkbox]')
        .prop('checked', false);
    },

    show() {
      this.$refs.modalDialog.open();
      this.$refs.modalDialog.active = true;
      this.name = '';
      this.loading = false;
      this.selectedEntries = [];
      this.dataTable.search('').draw();
    },

    close(force) {
      if (!force && this.loading) return;
      this.$refs.modalDialog.close();
    },

    async cloneItems() {
      this.loading = true;
      const response = await this.$api.cloneCareProtocolItems({
        protocolId: this.protocolId,
        itemIds: this.selectedEntries,
      });
      this.$emit('itemsUpdated', response.care_protocol_items);
      this.close(true);
    },

    clearSelection() {
      this.selectedEntries = [];
      $(this.$refs.theTable)
        .find('input[type=checkbox]')
        .prop('checked', false);
    },
  },

  async mounted() {
    try {
      const resp = await this.$api.getCareProtocols();
      this.protocols = resp.care_protocols;
    } catch (e) {
      this.protocols = [];
    }

    const that = this;
    this.dataTable = $(this.$refs.theTable).DataTable({
      processing: true,
      serverSide: true,
      responsive: true,

      order: [[1, 'asc']],
      ajax: (data, callback, tableSettings) => {
        this.$api.getCareProtocolItems(data).then((response) => {
          tableSettings.json = response;
          callback(response);
        });
      },
      dom: "<'h-row h-space-between h-align-item-center'<'h-show-bt h-margin-left-l header-title'><'h-d-flex h-table-bt'>><'h-row'<'h-col-desktop-12 h-col-mobile-12'tr>><'h-row h-space-between h-align-item-center h-padding-bottom-l' lip>",
      columns: [
        {
          name: 'id',
          searchable: false,
          orderable: false,
          render: (data) => {
            return `
              <div class="text-center">
                <div class="h-checkbox">
                  <input
                    type="checkbox"
                    class="custom-control-input"
                    id="cloneCareProtocolItemDialog::${dialogId}::entryId::${data}"
                    ${
  that.selectedEntries.indexOf(data) !== -1 ? 'checked' : ''
}
                    data-entryId="${data}"
                  >
                  <label
                    class="custom-control-label cursor-pointer"
                    for="cloneCareProtocolItemDialog::${dialogId}::entryId::${data}"></label>
                </div>
              </div>
            `;
          },
        },
        {
          name: 'name',
          searchable: true,
          render: data => (data ? that.$strUtils.escapeHtml(data) : null),
        },
        {
          name: 'post_surgery',
          searchable: false,
          render: (data) => {
            if (data) {
              return '<span class="text-blue">Post-surgery</span>';
            }
            return 'Pre-surgery';
          },
        },
        {
          name: 'care_protocol__hospital__name',
          searchable: false,
          orderable: false,
          render: data => (data ? that.$strUtils.escapeHtml(data) : null),
        },
        {
          name: 'care_protocol__name',
          searchable: true,
          orderable: false,
          render: data => (data ? that.$strUtils.escapeHtml(data) : null),
        },
        {
          name: 'active',
          searchable: false,
          render: (data) => {
            if (data) {
              return '<span class="text-success">Yes</span>';
            }
            return '<span class="text-danger">No</span>';
          },
        },
      ],
    });

    const searchInputs = $(this.$refs.searchInputsWrapper);
    $(this.$refs.modalDialog.el).find('.search-panel').append(searchInputs);
    searchInputs.removeClass('d-none');

    $(this.$refs.theTable).on('click', 'input[type=checkbox]', (e) => {
      const entryId = parseInt($(e.target).attr('data-entryId'), 10);
      const index = that.selectedEntries.indexOf(entryId);
      if (index === -1) {
        that.selectedEntries.push(entryId);
      } else {
        that.selectedEntries.splice(index, 1);
      }
    });
  },

  components: {
    Loader,
  },
};
</script>

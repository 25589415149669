<template>
  <div class="h-padding-l" v-dragscroll.y="false">
    <div style="padding-bottom: 35rem">
      <div class="h-margin-bottom-l">
        <div class="h-bg-w h-padding-top-l h-padding-right-l h-padding-left-l">
          <div>
            <QuestionsTable @questions="setQuestions" />
          </div>
        </div>
      </div>

      <div class="h-margin-bottom-l">
        <div class="h-bg-w h-padding-top-l h-padding-right-l h-padding-left-l">
          <div>
            <MicroprotocolsTable :questions="questions" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import settings from '@/settings.js';
import QuestionsTable from './HealthRiskAssessment/QuestionsTable';
import MicroprotocolsTable from './MicroprotocolsTable/MicroprotocolsTable';

export default {
  data() {
    return {
      questions: [],
    };
  },

  methods: {
    setQuestions(questions) {
      this.questions = questions;
    },
  },

  async mounted() {
    if (
      !settings.hasRole('system_administrator')
      && !settings.hasRole('nurse_administrator')
    ) {
      this.$router
        .push({
          name: 'index',
        })
        .catch((err) => {});
      return;
    }

    window.execAfterInitMenuInterface(() => {
      window.initActiveMenu('left-navigation-link>microprotocols');
    });
  },

  components: {
    QuestionsTable,
    MicroprotocolsTable,
  },
};
</script>

<template>
  <div>
    <div
      class="
        h-add-patient
        h-padding-top-l
        h-mobile-padding-left-l
        h-mobile-padding-right-l
      "
    >
      <div class="h-col-desktop-8 h-col-screen-m h-col-mobile-12 h-margin-auto">
        <div class="">
          <div class="">
            <div :class="{ 'd-none': loading }">
              <div
                v-if="formWasSaved === false"
                class="alert alert-danger border-0 alert-dismissible mb-4"
                role="alert"
              >
                Failed to save data. Please view the form to see errors.
              </div>
              <div
                v-if="formWasSaved === true"
                class="alert alert-success border-0 alert-dismissible mb-4"
                role="alert"
              >
                User was updated.
              </div>

              <StaffMemberEditor
                ref="staffMemberEditor"
                :careProtocols="careProtocols"
                :loading="loading"
                :errors="errors"
                :isProfilePage="false"
              />

              <div class="h-text-center h-margin-bottom-l">
                <button type="button" class="h-btn h-btn-accent" @click="save">
                  Save
                </button>

                <button type="button" class="h-btn h-btn-dangerous h-margin-left-m" @click="cancel">
                  Cancel
                </button>
              </div>
            </div>

            <Loader v-if="loading" />
          </div>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
</template>

<script>
import Loader from '@/components/Loader';
import strUtils from '@/utils/str_utils';
import StaffMemberEditor from './components/StaffMemberEditor';
import settings from '@/settings.js';

export default {
  data() {
    return {
      loading: true,
      member: null,
      memberSet: false,
      roleDisplayNames: [],
      careProtocols: [],
      photoUrl: '',
      removePhoto: false,
      strUtils,
      genderChoices: [
        [null, ''],
        ['Male', 'Male'],
        ['Female', 'Female'],
      ],
      errors: {},
      formWasSaved: null,
    };
  },

  methods: {
    async save() {
      this.errors = {};

      this.loading = true;
      this.formWasSaved = null;

      const payload = this.$refs.staffMemberEditor.getPayload();
      console.log('payload', payload);
      const phonesWithLocalIds = this.$refs.staffMemberEditor.member.phones;
      const response = await this.$api.updateStaffMember(payload);
      console.log('response', response);

      if (response.status === 'ok') {
        this.formWasSaved = true;
        this.setMember(response.member);
      } else {
        this.formWasSaved = false;
        this.errors = response.errors;
        if (this.errors && this.errors.phones) {
          const adjustedPhoneErrors = {};
          Object.getOwnPropertyNames(this.errors.phones).forEach((key) => {
            const index = parseInt(key, 10);
            if (isNaN(key)) return;
            const phoneID = phonesWithLocalIds[index].id
              ? phonesWithLocalIds[index].id
              : phonesWithLocalIds[index].__localId;
            adjustedPhoneErrors[phoneID] = this.errors.phones[key];
          });
          this.errors.phones = adjustedPhoneErrors;
        }
      }
      this.loading = false;
    },

    cancel() {
      this.$router
        .push({
          name: 'AllMembers',
        })
        .catch((err) => {});
    },

    setMember(loadedMember) {
      if (loadedMember.id === settings.currentSession.user.id) {
        if ((loadedMember.email ?? '').indexOf('support+' === -1)) {
          settings.currentSession.user.show_no_phone = loadedMember.phones.length === 0;
        }
      }
      this.member = loadedMember;
      this.$refs.staffMemberEditor.setMember(this.member);
    },
  },

  updated() {
    this.$refs.staffMemberEditor.setMember(this.member);
  },

  async mounted() {
    window.execAfterInitMenuInterface(() => {
      window.initActiveMenu('left-navigation-link>settings>staff>all-members', false);
    });
    this.hospitals = await this.$api.getHospitalsList();
    this.careProtocols = (await this.$api.getCareProtocols()).care_protocols;
    this.setMember(await this.$api.getStaffMember(this.$route.params.doctorId));
    this.loading = false;
  },

  components: {
    Loader,
    StaffMemberEditor,
  },
};
</script>

<template>
  <div>
    <div v-if="loading">
      <Loader />
    </div>
    <div>
      <div v-if="client.diagnoses.length" class="h1-table h-overflow-in">
        <table class="table dataTable h-noborder-input h-ICD-table">
          <thead class="thead-light">
            <tr>
              <th width="2%" class="h-text-center">#</th>
              <th width="70%">ICD Code</th>
              <th width="10%">Type</th>
              <th width="5%" class="h-text-center"></th>
            </tr>
          </thead>

          <DiagnoseRow
            v-for="(clientDiagnose, index) of client.diagnoses"
            :key="clientDiagnose.uuid"
            :index="index"
            :categories="categories"
            :clientDiagnose="clientDiagnose"
            @remove="removeRow"
            @categoryChange="category => (clientDiagnose.category = category)"
            @diagnoseChange="diagnose => (clientDiagnose.diagnose = diagnose)"
            @diagnoseTypeChange="diagnoseType => { clientDiagnose.diagnose_type = diagnoseType }"
            @editCategory="fetchCategories" />
        </table>
      </div>
      <div v-else class="h-text-center h-h5 h-secondary_shade_1 h-bold">
        No entries.
      </div>
      <div class="h-text-center h-margin-top-l">
        <button
          type="button"
          class="h-btn h-btn-accent h-btn-with-icon"
          @click="newRow">
          <i
            style="line-height: 1; font-size: 10rem;"
            class="h-secondary_shade_5 healenticons-plus h-padding-right-s"></i>
          Add diagnose
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import settings from '@/settings.js';
import Loader from '@/components/Loader';
import DiagnoseRow from './DiagnoseRow';

export default {
  data() {
    return {
      categories: null,
      loading: false,
      categoriesFetched: false,
    };
  },

  methods: {
    async fetchCategories() {
      try {
        const response = await this.$api.get(`${settings.BACKEND_URL}/api/v-nurse/diagnose-categories`);
        this.categories = response.data;
        this.categoriesFetched = true;
      } catch (error) {
        console.error('Error fetching categories:', error);
        this.categories = []; // fallback to an empty array
        this.categoriesFetched = false;
      }
    },

    async newRow() {
      if (!this.categoriesFetched) {
        this.loading = true;
        await this.fetchCategories();
        this.loading = false;
      }
      if (!this.client.diagnoses) {
        this.$set(this.client, 'diagnoses', []);
      }
      this.client.diagnoses.push({
        uuid: this.$uuid.v4(),
        category: 'New',
        diagnose: null,
        diagnose_type: 'primary',
      });
      this.$forceUpdate();
    },

    removeRow(row) {
      const indexToBeDeleted = this.client.diagnoses.findIndex(
        d => d.diagnose && d.diagnose.id === row.diagnose?.id,
      );

      if (indexToBeDeleted > -1) {
        this.client.diagnoses.splice(indexToBeDeleted, 1);
      } else {
        const uuidIndex = this.client.diagnoses.findIndex(
          d => d.uuid === row.uuid,
        );
        if (uuidIndex > -1) {
          this.client.diagnoses.splice(uuidIndex, 1);
        }
      }
      this.$forceUpdate();
    },
  },

  components: {
    Loader,
    DiagnoseRow,
  },

  props: {
    client: {
      type: Object,
      required: true,
    },
  },
};
</script>

export const PT_CATEGORY_LIST = [
  { value: undefined, title: '--- select ---' },
  { value: 'Neck', title: 'Neck' },
  { value: 'Shoulder', title: 'Shoulder' },
  { value: 'Hip/Pelvic', title: 'Hip/Pelvic' },
  { value: 'Fibromyalgia', title: 'Fibromyalgia' },
  { value: 'Rheumatoid Arthritis', title: 'Rheumatoid Arthritis' },
  { value: 'Hand/Wrist', title: 'Hand/Wrist' },
  { value: 'Elbow/Arm', title: 'Elbow/Arm' },
  { value: 'Upper/Mid Back', title: 'Upper/Mid Back' },
  { value: 'General Back', title: 'General Back' },
  { value: 'Foot/Ankle', title: 'Foot/Ankle' },
  { value: 'Migraines', title: 'Migraines' },
  { value: 'Low Back/Buttocks', title: 'Low Back/Buttocks' },
  { value: 'Knee/Leg', title: 'Knee/Leg' },
  { value: 'Lungs', title: 'Lungs' },
  { value: 'preop', title: 'preop' },
  { value: 'spo2', title: 'spo2' },
  { value: 'stroke', title: 'stroke' },
];

export const PATIENT_TABS = [
  { title: 'Monitoring', value: 'tabMonitoring' },
  { title: 'Planning', value: 'tabPlanning' },
  { title: 'Messages', value: 'tabMessages' },
  { title: 'Activity Logs <span class="h-dangerous">(0)</span>', value: 'tabPatientLog' },
  { title: 'Patient info', value: 'tabAdditionalInfo' },
];

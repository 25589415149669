<template>
  <div class="h-patient-height">
    <!-- Page-Title -->
    <div class="h-add-patient h-mobile-padding-left-l h-mobile-padding-right-l">
      <div class="h-col-mobile-12 h-margin-auto">
        <div>
          <div :class="{ 'd-none': loading }">
            <div
              v-if="formWasSaved === false"
              class="alert alert-danger border-0 alert-dismissible mb-4"
              role="alert">
              Failed to save data. Please view the form to see errors.
            </div>
            <div
              v-if="formWasSaved === true"
              class="alert alert-success border-0 alert-dismissible mb-4"
              role="alert">
              Patient data was saved.
            </div>

            <form class="h-form-btn">
              <div id="EditPatient">
                <div class="step-content">
                  <div class="step-tab-panel" data-step="step1">
                    <section>
                      <div v-if="!client.id" class="h-row">
                        <div
                          class="h-input-w49
                          h-desktop-margin-right-2
                          h-col-mobile-12
                          h-margin-bottom-l">
                          <label class="h-label"> Hospital: </label>
                          <HospitalSelector v-model="hospitalId" />
                        </div>
                      </div>

                      <div class="h-row">
                        <div
                          class="h-input-w49
                          h-desktop-margin-right-2
                          h-col-mobile-12
                          h-margin-bottom-l">
                          <label class="h-label"> Name: </label>
                          <InputField
                            id="input_name"
                            type="text"
                            v-model="client.name"
                            :error="errors.name"
                            :class="{ 'has-error': errors.name }"
                            @input="() => {
            errors.name = null;
          }
            " />
                        </div>
                        <div
                          class="h-input-w49
                          h-col-mobile-12
                          h-margin-bottom-l">
                          <label class="h-label"> Date of birth:</label>
                          <InputField
                            id="DateOfBirth"
                            name="birthday"
                            type="date"
                            v-model="client.date_of_birth"
                            :error="errors.date_of_birth"
                            :class="{ 'has-error': errors.date_of_birth }"
                            @input="() => {
            errors.date_of_birth = null;
          }
            " />
                          <div
                            class="invalid-feedback notDateOfBirth"
                            style="display: none">
                            The entered date is not accepted
                          </div>
                        </div>

                        <div
                          class="h-input-w49
                          h-desktop-margin-right-2
                          h-col-mobile-12
                          h-margin-bottom-l">
                          <label class="h-label"> Phone (+12345678900):</label>
                          <InputField
                            type="text"
                            v-model="client.phone"
                            :error="errors.phone"
                            :class="{ 'has-error': errors.phone }"
                            @input="() => {
            errors.phone = null;
          }
            "
                            @keyup="formatPhone" />
                          <br />
                          <InputField
                            label="Accepts SMS"
                            type="checkbox"
                            id="send_sms"
                            v-model="client.send_sms"
                            :error="errors.send_sms"
                            @input="errors.send_sms = null" />
                        </div>
                        <div
                          class="h-input-w49
                          h-col-mobile-12
                          h-margin-bottom-l">
                          <label class="h-label"> Gender:</label>
                          <InputField
                            type="select"
                            :choices="genderChoices"
                            v-model="client.gender"
                            :error="errors.gender"
                            @input="() => {
            errors.gender = null;
          }
            " />
                        </div>
                        <div
                          class="h-input-w49
                          h-desktop-margin-right-2
                          h-col-mobile-12
                          h-margin-bottom-l">
                          <label class="h-label"> Height (inches): </label>
                          <InputField
                            type="number"
                            v-model="client.height"
                            :error="errors.height"
                            :class="{ 'has-error': errors.height }"
                            @input="() => {
            errors.height = null;
          }
            " />
                        </div>

                        <div
                          class="h-input-w49
                          h-col-mobile-12
                          h-margin-bottom-l">
                          <label class="h-label"> Weight (lbs):</label>
                          <InputField
                            type="number"
                            v-model="client.weight"
                            :error="errors.weight"
                            :class="{ 'has-error': errors.height }"
                            @input="() => {
            errors.weight = null;
          }
            " />
                        </div>

                        <div
                          class="h-input-w49
                          h-desktop-margin-right-2
                          h-col-mobile-12
                          h-margin-bottom-l">
                          <label class="h-label"> Facility Provider:</label>
                          <InputField
                            type="select"
                            :choices="surgeonOptions"
                            v-model="surgeonId"
                            :error="errors.surgeon_id"
                            @input="() => {
            errors.surgeon_id = null;
          }
            " />
                        </div>
                        <div
                          class="h-input-w49
                          h-col-mobile-12
                          h-margin-bottom-l">
                          <label class="h-label"> Billing Provider:</label>
                          <InputField
                            type="select"
                            :choices="surgeonOptions"
                            v-model="billingPhysicianId"
                            :error="errors.billing_physician_id"
                            @input="() => {
            errors.billing_physician_id = null;
          }
            " />
                        </div>

                        <div
                          class="h-input-w49
                          h-col-mobile-12
                          h-desktop-margin-right-2
                          h-margin-bottom-l">
                          <label class="h-label"> Plan Start Date:</label>
                          <InputField
                            type="date"
                            id="PlanStartDate"
                            v-model="client.plan_start_date"
                            :error="errors.plan_start_date"
                            @input="() => {
            errors.plan_start_date = null;
          }
            " />
                          <div
                            class="invalid-feedback notPlanEndDate"
                            style="display: none">
                            The entered date is not accepted
                          </div>
                        </div>

                        <div
                          class="h-input-w49
                          h-col-mobile-12
                          h-margin-bottom-l">
                          <label class="h-label"> Plan End Date:</label>
                          <InputField
                            type="date"
                            id="PlanEndDate"
                            v-model="client.plan_end_date"
                            :error="errors.plan_end_date"
                            @input="() => {
            errors.plan_end_date = null;
          }
            " />

                          <div
                            class="invalid-feedback notPlanEndDate"
                            style="display: none">
                            The entered date is not accepted
                          </div>
                        </div>

                        <div
                          class="h-input-w49
                          h-col-mobile-12
                          h-desktop-margin-right-2
                          h-margin-bottom-l">
                          <label class="h-label"> Monitoring program:</label>
                          <InputField
                            type="select"
                            :choices="[
            { value: null, title: '--- select ---' },
            'CCM',
            'PCM',
            'RPM',
            'RTM',
            'Wellness'
          ]"
                            v-model="client.monitoring_program"
                            :error="errors.monitoring_program"
                            @input="errors.monitoring_program = null" />
                        </div>
                        <div
                          v-if="shouldAssignedNurseDropDownBeShown"
                          class="h-input-w49
                          h-col-mobile-12
                          h-margin-bottom-l">
                          <label class="h-label">
                            Assigned care provider:</label>
                          <InputField
                            type="select"
                            :choices="nurseOptions"
                            v-model="assignedNurseId"
                            :error="errors.assigned_nurse_id"
                            @input="() => {
            errors.assigned_nurse_id = null;
          }
            " />
                        </div>
                        <div
                          class="h-input-w49
                          h-col-mobile-12
                          h-margin-bottom-l">
                          <label class="h-label">
                            Care protocol
                            <span v-if="client.id">
                              (if changed, patient health status will also be
                              updated) </span>:
                          </label>
                          <InputField
                            type="select"
                            :choices="careProtocols"
                            v-model="client.care_protocol.id"
                            :error="errors.care_protocol_id"
                            @input="() => {
            errors.care_protocol_id = null;
          }
            " />
                        </div>
                        <div class="h-col-desktop-12
                        h-col-mobile-12">
                          <label class="h-label"> Pain categories:</label>
                          <PainCategories v-model="client.pain_categories" />
                        </div>
                      </div>
                    </section>
                  </div>
                  <div class="step-tab-panel top-90" data-step="step2">
                    <section>
                      <div class="h-row">
                        <div
                          class="h-col-desktop-12
                          h-col-mobile-12
                          h-margin-bottom-l">
                          <h3
                            class="h-h3 h-primary_shade_1 h-text-left h-margin-bottom-m">
                            Insurance Info: (optional)
                          </h3>
                          <InsurenceInfo :client="client" />
                        </div>
                        <div
                          class="h-col-desktop-12
                          h-col-mobile-12
                          h-margin-bottom-l
                          h-margin-top-l">
                          <h3
                            class="h-h3
                            h-primary_shade_1
                            h-text-left
                            h-margin-bottom-m">
                            Diagnosis: (optional)
                          </h3>
                          <Diagnosis :client="client" v-if="client.diagnoses" />

                          <h3
                            class="h-h3
                            h-primary_shade_1
                            h-text-left
                            h-margin-bottom-m
                            h-margin-top-l">
                            Medication use: (optional)
                          </h3>
                          <MedicationUse
                            v-if="client.medication_use_template"
                            :client="client"
                            :errors="errors.medication_use_template || {}" />
                        </div>
                      </div>
                    </section>
                  </div>
                  <div class="step-tab-panel top-90" data-step="step3">
                    <section class="h-padding-bottom-xl">
                      <div class="h-row">
                        <h3
                          class="h-h3
                          h-primary_shade_1
                          h-text-left
                          h-margin-bottom-l">
                          Extra fields: (optional)
                        </h3>
                      </div>
                      <div class="h-row">
                        <div
                          class="h-col-desktop-12
                          h-col-mobile-12
                          h-margin-bottom-l">
                          <form v-if="clientSet" method="post">
                            <div class="photo-wrapper">
                              <input
                                type="file"
                                id="input-file-now-custom-1"
                                class="dropify"
                                :data-default-file="this.photoUrl ||
            '/assets/images/users/patient-pro.png'
            "
                                ref="photo" />
                            </div>
                            <p
                              v-if="errors.photo"
                              class="h-h5 h-bold
                              h-dangerous
                              h-margin-top-s">
                              Photo: {{ errors.photo }}
                            </p>
                          </form>
                        </div>
                      </div>
                      <div class="h-row h-space-between">
                        <div class="h-margin-bottom-l">
                          <InputField
                            label="SCS"
                            type="checkbox"
                            id="scs"
                            v-model="client.scs"
                            :error="errors.scs"
                            @input="errors.scs = null" />
                        </div>
                        <div class="h-margin-bottom-l">
                          <InputField
                            label="Is traced"
                            type="checkbox"
                            id="is_traced"
                            v-model="client.is_traced"
                            :error="errors.is_traced"
                            @input="errors.is_traced = null" />
                        </div>
                        <div class="h-margin-bottom-l">
                          <InputField
                            label="Is test patient"
                            type="checkbox"
                            id="is_test"
                            v-model="client.is_test"
                            :error="errors.is_test"
                            @input="errors.is_test = null" />
                        </div>
                      </div>
                      <div class="h-row">
                        <div
                          v-if="client.scs"
                          class="h-col-desktop-12
                          h-col-mobile-12
                          h-margin-bottom-l">
                          <div class="h-row">
                            <div
                              class="h-input-w49
                              h-desktop-margin-right-2
                              h-col-mobile-12
                              h-margin-bottom-l">
                              <label class="h-label">
                                SCS date: (optional)</label>
                              <InputField
                                type="date"
                                id="scs_date"
                                v-model="client.scs_date"
                                :error="errors.scs_date"
                                @input="errors.scs_date = null" />
                            </div>
                            <div
                              class="h-input-w49
                              h-col-mobile-12
                              h-margin-bottom-l">
                              <label class="h-label"> SCS satisfaction:</label>
                              <InputField
                                type="select"
                                :choices="[
            { value: null, title: '--- select ---' },
            { value: 1, title: '1' },
            { value: 2, title: '2' },
            { value: 3, title: '3' },
            { value: 4, title: '4' },
            { value: 5, title: '5' },
          ]"
                                v-model="client.scs_satisfaction"
                                :error="errors.scs_satisfaction"
                                @input="errors.scs_satisfaction = null" />
                            </div>
                          </div>
                        </div>

                        <div
                          class="h-input-w49
                          h-desktop-margin-right-2
                          h-col-mobile-12
                          h-margin-bottom-l">
                          <label class="h-label">Address: (optional)</label>

                          <InputField
                            type="text"
                            v-model="client.address"
                            :error="errors.address"
                            @input="() => {
            errors.address = null;
          }
            " />
                        </div>
                        <div
                          class="h-input-w49
                          h-col-mobile-12
                          h-margin-bottom-l">
                          <label class="h-label"> E-Mail: (optional)</label>
                          <InputField
                            type="text"
                            v-model="client.email"
                            :error="errors.email"
                            @input="() => {
            errors.email = null;
          }
            "
                            @keypress="validateEmail" />
                          <div v-if="isInvalidEmail" class="invalid-feedback">
                            The entered email is not accepted
                          </div>
                        </div>
                        <div
                          class="h-input-w49
                          h-desktop-margin-right-2
                          h-col-mobile-12
                          h-margin-bottom-l">
                          <label class="h-label">
                            Mobile Type (Android, iPhone): (optional)
                          </label>
                          <InputField
                            type="text"
                            v-model="client.mobile_type"
                            :error="errors.mobile_type"
                            @input="() => {
            errors.mobile_type = null;
          }
            " />
                        </div>

                        <div
                          class="h-input-w49
                          h-col-mobile-12
                          h-margin-bottom-l">
                          <label class="h-label">
                            Primary Device Type: (optional)</label>
                          <InputField
                            type="text"
                            v-model="client.primary_device"
                            :error="errors.primary_device"
                            @input="() => {
            errors.primary_device = null;
          }
            " />
                        </div>

                        <div
                          class="h-input-w49
                          h-desktop-margin-right-2
                          h-col-mobile-12
                          h-margin-bottom-l">
                          <label class="h-label">
                            Surgery date: (optional)</label>
                          <InputField
                            type="date"
                            id="SurgeryDate"
                            v-model="client.surgery_date"
                            :error="errors.surgery_date"
                            @input="() => {
            errors.surgery_date = null;
          }
            " />
                          <div
                            class="invalid-feedback notSurgeryDate"
                            style="display: none">
                            The entered date is not accepted
                          </div>
                        </div>

                        <div
                          class="h-input-w49
                          h-col-mobile-12
                          h-margin-bottom-l"
                          v-if="newPatient === false">
                          <label class="h-label"> Account status: </label>
                          <InputField
                            type="select"
                            :choices="accountStatusChoices"
                            v-model="accountStatus"
                            :key="accountStatus"
                            @input="() => {
            errors.accountStatus = null;
          }
            " />
                        </div>

                        <div
                          class="h-input-w49
                          h-desktop-margin-right-2
                          h-col-mobile-12
                          h-margin-bottom-l">
                          <label class="h-label">
                            Surgery time: (optional)</label>
                          <InputField
                            type="time"
                            id="SurgeryTime"
                            v-model="client.surgery_time"
                            :error="errors.surgery_time"
                            @input="() => {
            errors.surgery_time = null;
          }
            " />
                        </div>

                        <div
                          class="h-input-w49
                          h-col-mobile-12
                          h-margin-bottom-l">
                          <label class="h-label">
                            Discharge date: (optional:)</label>
                          <InputField
                            type="date"
                            id="DischargeDate"
                            v-model="client.discharge_date"
                            :error="errors.discharge_date"
                            @input="() => {
            errors.discharge_date = null;
          }
            " />
                          <div
                            class="invalid-feedback notSurgeryDate"
                            style="display: none">
                            The entered date is not accepted
                          </div>
                        </div>

                        <div
                          class="h-input-w49
                          h-desktop-margin-right-2
                          h-col-mobile-12
                          h-margin-bottom-l">
                          <label class="h-label"> Status: </label>
                          <InputField
                            type="select"
                            v-model="if_inactive_reason_id"
                            :choices="inactivePatientReasons" />
                        </div>

                        <div
                          class="h-input-w49
                          h-desktop-margin-right-2
                          h-col-mobile-12
                          h-margin-bottom-l">
                          <label class="h-label">
                            Outstanding Issues or Concerns: (optional)
                          </label>
                          <InputField
                            type="textarea"
                            v-model="client.outstanding_issues_or_concerns"
                            :error="errors.outstanding_issues_or_concerns"
                            @input="() => {
            errors.outstanding_issues_or_concerns = null;
          }
            " />
                        </div>

                        <div
                          class="h-input-w49
                          h-col-mobile-12
                          h-margin-bottom-l">
                          <label class="h-label">
                            Wellness and Requirements for Service: (optional)
                          </label>
                          <InputField
                            type="textarea"
                            v-model="client.clearance_needs"
                            :error="errors.clearance_needs"
                            @input="() => {
            errors.clearance_needs = null;
          }
            " />
                        </div>

                        <div
                          class="h-input-w49
                          h-desktop-margin-right-2
                          h-col-mobile-12
                          h-margin-bottom-l">
                          <label class="h-label">
                            Provider List: (optional)</label>
                          <InputField
                            type="textarea"
                            v-model="client.follow_up_issues"
                            :error="errors.follow_up_issues"
                            @input="() => {
            errors.follow_up_issues = null;
          }
            " />
                        </div>

                        <div
                          class="h-input-w49
                          h-col-mobile-12
                          h-margin-bottom-l">
                          <label class="h-label">
                            Where they stand: (optional)</label>
                          <InputField
                            type="textarea"
                            v-model="client.where_they_stand"
                            :error="errors.where_they_stand"
                            @input="() => {
            errors.where_they_stand = null;
          }
            " />
                        </div>

                        <div
                          class="h-input-w49
                          h-desktop-margin-right-2
                          h-col-mobile-12
                          h-margin-bottom-l">
                          <label class="h-label">
                            Repeat check in: (optional)</label>

                          <InputField
                            type="date"
                            id="repeatCheckInDate"
                            v-model="client.repeat_check_in_date"
                            :error="errors.repeat_check_in_date"
                            @input="() => {
            errors.repeat_check_in_date = null;
          }
            " />
                          <div
                            class="invalid-feedback notrepeatCheckInDate"
                            style="display: none">
                            The entered date is not accepted
                          </div>
                        </div>
                        <div
                          class="h-input-w49
                          h-col-mobile-12
                          h-margin-bottom-l">
                          <InputField
                            class="h-desktop-padding-top-l"
                            type="time"
                            v-model="client.repeat_check_in_time"
                            :error="errors.repeat_check_in_time"
                            @input="() => {
            errors.repeat_check_in_time = null;
          }
            " />
                        </div>

                        <div
                          class="h-input-w49
                          h-desktop-margin-right-2
                          h-col-mobile-12
                          h-margin-bottom-l">
                          <label class="h-label"> MRN number:</label>
                          <InputField
                            type="text"
                            v-model="client.mrn"
                            :error="errors.mrn"
                            @input="() => {
            errors.mrn = null;
          }
            " />
                        </div>
                        <div
                          class="h-input-w49
                          h-col-mobile-12
                          h-margin-bottom-l">
                          <label class="h-label"> Location: </label>
                          <InputField
                            type="select"
                            v-model="client.location_id"
                            :choices="locationChoices" />
                        </div>
                      </div>
                    </section>
                  </div>
                </div>
                <div class="step-footer h-step-fix-footer">
                  <div
                    class="h-col-desktop-8
                    h-col-screen-m
                    h-col-mobile-12
                    h-margin-auto
                    h-btn-container">
                    <div class="h-text-right">
                      <button
                        type="button"
                        class="h-btn h-btn-accent"
                        @click="save">
                        Save
                      </button>
                      <button
                        type="button"
                        class="h-btn h-btn-dangerous h-margin-left-s"
                        @click="cancel"
                        v-if="this.client.id">
                        Close
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <!-- <div class="h-row">
                <div class="h-col-desktop-12 h-col-mobile-12 h-margin-bottom-l">
                  <label class="h-label">
                    Physiotherapist: (optional)
                     <InputField
                      type="select"
                      :choices="physiotherapistOptions"
                      v-model="physiotherapistId"
                      :error="errors.physiotherapist_id"
                      @input="() => { errors.physiotherapist_id=null }"
                    />
                  </label>
                </div>
              </div> -->
            </form>
          </div>

          <div v-if="loading" class="card-body">
            <Loader />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
.has-error {
  border-color: red;
}
</style>

<script>
import moment from 'moment';
import settings from '@/settings.js';
import Loader from '@/components/Loader';
import InputField from '@/components/InputField';
import Diagnosis from './components/diagnosis/Diagnosis';
import InsurenceInfo from './components/InsurenceInfo';
import MedicationUse from './components/medicationUse/MedicationUse';
import HospitalSelector from '@/components/HospitalSelector';
import eventBus from '../../../event-bus';
import PainCategories from './components/PainCategories';

export default {
  name: 'EditPatient',
  props: {
    defaultClient: {
      type: Object,
      required: true,
    },
  },
  data() {
    const clientCopy = JSON.parse(JSON.stringify(this.defaultClient));
    if (!Array.isArray(clientCopy.pain_categories)) {
      clientCopy.pain_categories = [];
    }
    if (
      !clientCopy.care_protocol ||
      typeof clientCopy.care_protocol.id !== 'number'
    ) {
      clientCopy.care_protocol = { id: null };
    }
    return {
      client: clientCopy,
      hospitalId: null,
      locationChoices: [],
      billingPhysicianId: null,
      surgeonId: null,
      surgeonOptions: [],

      assignedNurseId: null,
      nurseOptions: [],

      physiotherapistId: null,
      physiotherapistOptions: [],
      newPatient: false,
      loading: true,
      savedEmail: null,
      errors: {},
      clientSet: false,
      photoUrl: '',
      removePhoto: false,
      genderChoices: [
        [null, ''],
        ['Male', 'Male'],
        ['Female', 'Female'],
      ],
      mobileTypeChoices: [
        [null, ''],
        ['Android', 'Android'],
        ['iOS', 'iOS'],
      ],
      primaryDeviceChoices: [
        [null, ''],
        ['Pulse Oximeter', 'Pulse Oximeter'],
        ['Weighing Scale', 'Weighing Scale'],
        ['Thermometer', 'Thermometer'],
        ['Spirometer', 'Spirometer'],
        ['Blood Pressure Cuff', 'Blood Pressure Cuff'],
        ['Glucometer', 'Glucometer'],
      ],
      accountStatusChoices: [
        ['Pending activation', 'Pending activation'],
        ['Active', 'Active'],
        ['Inactive', 'Inactive'],
      ],
      accountStatus: null,
      careProtocols: [],
      formWasSaved: null,
      moment,
      // showNurseSelector: !settings.hasRole("nurse", true), // turn it on for all except nurse
      showNurseSelector: true, // turn it on for all except nurse
      if_inactive_reason_id: 0,
      inactivePatientReasons: [[null, '']],
      isInvalidEmail: false,
    };
  },

  watch: {
    async $route(to, from) {
      await this.onRouteChange();
    },

    accountStatus() {
      if (this.accountStatus === 'Active') {
        this.client.pending_activation = false;
        this.client.is_archived = false;
        this.if_inactive_reason_id = null;
      }

      if (this.accountStatus === 'Inactive') {
        this.client.is_archived = true;
        this.client.pending_activation = false;
      }

      if (this.accountStatus === 'Pending activation') {
        this.client.pending_activation = true;
        this.client.is_archived = false;
        this.if_inactive_reason_id = null;
      }
    },

    hospitalId() {
      if (!this.loading) {
        this.onRouteChange(this.hospitalId);
      }
    },

    defaultClient() {
      this.setClient(this.defaultClient);
    },
  },

  computed: {
    localizedClient() {
      return this.client.trim();
    },
    isAdmin() {
      return settings.hasRole('system_administrator');
    },

    isAccountInactive() {
      return this.accountStatus === 'Inactive';
    },

    shouldAssignedNurseDropDownBeShown() {
      let result = false;
      const isCurrentUserNurse = settings.hasRole('nurse', true);
      const isCurrentUserNurseAdmin = settings.hasRole(
        'nurse_administrator',
        true,
      );

      if (isCurrentUserNurse) result = false;

      if (isCurrentUserNurseAdmin) result = true;

      return true;
    },
  },

  methods: {
    formatPhone() {
      // Remove all non-digit characters except the plus sign that is not the first character
      this.client.phone = this.client.phone.replace(/[^+\d]|(?<=\+.*)\+/g, '');
    },
    validateEmail() {
      const pattern = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
      this.errors.email = null;
      if (!this.client.email.match(pattern)) {
        this.errors.email = 'Invalid email format';
        this.isInvalidEmail = true;
      }
    },

    hasError(fieldName) {
      return !!this.errors[fieldName];
    },
    clearError(fieldName) {
      this.errors[fieldName] = null;
    },

    async save() {
      if (this.accountStatus === 'Inactive') {
        if (!this.if_inactive_reason_id || this.if_inactive_reason_id === 0) {
          $.notify(
            'Please select a reason why this patient is set to inactive',
            {
              position: 'top center',
              className: 'error',
            },
          );
          return;
        }
      }
      this.loading = true;
      this.errors = {};
      this.formWasSaved = null;

      const email = this.client.email ? this.client.email.trim() : '';
      if (!this.client.name) {
        const message = 'Please enter the required Fields';
        $.notify(message, {
          position: 'top center',
          className: 'error',
        });
        this.errors.name = message;
        this.loading = false;
        return;
      }

      const payload = {
        hospital_id: this.hospitalId,
        location_id: this.client.location_id
          ? parseInt(this.client.location_id, 10)
          : 0,
        id: this.client.id,
        name: this.client.name,
        email,
        phone: this.client.phone,
        send_sms: this.client.send_sms,
        date_of_birth: this.client.date_of_birth,
        gender: this.client.gender,
        height: this.client.height,
        weight: this.client.weight,
        surgeon_id: this.surgeonId,
        billing_physician_id: this.billingPhysicianId,
        assigned_nurse_id: this.assignedNurseId,
        physiotherapist_id: this.physiotherapistId,
        surgery_date: this.client.surgery_date,
        surgery_time: this.client.surgery_time,
        discharge_date: this.client.discharge_date,
        program: this.client.program,
        care_protocol_id: this.client.care_protocol.id,
        address: this.client.address,
        A1C: this.client.A1C,
        medical_record_number: this.client.medical_record_number,
        outstanding_issues_or_concerns:
          this.client.outstanding_issues_or_concerns,
        clearance_needs: this.client.clearance_needs,
        date_seen_for_optimization: this.client.date_seen_for_optimization,
        date_for_return_visit: this.client.date_for_return_visit,
        communication_entries: this.client.communication_entries,
        communication_messages_done: this.client.communication_messages_done,
        follow_up_issues: this.client.follow_up_issues,
        where_they_stand: this.client.where_they_stand,
        repeat_check_in_date: this.client.repeat_check_in_date,
        repeat_check_in_time: this.client.repeat_check_in_time,
        removePhoto: this.removePhoto,
        mrn: this.client.mrn,
        plan_start_date: this.client.plan_start_date,
        plan_end_date: this.client.plan_end_date,
        diagnoses: this.client.diagnoses,
        registration_id: this.client.registration_id,
        voip_registration_id: this.client.voip_registration_id,
        is_archived: this.client.is_archived,
        pending_activation: this.client.pending_activation,
        insurance_info: this.client.insurance_info,
        mobile_type: this.client.mobile_type,
        primary_device: this.client.primary_device,
        medication_use_template: this.client.medication_use_template,
        scs: this.client.scs,
        scs_date: this.client.scs_date,
        scs_satisfaction: this.client.scs_satisfaction,
        monitoring_program: this.client.monitoring_program,
        if_inactive_reason_id: this.if_inactive_reason_id,
        is_traced: this.client.is_traced || false,
        is_test: this.client.is_test || false,
        pain_categories: this.client.pain_categories,
      };
      if (this.$refs.photo.files.length !== 0) {
        payload.photo = this.$refs.photo.files[0];
      }
      const response = await this.$api.upsertClient(payload);
      console.log("response", response)
      if (response.status === 'ok') {
        this.$emit('updateSuccess', response.client);
        this.setClient(response.client);
        eventBus.$emit('CloseEditProfileInfoTab', {});
      } else {
        this.formWasSaved = false;
        this.errors = response.errors;
        this.loading = false;
      }
    },

    cancel() {
      eventBus.$emit('CloseEditProfileInfoTab', {});
    },

    setClient(loadedClient) {
      this.clientData = loadedClient;
      if (this.client.email) {
        if (this.client.email.indexOf('@nomail.healent.com') !== -1) {
          this.client.email = '';
        }
      }
      this.photoUrl = this.client.profile;
      this.billingPhysicianId = this.client.billing_physician?.id;
      this.surgeonId = this.client.surgeon?.id;
      this.assignedNurseId = this.client.assigned_nurse?.id;
      this.physiotherapistId = this.client.physiotherapist?.id;
      this.removePhoto = false;
      if (this.client.care_protocol) {
        delete this.client.care_protocol.name;
      } else {
        this.client.care_protocol = { id: null };
      }
      if (!this.client.diagnoses) {
        this.client.diagnoses = [];
      }
      if (!this.client.medication_use_template) {
        this.client.medication_use_template = [];
      }

      if (this.client.if_inactive_reason_id) {
        this.if_inactive_reason_id = this.client.if_inactive_reason_id;
      }
      this.is_traced = this.client.is_traced;
      this.is_test = this.client.is_test;

      if (!this.clientSet) {
        this.clientSet = true;
        this.$nextTick(() => {
          $('.dropify')
            .dropify()
            .on('dropify.afterClear', () => {
              this.removePhoto = true;
            });
        });
      }

      if (loadedClient.is_archived) {
        this.accountStatus = 'Inactive';
      } else {
        this.accountStatus = 'Active';
      }

      if (loadedClient.pending_activation) this.accountStatus = 'Pending activation';

      if (!loadedClient.id) {
        if (settings.hasRole('surgeon', true)) {
          this.surgeonId = settings.currentSession.user.id;
        }
        if (settings.hasRole('physiotherapist', true)) {
          this.physiotherapistId = settings.currentSession.user.id;
        }
      }

      loadedClient.pain_categories = loadedClient.pain_categories ?? [];
    },

    async onRouteChange(switchHospitalToId) {
      this.loading = true;

      if (this.client.id) {
        window.execAfterInitMenuInterface(() => {
          window.initActiveMenu(
            'left-navigation-link>hospital>patients>add-patient',
            false,
          );
        });
        this.setClient(this.client);
      } else {
        window.execAfterInitMenuInterface(() => {
          window.initActiveMenu(
            'left-navigation-link>hospital>patients>add-patient',
          );
        });
        this.setClient({});
        this.newPatient = true;
        // this.accountStatus === 'Pending activation';
      }

      let hospitalId = switchHospitalToId || this.client.hospital_id;
      const hospitals = await this.$api.getHospitalsList();
      if (!hospitalId) {
        hospitalId = hospitals[0].id;
      }
      this.hospitalId = hospitalId;
      const hospital =
        hospitals.find((h) => {
          return h.id === hospitalId;
        }) || {};

      this.locationChoices = [
        {
          value: 0,
          title: '--- no location ---',
        },
      ];
      hospital.locations.forEach((location) => {
        this.locationChoices.push({
          value: location.id,
          title: location.name,
        });
      });

      const responseCareProtocols = await this.$api.get(
        `${settings.BACKEND_URL}/api/v-nurse/care-protocol/hospital`,
        {
          hospitalID: this.hospitalId,
        },
      );
      const careProtocols = responseCareProtocols.care_protocols.map(
        (protocol) => {
          return [protocol.id, protocol.name];
        },
      );
      careProtocols.unshift([null, '']);
      this.careProtocols = careProtocols;

      const surgeonsData = await this.$api.searchSeargeon({
        hospital_id: this.hospitalId,
      });
      this.surgeonOptions = surgeonsData.map((surgeon) => {
        return {
          value: surgeon.id,
          title: surgeon.name,
        };
      });
      this.surgeonOptions.unshift([null, '--- Select Provider ---']);

      const nurseData = await this.$api.searchNurse({
        hospital_id: this.hospitalId,
      });
      this.nurseOptions = nurseData
        .filter((nurse) => {
          return (
            !nurse.email.includes('support+') &&
            (!this.client?.id ||
              nurse.id === this.client?.assigned_nurse?.id ||
              (nurse.allow_patients_hospitals ?? []).includes(
                this.client?.hospital_id,
              ))
          );
        })
        .map((nurse) => {
          return {
            value: nurse.id,
            title: nurse.name,
          };
        });
      this.nurseOptions.unshift([null, '--- Select Nurse ---']);

      // const physiotherapistData = await this.$api.searchPhysiotherapist({
      //   hospital_id: this.hospitalId,
      // });
      // this.physiotherapistOptions = physiotherapistData.map(
      //   (physiotherapist) => {
      //     return {
      //       value: physiotherapist.id,
      //       title: physiotherapist.name,
      //     };
      //   },
      // );
      // this.physiotherapistOptions.unshift([
      //   null,
      //   '--- Select physiotherapist ---',
      // ]);

      this.loading = false;
    },
  },

  beforeDestroy() {
    eventBus.$offOwner(this);
  },

  async mounted() {
    $('.dropify').dropify();

    const response = await this.$api.getClientInactiveReasons();
    response.inactive_reasons.forEach((entry) => {
      const row = [entry.id, entry.reason_description];
      this.inactivePatientReasons.push(row);
    });

    await this.onRouteChange();

    eventBus.$on(
      'OpenEditProfile',
      (event) => {
        // this.formWasSaved = null
        this.loading = false;
      },
      this,
    );
    $('#AddPatient').steps();
    $(document).ready(() => {
      $('#DateOfBirth').focusout(() => {
        const todayDate = new Date().getFullYear();
        const date = new Date($('#DateOfBirth').val());
        const year = date.getFullYear();
        if (year < todayDate - 200 || year > todayDate || year === null) {
          $('#DateOfBirth').addClass('is-invalid');
          $('#DateOfBirth').val('');
          $('#DateOfBirth').focus();
          $('.notDateOfBirth').css('display', 'block');
        } else {
          $('#DateOfBirth').removeClass('is-invalid');
          $('.notDateOfBirth').css('display', 'none');
        }
      });
      $('#PlanEndDate').focusout(() => {
        const todayDate = new Date().getFullYear();
        const date = new Date($('#PlanEndDate').val());
        const year = date.getFullYear();
        if (year < todayDate - 200 || year > todayDate + 100) {
          $('#PlanEndDate').addClass('is-invalid');
          $('#PlanEndDate').val('');
          $('#PlanEndDate').focus();
          $('.notPlanEndDate').css('display', 'block');
        } else {
          $('#PlanEndDate').removeClass('is-invalid');
          $('.notPlanEndDate').css('display', 'none');
        }
      });
      $('#SurgeryDate').focusout(() => {
        const todayDate = new Date().getFullYear();
        const date = new Date($('#SurgeryDate').val());
        const year = date.getFullYear();
        if (year < todayDate - 200 || year > todayDate + 100) {
          $('#SurgeryDate').addClass('is-invalid');
          $('#SurgeryDate').val('');
          $('#SurgeryDate').focus();
          $('.notSurgeryDate').css('display', 'block');
        } else {
          $('#SurgeryDate').removeClass('is-invalid');
          $('.notSurgeryDate').css('display', 'none');
        }
      });
      $('#repeatCheckInDate').focusout(() => {
        const todayDate = new Date().getFullYear();
        const date = new Date($('#repeatCheckInDate').val());
        const year = date.getFullYear();
        if (year < todayDate - 200 || year > todayDate + 100) {
          $('#repeatCheckInDate').addClass('is-invalid');
          $('#repeatCheckInDate').val('');
          $('#repeatCheckInDate').focus();
          $('.notrepeatCheckInDate').css('display', 'block');
        } else {
          $('#repeatCheckInDate').removeClass('is-invalid');
          $('.notrepeatCheckInDate').css('display', 'none');
        }
      });
    });
  },

  components: {
    Loader,
    InputField,
    Diagnosis,
    InsurenceInfo,
    MedicationUse,
    HospitalSelector,
    PainCategories,
  },
};
</script>

<template>
  <div class="h-drawer-max79">
    <Drawer :direction="'right'" :exist="true" ref="modalDialog">
      <div class="h-drawer-card">
        <div class="h-drawer-card-header">
          <div class="h-main-drawer-card-header h-margin-bottom-l">
            <h3 class="h-h3 h-primary_shade_1">Delete item</h3>
            <button
              type="button"
              class="close"
              aria-hidden="true"
              @click="close"
              :disabled="loading"
            >
              <!-- <font-awesome-icon
                    :icon="['fas', 'sign-out-alt']"
                    style="height: 1.5vw; width: 1.5vw; color: #c3c4cb"
                  /> -->
              <img src="/assets/images/Collapse-drawer.svg" alt="" />
            </button>
          </div>
        </div>
        <div class="h-drawer-card-body">
          <div v-if="!loading && item">
            <h4 class="h-h4 h-primary_shade_1 h-margin-bottom-l">
              Do you really want to
              <strong class="h-dangerous">delete</strong> this item?
            </h4>
            <h4 class="h-h4 h-primary_shade_1 h-margin-bottom-s">
              <strong>Title:</strong> {{ item.title }}
            </h4>
            <h4 class="h-h4 h-primary_shade_1">
              <strong>Subtitle:</strong> {{ item.sub_title }}
            </h4>
          </div>

          <Loader v-else />
        </div>

        <div class="h-drawer-card-footer">
          <button
            type="button"
            class="h-btn h-btn-accent"
            @click="deleteItem"
            :disabled="loading"
          >
            Delete
          </button>

          <button
            type="button"
            class="h-btn h-btn-dangerous h-margin-left-s"
            @click="close"
            :disabled="loading"
          >
            Cancel
          </button>
        </div>
      </div>
    </Drawer>
  </div>
  <!-- /.modal -->
</template>


<script>
import Loader from '@/components/Loader';

export default {
  data() {
    return {
      loading: false,
      item: null,
      collectionId: null,
    };
  },

  methods: {
    show(collectionId, item) {
      this.$refs.modalDialog.open();
      this.$refs.modalDialog.active = true;
      this.loading = false;
      this.collectionId = collectionId;
      this.item = item;
      $(this.$refs.modalDialog.el).modal({
        backdrop: 'static',
        keyboard: false,
      });
      $('.modal-backdrop').last().insertBefore(this.$refs.modalDialog.el);
    },

    close(force) {
      this.$refs.modalDialog.close();
      this.$refs.modalDialog.active = false;
    },

    async deleteItem() {
      this.loading = true;
      const collection = await this.$api.deleteEducationEntry(
        this.collectionId,
        this.item.id,
      );
      this.$emit('entryDeleted', collection);
      this.close(true);
    },
  },

  components: {
    Loader,
  },
};
</script>

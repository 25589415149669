<template>
  <SlottedMessageInDrawer
    ref="slottedMessageInDrawer"
    title="Delete"
    :closeDisabled="loading"
  >
    <template v-slot:modal-body>
      <div v-if="!loading">
        <p class="h-h4 h-primary_shade_1">
          Do you really want to
          <strong class="h-dangerous">delete</strong>
          the thought of the day?
        </p>
      </div>
      <Loader v-else />
    </template>

    <template v-slot:modal-footer>
      <button
        type="button"
        class="h-btn h-btn-dangerous"
        @click="confirm"
        :disabled="loading"
      >
        Delete
      </button>

      <button
        type="button"
        class="h-btn h-btn-accent h-margin-left-s"
        @click="close"
        :disabled="loading"
      >
        Close
      </button>
    </template>
  </SlottedMessageInDrawer>
</template>


<script>
import Loader from '@/components/Loader';
import SlottedMessageInDrawer from '@/components/dialogs/SlottedMessageInDrawer';

export default {
  data() {
    return {
      loading: false,
      thoughtOfTheDay: null,
    };
  },

  methods: {
    show(thoughtOfTheDay) {
      this.loading = false;
      this.thoughtOfTheDay = thoughtOfTheDay;
      this.$refs.slottedMessageInDrawer.show();
    },

    close(force) {
      this.$refs.slottedMessageInDrawer.close(force);
    },

    async confirm() {
      this.loading = true;
      const response = await this.$api.deleteThoughtOfTheDay(
        this.careProtocolId,
        this.doctorId,
        this.thoughtOfTheDay.id,
      );
      this.$emit('deleted', response.thought_of_the_day_list);
      this.close(true);
    },
  },

  components: {
    Loader,
    SlottedMessageInDrawer,
  },

  props: {
    careProtocolId: {
      type: Number,
      required: true,
    },
    doctorId: {
      type: Number,
      required: false,
    },
  },
};
</script>

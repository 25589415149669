<template>
  <div>
    <div
      class="
        h-space-between
        h-row
        h-padding-bottom-l
        h-padding-left-l
        h-padding-right-l
      "
    >
      <div class="w-25">
        <input
          type="text"
          class="h-margin-top-l h-input"
          placeholder="Search..."
          v-model="search"
        />
      </div>
      <div class="w-25">
        <div>
          <span class="h-label">Search in</span>
        </div>
        <select class="form-control h-input" v-model="searchIn">
          <option value="title">Title</option>
          <option value="author">Author</option>
        </select>
      </div>

      <div class="w-25">
        <div>
          <span class="h-label">Order by</span>
        </div>
        <select class="form-control h-input" v-model="orderBy">
          <option value="title">Title</option>
          <option value="author">Author</option>
          <option value="kcal">Calories</option>
          <option value="fat_g">Fat</option>
          <option value="carb_g">Carbohydrates</option>
          <option value="protein_g">Protein</option>
          <option value="fiber_g">Fiber</option>
          <option value="sugar_g">Sugar</option>
          <option value="sodium_mg">Sodium</option>
          <option value="potass_mg">Potassium</option>
          <option value="phosph_mg">Phosphorus</option>
        </select>
      </div>
      <div class="h-margin-top-l">
        <button
          type="button"
          class="h-btn h-btn-accent"
          @click="() => doSearch(true)"
        >
          Search
        </button>
        <button
          type="button"
          class="h-btn h-btn-accent h-margin-left-s"
          @click="newRecipe"
        >
          <i class="mdi mdi-plus-circle-outline mr-2"></i>New recipe
        </button>
      </div>
    </div>

    <div class="page-recipes-and-filters">
      <div class="page-recipes">
        <RecipeList
          v-if="!loading"
          :recipeList="recipeList"
          :page="page"
          :perPage="perPage"
          :pageCount="pageCount"
          @setPage="setPage"
        />
        <Loader v-if="loading" />
      </div>
      <div class="page-filters">
        <SearchFilters v-model="filters" />
      </div>
    </div>
  </div>
</template>

<script>
import settings from '@/settings.js';
import Loader from '@/components/Loader';
import SearchFilters from './search/SearchFilters';
import RecipeList from './search/RecipeList';

export default {
  data() {
    return {
      loading: false,
      recipeList: [],
      page: 1,
      pageCount: null,
      perPage: 5,
      filters: {},
      search: '',
      searchIn: 'title',
      orderBy: 'title',
    };
  },

  methods: {
    newRecipe() {
      this.$router.push({ name: 'NewRecipe' });
    },
    async doSearch(setPageOne) {
      $('html').scrollTop(0);

      if (setPageOne) {
        this.page = 1;
      }

      const payload = {
        order_by: this.orderBy,
        page: this.page,
      };
      if (this.search) {
        payload[this.searchIn] = this.search;
      }
      Object.keys(this.filters).forEach((key) => {
        if (key !== 'nutritionValues') {
          payload[key] = this.filters[key].join(',');
        } else {
          Object.keys(this.filters[key]).forEach((nKey) => {
            payload[
              nKey
            ] = `${this.filters[key][nKey].from}<${this.filters[key][nKey].to}`;
          });
        }
      });

      this.loading = true;
      $(this.$refs.searchWrapper).scrollTop(0);
      const response = await this.$api.post(
        `${settings.BACKEND_URL}/api/recipes/search`,
        null,
        payload,
      );
      this.recipeList = response.recipe;
      this.pageCount = response.pages;
      this.loading = false;
    },

    async setPage(page) {
      this.page = page;
      await this.doSearch();
    },
  },

  components: {
    SearchFilters,
    Loader,
    RecipeList,
  },
};
</script>

<template>
  <div>
    <h5 class="h-h3 h-primary_shade_1 h-padding-bottom-s h-padding-top-m">
      Description
    </h5>
    <p class="h-h6 h-primary_shade_1">{{ recipe.description }}</p>
    <hr class="h-hr h-margin-bottom-s h-margin-top-m" />
    <h5 class="h-h3 h-primary_shade_1 h-padding-bottom-s h-padding-top-m">
      Ingredients
    </h5>

    <ul class="list-unstyled row ingredients">
      <li
        v-for="ingredient of ingredients"
        :key="ingredient.ingredient_id"
        class="col-6 h-h6 h-primary_shade_1 h-padding-bottom-xs"
      >
        {{ ingredient.qty }} {{ ingredient.unit }} {{ ingredient.usda.name }}
      </li>
    </ul>
    <hr class="h-hr h-margin-bottom-s h-margin-top-m" />

    <h5 class="h-h3 h-primary_shade_1 h-padding-bottom-s h-padding-top-m">
      Directions
    </h5>
    <p class="h-h6 h-primary_shade_1 pre-wrap">
      {{ recipe.directions }}
    </p>
    <hr class="h-hr h-margin-bottom-s h-margin-top-m" />

    <h5 class="h-h3 h-primary_shade_1 h-padding-bottom-s h-padding-top-m">
      Source
    </h5>
    <a v-if="recipe.url" :href="recipe.url" target="_blank">{{ recipe.url }}</a>
    <span v-else class="text-muted"> &lt;unknown&gt; </span>

    <hr class="h-hr h-margin-bottom-s h-margin-top-m" />
    <h5 class="h-h3 h-primary_shade_1 h-padding-bottom-s h-padding-top-m">
      Tags
    </h5>
    <div v-if="recipeTags.length" class="d-flex flex-row">
      <div v-for="tag of recipeTags" :key="tag" class="badge badge-primary tag">
        {{ tag }}
      </div>
    </div>
    <span v-else class="text-muted"> &lt;no tags&gt; </span>

    <br /><br />
  </div>
</template>


<style lang="stylus" scoped>
ul.ingredients {
  border-left: 1rem solid #2b5bdc;

  li {
    padding-left: 1em;
  }
}
</style>


<script>
export default {
  props: {
    recipe: {
      type: Object,
    },
    ingredients: {
      type: Array,
    },
  },

  computed: {
    recipeTags() {
      return this.recipe.tags
        .split('|')
        .sort()
        .map(tag => tag.trim())
        .filter(tag => !!tag);
    },
  },
};
</script>

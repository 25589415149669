<template>
  <main class="login">
    <link href="/assets/css/index.css" rel="stylesheet" type="text/css" />

    <div class="h-row">
      <div class="h-col-desktop-8 bg-accent h-hide-mobile">
        <div id="login_animation">
          <lottie-animation path="./assets/js/lottie_files/login.json" />
        </div>
      </div>
      <div class="h-col-desktop-4 h-col-mobile-12 h-d-flex">
        <div class="login-card">
          <h4 class="h-h1 h-primary_shade_1 h-text-center">
            Create new account
          </h4>

          <div
            v-if="invationInvalidErrorMsg"
            class="h-text-center h-margin-top-l invationInvalidErrorMsg"
          >
            <big class="h-h3 h-dangerous">{{ invationInvalidErrorMsg }}</big>

            <p class="h-h6 h-bold h-secondary_shade_1 h-margin-top-s">
              Go to
              <router-link :to="{ name: 'Login' }" class="h-accent">
                login page
              </router-link>
            </p>
          </div>

          <div v-if="!loading && !invationInvalidErrorMsg">
            <form class="form-horizontal auth-form">
              <div class="form-group h-row">
                <div class="h-col-desktop-12 h-col-mobile-12 h-margin-top-xl">
                  <label for="hospital" class="h-label">Hospital</label>
                  <div class="input-group">
                    <span class="auth-form-icon">
                      <i class="dripicons-medical"></i>
                    </span>
                    <input
                      id="hospital"
                      type="email"
                      class="h-input"
                      :value="inviteData.hospital.name"
                      readonly
                    />
                  </div>
                </div>
              </div>
              <!--end form-group-->
              <div class="form-group h-row">
                <div class="h-col-desktop-12 h-col-mobile-12 h-margin-top-l">
                  <label for="hospital" class="h-label">Email</label>
                  <div class="input-group">
                    <span class="auth-form-icon">
                      <i class="dripicons-mail"></i>
                    </span>
                    <input
                      id="useremail"
                      type="email"
                      class="h-input"
                      :value="inviteData.email"
                      readonly
                    />
                  </div>
                </div>
              </div>
              <!--end form-group-->
              <div class="form-group h-row">
                <div class="h-col-desktop-12 h-col-mobile-12 h-margin-top-l">
                  <label for="hospital" class="h-label">Name</label>
                  <div class="input-group">
                    <span class="auth-form-icon">
                      <i class="dripicons-mail"></i>
                    </span>
                    <input
                      id="name"
                      type="text"
                      class="h-input"
                      :value="inviteData.name"
                      readonly
                    />
                  </div>
                </div>
              </div>
              <!--end form-group-->
              <div class="form-group h-row">
                <div class="h-col-desktop-12 h-col-mobile-12 h-margin-top-l">
                  <label for="hospital" class="h-label">Password</label>
                  <div class="input-group">
                    <span class="auth-form-icon">
                      <i class="dripicons-lock"></i>
                    </span>
                    <input
                      id="userpassword"
                      type="password"
                      class="h-input"
                      :class="{ 'is-invalid': errors.password }"
                      placeholder="Enter password"
                      v-model="password"
                    />
                    <div class="invalid-feedback" v-if="errors.password">
                      {{ errors.password }}
                    </div>
                  </div>
                </div>
              </div>
              <!--end form-group-->
              <div class="form-group h-row">
                <div class="h-col-desktop-12 h-col-mobile-12 h-margin-top-l">
                  <label for="hospital" class="h-label">Confirm Password</label>
                  <div class="input-group">
                    <span class="auth-form-icon">
                      <i class="dripicons-lock-open"></i>
                    </span>
                    <input
                      id="conf_password"
                      type="password"
                      class="h-input"
                      :class="{
                        'is-invalid': errors.confirmPassword,
                      }"
                      placeholder="Enter Confirm Password"
                      v-model="confirmPassword"
                    />
                    <div class="invalid-feedback" v-if="errors.confirmPassword">
                      {{ errors.confirmPassword }}
                    </div>
                  </div>
                </div>
              </div>
              <!--end form-group-->

              <div class="form-group h-row">
                <div
                  class="
                    h-col-desktop-12 h-col-mobile-12 h-d-flex h-margin-top-l
                  "
                >
                  <label class="h-toggle h-margin-0" for="termsOfUseCheckbox">
                    <input
                      type="checkbox"
                      id="termsOfUseCheckbox"
                      class="h-input"
                      :class="{
                        'is-invalid': errors.acceptedTermsOfUse,
                      }"
                      v-model="acceptedTermsOfUse"
                    />
                    <span class="h-slider"></span>
                  </label>
                  <div class="h-d-flex-desktop">
                    <p
                      class="
                        h-h6 h-bold
                        h-secondary_shade_1
                        h-align-items-center h-padding-left-s
                      "
                    >
                      By registering you agree to the Healent Health
                      <a
                        href="#"
                        class="
                          h-h6
                          h-bold
                          h-accent
                          h-align-items-center
                          h-d-contents
                          h-padding-left-xs
                        "
                      >
                        Terms of Use</a
                      >
                    </p>
                  </div>

                  <div
                    class="invalid-feedback"
                    v-if="errors.acceptedTermsOfUse"
                  >
                    <p>{{ errors.acceptedTermsOfUse }}</p>
                  </div>
                </div>
                <!--end col-->
              </div>
              <!--end form-group-->

              <div class="form-group h-row">
                <div
                  class="
                    h-col-desktop-12
                    h-col-mobile-12
                    h-text-center
                    h-margin-top-l
                  "
                >
                  <button
                    class="h-btn h-btn-accent h-btn-full"
                    type="button"
                    @click="register"
                  >
                    Register
                  </button>
                </div>
                <!--end col-->
              </div>
              <!--end form-group-->
            </form>
            <!--end form-->

            <div
              class="
                h-col-desktop-12 h-col-mobile-12 h-text-center h-margin-top-m
              "
            >
              <p class="h-h6 h-bold h-secondary_shade_1">
                Already have an account?
                <router-link :to="{ name: 'Login' }" class="h-accent">
                  Log in
                </router-link>
              </p>
            </div>
          </div>

          <!--end auth-logo-text-->
          <Loader v-if="loading" />
        </div>
        <!--end card-->
      </div>
      <!--end auth-page-->
    </div>
  </main>
</template>


<script>
import settings from '@/settings.js';
import virgilHelper from '@/utils/virgil_helper';
import Loader from './components/Loader';

export default {
  name: 'Register',

  data() {
    return {
      loading: true,

      password: '',
      confirmPassword: '',
      acceptedTermsOfUse: false,

      errors: {},
      invitData: null,
      invationInvalidErrorMsg: '',
    };
  },

  watch: {
    password() {
      this.errors.password = null;
    },

    confirmPassword() {
      this.errors.confirmPassword = null;
    },

    acceptedTermsOfUse() {
      this.errors.acceptedTermsOfUse = null;
    },
  },

  methods: {
    async register() {
      // eslint-disable-next-line
      for (const key of Object.keys(this.errors)) {
        if (this.errors[key]) {
          return;
        }
      }

      this.loading = true;

      const result = await this.$api.register({
        invitation_code: this.$route.params.code,
        password: this.password,
        confirmPassword: this.confirmPassword,
        acceptedTermsOfUse: this.acceptedTermsOfUse,
      });
      this.errors = result.errors || {};

      if (result.status === 'ok') {
        settings.currentSession = result.current_session_data;
        virgilHelper.prepareKeyMaterial(
          result.current_session_data.user.id.toString(),
        );
        await virgilHelper.ensurePublicKey();
        this.$router
          .push({
            name: 'index',
          })
          .catch((err) => {});
      } else {
        this.loading = false;
      }
    },
  },

  async mounted() {
    const response = await this.$api.getUserInvite(this.$route.params.code);

    if (response.status === 'ok') {
      this.inviteData = response.invitation;
      this.invationInvalidErrorMsg = '';
    } else {
      this.inviteData = null;
      this.invationInvalidErrorMsg = response.message;
    }

    this.loading = false;
  },

  components: {
    Loader,
  },
};
</script>

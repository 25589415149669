<template>
  <div class="h-drawer-max79">
    <Drawer :direction="'right'" :exist="true" ref="modalDialog">
      <div class="h-drawer-card">
        <div class="h-drawer-card-header">
          <div class="h-main-drawer-card-header h-margin-bottom-l">
            <h3 class="h-h3 h-primary_shade_1">
              <slot name="modal-title">Add care protocol</slot>
            </h3>
            <button
              type="button"
              class="close"
              aria-hidden="true"
              @click="close"
              :disabled="loading"
            >
              <img src="/assets/images/Collapse-drawer.svg" alt="" />
            </button>
          </div>
        </div>
        <div class="h-drawer-card-body">
          <div v-if="!loading">
            <div v-if="!this.error">
              <label class="h-label">Select global care protocol: </label>
              <InputField type="select" :choices="careProtocolChoices" v-model="protocolId" />
            </div>

            <label v-else class="text-danger"> ERROR: {{ this.error }} </label>
          </div>

          <Loader v-else />
        </div>

        <div class="h-drawer-card-footer">
          <button type="button" class="h-btn h-btn-accent" @click="confirm" :disabled="loading">
            Confirm
          </button>

          <button
            type="button"
            class="h-btn h-btn-dangerous h-margin-left-s"
            @click="close"
            :disabled="loading"
          >
            Cancel
          </button>
        </div>
      </div>
    </Drawer>
  </div>
</template>

<script>
import settings from '@/settings.js';
import Loader from '@/components/Loader';
import InputField from '@/components/InputField';

export default {
  data() {
    return {
      loading: false,
      protocolId: null,
      hospitalId: null,
      error: '',
      careProtocolChoices: [],
      careProtocolChoicesLoaded: false,
    };
  },

  methods: {
    async show(hospitalId) {
      this.loading = true;
      this.error = '';
      this.hospitalId = parseInt(hospitalId, 10);
      this.$refs.modalDialog.open();
      this.$refs.modalDialog.active = true;
      $(this.$refs.modalDialog.el).modal({
        backdrop: 'static',
        keyboard: false,
      });
      $('.modal-backdrop')
        .last()
        .insertBefore(this.$refs.modalDialog.el);

      if (!this.careProtocolChoicesLoaded) {
        const { care_protocols: careProtocols } = await this.$api.getHospitalCareProtocols({
          hospitalID: 0,
        });
        this.careProtocolChoices = careProtocols.map(c => [c.id, c.name]);
        this.protocolId = careProtocols.length > 0 ? careProtocols[0].id : null;
      }
      this.careProtocolChoicesLoaded = true;
      this.loading = false;
    },

    close(force) {
      this.$refs.modalDialog.close();
      this.$refs.modalDialog.active = false;
      if (!force && this.loading) return;
      $(this.$refs.modalDialog.el).modal('hide');
    },

    async confirm() {
      this.loading = true;
      const response = await this.$api.post(
        `${settings.BACKEND_URL}/api/v-nurse/care-protocols/add-to-hospial`,
        null,
        {
          protocol_id: this.protocolId,
          hospital_id: this.hospitalId,
        },
      );
      if (response.status === 'error') {
        this.loading = false;
        this.error = response.message;
      } else {
        this.$emit('confirmed');
        this.close(true);
      }
    },
  },

  components: {
    Loader,
    InputField,
  },
};
</script>

<template>
  <div>
    <div
      v-if="globalMessage.message"
      ref="globalMessage"
      :class="`card text-white bg-${globalMessage.type} cursor-pointer globalMessage mt-3`"
      @click="hideGlobalMessage"
    >
      <div class="card-body py-3 px-4 globalMessageContent">
        {{ globalMessage.message }}
      </div>
    </div>
    <div
      class="
        h-space-between h-row h-padding-top-l h-padding-left-l h-padding-right-l
      "
    >
      <h3 class="text-left d-inline-block h-h3 h-primary_shade_1">
        <span class="h-secondary_shade_1">Recipe Editor:</span>
        {{ editedRecipe.title || "New Recipe" }}
      </h3>

      <div>
        <div class="recipe h-rec">
          <input
            type="file"
            class="dropify mx-auto d-block"
            :data-default-file="editedRecipe.image"
            ref="photo"
          />
        </div>
        <div v-if="errors.photo">
          <p class="h-dangerous text-right">Photo: {{ errors.photo }}</p>
        </div>
      </div>
    </div>
    <hr class="h-hr h-margin-l" />
    <div class="h-row h-row h-padding-left-l h-padding-right-l">
      <form
        class="form-horizontal form-material"
        :class="{
          'col-9 h-col-12': viewer === 'page',
          'col-8 h-col-12': viewer === 'dialog',
        }"
      >
        <div class="h-padding-right-l">
          <div class="form-group row">
            <div class="col-md-12 h-margin-bottom-l">
              <h3
                class="text-center h-h3 h-primary_shade_1"
                v-if="viewer === 'page'"
              >
                Recipe
              </h3>

              <label class="h-label">Title</label>
              <InputField
                type="text"
                v-model="editedRecipe.title"
                :error="errors.title"
                @input="
                  () => {
                    errors.title = null;
                  }
                "
              />
            </div>
            <div class="col-md-12 h-margin-bottom-l">
              <label class="h-label">Description</label>
              <InputField
                type="textarea"
                :rows="6"
                v-model="editedRecipe.description"
                :error="errors.description"
                @input="
                  () => {
                    errors.description = null;
                  }
                "
              />
            </div>
          </div>

          <h3
            class="text-center h-h3 h-primary_shade_1 h-padding-bottom-l"
            :class="{ 'h-dangerous': errors.ingredients_list }"
          >
            Ingredients
          </h3>
          <div v-if="errors.ingredients_list" class="h-dangerous">
            {{ errors.ingredients_list }}
          </div>
          <MealIngredientsEditor
            ref="mealIngredientsEditor"
            :ingredients="edditedIngredients"
            :errors="errors"
            @ingredientsChanged="
              () => {
                updateRecipeNutritionFacts();
              }
            "
            @afterIngredientAdded="errors.ingredients_list = null"
          />

          <div class="h-row h-padding-top-l">
            <div
              class="
                h-input-w49
                h-desktop-margin-right-2
                h-col-mobile-12
                h-margin-bottom-l
              "
            >
              <label class="h-label"> Totle number of servings</label>
              <InputField
                type="number"
                :step="1"
                :min="1"
                :max="12"
                v-model="editedRecipe.total_servings"
                :error="errors.total_servings"
                @input="
                  () => {
                    errors.total_servings = null;
                    updateRecipeNutritionFacts();
                  }
                "
              />
            </div>

            <div class="h-input-w49 h-col-mobile-12 h-margin-bottom-l">
              <label class="h-label"> Serving size</label>
              <InputField
                type="text"
                v-model="editedRecipe.serving_size"
                :error="errors.serving_size"
                @input="
                  () => {
                    errors.serving_size = null;
                  }
                "
              />
            </div>
          </div>

          <div class="h-row">
            <div class="col-md-12">
              <label class="h-label"> Directions</label>
              <InputField
                type="textarea"
                :rows="6"
                v-model="editedRecipe.directions"
                :error="errors.directions"
                @input="
                  () => {
                    errors.directions = null;
                  }
                "
              />
            </div>
          </div>

          <div class="h-row h-padding-top-l">
            <div
              class="
                h-input-w49
                h-desktop-margin-right-2
                h-col-mobile-12
                h-margin-bottom-l
              "
            >
              <label class="h-label"> Preparation time</label>
              <InputField
                type="select"
                :choices="[
                  [null, '--- select ---'],
                  '<15min',
                  '15min-30min',
                  '30min-1h',
                  '1h-2h',
                  '>2h',
                ]"
                v-model="editedRecipe.prep_time"
                :error="errors.prep_time"
                @input="
                  () => {
                    errors.prep_time = null;
                  }
                "
              />
            </div>

            <div class="h-input-w49 h-col-mobile-12 h-margin-bottom-l">
              <label class="h-label"> Cooking time</label>
              <InputField
                type="select"
                :choices="[
                  [null, '--- select ---'],
                  '<15min',
                  '15min-30min',
                  '30min-1h',
                  '1h-2h',
                  '>2h',
                ]"
                v-model="editedRecipe.cook_time"
                :error="errors.cook_time"
                @input="
                  () => {
                    errors.cook_time = null;
                  }
                "
              />
            </div>
          </div>

          <div class="h-row">
            <div
              class="
                h-input-w49
                h-desktop-margin-right-2
                h-col-mobile-12
                h-margin-bottom-l
              "
            >
              <label class="h-label"> Skill level </label>
              <InputField
                type="select"
                :choices="[
                  [null, '--- select ---'],
                  'Beginner',
                  'Intermediate',
                  'Advanced',
                  'Iron chef',
                ]"
                v-model="editedRecipe.skill_level"
                :error="errors.skill_level"
                @input="
                  () => {
                    errors.skill_level = null;
                  }
                "
              />
            </div>

            <div class="h-input-w49 h-col-mobile-12 h-margin-bottom-l">
              <label class="h-label"> Cuisine </label>
              <InputField
                type="select"
                :choices="[
                  [null, '--- select ---'],
                  'American',
                  'Chinese',
                  'French',
                  'Greek',
                  'Indian',
                  'Italian',
                  'Mediterranean',
                  'Mexican',
                  'Spanish',
                  'Thai',
                  'Other',
                ]"
                v-model="editedRecipe.cuisine"
                :error="errors.cuisine"
                @input="
                  () => {
                    errors.cuisine = null;
                  }
                "
              />
            </div>
          </div>

          <div class="h-row">
            <div
              class="
                h-input-w49
                h-desktop-margin-right-2
                h-col-mobile-12
                h-margin-bottom-l
              "
            >
              <label class="h-label"> Author</label>
              <InputField
                type="text"
                v-model="editedRecipe.author"
                :error="errors.author"
                @input="
                  () => {
                    errors.author = null;
                  }
                "
              />
            </div>

            <div class="h-input-w49 h-col-mobile-12 h-margin-bottom-l">
              <label class="h-label"> Source (URL)</label>
              <InputField
                type="text"
                v-model="editedRecipe.url"
                :error="errors.url"
                @input="
                  () => {
                    errors.url = null;
                  }
                "
              />
            </div>
          </div>

          <div class="h-row">
            <div class="col-md-12">
              <label class="h-label"> Tags</label>
              <InputField
                type="select"
                class="h-select-tags"
                :choices="
                  [
                    'Breakfast',
                    'Lunch',
                    'Dinner',
                    'Morning Snack',
                    'Evening Snack',
                    'Paleo',
                    'Pasta/Grain',
                    'Salad',
                    'Drink/Smoothie',
                    'Soup/Chili',
                    'Vegan',
                    'Vegetarian',
                    'Egg',
                    'Fish',
                    'Wheat',
                    'Peanuts',
                    'Pork',
                    'Beef',
                    'Poultry',
                    'Shellfish',
                    'Soy',
                    'Tree Nuts',
                    'Milk',
                    'Sides',
                    'Slow Cooker',
                    'Low-Sodium',
                    '10 or Less Ingredients',
                  ].sort()
                "
                :multiple="true"
                v-model="edditedTags"
                :error="errors.tags"
                @input="
                  () => {
                    errors.tags = null;
                  }
                "
              />
            </div>
          </div>

          <MealEditorSaveButton
            v-if="viewer === 'page'"
            @save="save"
            class="text-center h-margin-top-l"
          />
        </div>
      </form>

      <div
        :class="{
          'col-3 h-col-12 h-mob-top-l': viewer === 'page',
          'col-4 h-col-12 h-mob-top-l': viewer === 'dialog',
        }"
      >
        <h3 class="text-center h-h3 h-primary_shade_1 h-margin-bottom-l">
          Nutrition Facts
        </h3>

        <MealNutritionFacts :recipe="editedRecipe" class="mx-2 mt-4" />
      </div>
    </div>

    <MealEditorSaveButton
      v-if="viewer === 'dialog'"
      @save="save"
      class="text-center mt-3 mb-2"
    />

    <GlobalWait
      ref="globalWait"
      title="Saving recipe..."
      message="Saving recipe, please wait..."
    />
  </div>
</template>


<style lang="stylus" scoped>
div.globalMessageContent {
  padding: 5rem 9rem !important;
}

.recipe {
  min-width: 140rem;
  min-height: 110rem;
  max-width: 140rem;
  max-height: 110rem;
  display: flex;
}

form .form-group.row {
  margin-bottom: 0;
}

textarea.h-input {
  padding-right: 0;
}

.select2-container {
  width: auto !important;
}
</style>


<script>
import nutrutuinCalc from './nutrutuinCalc';

import InputField from '@/components/InputField';
import MealIngredientsEditor from './MealIngredientsEditor';
import MealNutritionFacts from '../MealNutritionFacts';
import MealEditorSaveButton from './MealEditorSaveButton';

import GlobalWait from '@/components/GlobalWait';

export default {
  props: {
    recipe: {
      type: Object,
    },
    ingredients: {
      type: Array,
    },
    viewer: {
      type: String,
    },
  },

  data() {
    return {
      errors: {},
      editedRecipe: JSON.parse(JSON.stringify(this.recipe)),
      edditedIngredients: JSON.parse(JSON.stringify(this.ingredients)),
      edditedTags: this.recipe.tags.split('|').sort(),
      globalMessage: {
        type: null,
        message: null,
      },
      removePhoto: false,
    };
  },

  methods: {
    hideGlobalMessage() {
      $(this.$refs.globalMessage).fadeOut();
    },

    updateRecipeNutritionFacts() {
      nutrutuinCalc.assignNutritionFacts(
        this.editedRecipe,
        this.edditedIngredients,
      );
      this.$forceUpdate();
    },

    async save() {
      this.$refs.globalWait.start();
      this.errors = {};
      this.globalMessage = {};

      this.$refs.mealIngredientsEditor.resetAddIngredient();

      const payload = {
        recipe: this.editedRecipe,
        ingredients: this.edditedIngredients,
        tags: this.edditedTags,
        removePhoto: this.removePhoto,
      };
      if (this.$refs.photo.files.length !== 0) {
        payload.photo = this.$refs.photo.files[0];
      }

      const response = await this.$api.updateRecipe(
        this.editedRecipe.recipe_id,
        payload,
      );
      this.$emit('scrollTop');

      if (response.status === 'error') {
        this.errors = response.errors;
        this.globalMessage = {
          type: 'danger',
          message: 'Form has errors.',
        };
        this.$refs.globalWait.stop();
      } else {
        this.removePhoto = false;
        this.globalMessage = {
          type: 'success',
          message: 'Recipe saved.',
        };
        this.$refs.globalWait.stop(() => {
          this.$emit('recipeSaved', response.recipe, response.ingredients);
        });
      }
    },
  },

  mounted() {
    $('.dropify')
      .dropify()
      .on('dropify.afterClear', () => {
        this.removePhoto = true;
      });
    this.updateRecipeNutritionFacts();
  },

  components: {
    InputField,
    MealIngredientsEditor,
    MealNutritionFacts,
    MealEditorSaveButton,
    GlobalWait,
  },
};
</script>

export default {
  ucFirst: function ucFirst(value) {
    if (!value) return '';
    return value[0].toUpperCase() + value.slice(1);
  },

  underscoreCaseToName: function underscoreCaseToName(value) {
    if (!value) return '';
    const parts = value.split('_');
    parts[0] = this.ucFirst(parts[0]);
    return parts.join(' ');
  },
};

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"\n      h-padding-left-l h-padding-right-l h-space-between h-align-items-center\n    "},[_c('h3',{staticClass:"h-h3 h-primary_shade_1"},[_vm._v("Surgery Checklist")]),_c('div',[(!_vm.readOnly && _vm.isTemplateMode)?_c('div',[_c('button',{staticClass:"h-btn h-btn-safe",attrs:{"type":"button"},on:{"click":function () {
              _vm.$refs.cloneItemsDialog.show();
            }}},[_vm._v(" Clone existing items ")]),_c('button',{staticClass:"h-btn h-btn-accent h-margin-left-s",attrs:{"type":"button"},on:{"click":function () {
              _vm.$refs.createItemDialog.show();
            }}},[_c('i',{staticClass:"healenticons-plus h-padding-right-xs"}),_vm._v("New item ")])]):_vm._e(),(!_vm.readOnly && !_vm.isTemplateMode)?_c('div',[_c('button',{staticClass:"h-btn h-btn-accent",attrs:{"type":"button","disabled":!_vm.doctorHasChanges},on:{"click":_vm.saveDoctorChanges}},[_vm._v(" Save changes ")])]):_vm._e()])]),_c('div',{staticClass:"h1-table"},[_c('table',{staticClass:"table dataTable"},[_c('thead',{staticClass:"thead-light"},[_c('tr',[(!_vm.readOnly && !_vm.isTemplateMode)?_c('th',{staticClass:"text-center"},[_vm._v(" On/Off ")]):_vm._e(),_c('th'),_c('th',{staticClass:"text-right w-1"},[_vm._v("#")]),_c('th',[_vm._v("Name")]),_c('th',[_vm._v("Start date")]),_c('th',[_vm._v("Due date")]),_c('th',[_vm._v("Staff alert")]),_c('th',[_vm._v("Reminders")]),_c('th',{staticClass:"text-center"},[_vm._v("Type")]),_c('th',{staticClass:"text-center"},[_vm._v("Active")]),_c('th',{staticClass:"text-center"},[_vm._v("Notifications only")]),(!_vm.readOnly && _vm.isTemplateMode)?_c('th',{staticClass:"text-right"},[_vm._v(" Action ")]):_vm._e()])]),_vm._l((_vm.careProtocolItems),function(item,index){return _c('tbody',{key:item.id},[_c('tr',{class:{ 'text-muted': !item.active }},[(!_vm.readOnly && !_vm.isTemplateMode)?_c('td',{staticClass:"text-center"},[_c('InputField',{attrs:{"id":("careProtocolItem-doctorSwitch::" + (item.id)),"type":"checkbox","checkboxSwitchType":"success"},on:{"change":function () {
                  _vm.doctorHasChanges = true;
                }},model:{value:(item.enabled),callback:function ($$v) {_vm.$set(item, "enabled", $$v)},expression:"item.enabled"}})],1):_vm._e(),_c('td',[_c('i',{staticClass:"font-16 expand-row",class:{
                'fas fa-plus-square text-success': !item.EXPANDED,
                'fas fa-minus-square text-danger': item.EXPANDED,
              },attrs:{"title":"Expand/collapse"},on:{"click":function($event){return _vm.toggleCareProtocolExpanded(item)}}})]),_c('td',{staticClass:"text-right"},[_vm._v(" "+_vm._s(index + 1)+" ")]),_c('td',[(
                item.active &&
                (!item.start_seconds_before.getTotalSeconds() ||
                  !item.due_seconds_before.getTotalSeconds())
              )?_c('big',[_c('IconTooltip',{attrs:{"iconClass":"mdi mdi-alert-outline alert-icon text-danger","title":"Start date and due date are\n                      required for auto assigning surgery items to clients."}})],1):_vm._e(),_vm._v(" "+_vm._s(item.name)+" ")],1),_c('td',[_vm._v(" "+_vm._s(item.start_seconds_before.toString() || "-")+" ")]),_c('td',[_vm._v(" "+_vm._s(item.due_seconds_before.toString() || "-")+" ")]),_c('td',[(item.staff_alerts.length !== 0)?_c('ol',{staticClass:"timeParts"},_vm._l((item.staff_alerts),function(entry,index){return _c('li',{key:index},[_vm._v(" "+_vm._s(entry.toString())+" ")])}),0):_c('span',[_vm._v("-")])]),_c('td',[(item.reminders.length !== 0)?_c('ol',{staticClass:"timeParts"},_vm._l((item.reminders),function(entry,index){return _c('li',{key:index},[_vm._v(" "+_vm._s(entry.toString())+" ")])}),0):_c('span',[_vm._v("-")])]),_c('td',{staticClass:"text-center"},[_c('span',{class:{
                'text-blue': item.active && item.post_surgery,
              }},[_vm._v(" "+_vm._s(item.post_surgery ? "Post-surgery" : "Pre-surgery")+" ")])]),_c('td',{staticClass:"text-center"},[_c('span',{class:{
                'text-success': item.active,
                'text-danger': !item.active,
              }},[_vm._v(" "+_vm._s(item.active ? "Yes" : "No")+" ")])]),_c('td',{staticClass:"text-center"},[_c('span',{class:{
                'text-success': item.is_notifications_only,
                'text-danger': !item.is_notifications_only,
              }},[_vm._v(" "+_vm._s(item.is_notifications_only ? "Yes" : "No")+" ")])]),(!_vm.readOnly && _vm.isTemplateMode)?_c('td',{staticClass:"text-right"},[_c('a',{staticClass:"mr-2",attrs:{"href":"javascript:void(0);"},on:{"click":function () {
                  _vm.$refs.editItemDialog.show(item);
                }}},[_c('i',{staticClass:"fas fa-edit text-info font-16",attrs:{"title":"Edit"}})]),_c('a',{staticClass:"mr-2",attrs:{"href":"javascript:void(0);"},on:{"click":function () {
                  _vm.$refs.deleteItemDialog.show(item);
                }}},[_c('i',{staticClass:"fas fa-trash-alt text-danger font-16",attrs:{"title":"Delete"}})])]):_vm._e()]),(item.EXPANDED)?_c('tr',[_c('td',{staticClass:"no-border-top careProtocol-educationRow",attrs:{"colspan":"100"}},[_c('div',{staticClass:"text-center mb-3"},[_c('strong',[_c('big',[_vm._v(" Care protocol item education: "+_vm._s(item.name)+" ")])],1)]),_c('EducationList',{attrs:{"collectionId":item.learn_collection_id,"readOnly":_vm.readOnly || !_vm.isTemplateMode,"dense":true}})],1)]):_vm._e()])}),(_vm.careProtocolItems.length === 0)?_c('tbody',[_vm._m(0)]):_vm._e()],2),_c('CreateItemDialog',{ref:"createItemDialog",attrs:{"protocolId":_vm.careProtocol.id},on:{"itemsUpdated":_vm.setItems}}),_c('CloneItemsDialog',{ref:"cloneItemsDialog",attrs:{"protocolId":_vm.careProtocol.id},on:{"itemsUpdated":_vm.setItems}}),_c('EditItemDialog',{ref:"editItemDialog",on:{"itemsUpdated":_vm.setItems}}),_c('DeleteItemDialog',{ref:"deleteItemDialog",on:{"itemsUpdated":_vm.setItems}}),_c('GlobalWait',{ref:"globalWait",attrs:{"title":"Saving enabled care protocl items","message":"Saving enabled care protocl items..."}})],1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('tr',[_c('td',{staticClass:"text-center",attrs:{"colspan":"100"}},[_vm._v("No entries")])])}]

export { render, staticRenderFns }
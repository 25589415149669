var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"h-drawer-max80 h-no-padding"},[_c('SlottedMessageInDrawer',{ref:"slottedMessage",attrs:{"title":'Health risk assessment'},scopedSlots:_vm._u([{key:"modal-body",fn:function(){return [(_vm.clientId)?_c('div',{staticClass:"h1-table h-padding-top-l"},[_c('table',{staticClass:"dataTable h-noborder-input table d-table h-drawer"},[_c('thead',{staticClass:"thead-light"},[_c('tr',[_c('th',{staticClass:"text-nowrap"},[_vm._v("Order")]),_c('th',[_vm._v("Question")]),_c('th',[_vm._v("Answers")])])]),(!_vm.loading)?_c('tbody',_vm._l((_vm.questions),function(question){return _c('tr',{key:question.id},[_c('td',{staticClass:"text-center"},[_vm._v(_vm._s(question.order))]),_c('td',{staticClass:"text-left",staticStyle:{"white-space":"normal"}},[_vm._v(" "+_vm._s(question.question)+" ")]),_c('td',[(!question.is_multiselect)?_c('InputField',{attrs:{"type":"select","classExtra":{ 'height-auto': true },"choices":_vm.answerChoiceMap[question.id] || [
                      {
                        value: null,
                        title: '--- select ---'
                      }
                    ],"disabled":!_vm.canEdit},on:{"change":function () {
                      _vm.hasChange = true
                    }},model:{value:(_vm.answerMap[question.id]),callback:function ($$v) {_vm.$set(_vm.answerMap, question.id, $$v)},expression:"answerMap[question.id]"}}):_c('div',_vm._l((question.answers),function(answer){return _c('div',{key:answer.key,staticStyle:{"margin-top":"3px","margin-bottom":"3px"}},[(Object.keys(_vm.answerMap).length > 0)?_c('InputField',{attrs:{"id":("answer::" + (question.id) + ":" + (answer.key)),"type":"checkbox","label":((answer.key) + " - " + (answer.value)),"disabled":!_vm.canEdit},on:{"change":function () {
                          _vm.hasChange = true
                        }},model:{value:(_vm.answerMap[question.id][answer.key]),callback:function ($$v) {_vm.$set(_vm.answerMap[question.id], answer.key, $$v)},expression:"answerMap[question.id][answer.key]"}}):_vm._e()],1)}),0)],1)])}),0):_c('tbody',[_c('tr',[_c('td',{staticClass:"text-center",attrs:{"colspan":"1000"}},[_vm._v("Loading...")])])])]),(!_vm.loading && !_vm.hasChange)?_c('p',{staticStyle:{"color":"var(--primary_shade_1)","font-size":"8rem","margin-top":"4rem","margin-left":"15rem"}},[_c('strong',[_vm._v("Total score:")]),_vm._v(" "+_vm._s(_vm.totalScore)+" ")]):_vm._e()]):_vm._e()]},proxy:true},{key:"modal-footer",fn:function(){return [(_vm.canEdit)?_c('button',{staticClass:"h-btn h-btn-accent",attrs:{"type":"button","disabled":_vm.saving},on:{"click":_vm.save}},[_vm._v(" "+_vm._s(_vm.saving ? 'Saving...' : 'Save')+" ")]):_vm._e(),_c('button',{staticClass:"h-btn h-btn-dangerous h-margin-left-s",attrs:{"type":"button"},on:{"click":_vm.close}},[_vm._v(" Close ")])]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
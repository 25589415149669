<template>
  <div>
    <div v-if="!loading">
      <div v-if="careProtocolChoices.length !== 0" class="row filters-wrapper d-print-none">
        <div class="col-md-6 col-sm-12">
          <label class="w-100">
            Care protocol:
            <InputField
              type="select"
              :choices="careProtocolChoices"
              v-model="selectedCareProtocolId"
            />
          </label>
        </div>
      </div>

      <div v-if="selectedCareProtocol" class="selectedCareProtocol-wrapper globalTopMargin">
        <h4 class="text-center">
          <span class="text-muted">Education Compliance:</span>
          {{ selectedCareProtocol.name }}
        </h4>
        <table
          class="table compliance-table mb-0"
          :class="{'table-hover': selectedCareProtocol.items.length !== 0}"
        >
          <thead class="thead-light">
            <tr>
              <th class="w-1">
                #
              </th>
              <th>
                Title
              </th>
              <th>
                Subtitle
              </th>
              <th>
                Type
              </th>
              <th class="text-right">
                Compliance
              </th>
            </tr>
          </thead>

          <tbody v-if="selectedCareProtocol.items.length !== 0">
            <tr v-for="(entry, index) of selectedCareProtocol.items" :key="entry.id">
              <td>
                {{ index+1 }}
              </td>
              <td>
                <a
                  v-if="entry.item_type === 'video'"
                  :href="entry.link"
                  target="_blank"
                >
                  {{ entry.title }}
                </a>
                <a
                  v-if="entry.item_type !== 'video'"
                  href='javascript:void(0);'
                  class="d-print-none"
                  @click="() => {
                    $refs.educationEntryDialog.show(
                      entry.id, selectedCareProtocol.learn_collection_id, true
                    );
                  }"
                >
                  {{ entry.title }}
                </a>
                <span
                  v-if="entry.item_type !== 'video'"
                  class="d-none d-print-inline"
                >
                  {{ entry.title }}
                </span>
              </td>
              <td>
                {{ entry.sub_title }}
              </td>
              <td>
                <a
                  v-if="entry.item_type === 'video'"
                  :href="entry.link"
                  target="_blank"
                >
                  {{ entry.item_type }}
                </a>
                <span v-else>
                  {{ entry.item_type }}
                </span>
              </td>
              <td :class="getComplianceClass(entry.compliance) + ' text-right'">
                {{ entry.compliance.toFixed(1) }}%
              </td>
            </tr>
          </tbody>

          <tbody v-else>
            <tr>
              <td class="text-center" colspan="100">
                No entries found
              </td>
            </tr>
          </tbody>
        </table>

        <hr class="mt-0" />

        <div
          v-if="selectedCareProtocol.items.length !== 0"
          class="text-center mt-4"
        >
          <button
            type="button"
            class="btn btn-primary print-button d-print-none"
            @click="print"
          >
            Print
          </button>
        </div>
      </div>

      <div v-if="!selectedCareProtocol" class="text-center text-muted globalTopMargin">
        <hr />
          Care protocol not selected.
        <hr />
      </div>

      <div v-if="careProtocolChoices.length === 0" class="text-center text-muted globalTopMargin">
        <hr />
          No protocols found
        <hr />
      </div>

    </div>

    <div v-else class="globalTopMargin">
      <Loader />
    </div>

    <EducationEntryDialog
      ref="educationEntryDialog"
    />
  </div>
</template>


<style lang="stylus" scoped>
.globalTopMargin
  margin-top 3em

@media print
  .globalTopMargin
    margin-top 0 !important

.selectedCareProtocol-wrapper
  margin-bottom 3em

  table, hr
    margin 0
    margin-left auto
    margin-right auto

.print-button
  width 200px
</style>


<script>
import Loader from '@/components/Loader';
import InputField from '@/components/InputField';
import EducationEntryDialog from '@/components/education/education_entry/EducationEntryDialog';


export default {
  props: ['filterValues'],

  data() {
    return {
      loading: true,
      compliance: [],
      selectedCareProtocolId: this.filterValues.selectedCareProtocolId,
    };
  },

  watch: {
    selectedCareProtocolId() {
      this.$emit(
        'updateFilterValues',
        {
          selectedCareProtocolId: parseInt(this.selectedCareProtocolId, 10),
        },
      );
    },
  },

  computed: {
    careProtocolChoices() {
      const result = this.compliance.map((careProtocol) => {
        return [careProtocol.id, careProtocol.name];
      });
      result.unshift([null, '--- Select care protocol ---']);
      return result;
    },

    selectedCareProtocol() {
      return this.compliance.find((careProtocol) => {
        return careProtocol.id === parseInt(this.selectedCareProtocolId, 10);
      });
    },
  },

  methods: {
    getComplianceClass(compliance) {
      if (compliance === null) {
        return '';
      }
      if (compliance < 50) {
        return 'text-danger';
      }
      if (compliance < 75) {
        return 'text-warning';
      }

      return 'text-success';
    },

    print() {
      print();
    },
  },

  async mounted() {
    this.compliance = (await this.$api.complianceEducation()).result;
    this.loading = false;
  },

  components: {
    Loader,
    InputField,
    EducationEntryDialog,
  },
};
</script>

<template>
  <div>
    <div class="h-padding-left-l h-padding-right-l h-space-between h-align-items-center">
      <h3 class="h-h3 h-primary_shade_1">Assessments</h3>
      <div>
        <div v-if="!readOnly && (isTemplateMode || clientId)">
          <div v-if="isTemplateMode">
            <button type="button" class="h-btn h-btn-accent" @click="edit">Edit</button>
          </div>
        </div>

        <div v-if="!readOnly && !isTemplateMode && !clientId">
          <button
            type="button"
            class="h-btn h-btn-accent"
            :disabled="!doctorHasChanges"
            @click="saveDoctorChanges"
          >
            Save changes
          </button>
        </div>
      </div>
    </div>
    <div v-if="!loading" class="h1-table">
      <table class="table dataTable">
        <thead class="thead-light">
          <tr>
            <th
              v-if="!readOnly && !isTemplateMode && !clientId"
              class="w-1 text-center text-nowrap"
            >
              On/Off
            </th>
            <th class="w-1 text-nowrap">Start date</th>
            <th class="w-1 text-nowrap">Is after</th>
            <th class="w-1 text-nowrap">Days before/after</th>
            <th>Name</th>
            <th class="w-1 text-nowrap text-center">Repeat every X days</th>
          </tr>
        </thead>

        <tbody v-if="items.length !== 0">
          <tr v-for="item of items" :key="item.id">
            <td v-if="!readOnly && !isTemplateMode && !clientId" class="text-center">
              <InputField
                :id="`careProtocolItem-assesment::${item.id}`"
                type="checkbox"
                checkboxSwitchType="success"
                v-model="item.enabled"
                @change="
                  () => {
                    doctorHasChanges = true
                  }
                "
              />
            </td>
            <td>
              {{ item.start_date_type_display }}
            </td>
            <td class="text-center">
              {{ item.post_op ? 'Yes' : 'No' }}
            </td>
            <td class="text-center">
              {{ item.shift_in_days }}
            </td>
            <td>
              {{ item.assesment.title }}
            </td>
            <td class="text-center">
              {{ item.repeat_every_x_days }}
            </td>
          </tr>
        </tbody>

        <tbody v-else>
          <tr>
            <td class="text-center" colspan="100">No entries</td>
          </tr>
        </tbody>
      </table>
    </div>

    <Loader v-else />

    <AssesmentEntriesDialog
      ref="assesmentEntriesDialog"
      :careProtocolId="careProtocolId"
      :isTemplateMode="isTemplateMode"
      :careProtocolMode="careProtocolMode"
      @save="setItems"
    />

    <GlobalWait ref="globalWait" title="Saving assesment list" message="Saving assesment list..." />
  </div>
</template>

<style lang="stylus" scoped>
.edit-button {
  min-width: 10em;
}
</style>

<script>
import moment from 'moment-timezone';
import Loader from '@/components/Loader';
import GlobalWait from '@/components/GlobalWait';
import AssesmentEntriesDialog from './components/AssesmentEntriesDialog';
import InputField from '@/components/InputField';

export default {
  data() {
    return {
      loading: true,
      items: [],
      doctorHasChanges: false,
    };
  },
  filters: {
    formatDate(date) {
      if (!date) {
        return 'No data';
      }
      return moment(date).format('MMM D, YYYY h:mm a');
    },
  },
  methods: {
    edit() {
      this.$refs.assesmentEntriesDialog.show(this.items);
    },

    setItems(items) {
      this.items = items;
    },

    async saveDoctorChanges() {
      this.$refs.globalWait.start();
      const disabledItems = this.items
        .filter((item) => {
          return item.enabled === false;
        })
        .map((item) => {
          return item.id;
        });
      const response = await this.$api.saveCpAssesments({
        clientId: this.clientId,
        careProtocolId: this.careProtocolId,
        userId: this.doctorId,
        disabledItems,
      });
      this.entries = response.cp_assesments;
      this.doctorHasChanges = false;
      this.$refs.globalWait.stop();
    },
  },

  async mounted() {
    this.items = (
      await this.$api.getCpAssesments({
        clientId: this.clientId,
        careProtocolId: this.careProtocolId,
        userId: this.doctorId,
      })
    ).cp_assesments;
    this.loading = false;
  },

  components: {
    Loader,
    AssesmentEntriesDialog,
    InputField,
    GlobalWait,
  },

  props: {
    careProtocolId: {
      type: Number,
      required: true,
    },
    clientId: {
      type: Number,
      required: false,
    },
    readOnly: {
      type: Boolean,
      required: false,
    },
    doctorId: {
      type: Number,
      required: false,
    },
    isTemplateMode: {
      type: Boolean,
      required: false,
    },
    careProtocolMode: {
      type: Boolean,
      required: false,
    },
  },
};
</script>

<template>
  <div class="controls-wrapper"  ref="wrapper">
    <div
      v-if="showInitialControls"
    >
      <button
        type="button"
        class="btn btn-success icon-button icon-button-text waves-effect"
        @click="$emit('joinVideo')"
        :disabled="this.mode === videoSettings.constants.VIDEO_MODE_JOINING"
      >
        <span v-if="this.mode === videoSettings.constants.VIDEO_MODE_INITIAL">
          <i class="fas fa-phone mr-2"></i>
          Join Meeting
        </span>
        <span v-else>
          Joining ...
        </span>
      </button>
    </div>

    <div
      v-if="showInMeetingControls"
    >
      <button
        v-if="audioDeviceWorking"
        type="button"
        class="btn icon-button waves-effect"
        :class="{
          'btn-info': !audioMuted,
          'btn-primary': audioMuted,
        }"
        @click="$emit('toggleMicrophone')"
        title='Enable/disable microphone'
      >
        <i
          class="fas"
          :class="{
            'fa-microphone-slash': !audioMuted,
            'fa-microphone': audioMuted,
          }"
        ></i>
      </button>

      <button
        v-else
        type="button"
        class="btn btn-danger icon-button waves-effect btn-outline-dashed tlp tlp-show"
        @click="showAudioProblem"
        title='Microphone not capturing audio'
        data-placement="top"
      >
        <i
          class="fas fa-microphone-slash"
        ></i>
      </button>


      <button
        type="button"
        class="btn btn-danger icon-button waves-effect"
        @click="$emit('leaveVideo')"
        title='Leave meeting'
      >
        <i class="fas fa-phone-slash"></i>
      </button>

      <button
        v-if="videoDeviceWorking"
        type="button"
        class="btn icon-button waves-effect"
        :class="{
          'btn-info': !videoMuted,
          'btn-primary': videoMuted,
        }"
        @click="$emit('toggleVideo')"
        title='Enable/disable video'
      >
        <i
          class="fas"
          :class="{
            'fa-video-slash': !videoMuted,
            'fa-video': videoMuted,
          }"
        ></i>
      </button>

      <button
        v-else
        type="button"
        class="btn btn-danger icon-button waves-effect btn-outline-dashed"
        @click="showVideoProblem"
        title='Camera not capturing video'
      >
        <i
          class="fas fa-video-slash"
        ></i>
      </button>

      <button
        v-if="showSendCallNotification"
        type="button"
        class="btn btn-success icon-button waves-effect"
        @click="notifyUserAboutCall"
        :disabled="!sendNotificationEnabled"
        title='Notify user about meeting (can be sent once in 30 sec)'
      >
        <i
          class="fas fa-bell"
        ></i>
      </button>
    </div>

    <SimpleMessage
      ref="messageDialog"
    />
  </div>
</template>


<style lang="stylus" scoped>
.controls-wrapper
  position absolute
  left 0
  right 0
  bottom 20px
  background-color transparent
  z-index 2
  text-align center

.icon-button-text
  padding-left 14px !important
  padding-right 14px !important

@media (max-width: 800px)
  .controls-wrapper
    bottom 10px

.icon-button
  margin 4px 7px
  padding 8px

@media screen and (min-width: 800px)
  .icon-button
    min-width 5em
    font-size 150%

@media (max-width: 800px)
  .controls-wrapper

  .icon-button
    min-width 4em
    font-size 120%
</style>


<script>
import videoSettings from '../utils/videoSettings';
import SimpleMessage from '@/components/dialogs/SimpleMessage';


export default {
  data() {
    return {
      videoSettings,

      sendNotificationEnabled: true,
      sendCallNotificationTimer: null,
    };
  },

  computed: {
    showInitialControls() {
      return (
        [
          videoSettings.constants.VIDEO_MODE_INITIAL,
          videoSettings.constants.VIDEO_MODE_JOINING,
        ].indexOf(this.mode) !== -1
      );
    },

    showInMeetingControls() {
      return this.mode === videoSettings.constants.VIDEO_MODE_IN_MEETING;
    },

    audioDeviceWorking() {
      return this.audioEnabled;
    },

    videoDeviceWorking() {
      return this.videoEnabled;
    },

    showSendCallNotification() {
      return (
        // for now only staff can send notification
        (this.remoteUser.type === 'client') && (
          (this.connectedUsers.indexOf(this.localUser) === -1)
          || (this.connectedUsers.indexOf(this.remoteUser) === -1)
        )
      );
    },
  },

  methods: {
    showAudioProblem() {
      this.$refs.messageDialog.show(
        'Problem with audio input',
        !this.audioEnabled
          ? 'Audio device not available. '
              + 'Please make sure audio device is connected, enabled, not in use by any other '
              + 'application.'
          : 'Audio input volume is 0.',
      );
    },

    showVideoProblem() {
      this.$refs.messageDialog.show(
        'Problem with video input',
        'Video device not available. '
          + 'Please make sure video device is connected, enabled, not in use by any other '
          + 'application.',
      );
    },

    notifyUserAboutCall() {
      this.sendNotificationEnabled = false;
      this.sendCallNotificationTimer = setTimeout(() => {
        this.sendNotificationEnabled = true;
      }, 3000);

      this.$emit('notifyUserAboutCall');
    },
  },

  destroyed() {
    if (this.sendCallNotificationTimer) {
      this.sendCallNotificationTimer.clear();
    }
  },

  props: {
    mode: {
      type: String,
      default: videoSettings.constants.VIDEO_MODE_INITIAL,
    },
    audioMuted: {
      type: Boolean,
      default: false,
    },
    videoMuted: {
      type: Boolean,
      default: false,
    },
    videoEnabled: {
      type: Boolean,
      default: true,
    },
    audioEnabled: {
      type: Boolean,
      default: true,
    },
    remoteUser: {
      type: Object,
      required: true,
    },
    localUser: {
      type: Object,
      required: true,
    },
    connectedUsers: {
      type: Array,
      required: true,
    },
    meetingData: {
      type: Object,
      required: true,
    },
  },

  components: {
    SimpleMessage,
  },
};
</script>

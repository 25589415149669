<template>
  <div
    class="h-drawer-max60 h-no-padding h-no-footer AssessmentClient
"
  >
    <SlottedMessageInDrawer :key="title" ref="slottedMessage" modalSize="lg" :title="title">
      <template v-slot:modal-header><div></div></template>
      <template v-slot:modal-body>
        <div v-if="item">
          <AssessmentClient
            :client="client"
            :assessmentId="item.assessment_id"
            :canEdit="canEdit"
            :filterTitle="item.title"
            @close="close"
          />
        </div>
      </template>
    </SlottedMessageInDrawer>
  </div>
</template>

<script>
import SlottedMessageInDrawer from '@/components/dialogs/SlottedMessageInDrawer';
import AssessmentClient from '../AssessmentClient';

export default {
  data() {
    return {
      title: null,
      item: null,
      canEdit: true,
    };
  },

  methods: {
    show(item) {
      this.item = item;
      this.title = item.title;
      this.$nextTick(() => {
        this.$refs.slottedMessage.show();
      });
    },

    close() {
      this.$refs.slottedMessage.close();
    },
  },
  mounted() {},

  props: {
    client: {
      type: Object,
      required: true,
    },
  },

  components: {
    SlottedMessageInDrawer,
    AssessmentClient,
  },
};
</script>

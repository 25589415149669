<template>
  <div class="h-bg-w h-margin-top-l h-padding-top-l">
    <div class="h-margin-left-l h-margin-right-l h-align-items-center h-space-between">
      <h3 class="h-h3 h-primary_shade_1">Patient notes</h3>
      <div class="h-h4">
        <button
          type="button"
          class="h-btn h-btn-accent"
          @click="
            () => {
              $refs.singleEditDialog.show();
            }
          "
        >
          <div class="h-align-items-center">
            <i class=" h-secondary_shade_5 healenticons-plus h-padding-right-s"></i>Add
          </div>
        </button>
      </div>
    </div>
    <div class="h1-table">
      <table class="table dataTable checklist mt-3 xs-title-content h-table-max-15">
        <thead class="thead-light">
          <tr>
            <th class="text-right" style="width: 5%">
              <span>Date</span>
            </th>
            <th class="text-left" style="width: 10%">
              <span>Physician Name</span>
            </th>
            <th class="text-left" style="width: 80%"><span>Note</span></th>
            <th class="text-left" style="width: 4%">
              <span> {{ client.surgeon ? client.surgeon.name : "Surgeon" }} notified </span>
            </th>
            <th class="text-left" style="width: 1%">
              <span>Action</span>
            </th>
          </tr>
        </thead>

        <tbody v-if="client.communication_entries && client.communication_entries.length">
          <tr v-for="note of client.communication_entries" :key="note.id" class="">
            <td class="text-right">
              {{ note.date | formatDate }}
            </td>
            <td class="text-left">
              <div>{{ note.providerName ? note.providerName.trim() : "" }}</div>
            </td>
            <td class="text-left">
              <TruncateText
                :data-tooltip="note.note.trim()"
                :text="note.note ? note.note.trim() : ''"
              />
            </td>
            <td class="text-left">
              <div class="toggle-center" @click="toggleNoteSurgeonNotified(note)">
                <InputField type="toggle" v-model="note.surgeonNotified" :readonly="true" />
              </div>
            </td>
            <td class="text-left">
              <a
                href="javascript:void(0);"
                @click="
                  () => {
                    $refs.singleEditDialog.show(note);
                  }
                "
              >
                <i class="fas fa-pen font-16 cursor-pointer" title="Edit"></i>
              </a>

              <a
                href="javascript:void(0);"
                @click="
                  () => {
                    $refs.singleDeleteDialog.show(note);
                  }
                "
              >
                <i class="fas fa-trash-alt h-dangerous font-16 cursor-pointer" title="Delete"></i>
              </a>
            </td>
          </tr>
        </tbody>

        <tbody v-else>
          <tr>
            <td colspan="1000" class="h-h4 h-secondary_shade_1 h-text-center">No records</td>
          </tr>
        </tbody>
      </table>

      <SingleEditDialog
        ref="singleEditDialog"
        :client="client"
        @update="updateCommunicationNotes"
      />

      <SingleDeleteDialog
        ref="singleDeleteDialog"
        :client="client"
        @update="updateCommunicationNotes"
      />
    </div>
  </div>
</template>

<style lang="stylus" scoped>
.edit-button {
  min-width: 10em;
}
</style>

<script>
import moment from 'moment-timezone';
import SingleEditDialog from './SingleEditDialog';
import SingleDeleteDialog from './SingleDeleteDialog';
import InputField from '@/components/InputField';
import TruncateText from '@/components/TruncateText';

export default {
  components: {
    SingleEditDialog,
    SingleDeleteDialog,
    InputField,
    TruncateText,
  },
  filters: {
    formatDate(date) {
      if (!date) {
        return 'No data';
      }
      return moment(date).format('MMM D, YYYY');
    },
  },
  methods: {
    updateCommunicationNotes(communicationNotes) {
      this.client.communication_entries = communicationNotes;
      this.$forceUpdate();
    },

    async toggleNoteSurgeonNotified(note) {
      const payloadNote = JSON.parse(JSON.stringify(note));
      payloadNote.surgeonNotified = !payloadNote.surgeonNotified;
      await this.$api.saveClientCommunicationNote({
        client_id: this.client.id,
        communication_entry: note,
      });
      note.surgeonNotified = payloadNote.surgeonNotified;
    },
  },

  props: {
    client: {
      type: Object,
      required: true,
    },
  },
};
</script>

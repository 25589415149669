<template>
  <tr>
    <td>
      <multiselect
        v-model="selectedStartDateType"
        :options="startDateTypeOptions"
        :placeholder="'Select start date'"
        :multiple="false"
        track-by="value"
        :id="`startDateType-${item.KEY}`"
      >
        <template v-slot:option="{ option }">
          {{ option.label }}
        </template>
        <template v-slot:singleLabel="{ option }">
          {{ option.label }}
        </template>
      </multiselect>
    </td>
    <td class="h-text-left">
      <InputField
        type="checkbox"
        checkboxSwitchType="success"
        :id="item.KEY"
        v-model="item.post_op"
      />
    </td>
    <td class="h-text-right">
      <input
        type="number"
        min="0"
        class="h-input"
        v-model="item.shift_in_days"
        placeholder="Enter days before/after"
      />
    </td>
    <td style="position: relative">

      <multiselect
        v-if="!item.id"
        v-model="selectedAssessment"
        :options="assessmentOptions"
        :placeholder="'Type to search...'"
        :multiple="false"
        track-by="value"
        :id="assessmentId"
      >
        <template v-slot:option="{ option }">
          {{ option.label }}
        </template>
        <template v-slot:singleLabel="{ option }">
          {{ option.label }}
        </template>
      </multiselect>
      <span class="text-muted" v-else>
        {{ item.assesment.title }}
      </span>
    </td>
    <td class="h-text-right">
      <input
        type="number"
        min="0"
        class="h-input"
        v-model="item.repeat_every_x_days"
        placeholder="Enter Repeat every X days"
      />
    </td>
    <td class="text-center">
      <i
        class="fas fa-trash-alt text-danger font-16"
        title="Remove"
        @click="$emit('remove', item)"
      ></i>
    </td>
  </tr>
</template>

<script>
import Multiselect from 'vue-multiselect';
import InputField from '@/components/InputField';
import 'vue-multiselect/dist/vue-multiselect.min.css';

export default {
  name: 'Row',
  data() {
    return {
      selectedStartDateType: null,
      startDateTypeOptions: [
        { value: 'START_TYPE_PROTOCOL', label: 'Care protocol date' },
        { value: 'START_TYPE_CLIENT_PLAN', label: 'Client plan start' },
        { value: 'START_TYPE_SURGERY_DATE', label: 'Surgery date' },
      ],
      selectedAssessment: null,
      assessmentOptions: [],
      assessmentLoaded: false,
    };
  },
  components: {
    InputField,
    Multiselect,
  },

  props: {
    item: {
      type: Object,
      required: true,
    },
  },

  computed: {
    assessmentId() {
      return `assessmentRow-${this.item.KEY}`;
    },
  },

  watch: {
    selectedStartDateType(newValue) {
      this.item.start_date_type = newValue ? newValue.value : null;
    },
    selectedAssessment(newValue) {
      if (newValue) {
        this.$emit('update-assessment', { key: this.item.KEY, assessmentId: newValue.value });
      }
    },
    item: {
      immediate: true,
      handler(newItem) {
        this.initializeSelectedAssessment();
      },
    },
    assessmentLoaded(newValue) {
      if (newValue) {
        this.initializeSelectedAssessment();
      }
    },
  },

  methods: {
    getStartDateTypeObject() {
      const startTypeValue = this.item.start_date_type;
      let selectedOption = null;

      switch (startTypeValue) {
        case 'START_TYPE_PROTOCOL':
          selectedOption = this.startDateTypeOptions[0];
          break;
        case 'START_TYPE_CLIENT_PLAN':
          selectedOption = this.startDateTypeOptions[1];
          break;
        case 'START_TYPE_SURGERY_DATE':
          selectedOption = this.startDateTypeOptions[2];
          break;
      }

      return selectedOption;
    },

    initializeSelectedAssessment() {
      if (this.assessmentOptions.length > 0 && this.item.assesment_id !== undefined) {
        const matchingAssessment = this.assessmentOptions.find(
          assessment => assessment.value === this.item.assesment_id,
        );
        this.selectedAssessment = matchingAssessment || null;
      }
    },
    fetchAssessments() {
      this.$api
        .getAssesments()
        .then((result) => {
          this.assessmentOptions = result.assesments.map(item => ({
            value: item.id,
            label: item.title,
          }));
          this.initializeSelectedAssessment();
        })
        .catch((error) => {
          console.error('Failed to fetch assessments:', error);
        });
    },
  },

  mounted() {
    this.selectedStartDateType = this.getStartDateTypeObject();
    this.fetchAssessments();
  },
};
</script>

<template>
  <div
    class="h-text-center" :style="{ margin: margin, padding: padding }"
    :class="{
      loader: useLoaderClass,
    }"
  >
    <div
      class="spinner-border"
      :class="'text-' + color"
      role="status"
      :style="{ width: size, height: size }"
    ></div>
    <p
      class="h-h6"
      :class="'text-' + textColor"
      :style="{ 'margin-top': pMarginTop }"
    >
      {{ message }}
    </p>
  </div>
</template>


<script>
export default {
  name: 'Loader',
  props: {
    message: {
      type: String,
      default: 'Please wait...',
    },
    color: {
      type: String,
      default: 'primary',
    },
    textColor: {
      type: String,
      default: 'muted',
    },
    margin: {
      type: String,
      default: '2.500vw',
    },
    padding: {
      type: String,
      default: '0vw',
    },
    size: {
      type: String,
      default: '30rem',
    },
    pMarginTop: {
      type: String,
      default: '1.250vw',
    },
    useLoaderClass: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

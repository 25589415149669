<template>
  <div class="h-drawer-max500">
    <Drawer :direction="'right'" :exist="true" ref="modalDialog">
      <div class="h-drawer-card">
        <div class="h-drawer-card-header">
          <div class="h-main-drawer-card-header h-margin-bottom-l">
            <h3 class="h-h3 h-primary_shade_1">
              <slot name="modal-title">{{
                isEditMode ? "Edit Campaign" : "New Campaign"
              }}</slot>
            </h3>
            <button
              type="button"
              class="close"
              aria-hidden="true"
              @click="close"
              :disabled="loading"
            >
              <img src="/assets/images/Collapse-drawer.svg" alt="" />
            </button>
          </div>
        </div>
        <div class="h-drawer-card-body">
          <div v-if="!loading">
            <div>
              <label class="h-label">Title</label>
              <InputField
                id="name"
                type="text"
                v-model="title"
                :class="{ 'error-class': formSubmitted && !title }"
              />
            </div>

            <div class="h-margin-top-l">
              <label class="h-label">Topic</label>
              <div
                :class="{ 'error-class': formSubmitted && !selectedTopic }"
                class="h-d-flex h-loop-radio"
              >
                <div
                  class="h-custom-radio-btn"
                  :class="{ 'h-radio-gray': !selectedTopic }"
                  v-for="topic in topics"
                  :key="topic"
                >
                  <div class="h-custom-checkbox">
                    <input
                      type="radio"
                      :id="topic"
                      :value="topic"
                      v-model="selectedTopic"
                      name="topicGroup"
                      class="custom-control-input"
                    />
                    <label
                      :for="topic"
                      class="custom-control-label cursor-pointer"
                      >{{ topic }}</label
                    >
                  </div>
                </div>
              </div>
            </div>
            <div class="h-margin-top-l">
              <label class="h-label">Trigger</label>
              <div
                :class="{ 'error-class': formSubmitted && !selectedTrigger }"
                class="h-d-flex h-loop-radio"
              >
                <div
                  class="h-custom-radio-btn"
                  :class="{ 'h-radio-gray': !selectedTrigger }"
                  v-for="trigger in triggers"
                  :key="trigger"
                >
                  <div class="h-custom-checkbox">
                    <input
                      type="radio"
                      :id="trigger"
                      :value="trigger"
                      v-model="selectedTrigger"
                      name="triggerGroup"
                      class="custom-control-input"
                    />
                    <label
                      :for="trigger"
                      class="custom-control-label cursor-pointer"
                      >{{ trigger }}</label
                    >
                  </div>
                </div>
              </div>
            </div>
            <div class="h-margin-top-l">
              <div
                class="h-space-between h-align-items-center h-margin-bottom-s"
              >
                <label class="h-label">Cadence</label>
                <i
                  style="line-height: 1; font-size: 10rem"
                  class="h-accent healenticons-plus h-btn-with-icon h-padding-right-s"
                  @click="addNewCadence"
                ></i>
              </div>

              <div class="h1-table h-w-100">
                <table
                  class="table dataTable
                  h-table-topbordar
                  h-noborder-input
                  h-margin-bottom-0
                  h-cadence-table"
                >
                  <tbody v-for="(cadence, index) in cadences" :key="index">
                    <tr>
                      <td
                        style="width: 10%"
                        :class="{
                          'error-class': formSubmitted && !cadence.when,
                        }"
                      >
                        <InputField
                          type="select"
                          v-model="cadence.when"
                          :choices="cadenceChoices"
                        />
                      </td>
                      <td
                        style="width: 4%"
                        :class="{
                          'error-class': formSubmitted && !cadence.number,
                        }"
                      >
                        <InputField
                          type="text"
                          v-model="cadence.number"
                          placeholder="Number"
                        />
                      </td>
                      <td
                        style="width: 10%"
                        :class="{
                          'error-class': formSubmitted && !cadence.unit,
                        }"
                      >
                        <InputField
                          type="select"
                          v-model="cadence.unit"
                          :choices="formattedCadenceUnits"
                        />
                      </td>
                      <td
                        style="width: 20%"
                        :class="{
                          'error-class': formSubmitted && !cadence.channel,
                        }"
                      >
                        <InputField
                          type="select"
                          v-model="cadence.channel"
                          :choices="formattedCadenceChannels"
                        />
                      </td>
                      <td style="width: 1%" class="h-text-center">
                        <i
                          class="fas fa-trash-alt text-danger font-16 cursor-pointer"
                          title="Delete"
                          @click="deleteCadence(index)"
                        ></i>
                      </td>
                    </tr>
                    <tr>
                      <td
                        colspan="5"
                        style="width: 55%; padding: 0 !important"
                        :class="{
                          'error-class': formSubmitted && !cadence.content,
                        }"
                      >
                        <div
                          class="h-ck100 h-toolbar h-drawer-maxfull h-edu text-campaign"
                        >
                          <CKEditor
                            v-model="cadence.content"
                            :config="editorConfig"
                            :editor="editor"
                          />
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="h-margin-top-l h-margin-bottom-xl widgetListWrapper">
              <InputField
                type="toggle"
                id="Status"
                :label="campaignStatusLabel"
                class="h-fix-checkbox"
                v-model="status"
              />
            </div>
          </div>

          <Loader v-else />
        </div>

        <div class="h-drawer-card-footer">
          <button
            type="button"
            class="h-btn h-btn-accent"
            @click="confirm"
            :disabled="loading"
          >
            Confirm
          </button>

          <button
            type="button"
            class="h-btn h-btn-dangerous h-margin-left-s"
            @click="close"
            :disabled="loading"
          >
            Cancel
          </button>
        </div>
      </div>
    </Drawer>
  </div>
</template>

<script>
import CKEditor from '@ckeditor/ckeditor5-vue2';
import Loader from '@/components/Loader';
import InputField from '@/components/InputField';
import * as customEditor from '../../../../../public/assets/js/ckeditor';

export default {
  props: {
    editCampaignData: {
      type: Object,
      default: () => null,
    },
  },
  data() {
    return {
      loading: false,
      error: '',
      topics: [
        'Vitals',
        'Health',
        'PROMs',
        'Appointments',
        'PT',
        'Goals',
        'Payments',
      ],
      triggers: ['Activated', 'Missed', 'Completed'],
      title: '',
      selectedTopic: null,
      selectedTrigger: null,
      status: true,
      isEditMode: false,
      cadenceUnits: [null, 'day', 'hr', 'min', 'sec'],
      cadenceChannels: [
        null,
        'SMS',
        'Push notification',
        'Robo call',
        'Voice message',
        'In-app pop-up (provider)',
        'In-app pop-up (nurse)',
        'In-app message',
        'Email',
      ],
      cadences: [
        {
          when: null,
          number: '',
          unit: null,
          channel: null,
          content: '',
        },
      ],
      formSubmitted: false,
      editor: customEditor,
      editorConfig: {
        fontFamily: {
          options: [
            'default',
            'Arial, Helvetica, sans-serif',
            'Courier New, Courier, monospace',
            'Georgia, serif',
            'Lucida Sans Unicode, Lucida Grande, sans-serif',
            'Tahoma, Geneva, sans-serif',
            'Times New Roman, Times, serif',
            'Trebuchet MS, Helvetica, sans-serif',
            'Verdana, Geneva, sans-serif',
          ],
        },
        toolbar: {
          items: [
            'heading',
            '|',
            'alignment',
            '|',
            'bold',
            'italic',
            'strikethrough',
            'underline',
            'subscript',
            'superscript',
            '|',
            'link',
            '|',
            'bulletedList',
            'numberedList',
            'todoList',
            '-',
            'fontfamily',
            'fontsize',
            'fontColor',
            'fontBackgroundColor',
            '|',
            'code',
            'codeBlock',
            '|',
            'insertTable',
            '|',
            'outdent',
            'indent',
            '|',
            'imageInsert',
            'blockQuote',
            '|',
            'undo',
            'redo',
          ],
          shouldNotGroupWhenFull: true,
        },
        image: {
          toolbar: [
            'imageStyle:full',
            'imageStyle:side',
            '|',
            'imageTextAlternative',
          ],
        },
        table: {
          contentToolbar: ['tableColumn', 'tableRow', 'mergeTableCells'],
        },
        language: 'en',
        licenseKey: '',
        wordCount: {
          onUpdate: (stats) => {
            this.charactersLength = stats.characters;
          },
        },
      },
    };
  },
  watch: {
    editCampaignData(newVal) {
      if (newVal) {
        this.title = newVal.title;
        this.selectedTopic = newVal.topic;
        this.selectedTrigger = newVal.trigger;
        this.cadences = newVal.cadences;
        this.status = newVal.status === 'Active';
        this.isEditMode = true;
      } else {
        this.resetForm();
        this.isEditMode = false;
      }
    },
  },
  created() {
    this.trigger = this.editCampaignData ? this.editCampaignData.trigger : null;
  },
  computed: {
    formattedCadenceUnits() {
      return this.cadenceUnits.map(unit => [
        unit,
        unit === null ? 'Time unit' : unit,
      ]);
    },
    formattedCadenceChannels() {
      return this.cadenceChannels.map(channel => [
        channel,
        channel === null ? 'Channel' : channel,
      ]);
    },
    cadenceChoices() {
      let choices = [null, 'Before', 'After'];
      if (this.selectedTrigger === 'Active') {
        choices = [null, 'Before', 'After'];
      } else if (
        this.selectedTrigger === 'Missed' ||
        this.selectedTrigger === 'Completed'
      ) {
        choices = [null, 'After'];
      }
      return choices.map(choice => [
        choice,
        choice === null ? 'When?' : choice,
      ]);
    },
    campaignStatusLabel() {
      const label = `Campaign status (${this.status ? 'active' : 'inactive'})`;
      return label;
    },
  },

  methods: {
    resetForm() {
      this.title = '';
      this.topic = null;
      this.trigger = null;
      this.cadences = [];
      this.status = false;
    },
    addNewCadence() {
      this.cadences.push({
        when: null,
        number: '',
        unit: null,
        channel: null,
        content: '',
      });
    },
    deleteCadence(index) {
      this.cadences.splice(index, 1);
    },
    addNewCampaign(campaignData) {
      this.campaigns.push(campaignData);
    },
    async show(hospitalId) {
      this.loading = true;
      this.error = '';
      this.$refs.modalDialog.open();
      this.$refs.modalDialog.active = true;
      $(this.$refs.modalDialog.el).modal({
        backdrop: 'static',
        keyboard: false,
      });
      $('.modal-backdrop').last().insertBefore(this.$refs.modalDialog.el);
      this.loading = false;
    },

    close(force) {
      this.$refs.modalDialog.close();
      this.$refs.modalDialog.active = false;
      if (!force && this.loading) return;
      $(this.$refs.modalDialog.el).modal('hide');
    },

    async confirm() {
      this.loading = true;
      this.formSubmitted = true;
      if (!this.title.trim()) {
        $.notify('Please enter a title', {
          position: 'top center',
          className: 'error',
        });
        this.loading = false;
        return;
      }
      if (!this.selectedTopic) {
        $.notify('Please select a topic', {
          position: 'top center',
          className: 'error',
        });
        this.loading = false;
        return;
      }
      if (!this.selectedTrigger) {
        $.notify('Please select a trigger', {
          position: 'top center',
          className: 'error',
        });
        this.loading = false;
        return;
      }
      const isCadenceValid = this.cadences.some((cadence) => {
        return (
          cadence.when &&
          cadence.number.trim() &&
          cadence.unit &&
          cadence.channel &&
          cadence.content.trim()
        );
      });

      if (!isCadenceValid) {
        $.notify('Please fill in at least one complete cadence', {
          position: 'top center',
          className: 'error',
        });
        this.loading = false;
        return;
      }

      this.formSubmitted = false;
      const campaignData = {
        title: this.title,
        topic: this.selectedTopic,
        trigger: this.selectedTrigger,
        cadences: this.cadences,
        status: this.status ? 'Active' : 'Inactive',
      };

      if (this.isEditMode) {
        localStorage.setItem('editCampaign', JSON.stringify(campaignData));
        this.$emit('update-campaign', this.editCampaignData.id, campaignData);
      } else {
        console.log(campaignData);
        localStorage.setItem('newCampaign', JSON.stringify(campaignData));
        this.$emit('new-campaign-added', campaignData);
      }

      this.resetForm();
      this.close(true);
    },
  },

  components: {
    Loader,
    InputField,
    CKEditor: CKEditor.component,
  },
};
</script>

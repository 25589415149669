<template>
  <div :class="getWrapperClass()">
    <input
      :value="value"
      :id="id"
      v-if="
        ['text', 'password', 'search', 'date', 'tel', 'time', 'datetime', 'number'].indexOf(
          type
        ) !== -1
      "
      :type="type"
      class="form-control h-input"
      :class="{
        'is-invalid': error,
        'form-control-sm': dense,
        ...classExtra
      }"
      :disabled="disabled"
      :allowPasting="true"
      @input="onInput"
      @change="onChange"
      @keydown="onKeyDown"
      @keyup="onKeyUp"
      @keypress="onKeyPress"
      @paste="onPaste"
      :step="step"
      :min="min"
      :max="max"
      :maxlength="maxlength"
      :readonly="readonly"
      :placeholder="placeholder"
      :name="name"
      :autocomplete="autocomplete"
    />
    <div
      v-if="type === 'toggle'"
      class="h-d-flex h-align-items-center h-margin-bottom-m h-space-between"
    >
      <label
        class="h-cursor-pointer h-text-label h-d-flex h-align-items-center h-space-between h-w-100"
        >{{ label }}
        <label v-if="type === 'toggle'" class="h-toggle" :for="id">
          <input
            v-if="type === 'toggle'"
            :value="value"
            :id="id"
            type="checkbox"
            class="h-input"
            :class="{
              'is-invalid': error,
              'form-control-sm': dense,
              ...classExtra
            }"
            :disabled="disabled"
            @input="onInput"
            @change="onChange"
            :checked="value"
            :readonly="readonly"
            v-model="value"
          />
          <span class="h-slider"></span>
        </label>
      </label>
    </div>

    <div
      v-if="type === 'radio'"
      class="h-d-flex h-align-items-center h-margin-bottom-m h-space-between"
    >
      <h4 class="h-text-label">{{ label }}</h4>
      <label v-if="type === 'radio'" class="h-toggle" :for="id">
        <input
          v-if="type === 'radio'"
          :value="value"
          :id="id"
          type="checkbox"
          class="h-inputddd"
          :class="{
            'is-invalid': error,
            'form-control-sm': dense,
            ...classExtra
          }"
          :disabled="disabled"
          @input="onInput"
          @change="onChange"
          :checked="value"
          :readonly="readonly"
        />
        <span class="h-slider"></span>
      </label>
    </div>

    <div class="h-custom-checkbox">
      <input
        v-if="type === 'checkbox'"
        :value="value"
        :id="id"
        type="checkbox"
        class="custom-control-input"
        :class="{
          'is-invalid': error,
          'form-control-sm': dense,
          ...classExtra
        }"
        :disabled="disabled"
        @input="onInput"
        @change="onChange"
        :checked="value"
        :readonly="readonly"
      />
      <label
        v-if="type === 'checkbox'"
        class="custom-control-label cursor-pointer"
        :class="labelClass"
        :for="id"
        >{{ label }}</label
      >
    </div>

    <select
      ref="theElement"
      :id="id"
      v-if="type === 'select'"
      class="form-control h-input"
      :class="{
        'is-invalid': error,
        'form-control-sm': dense,
        'select2 select2-multiple': multiple,
        ...classExtra
      }"
      :disabled="disabled"
      :multiple="multiple"
      @input="onInput"
      @change="onChange"
      @click.stop="onClick"
      :readonly="readonly"
    >
      <option
        v-for="choice of processedChoices"
        :key="choice.value"
        :value="choice.value"
        :selected="isOptionSelected(choice.value)"
      >
        {{ choice.title }}
      </option>
    </select>

    <div
      v-if="type === 'file'"
      class="form-group form-group-file h-input-file"
      :class="{
        'is-invalid': error
      }"
    >
      <div class="custom-file">
        <input
          type="file"
          class="custom-file-input"
          :class="{ 'is-invalid': error }"
          :id="id"
          :disabled="disabled"
          @input="onInput"
          @change="onChange"
          :readonly="readonly"
          :accept="accept"
        />
        <label class="custom-file-label form-control" :for="id">
          {{ getFileLabel() }}
        </label>
      </div>
    </div>

    <textarea
      :value="value"
      :rows="rows"
      :id="id"
      v-if="type === 'textarea'"
      class="form-control h-input"
      :class="{
        'is-invalid': error,
        'form-control-sm': dense,
        'padding-right-0': paddingRight,
        ...classExtra
      }"
      :disabled="disabled"
      @input="onInput"
      @change="onChange"
      :readonly="readonly"
      :maxlength="maxlength"
      :placeholder="placeholder"
    ></textarea>

    <div class="invalid-feedback" v-if="error && error !== true">
      {{ error }}
    </div>
  </div>
</template>

<style lang="stylus" scoped>
input[type=file].form-control {
  border: none;
}
.form-group-file {
  margin: 0px;
}

.is-invalid~.invalid-feedback {
  display: block;
}

.padding-right-5 {
  padding-right: 5rem;
}

.padding-right-0 {
  padding-right: 0 !important;
}
</style>

<script>
export default {
  methods: {
    getWrapperClass() {
      const result = {
        'custom-control': this.type === 'checkbox',
        'custom-toggle': this.type === 'checkbox',
        'custom-radio': this.type === 'checkbox',
        'custom-checkbox': !this.checkboxSwitchType,
        'custom-switch': this.checkboxSwitchType,
        'padding-right-5': this.type === 'textarea' && this.paddingRight,
      };
      if (this.checkboxSwitchType) {
        result[`switch-${this.checkboxSwitchType}`] = true;
        result['switch-disabled'] = this.disabled;
      }
      return result;
    },

    onChange(event) {
      // for each processedChoices check if it is selected
      event.input_type = this.input_type;
      if (this.type === 'select') {
        // check if value exists in choice object
        const isOptionSelected = choice => this.isOptionSelected(choice.value);
        const selectedOption = this.processedChoices.find(isOptionSelected);
        // add selected option to event data
        event.selectedOption = selectedOption;
      }
      this.$emit('change', event);
    },

    onClick(event) {
      this.$emit('click', event);
    },

    onPaste(event) {
      if (!this.allowPasting) event.preventDefault();
    },

    onKeyPress(event) {
      this.$emit('keypress', event);
    },

    onKeyDown(event) {
      this.$emit('keydown', event);
    },

    onKeyUp(event) {
      this.$emit('keyup', event);
    },

    onInput(event) {
      if (this.type === 'checkbox' || this.type === 'toggle') {
        this.$emit('input', event.target.checked);
      } else if (this.type === 'file') {
        if (event.target.files.length) {
          this.$emit('input', event.target.files[0]);
        } else {
          this.$emit('input', null);
        }

        this.$forceUpdate();
      } else if (this.type === 'select' && this.multiple) {
        this.$emit('input', $(event.target).val());
      } else {
        this.$emit('input', event.target.value);
      }
    },

    isOptionSelected(optionValue) {
      if (!this.multiple) {
        if (typeof optionValue === 'number' && typeof this.value === 'string') {
          return this.value === optionValue.toString();
        }
        return this.value === optionValue;
      }
      return this.value.indexOf(optionValue) !== -1;
    },

    getFileLabel() {
      if (this.value) {
        return this.value.name;
      }
      return 'Choose file';
    },
  },

  computed: {
    processedChoices() {
      return this.choices.map((choice) => {
        if (Array.isArray(choice)) {
          return {
            value: choice[0],
            title: choice[1],
          };
        }
        if (typeof choice === 'string') {
          return {
            value: choice,
            title: choice,
          };
        }
        return choice;
      });
    },
  },

  mounted() {
    if (this.multiple && this.type === 'select') {
      $(this.$refs.theElement).select2();
      $(this.$refs.theElement).on('change', (e) => {
        this.onChange(e);
        this.onInput(e);
      });
    }
  },

  props: {
    id: {
      type: String,
    },
    name: {
      type: String,
    },
    autocomplete: {
      type: String,
      default: 'true',
    },
    type: {
      type: String,
      default: 'text',
    },
    checkboxSwitchType: {
      type: String,
      default: '',
    },
    choices: {
      type: Array,
      default: null,
    },
    step: {
      type: Number,
    },
    min: {
      type: Number,
    },
    max: {
      type: Number,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    value: {
      type: null,
    },
    error: {
      type: null,
    },
    dense: {
      type: Boolean,
      default: false,
    },
    maxlength: {
      type: Number,
    },
    rows: {
      type: Number,
    },
    label: {
      type: String,
      default: '',
    },
    classExtra: {
      type: Object,
      default: () => {
        return {};
      },
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: '',
    },
    allowPasting: {
      type: Boolean,
      default: true,
    },
    labelClass: {
      default: '',
    },
    paddingRight: {
      type: Boolean,
      default: false,
    },
    accept: {
      type: String,
      default: null,
    },
  },

  data() {
    return {
      selectedOption: 0,
    };
  },
};
</script>

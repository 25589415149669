var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('WidgetFilters',{on:{"reload":_vm.load}}),(_vm.widgets.length !== 0)?_c('div',{staticClass:"h-row insights-loop gap-loop"},_vm._l((_vm.widgets),function(widget){return _c('div',{key:widget.title,class:_vm.getWidgetWrapperClass(widget)},[(widget.type === 'UpcomingSurgeries')?_c('UpcomingSurgeries',{ref:"upcomingSurgeries",refInFor:true,attrs:{"dashinfo":_vm.dashinfo,"loaded":!!_vm.dashinfo}}):_vm._e(),(widget.type === 'SimpleCardNumber')?_c('SimpleCardNumber',{attrs:{"loaded":!!_vm.dashinfo,"iconClass":widget.iconClass},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" "+_vm._s(widget.title)),(widget.unit && widget.showUnitWithTitle)?_c('span',{staticClass:"no-text-transform"},[_vm._v(" ("+_vm._s(widget.unit)+")")]):_vm._e(),(widget.getHelpDialogRef)?_c('i',{staticClass:"fas fa-question-circle h-info-icon h-accent",attrs:{"title":"Click to see more details"},on:{"click":function($event){widget.getHelpDialogRef().show()}}}):_vm._e()]},proxy:true},{key:"content",fn:function(){return [_c('div',{staticClass:"h-margin-top-s"},[_c('span',{staticClass:"h-h2 h-accent"},[_vm._v(_vm._s(widget.getSummeryValue()))]),(
                widget.unit &&
                widget.getSummeryValue() !== '?' &&
                !widget.showUnitWithTitle
              )?_c('small',[_vm._v(" "+_vm._s(widget.unit)+" ")]):_vm._e(),_c('div',{staticClass:"h-h4 h-primary_shade_3 h-margin-top-s"},[(widget.getWeekTrend)?_c('div',{staticClass:"h-h4 h-secondary_shade_1 h-margin-left-xs",staticStyle:{"font-weight":"normal"}},[(widget.getWeekTrend() !== null)?_c('small',{class:{
                    'h-safe': widget.getWeekTrend() > 0,
                    'h-dangerous': widget.getWeekTrend() < 0,
                  }},[_c('i',{class:{
                      'fas fa-long-arrow-alt-up': widget.getWeekTrend() > 0,
                      'fas fa-long-arrow-alt-down': widget.getWeekTrend() < 0,
                    }}),_vm._v(" Week trend: "+_vm._s(widget.getWeekTrend() || "?")+"% ")]):_vm._e(),(widget.getWeekTrend() === null)?_c('span',{staticClass:"h-secondary_shade_1",attrs:{"title":"Not enough data to calculate trending"}},[_c('i',{staticClass:"fas fa-arrows-alt-v"}),_vm._v(" Week trend: ? ")]):_vm._e()]):_vm._e(),(widget.getFullTrend)?_c('div',{staticClass:"h-h4 h-secondary_shade_1 h-margin-left-xs",staticStyle:{"font-weight":"normal"}},[(widget.getFullTrend() !== null)?_c('small',{class:{
                    'h-safe': widget.getFullTrend() > 0,
                    'h-dangerous': widget.getFullTrend() < 0,
                  }},[_c('i',{class:{
                      'fas fa-long-arrow-alt-up': widget.getFullTrend() > 0,
                      'fas fa-long-arrow-alt-down': widget.getFullTrend() < 0,
                    }}),_vm._v(" Full trend: "+_vm._s(widget.getFullTrend() || "?")+"% ")]):_vm._e(),(widget.getFullTrend() === null)?_c('span',{staticClass:"h-secondary_shade_1",attrs:{"title":"Not enough data to calculate trending"}},[_c('i',{staticClass:"fas fa-arrows-alt-v"}),_vm._v(" Full trend: ? ")]):_vm._e()]):_vm._e()])])]},proxy:true}],null,true)}):_vm._e()],1)}),0):_vm._e(),_c('WidgetsSelectorDialog',{ref:"widgetsSelectorDialog",attrs:{"widgets":_vm.widgets,"allWidgets":_vm.allWidgets},on:{"toggleSelectedWidget":_vm.toggleSelectedWidget}}),_c('SlottedMessageInDrawer',{ref:"checklistCompletionDialog",attrs:{"title":"Checklist Completion"},scopedSlots:_vm._u([{key:"modal-body",fn:function(){return [_c('div',{staticClass:"h-max-w-33"},[_c('div',{staticClass:"h-h3 h-primary_shade_1"},[_vm._v(" To calculate checklist completion all active care protocols (care protocols currently being done by clients) are taken and items from the care protocols that should have been completed by now are used in a formular: ")]),_c('div',{staticClass:"h-h3 h-primary_shade_1 h-margin-top-s"},[_c('i',[_vm._v("Number of completed items / All items that should be completed")])])])]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div class="h-drawer-maxfull h-edu">
    <Drawer :direction="'right'" :exist="true" ref="modalDialog">
      <div class="h-drawer-card">
        <div class="h-drawer-card-header">
          <div class="h-main-drawer-card-header h-margin-bottom-l">
            <h3 class="h-h3 h-primary_shade_1">New entry #{{ entryId || "NEW" }}</h3>
            <button
              type="button"
              class="close"
              aria-hidden="true"
              @click="close"
              :disabled="loading"
            >
              <img src="/assets/images/Collapse-drawer.svg" alt="" />
            </button>
          </div>
        </div>
        <div class="h-drawer-card-body">
          <div v-if="!loading && !saving">
            <div class="h-row">
              <div class="col-5 h-padding-left-l h-padding-top-l h-edu-inputs">
                <div class="h-row h-space-between h-padding-right-m">
                  <div v-if="entry.order || !readOnly" class="col-6 h-padding-bottom-l">
                    <label
                      class="h-label h-alert-icons h-d-flex"
                      for="editEducationalEntryDialog-order"
                    >
                      <i
                        class="healenticons-group-2843 h-line-height-1 cursor-pointer"
                        @click="showInfo('order')"
                      ></i>
                      Order
                    </label>
                    <InputField
                      v-if="!readOnly"
                      id="editEducationalEntryDialog-order"
                      type="number"
                      :step="1"
                      v-model="entry.order"
                      :error="errors.order"
                      @input="
                        () => {
                          errors.order = null;
                        }
                      "
                    />
                    <div v-else class="col-form-label text-left">
                      <span v-if="entry.order">
                        {{ entry.order }}
                      </span>
                      <span v-else class="text-muted"> &lt;None&gt; </span>
                    </div>
                  </div>

                  <div v-if="entry.title || !readOnly" class="col-6 h-padding-bottom-l">
                    <label
                      class="h-label h-alert-icons h-d-flex"
                      for="editEducationalEntryDialog-title"
                    >
                      <i
                        class="healenticons-group-2843 h-line-height-1 cursor-pointer"
                        @click="showInfo('title')"
                      ></i>
                      Title
                    </label>
                    <InputField
                      v-if="!readOnly"
                      id="editEducationalEntryDialog-title"
                      type="text"
                      v-model="entry.title"
                      :error="errors.title"
                      @input="
                        () => {
                          errors.title = null;
                        }
                      "
                    />
                    <div v-else class="col-form-label text-left">
                      <span v-if="entry.title">
                        {{ entry.title }}
                      </span>
                      <span v-else class="text-muted"> &lt;None&gt; </span>
                    </div>
                  </div>

                  <div v-if="entry.sub_title || !readOnly" class="col-6 h-padding-bottom-l">
                    <label
                      class="h-label h-alert-icons h-d-flex"
                      for="editEducationalEntryDialog-sub_title"
                      ><i
                        class="healenticons-group-2843 h-line-height-1 cursor-pointer"
                        @click="showInfo('subtitle')"
                      ></i>
                      Subtitle
                    </label>
                    <InputField
                      v-if="!readOnly"
                      id="editEducationalEntryDialog-sub_title"
                      type="text"
                      v-model="entry.sub_title"
                      :error="errors.sub_title"
                      @input="
                        () => {
                          errors.sub_title = null;
                        }
                      "
                    />
                    <div v-else class="col-form-label text-left">
                      <span v-if="entry.sub_title">
                        {{ entry.sub_title }}
                      </span>
                      <span v-else class="text-muted"> &lt;None&gt; </span>
                    </div>
                  </div>

                  <div v-if="entry.image || !readOnly" class="col-6 h-padding-bottom-l">
                    <label
                      class="h-label h-alert-icons h-d-flex"
                      for="editEducationalEntryDialog-image"
                      ><i
                        class="healenticons-group-2843 h-line-height-1 cursor-pointer"
                        @click="showInfo('image')"
                      ></i>
                      Image
                    </label>
                    <InputField
                      v-if="!readOnly"
                      id="editEducationalEntryDialog-image"
                      type="text"
                      v-model="entry.image"
                      :error="errors.image"
                      @input="
                        () => {
                          errors.image = null;
                        }
                      "
                    />
                    <div v-else class="col-form-label text-left">
                      <span v-if="entry.image">
                        {{ entry.image }}
                      </span>
                      <span v-else class="text-muted"> &lt;None&gt; </span>
                    </div>
                  </div>

                  <div v-if="entry.link || !readOnly" class="col-6 h-padding-bottom-l">
                    <label
                      class="h-label h-alert-icons h-d-flex"
                      for="editEducationalEntryDialog-link"
                      ><i
                        class="healenticons-group-2843 h-line-height-1 cursor-pointer"
                        @click="showInfo('video')"
                      ></i>
                      Video
                    </label>
                    <InputField
                      v-if="!readOnly"
                      id="editEducationalEntryDialog-link"
                      type="text"
                      v-model="entry.link"
                      :error="errors.link"
                      @input="
                        () => {
                          errors.link = null;
                        }
                      "
                    />
                    <div v-else class="col-form-label text-left">
                      <span v-if="entry.link">
                        {{ entry.link }}
                      </span>
                      <span v-else class="text-muted"> &lt;None&gt; </span>
                    </div>
                  </div>

                  <div v-if="entry.category || !readOnly" class="col-6 h-padding-bottom-l">
                    <label
                      class="h-label h-alert-icons h-d-flex"
                      for="editEducationalEntryDialog-category"
                      ><i
                        class="healenticons-group-2843 h-line-height-1 cursor-pointer"
                        @click="showInfo('category')"
                      ></i>
                      Category
                    </label>
                    <InputField
                      v-if="!readOnly"
                      id="editEducationalEntryDialog-category"
                      type="text"
                      v-model="entry.category"
                      :error="errors.category"
                      @input="
                        () => {
                          errors.category = null;
                        }
                      "
                    />
                    <div v-else class="col-form-label text-left">
                      <span v-if="entry.category">
                        {{ entry.category }}
                      </span>
                      <span v-else class="text-muted"> &lt;None&gt; </span>
                    </div>
                  </div>

                  <div v-if="entry.source || !readOnly" class="col-6 h-padding-bottom-l">
                    <label
                      class="h-label h-alert-icons h-d-flex"
                      for="editEducationalEntryDialog-source"
                      ><i
                        class="healenticons-group-2843 h-line-height-1 cursor-pointer"
                        @click="showInfo('source')"
                      ></i>
                      Source
                    </label>
                    <InputField
                      v-if="!readOnly"
                      id="editEducationalEntryDialog-source"
                      type="text"
                      v-model="entry.source"
                      :error="errors.source"
                      @input="
                        () => {
                          errors.source = null;
                        }
                      "
                    />
                    <div v-else class="col-form-label text-left">
                      <span v-if="entry.source">
                        {{ entry.source }}
                      </span>
                      <span v-else class="text-muted"> &lt;None&gt; </span>
                    </div>
                  </div>
                  <div
                    v-if="hospitalMode && (entry.primary_tag || !readOnly)"
                    class="col-6 h-padding-bottom-l"
                  >
                    <label
                      class="h-label h-alert-icons h-d-flex"
                      for="editEducationalEntryDialog-primary_tag"
                      ><i
                        class="healenticons-group-2843 h-line-height-1 cursor-pointer"
                        @click="showInfo('primaryTag')"
                      ></i>
                      Primary tag
                    </label>
                    <InputField
                      v-if="!readOnly"
                      id="editEducationalEntryDialog-primary_tag"
                      type="select"
                      :step="1"
                      v-model="entry.primary_tag"
                      :error="errors.primary_tag"
                      @input="
                        () => {
                          errors.primary_tag = null;
                        }
                      "
                      :choices="primaryTagChoices"
                    />
                    <div v-else class="col-form-label text-left">
                      <span v-if="entry.primary_tag">
                        {{ entry.primary_tag }}
                      </span>
                      <span v-else class="text-muted"> &lt;None&gt; </span>
                    </div>
                  </div>
                  <div v-if="entry.description || !readOnly" class="col-12 h-padding-bottom-l">
                    <label
                      class="h-label h-alert-icons h-d-flex"
                      for="editEducationalEntryDialog-description"
                      ><i
                        class="healenticons-group-2843 h-line-height-1 cursor-pointer"
                        @click="showInfo('description')"
                      ></i>
                      Description
                    </label>
                    <InputField
                      v-if="!readOnly"
                      id="editEducationalEntryDialog-description"
                      type="textarea"
                      v-model="entry.description"
                      :error="errors.description"
                      :rows="5"
                      @input="
                        () => {
                          errors.description = null;
                        }
                      "
                    />
                    <div v-else class="col-form-label text-left">
                      <span v-if="entry.description" class="pre-wrap">
                        {{ entry.description }}
                      </span>
                      <span v-else class="text-muted"> &lt;None&gt; </span>
                    </div>
                  </div>

                  <div
                    v-if="hospitalMode && (entry.keywords || !readOnly)"
                    class="col-12 h-padding-bottom-l"
                  >
                    <label
                      class="h-label h-alert-icons h-d-flex"
                      for="editEducationalEntryDialog-keywords"
                      ><i
                        class="healenticons-group-2843 h-line-height-1 cursor-pointer"
                        @click="showInfo('keywords')"
                      ></i>
                      Keywords
                    </label>
                    <InputField
                      v-if="!readOnly"
                      id="editEducationalEntryDialog-keywords"
                      type="textarea"
                      v-model="entry.keywords"
                      :error="errors.keywords"
                      :rows="2"
                      @input="
                        () => {
                          errors.keywords = null;
                        }
                      "
                    />
                    <div v-else class="col-form-label text-left">
                      <span v-if="entry.keywords" class="pre-wrap">
                        {{ entry.keywords }}
                      </span>
                      <span v-else class="text-muted"> &lt;None&gt; </span>
                    </div>

                    <div>
                      <p class="h-h5 h-primary_shade_1 h-margin-top-s">
                        Comma separated list of keywords.
                      </p>
                    </div>
                  </div>

                  <div
                    v-if="hospitalMode && (entry.min_age || !readOnly)"
                    class="col-6 h-padding-bottom-l"
                  >
                    <label
                      class="h-label h-alert-icons h-d-flex"
                      for="editEducationalEntryDialog-min_age"
                      ><i
                        class="healenticons-group-2843 h-line-height-1 cursor-pointer"
                        @click="showInfo('minAge')"
                      ></i>
                      Min age
                    </label>
                    <InputField
                      v-if="!readOnly"
                      id="editEducationalEntryDialog-min_age"
                      type="number"
                      :step="1"
                      v-model="entry.min_age"
                      :error="errors.min_age"
                      @input="
                        () => {
                          errors.min_age = null;
                        }
                      "
                    />
                    <div v-else class="col-form-label text-left">
                      <span v-if="entry.min_age">
                        {{ entry.min_age }}
                      </span>
                      <span v-else class="text-muted"> &lt;None&gt; </span>
                    </div>
                  </div>

                  <div
                    v-if="hospitalMode && (entry.max_age || !readOnly)"
                    class="col-6 h-padding-bottom-l"
                  >
                    <label
                      class="h-label h-alert-icons h-d-flex"
                      for="editEducationalEntryDialog-max_age"
                      ><i
                        class="healenticons-group-2843 h-line-height-1 cursor-pointer"
                        @click="showInfo('maxAge')"
                      ></i>
                      Max age
                    </label>
                    <InputField
                      v-if="!readOnly"
                      id="editEducationalEntryDialog-max_age"
                      type="number"
                      :step="1"
                      v-model="entry.max_age"
                      :error="errors.max_age"
                      @input="
                        () => {
                          errors.max_age = null;
                        }
                      "
                    />
                    <div v-else class="col-form-label text-left">
                      <span v-if="entry.max_age">
                        {{ entry.max_age }}
                      </span>
                      <span v-else class="text-muted"> &lt;None&gt; </span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-7">
                <div v-if="entry.item_type !== 'video'" class="h-ck100 h-toolbar h-padding-left-l">
                  <div v-if="!readOnly" class="h-ck100 h-toolbar h-drawer-maxfull h-edu">
                    <CKEditor
                      v-model="entry.html_content"
                      :config="editorConfig"
                      :editor="editor"
                    ></CKEditor>
                  </div>
                  <div v-else class="h-primary_shade_1">
                    <div v-if="entry.html_content" v-html="entry.html_content"></div>
                    <div class="text-muted">&lt;No content&gt;</div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <Loader v-else />
        </div>

        <div class="h-drawer-card-footer">
          <button
            v-if="!readOnly"
            type="button"
            class="h-btn h-btn-accent"
            @click="save"
            :disabled="saving"
          >
            Save
          </button>

          <button
            type="button"
            class="h-btn h-btn-dangerous h-margin-left-s"
            @click="close"
            :disabled="saving"
          >
            Close
          </button>
        </div>
      </div>
    </Drawer>
    <InformationDialogVue ref="InformationDialogVue" title="Info">
      <template v-slot:modal-body>
        <div v-html="currentContent"></div>
      </template>
    </InformationDialogVue>
  </div>
</template>
<script>
import CKEditor from '@ckeditor/ckeditor5-vue2';
import Loader from '@/components/Loader';
import InputField from '@/components/InputField';
import InformationDialogVue from '@/components/dialogs/InformationDialog';
import * as customEditor from '../../../../public/assets/js/ckeditor';

export default {
  data() {
    const primaryTagChoices = [
      'Blood Glucose',
      'Hours of sleep',
      'Total water intake',
      'Blood Pressure (Sistolic / Diastolic)',
      'Quality of sleep',
      'Total physical activity today',
      'Total water intake (ref. 8oz = 1 cup)',
      'Glucosomine (A)',
      'Glucosamine (A)',
      'Your Weight',
      'Januvia (sitagliptin)',
      'Lipitor',
      'Pain Today',
      'Body Temperature',
      'Platelets',
      'Advair Diskus (fluticasone)',
      'Belching or gas',
      'Rate your stress level',
      'Abilify (aripiprazole)',
      'Blood Pressure',
      'Albumin',
      'Total Bilirubin',
      'Lantus Solostar (insulin glargine)',
      'HgbA1C',
      'How many glasses of wine/beer/liqour did you consume today',
      'Wine/Beer/Alcohol today',
      'Lyrica (pregabalin)',
      'Did you meditate today?',
      'Enbrel (etanercept)',
      'Humira (adalimumab)',
      'Breathing Rate',
      'Synthroid (levothyroxine)',
      'Blood Urea Nitrogen',
      'Hemoglobin-HgB',
      'C-reactive Protein',
      'Crestor (rosuvastatin)',
      'Bowel Movement',
      'Harvoni (ledipasvir and sofosbuvir)',
      'Azathioprine (Imuran)',
      'Arterial blood gases',
      'Heavy or Irregular Breathing',
      'Cyclosporine (Neoral)',
      'Alpha-fetoprotein',
      'Rate your cravings',
      'How many cigarettes did you smoke today',
      'BCAAs',
      'Total alcoholic drinks today (eg. Beer, Wine)',
      'Cyclosporine (Sandimmune)',
      'Vyvanse (lisdexamfetamine)',
      'Record Blood Sugar',
      'Time spent on physical activity',
      'Total Steps today',
      'How ready are you for your surgery?',
      'Compared to last week, how worried are you about your surgical procedure?',
      'Did you smoke today?',
      'Omega-3 fish oil',
      'Diarrhea',
      'Berberine',
      'Mupirocin nasal ointment from pharmacy',
      'Dentist Approval',
      'Insurance clearance',
      'PCP Clearance',
      'Pick up Hibiclens Chlorhexidine soap from your pharmacy',
      'Do not eat or drink anything past midnight',
      'Schedule your post-operative appointment',
      'Stop taking anti-inflammatory medications and supplements',
      'Start cleaning routine',
      'Type and Screen test',
      'Phentermine',
      'Blood Sugar',
      'Complete Dental evaluation',
      'Rate your stress',
      'Rate your mood',
      'How many cigarettes did you smoke today?',
      'Pain Score',
      'Breathing Difficulty',
      'How many alcoholic drinks did you have today?',
      'Steps Count',
      'Foot Temperature',
      'Physical Therapy',
      'Pulse Rate',
      'Nutrition',
      'SpO2',
    ];
    primaryTagChoices.sort();
    primaryTagChoices.unshift([null, '--- select ---']);
    return {
      infoHtmlContent: {
        order: '<h4>Enter the order.</h4>',
        title: '<h4>Enter the title.</h4>',
        subtitle: '<h4>Enter the subtitle.</h4>',
        image: '<h4>Enter the image URL.</h4>',
        video: '<h4>Enter the video URL.</h4>',
        category: `<h4 class="h-padding-bottom-s">User provider name (exact match) as meet your provider</h4>
        <h4 class="h-padding-bottom-s">Use the name of the protocol to show procedural videos</h4>
        <h4 class="h-padding-bottom-s">Use 'Meditation', 'Anxiety', or 'Depression' for a BHI activity</h4>`,
        source: '<h4>Enter the source.</h4>',
        primaryTag: '<h4>Enter the primary tag.</h4>',
        description: '<h4>Enter the description.</h4>',
        keywords: '<h4>Enter the keywords.</h4>',
        minAge: '<h4>Enter the min age.</h4>',
        maxAge: '<h4>Enter the max age.</h4>',
      },
      currentContent: '',
      primaryTagChoices,
      entryId: null,
      collectionId: null,
      loading: true,
      saving: false,
      axiosToken: null,
      errors: {},
      entry: null,
      readOnly: null,
      editor: customEditor,
      editorConfig: {
        fontColor: {
          colors: [
            {
              color: '#152145',
              label: 'Primary',
            },
            {
              color: '#c3c4cb',
              label: 'Grey',
            },

            {
              color: '#2b5adc',
              label: 'Accent',
            },
            {
              color: '#1b998b',
              label: 'Green',
            },

            {
              color: '#EE4266 ',
              label: 'Red',
              hasBorder: true,
            },

            // ...
          ],
        },
        toolbar: {
          items: [
            'heading',
            '|',
            'bold',
            'italic',
            'link',
            'bulletedList',
            'numberedList',
            '|',
            'alignment',
            'fontColor',
            'blockQuote',
            '|',
            'imageInsert',
            'mediaEmbed',
            'insertTable',
            'horizontalLine',
            'undo',
            'redo',
          ],
          shouldNotGroupWhenFull: true,
        },
        image: {
          toolbar: ['imageStyle:full', 'imageStyle:side', '|', 'imageTextAlternative'],
        },
        table: {
          contentToolbar: ['tableColumn', 'tableRow', 'mergeTableCells'],
        },
        language: 'en',
        licenseKey: '',
        wordCount: {
          onUpdate: (stats) => {
            this.charactersLength = stats.characters;
          },
        },
        // cloudServices: {
        //   uploadUrl: 'https://81032.cke-cs.com/easyimage/upload/',
        //   tokenUrl: 'https://81032.cke-cs.com/token/dev/b9cd9af09740c9e878b31d43115b4ecba9b052f494d9deb8ae5f617cebec',
        //   resourceType: 'Images'
        // }
      },
    };
  },
  methods: {
    showInfo(type) {
      this.currentContent = this.infoHtmlContent[type];
      this.$refs.InformationDialogVue.show();
    },
    async show(entryId, collectionId, readOnly) {
      this.entryId = entryId;
      this.collectionId = collectionId;
      this.saving = false;
      this.errors = {};
      this.readOnly = readOnly;

      if (this.axiosToken) {
        this.axiosToken.cancel();
      }
      this.axiosToken = this.$api.makeToken();

      this.loading = true;
      this.$refs.modalDialog.open();
      this.$refs.modalDialog.active = true;

      if (entryId) {
        this.entry = await this.$api.getEducationEntry(entryId, this.axiosToken);
      } else {
        this.entry = {}; // new entry
      }
      this.loading = false;
    },

    close(force) {
      if (this.saving && !force) {
        return;
      }
      this.$refs.modalDialog.close();
      this.$refs.modalDialog.active = false;
    },

    async save() {
      this.saving = true;
      let response;
      if (this.entry.id) {
        response = await this.$api.updateEducationEntry(this.entry.id, {
          collection_id: this.collectionId,
          learn: this.entry,
        });
      } else {
        response = await this.$api.createEducationEntry({
          collection_id: this.collectionId,
          learn: this.entry,
        });
      }
      if (response.status === 'error') {
        this.errors = response.errors;
        this.saving = false;
        return;
      }
      this.entry = response.learn;
      this.$emit('entryUpdated', response.collection);
      this.close(true);
    },
  },

  components: {
    Loader,
    InputField,
    CKEditor: CKEditor.component,
    InformationDialogVue,
  },

  props: {
    hospitalMode: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

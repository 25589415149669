<template>
  <SlottedMessage
    ref="slottedMessage"
    title="Mobile phone call"
    :closeDisabled="calling"
  >
    <template v-slot:modal-body>
      <div class="py-4">
        <div v-if="!calling" class="text-center">
          <strong v-if="callResult" class="text-danger">
            {{ callResult }}
          </strong>
          <div v-else>
            Start a call to <strong>{{user.name || user.email}}</strong>?
          </div>
        </div>

        <div v-else class="text-center">
          {{ callProgress }}
        </div>
      </div>
    </template>

    <template v-slot:modal-footer>
        <button
          v-if="!calling"
          type="button"
          class="btn btn-success waves-effect"
          @click="startCall"
        >
          Call
        </button>

        <button
          v-if="calling"
          type="button"
          class="btn btn-danger waves-effect"
          @click="cancelCall"
        >
          Cancel
        </button>

        <button
          type="button"
          class="btn btn-primary waves-effect"
          @click="close"
          :disabled="calling"
        >
          Close
        </button>
      </template>
  </SlottedMessage>
</template>


<script>
import SlottedMessage from '@/components/dialogs/SlottedMessage';


export default {
  data() {
    return {
      calling: false,
      callProgress: null,
      callResult: 'null',
      callCancelToken: null,
    };
  },

  methods: {
    show() {
      this.calling = false;
      this.callProgress = null;
      this.callResult = null;
      this.$refs.slottedMessage.show();
    },

    async startCall() {
      this.calling = true;
      this.callResult = null;
      this.callProgress = 'Calling...';

      this.callCancelToken = this.$api.makeToken();
      const result = await this.$api.meetingNotifyAboutCall(
        this.meetingCode,
        this.user.id,
        this.callCancelToken,
      );

      if (result.status === 'error') {
        this.calling = false;
        this.callResult = result.message;
        this.callProgress = null;
      }
    },

    async cancelCall() {
      if (this.callCancelToken) {
        this.callCancelToken.cancel('Call canceled.');
        this.callCancelToken = null;
      }

      await this.$api.meetingCancelCall(
        this.meetingCode,
        this.user.id,
      );

      this.calling = false;
      this.callResult = null;
      this.callProgress = null;
    },

    callDialProgress(data) {
      if (this.meetingCode !== data.meeting_code) {
        return;
      }

      if (data.progress === 'accepted') {
        this.close(true);
        return;
      }

      this.calling = false;
      this.callProgress = null;
      this.callResult = {
        bussy: 'The client phone is bussy.',
        missed: 'Client does not pick up the phone.',
        rejected: 'Call rejected.',
        ended: 'Call ended.',
      }[data.progress] || 'Call ended.';
    },

    close(force) {
      this.$refs.slottedMessage.close(force);
    },
  },

  mounted() {
    this.$globalCon.socket.on('call_dial_progress', this.callDialProgress);
  },

  destroyed() {
    this.$globalCon.socket.off('call_dial_progress', this.callDialProgress);
  },

  components: {
    SlottedMessage,
  },

  props: {
    meetingCode: {
      type: String,
    },
    user: {
      type: null, // Object or null
    },
  },
};
</script>

<template>
  <div>
    <div class="h-space-between h-align-items-center">
      <h3 class="h-h3 h-primary_shade_1">Microprotocols</h3>
      <div
        class="
          h-padding-left-l h-padding-right-l h-space-between h-align-items-center
        "
      >
        <div>
          <button type="button" class="h-btn h-btn-accent h-margin-left-s" @click="newItem">
            <i class="healenticons-plus h-padding-right-xs"></i>New item
          </button>
        </div>
      </div>
    </div>

    <div v-if="!loading" class="h1-table">
      <table class="table dataTable h-noborder-input h-MicroprotocolsTable">
        <thead class="thead-light">
          <tr>
            <th style="width: 5%;" class="text-left">Problem</th>
            <th style="width: 30%;" class="text-left">Desired outcomes</th>
            <th style="width: 30%;" class="text-left">Nursing interventions</th>
            <th style="width: 30%;" class="text-left">Trigger answers</th>
            <th style="width: 5%;" class="w-1 text-nowrap">Action</th>
          </tr>
        </thead>

        <tbody v-if="microprotocols.length === 0">
          <tr>
            <td colspan="1000" class="text-center text-muted">Empty</td>
          </tr>
        </tbody>

        <tbody v-else>
          <tr v-for="microprotocol of microprotocols" :key="microprotocol.id">
            <td class="h-pre-wrap">
              {{ microprotocol.problem }}
            </td>
            <td>
              <span v-if="!microprotocol.desired_outcomes.length">-</span>
              <ul
                v-for="desired_outcome of microprotocol.desired_outcomes"
                :key="desired_outcome.uuid"
              >
                <li style="white-space: pre-wrap;">
                  {{ desired_outcome.value }}
                </li>
              </ul>
            </td>
            <td>
              <span v-if="!microprotocol.nursing_interventions.length">-</span>
              <ul
                v-for="nursing_intervention of microprotocol.nursing_interventions"
                :key="nursing_intervention.uuid"
              >
                <li style="white-space: pre-wrap;">
                  {{ nursing_intervention.value }}
                </li>
              </ul>
            </td>
            <td>
              <span v-if="!microprotocol.question_answers.length">-</span>
              <ol>
                <li
                  v-for="question_answer of microprotocol.question_answers"
                  :key="question_answer.uuid"
                >
                  <strong>Group:</strong>
                  <ul>
                    <li
                      v-for="answer of question_answer.answers"
                      :key="answer.key"
                      style="margin-left: 15rem"
                    >
                      <span v-if="getQuestion(answer.question_id)">
                        {{ getQuestion(answer.question_id).question }}
                        <span v-if="getQuestionAnswer(answer.question_id, answer.answer_key)">
                          ({{ answer.answer_key }} -
                          {{ getQuestionAnswer(answer.question_id, answer.answer_key).value }})
                        </span>
                        <span v-else>
                          (<span class="text-danger">
                            {{ answer.answer_key }} - Answer deleted </span
                          >)
                        </span>
                      </span>
                      <span v-else class="text-danger">Question deleted</span>
                    </li>
                  </ul>
                </li>
              </ol>
            </td>
            <td>
              <a href="javascript:void(0);" class="mr-2" @click="editItem(microprotocol)">
                <i class="fas fa-edit text-info font-16" title="Edit"></i>
              </a>

              <a href="javascript:void(0);" class="mr-2" @click="deleteItem(microprotocol)">
                <i class="fas fa-trash-alt text-danger font-16" title="Delete"></i>
              </a>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <Loader v-else style="position: relative !important; margin-top: 50rem !important" />

    <EditMicroprotocolDialog
      ref="editMicroprotocolDialog"
      @setItems="setItems"
      :questions="questions"
    />
    <DeleteMicroprotocolDialog ref="deleteMicroprotocolDialog" @setItems="setItems" />
  </div>
</template>

<script>
import settings from '@/settings.js';
import Loader from '@/components/Loader';
import EditMicroprotocolDialog from './EditMicroprotocolDialog';
import DeleteMicroprotocolDialog from './DeleteMicroprotocolDialog';

export default {
  data() {
    return {
      loading: true,
      microprotocols: [],
    };
  },

  methods: {
    newItem() {
      this.$refs.editMicroprotocolDialog.show({});
    },

    editItem(item) {
      this.$refs.editMicroprotocolDialog.show(item);
    },

    deleteItem(item) {
      this.$refs.deleteMicroprotocolDialog.show(item);
    },

    getQuestion(id) {
      return this.questions.find(question => question.id.toString() === id.toString());
    },

    setItems(items) {
      this.microprotocols = items;
    },

    getQuestionAnswer(questionId, answerKey) {
      const question = this.getQuestion(questionId);
      console.log(question.answers, answerKey);
      return question.answers.find(answer => answer.key === answerKey);
    },
  },

  async mounted() {
    const response = await this.$api.get(`${settings.BACKEND_URL}/api/v-nurse/microprotocols`);
    this.microprotocols = response.microprotocols;
    console.log(this);
    this.loading = false;
  },

  components: {
    Loader,
    EditMicroprotocolDialog,
    DeleteMicroprotocolDialog,
  },

  props: ['questions'],
};
</script>

import Vue from 'vue';
import VueRouter from 'vue-router';
import settings from '@/settings.js';
import virgilHelper from '@/utils/virgil_helper';

import MainInterface from '@/components/main_interface/MainInterface';

import Dashboard from '../views/surgery_navigator/dashboard/Dashboard';
import CareProtocolTemplates from '../views/surgery_navigator/care_protocol_templates/CareProtocolTemplates';
import AssessmentsEditor from '../views/surgery_navigator/assessments/AssessmentsEditor';
import AllPatients from '../views/surgery_navigator/all_patients/AllPatients';
import EditPatientInfoTab from '../views/surgery_navigator/edit_patient/EditPatientInfoTab';
import AddPatient from '../views/surgery_navigator/edit_patient/AddPatient';

import RPMRTMStratification from '../components/surveys/RPMRTMStratification';

import Chat from '../views/surgery_navigator/chat/Chat';
import Reports from '../views/surgery_navigator/reports/Reports';

import Profile from '../views/settings/hospital_staff/Profile';
import SettingsChangePassword from '../views/settings/settings_change_password/SettingsChangePassword';
import Invite from '../views/settings/hospital_staff/invite/Invite';
import AllMembers from '../views/settings/hospital_staff/AllMembers';
import EditStaffMember from '../views/settings/hospital_staff/edit_staff_member/EditStaffMember';
import HospitalSettings from '../views/settings/hospital_settings/HospitalSettings';
import Forms from '../views/settings/form_builder/Forms';
import CreateForm from '../views/settings/form_builder/CreateForm';
import FormBuild from '../views/settings/form_builder/FormBuild';
import Microprotocols from '../views/settings/microprotocols/Microprotocols';

import CareProtocol from '../views/settings/care_protocols/CareProtocol';
import ImportAppointments from '../views/settings/import_appointments/ImportAppointments';
import ImportPatients from '../views/settings/import_patients/ImportPatients';
import AppointmentsCalendar from '../components/appointments_calendar/AppointmentsCalendar';
import LearnModule from '../views/settings/learn_module/LearnModule';
import PhysicalTherapy from '../views/settings/physical_therapy/PhysicalTherapy';
import EditPhysicalTherapy from '../views/settings/physical_therapy/EditPhysicalTherapy';

import Register from '../views/authantication/Register';
import Login from '../views/authantication/Login';
import LoginCognito from '../views/cognito/Login';
import RecoverPassword from '../views/authantication/RecoverPassword';
import RecoverPasswordCognito from '../views/cognito/RecoverPasswordCognito';
import ChangePassword from '../views/authantication/ChangePassword';
import PasswordChanged from '../views/authantication/PasswordChanged';
import SessionExpired from '../views/authantication/SessionExpired';

import VideoMeeting from '../views/video_meeting/VideoMeeting';

import NewRecipe from '../views/general/recipe/view_recipe/NewRecipe';
import RecipePage from '../views/general/recipe/view_recipe/RecipePage';
import SerachRecipePage from '../views/general/recipe/search_recipe/SearchRecipePage';
import ClientsActivity from '../views/surgery_navigator/dashboard/main_tables/clients_activity/ClientsActivity';
import Documents from '../views/surgery_navigator/docuements/Documents';
import NudgingConfig from '../views/surgery_navigator/nudging/NudgingConfig';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'index',
    redirect: {
      name: 'AllPatients',
    },
  },
  // --- video-meeting ---------------------------------------------------------
  {
    path: '/video-meeting/main/:side',
    name: 'VideoMeeting',
    component: VideoMeeting,
    meta: {
      title: 'Video Meeting',
      getGlobalConSection: () => Vue.prototype.$globalCon.SECTION_WEB_VIDEO_MEETING,
    },
  },

  // --- surgery-navigator -----------------------------------------------------
  {
    path: '/surgery-navigator',
    meta: {
      authRequired: true,
    },
    component: MainInterface,
    children: [
      {
        path: 'dashboard',
        name: 'Dashboard',
        component: Dashboard,
        meta: {
          parent: null,
          collapseSettings: true,
          title: 'Dashboard',
          getGlobalConSection: () => Vue.prototype.$globalCon.SECTION_MAIN_SITE,
        },
      },
      {
        path: 'care_protocol_templates',
        name: 'CareProtocolTemplates',
        component: CareProtocolTemplates,
        meta: {
          parent: 'Dashboard',
          title: 'Care protocol templates',
          getGlobalConSection: () => Vue.prototype.$globalCon.SECTION_MAIN_SITE,
        },
      },
      {
        path: 'physical_therapy',
        name: 'PhysicalTherapy',
        component: PhysicalTherapy,
        meta: {
          parent: 'Dashboard',
          title: 'Physical therapy',
          getGlobalConSection: () => Vue.prototype.$globalCon.SECTION_MAIN_SITE,
        },
      },
      {
        path: 'add-physical-therapy',
        name: 'AddPhysicalTherapy',
        component: EditPhysicalTherapy,
        meta: {
          parent: 'PhysicalTherapy',
          title: 'Add physical therapy',
          getGlobalConSection: () => Vue.prototype.$globalCon.SECTION_MAIN_SITE,
        },
      },
      {
        path: 'edit-physical-therapy/:id',
        name: 'EditPhysicalTherapy',
        component: EditPhysicalTherapy,
        meta: {
          parent: 'PhysicalTherapy',
          title: 'Edit physical therapy',
          getGlobalConSection: () => Vue.prototype.$globalCon.SECTION_MAIN_SITE,
        },
      },
      {
        path: 'assessments_editor',
        name: 'AssessmentsEditor',
        component: AssessmentsEditor,
        meta: {
          parent: 'Dashboard',
          title: 'Edit Assessments',
          getGlobalConSection: () => Vue.prototype.$globalCon.SECTION_MAIN_SITE,
        },
      },
      {
        path: 'nudging_configuration',
        name: 'NudgingConfiguration',
        component: NudgingConfig,
        meta: {
          parent: 'Dashboard',
          collapseSettings: true,
          title: 'Nudging recordings configuration',
          getGlobalConSection: () => Vue.prototype.$globalCon.SECTION_MAIN_SITE,
        },
      },
      {
        path: 'patients',
        name: 'AllPatients',
        component: AllPatients,
        meta: {
          parent: 'Dashboard',
          collapseSettings: true,
          title: 'All patients',
          getGlobalConSection: () => Vue.prototype.$globalCon.SECTION_MAIN_SITE,
        },
      },
      {
        path: 'patients/patients-logs',
        name: 'ClientsActivity',
        component: ClientsActivity,
        meta: {
          parent: 'Dashboard',
          collapseSettings: true,
          title: 'Patients Alerts',
          getGlobalConSection: () => Vue.prototype.$globalCon.SECTION_MAIN_SITE,
        },
      },
      {
        path: 'patients/AppointmentsCalendar',
        name: 'AppointmentsCalendar',
        component: AppointmentsCalendar,
        meta: {
          parent: 'Dashboard',
          collapseSettings: true,
          title: 'Appointments',
          getGlobalConSection: () => Vue.prototype.$globalCon.SECTION_MAIN_SITE,
        },
      }, /* ,
      {
        path: 'patients/:id',
        name: 'PatientDetail',
        component: PatientDetail,
        meta: {
          parent: 'Dashboard',
          collapseSettings: true,
          title: 'Patient Profile',
          getGlobalConSection: () => Vue.prototype.$globalCon.SECTION_MAIN_SITE,
        },
      }, */
      {
        path: 'patients/add-patient',
        name: 'AddPatient',
        component: AddPatient,
        meta: {
          parent: 'AllPatients',
          title: 'Add patient',
          getGlobalConSection: () => Vue.prototype.$globalCon.SECTION_MAIN_SITE,
        },
      },
      {
        path: 'patients/edit-patient/:id',
        name: 'EditPatient',
        component: EditPatientInfoTab,
        meta: {
          parent: 'AllPatients',
          title: 'Edit patient',
          getGlobalConSection: () => Vue.prototype.$globalCon.SECTION_MAIN_SITE,
        },
      },
      {
        path: 'surveys/rpmrtm-assessment',
        name: 'RPMRTMStratification',
        component: RPMRTMStratification,
        meta: {
          title: 'RPM/RTM Stratification Assessment',
          getGlobalConSection: () => Vue.prototype.$globalCon.SECTION_MAIN_SITE,
        },
      },
      {
        path: 'messages',
        name: 'Chat',
        component: Chat,
        meta: {
          parent: 'Dashboard',
          collapseSettings: true,
          title: 'Messages',
          getGlobalConSection: () => Vue.prototype.$globalCon.SECTION_WEB_CHAT,
        },
      },
      {
        path: 'messages/:userId',
        name: 'Chat/userId',
        component: Chat,
        meta: {
          parent: 'Dashboard',
          collapseSettings: true,
          title: 'Messages',
          getGlobalConSection: () => Vue.prototype.$globalCon.SECTION_WEB_CHAT,
        },
      },
      {
        path: 'reports',
        name: 'Reports',
        component: Reports,
        meta: {
          parent: 'Dashboard',
          collapseSettings: true,
          title: 'Reports',
          getGlobalConSection: () => Vue.prototype.$globalCon.SECTION_MAIN_SITE,
        },
      },
      {
        path: 'reports/:section',
        name: 'Reports/section',
        component: Reports,
        meta: {
          parent: 'Dashboard',
          title: 'Reports',
          getGlobalConSection: () => Vue.prototype.$globalCon.SECTION_MAIN_SITE,
        },
      },
      {
        path: 'documents',
        name: 'Documents',
        component: Documents,
        meta: {
          parent: 'Dashboard',
          collapseSettings: true,
          title: 'Documents',
          getGlobalConSection: () => Vue.prototype.$globalCon.SECTION_MAIN_SITE,
        },
      },
    ],
  },

  // --- settings --------------------------------------------------------------
  {
    path: '/settings',
    component: MainInterface,
    meta: {
      authRequired: true,
      rolesAllowed: ['system_administrator', 'nurse_administrator'],
    },
    children: [
      {
        path: 'change-password',
        name: 'SettingsChangePassword',
        component: SettingsChangePassword,
        meta: {
          parent: 'Dashboard',
          title: 'Change password',
          getGlobalConSection: () => Vue.prototype.$globalCon.SECTION_MAIN_SITE,
        },
      },
      {
        path: 'profile',
        name: 'Profile',
        component: Profile,
        meta: {
          parent: 'Dashboard',
          title: 'Profile',
          rolesAllowed: ['system_administrator', 'nurse_administrator', 'nurse'],
          superuserOnly: false,
          getGlobalConSection: () => Vue.prototype.$globalCon.SECTION_MAIN_SITE,
        },
      },
      // hospital-members
      {
        path: 'hospital-members/all',
        name: 'AllMembers',
        component: AllMembers,
        meta: {
          parent: 'Dashboard',
          title: 'Users',
          getGlobalConSection: () => Vue.prototype.$globalCon.SECTION_MAIN_SITE,
        },
      },
      {
        path: 'hospital-members/edit/:doctorId',
        name: 'EditStaffMember',
        component: EditStaffMember,
        meta: {
          parent: 'AllMembers',
          shouldDisplayLeftArrow: true,
          title: 'Edit user',
          getGlobalConSection: () => Vue.prototype.$globalCon.SECTION_MAIN_SITE,
        },
      },
      {
        path: 'hospital-members/invite',
        name: 'Invite',
        component: Invite,
        meta: {
          parent: 'AllMembers',
          title: 'Invite new members',
          getGlobalConSection: () => Vue.prototype.$globalCon.SECTION_MAIN_SITE,
        },
      },
      {
        path: 'hospital/settings',
        name: 'HospitalSettings',
        component: HospitalSettings,
        meta: {
          parent: 'Dashboard',
          title: 'Facility Settings',
          getGlobalConSection: () => Vue.prototype.$globalCon.SECTION_MAIN_SITE,
        },
      },
      {
        path: 'forms/',
        name: 'Forms',
        component: Forms,
        meta: {
          parent: 'Dashboard',
          title: 'From Builder',
          getGlobalConSection: () => Vue.prototype.$globalCon.SECTION_MAIN_SITE,
        },
      },
      {
        path: 'forms/new',
        name: 'NewForm',
        component: CreateForm,
        meta: {
          parent: 'Dashboard',
          title: 'New From',
          getGlobalConSection: () => Vue.prototype.$globalCon.SECTION_MAIN_SITE,
        },
      },
      {
        path: 'forms/:formId',
        name: 'FormBuilder',
        component: FormBuild,
        meta: {
          parent: 'Dashboard',
          title: 'From Builder',
          getGlobalConSection: () => Vue.prototype.$globalCon.SECTION_MAIN_SITE,
        },
      },
      // care protocols
      {
        path: 'care-protocol/:id',
        name: 'CareProtocol',
        component: CareProtocol,
        meta: {
          parent: 'Dashboard',
          title: 'Care Protocol template',
          getGlobalConSection: () => Vue.prototype.$globalCon.SECTION_MAIN_SITE,
        },
      },
      {
        path: 'care-protocol/:id/doctor/:doctorId',
        name: 'CareProtocolOfUser',
        component: CareProtocol,
        meta: {
          parent: 'EditStaffMember',
          title: 'Care Protocol of a doctor',
          getGlobalConSection: () => Vue.prototype.$globalCon.SECTION_MAIN_SITE,
        },
      },
      // Microprotocols
      {
        path: 'microprotocols',
        name: 'Microprotocols',
        component: Microprotocols,
        meta: {
          parent: 'Dashboard',
          title: 'Microprotocols',
          getGlobalConSection: () => Vue.prototype.$globalCon.SECTION_MAIN_SITE,
        },
      },
      // import appointments
      {
        path: 'import-appointments',
        name: 'ImportAppointments',
        component: ImportAppointments,
        meta: {
          parent: 'Dashboard',
          title: 'Import Appointments',
          getGlobalConSection: () => Vue.prototype.$globalCon.SECTION_MAIN_SITE,
        },
      },
      // import patients
      {
        path: 'import-csv',
        name: 'ImportCSV',
        component: ImportPatients,
        meta: {
          parent: 'Dashboard',
          title: 'Import CSV',
          getGlobalConSection: () => Vue.prototype.$globalCon.SECTION_MAIN_SITE,
        },
      },
      // LearnModule
      {
        path: 'learn-module',
        name: 'LearnModule',
        component: LearnModule,
        meta: {
          parent: 'Dashboard',
          title: 'Learn Module',
          getGlobalConSection: () => Vue.prototype.$globalCon.SECTION_MAIN_SITE,
        },
      },
    ],
  },

  // --- general ---------------------------------------------------------------
  {
    path: '/general',
    component: MainInterface,
    meta: {
      authRequired: true,
    },
    children: [
      {
        path: 'recipe/search',
        name: 'SerachRecipePage',
        component: SerachRecipePage,
        meta: {
          parent: 'Dashboard',
          title: 'Serach recipe',
          getGlobalConSection: () => Vue.prototype.$globalCon.SECTION_MAIN_SITE,
        },
      },
      {
        path: 'recipe/view/:recipeId',
        name: 'Recipe',
        component: RecipePage,
        meta: {
          parent: 'SerachRecipePage',
          title: 'Recipe',
          getGlobalConSection: () => Vue.prototype.$globalCon.SECTION_MAIN_SITE,
        },
      },
      {
        path: 'recipe/new',
        name: 'NewRecipe',
        component: NewRecipe,
        meta: {
          parent: 'SerachRecipePage',
          title: 'New Recipe',
          getGlobalConSection: () => Vue.prototype.$globalCon.SECTION_MAIN_SITE,
        },
      },
    ],
  },

  // --- authantication --------------------------------------------------------
  {
    path: '/session-expired',
    name: 'SessionExpired',
    component: SessionExpired,
    meta: {
      title: 'Session Expired',
      getGlobalConSection: () => null,
    },
  },
  {
    path: '/register/:code',
    name: 'Register',
    component: Register,
    meta: {
      title: 'Register',
      getGlobalConSection: () => null,
    },
  },
  {
    path: '/login',
    name: 'Login',
    component: (settings.forceCognito && (settings.env !== 'local'))
      ? LoginCognito
      : Login,
    meta: {
      title: 'Login',
      getGlobalConSection: () => null,
    },
  },
  {
    path: '/login-cognito',
    name: 'LoginCognito',
    component: LoginCognito,
    meta: {
      title: 'Login',
      getGlobalConSection: () => null,
    },

    RecoverPasswordCognito,
  },
  {
    path: '/recover-password-cognito',
    name: 'RecoverPasswordCognito',
    component: RecoverPasswordCognito,
    meta: {
      title: 'Recover password',
      getGlobalConSection: () => null,
    },

    RecoverPasswordCognito,
  },
  {
    path: '/logout',
    name: 'Logout',
  },
  {
    path: '/recover-password',
    name: 'RecoverPassword',
    component: RecoverPassword,
    meta: {
      title: 'Recover password',
      getGlobalConSection: () => null,
    },
  },
  {
    path: '/change-password/:code',
    name: 'ChangePassword',
    component: ChangePassword,
    meta: {
      title: 'Change password',
      getGlobalConSection: () => null,
    },
  },
  {
    path: '/password-changed',
    name: 'PasswordChanged',
    component: PasswordChanged,
    meta: {
      title: 'Change password',
      getGlobalConSection: () => null,
    },
  },
];

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes,
});

// eslint-disable-next-line consistent-return
function enuserSettingsLoad(callback) {
  if (settings.currentSessionLoaded) {
    return callback();
  }
  // eslint-disable-next-line consistent-return
  Vue.prototype.$api.getCurrentSessionData().then((response) => {
    if (response.status !== 'ok') {
      return callback();
    }

    settings.currentSession = response.current_session_data;
    if (!settings.currentSession) {
      settings.currentSessionLoaded = true;
      return callback();
    }

    Vue.prototype.$api.getCareProtocols().then((protocolsResponse) => {
      settings.careProtocols = protocolsResponse.care_protocols;
      settings.currentSessionLoaded = true;
      return callback();
    });
  });
}

// eslint-disable-next-line consistent-return
router.beforeEach(async (to, from, next) => {
  if (to.name === 'Logout') {
    return Vue.prototype.$api.logout().then((response) => {
      if (response.status !== 'ok') {
        console.error('Failed to logout');
        return;
      }
      virgilHelper.prepareKeyMaterial(null, null);
      next('/login');
    });
  }

  let superuserOnly;
  let authenticationRequired;
  let rolesAllowed;
  for (let i = to.matched.length - 1; i >= 0; i -= 1) {
    if (rolesAllowed === undefined) {
      rolesAllowed = to.matched[i].meta.rolesAllowed;
    }
    if (superuserOnly === undefined) {
      superuserOnly = to.matched[i].meta.superuserOnly;
    }
    if (authenticationRequired === undefined) {
      authenticationRequired = to.matched[i].meta.authRequired;
    }
  }
  authenticationRequired = !!(authenticationRequired || superuserOnly || rolesAllowed);

  // next('/login');

  if (authenticationRequired) {
    enuserSettingsLoad(() => {
      if (!settings.currentSession) {
        next('/login');
        return;
      }
      if (
        (superuserOnly && !settings.currentSession?.user.is_superuser)
        || (rolesAllowed && !rolesAllowed.find(role => settings.hasRole(role)))
      ) {
        next('/surgery-navigator/dashboard');
      }
      next();
    });
  } else {
    return next();
  }
});

router.afterEach((to) => {
  Vue.prototype.$breadcrums.processNavigationTo(to);

  const globalConSection = to.meta.getGlobalConSection
    ? to.meta.getGlobalConSection()
    : null;
  Vue.prototype.$globalCon.setSection(globalConSection);

  document.title = to.meta.title || 'Care Navigator';
  $('html').scrollTop(0);

  if ($('.menu-body').is(':visible') && $(window).width() <= 1024) {
    $('.button-menu-mobile').click();
  }
});

export default router;

<template>
  <main class="login">
    <div class="h-row">
      <div class="h-col-desktop-8 bg-accent h-hide-mobile">
        <div id="login_animation">
          <lottie-animation path="./assets/js/lottie_files/login.json" />
        </div>
      </div>
      <div class="h-col-desktop-4 h-col-mobile-12 h-d-flex">
        <div class="login-card">
          <h1 class="h-h1 h-primary_shade_1 h-text-center">Let's Get Started</h1>
          <h3 class="h-h3 h-secondary_shade_1 h-text-center h-margin-top-s">Sign in to continue</h3>
          <!--end auth-logo-text-->
          <div v-if="!loading" class="h-margin-top-xl">
            <form class="form-horizontal auth-form">
              <div v-if="mode === 'enter'">
                <div class="form-group h-row">
                  <div class="h-col-desktop-12 h-col-mobile-12">
                    <label for="email" class="h-label">Email</label>
                    <div class="input-group">
                      <span class="auth-form-icon">
                        <i class="healenticons-emailalt"></i>
                      </span>
                      <input
                        type="text"
                        class="h-input"
                        :class="{ 'is-invalid': errors.email }"
                        id="email"
                        placeholder="Enter email"
                        v-model="email"
                      />
                      <div class="invalid-feedback" v-if="errors.email">
                        <p>{{ errors.email }}</p>
                      </div>
                    </div>
                  </div>
                </div>
                <!--end form-group-->
                <div class="form-group h-row">
                  <div class="h-col-desktop-12 h-col-mobile-12 h-margin-top-l">
                    <label for="userpassword" class="h-label">Password</label>
                    <div class="input-group">
                      <span class="auth-form-icon">
                        <i class="dripicons-lock"></i>
                      </span>
                      <input
                        type="password"
                        class="h-input"
                        :class="{ 'is-invalid': errors.password }"
                        id="userpassword"
                        placeholder="Enter password"
                        v-model="password"
                      />
                      <div class="invalid-feedback" v-if="errors.password">
                        <p>{{ errors.password }}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div v-if="mode === 'pincode'">
                <div class="form-group h-row">
                  <div class="h-col-desktop-12 h-col-mobile-12">
                    <label for="pincode" class="h-label">Pincode</label>
                    <div class="input-group">
                      <span class="auth-form-icon">
                        <i class="dripicons-lock"></i>
                      </span>
                      <input
                        type="text"
                        class="h-input"
                        :class="{ 'is-invalid': errors.pincode }"
                        id="pincode"
                        placeholder="Enter pincode"
                        v-model="pincode"
                      />
                      <div class="invalid-feedback" v-if="errors.pincode">
                        <p>{{ errors.pincode }}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!--end form-group-->
              <div class="form-group h-row">
                <div class="h-col-desktop-12 h-col-mobile-12 h-margin-top-l">
                  <button class="h-btn h-btn-accent h-btn-full" type="button" @click="login">
                    Login
                  </button>
                </div>
              </div>

              <!--end form-group-->
              <div v-if="mode === 'pincode'" class="form-group h-row">
                <div class="h-col-desktop-12 h-col-mobile-12 h-margin-top-l">
                  <button
                    class="h-btn h-btn-dangerous h-btn-full"
                    type="button"
                    @click="mode = 'enter'"
                  >
                    Return
                  </button>
                </div>
              </div>
              <!--end form-group-->
              <div class="form-group h-row">
                <div class="h-col-desktop-12 h-col-mobile-12 h-text-center h-margin-top-l">
                  <router-link :to="{ name: 'RecoverPassword' }">
                    <span class="h-h6 h-bold h-secondary_shade_1"> Forgot password?</span>
                  </router-link>
                </div>
                <!--end col-->
              </div>
              <!--end form-group-->
            </form>
            <!--end form-->
          </div>
          <Loader v-if="loading" />
        </div>
        <!--end card-->
      </div>
      <!--end auth-page-->
    </div>
  </main>
  <!--end main-->
</template>

<script>
import settings from '@/settings.js';
import virgilHelper from '@/utils/virgil_helper';
import Loader from './components/Loader';

const MODE_ENTER = 'enter';
const MODE_PINCODE = 'pincode';

export default {
  name: 'Login',

  data() {
    return {
      loading: false,
      mode: MODE_ENTER,

      email: '',
      password: '',

      errors: {},
      pincode: '',
    };
  },

  watch: {
    email() {
      this.errors.email = null;
    },

    password() {
      this.errors.password = null;
    },

    pincode() {
      this.errors.pincode = null;
    },
  },

  methods: {
    async enter(response) {
      settings.currentSession = response.current_session_data;

      virgilHelper.prepareKeyMaterial(response.current_session_data.user.id.toString());
      await virgilHelper.ensurePublicKey();

      if (settings.currentSession.user.show_no_phone) {
        this.$router
          .push({
            name: 'Profile',
          })
          .catch((err) => {});
      } else if (settings.currentSession.user.email.match('^support')) {
        this.$router
          .push({
            name: 'RPMRTMStratification',
          })
          .catch((err) => {});
      } else if (settings.isBiller()) {
        this.$router
          .push({
            name: 'Documents',
          })
          .catch((err) => {});
      } else {
        this.$router
          .push({
            name: 'index',
          })
          .catch((err) => {});
      }
    },

    async login() {
      this.loading = true;

      if (this.mode === MODE_ENTER) {
        const result = await this.$api.login({
          email: this.email,
          password: this.password,
        });

        this.errors = result.errors || {};
        if (Object.keys(this.errors).length > 0) {
          this.loading = false;
        } else if (result.status === 'ok') {
          if (result.current_session_data) {
            await this.enter(result);
          } else {
            this.loading = false;
            this.mode = MODE_PINCODE;
          }
        }
      } else if (this.mode === MODE_PINCODE) {
        const result = await this.$api.post(
          `${settings.BACKEND_URL}/api/v-nurse/login-pincode`,
          null,
          {
            email: this.email,
            password: this.password,
            pincode: this.pincode,
          },
        );

        this.errors = result.errors || {};
        if (Object.keys(this.errors).length > 0) {
          this.loading = false;
        } else if (result.status === 'ok') {
          await this.enter(result);
        }
      }
    },
  },

  components: {
    Loader,
  },
};
</script>

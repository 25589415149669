<template>
  <div class="h2-table">
    <table class="table dataTable h-noborder-input table-hover">
      <thead>
        <tr>
          <th width="5%" class="text-center">Quantity</th>
          <th width="5%">Unit</th>
          <th width="5%">Name</th>
          <th width="5%" class="text-right">Action</th>
        </tr>
      </thead>

      <tbody>
        <tr v-for="ingredient of ingredients" :key="ingredient.ingredient_id">
          <td class="text-center qty-wrapper">
            <InputField
              type="number"
              :min="0"
              :classExtra="{ 'form-control-sm': true }"
              v-model="ingredient.qty"
              :error="getIngedientError(ingredient, 'qty')"
              @input="ingredientFieldChanged(ingredient, 'qty')"
            />
          </td>
          <td class="text-center unit-wrapper">
            <InputField
              type="select"
              :classExtra="{ 'form-control-sm': true }"
              v-model="ingredient.unit"
              :choices="getIngredientUnitTypes(ingredient)"
              :error="getIngedientError(ingredient, 'unit')"
              @input="ingredientFieldChanged(ingredient, 'unit')"
            />
          </td>
          <td>{{ ingredient.usda.name }}</td>
          <td class="text-right">
            <a
              href="javascript:void(0)"
              class="h-h3"
              @click="deleteIngredient(ingredient)"
            >
              <i class="fas fa-trash-alt h-dangerous" title="Delete"></i>
            </a>
          </td>
        </tr>
      </tbody>

      <MealIngredientAdd
        ref="mealIngredientAdd"
        @addIngredient="addIngredient"
      />
    </table>
  </div>
</template>


<style lang="stylus" scoped>
td.qty-wrapper {
  width: 10em;
}

td.unit-wrapper {
  width: 23em;
}
</style>


<script>
import InputField from '@/components/InputField';
import MealIngredientAdd from './MealIngredientAdd';

export default {
  props: {
    ingredients: {
      type: Array,
    },
    errors: {
      type: Object,
    },
  },

  methods: {
    getIngedientError(ingredient, fieldName) {
      if (!this.errors.ingredients) {
        return null;
      }
      return this.errors.ingredients[ingredient.ingredient_id]
        ? this.errors.ingredients[ingredient.ingredient_id][fieldName]
        : null;
    },

    setIngredientError(ingredient, fieldName, error) {
      if (!this.errors.ingredients) {
        this.errors.ingredients = {};
      }
      if (!this.errors.ingredients[ingredient.ingredient_id]) {
        this.errors.ingredients[ingredient.ingredient_id] = {};
      }
      this.errors.ingredients[ingredient.ingredient_id][fieldName] = error;
    },

    ingredientFieldChanged(ingredient, fieldName, error) {
      this.setIngredientError(ingredient, fieldName, null);

      if (fieldName === 'unit') {
        const unitGrams = ingredient.usda.units_and_grams[ingredient.unit];
        ingredient.qty_g = unitGrams * ingredient.qty;
      }

      this.$emit('ingredientsChanged');
    },

    getIngredientUnitTypes(ingredient) {
      if (ingredient.usda.units_and_grams[ingredient.unit] === undefined) {
        // looking for standard name
        const unitGrams = ingredient.qty_g / ingredient.qty;
        const unitName = Object.keys(ingredient.usda.units_and_grams).find(
          (key) => {
            return ingredient.usda.units_and_grams[key] === unitGrams;
          },
        );

        if (unitName) {
          // replacing unit name with standard
          ingredient.unit = unitName;
        } else {
          // adding non-standard unit to the list
          ingredient.usda.units_and_grams[ingredient.unit] = ingredient.qty_g / ingredient.qty;
        }
      }
      return Object.keys(ingredient.usda.units_and_grams);
    },

    deleteIngredient(ingredient) {
      const index = this.ingredients.indexOf(ingredient);
      if (index === -1) {
        return;
      }
      this.ingredients.splice(index, 1);
      this.$emit('ingredientsChanged');
    },

    addIngredient(ingredient) {
      // ingredient already validated
      this.ingredients.push(ingredient);
      this.$emit('ingredientsChanged');
      this.$emit('afterIngredientAdded');
    },

    resetAddIngredient() {
      this.$refs.mealIngredientAdd.reset();
    },
  },

  components: {
    InputField,
    MealIngredientAdd,
  },
};
</script>

<template>
  <div class="modal fade" role="dialog" aria-hidden="true" ref="modalDialog">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title mt-0">{{ titleValue }}</h5>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-hidden="true"
          >
            ×
          </button>
        </div>

        <div class="modal-body">
          <p
            v-if="!isHtml"
            :class="{
              'pre-wrap': preWrap && !breakAll,
              'break-all': breakAll,
            }"
          >
            {{ textValue }}
          </p>
          <div v-else v-html="textValue"></div>
        </div>

        <div class="modal-footer">
          <button
            v-if="actionButton"
            type="button"
            :class="`btn btn-${actionButton.btnType || 'primary'} waves-effect`"
            @click="onActionButtonClick"
          >
            {{ actionButton.text }}
          </button>

          <button
            type="button"
            class="btn btn-primary waves-effect"
            data-dismiss="modal"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
export default {
  props: {
    title: {
      type: String,
      default: 'Message',
    },
    text: {
      type: String,
      default: 'Message',
    },
    preWrap: {
      type: Boolean,
      default: true,
    },
    breakAll: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      titleValue: this.title,
      textValue: this.text,
      isShown: false,
      isHtml: false,
      actionButton: null,
    };
  },

  methods: {
    show(title, text, override, isHtml, actionButton) {
      if (this.isShown && override === false) return;
      this.isShown = true;
      this.titleValue = title || this.title;
      this.textValue = text || this.text;
      this.isHtml = isHtml;
      this.actionButton = actionButton;
      $(this.$refs.modalDialog.el).appendTo('body');
      $(this.$refs.modalDialog.el).modal('show');
      $('.modal-backdrop').last().insertBefore(this.$refs.modalDialog.el);
    },

    close() {
      $(this.$refs.modalDialog.el).modal('hide');
    },

    scrollTop(top) {
      return $(this.$refs.modalDialog.el).scrollTop(top);
    },

    onActionButtonClick() {
      this.close();
      this.actionButton.onClick();
    },
  },

  mounted() {
    $(this.$refs.modalDialog.el).on('shown.bs.modal', () => {
      this.isShown = true;
    });
    $(this.$refs.modalDialog.el).on('hidden.bs.modal', () => {
      this.isShown = false;
    });
  },

  beforeDestroy() {
    $(this.$refs.modalDialog.el).remove();
  },
};
</script>

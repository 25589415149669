<template>
  <div class="h-drawer-max79350">
    <SlottedMessageInDrawer ref="slottedMessage" title="Question">
      <template v-slot:modal-body>
        <div v-if="localEntry" :class="{ 'd-none': loading }">
          <div
            class="h-margin-bottom-l h-margin-bottom-m"
          >
            <label class="h-label">Order: </label>
            <InputField
              type="number"
              v-model="localEntry.order"
              :error="errors.order"
              @change="errors.order = null"
            />
          </div>

          <div
            class="h-margin-bottom-l h-margin-bottom-m"
          >
            <label class="h-label">Question: </label>
            <InputField
              type="textarea"
              v-model="localEntry.question"
              :error="errors.question"
              @change="errors.question = null"
            />
          </div>

          <div
            class="h-margin-bottom-l h-margin-bottom-m"
          >
            <label class="h-label">
              Answers:
            </label>
            <div v-if="localEntry.answers && localEntry.answers">
              <div
                class="d-flex align-items-center mbp"
                v-for="(answer, index) of localEntry.answers"
                :key="answer.uuid"
              >
                <InputField
                  class="flex-grow-0"
                  type="text"
                  v-model="answer.key"
                  @change="errors.key[answer.uuid] = null"
                  :error="errors.key[answer.uuid]"
                  style="margin-right: 10px"
                  placeholder="KEY"
                />
                <InputField
                  class="flex-grow-1"
                  type="text"
                  v-model="answer.value"
                  :error="errors.value[answer.uuid]"
                  @change="errors.value[answer.uuid] = null"
                  placeholder="Answer"
                />
                <InputField
                  class="flex-grow-0"
                  type="number"
                  v-model="answer.score"
                  @change="errors.score[answer.uuid] = null"
                  :error="errors.score[answer.uuid]"
                  style="margin-left: 10px"
                  placeholder="Score"
                />
                <i
                  class="fas fa-trash-alt text-danger trash-icon"
                  title="Delete"
                  @click="deleteAnswer(index)"
                ></i>
              </div>
            </div>

            <button
              type="button"
              class="h-btn h-btn-accent btn-xs"
              @click="addAnswer()"
            >
              Add
            </button>
          </div>

          <div
            class="h-margin-bottom-l h-margin-bottom-m"
          >
            <InputField
              type="checkbox"
              v-model="localEntry.is_multiselect"
              :error="errors.is_multiselect"
              @change="errors.is_multiselect = null"
              id="EditQuestionsDialog.is_multiselect"
              label="Allow multiple answers"
            />
          </div>
        </div>
        <Loader v-if="loading" />
      </template>

      <template v-slot:modal-footer>
        <button
          type="button"
          class="h-btn h-btn-accent"
          @click="save"
          :disabled="loading"
        >
          Save
        </button>
        <button
          type="button"
          class="h-btn h-btn-dangerous h-margin-left-s"
          @click="close"
          :disabled="loading"
        >
          Cancel
        </button>
      </template>
    </SlottedMessageInDrawer>
  </div>
</template>

<style lang="stylus" scoped>
.modal-dialog
  max-width: 1200px

.trash-icon
  font-size: 10rem
  margin-left: 10rem
  cursor: pointer

.mbp
  margin-bottom: 4rem
</style>

<script>
import settings from '@/settings.js';
import Loader from '@/components/Loader';
import SlottedMessageInDrawer from '@/components/dialogs/SlottedMessageInDrawer';
import InputField from '@/components/InputField';

export default {
  data() {
    return {
      localEntry: null,
      errors: {
        value: {},
        score: {},
        key: {},
      },
      loading: false,
    };
  },
  methods: {
    async save() {
      this.loading = true;
      const response = await this.$api.post(
        `${settings.BACKEND_URL}/api/v-nurse/microprotocols/question/upsert`,
        null,
        {
          data: this.localEntry,
        },
      );
      this.loading = false;
      if (response.status === 'ok') {
        this.errors = {
          value: {},
          score: {},
          key: {},
        };
        this.$emit('save', response.questions);
        this.close(true);
      } else {
        this.errors = response.errors;
        this.errors.value = this.errors.value ?? {};
        this.errors.key = this.errors.key ?? {};
        this.errors.score = this.errors.score ?? {};
      }
    },

    show(entry) {
      this.errors = {
        value: {},
        score: {},
        key: {},
      };
      this.localEntry = JSON.parse(JSON.stringify(entry));
      this.localEntry.question = this.localEntry.question ?? '';
      this.localEntry.answers = this.localEntry.answers ?? [];
      this.localEntry.answers.forEach((answer) => {
        answer.uuid = answer.uuid ?? this.$uuid.v4();
      });

      this.loading = false;
      this.$refs.slottedMessage.show();
    },

    close(force) {
      this.$refs.slottedMessage.close(force);
      this.$refs.slottedMessage.active = false;
    },

    addAnswer() {
      this.localEntry.answers.push({
        uuid: this.$uuid.v4(),
        key: '',
        value: '',
      });
      this.localEntry = {
        ...this.localEntry,
      };
    },

    deleteAnswer(index) {
      this.localEntry.answers.splice(index, 1);
      this.answers = {
        ...this.answers,
      };
    },
  },
  components: {
    Loader,
    SlottedMessageInDrawer,
    InputField,
  },
};
</script>

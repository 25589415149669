<template>
  <div>
    <!-- <div class="h-icon-tooltip">
      <img
        src="../../../../../public/assets/images/Report info.svg"
        class="h-report-icon"
      />
      <label class="h-margin-top-l"> </label>
    </div> -->
    <div
      class="h-border-right-0 h-input20 h-padding-bottom-m h-padding-top-m flex-col h-bg-w"
    >
      <div class="h-row" style="text-align: center; justify-content: center">
        <div class="h-label">Report calculation last started at:</div>
        <div
          class="h-label h-margin-left-s"
          v-bind:class="lateStarted ? 'text-red' : 'text-black'"
        >
          {{ lastStartedAt }}
        </div>
      </div>
      <div class="h-row" style="text-align: center; justify-content: center">
        <div class="h-label">last completed at:</div>
        <div
          class="h-label h-margin-left-s"
          v-bind:class="lateFinished ? 'text-red' : 'text-black'"
        >
          {{ lastFinishedAt }}
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="stylus" scoped>
.text-red {
  color: red;
}

.text-black {
  color: black;
}

.flex-col {
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
}
</style>

<script>
import moment from 'moment';
import settings from '@/settings.js';

export default {
  props: ['reportCode'],

  data() {
    return {
      reportsInfo: {},
    };
  },

  computed: {
    lastStartedAt() {
      if (!this.reportsInfo.last_started_at) return 'Unknown';
      return moment
        .utc(this.reportsInfo.last_started_at)
        .local()
        .format('YYYY-MM-DD HH:mm:ss');
    },

    lastFinishedAt() {
      if (!this.reportsInfo.last_finished_at) return 'Unknown';
      return moment
        .utc(this.reportsInfo.last_finished_at)
        .local()
        .format('YYYY-MM-DD HH:mm:ss');
    },

    lateStarted() {
      const OneDayAgo = moment().subtract(1, 'days');
      const reportDate = moment(this.reportsInfo.last_started_at);
      return reportDate <= OneDayAgo;
    },

    lateFinished() {
      const OneDayAgo = moment().subtract(1, 'days');
      const reportDate = moment(this.reportsInfo.last_finished_at);
      return reportDate <= OneDayAgo;
    },
  },

  async mounted() {
    this.reportsInfo = await this.$api.get(
      `${settings.BACKEND_URL}/api/v-nurse/reports/job-info`,
      {
        reportCode: this.reportCode,
      },

    );
  },
};
</script>

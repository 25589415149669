<template>
  <div>
    <div
      class="
        h-space-between h-row h-padding-top-l h-padding-left-l h-padding-right-l
      "
    >
      <div>
        <h3
          id="MealTitle"
          class="h-h3 h-primary_shade_1 h-padding-bottom-s d-flex"
        >
          {{ recipe.title }}
        </h3>
        <ul class="list-unstyled h-general-info recipe-info">
          <li class="col-3">
            <img
              class="mr-2"
              src="/kitchry_assets/images/icons/globe.svg"
              alt="Meal photo"
              width="25"
              height="25"
            />
            {{ recipe.cuisine }}
          </li>

          <li class="col-3">
            <img
              class="mr-2"
              src="/kitchry_assets/images/icons/people.svg"
              alt="Servings"
              width="25"
              height="25"
            />
            {{ recipe.servings }} servings
          </li>

          <li class="col-3">
            <img
              class="mr-2"
              src="/kitchry_assets/images/icons/fork.svg"
              alt="Meal photo"
              width="25"
              height="25"
            />
            {{ recipe.kcal.toFixed(1) }} kilocalories
          </li>

          <li class="col-3">
            <img
              class="mr-2"
              src="/kitchry_assets/images/icons/clock.svg"
              alt="Meal photo"
              width="25"
              height="25"
            />
            {{ recipe.cook_time }}
          </li>
        </ul>
      </div>

      <img
        :src="recipe.image"
        class="text-right d-block recipeView h-mob-top-l"
        alt="Meal photo"
      />
    </div>
    <hr class="h-hr h-margin-l" />

    <div class="h-row h-padding-left-l h-padding-right-l">
      <div
        :class="{
          'col-9 h-col-12': viewer === 'page',
          'col-8 h-col-12': viewer === 'dialog',
        }"
      >
        <div v-if="viewer === 'page'">
          <h3 class="text-center h-h3 h-primary_shade_1">Recipe</h3>
        </div>

        <MealMainInfo
          :recipe="recipe"
          :ingredients="ingredients"
          :class="{
            'h-padding-right-m': viewer === 'dialog',
          }"
        />
      </div>

      <div
        :class="{
          'col-3 h-col-12 h-mob-top-l': viewer === 'page',
          'col-4 h-col-12 h-mob-top-l': viewer === 'dialog',
        }"
      >
        <div>
          <h3 class="text-center h-h3 h-primary_shade_1 h-padding-bottom-s">
            Nutrition Facts
          </h3>
        </div>

        <MealNutritionFacts :recipe="recipe" />
      </div>
    </div>
  </div>
</template>


<script>
import MealMainInfo from './MealMainInfo';
import MealNutritionFacts from '../MealNutritionFacts';

export default {
  props: {
    recipe: {
      type: Object,
    },
    ingredients: {
      type: Array,
    },
    viewer: {
      type: String,
    },
  },

  components: {
    MealMainInfo,
    MealNutritionFacts,
  },
};
</script>

<template>
  <div>
    <h4 v-if="title">{{ title }}</h4>
    <div class="px-4">
      <InputField
        v-for="option of allOptions"
        :id="option.KEY"
        :key="option.KEY"
        type="checkbox"
        :label="option.label.toString()"
        @input="processInput(option, $event)"
      />
    </div>
  </div>
</template>


<script>
import InputField from '@/components/InputField';


export default {
  data() {
    return {
      allOptions: this.options.map((option) => {
        return {
          KEY: this.$uuid.v4(),
          label: option,
          value: option,
        };
      }),
    };
  },

  methods: {
    processInput(option, checked) {
      const value = this.value ? [...this.value] : [];
      const index = value.indexOf(option.value);
      if (checked) {
        if (index === -1) {
          value.push(option.value);
          this.$emit('input', value);
        }
      } else if (index !== -1) {
        value.splice(index, 1);
        this.$emit('input', value);
      }
    },
  },

  props: {
    title: {
      type: String,
    },
    options: {
      type: Array,
    },
    value: {
      type: null,
    },
  },

  components: {
    InputField,
  },
};
</script>

<template>
  <!-- patient info -->
  <div class="h-bg-w h-padding-l">
    <div class="h-align-items-center h-space-between">
      <h5 class="h-h3 h-primary_shade_1">Additional info</h5>
      <div class="h-h3 h-primary_shade_1">
        <a
          target="_blank"
          href="https://api-dev.healent.com/static/Bari_Test_2020-11-06.pdf"
        >
          <i class="healenticons-document"></i>
        </a>
        <a
          href="#"
          class="EditProfile"
          :data-id="clientInfo.id"
          @click.prevent="openEdit"
        >
          <i class="healenticons-edit h-margin-left-m" title="Edit"></i>
        </a>
      </div>
    </div>
    <div class="h-margin-top-m">
      <div class="h-row">
        <div class="h-align-items-center h-margin-bottom-l">
          <img
            :src="clientInfo.avatar_url || '/assets/images/users/user-8.jpg'"
            alt="User"
            class="h-xs-img"
          />
          <div class="h-margin-left-s">
            <h5 class="h-h3 h-primary_shade_1">
              {{ clientName }}
              <span v-if="isTest" class="h-h4 h-secondary_shade_1">
                {{ isTestPatient }}
              </span>
              <span v-if="isClientArchived" class="h-h4 h-secondary_shade_1">
                {{ inactiveOrPending }}
              </span>
            </h5>
            <p class="h-h4 h-secondary_shade_1">
              Last Update: {{ lastUpdatedFormatted }}
            </p>
          </div>
        </div>
      </div>
      <div class="h-row">
        <div class="col-lg-4 h-margin-top-m">
          <h4 class="h-h4 h-secondary_shade_1">Patient ID:</h4>
          <h4 class="h-h4 h-primary_shade_1 h-margin-top-s">
            {{ clientIdFormatted }}
          </h4>
        </div>
        <div class="col-lg-4 h-margin-top-m">
          <h4 class="h-h4 h-secondary_shade_1">Hospital name:</h4>
          <h4 class="h-h4 h-primary_shade_1 h-margin-top-s">
            {{ hospitalName }}
          </h4>
        </div>
        <div class="col-lg-4 h-margin-top-m">
          <h4 class="h-h4 h-secondary_shade_1">Location name:</h4>
          <h4 class="h-h4 h-primary_shade_1 h-margin-top-s">
            {{ locationName }}
          </h4>
        </div>
        <div class="col-lg-4 h-margin-top-m">
          <h4 class="h-h4 h-secondary_shade_1">Date of birth:</h4>
          <h4 class="h-h4 h-primary_shade_1 h-margin-top-s">
            {{ formattedDateOfBirth }}
          </h4>
        </div>
        <div class="col-lg-4 h-margin-top-m">
          <h4 class="h-h4 h-secondary_shade_1">BMI:</h4>
          <h4 class="h-h4 h-primary_shade_1 h-margin-top-s">
            {{ bmiValue }}
          </h4>
        </div>
        <div class="col-lg-4 h-margin-top-m">
          <h4 class="h-h4 h-secondary_shade_1">Facility Provider:</h4>
          <h4 class="h-h4 h-primary_shade_1 h-margin-top-s">
            {{ surgeonName }}
          </h4>
        </div>
        <div class="col-lg-4 h-margin-top-m">
          <h4 class="h-h4 h-secondary_shade_1">Billing Provider:</h4>
          <h4 class="h-h4 h-primary_shade_1 h-margin-top-s">
            {{ billingPhysicianName }}
          </h4>
        </div>
        <div class="col-lg-4 h-margin-top-m">
          <h4 class="h-h4 h-secondary_shade_1">Phone No:</h4>
          <h4 class="h-h4 h-primary_shade_1 h-margin-top-s">
            {{ phoneNumber }}
          </h4>
        </div>
        <div class="col-lg-4 h-margin-top-m">
          <h4 class="h-h4 h-secondary_shade_1">Accepts SMS:</h4>
          <h4 class="h-h4 h-primary_shade_1 h-margin-top-s">
            {{ sendSms }}
          </h4>
        </div>
        <div class="col-lg-4 h-margin-top-m">
          <h4 class="h-h4 h-secondary_shade_1">Weight:</h4>
          <h4 class="h-h4 h-primary_shade_1 h-margin-top-s">
            {{ clientWeight }} lbs
          </h4>
        </div>
        <div class="col-lg-4 h-margin-top-m">
          <h4 class="h-h4 h-secondary_shade_1">Confirmed at:</h4>
          <h4 class="h-h4 h-primary_shade_1 h-margin-top-s">
            {{ confirmedDateFormatted }}
          </h4>
        </div>
        <div class="col-lg-4 h-margin-top-m">
          <h4 class="h-h4 h-secondary_shade_1">Surgery date:</h4>
          <h4 class="h-h4 h-primary_shade_1 h-margin-top-s">
            {{ surgeryDateTimeFormatted }}
          </h4>
        </div>
        <div class="col-lg-4 h-margin-top-m">
          <h4 class="h-h4 h-secondary_shade_1">Discharge date:</h4>
          <h4 class="h-h4 h-primary_shade_1 h-margin-top-s">
            {{ dischargeDateTimeFormatted }}
          </h4>
        </div>
        <div class="col-lg-4 h-margin-top-m">
          <h4 class="h-h4 h-secondary_shade_1">Email:</h4>
          <h4 class="h-h4 h-primary_shade_1 h-margin-top-s">
            {{ formattedClientEmail }}
          </h4>
        </div>
        <div class="col-lg-4 h-margin-top-m">
          <h4 class="h-h4 h-secondary_shade_1">Height:</h4>
          <h4 class="h-h4 h-primary_shade_1 h-margin-top-s">
            {{ clientHeight }} inches
          </h4>
        </div>
        <div class="col-lg-4 h-margin-top-m">
          <h4 class="h-h4 h-secondary_shade_1">Risk Score:</h4>
          <h4 class="h-h4 h-primary_shade_1 h-margin-top-s">
            {{ clientRaptScore }}
          </h4>
        </div>
        <div class="col-lg-4 h-margin-top-m">
          <h4 class="h-h4 h-secondary_shade_1">Last in app activity:</h4>
          <h4 class="h-h4 h-primary_shade_1 h-margin-top-s">
            {{ lastLoginActivityFormatted }}
          </h4>
        </div>
        <div class="col-lg-4 h-margin-top-m">
          <h4 class="h-h4 h-secondary_shade_1">Primary Device:</h4>
          <h4 class="h-h4 h-primary_shade_1 h-margin-top-s">
            {{ primaryDevice }}
          </h4>
        </div>
        <div class="col-lg-4 h-margin-top-m">
          <h4 class="h-h4 h-secondary_shade_1">Phone Type:</h4>
          <h4 class="h-h4 h-primary_shade_1 h-margin-top-s">
            {{ mobileType }}
          </h4>
        </div>
        <div class="col-lg-4 h-margin-top-m">
          <h4 class="h-h4 h-secondary_shade_1">First appointment at:</h4>
          <h4 class="h-h4 h-primary_shade_1 h-margin-top-s">
            {{ firstMeetingFormatted }}
          </h4>
        </div>
        <div class="col-lg-4 h-margin-top-m">
          <h4 class="h-h4 h-secondary_shade_1">Medical Record number:</h4>
          <h4 class="h-h4 h-primary_shade_1 h-margin-top-s">
            {{ medicalRecordNumber }}
          </h4>
        </div>
        <div class="col-lg-4 h-margin-top-m">
          <h4 class="h-h4 h-secondary_shade_1">Care protocol:</h4>
          <h4 class="h-h4 h-primary_shade_1 h-margin-top-s">
            {{ careProtocolName }}
          </h4>
        </div>
        <div class="col-lg-4 h-margin-top-m">
          <h4 class="h-h4 h-secondary_shade_1">Last appointment at:</h4>
          <h4 class="h-h4 h-primary_shade_1 h-margin-top-s">
            {{ lastMeetingFormatted }}
          </h4>
        </div>
        <div class="col-lg-4 h-margin-top-m">
          <h4 class="h-h4 h-secondary_shade_1">
            Total number of appointments:
          </h4>
          <h4 class="h-h4 h-primary_shade_1 h-margin-top-s">
            {{ meetingCount }}
          </h4>
        </div>
        <div class="col-lg-4 h-margin-top-m">
          <h4 class="h-h4 h-secondary_shade_1">Assigned care provider:</h4>
          <h4 class="h-h4 h-primary_shade_1 h-margin-top-s">
            {{ assignedNurseName }}
          </h4>
        </div>
        <div class="col-lg-4 h-margin-top-m">
          <h4 class="h-h4 h-secondary_shade_1">SCS:</h4>
          <h4 class="h-h4 h-primary_shade_1 h-margin-top-s">
            {{ isSCS }}
          </h4>
        </div>
        <div v-if="clientInfo.scs" class="col-lg-4 h-margin-top-m">
          <h4 class="h-h4 h-secondary_shade_1">SCS date:</h4>
          <h4 class="h-h4 h-primary_shade_1 h-margin-top-s">
            {{ scsDateFormatted }}
          </h4>
        </div>
        <div v-if="clientInfo.scs" class="col-lg-4 h-margin-top-m">
          <h4 class="h-h4 h-secondary_shade_1">SCS satisfaction:</h4>
          <h4 class="h-h4 h-primary_shade_1 h-margin-top-s">
            {{ scsSatisfaction }}
          </h4>
        </div>
        <div class="col-lg-4 h-margin-top-m">
          <h4 class="h-h4 h-secondary_shade_1">Monitoring program:</h4>
          <h4 class="h-h4 h-primary_shade_1 h-margin-top-s">
            {{ monitoringProgram }}
          </h4>
        </div>
        <div class="col-lg-4 h-margin-top-m">
          <h4 class="h-h4 h-secondary_shade_1">Activated at:</h4>
          <h4 class="h-h4 h-primary_shade_1 h-margin-top-s">
            {{ activatedAtFormatted }}
          </h4>
        </div>
        <div class="col-lg-4 h-margin-top-m">
          <h4 class="h-h4 h-secondary_shade_1">Deactivated at:</h4>
          <h4 class="h-h4 h-primary_shade_1 h-margin-top-s">
            {{ deactivatedAtFormatted }}
          </h4>
        </div>
        <div class="col-lg-4 h-margin-top-m">
          <h4 class="h-h4 h-secondary_shade_1">Plan Start Date:</h4>
          <h4 class="h-h4 h-primary_shade_1 h-margin-top-s">
            {{ planStartDateFormatted }}
          </h4>
        </div>
        <div class="col-lg-4 h-margin-top-m">
          <h4 class="h-h4 h-secondary_shade_1">Plan End Date:</h4>
          <h4 class="h-h4 h-primary_shade_1 h-margin-top-s">
            {{ planEndDateFormatted }}
          </h4>
        </div>
        <div
          class="col-lg-4 h-margin-top-m"
          v-if="clientInfo.if_inactive_reason"
        >
          <h4 class="h-h4 h-secondary_shade_1">Status Reason:</h4>
          <h4 class="h-h4 h-primary_shade_1 h-margin-top-s">
            {{ inactiveReason }}
          </h4>
        </div>
        <div class="col-lg-4 h-margin-top-m">
          <h4 class="h-h4 h-secondary_shade_1">MRN number:</h4>
          <h4 class="h-h4 h-primary_shade_1 h-margin-top-s">
            {{ mrnValue }}
          </h4>
        </div>

        <div class="col-lg-4 h-margin-top-m" v-if="clientInfo.pain_categories">
          <h4 class="h-h4 h-secondary_shade_1">Pain categories:</h4>
          <h4 class="h-h4 h-primary_shade_1 h-margin-top-s">
            {{ painCategoriesJoined }}
          </h4>
        </div>
      </div>

      <!-- here end -->
      <div class="h-row">
        <div class="col-lg-12 h-margin-top-xl">
          <h4 class="h-h4 h-secondary_shade_1">Insurance Info:</h4>
          <ol v-if="hasInsuranceInfo">
            <li v-for="entry of clientInsuranceInfo" :key="entry.id">
              <h4 class="h-h4 h-primary_shade_1 h-margin-top-xs">
                {{ entry.name || "" }}
              </h4>
            </li>
          </ol>

          <div v-else class="h-h4 h-primary_shade_1 h-margin-top-s">
            No entries.
          </div>
          <hr class="h-hr h-margin-top-m h-margin-bottom-s" />

          <div v-if="isAdmin">
            <div class="h-margin-top-xs">
              <h4 class="h-h4 h-secondary_shade_1">Registration ID:</h4>
              <div class="break-all">
                <h4 class="h-h4 h-primary_shade_1 h-margin-top-xs">
                  {{ registrationIdValue }}
                </h4>
              </div>
              <hr class="h-hr h-margin-top-m h-margin-bottom-s" />
            </div>
            <div class="h-margin-top-xs">
              <h4 class="h-h4 h-secondary_shade_1">Voip registration ID:</h4>
              <div class="break-all">
                <h4 class="h-h4 h-primary_shade_1 h-margin-top-xs">
                  {{ voipRegistrationIdValue }}
                </h4>
              </div>
              <hr class="h-hr h-margin-top-m h-margin-bottom-s" />
            </div>
          </div>
        </div>

        <div class="col-lg-12 h-margin-top-m">
          <h4 class="h-h4 h-secondary_shade_1">Diagnosis:</h4>
          <ol v-if="hasDiagnoses">
            <li
              v-for="clientDiagnose of clientDiagnoses"
              :key="clientDiagnose.id"
            >
              <h4 class="h-h4 h-primary_shade_1 h-margin-top-xs">
                [{{
                  clientDiagnose.diagnose_type
                    ? clientDiagnose.diagnose_type.toUpperCase()
                    : ""
                }}]
                {{
                  clientDiagnose.diagnose && clientDiagnose.diagnose.title
                    ? clientDiagnose.diagnose.title
                    : ""
                }}
              </h4>
            </li>
          </ol>

          <div v-else class="h-h4 h-primary_shade_1 h-margin-top-xs">
            No entries.
          </div>
          <hr class="h-hr h-margin-top-m h-margin-bottom-s" />
        </div>
        <div class="col-lg-12 h-margin-top-m">
          <h4 class="h-h4 h-secondary_shade_1">
            Outstanding Issues or Concerns:
          </h4>
          <div class="h-h4 h-primary_shade_1 h-margin-top-xs">
            {{ outstandingIssuesOrConcernsValue }}
          </div>
          <hr class="h-hr h-margin-top-m h-margin-bottom-s" />
        </div>

        <div class="h-margin-top-m col-lg-12">
          <h4 class="h-h4 h-secondary_shade_1">
            Wellness and Requirements for Service:
          </h4>
          <div class="h-h4 h-primary_shade_1 h-margin-top-xs">
            {{ clearanceNeedsValue }}
          </div>
          <hr class="h-hr h-margin-top-m h-margin-bottom-s" />
        </div>

        <div class="h-margin-top-m col-lg-12">
          <h4 class="h-h4 h-secondary_shade_1">Provider List:</h4>
          <div class="h-h4 h-primary_shade_1 h-margin-top-xs">
            {{ followUpIssuesValue }}
          </div>
          <hr class="h-hr h-margin-top-m h-margin-bottom-s" />
        </div>

        <div class="col-lg-12 h-margin-top-m">
          <h4 class="h-h4 h-secondary_shade_1">Where they stand:</h4>
          <div class="h-h4 h-primary_shade_1 h-margin-top-xs">
            {{ whereTheyStandValue }}
          </div>
          <hr class="h-hr h-margin-top-m h-margin-bottom-s" />
        </div>

        <div class="col-lg-12 h-margin-top-m">
          <h4 class="h-h4 h-secondary_shade_1">Repeat check in:</h4>
          <div class="h-h4 h-primary_shade_1 h-margin-top-xs">
            {{ repeatCheckInValue }}
          </div>
          <hr class="h-hr h-margin-top-m h-margin-bottom-s" />
        </div>
      </div>

      <!-- here end -->
      <div class="h-row">
        <div class="col-lg-4 h-margin-top-m">
          <h4 class="h-h4 h-secondary_shade_1">CPTE Factor:</h4>
          <h4 class="h-h4 h-primary_shade_1 h-margin-top-s">
            {{ isTracedValue }}
          </h4>
        </div>
      </div>

      <div class="h-row h-margin-top-m" style="justify-content: center">
        <div
          class="h-btn h-btn-accent"
          style="align-content: center"
          @click="resendActivation"
        >
          Resend registration Email and SMS
        </div>
      </div>
    </div>
    <EditProfileInfoTabDrawer
      ref="EditProfileInDrawer"
      :client="clientInfo"
      :key="client.id"
      @updateSuccess="updateSuccess"
    />
  </div>
</template>

<style scoped lang="stylus">
.info-table {
  th, td {
    padding: 0.156vw;
  }

  td {
    padding-right: 6.667vw;
  }

  th {
    padding-right: 0.833vw;
    text-align: right;
    font-weight: 600;
  }
}

.edit-button {
  min-width: 8.333vw;
}
</style>

<script>
import moment from 'moment-timezone';
import settings from '@/settings.js';
import eventBus from '../../../../../event-bus';
import EditProfileInfoTabDrawer from '../../../edit_patient/EditProfileInfoTabDrawer';

export default {
  props: ['client'],

  data() {
    return {
      clientInfo: this.client,
    };
  },

  filters: {
    formatDate(date) {
      if (!date) {
        return '-';
      }
      return moment(date).format('MMM D, YYYY');
    },
    formatDateWithH(date) {
      if (!date) {
        return '-';
      }
      return moment(date).format('MMM D, YYYY h:mm a');
    },
  },
  methods: {
    formatEmail(email) {
      if (!email) return '-';
      if (email.indexOf('@nomail.healent.com') !== -1) {
        return '-';
      }
      return email;
    },

    async openEdit() {
      try {
        this.$refs.EditProfileInDrawer.show(this.clientInfo);
      } catch (error) {
        console.error(error);
      }
    },

    updateSuccess(client) {
      this.clientInfo = client;
    },

    async resendActivation() {
      const response = await this.$api.post(
        `${settings.BACKEND_URL}/api/v-nurse/clients/resend-registration-email`,
        {
          clientId: this.client.id,
        },
      );
      console.log('resend activation', this.clientInfo.id);
    },
  },

  beforeDestroy() {
    eventBus.$offOwner(this);
  },

  mounted() {
    eventBus.$on(
      'currentPatientActivated',
      () => {
        this.clientInfo.is_archived = false;
        this.clientInfo.pending_activation = false;
      },
      this,
    );
    this.$el.setAttribute('data-id', this.clientInfo.id);
  },

  computed: {
    clientName() {
      return this.clientInfo && this.clientInfo.name
        ? this.clientInfo.name
        : '<No data>';
    },
    isTest() {
      return this.clientInfo && this.clientInfo.is_test;
    },
    isClientArchived() {
      return this.clientInfo && this.clientInfo.is_archived;
    },
    lastUpdatedFormatted() {
      if (this.clientInfo && this.clientInfo.last_updated_at) {
        const locaDate = this.$dateUtils.toLocalDatetime(
          this.clientInfo.last_updated_at,
        );
        return this.$options.filters.formatDateWithH(locaDate);
      }
      return '<No data>';
    },
    clientIdFormatted() {
      return this.clientInfo && this.clientInfo.id
        ? `#${this.clientInfo.id}`
        : '-';
    },
    hospitalName() {
      return this.clientInfo && this.clientInfo.hospital_name
        ? this.clientInfo.hospital_name
        : '-';
    },
    locationName() {
      return this.clientInfo && this.clientInfo.location_name
        ? this.clientInfo.location_name
        : '-';
    },
    formattedDateOfBirth() {
      if (this.clientInfo && this.clientInfo.date_of_birth !== null) {
        return this.$options.filters.formatDate(this.clientInfo.date_of_birth);
      }
      return '-';
    },
    bmiValue() {
      return this.clientInfo && this.clientInfo.bmi ? this.clientInfo.bmi : '-';
    },
    billingPhysicianName() {
      return this.clientInfo && this.clientInfo.billing_physician
        ? this.clientInfo.billing_physician.name
        : '-';
    },
    surgeonName() {
      return this.clientInfo &&
        this.clientInfo.surgeon &&
        this.clientInfo.surgeon.name
        ? this.clientInfo.surgeon.name
        : '-';
    },
    phoneNumber() {
      return this.clientInfo && this.clientInfo.phone
        ? this.clientInfo.phone
        : '-';
    },
    sendSms() {
      return this.clientInfo && this.clientInfo.send_sms ? 'Yes' : 'No';
    },
    clientWeight() {
      return this.clientInfo && this.clientInfo.weight
        ? this.clientInfo.weight
        : '-';
    },
    confirmedDateFormatted() {
      if (this.clientInfo && this.clientInfo.confirmed_at !== null) {
        const locaDate = this.$dateUtils.toLocalDatetime(
          this.clientInfo.confirmed_at,
        );
        return this.$options.filters.formatDateWithH(locaDate);
      }
      return '-';
    },
    surgeryDateTimeFormatted() {
      if (this.clientInfo && this.clientInfo.surgery_date !== null) {
        const combinedDateTime = this.$dateUtils.moment(
          `${this.clientInfo.surgery_date} ${this.clientInfo.surgery_time}`,
        );
        return this.$options.filters.formatDateWithH(combinedDateTime);
      }
      return '-';
    },
    dischargeDateTimeFormatted() {
      if (this.clientInfo && this.clientInfo.discharge_date !== null) {
        const combinedDateTime = this.$dateUtils.moment(
          `${this.clientInfo.discharge_date}`,
        );
        return this.$options.filters.formatDate(combinedDateTime);
      }
      return '-';
    },
    formattedClientEmail() {
      return this.formatEmail(this.clientInfo && this.clientInfo.email);
    },
    clientHeight() {
      return this.clientInfo && this.clientInfo.height
        ? this.clientInfo.height
        : '-';
    },
    clientRaptScore() {
      return this.clientInfo && this.clientInfo.rapt_score
        ? this.clientInfo.rapt_score
        : '-';
    },
    lastLoginActivityFormatted() {
      if (this.clientInfo && this.clientInfo.last_login_activity !== null) {
        return this.$options.filters.formatDateWithH(
          this.clientInfo.last_login_activity,
        );
      }
      return '-';
    },
    primaryDevice() {
      return this.clientInfo && this.clientInfo.primary_device
        ? this.clientInfo.primary_device
        : '-';
    },
    mobileType() {
      return this.clientInfo && this.clientInfo.mobile_type
        ? this.clientInfo.mobile_type
        : '-';
    },
    firstMeetingFormatted() {
      if (this.clientInfo && this.clientInfo.first_meeting_datetime) {
        const locaDate = this.$dateUtils.toLocalDatetime(
          this.clientInfo.first_meeting_datetime,
        );
        return this.$options.filters.formatDateWithH(locaDate);
      }
      return '-';
    },
    medicalRecordNumber() {
      return this.clientInfo && this.clientInfo.medical_record_number
        ? this.clientInfo.medical_record_number
        : '-';
    },
    careProtocolName() {
      return this.clientInfo &&
        this.clientInfo.care_protocol &&
        this.clientInfo.care_protocol.name
        ? this.clientInfo.care_protocol.name
        : '-';
    },
    lastMeetingFormatted() {
      if (this.clientInfo && this.clientInfo.last_meeting_datetime) {
        const locaDate = this.$dateUtils.toLocalDatetime(
          this.clientInfo.last_meeting_datetime,
        );
        return this.$options.filters.formatDateWithH(locaDate);
      }
      return '-';
    },
    meetingCount() {
      return this.clientInfo && this.clientInfo.meeting_count !== null
        ? this.clientInfo.meeting_count
        : '-';
    },
    assignedNurseName() {
      return this.clientInfo && this.clientInfo.assigned_nurse
        ? this.clientInfo.assigned_nurse.name
        : '-';
    },
    isSCS() {
      return this.clientInfo && this.clientInfo.scs ? 'Yes' : 'No';
    },
    scsDateFormatted() {
      if (this.clientInfo && this.clientInfo.scs_date !== null) {
        return this.$options.filters.formatDate(this.clientInfo.scs_date);
      }
      return '-';
    },
    scsSatisfaction() {
      return this.clientInfo &&
        typeof this.clientInfo.scs_satisfaction !== 'undefined'
        ? this.clientInfo.scs_satisfaction
        : '-';
    },
    monitoringProgram() {
      return this.clientInfo &&
        typeof this.clientInfo.monitoring_program !== 'undefined'
        ? this.clientInfo.monitoring_program
        : '-';
    },
    activatedAtFormatted() {
      if (this.clientInfo && this.clientInfo.activated_at !== null) {
        return this.$options.filters.formatDate(this.clientInfo.activated_at);
      }
      return '-';
    },
    deactivatedAtFormatted() {
      if (this.clientInfo && this.clientInfo.deactivated_at !== null) {
        return this.$options.filters.formatDate(this.clientInfo.deactivated_at);
      }
      return '-';
    },
    planStartDateFormatted() {
      if (this.clientInfo && this.clientInfo.plan_start_date !== null) {
        return this.$options.filters.formatDate(
          this.clientInfo.plan_start_date,
        );
      }
      return '-';
    },
    planEndDateFormatted() {
      if (this.clientInfo && this.clientInfo.plan_end_date !== null) {
        return this.$options.filters.formatDate(this.clientInfo.plan_end_date);
      }
      return '-';
    },
    inactiveReason() {
      return this.clientInfo &&
        typeof this.clientInfo.if_inactive_reason !== 'undefined'
        ? this.clientInfo.if_inactive_reason
        : '-';
    },
    mrnValue() {
      return this.clientInfo && this.clientInfo.mrn ? this.clientInfo.mrn : '-';
    },
    painCategoriesJoined() {
      if (this.clientInfo && Array.isArray(this.clientInfo.pain_categories)) {
        return this.clientInfo.pain_categories.join(', ');
      }
      return '-';
    },
    registrationIdValue() {
      return this.clientInfo && this.clientInfo.registration_id
        ? this.clientInfo.registration_id
        : '-';
    },
    voipRegistrationIdValue() {
      return this.clientInfo && this.clientInfo.voip_registration_id
        ? this.clientInfo.voip_registration_id
        : '-';
    },
    outstandingIssuesOrConcernsValue() {
      return this.clientInfo && this.clientInfo.outstanding_issues_or_concerns
        ? this.clientInfo.outstanding_issues_or_concerns
        : '-';
    },
    clearanceNeedsValue() {
      return this.clientInfo && this.clientInfo.clearance_needs
        ? this.clientInfo.clearance_needs
        : '-';
    },
    followUpIssuesValue() {
      return this.clientInfo && this.clientInfo.follow_up_issues
        ? this.clientInfo.follow_up_issues
        : '-';
    },
    whereTheyStandValue() {
      return this.clientInfo && this.clientInfo.where_they_stand
        ? this.clientInfo.where_they_stand
        : '-';
    },
    repeatCheckInValue() {
      return this.clientInfo && this.clientInfo.repeat_check_in
        ? this.clientInfo.repeat_check_in
        : '-';
    },
    isTracedValue() {
      return this.clientInfo && this.clientInfo.is_traced ? 'Yes' : 'No';
    },
    hasDiagnoses() {
      return (
        this.clientInfo &&
        this.clientInfo.diagnoses &&
        this.clientInfo.diagnoses.length > 0
      );
    },
    clientDiagnoses() {
      return this.hasDiagnoses ? this.clientInfo.diagnoses : [];
    },
    hasInsuranceInfo() {
      return (
        this.clientInfo &&
        this.clientInfo.insurance_info &&
        this.clientInfo.insurance_info.length > 0
      );
    },
    clientInsuranceInfo() {
      return this.hasInsuranceInfo ? this.clientInfo.insurance_info : [];
    },
    isTestPatient() {
      return this.clientInfo && this.clientInfo.is_test ? '(Test patient)' : '';
    },
    inactiveOrPending() {
      if (this.clientInfo.is_archived) {
        if (this.clientInfo.pending_activation) return '(pending)';
        return '(inactive)';
      }
      return '';
    },
    isAdmin() {
      return settings.hasRole('system_administrator');
    },
    oximeterNotificationSetAt() {
      return this.clientInfo.oximeter_reminder_at
        ? this.clientInfo.oximeter_reminder_at
        : 'N/A';
    },
    lastDailyOximeterNotification() {
      return this.clientInfo.last_daily_oximeter_reminder_at
        ? this.clientInfo.last_daily_oximeter_reminder_at
        : 'N/A';
    },
  },
  components: {
    EditProfileInfoTabDrawer,
  },
};
</script>

<template>
  <SlottedMessageInDrawer
    ref="SlottedMessageInDrawer"
    title="Resend Welcome SMS?"
    :closeDisabled="loading">
    <template v-slot:modal-body>
      <div v-if="!loading && data" class="h-h4 h-primary_shade_1">
        Send welcome SMS to
        <strong>{{ data["Patient Name"] || "-" }}</strong> (#{{ data.phone || "-" }}) ?
      </div>

      <Loader v-else />
    </template>

    <template v-slot:modal-footer>
      <button
        type="button"
        class="h-btn h-btn-accent"
        @click="confirm"
        :disabled="loading">
        Confirm
      </button>

      <button
        type="button"
        class="h-btn h-btn-dangerous h-margin-left-s"
        @click="close"
        :disabled="loading">
        Close
      </button>
    </template>
  </SlottedMessageInDrawer>
</template>

<script>
import settings from '@/settings.js';
import Loader from '@/components/Loader';
import SlottedMessageInDrawer from '@/components/dialogs/SlottedMessageInDrawer';

export default {
  data() {
    return {
      loading: false,
      data: null,
    };
  },

  methods: {
    show(data) {
      this.data = data;
      console.log('Modal Data:', this.data); // Log the data to check if the phone is included
      this.loading = false;
      this.$refs.SlottedMessageInDrawer.show();
    },

    close(force) {
      this.$refs.SlottedMessageInDrawer.close(force);
    },

    async confirm() {
      this.loading = true;
      const response = await this.$api.get(
        `${settings.BACKEND_URL}/api/v-nurse/account/resend-activation-code`,
        { id: this.data.id },
      );
      if (response.status === 'ok') {
        $.notify('Welcome SMS sent.', {
          position: 'top center',
          className: 'success',
        });
      } else {
        $.notify('Failed to send the SMS.', {
          position: 'top center',
          className: 'error',
        });
      }
      this.close(true);
    },
  },

  components: {
    Loader,
    SlottedMessageInDrawer,
  },
};
</script>

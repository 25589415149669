<template>
  <div>
    <div
      class="
        h-add-patient
        h-padding-top-l
        h-mobile-padding-left-l
        h-mobile-padding-right-l
      "
    >
      <div class="h-col-desktop-8 h-col-screen-m h-col-mobile-12 h-margin-auto">
        <div
          v-if="currentTab === 'tabNewMember'"
          class="h-h1 h-primary_shade_1"
        >
          <InviteUser />
        </div>
        <div v-if="currentTab === 'tabCsvLoad'" class="h-h1 h-primary_shade_1">
          <LoadUsersCSV />
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import settings from '@/settings.js';
import InviteUser from './InviteUser';
import LoadUsersCSV from './LoadUsersCsv';
import eventBus from '../../../../event-bus';

export default {
  data() {
    return {
      dietData: null,
      loading: true,

      tabs: [
        { title: 'Individual Member', value: 'tabNewMember' },
        // { title: "Multiple Members", value: "tabCsvLoad" },
      ],
      currentTab: 'tabNewMember',
    };
  },
  beforeCreate() {
    if (settings.getInviteRoles().length === 0) {
      this.$router.push({ name: 'Dashboard' });
    }
  },

  async mounted() {
    window.execAfterInitMenuInterface(() => {
      window.initActiveMenu('left-navigation-link>settings>staff>invite');
    });

    const payload = {
      tabInfo: this.tabs,
      activeTab: localStorage.lastSelectedTabInPatientProfile
        ? localStorage.lastSelectedTabInPatientProfile
        : this.currentTab,
      updateCurrentTab: true,
    };
    eventBus.$emit('sendingTabArrayInfo', { payload });

    eventBus.$on('tabSelectedInTopBar', (event) => {
      this.currentTab = event.newTab;
    }, this);

    this.loading = false;
  },

  beforeDestroy() {
    eventBus.$offOwner(this);
    const payload = {
      tabInfo: [],
      activeTab: '',
      updateCurrentTab: false,
    };
    eventBus.$emit('sendingTabArrayInfo', { payload });
    eventBus.$off('tabSelectedInTopBar');
  },
  components: {
    InviteUser,
    LoadUsersCSV,
  },
};
</script>

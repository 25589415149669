<template>
  <div style="height: 100vh">
    <div class="h-col-desktop-11  h-col-screen-m h-col-mobile-12 h-margin-auto">
      <div class="step-app">
        <div class="form step-content" v-if="!loading">
          <div class="h-row h-col-desktop-12 h-col-mobile-12">
            <div class="h-col-desktop-3 h-col-mobile-12 h-margin-right-m  margin-b-4">
              <label class="h-label"> Name: </label>
              <InputField
                id="input_name"
                type="text"
                v-model="formInfo.name"
                :error="errors.name"
                @input="() => {errors.name = null}"
              />
              <div :class="['invalid-feedback', { 'd-block': errors.name }]">
                This field is required
              </div>
            </div>
            <div class="h-col-desktop-3 h-col-mobile-12 h-margin-right-m margin-b-4">
              <label class="h-label"> Hospital: </label>
              <HospitalSelector
                v-model="formInfo.hospitalId" :value="formInfo.hospitalId" @hospital="setHospital"/>
            </div>
            <div class="h-col-desktop-3 h-col-mobile-12  margin-b-4">
              <label class="h-label"> Type: </label>
              <InputField type="select" v-model="formInfo.type" :choices="typeChoices"/>
            </div>
          </div>
          <FormBuilder :form="form" :options="options" @change="handleChange"/>
        </div>
        <div class="step-footer h-step-fix-footer">
          <div class="h-col-desktop-8 h-col-screen-m h-col-mobile-12 h-margin-auto">
            <div class="h-text-right">
              <button
                type="button"
                class="h-btn h-btn-dangerous h-margin-right-s"
                @click="cancel"
              >
                Cancel
              </button>
              <button type="button" class="h-btn h-btn-accent" @click="save(false)">
                Save
              </button>
            </div>
          </div>
          <div class="h-hide">
            <ul class="step-steps">
              <li data-step-target="step1">Step 1</li>
              <li data-step-target="step2">Step 2</li>
              <li data-step-target="step3">Step 3</li>
            </ul>
          </div>
        </div>
        <link href="https://cdn.jsdelivr.net/npm/formiojs@4.18.1/dist/formio.full.min.css"
              rel="stylesheet">
      </div>
    </div>
  </div>
</template>

<script>
import { FormBuilder } from 'vue-formio';
import HospitalSelector from '@/components/HospitalSelector';
import InputField from '@/components/InputField';

export default {
  name: 'FormBuild',
  components: {
    FormBuilder,
    HospitalSelector,
    InputField,
  },
  data() {
    return {
      loading: true,
      fromId: parseInt(this.$route.params.formId, 10),
      name: null,
      hospitalId: null,
      typeId: null,
      formInfo: {
        hospitalId: null,
        type: null,
        name: null,
        formData: {},
      },
      errors: {},
      formData: {},
      form: {
        display: 'pdf',
        settings: {
        },
      },
      typeChoices: [
        { value: 'registration', title: 'Registration' },
        { value: 'evaluation', title: 'Evaluation' },
      ],
      options: {},
    };
  },
  async mounted() {
    this.loading = true;
    try {
      const response = await this.$api.getForm(this.fromId);
      this.formInfo = response.data;
      this.form = this.formInfo.formData;
    } catch {
      this.formInfo = null;
    }
    this.loading = false;
  },
  methods: {
    handleChange(schema) {
      this.formInfo.formData = schema;
    },
    setHospital(hospital) {
    },
    async save() {
      this.loading = true;
      await this.$api.updateFormBuilder(this.formInfo);
      this.loading = false;
    },
    async cancel() {
      await this.$router.push({
        name: 'Forms',
      });
    },
  },
};
</script>
<style scoped>
>>> .h-input {
  height: 20rem !important;
}

.margin-b-4 {
  margin: 5px 20px 20px 0;
}

>>> .formbuilder .builder-sidebar_search {
  height: 20rem !important;
  border: none !important;
  border-bottom: 1px #A1A3B3 solid !important;
  border-radius: 0 !important;
  width: 100% !important;
  background: #f7f8fc !important;
}

>>> iframe {
  height: calc(100vh - 120rem) !important;
}

>>> .formbuilder .formcomponent {
  text-align: left;
  font-size: 1.0em;
  line-height: 2.2;
  padding-left: 20px;
  border-radius: 0.3em;
  border: 1px #D6D7DD solid;
  color: #152145;
  background: #f7f8fc !important;
}

>>> .formbuilder > div:first-child {
  /*transform: translate(-40px, 200px);*/
}

>>> .formbuilder .builder-sidebar_scroll {
  overflow-y: clip !important;
}

</style>

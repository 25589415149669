<template>
  <div>
    <div class="h-space-between h-align-items-center">
      <h3 class="h-h3 h-primary_shade_1">Email list</h3>
      <div class="h-d-flex">
        <button
          style="width: max-content"
          class="h-btn h-btn-accent h-margin-left-m h-align-items-center"
          @click="addEmail"
          v-show="isOpen"
        >
          <i
            style="line-height: 1; font-size: 10rem"
            class="h-secondary_shade_5 healenticons-plus h-btn-with-icon h-padding-right-s"
          ></i
          >Add Email
        </button>
        <button
          style="width: max-content"
          class="h-btn h-btn-accent h-margin-left-m h-align-items-center"
          @click="$emit('toggleSection')"
        >
          <i
            :class="[
              'h-arrow-down',
              'h-secondary_shade_5',
              'healenticons-arrow-ios-forward-fill',
              'h-btn-with-icon',
              { up: isOpen },
            ]"
          ></i
          >{{ isOpen ? "Collapse" : "Expand" }}
        </button>
      </div>
    </div>

    <transition name="slide">
      <div v-show="isOpen" class="h1-table h-w-100">
        <table class="table dataTable h-noborder-input h-margin-bottom-0">
          <thead class="thead-light">
            <tr>
              <th width="40%">Email</th>
              <th>Description</th>
              <th class="text-right w-1">Action</th>
            </tr>
          </thead>

          <tbody v-if="emails.length !== 0">
            <tr v-for="email of emails" :key="getEmailId(email)" :email="email">
              <td>
                <InputField
                  type="text"
                  v-model="email.email"
                  placeholder="Enter Email"
                  :error="getEmailErrors(email).email"
                  @change="clearEmailError(email, 'email')"
                />
              </td>
              <td>
                <InputField
                  type="text"
                  v-model="email.description"
                  placeholder="Enter description"
                  :error="getEmailErrors(email).description"
                  @change="clearEmailError(email, 'description')"
                />
              </td>
              <td>
                <i
                  class="fas fa-trash-alt text-danger font-16 cursor-pointer"
                  title="Delete"
                  @click="
                    () => {
                      removeEmail(email);
                    }
                  "
                ></i>
              </td>
            </tr>
          </tbody>

          <tbody v-else>
            <tr>
              <td colspan="4" class="text-center">No emails</td>
            </tr>
          </tbody>
        </table>
      </div>
    </transition>
  </div>
</template>

<style scoped lang="stylus">
label {
  margin-top: 0.833vw;
}

td {
  vertical-align: top;
}

td:last-child {
  vertical-align: middle;
}
</style>

<script>
import InputField from '@/components/InputField';

export default {
  data() {
    return {
      lastEmailId: 0,
    };
  },

  methods: {
    clearEmailError(email, field) {
      const errors = this.getEmailErrors(email);
      errors[field] = null;
    },

    addEmail() {
      this.lastEmailId += 1;
      let uiId = `uiId:${this.lastEmailId}`;
      // eslint-disable-next-line no-loop-func
      while (this.emails.find(e => e.uiId === uiId)) {
        this.lastEmailId += 1;
        uiId = `uiId:${this.lastEmailId}`;
      }
      this.emails.push({
        uiId,
        type: '',
        number: '',
        extra: '',
        description: '',
      });
    },

    getEmailId(email) {
      if (email.id) return email.id;
      return email.uiId;
    },

    getEmailErrors(email) {
      if (!this.errors) return {};
      return this.errors[this.getEmailId(email)] || {};
    },

    removeEmail(email) {
      this.emails.splice(this.emails.indexOf(email), 1);
      if (this.errors) {
        if (this.errors[this.getEmailId(email)]) {
          delete this.errors[this.getEmailId(email)];
        }
      }
    },
  },

  components: {
    InputField,
  },

  props: {
    errors: {
      type: Object,
      required: true,
    },
    emails: {
      type: Array,
      required: true,
    },
    isOpen: {
      type: Boolean,
      required: true,
    },
  },
};
</script>

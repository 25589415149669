<template>
  <div v-if="memberSet">
    <div
      v-if="gcalendar_error_message"
      class="alert alert-danger border-0 alert-dismissible mb-4"
      role="alert"
    >
      <strong>Failed to connect Google calendar:</strong>
      {{ gcalendar_error_message }}
    </div>

    <div
      v-if="gcalendar_is_success"
      class="alert alert-success border-0 alert-dismissible mb-4"
      role="alert"
    >
      Successfully connected Google calendar.
    </div>

    <form v-if="memberSet" method="post">
      <div class="photo-wrapper">
        <input
          type="file"
          class="dropify input-file-now-custom-1"
          :data-default-file="this.photoUrl || '/assets/images/users/patient-pro.png'"
          ref="photo"
        />
      </div>
      <p v-if="errors.photo" class="text-danger">Photo: {{ errors.photo }}</p>
    </form>

    <form v-if="memberSet" class="h-form-btn h-margin-top-l">
      <div class="h-row">
        <div class="h-input-w49 h-desktop-margin-right-2 h-col-mobile-12 h-margin-bottom-l">
          <label class="h-label">Title</label>
          <InputField
            type="text"
            v-model="member.title"
            :error="errors.title"
            @input="
              () => {
                errors.title = null;
              }
            "
          />
        </div>

        <div class="h-input-w49 h-col-mobile-12 h-margin-bottom-l">
          <label class="h-label">Name</label>

          <InputField
            type="text"
            v-model="member.name"
            :error="errors.name"
            @input="
              () => {
                errors.name = null;
              }
            "
          />
        </div>

        <div class="h-input-w49 h-desktop-margin-right-2 h-col-mobile-12 h-margin-bottom-l">
          <label class="h-label">E-Mail</label>

          <input
            type="text"
            class="h-input"
            :value="member.email"
            :error="errors.email"
            @input="
              () => {
                errors.email = null;
              }
            "
            disabled
          />
        </div>

        <div class="h-input-w49 h-col-mobile-12 h-margin-bottom-l">
          <label class="h-label">Registration code</label>

          <InputField
            type="text"
            v-model="member.registration_code"
            :error="errors.registration_code"
            @input="
              () => {
                errors.registration_code = null;
              }
            "
          />
        </div>

        <div class="h-input-w49 h-desktop-margin-right-2 h-col-mobile-12 h-margin-bottom-l">
          <label class="h-label">Date of birth</label>
          <InputField
            type="date"
            id="DateOfBirth"
            v-model="member.date_of_birth"
            :error="errors.date_of_birth"
            @input="
              () => {
                errors.date_of_birth = null;
              }
            "
          />
          <div class="invalid-feedback notDateOfBirth" style="display: none">
            The entered date is not accepted
          </div>
        </div>

        <div class="h-input-w49 h-col-mobile-12 h-margin-bottom-l">
          <label class="h-label">Gender</label>

          <InputField
            type="select"
            :choices="genderChoices"
            v-model="member.gender"
            :error="errors.gender"
            @input="
              () => {
                errors.gender = null;
              }
            "
          />
        </div>
        <div class="h-w-100 h-margin-top-l">
          <PhoneListEditor :phones="member.phones" :errors="errors.phones" />
        </div>

        <div class="h-w-100 h-margin-top-l">
          <HospitalsAndRoles
            :member="member"
            :allHospitals="hospitals"
            :readonly="
              !settings.hasRole('system_administrator') && !settings.hasRole('nurse_administrator')
            "
            @hospitalEnabledChanged="handleHospitalEnabledChange"
          />
        </div>

        <div class="h-w-100 h-margin-top-l">
          <NotifyUsersEditor
            :member="member"
            :errors="errors.notifyUsers"
            ref="notifyUsersEditor"
          />
        </div>

        <div class="h-margin-top-l h-margin-bottom-xl" v-if="isCurrentUser">
          <h3 class="h-h3 h-primary_shade_1">Change password</h3>
          <router-link
            :to="{ name: 'SettingsChangePassword' }"
            class="h-btn h-btn-accent h-a-btn h-margin-top-l"
            >Change password</router-link
          >
        </div>

        <div class="h-w-100">
          <div class="telehealth telehealth-border">
            <InputField
              v-if="showAllowCommunicationMessaging"
              class="h-margin-bottom-s"
              :class="{ 'h-padding-top-m': showGoogleCalendarIntegration }"
              type="checkbox"
              v-model="member.allow_communication_messaging"
              id="allow_communication_messaging"
              label="Allow communication messaging"
            />
            <div v-if="showGoogleCalendarIntegration">
              <h3 class="h-h3 h-primary_shade_1 h-margin-bottom-l">Google calendar</h3>
              <p class="h-h4 h-primary_shade_1 h-margin-bottom-s">
                Email used:
                <strong>{{ settings.currentSession.user.email }}</strong>
              </p>
              <p class="h-h4 h-primary_shade_1 h-margin-bottom-s">
                Google calendar connected:
                <span
                  :class="{
                    'h-safe': telehealth.is_google_calendar_integrated,
                    'h-dangerous': !telehealth.is_google_calendar_integrated
                  }"
                >
                  {{ telehealth.is_google_calendar_integrated ? "Yes" : "No" }}
                </span>
              </p>
              <p class="h-text-center h-margin-bottom-s">
                <a :href="telehealth.calendar_oauth_uri" class="h-btn h-btn-accent h-a-btn">
                  {{ telehealth.is_google_calendar_integrated ? "Re-establish" : "Establish" }}
                  connection to your <strong>Google Calendar</strong> account
                </a>
              </p>
              <p class="h-text-center h-margin-bottom-s">
                <a
                  @click="revokeCalendar"
                  class="h-btn h-btn-dangerous h-a-btn"
                  v-bind:class="{
                    'btn-outline-pink': telehealth.is_google_calendar_integrated
                  }"
                >
                  Revoke connection to your
                  <strong>Google Calendar</strong> account
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import settings from '@/settings.js';
import InputField from '@/components/InputField';
import strUtils from '@/utils/str_utils';
import PhoneListEditor from './PhoneListEditor';
import HospitalsAndRoles from './HospitalsAndRoles';
import NotifyUsersEditor from './NotifyUsersEditor';
import eventBus from '../../../../../event-bus';

export default {
  props: ['loading', 'memberData', 'errors', 'careProtocols', 'isProfilePage'],

  data() {
    return {
      gcalendar_error_message: null,
      gcalendar_is_success: null,
      member: {},
      memberSet: false,
      photoUrl: '',
      removePhoto: false,
      strUtils,
      genderChoices: [
        [null, ''],
        ['Male', 'Male'],
        ['Female', 'Female'],
      ],
      hospitals: [],
      settings,
      telehealth: {},
      revokeStatus: null,
    };
  },

  computed: {
    canSwitchHospitalsAndRoles() {
      return settings.hasRole('nurse_administrator') || settings.hasRole('system_administrator');
    },

    showAllowCommunicationMessaging() {
      return settings.hasRole('system_administrator');
    },

    showGoogleCalendarIntegration() {
      return true; // always show it
    },

    isCurrentUser() {
      return settings.currentSession.user.id === this.member.id;
    },
  },

  watch: {
    '$route.query': () => {
      this.gcalendar_error_message = this.$route.query.error_message;
      this.gcalendar_is_success = this.$route.query.gcalendar_is_success === 'true';
    },
  },

  methods: {
    handleHospitalEnabledChange({ hospitalId, enabled }) {
      const hospitalIndex = this.member.hospitals.findIndex(id => id === hospitalId);
      if (enabled && hospitalIndex === -1) {
        this.member.hospitals.push(hospitalId);
      } else if (!enabled && hospitalIndex !== -1) {
        this.member.hospitals.splice(hospitalIndex, 1);
      }
    },
    setMember(memberData) {
      this.gcalendar_error_message = this.$route.query.error_message;
      this.gcalendar_is_success = this.$route.query.gcalendar_is_success === 'true';

      if (!memberData) return;

      this.member = memberData;
      const isCurrentStaffMember = settings.currentSession.user.id === this.member.id;

      let specialText = this.member.name;

      if (this.isProfilePage && isCurrentStaffMember) {
        specialText = '';
      }

      eventBus.$emit('specialBreadcrumbTextEvent', { specialText });

      this.photoUrl = this.member.profile;
      this.removePhoto = false;

      if (!this.memberSet) {
        this.memberSet = true;
        this.$nextTick(() => {
          $('.dropify')
            .dropify()
            .on('dropify.afterClear', () => {
              this.removePhoto = true;
            });
        });
      }
    },

    async revokeCalendar() {
      this.revokeStatus = await this.$api.revokeCalendarPermission();
      this.telehealth.is_google_calendar_integrated = false;
    },

    getPayload() {
      const phones = JSON.parse(JSON.stringify(this.member.phones));
      phones.forEach((phone) => {
        if (phone.__localId) {
          delete phone.__localId;
        }
      });

      const notifyUsers = this.$refs.notifyUsersEditor
        ? this.$refs.notifyUsersEditor.getUserList().map(user => user.id)
        : [];

      const payload = {
        id: this.member.id,
        title: this.member.title,
        name: this.member.name,
        date_of_birth: this.member.date_of_birth,
        gender: this.member.gender,
        registration_code: this.member.registration_code,
        phones,
        enabledCareProtocols: this.member.enabled_care_protocols,
        removePhoto: this.removePhoto,
        allow_communication_messaging: this.member.allow_communication_messaging,
        hospitals: this.member.hospitals,
        notifyUsers,
        allowPatientHospitals: this.member.allow_patient_hospitals,
        allowRpmHospitals: this.member.allow_rpm_hospitals,
        allowRtmHospitals: this.member.allow_rtm_hospitals,
        roles: this.member.roles,
        hospitalsData: this.member.hospitals_data,
      };
      if (this.$refs.photo.files.length !== 0) {
        payload.photo = this.$refs.photo.files[0];
      }
      return payload;
    },
  },

  async mounted() {
    if (this.memberData) {
      this.setMember(this.memberData);
    }
    this.hospitals = await this.$api.getHospitalsList();
    this.telehealth = await this.$api.getRaw('/api/v-nurse/telehealth');

    $(document).ready(() => {
      $('#DateOfBirth').focusout(() => {
        const todayDate = new Date().getFullYear();
        const date = new Date($('#DateOfBirth').val());
        const year = date.getFullYear();
        if (year < todayDate - 200 || year > todayDate || year === null) {
          $('#DateOfBirth').addClass('is-invalid');
          $('#DateOfBirth').val('');
          $('#DateOfBirth').focus();
          $('.notDateOfBirth').css('display', 'block');
        } else {
          $('#DateOfBirth').removeClass('is-invalid');
          $('.notDateOfBirth').css('display', 'none');
        }
      });
    });
  },

  components: {
    InputField,
    PhoneListEditor,
    NotifyUsersEditor,
    HospitalsAndRoles,
  },
};
</script>

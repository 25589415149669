<template>
  <div class="h-drawer-max79">
    <SlottedMessageInDrawer ref="slottedMessage" title="SFTP push">
      <template v-slot:modal-body>
        <label v-if="!loading" style="font-weight: normal">
          <div v-if="rowMetadata.name">
            Schedule push to server client report of "<strong>{{ rowMetadata.name }}</strong>"?
          </div>
          <div v-else>
            Schedule push to server client reports?
            <br />
            (already schedules or pushed reports will not be overwritten,
            use individual push buttons to overwrite)
          </div>
          <br />
          <div v-if="rowMetadata.hospital__name">
            <strong>Hospital:</strong> {{ rowMetadata.hospital__name }}
            <br />
          </div>
          <strong>Date range:</strong>
          {{ rowMetadata.report.date_start }}...{{ rowMetadata.report.date_end }}
        </label>
       <Loader v-if="loading"/>

      </template>

      <template v-slot:modal-footer>
        <button
          type="button"
          class="h-btn h-btn-accent"
          @click="confirm"
          :disabled="loading"
        >
          Confirm
        </button>
        <button
          type="button"
          class="h-btn h-btn-dangerous h-margin-left-s"
          @click="close"
          :disabled="loading"
        >
          Cancel
        </button>
      </template>
    </SlottedMessageInDrawer>
  </div>
</template>


<style lang="stylus" scoped>
.modal-dialog {
  max-width: 1200px;
}
</style>


<script>
import settings from '@/settings.js';
import SlottedMessageInDrawer from '@/components/dialogs/SlottedMessageInDrawer';
import Loader from '@/components/Loader';

export default {
  data() {
    return {
      loading: true,
      rowMetadata: null,
    };
  },
  methods: {
    show(rowMetadata) {
      this.loading = false;
      this.rowMetadata = rowMetadata;
      this.$refs.slottedMessage.show();
    },

    close() {
      this.$refs.slottedMessage.close();
      this.$refs.slottedMessage.active = false;
    },

    async confirm() {
      if (this.rowMetadata.report.reportCode === 'billing') {
        this.confirmBillingReport();
      } else {
        this.confirmHospitalReport();
      }
    },

    async confirmBillingReport() {
      this.loading = true;
      console.log({
        hospitalId: this.rowMetadata.report.hospitalId,
        reportDateStart: this.rowMetadata.report.date_start,
        reportDateEnd: this.rowMetadata.report.date_end,
        reportCode: this.rowMetadata.report.reportCode,
      });
      const response = await this.$api.post(
        `${settings.BACKEND_URL}/api/v-nurse/hospital/sftp-push-billing-report`,
        null,
        {
          hospitalId: this.rowMetadata.report.hospitalId,
          reportDateStart: this.rowMetadata.report.date_start,
          reportDateEnd: this.rowMetadata.report.date_end,
          reportCode: this.rowMetadata.report.reportCode,
        },
      );

      if ((response.status !== 'ok') || (response.result === false)) {
        $.notify(response.message, {
          position: 'top center',
          className: 'error',
        });
      } else {
        $.notify('Schedule SFTP report push executed', {
          position: 'top center',
          className: 'success',
        });
      }
      this.close();
    },

    async confirmHospitalReport() {
      this.loading = true;

      let response;
      if (this.rowMetadata.id) {
        response = await this.$api.get(
          `${settings.BACKEND_URL}/api/v-nurse/client/sftp-push-progress-report`,
          {
            clientId: this.rowMetadata.id,
            reportDateStart: this.rowMetadata.report.date_start,
            reportDateEnd: this.rowMetadata.report.date_end,
            reportCode: 'hospital',
          },
        );
      } else {
        response = await this.$api.post(
          `${settings.BACKEND_URL}/api/v-nurse/client/all/sftp-push-progress-report`,
          null,
          {
            hospitalId: this.rowMetadata.hospitalId,
            reportDateStart: this.rowMetadata.report.date_start,
            reportDateEnd: this.rowMetadata.report.date_end,
            reportCode: this.rowMetadata.reportCode || 'hospital',
          },
        );
      }

      if ((response.status !== 'ok') || (response.result === false)) {
        $.notify(response.message, {
          position: 'top center',
          className: 'error',
        });
      } else {
        if (this.rowMetadata.id) {
          this.$emit('reportPushStatusChanged', this.rowMetadata);
        } else {
          this.$emit('allReportsPushStatusChanged');
        }
        $.notify('Schedule SFTP report push executed', {
          position: 'top center',
          className: 'success',
        });
      }
      this.close();
    },
  },

  components: {
    SlottedMessageInDrawer,
    Loader,
  },
};
</script>

<template>
  <SlottedMessageInDrawer
    ref="slottedMessage"
    title="Delete record"
    :closeDisabled="loading"
  >
    <template v-slot:modal-body>
      <div v-if="!loading" class="h-h4 h-primary_shade_1 h-margin-l">
        Do you really want to <strong>delete</strong> the record?
      </div>
      <Loader v-else />
    </template>

    <template v-slot:modal-footer>
      <button
        type="button"
        class="h-btn h-btn-accent"
        @click="deleteRecord"
        :disabled="loading"
      >
        Delete
      </button>

      <button
        type="button"
        class="h-btn h-btn-dangerous h-margin-left-s"
        @click="close"
        :disabled="loading"
      >
        Cancel
      </button>
    </template>
  </SlottedMessageInDrawer>
</template>


<script>
import settings from '@/settings.js';
import Loader from '@/components/Loader';
import SlottedMessageInDrawer from '@/components/dialogs/SlottedMessageInDrawer';

export default {
  data() {
    return {
      loading: true,
      row: null,
      e: null,
      dataTable: null,
    };
  },

  methods: {
    async show(e, row, dataTable) {
      this.loading = false;
      this.row = row;
      this.e = e;
      this.dataTable = dataTable;
      this.$refs.slottedMessage.show();
    },

    close(force) {
      this.$refs.slottedMessage.close(force);
    },

    async deleteRecord() {
      this.loading = true;
      const response = await this.$api.get(
        `${settings.BACKEND_URL}/api/v-nurse/checklist/delete-record/${this.row.record.id}`,
      );
      if (response.status === 'ok') {
        const tr = $(this.e.target).closest('tr').get(0);
        this.dataTable.row(tr).remove().draw();
        this.$emit('shouldReload', this.anId);
      } else {
        $.notify('Failed to delete the record', {
          position: 'top center',
          className: 'error',
        });
      }
      this.close(true);
    },
  },

  components: {
    Loader,
    SlottedMessageInDrawer,
  },

  beforeDestroy() {
    if (this.dataTable) {
      this.dataTable.destroy(true);
      this.dataTable = null;
    }
  },

  props: ['record'],
};
</script>

<template>
  <div>
    <!-- Page-Title -->
    <div class="row">
      <div class="col-lg-12">
        <div class="page-title-box">
          <div class="float-right">
            <ol class="breadcrumb">
              <li class="breadcrumb-item">
                <a href="javascript:void(0);">Settings</a>
              </li>
              <li class="breadcrumb-item active">Care Protocol</li>
            </ol>
          </div>
          <h5 class="page-title">
            Care Protocol: {{ careProtocol.name }}
            <span v-if="doctor" class="text-muted"> [Doctor: {{ doctor.display_name }}] </span>
          </h5>
        </div>
        <!--end page-title-box-->
      </div>
      <!--end col-->
    </div>
    <!-- end page title end breadcrumb -->

    <div class="row">
      <div class="col-12">
        <div class="card">
          <div v-if="!loading" class="card-body">
            <h3 class="text-center mb-3">Care protocol details</h3>

            <table class="table w-100 main">
              <tr>
                <th class="w-1 text-nowrap border-top-0 p-2 text-right">
                  Name:
                </th>
                <td class="border-top-0 p-2">
                  {{ careProtocol.name }}
                </td>
              </tr>

              <tr>
                <th class="w-1 text-nowrap border-top-0 p-2 text-right">
                  Start date type:
                </th>
                <td class="border-top-0 p-2">
                  {{ strUtils.underscoreCaseToName(careProtocol.start_date_type) }}
                </td>
              </tr>

              <tr>
                <th class="w-1 text-nowrap border-top-0 p-2 text-right">
                  Top title header:
                </th>
                <td class="border-top-0 p-2">
                  <span v-if="careProtocol.tile_header">
                    {{ careProtocol.tile_header }}
                  </span>
                  <span v-else class="text-muted"> &lt;None&gt; </span>
                </td>
              </tr>
              <tr>
                <th class="w-1 text-nowrap border-top-0 p-2 text-right">
                  Top title description:
                </th>
                <td class="border-top-0 p-2">
                  <span v-if="careProtocol.tile_description">
                    {{ careProtocol.tile_description }}
                  </span>
                  <span v-else class="text-muted"> &lt;None&gt; </span>
                </td>
              </tr>

              <tr>
                <th class="w-1 text-nowrap border-top-0 p-2 text-right">
                  Top title link:
                </th>
                <td class="border-top-0 p-2">
                  <span v-if="careProtocol.top_title_link">
                    {{ careProtocol.top_title_link }}
                  </span>
                  <span v-else class="text-muted"> &lt;None&gt; </span>
                </td>
              </tr>

              <tr>
                <th class="w-1 text-nowrap border-top-0 p-2 text-right">
                  Send daily notifications:
                </th>
                <td class="border-top-0 p-2">
                  {{ careProtocol.send_daily_notifications }}
                </td>
              </tr>

              <tr>
                <th class="w-1 text-nowrap border-top-0 p-2 text-right">
                  Send weekly notifications:
                </th>
                <td class="border-top-0 p-2">
                  {{ careProtocol.send_weekly_notifications }}
                </td>
              </tr>
            </table>

            <div v-if="isTemplateMode" class="mt-4 mb-2 text-right">
              <button
                type="button"
                class="btn btn-primary waves-effect ml-2 edit-button"
                @click="
                  () => {
                    $refs.editCareProtocolDialog.show();
                  }
                "
              >
                Edit
              </button>
            </div>
          </div>

          <Loader v-else />
        </div>
      </div>
    </div>

    <div v-if="!loading">
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-body">
              <h3 class="text-center mb-1">Surgery/Procedure Checklist</h3>
              <AvailableOptionBadges
                :options="getEditableOptions(true, true, false)"
                class="pb-3 text-center"
                @click="$refs.cpItemsBadges.show"
              />

              <CareProtocolItems
                :careProtocol="careProtocol"
                :isTemplateMode="isTemplateMode"
                :doctor="doctor"
              />
            </div>
          </div>
        </div>
      </div>

      <!-- Care protocol education -->
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-body">
              <h3 class="text-center mb-1">Care protocol education</h3>
              <AvailableOptionBadges
                :options="getEditableOptions(true, true, false)"
                class="pb-3 text-center"
                @click="$refs.cpEducationBadges.show"
              />
              <EducationList
                :collectionId="careProtocol.learn_collection_id"
                :isTemplateMode="isTemplateMode"
                :doctor="doctor"
              />
            </div>
          </div>
        </div>
      </div>

      <!-- Therapy PT -->
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-body">
              <h3 class="text-center mb-1">Therapy PT</h3>
              <AvailableOptionBadges
                :options="getEditableOptions(true, true, true)"
                class="text-center"
                @click="$refs.cpTherapyPtBadges.show"
              />

              <TherapyPt
                :careProtocolId="careProtocol.id"
                :careProtocolMode="true"
                :isTemplateMode="isTemplateMode"
                :doctor="doctor"
              />
            </div>
          </div>
        </div>
      </div>

      <!-- Assessment -->
      <div class="row">
        <div class="col-lg-12">
          <div class="card">
            <div class="card-body">
              <h3 class="text-center mb-1">Assessments</h3>
              <AvailableOptionBadges
                :options="getEditableOptions(true, true, false)"
                class="pb-3 text-center"
                @click="$refs.cpAssesmentBadges.show"
              />

              <Assesment
                :careProtocolId="careProtocol.id"
                :doctorId="parseInt($route.params.doctorId, 10)"
                :isTemplateMode="isTemplateMode"
                :careProtocolMode="true"
              />
            </div>
          </div>
        </div>
      </div>

      <!-- HealthStatus -->
      <div class="row" v-if="!doctor">
        <div class="col-12">
          <div class="card">
            <div class="card-body">
              <h3 class="text-center mb-1">
                Health status
                <small>
                  <span v-if="doctor" class="text-muted">
                    [Doctor: {{ doctor.display_name }}]
                  </span>
                  <span v-else class="text-muted"> [Template] </span>
                </small>
              </h3>

              <HealthStatus :holder="careProtocol" :cpMode="true" />
            </div>
          </div>
        </div>
      </div>

      <!-- Thought of the day -->
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-body">
              <h3 class="text-center mb-1">
                Thoughts of the Day
                <small>
                  <span v-if="doctor" class="text-muted">
                    [Doctor: {{ doctor.display_name }}]
                  </span>
                  <span v-else class="text-muted"> [Template] </span>
                </small>
              </h3>
              <AvailableOptionBadges
                :options="getEditableOptions(true, true, false)"
                class="pb-3 text-center"
                @click="$refs.cpThoughtForTheDayBadges.show"
              />

              <ThoughtOfTheDay
                :careProtocolId="careProtocol.id"
                :doctorId="parseInt($route.params.doctorId, 10)"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- isDoctor-note -->
    <!-- <div class="isDoctor-note" v-if="!isTemplateMode">
      You're editing care protocol of
      <span v-if="doctor">
        <u>Dr. {{ doctor.display_name }}</u
        >.
      </span>
    </div>
    <div class="isDoctor-note template" v-else>
      You're editing an organization level template.
    </div> -->

    <EditCareProtocolDialog
      ref="editCareProtocolDialog"
      :defaultCareProtocol="careProtocol"
      @protocolUpdated="protocolUpdated"
    />

    <SlottedMessage ref="cpItemsBadges" title="Edit options">
      <template v-slot:modal-body>
        <div class="mb-2">
          <strong>Surgery Checklist editting:</strong>
        </div>
        <ul>
          <li>
            In care protocol template care protocol items can be fully editted.
          </li>
          <li>
            In doctor care protocol items can only be switched on and off.
          </li>
          <li>
            Item addition and removal in templates automatically propogated to all doctors. New
            items automatically enabled in doctor care protocols.
          </li>
        </ul>
      </template>
    </SlottedMessage>

    <SlottedMessage ref="cpEducationBadges" title="Edit options">
      <template v-slot:modal-body>
        <div class="mb-2">
          <strong>Care Protocol Education editting:</strong>
        </div>
        <ul>
          <li>
            In care protocol template care protocol education items can be fully editted.
          </li>
          <li>
            In doctor care protocol items can only be switched on and off.
          </li>
          <li>
            Item addition and removal in templates automatically propogated to all doctors. New
            items automatically enabled in doctor care protocols.
          </li>
        </ul>
      </template>
    </SlottedMessage>

    <SlottedMessage ref="cpTherapyPtBadges" title="Edit options">
      <template v-slot:modal-body>
        <div class="mb-2">
          <strong>Therapy PT editting:</strong>
        </div>
        <ul>
          <li>
            In care protocol template therapy PT items can be fully editted.
          </li>
          <li>
            In doctor care protocol items can only be switched on and off.
          </li>
          <li>
            In pations' care protocols start time, end time, and title not edditable, everything
            else can be changed. Item addition and removal not allowed.
          </li>
          <li>
            Item addition and removal in templates automatically propogated to all doctors. New
            items automatically enabled in doctor care protocols.
          </li>
        </ul>
      </template>
    </SlottedMessage>

    <SlottedMessage ref="cpThoughtForTheDayBadges" title="Edit options">
      <template v-slot:modal-body>
        <div class="mb-2">
          <strong>Thought for the day:</strong>
        </div>
        <ul>
          <li>In care protocol template items can be fully editted.</li>
          <li>In doctor care protocol items can be fully editted.</li>
          <li>
            Templated and doctor thoughts of the day are independant, but you can use "Clone
            existing item" button to import/export thoughts for the day from other care protocols.
          </li>
        </ul>
      </template>
    </SlottedMessage>

    <SlottedMessage ref="cpAssesmentBadges" title="Edit options">
      <template v-slot:modal-body>
        <div class="mb-2">
          <strong>Assessment:</strong>
        </div>
        <ul>
          <li>In care protocol template items can be fully editted.</li>
          <li>In doctor care protocol items can be fully editted.</li>
          <li>
            Item addition and removal in templates automatically propogated to all doctors. New
            items automatically enabled in doctor care protocols.
          </li>
        </ul>
      </template>
    </SlottedMessage>
  </div>
</template>

<style lang="stylus" scoped>
.edit-button {
  min-width: 8.333vw;
}

.isDoctor-note {
  position: fixed;
  bottom: 0.521vw;
  right: 1.042vw;
  background-color: #80ff80;
  border: 0.104vw solid black;
  border-radius: 0.26vw;
  padding: 0.26vw 0.781vw;
  font-weight: bold;
}

.isDoctor-note.template {
  background-color: #ffd4d4;
}
</style>

<script>
import strUtils from '@/utils/str_utils';
import settings from '@/settings.js';
import Loader from '@/components/Loader';
import CareProtocolItems from './components/care_protocol_items/CareProtocolItems';
import ThoughtOfTheDay from '@/components/thought_of_the_day/ThoughtOfTheDay';
import EditCareProtocolDialog from './components/EditCareProtocolDialog';
import SlottedMessage from '@/components/dialogs/SlottedMessage';

import EducationList from '@/components/education/education_list/EducationList';
import TherapyPt from '@/components/therapy_pt/TherapyPt';
import Assesment from '@/components/assesment/Assesment';
import AvailableOptionBadges from '@/components/AvailableOptionBadges';
import HealthStatus from '@/components/health_status/HealthStatus';

export default {
  data() {
    return {
      strUtils,

      loading: true,
      careProtocol: settings.careProtocols.find((value) => {
        return value.id === parseInt(this.$route.params.id, 10);
      }),
      doctor: null,
    };
  },

  computed: {
    isTemplateMode() {
      return !this.$route.params.doctorId;
    },
  },

  watch: {
    async $route(to, from) {
      await this.reload();
    },
  },

  methods: {
    getEditableOptions(template, doctor, option) {
      return [
        {
          text: 'Template',
          enabled: template,
          hint: template ? 'Editable at template level' : 'Not editable at template level',
        },
        {
          text: 'Doctor',
          enabled: doctor,
          hint: doctor ? 'Editable at doctor level' : 'Not editable at doctor level',
        },
        {
          text: 'Patient',
          enabled: option,
          hint: option ? 'Editable at patient level' : 'Not editable at patient level',
        },
      ];
    },

    async protocolUpdated(careProtocol) {
      await this.reload(careProtocol);
    },

    async reload(careProtocol) {
      if (!this.$route.params.doctorId) {
        this.doctor = null;
      }
      if (!careProtocol) {
        this.loading = true;

        window.execAfterInitMenuInterface(() => {
          if (this.isTemplateMode) {
            window.initActiveMenu(`left-navigation-link>care_protocols>${this.$route.params.id}`);
          } else {
            window.initActiveMenu('left-navigation-link>settings>staff>all-members', false);
          }
        });

        this.careProtocol = await this.$api.getCareProtocol(
          this.$route.params.id,
          this.$route.params.doctorId,
        );

        if (this.careProtocol.user) {
          this.doctor = this.careProtocol.user;
        }
      } else {
        this.careProtocol = careProtocol;
      }

      this.loading = false;
    },
  },

  async mounted() {
    await this.reload();
  },

  components: {
    Loader,
    EditCareProtocolDialog,
    CareProtocolItems,
    ThoughtOfTheDay,
    EducationList,
    TherapyPt,
    AvailableOptionBadges,
    SlottedMessage,
    Assesment,
    HealthStatus,
  },
};
</script>

<template>
  <div>
    <div class="h-space-between h-align-items-center">
      <h3 class="h-h3 h-primary_shade_1">Location list</h3>
      <div class="h-d-flex">
        <button
          style="width: max-content"
          class="h-btn h-btn-accent h-margin-left-m h-align-items-center"
          @click="addLocation"
          v-show="isOpen"
        >
          <i
            style="line-height: 1; font-size: 10rem"
            class="h-secondary_shade_5 healenticons-plus h-btn-with-icon h-padding-right-s"
          ></i
          >Add Location
        </button>
        <button
          style="width: max-content"
          class="h-btn h-btn-accent h-margin-left-m h-align-items-center"
          @click="$emit('toggleSection')"
        >
          <i
            :class="[
              'h-arrow-down',
              'h-secondary_shade_5',
              'healenticons-arrow-ios-forward-fill',
              'h-btn-with-icon',
              { up: isOpen },
            ]"
          ></i
          >{{ isOpen ? "Collapse" : "Expand" }}
        </button>
      </div>
    </div>

    <transition name="slide">
      <div v-show="isOpen" class="h1-table h-w-100">
        <table class="table dataTable h-noborder-input h-margin-bottom-0">
          <thead class="thead-light">
            <tr>
              <th width="250">Location</th>
              <th class="text-right w-1">Action</th>
            </tr>
          </thead>

          <tbody v-if="locations.length !== 0">
            <tr
              v-for="location of locations"
              :key="getLocationId(location)"
              :location="location"
            >
              <td>
                <InputField
                  type="text"
                  v-model="location.name"
                  placeholder="Enter location"
                  :error="getLocationErrors(location).name"
                  @change="clearLocationError(location, 'name')"
                />
              </td>
              <td>
                <i
                  class="fas fa-trash-alt text-danger font-16 cursor-pointer"
                  title="Delete"
                  @click="
                    () => {
                      removeLocation(location);
                    }
                  "
                ></i>
              </td>
            </tr>
          </tbody>

          <tbody v-else>
            <tr>
              <td colspan="4" class="text-center">No locations</td>
            </tr>
          </tbody>
        </table>
      </div>
    </transition>
  </div>
</template>

<style scoped lang="stylus">
label {
  margin-top: 0.833vw;
}

td {
  vertical-align: top;
}

td:last-child {
  vertical-align: middle;
}
</style>

<script>
import InputField from '@/components/InputField';

export default {
  data() {
    return {
      lastLocationId: 0,
    };
  },

  methods: {
    clearLocationError(location, field) {
      const errors = this.getLocationErrors(location);
      errors[field] = null;
    },

    addLocation() {
      this.lastLocationId += 1;
      let uiId = `uiId:${this.lastLocationId}`;
      // eslint-disable-next-line no-loop-func
      while (this.locations.find(e => e.uiId === uiId)) {
        this.lastLocationId += 1;
        uiId = `uiId:${this.lastLocationId}`;
      }
      this.locations.push({
        uiId,
        name: '',
      });
    },

    getLocationId(location) {
      if (location.id) return location.id;
      return location.uiId;
    },

    getLocationErrors(location) {
      if (!this.errors) return {};
      return this.errors[this.getLocationId(location)] || {};
    },

    removeLocation(location) {
      this.locations.splice(this.locations.indexOf(location), 1);
      if (this.errors) {
        if (this.errors[this.getLocationId(location)]) {
          delete this.errors[this.getLocationId(location)];
        }
      }
    },
  },

  components: {
    InputField,
  },

  props: {
    errors: {
      type: Object,
      required: true,
    },
    locations: {
      type: Array,
      required: true,
    },
    isOpen: {
      type: Boolean,
      required: true,
    },
  },
};
</script>

<template>
  <div class="h1-table">
    <table id="Medication-use" class="table dataTable checklist">
      <thead class="thead-light">
        <tr>
          <th style="width: 5%;" v-if="!editMode" class="text-center text-nowrap">
            Submittion date
          </th>
          <th style="width: 10%;" class="text-left text-nowrap">Medication type</th>
          <th style="width: 10%;" class="text-left text-nowrap">Name</th>
          <th style="width: 10%;" class="text-right th-wrap">"Times" per dose</th>
          <th style="width: 10%;" class="text-right th-wrap">Amount per "time"</th>
          <th style="width: 10%;" class="text-left th-wrap">Amount unit</th>
          <th style="width: 10%;" class="text-right th-wrap">Reminder times</th>
          <th style="width: 10%;" class="text-right">Doses</th>
          <th style="width: 10%;" class="text-left">Comment</th>
          <th style="width: 5%;" v-if="editMode" class="text-left">Action</th>
        </tr>
      </thead>
      <tbody v-if="medicationUseEntries && medicationUseEntries.length">
        <tr
          v-for="entry of medicationUseEntries"
          :key="entry.id"
          :class="{
            odd: entry.odd
          }"
        >
          <td v-if="!editMode" class="text-nowrap ">
            {{ createdAt(entry.created_at) }}
          </td>

          <td class="text-nowrap ">
            <TruncateText
              :text="entry.medication_type ? entry.medication_type : ''"
              :data-tooltip="entry.medication_type"
            />
          </td>

          <td class="text-nowrap ">
            {{ entry.name }}
          </td>

          <td class="text-nowrap  text-right">
            {{ entry.times_a_term }}
          </td>

          <td class="text-nowrap  text-right">
            {{ entry.amount }}
          </td>

          <td class="text-nowrap  text-left">
            {{ entry.amount_unit }}
          </td>

          <td class="pre-wrap text-right">
            <div v-for="value of (entry.data || {}).times || []" :key="value.uuid">
              {{ moment(`2000-10-20 ${value.value}`).format('h:mm a') }}
            </div>
          </td>

          <td class="text-right">
            {{
              Object.keys((entry.data || {}).doses || {})
                .filter(key => entry.data.doses[key])
                .sort()
                .join(', ')
            }}
          </td>

          <td class="pre-wrap">
            <TruncateText
              :text="entry.comment ? entry.comment : ''"
              :data-tooltip="entry.comment"
            />
          </td>

          <td v-if="editMode">
            <a href="javascript:void(0);" class="mr-2" @click="$emit('edit', entry)">
              <i class="fas fa-edit text-info font-16" title="Edit"></i>
            </a>

            <a
              href="javascript:void(0);"
              class="mr-2"
              @click="$emit('showDeleteEntryDialog', entry)"
            >
              <i class="fas fa-trash-alt text-danger font-16" title="Delete"></i>
            </a>
          </td>
        </tr>
      </tbody>

      <tbody v-else>
        <tr>
          <td colspan="1000" class="text-center h-h3 h-secondary_shade_1">
            No records
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import moment from 'moment';
import TruncateText from '@/components/TruncateText';

export default {
  components: {
    TruncateText,
  },
  props: ['medicationUseEntries', 'editMode'],

  data() {
    return {
      moment,
    };
  },

  methods: {
    createdAt(d) {
      return moment
        .utc(d)
        .local()
        .format('MM/DD/YYYY h:mm:ss a');
    },
  },
};
</script>

<template>
  <div>
    <InputField
      v-if="hospital"
      type="select"
      v-model="hospitalId"
      :choices="hospitalChoices"
      :dense="dense"
      :disabled="disabled"
    />
  </div>
</template>


<script>
import InputField from '@/components/InputField';

export default {
  data() {
    return {
      hospitalId: null,
      hospital: null,
      hospitalsMap: {},
      hospitalChoices: [],
    };
  },

  watch: {
    hospitalId() {
      this.onHospitalSwitch();
    },
  },

  methods: {
    onHospitalSwitch() {
      this.hospital = this.hospitalsMap[this.hospitalId];
      this.$emit('input', this.hospitalId);
      this.$emit('hospital', this.hospital);
    },
  },

  async mounted() {
    const hospitals = await this.$api.getHospitalsList();
    this.hospitalChoices = hospitals.map((hospital) => {
      this.hospitalsMap[hospital.id] = hospital;

      return {
        value: hospital.id,
        title: hospital.name,
      };
    });
    this.hospitalId = this.value ? this.value : hospitals[0].id;
    this.onHospitalSwitch();
  },

  components: {
    InputField,
  },

  props: {
    value: {
      type: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    dense: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<template>
  <div class="row">
    <div class="h-w-100 h-align-items-center h-space-between">
      <div>
        <h3 class="h-h3 h-primary_shade_1">
          Copy SMS, e-mail and push notifications to users:
        </h3>
        <small class="text-muted">(Providers only)</small>
      </div>
      <div class="h-h4 h-accent">
        <i
          @click="addMember"
          class="healenticons-plus h-accent h-cursor-pointer"
          data-tooltip="Add member"
        ></i>
      </div>
    </div>
    <div class="col-md-12 h1-table">
      <table class="table dataTable h-noborder-input w-100">
        <thead class="thead-light">
          <tr>
            <th style="width: 20em">Name</th>
            <th>Email</th>
            <th class="text-right w-1">Action</th>
          </tr>
        </thead>
        <tbody v-if="!loading && userList.length">
          <tr v-for="(user, index) of userList" :key="index">
            <td>
              <InputField
                type="select"
                :choices="memberChoices"
                :value="user.id || null"
                @input="e => setMember(e, index)"
              />
            </td>
            <td>
              {{ user.email }}
            </td>
            <td>
              <a
                href="javascript:void(0);"
                class="mr-2"
                @click="
                  () => {
                    removeMember(user);
                  }
                "
              >
                <i class="fas fa-trash-alt text-danger font-16" title="Delete"></i>
              </a>
            </td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr v-if="!loading">
            <td class="text-muted text-center" colspan="1000">
              No entries.
            </td>
          </tr>
          <tr v-else>
            <td class="text-center" colspan="1000">
              Loading...
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="col-md-12">
      <hr />
    </div>
  </div>
</template>

<script>
import settings from '@/settings.js';
import InputField from '@/components/InputField';
import strUtils from '@/utils/str_utils';

export default {
  data() {
    return {
      loading: true,
      userList: [],
      allUsersList: [],
      memberChoices: [],
    };
  },

  methods: {
    addMember() {
      if (this.allUsersList.length === 0) return;
      this.userList.push(this.allUsersList[0]);
    },

    setMember(e, index) {
      this.userList[index] = this.allUsersList.find(user => user.id === parseInt(e, 10));
      this.$forceUpdate();
    },

    getMemberId(user) {
      return user.id || user.guid;
    },

    removeMember(user) {
      this.userList.splice(this.userList.indexOf(user), 1);
    },

    getUserList() {
      return this.userList;
    },
  },

  async mounted() {
    let response = await this.$api.get(`${settings.BACKEND_URL}/api/v-nurse/search-member`);
    this.allUsersList = response;

    this.memberChoices = this.allUsersList.map(member => [member.id, member.name]);
    response = await this.$api.get(
      `${settings.BACKEND_URL}/api/v-nurse/get-copy-notificaitons-users/${this.member.id}`,
    );
    this.userList = response.data;
    this.loading = false;
  },

  props: {
    member: {
      type: Object,
      required: true,
    },
  },

  components: {
    InputField,
  },
};
</script>

<template>
  <div style="height: 100vh">
    <div :class="{ 'd-none': loading }">
      <div class="h-patient-height h-row h-col-desktop-12 h-col-mobile-12">
        <div class="h-col-desktop-3 h-col-mobile-12 left-panel-bg h-padding">
          <div class="h-col-desktop-12 h-col-mobile-12 h-margin-bottom-l">
            <label class="h-label"> Name: </label>
            <InputField
              id="input_name"
              type="text"
              v-model="name"
              :error="errors.name"
              @input="() => {errors.name = null}"
            />
            <div :class="['invalid-feedback', { 'd-block': errors.name }]">
              This field is required
            </div>
          </div>
          <div class="h-col-desktop-12 h-col-mobile-12 h-margin-bottom-l">
            <label class="h-label"> Hospital: </label>
            <HospitalSelector v-model="hospitalId" @hospital="setHospital"/>
          </div>
          <div class="h-col-desktop-12 h-col-mobile-12">
            <label class="h-label"> Type: </label>
            <InputField type="select" v-model="typeId" :choices="typeChoices"/>
          </div>
        </div>
        <div class="h-col-desktop-9 h-col-mobile-12">
          <DropFile v-model="file" :error="errors.file" @setFile="setFile"/>
        </div>
      </div>
      <div class="h-step-fix-footer">
        <div class="h-col-desktop-8 h-col-screen-m h-col-mobile-12 h-margin-auto">
          <div class="h-text-right">
            <button
              type="button"
              class="h-btn h-btn-dangerous h-margin-right-s"
              @click="cancel"
            >
              Cancel
            </button>
            <button type="button" class="h-btn h-btn-accent" @click="save(false)">
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
    <div v-if="loading" class="card-body">
      <Loader/>
    </div>
  </div>
</template>

<script>
import HospitalSelector from '@/components/HospitalSelector';
import InputField from '@/components/InputField';
import DropFile from '@/components/DropFile';
import Loader from '@/components/Loader';

export default {
  name: 'CreateForm',
  components: {
    HospitalSelector,
    InputField,
    DropFile,
    Loader,
  },
  data() {
    return {
      name: null,
      file: null,
      hospitalId: null,
      typeId: 'registration',
      errors: {},
      formData: {},
      options: {},
      formWasSaved: null,
      loading: false,
      typeChoices: [
        { value: 'registration', title: 'Registration' },
        { value: 'evaluation', title: 'Evaluation' },
      ],
    };
  },
  methods: {
    handleChange(schema) {
      this.formData = schema;
    },
    setHospital(hospital) {
      this.hospitalId = hospital.id;
    },
    setFile(file) {
      this.file = file;
      this.errors.file = null;
    },
    async save() {
      this.errors = {};
      if (!this.name) {
        this.errors.name = true;
      }
      if (!this.file) {
        this.errors.file = true;
      }
      if (Object.keys(this.errors).length > 0) {
        this.formWasSaved = false;
        $.notify('Please fill in all required fields', {
          position: 'top center',
          className: 'error',
        });
        return;
      }
      const payload = {
        name: this.name,
        file: this.file,
        type: this.typeId,
        hospital_id: this.hospitalId,
      };
      this.loading = true;
      let response = {};
      try {
        response = await this.$api.createFormBuilder(payload);
      } catch (e) {
        response.errors = true;
      }
      if (response && response.status === 'ok') {
        this.formWasSaved = true;
        await this.$router.push({
          name: 'FormBuilder',
          params: {
            formId: response.data.id,
          },
        });
        this.loading = false;
      } else {
        const message = response.message;
        $.notify(`Create Form Builder Was Failed: ${message}`, {
          position: 'top center',
          className: 'error',
        });
        this.formWasSaved = false;
        this.loading = false;
      }
    },
    async cancel() {
      await this.$router.push({
        name: 'Forms',
      });
    },
  },
};
</script>
<style scoped>
.left-panel-bg {
  background: white;
  padding: 30px;
}
</style>

<template>
  <div class="h-bg-gray">
    <div
      v-if="loadingOptions"
      id="headerBox"
      class="h-padding-left-l h-padding-right-l h-padding-bottom-l h-bg-w text-center text-muted"
      style="font-size: 1.5em"
    >
      Please wait...
    </div>
    <div v-if="!loadingOptions" class="h1-table h-margin-top-l h-padding-top-l h-bg-w">
      <table
        id="statistic-report-table"
        class="table dataTable TableReports dtr-inline collapsed"
        ref="table"
      >
        <thead class="thead-light">
          <tr>
            <th>Date</th>
            <th>Hospital</th>
            <th>Provider</th>
            <th>Nurse</th>
            <th v-for="metric of metrics" :key="metric.name" class="text-right">
              {{ metric.display_name }}
            </th>
          </tr>
        </thead>
      </table>
    </div>
    <SelectorDialogInDrawer
      ref="toggleColumnsDialog"
      :allOptions="allTableColumns"
      :selectedOptions="visibleTableColumns"
      :getOptionKey="
        option => {
          return option.name;
        }
      "
      :getOptionText="
        option => {
          return option.text;
        }
      "
      windowTitle="Toggle table columns"
      listTitle="Table table columns to show"
      @toggleOption="toggleColumnVisible"
    />
  </div>
</template>

<script>
import moment from 'moment-timezone';
import settings from '@/settings.js';
import SelectorDialogInDrawer from '@/components/dialogs/SelectorDialogInDrawer';

const TABLE_COLUMNS_KEY = 'StatisticReport_columnKeys';

export default {
  data() {
    return {
      loadingOptions: true,
      dataTable: null,
      allTableColumns: [],
      visibleTableColumns: [],
      errors: {},
      hospitals: [],
      hospitalId: 'true',
      surgeons: [],
      surgeonId: null,
      nurses: [],
      nurseId: null,
      dateStart: moment()
        .startOf('month')
        .format('YYYY-MM-DD'),
      dateEnd: moment()
        .endOf('month')
        .format('YYYY-MM-DD'),
      aggregate: true,
      metrics: [],
    };
  },

  computed: {
    hospitalChoices() {
      const choices = this.hospitals.map((hospital) => {
        return [hospital.id, hospital.name];
      });
      choices.unshift(['true', '[ALL]']);
      choices.unshift([null, '--- select hospital ---']);
      return choices;
    },

    surgeonOptions() {
      const choices = this.surgeons
        .map((surgeon) => {
          if (
            this.hospitalId &&
            this.hospitalId !== 'true' &&
            surgeon.hospital.id !== parseInt(this.hospitalId, 10)
          ) {
            return null;
          }
          return [surgeon.id, surgeon.name];
        })
        .filter(entry => entry !== null);
      choices.unshift(['true', '[ALL]']);
      choices.unshift([null, '--- select provider ---']);

      return choices;
    },

    nurseOptions() {
      const choices = this.nurses
        .map((nurse) => {
          if (this.hospitalId && this.hospitalId !== 'true') {
            const foundHospital = nurse.hospitals.find(
              hospital => hospital.id === parseInt(this.hospitalId, 10),
            );
            if (!foundHospital) {
              return null;
            }
          }
          return [nurse.id, nurse.name];
        })
        .filter(entry => entry !== null);
      choices.unshift(['true', '[ALL]']);
      choices.unshift([null, '--- select nurse ---']);

      return choices;
    },
  },

  watch: {
    hospitalId() {
      this.updateFilters('hospitalId');
    },

    nurseId() {
      this.updateFilters('nurseId');
    },

    surgeonId() {
      this.updateFilters('surgeonId');
    },

    aggregate() {
      if (this.hospitalId === 'true') this.hospitalId = null;
      if (this.nurseId === 'true') this.nurseId = null;
      if (this.surgeonId === 'true') this.surgeonId = null;
    },
  },

  methods: {
    updateFilters(source) {
      if (source === 'hospitalId') {
        if (this.hospitalId && this.hospitalId !== 'true') {
          const nurse = this.nurses.find(n => n.id === parseInt(this.nurseId, 10));
          if (nurse) {
            const foundHospital = nurse.hospitals.find(
              hospital => hospital.id === parseInt(this.hospitalId, 10),
            );
            if (!foundHospital) {
              this.nurseId = null;
            }
          }

          const surgeon = this.surgeons.find(s => s.id === parseInt(this.surgeonId, 10));
          if (surgeon && surgeon.hospital.id !== parseInt(this.hospitalId, 10)) {
            this.surgeonId = null;
          }
        }
      } else if (source === 'nurseId' && this.nurseId !== null) {
        this.surgeonId = null;
      } else if (source === 'surgeonId' && this.surgeonId !== null) {
        this.nurseId = null;
      }
    },

    reload() {
      this.dataTable.ajax.reload();
    },

    toggleColumnVisible(column, visible) {
      if (visible) {
        this.visibleTableColumns.push(column);
      } else {
        const index = this.visibleTableColumns.indexOf(column);
        this.visibleTableColumns.splice(index, 1);
      }

      localStorage.setItem(TABLE_COLUMNS_KEY, JSON.stringify(this.visibleTableColumns));

      const dtColumn = this.dataTable.column(this.allTableColumns.indexOf(column));
      dtColumn.visible(!dtColumn.visible());
    },
  },

  beforeDestroy() {
    if (this.dataTable) {
      this.dataTable.destroy(true);
      this.dataTable = null;
    }
  },

  async mounted() {
    const [hospitals, surgeons, nurses, metrics] = await Promise.all([
      this.$api.getHospitalsList(),
      this.$api.searchSeargeon(),
      this.$api.searchNurse(),
      this.$api.get(`${settings.BACKEND_URL}/api/v-nurse/project-statistics/metrics`),
    ]);
    this.hospitals = hospitals;
    this.surgeons = surgeons;
    this.nurses = nurses;
    this.metrics = metrics.metrics.filter(m => !m.name.startsWith('__'));

    this.loadingOptions = false;

    await this.$forceUpdate();

    // --- START: DATATABLE ---
    const that = this;
    this.dataTable = $('#statistic-report-table').DataTable({
      processing: true,
      serverSide: true,
      responsive: false,
      lengthMenu: [5, 10, 20, 50, 100, 200, 500],
      pageLength: 50,
      searching: false,
      order: [[5, 'desc']],
      ajax: (data, callback, tableSettings) => {
        data.hospital_id = this.hospitalId === 'true' ? true : this.hospitalId;

        data.provider_id = this.surgeonId === 'true' ? true : this.surgeonId;
        data.nurse_id = this.nurseId === 'true' ? true : this.nurseId;
        data.date_start = this.dateStart;
        data.date_end = this.dateEnd;
        data.aggregate = this.aggregate;

        this.$api
          .post(`${settings.BACKEND_URL}/api/v-nurse/project-statistics/load`, null, data)
          .then((response) => {
            tableSettings.json = response;
            callback(response);
          });
      },
      dom:
        '<"h-row h-space-between h-align-item-center"<"h-show-bt h-margin-left-l header-title"><"h-d-flex h-table-bt"fB>>' +
        '<"h-row"<"h-col-desktop-12 h-col-mobile-12"tr>><"h-row h-space-between h-align-item-center h-padding-bottom-l"lip>',
      buttons: {
        buttons: [
          {
            extend: 'collection',
            className: 'ExportIcon',
            buttons: ['copy', 'excel', 'csv', 'print'],
          },
          {
            text: 'Change columns',
            action(e, dt, node, config) {
              that.$refs.toggleColumnsDialog.show();
            },
            className: 'h-btn-icon',
          },
        ],
        dom: {
          button: {
            className: 'h-btn',
          },
        },
      },
      columns: [
        {
          name: 'date',
          orderable: true,
          render: (data) => {
            return data || '[RANGE]';
          },
        },
        {
          name: 'hospital__name',
          orderable: true,
        },
        {
          name: 'provider__name',
          orderable: true,
        },
        {
          name: 'nurse__name',
          orderable: true,
        },
        ...this.metrics.map((metric) => {
          return {
            name: metric.name,
            searchable: false,
            orderable: true,
            render: (data) => {
              if (data !== null) {
                const result = metric.unit ? data.toFixed(2) : data;
                if (metric.name.endsWith('::trend')) {
                  const sign = result > 0 ? '+' : '';
                  return `${sign}${result}%`;
                }
                return result;
              }
              return '-';
            },
          };
        }),
      ],
    });

    $('div.header-title').html(
      "<h3 class='h-h3 h-primary_shade_1'>Statistic report<span id='iconExpend' class='ExpendIcon h-margin-left-s'></span></h3>",
    );
    $(document).ready(() => {
      $('#iconExpend').click(() => {
        $('#iconExpend').toggleClass('CollapseIcon');
        $('.TableReports').toggleClass('Expended');
        $('#headerInput').toggleClass('d-none');
        $('#headerBox').toggleClass('d-none');
      });
    });
    this.allTableColumns = this.dataTable.context[0].aoColumns.map((aoColumn, index) => {
      return {
        text: $(this.dataTable.column(index).header()).text(),
        name: aoColumn.name,
      };
    });
    this.visibleTableColumns = JSON.parse(localStorage.getItem(TABLE_COLUMNS_KEY) || '[]');
    if (this.visibleTableColumns.length === 0) {
      this.visibleTableColumns = this.allTableColumns.slice();
    } else {
      this.visibleTableColumns = this.allTableColumns.filter((columnExists) => {
        const keep = this.visibleTableColumns.find(
          columnVis => columnVis.name === columnExists.name,
        );
        if (!keep) {
          const dtColumn = this.dataTable.column(this.allTableColumns.indexOf(columnExists));
          dtColumn.visible(false);
        }
        return keep;
      });
    }
    // --- END: DATATABLE ---
  },

  components: {
    SelectorDialogInDrawer,
  },
};
</script>

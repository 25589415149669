import { render, staticRenderFns } from "./ChatDisplay.vue?vue&type=template&id=7604228c&scoped=true&"
import script from "./ChatDisplay.vue?vue&type=script&lang=js&"
export * from "./ChatDisplay.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7604228c",
  null
  
)

export default component.exports
<template>
  <div class="h-drawer-max80 h-no-padding">
    <SlottedMessageInDrawer ref="slottedMessage" title="CCM Protocols">
      <template v-slot:modal-header>
        <div class="h-drawer-card-header">
          <div class="h-main-drawer-card-header align-items-center h-margin-bottom-l">
            <h3 class="h-h3 h-primary_shade_1">
              <slot name="modal-title">
                CCM Protocols
                <span v-if="hasChanges" class="h-dangerous">(Unsaved changes)</span></slot
              >
            </h3>
            <div class="h-align-items-center">
              <button
                type="button"
                class="close h-margin-left-m"
                aria-hidden="true"
                @click="close"
                :disabled="loading"
              >
                <img src="../../../../../../../public/assets/images/Collapse-drawer.svg" alt="" />
              </button>
            </div>
          </div>
        </div>
      </template>

      <template v-slot:modal-body>
        <div class="h-padding-left-l h-padding-right-l">
          <div v-if="!loading" class="h1-table">
            <table class="table dataTable h-noborder-input h-ccm-table" style="display: table">
              <thead class="thead-light">
                <tr>
                  <th style="width: 10%;">Problem</th>
                  <th style="width: 40%;">Desired outcomes</th>
                  <th style="width: 40%;">Nursing interventions</th>
                  <th style="width: 10%;">Status</th>
                </tr>
              </thead>

              <tbody v-if="microprotocols.length !== 0">
                <tr v-for="microprotocol of microprotocols" :key="microprotocol.id">
                  <td class="h-pre-wrap">
                    {{ microprotocol.problem }}
                  </td>

                  <td>
                    <span v-if="!microprotocol.visible_desired_outcomes.length">-</span>
                    <ul v-else style="list-style: none">
                      <li
                        v-for="(desired_outcome, index) of microprotocol.visible_desired_outcomes"
                        :key="desired_outcome.uuid"
                      >
                        <div
                          class="h-d-flex h-select-border h-space-between h-margin-bottom-s"
                          :class="{
                            'section-with-switcher':
                              index !== microprotocol.visible_desired_outcomes.length - 1
                          }"
                        >
                          <InputField
                            :id="
                              `microprotocol::${microprotocol.id}
                      ::desired_outcome::${desired_outcome.uuid}`
                            "
                            type="checkbox"
                            v-model="microprotocol.marked_desired_outcomes[desired_outcome.uuid]"
                            :disabled="loading"
                            :label="desired_outcome.value"
                            labelClass="white-space-pre-wrap"
                            @change="hasChanges = true"
                            class="h-margin-bottom-s h-margin-top-s"
                          />
                          <InputField
                            type="select"
                            v-model="microprotocol.desired_outcomes_statuses[desired_outcome.uuid]"
                            :disabled="loading"
                            @change="hasChanges = true"
                            :dense="true"
                            :choices="['Not started', 'In progress', 'Completed']"
                          />
                        </div>
                      </li>
                    </ul>
                  </td>

                  <td>
                    <span v-if="!microprotocol.visible_nursing_interventions.length">-</span>
                    <ul v-else style="list-style: none">
                      <li
                        v-for="nursing_intervention of microprotocol.visible_nursing_interventions"
                        :key="nursing_intervention.uuid"
                      >
                        <div class="h-margin-bottom-s">
                          <InputField
                            :id="
                              `microprotocol::${microprotocol.id}
                      ::nursing_intervention::${nursing_intervention.uuid}`
                            "
                            type="checkbox"
                            v-model="
                              microprotocol.marked_nursing_interventions[nursing_intervention.uuid]
                            "
                            :disabled="loading"
                            :label="nursing_intervention.value"
                            labelClass="white-space-pre-wrap"
                            @change="hasChanges = true"
                          />
                        </div>
                      </li>
                    </ul>
                  </td>
                  <td style="min-width: 50rem" class=" h-select-border">
                    <InputField
                      type="select"
                      v-model="microprotocol.status"
                      :disabled="loading"
                      @change="hasChanges = true"
                      :dense="true"
                      :choices="[[null, '<no_value>'], 'Not started', 'In progress', 'Completed']"
                      class="h-margin-bottom-s"
                    />
                  </td>
                </tr>
              </tbody>

              <tbody v-else>
                <tr>
                  <td class="text-center" colspan="100">No entries</td>
                </tr>
              </tbody>
            </table>
          </div>
          <Loader v-else />
        </div>
      </template>

      <template v-slot:modal-footer>
        <button
          type="button"
          class="h-btn h-btn-gray"
          @click="saveOutcomeInterventionMarks"
          :disabled="!hasChanges"
          :class="{ 'h-btn-accent': hasChanges }"
        >
          Save
        </button>
        <button
          type="button"
          class="h-btn h-btn-dangerous h-margin-left-s"
          :disabled="loading"
          @click="close"
        >
          Cancel
        </button>
      </template>
    </SlottedMessageInDrawer>
  </div>
</template>
<script>
import settings from '@/settings.js';
import Loader from '@/components/Loader';
import InputField from '@/components/InputField';
import SlottedMessageInDrawer from '@/components/dialogs/SlottedMessageInDrawer';
import eventBus from '@/event-bus';

export default {
  data() {
    return {
      loading: true,
      microprotocols: [],
      allMicroprotocols: [],
      hasChanges: false,
    };
  },

  components: {
    Loader,
    InputField,
    SlottedMessageInDrawer,
  },

  methods: {
    async load() {
      this.loading = true;
      const response = await this.$api.get(
        `${settings.BACKEND_URL}/api/v-nurse/microprotocols/client`,
        {
          client_id: this.clientId,
        },
      );
      this.microprotocols = response.microprotocols ?? [];

      const allMicroprotocolsResponse = await this.$api.get(
        `${settings.BACKEND_URL}/api/v-nurse/microprotocols`,
      );
      this.allMicroprotocols = allMicroprotocolsResponse.microprotocols;
      this.loading = false;
    },

    setMicroprotocols(microprotocols) {
      this.microprotocols = microprotocols ?? [];
      this.hasChanges = false;
    },

    async saveOutcomeInterventionMarks() {
      this.loading = true;
      const microprotocolMarks = {};
      this.microprotocols.forEach((microprotocol) => {
        microprotocolMarks[microprotocol.id] = {
          marked_desired_outcomes: microprotocol.marked_desired_outcomes,
          marked_nursing_interventions: microprotocol.marked_nursing_interventions,
          desired_outcomes_statuses: microprotocol.desired_outcomes_statuses,
          status: microprotocol.status,
        };
      });
      const response = await this.$api.post(
        `${settings.BACKEND_URL}/api/v-nurse/microprotocols/client/set-marks`,
        null,
        {
          data: {
            client_id: this.clientId,
            microprotocol_marks: microprotocolMarks,
          },
        },
      );
      this.setMicroprotocols(response.microprotocols);
      this.loading = false;
      this.close();
    },
    show() {
      this.loading = false;
      this.$refs.slottedMessage.show();
    },

    close(force) {
      this.$refs.slottedMessage.close(force);
      this.$refs.slottedMessage.active = false;
      eventBus.$emit('CloseEditCCM', {});
    },
  },

  async mounted() {
    await this.load();
  },

  props: {
    clientId: {
      type: Number,
      required: false,
    },
  },
};
</script>

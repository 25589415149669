<template>
  <div class="h-drawer-max79">
    <SlottedMessageInDrawer ref="slottedMessage" title="CCM protocols manager">
      <template v-slot:modal-body>
        <div>
          <div
            v-for="microprotocol of allLocalMicroprotocols"
            :key="microprotocol.id"
            class="h-margin-bottom-l h-padding-top-0 h-border-bottom-1"
          >
            <h4 class="h-font20 h-primary_shade_1 h-padding-bottom-s">Problem</h4>
            <InputField
              :id="`microprotocols-manager-dialog::${microprotocol.id}`"
              type="checkbox"
              checkboxSwitchType="success"
              v-model="enabledMicroprotocols[microprotocol.id]"
              :label="microprotocol.problem"
              :disabled="loading"
            />

            <div v-if="enabledMicroprotocols[microprotocol.id]" class="h-margin-top-l">
              <h4 class="h-font20 h-primary_shade_1 h-padding-bottom-xm">
                Desired outcomes <span class="h-h5">(Toggle to hide desired outcomes)</span>
              </h4>

              <h5 v-if="!microprotocol.desired_outcomes.length" class="h-h5 h-secondary_shade_1">
                No desired outcome
              </h5>
              <div v-else>
                <div
                  v-for="desired_outcome of microprotocol.desired_outcomes"
                  :key="desired_outcome.uuid"
                  class="h-margin-bottom-xm"
                >
                  <InputField
                    :id="
                      `microprotocolsDialog::${microprotocol.id}
                      ::desired_outcome::${desired_outcome.uuid}`
                    "
                    type="checkbox"
                    v-model="microprotocol.hidden_desired_outcomes[desired_outcome.uuid]"
                    :disabled="loading"
                    :label="desired_outcome.value"
                    labelClass="white-space-pre-wrap"
                  />
                </div>
              </div>
            </div>

            <div v-if="enabledMicroprotocols[microprotocol.id]" class="h-margin-top-l">
              <h4 class="h-font20 h-primary_shade_1 h-padding-bottom-xm">
                Nursing interventions
                <span class="h-h5">(Toggle to hide nursing interventions)</span>
              </h4>
              <h5
                v-if="!microprotocol.nursing_interventions.length"
                class="h-h5 h-secondary_shade_1"
              >
                No nursing interventions
              </h5>
              <div v-else>
                <div
                  v-for="nursing_intervention of microprotocol.nursing_interventions"
                  :key="nursing_intervention.uuid"
                  class="h-margin-bottom-xm"
                >
                  <InputField
                    :id="
                      `microprotocolsDialog::${microprotocol.id}
                      ::nursing_intervention::${nursing_intervention.uuid}`
                    "
                    type="checkbox"
                    v-model="microprotocol.hidden_nursing_interventions[nursing_intervention.uuid]"
                    :disabled="loading"
                    :label="nursing_intervention.value"
                    labelClass="white-space-pre-wrap"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>

      <template v-slot:modal-footer>
        <button type="button" class="h-btn h-btn-accent" @click="save" :disabled="loading">
          Save
        </button>
        <button
          type="button"
          class="h-btn h-btn-dangerous h-margin-left-s"
          @click="close"
          :disabled="loading"
        >
          Cancel
        </button>
      </template>
    </SlottedMessageInDrawer>
  </div>
</template>
<script>
import Vue from 'vue';
import settings from '@/settings.js';
import Loader from '@/components/Loader';
import SlottedMessageInDrawer from '@/components/dialogs/SlottedMessageInDrawer';
import InputField from '@/components/InputField';

export default {
  props: ['allMicroprotocols', 'selectedMicroprotocols', 'clientId', 'clientMicroprotocols'],
  data() {
    return {
      enabledMicroprotocols: {},
      allLocalMicroprotocols: {},
      loading: false,
    };
  },
  methods: {
    async save() {
      this.loading = true;
      const microprotocols = [];
      Object.keys(this.enabledMicroprotocols)
        .filter(id => this.enabledMicroprotocols[id])
        .forEach((microprotocolIdStr) => {
          const microprotocolId = parseInt(microprotocolIdStr, 10);
          const microprotocol = this.allLocalMicroprotocols.find(
            localMicroprotocol => localMicroprotocol.id === microprotocolId,
          );
          microprotocols.push({
            id: microprotocol.id,
            hidden_nursing_interventions: microprotocol.hidden_nursing_interventions,
            hidden_desired_outcomes: microprotocol.hidden_desired_outcomes,
          });
        });
      const response = await this.$api.post(
        `${settings.BACKEND_URL}/api/v-nurse/microprotocols/client/set`,
        null,
        {
          data: {
            client_id: this.clientId,
            microprotocols,
          },
        },
      );
      this.$emit('save', response.microprotocols);
      this.close();
    },

    show() {
      this.loading = false;
      this.enabledMicroprotocols = {};
      this.selectedMicroprotocols.forEach((microprotocol) => {
        Vue.set(this.enabledMicroprotocols, microprotocol.id, true);
      });
      this.allLocalMicroprotocols = this.allMicroprotocols.map((microprotocol) => {
        const clientMicroprotocol = this.clientMicroprotocols.find(c => c.id === microprotocol.id);
        if (clientMicroprotocol) return JSON.parse(JSON.stringify(clientMicroprotocol));
        return {
          ...microprotocol,
          hidden_desired_outcomes: {},
          hidden_nursing_interventions: {},
        };
      });

      this.$refs.slottedMessage.show();
    },

    close(force) {
      this.$refs.slottedMessage.close(force);
      this.$refs.slottedMessage.active = false;
    },
  },
  components: {
    SlottedMessageInDrawer,
    InputField,
  },
};
</script>

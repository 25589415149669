import Vue from "vue";

const BACKEND_URL = (() => {
  const env = process.env.VUE_APP_ENV;
  const ver_env = process.env.VUE_APP_TRIGGER;
  if (env === "production") {
    if (ver_env && (ver_env == 1 || ver_env == "01")) {
      return "https://api-app01.healent.com";
    } else {
      return "https://api-appmain.healent.com";
    }
  }
  if (env === "development") {
    return "https://api-dev.healent.com";
  }
  return "http://localhost:5000";
})();

const COGNITO_GATEWAY_URL = (() => {
  const env = process.env.VUE_APP_ENV;
  if (env === "production") return "https://apigwdev.healent.com";
  if (env === "development") return `${BACKEND_URL}/api/v-nurse/cognito/gateway`;
  return `${BACKEND_URL}/api/v-nurse/cognito/gateway`;
})();

export default {
  forceCognito: false,
  env: process.env.VUE_APP_ENV,

  BACKEND_URL,
  COGNITO_GATEWAY_URL,

  currentSession: Vue.observable({}),
  currentSessionLoaded: false,
  virgilData: {},
  careProtocols: [],
  twoMetricValues: ["Blood Pressure"],

  getInviteRoles() {
    if (!this.currentSession) {
      return [];
    }

    if (
      this.currentSession.user.is_superuser ||
      this.currentSession.user.roles.indexOf("system_administrator") !== -1
    ) {
      return ["doctor", "nurse", "nurse_administrator", "surgeon", "physiotherapist"];
    }
    if (this.currentSession.user.roles.indexOf("nurse_administrator") !== -1) {
      return ["doctor", "nurse", "surgeon", "physiotherapist"];
    }
    return [];
  },

  hasRole(role, ignoreSuperUser) {
    if (!this.currentSession) {
      return false;
    }
    if (this.currentSession.user.is_superuser && !ignoreSuperUser) {
      return true;
    }
    return this.currentSession.user.roles.indexOf(role) !== -1;
  },

  isSuperUser() {
    if (!this.currentSession) {
      return false;
    }
    return this.currentSession.user.is_superuser;
  },

  isBiller() {
    if (!this.currentSession) {
      return false;
    }
    return (
      this.currentSession.user?.roles.length === 1 &&
      this.currentSession.user?.roles[0] === "biller"
    );
  },

  canViewClients() {
    // eslint-disable-next-line
    for (let role of [
      "doctor",
      "nurse",
      "surgeon",
      "physiotherapist",
      "system_administrator",
      "nurse_administrator"
    ]) {
      if (this.hasRole(role, true)) {
        return true;
      }
    }
    return false;
  },

  userCanEditStaffMembers() {
    // eslint-disable-next-line
    for (let role of ["system_administrator", "nurse_administrator"]) {
      if (this.hasRole(role, true)) {
        return true;
      }
    }
    return false;
  }
};

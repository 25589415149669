var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"factorItem h-d-flex h-align-item-center h-cursor-pointer",on:{"click":function () { return _vm.viewDetails(_vm.item); }}},[_c('div',{staticClass:"h-d-flex h-w50 "},[_c('div',{staticClass:"h-fs-icon",attrs:{"data-tooltip":("" + (_vm.itemAttributes.hint))}},[_c('i',{class:("healenticons-" + (_vm.itemAttributes.class)),attrs:{"data-tooltip":("" + (_vm.itemAttributes.hint))}})]),_c('div',{staticClass:"align-left flex-column h-align-center h-d-flex"},[_c('h4',{class:[
    'h-font20',
    'h-margin-bottom-xxs',
    'h-text-left',
    _vm.isNull ? 'h-secondary_shade_1' : 'h-primary_shade_1'
  ],attrs:{"data-tooltip":("" + (_vm.itemAttributes.hint))}},[_vm._v(" "+_vm._s(_vm.itemAttributes.title)+" ")]),_c('h6',{staticClass:"h-h4 h-text-left h-secondary_shade_1",attrs:{"data-tooltip":("" + (_vm.isNull ? 'No data found in the specified range' : 'Last value provided by the patient'))}},[_vm._v(" "+_vm._s(_vm.isNull ? 'No data' : ((_vm.formatNumber(_vm.item.actual, _vm.itemAttributes.title)) + " " + (_vm.itemAttributes.unit)))+" ")])])]),_c('div',{staticClass:"h-w50 h-d-flex justify-content-end align-items-end"},[_c('h6',{staticClass:"h-h4 h-progress-value",style:(("color:" + (_vm.itemAttributes.trendColor) + " ;")),attrs:{"data-tooltip":("" + (_vm.notEnoughData
    ? 'No enough data'
    : _vm.isNull
      ? 'No data found in the specified range'
      : this.isFullJourney
        ? 'Trend (Change factor of the entire journey)'
        : 'Trend (Comparing the averages of the last 30 days with the 30 days before' +
        ' to determine the change factor)'))}},[(_vm.isNull || _vm.notEnoughData)?_c('span',{staticClass:"h-secondary_shade_1 h-margin-right-xs"},[_vm._v(" ? ")]):(_vm.item.trend === 0)?_c('span',{staticClass:"h-margin-right-xs",style:(("color:" + (_vm.itemAttributes.trendColor)))},[_vm._v("--")]):(_vm.item.trend > 0)?_c('i',{staticClass:"healenticons-path-3321-1",style:(("color:" + (_vm.itemAttributes.trendColor) + " ;"))}):_c('i',{staticClass:"healenticons-path-3322",style:(("color:" + (_vm.itemAttributes.trendColor) + " ;"))}),_vm._v(" "+_vm._s(_vm.notEnoughData ? '--' : _vm.isNull ? '--' : _vm.formatNumber(_vm.item.trend, _vm.itemAttributes.title))+_vm._s('%')+" ")]),_c('Progress',{attrs:{"color":_vm.itemAttributes.color,"value":_vm.progressValue,"min":_vm.item.min,"max":_vm.item.max,"isNull":_vm.isNull,"notEnoughData":_vm.notEnoughData}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }
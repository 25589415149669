var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"h-factor-progress"},[_c('div',{staticClass:"h-d-flex h-space-between h-margin-bottom-xs"},[_c('span',{staticClass:"h-h6 h-primary_shade_1",attrs:{"data-tooltip":("" + (_vm.notEnoughData
            ? 'No enough data'
            : _vm.isNull
            ? 'No data found in the specified range'
            : 'Min value of the range'))}},[_vm._v(_vm._s(_vm.notEnoughData ? '--' : _vm.formatNumber(_vm.min)))]),_c('span',{staticClass:"h-h6 h-primary_shade_1",attrs:{"data-tooltip":("" + (_vm.notEnoughData
            ? 'No enough data'
            : _vm.isNull
            ? 'No data found in the specified range'
            : 'Max value of the range'))}},[_vm._v(_vm._s(_vm.notEnoughData ? '--' : _vm.formatNumber(_vm.max)))])]),_c('div',{staticClass:"h-progress-container"},[_c('div',{staticClass:"h-progress-bar"}),(!_vm.isNull && !_vm.notEnoughData)?_c('div',{staticClass:"h-progress-point",style:({
        'margin-left': _vm.pointPosition,
        background: _vm.color
      }),attrs:{"data-tooltip":"Last value provided by the patient"}}):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div
    class="
      h-padding-left-l h-padding-right-l h-space-between h-align-items-center
      h-padding-bottom-l
    "
  >
    <h3 class="h-h3 h-primary_shade_1">
      Select care protocol:
      <InputField
        type="select"
        :choices="careProtocolChoices"
        style="min-width:20em;display:inline-block;"
        v-model="protocolId"
      />

      <button
        type="button"
        class="h-btn h-btn-accent h-margin-left-s"
        @click="newCareProtocol"
      >
        <i class="healenticons-plus mr-4"></i> Add
      </button>

      <button
        type="button"
        class="h-btn h-btn-dangerous h-margin-left-s"
        @click="deleteCareProtocol"
      >
        <i class="fas fa-trash-alt font-16 mr-4"></i> Delete
      </button>
    </h3>

    <EditCareProtocolDialog
      ref="EditCareProtocolDialog"
      :selectedHospitalId="selectedHospitalId"
      :hospitalChoices="hospitalChoices"
      @protocolUpdated="protocolCreated"
    />
    <DeleteCrateProtocolDialog
      ref="DeleteCrateProtocolDialog"
      @protocolIdDeleted="protocolIdDeleted"
    />
  </div>
</template>

<script>
import InputField from '@/components/InputField';
import EditCareProtocolDialog from '@/views/settings/care_protocols/components/EditCareProtocolDialog';
import DeleteCrateProtocolDialog from '@/views/settings/care_protocols/components/DeleteCrateProtocolDialog';


export default {
  watch: {
    protocolId() {
      this.$emit('protocolIdSelected', this.protocolId);
    },
  },

  methods: {
    newCareProtocol() {
      this.$refs.EditCareProtocolDialog.show({});
    },

    deleteCareProtocol() {
      this.$refs.DeleteCrateProtocolDialog.show(this.protocolId, this.selectedHospitalId);
    },

    protocolCreated(protocol) {
      this.$emit('protocolCreated', protocol);
      this.protocolIdSelected = protocol.id;
    },

    protocolIdDeleted(protocolId) {
      this.$emit('protocolIdDeleted', protocolId);
    },
  },

  props: ['protocolId', 'careProtocolChoices', 'hospitalChoices', 'selectedHospitalId'],

  components: {
    InputField,
    EditCareProtocolDialog,
    DeleteCrateProtocolDialog,
  },
};
</script>

<template>
  <div>
    <div class="h-space-between h-align-items-center">
      <h3 class="h-h3 h-primary_shade_1">Annual health risk assessment</h3>
      <div
        class="
          h-padding-left-l h-padding-right-l h-space-between h-align-items-center
        "
      >
        <div>
          <button
            type="button"
            class="h-btn h-btn-accent h-margin-left-s"
            @click="newItem"
          >
            <i class="healenticons-plus h-padding-right-xs"></i>New item
          </button>
        </div>
      </div>
    </div>

    <div v-if="!loading" class="h1-table">
      <table class="table dataTable">
        <thead class="thead-light">
          <tr>
            <th class="w-1 text-center text-nowrap">Order</th>
            <th class="text-left">Question</th>
            <th class="text-left">Answers</th>
            <th class="text-left">Multiselect</th>
            <th class="w-1 text-nowrap">Action</th>
          </tr>
        </thead>

        <tbody v-if="questions.length === 0">
          <tr>
            <td colspan="1000" class="text-center text-muted">Empty</td>
          </tr>
        </tbody>

        <tbody v-else>
          <tr
            v-for="question of questions"
            :key="question.id"
          >
            <td class="text-center">{{ question.order }}</td>
            <td class="text-left">{{ question.question }}</td>
            <td class="text-left">
              <div v-for="answer of question.answers" :key="answer.uuid">
                <strong>{{ answer.key }}</strong> - {{ answer.value }} <i>({{ answer.score }})</i>
              </div>
            </td>
            <td>
              {{ question.is_multiselect ? 'Yes' : 'No' }}
            </td>
            <td>
              <a
                href="javascript:void(0);"
                class="mr-2"
                @click="showEditDialog(question)"
              >
                <i class="fas fa-edit text-info font-16" title="Edit"></i>
              </a>

              <a
                href="javascript:void(0);"
                class="mr-2"
                @click="showDeleteDialog(question)"
              >
                <i
                  class="fas fa-trash-alt text-danger font-16"
                  title="Delete"
                ></i>
              </a>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <Loader v-else style="position: relative !important; margin-top: 50rem !important" />

    <EditQuestionsDialog
      ref="editQuestionsDialog"
      @save="saveEntry"
    />
    <DeleteQuestionDialog
      ref="deleteQuestionsDialog"
      @del="delEntry"
    />
  </div>
</template>

<script>
import settings from '@/settings.js';
import EditQuestionsDialog from './EditQuestionsDialog';
import Loader from '@/components/Loader';
import DeleteQuestionDialog from './DeleteQuestionDialog';

export default {
  data() {
    return {
      loading: true,
      questions: [],
    };
  },

  methods: {
    saveEntry(questions) {
      this.questions = questions;
    },

    delEntry(questions) {
      this.questions = questions;
    },

    newItem() {
      this.$refs.editQuestionsDialog.show({});
    },

    showEditDialog(question) {
      this.$refs.editQuestionsDialog.show(question);
    },

    showDeleteDialog(question) {
      this.$refs.deleteQuestionsDialog.show(question);
    },
  },

  watch: {
    questions() {
      this.$emit('questions', this.questions);
    },
  },

  async mounted() {
    const response = await this.$api.get(
      `${settings.BACKEND_URL}/api/v-nurse/microprotocols/questions`,
    );
    this.questions = response.questions;
    this.loading = false;
  },

  components: {
    EditQuestionsDialog,
    Loader,
    DeleteQuestionDialog,
  },
};
</script>

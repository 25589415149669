<template>
  <SlottedMessageInDrawer
    ref="slottedMessage"
    title="Resend invitation e-mail"
    :closeDisabled="loading"
  >
    <template v-slot:modal-body>
      <div v-if="!loading && data" class="h-h4 h-primary_shade_1">
        Do you want to resend invitation letter to {{ data.name }}? ({{
          data.email
        }})
      </div>

      <Loader v-else />
    </template>

    <template v-slot:modal-footer>
      <button
        type="button"
        class="h-btn h-btn-accent"
        @click="confirm"
        :disabled="loading"
      >
        Confirm
      </button>

      <button
        type="button"
        class="h-btn h-btn-dangerous h-margin-left-l"
        @click="close"
        :disabled="loading"
      >
        Close
      </button>
    </template>
  </SlottedMessageInDrawer>
</template>


<script>
import settings from '@/settings.js';
import Loader from '@/components/Loader';
import SlottedMessageInDrawer from '@/components/dialogs/SlottedMessageInDrawer';

export default {
  data() {
    return {
      loading: false,
      data: null,
    };
  },

  methods: {
    show(data) {
      this.data = data;
      this.loading = false;
      this.$refs.slottedMessage.show();
    },

    close(force) {
      this.$refs.slottedMessage.close(force);
    },

    async confirm() {
      this.loading = true;
      const response = await this.$api.get(
        `${settings.BACKEND_URL}/api/v-nurse/resend-invite`,
        {
          userId: this.data.id,
          register_link_template:
            window.location.origin
            + this.$router.resolve({
              name: 'Register',
              params: {
                code: ':code',
              },
            }).href,
        },
      );
      if (response.status === 'ok') {
        $.notify('E-mail with activation code was sent.', {
          position: 'top center',
          className: 'success',
        });
      } else {
        $.notify('Failed to send an e-mail with activation code.', {
          position: 'top center',
          className: 'error',
        });
      }
      this.close(true);
    },
  },

  components: {
    Loader,
    SlottedMessageInDrawer,
  },
};
</script>

<template>
  <div class="h-drawer-max80 h-no-padding">
    <SlottedMessageInDrawer ref="slottedMessage" modalSize="lg" :title="tableData.title">
      <template v-slot:modal-body>
        <div
          class="h1-table h-padding-top-l"
          v-if="riskSurveyData && riskSurveyData.submitted_on_fulldatetime"
        >
          <table class="dataTable h-noborder-input table d-table h-drawer">
            <thead>
              <tr>
                <th>OSORD</th>
                <th>MEDD</th>
                <th>Submitted On</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{{ riskSurveyData.osord_value.toFixed(2) }}</td>
                <td>{{ riskSurveyData.medd_value.toFixed(2) }}</td>
                <td>{{ riskSurveyData.submitted_on_fulldatetime }}</td>
              </tr>
            </tbody>
          </table>

          <table class="dataTable h-noborder-input table d-table h-drawer">
            <thead>
              <tr>
                <th>OSORD Section</th>
              </tr>
            </thead>
          </table>

          <table
            class="dataTable h-noborder-input table d-table h-drawer"
            v-for="section of osordSections"
            :key="section.section"
          >
            <tbody>
              <tr v-for="question of section.questions" :key="question.question">
                <td>{{ question.question }}</td>
                <table class="dataTable h-noborder-input table d-table h-drawer">
                  <tbody>
                    <tr v-for="option of question.options" :key="option.optionText">
                      <td>{{ option.optionText }} {{ question.selectedOptions }}</td>
                      <td>
                        <InputField
                          type="checkbox"
                          :id="`'OSORD::${option.name}'`"
                          :value="question.selectedOptions.includes(option.name)"
                          @input="
                            toggleOsord(
                              question.selectedOptions,
                              option.name,
                              question.options[question.options.length - 1].name
                            )
                          "
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </tr>
            </tbody>
          </table>

          <table class="dataTable h-noborder-input table d-table h-drawer">
            <thead>
              <tr>
                <th>MEDD Section</th>
              </tr>
            </thead>
          </table>

          <table class="dataTable h-noborder-input table d-table h-drawer">
            <thead>
              <tr>
                <th>Medication</th>
                <th style="width:20em">Quantity</th>
                <th style="width:20em">Strength</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="meddItem of meddSection" :key="meddItem.id">
                <td>{{ meddItem.medication }}</td>
                <td>
                  <InputField type="number" v-model="meddItem.quantity" @input="updateSummery" />
                </td>
                <td>
                  <InputField type="number" v-model="meddItem.strength" @input="updateSummery" />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </template>

      <template v-slot:modal-footer>
        <button type="button" class="h-btn h-btn-accent" @click="save" :disabled="saving">
          {{ saving ? 'Saving...' : 'Save' }}
        </button>

        <button type="button" class="h-btn h-btn-dangerous h-margin-left-s" @click="close">
          Close
        </button>
      </template>
    </SlottedMessageInDrawer>
  </div>
</template>

<style lang="css" scoped>
.h1-table table.table td,
.h1-table table.table th {
  white-space: unset;
}

.h1-table table.dataTable td,
.h1-table table.dataTable th {
  white-space: unset;
}
</style>

<script>
import settings from '@/settings.js';
import InputField from '@/components/InputField';
import SlottedMessageInDrawer from '@/components/dialogs/SlottedMessageInDrawer';
import { getSummeryValues } from '@/components/surveys/utils';

export default {
  data() {
    return {
      saving: false,
      tableData: {
        title: '',
        assessmentTitle: '',
      },
      riskSurveyData: {},
      osordSections: [],
      meddSection: [],
    };
  },

  methods: {
    getAssessmentByTitle(title) {
      return this.allAssessments.filter((assessment) => {
        return assessment.assessment_title === title;
      });
    },

    async show(title, riskSurveyData) {
      this.saving = false;
      this.tableData = {
        title,
        assessmentTitle: title,
        sections: [],
        tableRows: [],
      };
      this.riskSurveyData = JSON.parse(JSON.stringify(riskSurveyData));
      this.osordSections = this.riskSurveyData.survey_answers.osordSection[0].sections;
      this.meddSection = this.riskSurveyData.survey_answers.meddSection;

      await this.$forceUpdate();
      this.$refs.slottedMessage.show();
    },

    toggleOsord(container, optionName, noneOptionName) {
      const index = container.indexOf(optionName);
      if (index === -1) {
        if (optionName === noneOptionName) {
          container.splice(0, container.length);
        } else {
          const noneIndex = container.indexOf(noneOptionName);
          if (noneIndex !== -1) {
            container.splice(noneIndex, 1);
          }
        }
        container.push(optionName);
      } else {
        container.splice(index, 1);
      }

      this.updateSummery();
    },

    updateSummery() {
      const summery = getSummeryValues(this.riskSurveyData.survey_answers);
      this.riskSurveyData.medd_value = summery.medd;
      this.riskSurveyData.osord_value = summery.osord;
    },

    reload() {
      const reloadInfo = {};
      reloadInfo.title = this.tableData.title;
      reloadInfo.assessmentTitle = this.tableData.assessmentTitle;
      this.$emit('reloadChartInfo', reloadInfo);
    },

    close() {
      this.$refs.slottedMessage.close();
    },

    async save() {
      const response = await this.$api.post(
        `${settings.BACKEND_URL}/api/v-nurse/assesments/risk_assessment_answers/update`,
        {
          clientId: this.client.id,
        },
        this.riskSurveyData,
      );
      this.$emit('update', response);
      this.close();
    },
  },

  props: {
    client: {
      type: Object,
    },
  },

  components: {
    SlottedMessageInDrawer,
    InputField,
  },
};
</script>

<template>
  <div ref="wrapper" v-if="settings.canViewClients()">
    <nav class="navbar navbar-expand navbar-light bg-white mb-0 pb-0" style="z-index: 9">
      <div
        v-if="!currentSession.flags.has_phone && currentSession.global_settings.show_no_phone_warning
    "
        class="isDoctor-note template"
        style="font-size: 110%">
        You do not have a mobile phone number set, two factor authentication is not enabled.
        <br />
        Go to your <router-link :to="{ name: 'Profile' }">profile</router-link> to set it.
      </div>
      <div class="h-align-item-center h-d-flex h-margin-right-l h-space-between h-w-100">
        <div class="h-row">
          <div class="topBar">
            <h5 id="topbarTitle" class="h-margin-top-s h-margin-bottom-xs">
              <span class="h-h3 h-primary_shade_1"> {{ $breadcrums.backlink.title }}</span>
            </h5>
          </div>
        </div>
      </div>
      <div class="row col-6" style="justify-content: flex-end; line-height: normal">
        <div id="navbarSupportedContent">
          <ul class="h-d-flex h-align-item-center">
            <li class="h-hight-50 h-hide-on-tool h-md-hide">
              <SearchPatient />
            </li>
            <li class="h-topbar-item h-md-hide">
              <a class="h-accent" href="#" @click="addDrawer">
                <i class="healenticons-plus"></i>
              </a>
            </li>

            <li class="h-topbar-item h-margin-left-m h-hide-on-tool h-md-hide">
              <a ref="reportIcon" href="#">
                <i class="healenticons-reports"></i>
                <span class="badge badge-bell badge-pill">{{ downloadOrdersCount }}</span>
              </a>
            </li>

            <li class="h-topbar-item h-margin-left-m h-hide-on-tool h-md-hide">
              <Notifications />
            </li>

            <li class="h-topbar-item h-hide-on-tool h-md-hide">
              <a href="#" @click="settingsDrawer">
                <img
                  :src="currentSession.user.profile || '/assets/images/users/user-4.jpg'"
                  alt="profile-user"
                  class="h-profile-user rounded-circle" />
              </a>
            </li>
          </ul>
          <!--           <ul class="nav navbar-nav ml-auto">
              <li class="nav-item"></li>
            </ul> -->
        </div>
        <div id="h-menu" class="h-margin-right-l h-f-none">
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>
    </nav>

    <DownloadAllReportsDrawer ref="downloadAllReportsDrawer" />
    <SettingsDrawer ref="SettingsDrawer" />
    <AddDrawer ref="AddDrawer" />
    <PatientProfileInDrawer ref="patientProfileDrawer" :key="clientID" />
  </div>
</template>

<script>
import resize from 'vue-resize-directive';
import settings from '@/settings.js';
import Notifications from './Notifications';
import SearchPatient from './SearchPatient';
import DownloadAllReportsDrawer from '@/components/reports/DownloadAllReportsDrawer';

import eventBus from '../../event-bus';
import PatientProfileInDrawer from '../../views/surgery_navigator/all_patients/components/PatientProfileInDrawer';
import SettingsDrawer from './TopBarDrawers/SettingsDrawer';
import AddDrawer from './TopBarDrawers/AddDrawer';

export default {
  data() {
    return {
      currentSession: settings.currentSession,
      specialBreadcrumbText: '',
      client: null,
      isActive: false,
      downloadOrdersCount: 0,
      rpm_rtm_strat_visible: true,
      running_env: null,
      settings,
      AllPatientsActive: true,
      AppointmentActive: false,
      appointment_being_edited: false,
      clientChanged: false,
      clientID: null,
    };
  },

  beforeDestroy() {
    eventBus.$offOwner(this);
    this.client = null;
  },

  async mounted() {
    this.running_env = await this.$api.getRunningEnviroment();
    // Check if the user closed the window with an unsaved note in a previous session
    const possibleUnsavedAppointmentNoteId = localStorage.leavingUnsavedAppointmentId
      ? localStorage.leavingUnsavedAppointmentId
      : null;
    if (possibleUnsavedAppointmentNoteId) {
      // alert(`User did not save note for this appointment: ${possibleUnsavedAppointmentNoteId}
      // in a previous session... `);
      localStorage.removeItem('leavingUnsavedAppointmentId');
    }
    eventBus.$on(
      'specialBreadcrumbTextEvent',
      (event) => {
        this.specialBreadcrumbText = event.specialText;
      },
      this,
    );

    eventBus.$on(
      'refreshReportOrdersCounter',
      (event) => {
        this.$api.getDownloadAllReportsOrdersCount().then((response) => {
          this.downloadOrdersCount = response.data[0].totalOrders;
        });
      },
      this,
    );

    eventBus.$on(
      'uncompleted-appointment-edit-begin',
      (event) => {
        this.appointment_being_edited = true;
      },
      this,
    );

    eventBus.$on(
      'uncompleted-appointment-edit-stop',
      (event) => {
        this.appointment_being_edited = false;
      },
      this,
    );
    $(this.$refs.leftArrow).on('click', () => {
      eventBus.$emit('LeftArrowOnTopBarClicked');
    });

    $(this.$refs.reportIcon).on('click', () => {
      this.openAllDownloadReports();
    });

    this.$api.getDownloadAllReportsOrdersCount().then((response) => {
      this.downloadOrdersCount = response.data[0].totalOrders;
    });
    $(document).ready(() => {
      // Handle input changes in the search box
      $('#customSearchBox').on('input', function () {
        if ($(this).val() !== '') {
          $('#search-patients').addClass('showTable');
        } else {
          $('#search-patients').removeClass('showTable');
        }
      });

      // Handle clicking the search button
      $('#searchTrigger').click(function () {
        performSearch();
      });

      function performSearch() {
        if ($('#customSearchBox').val() !== '') {
          $('#search-patients').addClass('showTable');
        }
      }

      // Handle clicking outside the search area
      $(document).click(function (event) {
        if (!$(event.target).closest('.search-container, #search-patients').length) {
          $('#search-patients').removeClass('showTable');
          $('#customSearchBox').val('');
        }
      });

      if (
        window.location.href.indexOf('opioid-risk-assessment') > -1 ||
        window.location.href.indexOf('rpmrtm-assessment') > -1
      ) {
        $('nav').css('z-index', '99999999');
        $('#leftNav').addClass('d-none');
        $('.h-hide-on-tool').addClass('d-none');
        $('#AssessmentTool').removeClass('d-none');
        $('.collapse:not(.show)').addClass('d-flex');
        $('#h-menu').addClass('h-force-none');
        $('h1.h-h1').addClass('mob-h-h3');
      } else {
        $('#leftNav').removeClass('d-none');
        $('.h-hide-on-tool').removeClass('d-none');
      }
      if (window.location.href.indexOf('rpmrtm-assessment') > -1) {
        $('nav').addClass('mob-nav');
      }
    });
    eventBus.$on(
      'profile-closed',
      () => {
        const currentUrl = new URL(window.location.href);
        const hash = currentUrl.hash;

        if (hash.includes('patientID')) {
          const hashParams = new URLSearchParams(hash.slice(hash.indexOf('?')));
          hashParams.delete('patientID');
          window.location.hash = `${hash.split('?')[0]}?${hashParams.toString()}`;
        }
      },
      this,
    );
    eventBus.$on(
      'profile-drawer-closed',
      () => {
        const currentUrl = new URL(window.location.href);
        const hash = currentUrl.hash;

        if (hash.includes('patientID')) {
          const hashParams = new URLSearchParams(hash.slice(hash.indexOf('?')));
          hashParams.delete('patientID');
          window.location.hash = `${hash.split('?')[0]}?${hashParams.toString()}`;
        }
      },
      this,
    );
    const currentUrl = new URL(window.location.href);
    const hash = currentUrl.hash;
    const hashParams = new URLSearchParams(hash.slice(hash.indexOf('?')));

    if (hashParams.has('patientID')) {
      const patientID = hashParams.get('patientID');
      this.clientID = patientID;
      this.$nextTick(async () => {
        if (this.$refs.patientProfileDrawer) {
          await this.$refs.patientProfileDrawer.show(patientID);
        }
      });
    }
  },
  computed: {
    specialBreadcrumbTextCharacters() {
      return this.specialBreadcrumbText !== undefined ? this.specialBreadcrumbText.length : 0;
    },
    isDevEnvironment() {
      let isDev = false;

      if (this.running_env) isDev = this.running_env.environment === 'development';

      return isDev;
    },
  },
  methods: {
    settingsDrawer() {
      this.$refs.SettingsDrawer.show();
    },
    addDrawer() {
      this.$refs.AddDrawer.show();
    },

    async sleep(msec) {
      return new Promise(resolve => setTimeout(resolve, msec));
    },
    openAllDownloadReports() {
      this.$refs.downloadAllReportsDrawer.show();
    },
    Msgcloce() {
      $('.MSG-onload').hide();
    },
  },
  directives: {
    resize,
  },
  components: {
    Notifications,
    SearchPatient,
    DownloadAllReportsDrawer,
    PatientProfileInDrawer,
    SettingsDrawer,
    AddDrawer,
  },
};
</script>

<template>
  <div class="recipeWrapper">
    <router-link
      class="h-a-recipe"
      :to="{
        name: 'Recipe',
        params: {
          recipeId: recipe.recipe_id,
        },
      }"
    >
      <div class="h-space-between h-align-items-center">
        <div class="recipeDescription">
          <h4>
            <router-link
              :to="{
                name: 'Recipe',
                params: {
                  recipeId: recipe.recipe_id,
                },
              }"
            >
              {{ index }}. {{ recipe.title }}
            </router-link>
          </h4>
          <div class="recipeDescriptionText">
            <div><strong>Cuisine:</strong> {{ recipe.cuisine }}</div>
            <div><strong>Servings:</strong> {{ recipe.servings }}</div>
            <div><strong>Cilocalories:</strong> {{ recipe.kcal }}</div>
            <div><strong>Cooking time:</strong> {{ recipe.cook_time }}</div>
            <div v-if="recipeTags.length" class="mt-3">
              <div class="d-flex flex-row">
                <div
                  v-for="tag of recipeTags"
                  :key="tag"
                  class="badge badge-primary tag"
                >
                  {{ tag }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="imageWrapper">
          <img
            :src="recipe.image"
            class="recipe mx-auto d-block"
            alt="Meal photo"
          />
        </div>
      </div>
    </router-link>
  </div>
</template>


<script>
export default {
  computed: {
    recipeTags() {
      return this.recipe.tags.split('|').sort();
    },
  },

  props: {
    recipe: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
  },
};
</script>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"recipeWrapper"},[_c('router-link',{staticClass:"h-a-recipe",attrs:{"to":{
      name: 'Recipe',
      params: {
        recipeId: _vm.recipe.recipe_id,
      },
    }}},[_c('div',{staticClass:"h-space-between h-align-items-center"},[_c('div',{staticClass:"recipeDescription"},[_c('h4',[_c('router-link',{attrs:{"to":{
              name: 'Recipe',
              params: {
                recipeId: _vm.recipe.recipe_id,
              },
            }}},[_vm._v(" "+_vm._s(_vm.index)+". "+_vm._s(_vm.recipe.title)+" ")])],1),_c('div',{staticClass:"recipeDescriptionText"},[_c('div',[_c('strong',[_vm._v("Cuisine:")]),_vm._v(" "+_vm._s(_vm.recipe.cuisine))]),_c('div',[_c('strong',[_vm._v("Servings:")]),_vm._v(" "+_vm._s(_vm.recipe.servings))]),_c('div',[_c('strong',[_vm._v("Cilocalories:")]),_vm._v(" "+_vm._s(_vm.recipe.kcal))]),_c('div',[_c('strong',[_vm._v("Cooking time:")]),_vm._v(" "+_vm._s(_vm.recipe.cook_time))]),(_vm.recipeTags.length)?_c('div',{staticClass:"mt-3"},[_c('div',{staticClass:"d-flex flex-row"},_vm._l((_vm.recipeTags),function(tag){return _c('div',{key:tag,staticClass:"badge badge-primary tag"},[_vm._v(" "+_vm._s(tag)+" ")])}),0)]):_vm._e()])]),_c('div',{staticClass:"imageWrapper"},[_c('img',{staticClass:"recipe mx-auto d-block",attrs:{"src":_vm.recipe.image,"alt":"Meal photo"}})])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }
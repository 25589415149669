<template>
  <div>
    <!-- PatientInfo -->
    <PatientInfo :client="client" />

    <!-- Utilization calculator
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <h4>
              Utilization calculator
            </h4>

            <table id="datatable" class="table">
              <thead class="thead-light">
                <tr>
                  <th>Item</th>
                  <th>Date</th>
                  <th>Nr.Units</th>
                  <th>$ Utilization</th>
                </tr>
              </thead>

              <tbody>
                <tr>
                  <td>SNF Stay</td>
                  <td>Oct 11, 2020</td>
                  <td>4</td>
                  <td>$3000</td>
                </tr>
                <tr>
                  <td>Home Health</td>
                  <td>Oct 12, 2020</td>
                  <td>1</td>
                  <td>$150</td>
                </tr>
                <tr>
                  <td>Home Health</td>
                  <td>Oct 16, 2020</td>
                  <td>1</td>
                  <td>$150</td>
                </tr>
                <tr>
                  <td>Home Health</td>
                  <td>Oct 19, 2020</td>
                  <td>1</td>
                  <td>$150</td>
                </tr>
                <tr>
                  <td>Outpatient PT</td>
                  <td>Oct 22, 2020</td>
                  <td>1</td>
                  <td>$150</td>
                </tr>
                <tr>
                  <td>Outpatient PT</td>
                  <td>Oct 25, 2020</td>
                  <td>1</td>
                  <td>$150</td>
                </tr>
                <tr>
                  <td><strong class="ml-2">TOTAL UTILIZATION</strong></td>
                  <td></td>
                  <td></td>
                  <td><strong>$3750</strong></td>
                </tr>
              </tbody>
            </table>

            <hr />
          </div>
        </div>
      </div>
    </div> -->

    <!-- HealthStatus -->
    <!-- <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body"> -->

    <!-- <HealthStatus :holder="client" /> -->
    <!--
          </div>
        </div>
      </div>
    </div> -->

    <!-- MedicationUse -->
    <!-- <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <MedicationUse :client="client" />
          </div>
        </div>
      </div>
    </div> -->

    <!-- Assessment -->
    <!-- <div class="row" v-if="client.care_protocol && isAdmin">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <h4>
              Assessments
            </h4>

            <AssesmentClient :clientId="client.id" :client="client" />
          </div>
        </div>
      </div>
    </div> -->

    <!-- HealentIndexFunction -->
    <!-- <div class="row" v-if="
      (client.hif_progress.second_period.data.score !== null) ||
      (client.hif_progress.first_period.data.score !== null)
    ">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <h4>
              Healent Function Index
            </h4>

            <HealentIndexFunction :client="client" />
          </div>
        </div>
      </div>
    </div> -->

    <!-- Lab Work
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <h4>
              Lab Work
            </h4>

            <table id="datatable" class="table">
              <thead class="thead-light">
                <tr>
                  <th>Name</th>
                  <th>Result</th>
                  <th>Status</th>
                  <th>Normal Range</th>
                  <th>Result Date</th>
                </tr>
              </thead>

              <tbody>
                <tr>
                  <td>Hemoglobin A1C %</td>
                  <td>7.2</td>
                  <td><span class="badge badge-soft-danger">Elevated</span></td>
                  <td>below 6</td>
                  <td>Apr 25, 2020</td>
                </tr>
                <tr>
                  <td>Albumin g/DL</td>
                  <td>4.5</td>
                  <td><span class="badge badge-soft-success">Normal</span></td>
                  <td>between 3.4 to 5.4 g/DL</td>
                  <td>Apr 20, 2020</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>-->
  </div>
</template>


<script>
import settings from '@/settings.js';
import PatientInfo from './PatientInfo';

export default {
  props: {
    client: {
      type: Object,
    },
  },

  computed: {
    showCareProtocol() {
      if (!this.client.care_protocol) return false;
      return (
        this.client.care_protocol_items.filter((item) => {
          return item.enabled_for_surgeon;
        }).length !== 0
      );
    },

    isAdmin() {
      return settings.hasRole('system_administrator');
    },
  },

  components: {
    PatientInfo,
  },
};
</script>

<template>
  <main class="login">
    <link href="/assets/css/index.css" rel="stylesheet" type="text/css" />

    <div class="h-row">
      <div class="h-col-desktop-8 bg-accent h-hide-mobile">
        <div id="login_animation">
          <lottie-animation path="./assets/js/lottie_files/login.json" />
        </div>
      </div>
      <div class="h-col-desktop-4 h-col-mobile-12 h-d-flex">
        <div class="login-card">
          <h1 class="h-h1 h-primary_shade_1 h-text-center">Change your password</h1>
          <p
            v-if="!loading && !globalMessage"
            class="h-h3 h-secondary_shade_1 h-text-center h-margin-top-s">
            Set new password
          </p>
          <!--end auth-logo-text-->
          <div v-if="!loading && !globalMessage">
            <form class="form-horizontal auth-form h-margin-top-xl">

              <div class="form-group h-row h-padding-bottom-l">
                <div class="h-col-desktop-12 h-col-mobile-12">
                  <label for="useremail" class="h-label">Email</label>
                  <div class="input-group">
                    <span class="auth-form-icon">
                      <i class="dripicons-user"></i>
                    </span>
                    <input
                      class="h-input"
                      :class="{ 'is-invalid': errors.email }"
                      id="useremail"
                      type="email"
                      placeholder="email"
                      :value="recoverData.email"
                      readonly />
                    <div class="invalid-feedback" v-if="errors.email">
                      {{ errors.email }}
                    </div>
                  </div>
                </div>
              </div><!--end form-group-->

              <div class="form-group h-row h-padding-bottom-l">
                <div class="h-col-desktop-12 h-col-mobile-12">
                  <label for="userpassword" class="h-label">Password</label>
                  <div class="input-group">
                    <span class="auth-form-icon">
                      <i class="dripicons-lock"></i>
                    </span>
                    <input
                      class="h-input"
                      :class="{ 'is-invalid': errors.password }"
                      id="userpassword"
                      type="password"
                      placeholder="Password"
                      v-model="password" />
                    <div class="invalid-feedback" v-if="errors.password">
                      {{ errors.password }}
                    </div>
                  </div>
                </div>
              </div><!--end form-group-->

              <div class="form-group h-row h-padding-bottom-l">
                <div class="h-col-desktop-12 h-col-mobile-12">
                  <label for="conf_password" class="h-label">Confirm Password</label>
                  <div class="input-group">
                    <span class="auth-form-icon">
                      <i class="dripicons-lock-open"></i>
                    </span>
                    <input
                      class="h-input"
                      :class="{ 'is-invalid': errors.confirmPassword }"
                      id="conf_password"
                      type="password"
                      placeholder="Enter Confirm Password"
                      v-model="confirmPassword" />
                    <div class="invalid-feedback" v-if="errors.confirmPassword">
                      {{ errors.confirmPassword }}
                    </div>
                  </div>
                </div>
              </div><!--end form-group-->
              <div class="form-group h-row">
                <div class="h-col-desktop-12 h-col-mobile-12 h-margin-top-l">
                  <button
                    class="h-btn h-btn-accent h-btn-full"
                    type="button"
                    @click="changePassword">
                    Change password
                  </button>
                </div>
                <!--end col-->
              </div>
            </form><!--end form-->

          </div>

          <Loader v-if="loading" />
        </div>
        <!--end card-->
      </div>
      <!--end auth-page-->
    </div>
  </main>
</template>

<style lang="stylus" scoped>
.account-body {
  background-color: transparent;
  padding-top: 2em;
  padding-bottom: 4em;
}
 input[readonly] {
    background-color:var(--secondary_shade_2) 
    cursor: not-allowed; 
 }
</style>
<script>
import settings from '@/settings.js';
import Loader from './components/Loader';
import virgilHelper from '@/utils/virgil_helper';


export default {
  name: 'ChangePassword',

  data() {
    return {
      loading: true,

      recoverData: {
        email: '',
      },
      password: '',
      confirmPassword: '',

      errors: {},

      globalMessage: '',

      isMobile: this.$route.query.mobile === 'true',
    };
  },

  watch: {
    password() {
      this.errors.password = null;
    },

    confirmPassword() {
      this.errors.confirmPassword = null;
    },
  },

  methods: {
    async changePassword() {
      this.loading = true;
      const response = await this.$api.usePasswordRestore({
        code: this.$route.params.code,
        password: this.password,
        confirmPassword: this.confirmPassword,
      });

      this.errors = response.errors || {};
      if (response.status === 'error') {
        if (response.message) {
          this.globalMessage = response.message;
        }
        this.loading = false;
        return;
      }
      if (response.status === 'ok') {
        this.$router.push({
          name: 'PasswordChanged',
        }).catch((err) => { });
      }
    },
  },

  async mounted() {
    const response = await this.$api.getPasswordRestore(this.$route.params.code);
    this.loading = false;
    if (response.status === 'error') {
      this.globalMessage = response.message;
      return;
    }
    this.recoverData = response.data;
  },

  components: {
    Loader,
  },
};
</script>

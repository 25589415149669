<template>
  <div>
    <div class="h-space-between h-align-items-center h-mob-align">
      <div class="h-space-between h-align-items-center w-50">
        <div class="w-50">
          <label class="h-label h-pross"
            >Patient
            <select
              id="progressReport-searchClient"
              class="h-input"
              placeholder="Type to search..."
              data-noresults-text="No patients found."
              autocomplete="off"
            ></select
          ></label>
        </div>
        <div class="w-50 h-margin-left-l">
          <label class="h-label">Report period</label>
          <input type="month" v-model="reportDateStart" class="h-input" />
        </div>
      </div>
      <div>
        <button
          type="button"
          class="h-btn h-btn-accent"
          @click="downloadPatientReport"
          :disabled="!downloadEnabled"
        >
          Download patient report
        </button>
      </div>
    </div>
    <div class="row h-mob-left">
      <div class="col-12 input-row text-right">
        <InputField
          id="patientReportForm::skip-empty-reports"
          type="checkbox"
          checkboxSwitchType="success"
          v-model="skipEmptyReports"
          label="Skip empty reports"
          class="
            d-inline-block
            h-custom-checkbox h-margin-bottom-s h-margin-right-l
          "
        />
        <button
          type="button"
          class="h-btn h-btn-accent h-margin-left-s"
          @click="downloadAllReports"
          :disabled="!allDownloadEnabled"
        >
          Download all reports (zip file)
        </button>
      </div>
    </div>

    <div class="row h-mob-left">
      <div class="col-12 input-row text-right">
        <h6 class="h-h6 h-primary_shade_2">
          Downloading all reports mey take some time.
        </h6>
      </div>
    </div>
  </div>
</template>


<style lang="stylus" scoped>
.input-row {
  margin-top: 1em;
}
</style>


<script>
import moment from 'moment';
import settings from '@/settings.js';
import InputField from '@/components/InputField';

export default {
  data() {
    return {
      client: null,
      reportDateStart: moment().startOf('month').format('YYYY-MM'),
      reportDateEnd: moment().endOf('month').format('YYYY-MM'),
      skipEmptyReports: true,
    };
  },

  watch: {
    reportDateStart() {
      this.reportDateEnd = moment(this.reportDateStart)
        .endOf('month')
        .format('YYYY-MM');
    },
  },

  computed: {
    downloadEnabled() {
      return this.client && this.reportDateStart && this.reportDateEnd;
    },

    allDownloadEnabled() {
      return this.reportDateStart && this.reportDateEnd;
    },
  },

  methods: {
    async downloadPatientReport() {
      const reportDateStart = moment(this.reportDateStart)
        .startOf('month')
        .format('YYYY-MM-DD');
      const reportDateEnd = moment(this.reportDateEnd)
        .endOf('month')
        .format('YYYY-MM-DD');

      const url = `${settings.BACKEND_URL}/api/v-nurse/client/progress-report`
        + `?id=${this.client.id}&date-start=${reportDateStart}`
        + `&date-end=${reportDateEnd}`;
      this.$api.downloadFile(url);
    },

    async downloadAllReports() {
      const reportDateStart = moment(this.reportDateStart)
        .startOf('month')
        .format('YYYY-MM-DD');
      const reportDateEnd = moment(this.reportDateEnd)
        .endOf('month')
        .format('YYYY-MM-DD');

      const url = `${settings.BACKEND_URL}/api/v-nurse/all-clients/progress-report`;
      const payload = {
        'date-start': reportDateStart,
        'date-end': reportDateEnd,
        ext: 'zip',
        'skip-empty': this.skipEmptyReports,
      };
      this.$api.downloadFile(url, payload);
    },
  },

  mounted() {
    const $api = this.$api;
    $('#progressReport-searchClient').autoComplete({
      minLength: 2,
      events: {
        search(qry, callback, origJQElement) {
          $api.searchClient(qry).then((result) => {
            callback(result);
          });
        },
      },
    });
    $('#progressReport-searchClient').on('autocomplete.select', (evt, item) => {
      $('#progressReport-searchClient').removeClass('is-invalid');
      this.client = item;
    });
  },

  components: {
    InputField,
  },
};
</script>

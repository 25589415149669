<template>
  <div>
    <MealContainer
      class="h-bg-w"
      viewer="page"
      :recipeId="recipeId"
      @scrollTop="scrollTop"
    />
  </div>
</template>


<script>
import MealContainer from './components/MealContainer';

export default {
  data() {
    return {
      recipeId: parseInt(this.$route.params.recipeId, 10),
    };
  },

  methods: {
    scrollTop() {
      $(window).scrollTop(0);
    },
  },

  mounted() {
    window.execAfterInitMenuInterface(() => {
      window.initActiveMenu('left-navigation-link>hospital>dashboard', false);
    });
  },

  components: {
    MealContainer,
  },
};
</script>

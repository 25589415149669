<template>
  <div class="h1-table">
    <table class="table dataTable h-noborder-input h-input-w-100">
      <thead class="thead-light">
        <tr>
          <th width="5%" class="h-text-center">Weeks</th>
          <th width="5%" class="h-text-center">Days</th>
          <th width="5%" class="h-text-center">Hours</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>
            <div style="display: inherit">
              <input
                type="number"
                step="1"
                min="0"
                max="47"
                class="h-input"
                :value="timeParts.weeks"
                @change="
                  (e) => {
                    setWeeks(e.target.value);
                  }
                "
              />
              Weeks
            </div>
          </td>

          <td>
            <div style="display: inherit">
              <input
                type="number"
                step="1"
                min="0"
                max="6"
                class="h-input"
                :value="timeParts.days"
                @change="
                  (e) => {
                    setDays(e.target.value);
                  }
                "
              />
              Days
            </div>
          </td>

          <td>
            <div style="display: inherit">
              <input
                type="number"
                step="1"
                min="0"
                max="23"
                class="h-input"
                :value="timeParts.hours"
                @change="
                  (e) => {
                    setHours(e.target.value);
                  }
                "
              />
              Hours
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  name: 'TimePartsSingleEditor',
  props: ['timeParts'],

  methods: {
    setWeeks(value) {
      this.timeParts.setWeeks(value);
      this.$forceUpdate();
    },

    setDays(value) {
      this.timeParts.setDays(value);
      this.$forceUpdate();
    },

    setHours(value) {
      this.timeParts.setHours(value);
      this.$forceUpdate();
    },
  },
};
</script>

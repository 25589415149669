
  <!-- <SlottedMessage
    ref="slottedMessage"
    title="Clear history and enable resend"
    :closeDisabled="loading"
  > -->
    <template v-slot:modal-body>
  <Drawer :direction="'right'" :exist="true" ref="modalDialog">
    <div class="row h-100 h-drawer-max79">
      <div class="col-lg-12">
        <div class="card h-100 h-drawer-card">
          <div
            class="card-header h-drawer-card-header h-padding-bottom-l"
            style="background-color: white"
          >
            <div class="row">
              <div class="col-lg-9 text-left">
                <h5 class="h-h3 h-primary_shade_1">
                  Clear history and enable resend
                </h5>
              </div>
              <div class="col-lg-3 text-right">
                <button
                  type="button"
                  class="close"
                  aria-hidden="true"
                  @click="close"
                  :disabled="loading"
                >
                  <!-- <font-awesome-icon
                    :icon="['fas', 'sign-out-alt']"
                    style="height: 1.5vw; width: 1.5vw; color: #c3c4cb"
                  /> -->
                  <img
                    src="../../../../public/assets/images/Collapse-drawer.svg"
                    alt=""
                  />
                </button>
              </div>
            </div>
          </div>
          <div v-if="!loading" class="card-body h-drawer-card-body">
            <div class="row">
              <div class="col-12">
                <div>
                  <p class="h-h4 h-primary_shade_1">
                    Do you really want to resend this notification again at a
                    prefered time of client?
                  </p>
                </div>
              </div>
            </div>
          </div>
          <Loader v-else />

          <div class="h-drawer-card-footer">
            <button
              type="button"
              class="h-btn h-btn-accent"
              @click="confirm"
              :disabled="loading"
            >
              Confirm
            </button>

            <button
              type="button"
              class="h-btn h-btn-dangerous h-margin-left-s"
              @click="close"
              :disabled="loading"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  </Drawer>
</template>
  <!-- </SlottedMessage> -->


<script>
import Loader from '@/components/Loader';

export default {
  data() {
    return {
      loading: false,
      thoughtOfTheDay: null,
    };
  },

  methods: {
    show(thoughtOfTheDay) {
      this.loading = false;
      this.thoughtOfTheDay = thoughtOfTheDay;
      // this.$refs.slottedMessage.show();
      this.$refs.modalDialog.open();
      this.$refs.modalDialog.active = true;
    },

    close(force) {
      // this.$refs.slottedMessage.close(force);
      this.$refs.modalDialog.close();
      this.$refs.modalDialog.active = false;
    },

    async confirm() {
      this.loading = true;
      const response = await this.$api.enableThoughtOfTheDayResend(
        this.thoughtOfTheDay.id,
        this.clientId,
      );
      if (response.thought_of_the_day_list !== undefined) {
        this.$emit('resendEnabled', response.thought_of_the_day_list);
      }
      this.close(true);
    },
  },

  components: {
    Loader,
  },

  props: {
    clientId: {
      type: Number,
      required: true,
    },
  },
};
</script>

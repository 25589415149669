<template>
  <div>
    <div class="h-bg-w h-padding-top-l h-padding-bottom-l h-margin-l">
      <div
        class="h-col-desktop-4 h-margin-bottom-l h-margin-left-l h-padding-top-l h-primary_shade_1"
      >
        <InputField
          v-if="hospital"
          type="select"
          class="h-input"
          v-model="hospitalId"
          :choices="hospitalChoices"
          dense
          :disabled="loading"
        />
      </div>

      <div v-if="!loading">
        <EducationList
          :collectionId="hospital.learn_module_collection_id"
          :readOnly="false"
          :isTemplateMode="true"
          :hospitalMode="true"
        />
      </div>

      <div v-else>
        <Loader />
      </div>
    </div>
  </div>
</template>

<script>
import settings from '@/settings.js';
import Loader from '@/components/Loader';
import EducationList from '@/components/education/education_list/EducationList';
import InputField from '@/components/InputField';

export default {
  data() {
    return {
      loading: true,

      hospitalId: null,
      hospital: null,
      hospitalsMap: {},
      hospitalChoices: [],
      timeout: null,
    };
  },

  watch: {
    hospitalId() {
      this.hospital = this.hospitalsMap[this.hospitalId];
      this.loading = true;
      this.timeout = setTimeout(() => {
        this.loading = false;
      }, 0);
    },
  },

  beforeDestroy() {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
  },

  async mounted() {
    if (!settings.hasRole('system_administrator') && !settings.hasRole('nurse_administrator')) {
      this.$router
        .push({
          name: 'index',
        })
        .catch((err) => {});
      return;
    }

    window.execAfterInitMenuInterface(() => {
      window.initActiveMenu('left-navigation-link>settings>learn-module');
    });

    const hospitals = await this.$api.getHospitalsList();
    this.hospitalChoices = hospitals
      .sort((a, b) => a.name.localeCompare(b.name))
      .map((hospital) => {
        this.hospitalsMap[hospital.id] = hospital;

        return {
          value: hospital.id,
          title: hospital.name,
        };
      });
    this.hospitalId = hospitals[0].id;
    this.onHospitalSwitch();
    this.loading = false;
  },

  components: {
    Loader,
    EducationList,
    InputField,
  },
};
</script>

<template>
  <div>
    <div class="h1-table">
      <table class="table dataTable h-noborder-input h-input-w-100">
        <thead class="thead-light">
          <tr>
            <th width="10%" class="h-text-center">Weeks</th>
            <th width="10%" class="h-text-center">Days</th>
            <th width="10%" class="h-text-center">Hours</th>
            <th width="1%" class="h-text-center">Action</th>
          </tr>
        </thead>
        <tbody v-if="timePartsList.length !== 0">
          <tr v-for="timeParts of timePartsList" :key="timeParts.id">
            <td>
              <div style="display: inline-flex; align-items: center">
                <input
                  type="number"
                  step="1"
                  min="0"
                  max="47"
                  class="h-input"
                  :value="timeParts.weeks"
                  @change="
                    (e) => {
                      setWeeks(timeParts, e.target.value);
                    }
                  "
                />
                Weeks
              </div>
            </td>

            <td>
              <div style="display: inline-flex; align-items: center">
                <input
                  type="number"
                  step="1"
                  min="0"
                  max="6"
                  class="h-input"
                  :value="timeParts.days"
                  @change="
                    (e) => {
                      setDays(timeParts, e.target.value);
                    }
                  "
                />
                Days
              </div>
            </td>

            <td>
              <div style="display: inline-flex; align-items: center">
                <input
                  type="number"
                  step="1"
                  min="0"
                  max="23"
                  class="h-input"
                  :value="timeParts.hours"
                  @change="
                    (e) => {
                      setHours(timeParts, e.target.value);
                    }
                  "
                />
                Hours
              </div>
            </td>

            <td class="h-text-center">
              <a
                href="javascript:void(0);"
                @click="
                  () => {
                    removeEntry(timeParts);
                  }
                "
              >
                <i
                  class="fas fa-trash-alt h-dangerous font-16"
                  title="Delete"
                ></i>
              </a>
            </td>
          </tr>
        </tbody>

        <tbody v-else>
          <tr>
            <td colspan="4" class="text-center">&lt;NO_ENTRIES&gt;</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="h-margin-top-s h-margin-right-l h-text-right">
      <button
        type="button"
        class="h-btn h-btn-accent h-btn-height20"
        @click="addEntry"
      >
        <i class="mdi mdi-plus-circle-outline mr-2"></i>Add entry
      </button>
    </div>
  </div>
</template>

<script>
import TimeParts from '@/components/time_parts/TimeParts';

export default {
  name: 'TimePartsMultiEditor',
  props: ['timePartsList'],

  methods: {
    setWeeks(timeParts, value) {
      timeParts.setWeeks(value);
    },

    setDays(timeParts, value) {
      timeParts.setDays(value);
    },

    setHours(timeParts, value) {
      timeParts.setHours(value);
    },

    addEntry() {
      this.timePartsList.push(new TimeParts());
    },

    removeEntry(timeParts) {
      this.timePartsList.splice(this.timePartsList.indexOf(timeParts), 1);
    },
  },
};
</script>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"h-bg-gray h-col-desktop-10 h-col-mobaile-12"},[(_vm.loadingOptions)?_c('div',{staticClass:"h-padding-left-l h-padding-right-l h-padding-bottom-l h-bg-w text-center text-muted",staticStyle:{"font-size":"1.5em"},attrs:{"id":"headerBox1"}},[_vm._v(" Please wait... ")]):_vm._e(),(!_vm.loadingOptions)?_c('div',{staticClass:"h-padding-left-l h-bg-w",attrs:{"id":"headerBox"}},[_c('div',{staticClass:"h-row"},[_c('div',{staticClass:"h-col h-col-mobile-12 h-mobile-margin-top-l\n        h-mobile-margin-left-0 h-border-right-0"},[_c('label',{staticClass:"h-label"},[_vm._v("Date start: ")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.dateStart),expression:"dateStart"}],staticClass:"h-input",attrs:{"type":"date"},domProps:{"value":(_vm.dateStart)},on:{"input":function($event){if($event.target.composing){ return; }_vm.dateStart=$event.target.value}}})]),_c('div',{staticClass:"h-col h-col-mobile-12 h-mobile-margin-top-l h-mobile-margin-left-0 h-border-left-0"},[_c('label',{staticClass:"h-label"},[_vm._v("Date end:")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.dateEnd),expression:"dateEnd"}],staticClass:"h-input",attrs:{"type":"date"},domProps:{"value":(_vm.dateEnd)},on:{"input":function($event){if($event.target.composing){ return; }_vm.dateEnd=$event.target.value}}})]),_c('div',{staticClass:"h-col-desktop-2 h-col-mobile-12 h-mobile-margin-top-l\n        h-mobile-margin-left-0 h-padding-left-l h-margin-left-l"},[_c('label',{staticClass:"h-label"},[_vm._v("Hospital:")]),_c('InputField',{attrs:{"name":"hospitalSelect","type":"select","choices":_vm.hospitalChoices,"error":_vm.errors.hospital_id},on:{"input":function () {
              _vm.errors.hospital_id = null
            }},model:{value:(_vm.hospitalId),callback:function ($$v) {_vm.hospitalId=$$v},expression:"hospitalId"}})],1),_c('div',{staticClass:"h-col-desktop-2 h-col-mobile-12 h-mobile-margin-top-l\n        h-mobile-margin-left-0 h-padding-left-l h-margin-left-l"},[_c('label',{staticClass:"h-label"},[_vm._v("Physician:")]),_c('InputField',{attrs:{"type":"select","choices":_vm.surgeonOptions,"error":_vm.errors.surgeon_id},on:{"input":function () {
              _vm.errors.surgeon_id = null
            }},model:{value:(_vm.surgeonId),callback:function ($$v) {_vm.surgeonId=$$v},expression:"surgeonId"}})],1),_c('div',{staticClass:"h-col-desktop-2 h-col-mobile-12 h-mobile-margin-top-l\n        h-mobile-margin-left-0 h-padding-left-l h-margin-left-l"},[_c('label',{staticClass:"h-label"},[_vm._v("Nurse:")]),_c('InputField',{attrs:{"type":"select","choices":_vm.nurseOptions,"error":_vm.errors.nurse_id},on:{"input":function () {
              _vm.errors.nurse_id = null
            }},model:{value:(_vm.nurseId),callback:function ($$v) {_vm.nurseId=$$v},expression:"nurseId"}})],1),_c('div',{staticClass:"h-col-desktop-2 h-col-mobile-12 h-mobile-margin-top-l\n        h-mobile-margin-left-0 h-margin-left-l h-mobile-margin-left-0",staticStyle:{"display":"flex","flex-direction":"row","margin-top":"15rem","align-items":"center","justify-content":"space-between"}},[_c('InputField',{attrs:{"type":"checkbox","id":"StatisticReport:aggregate","label":"Aggregate"},model:{value:(_vm.aggregate),callback:function ($$v) {_vm.aggregate=$$v},expression:"aggregate"}}),_c('div',{staticClass:"h-icon-tooltip"},[_c('img',{staticClass:"h-report-icon h-padding-left-s",class:{ 'h-disabled': !_vm.hospitalId && false },attrs:{"src":require("../../../../../../public/assets/images/Down report.svg")},on:{"click":_vm.reload}}),_c('label',{staticClass:"h-margin-top-l"},[_vm._v(" Load report ")])])],1)])]):_vm._e(),_c('SelectorDialogInDrawer',{ref:"toggleColumnsDialog",attrs:{"allOptions":_vm.allTableColumns,"selectedOptions":_vm.visibleTableColumns,"getOptionKey":function (option) {
        return option.name
      },"getOptionText":function (option) {
        return option.text
      },"windowTitle":"Toggle table columns","listTitle":"Table table columns to show"},on:{"toggleOption":_vm.toggleColumnVisible}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <i
    :class="iconClass"
    :data-title="title"
    ref="icon"
  ></i>
</template>


<script>
export default {
  name: 'IconTooltip',

  props: {
    title: {
      type: String,
      required: true,
    },
    iconClass: {
      type: String,
      required: true,
    },
  },

  mounted() {
    $(this.$refs.icon).tooltip();
  },
};
</script>

<template>
  <div>
    <div v-if="!loading">
      <h4 class="text-center pt-4">Client engagement report</h4>

      <table
        class="table"
        ref="table"
        :class="{ 'table-hover': report && report.client_stats.length !== 0 }"
      >
        <thead class="thead-light">
          <tr>
            <th>Client name</th>
            <th>Confirmed at</th>
            <th>Last activity</th>
            <th>Active days</th>
            <th
              v-for="itemType of report.checklist_item_types"
              :key="itemType.title"
            >
              {{ itemType.title }}
            </th>
          </tr>
        </thead>

        <tbody v-if="report && report.client_stats.length !== 0">
          <tr
            v-for="client_stats of report.client_stats"
            :key="client_stats.client.id"
          >
            <td>
              <router-link
                :to="{
                  name: 'PatientProfile',
                  params: { id: client_stats.client.id },
                }"
              >
                {{ client_stats.client.name || "-" }}
                <br />
                ({{ client_stats.client.email || "-" }})
              </router-link>
            </td>
            <td>
              {{ $dateUtils.toLocalDate(client_stats.client.confirmed_at) }}
            </td>
            <td>
              {{
                $dateUtils.toLocalDate(client_stats.client.last_login_activity)
              }}
            </td>
            <td>
              {{
                client_stats.client.active_days !== null
                  ? client_stats.client.active_days
                  : "-"
              }}
            </td>
            <td
              v-for="itemType of report.checklist_item_types"
              :key="itemType.title"
            >
              {{ getClientStat(client_stats, itemType) }}
            </td>
          </tr>
        </tbody>

        <tbody v-else>
          <tr>
            <td class="text-center" colspan="1000">No entries found</td>
          </tr>
        </tbody>
      </table>
    </div>

    <div v-else class="globalTopMargin">
      <Loader />
    </div>
  </div>
</template>


<style lang="stylus" scoped>
.globalTopMargin {
  margin-top: 2.5vw;
}

@media print {
  .globalTopMargin {
    margin-top: 0 !important;
  }
}

table, hr {
  margin: 0;
  margin-left: auto;
  margin-right: auto;
}

.print-button {
  width: 10.417vw;
}
</style>


<script>
import Loader from '@/components/Loader';

export default {
  data() {
    return {
      loading: true,
      report: null,
      timeout: null,
    };
  },

  methods: {
    getClientStat(clientStats, itemType) {
      const data = clientStats.checklist_items.find((item) => {
        return item.titile === itemType.title;
      });
      if (!data) return '-';
      return this.$dateUtils.toLocalDate(data.last_record_at);
    },
  },

  async mounted() {
    this.report = await this.$api.engagementReport();
    this.loading = false;
    this.timeout = setTimeout(() => {
      $(this.$refs.table).DataTable();
    }, 0);
  },

  beforeDestroy() {
    if (this.timeout) clearTimeout(this.timeout);
  },

  components: {
    Loader,
  },
};
</script>

<template>
  <div>
    <button
      v-for="option of options"
      :key="option.text"
      class="h-btn-bordered h-margin-right-s h-btn-height20"
      :class="{
        'h-btn-fill-accent': option.enabled === true,
        'h-btn-bordered ': option.enabled === false,
        'h-btn-bordered ': option.enabled === null,
      }"
      :title="option.hint"
      @click="$emit('click', option)"
    >
      {{ option.text }}
    </button>
  </div>
</template>


<script>
export default {
  props: ['options'],
};
</script>

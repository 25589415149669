<template>
  <div>
    <div class="h-align-items-center h-space-between">
      <div>
        <h5 class="h-h3 h-primary_shade_1">Enabled hospitals</h5>
      </div>
    </div>

    <div class="h1-table">
      <table class="table dataTable h-noborder-input" style="display: table">
        <thead class="thead-light">
          <tr>
            <th>Hospital</th>
            <th>Roles</th>
            <th>Allow patients</th>
          </tr>
        </thead>

        <tbody v-if="allHospitals.length !== 0">
          <tr v-for="hospital of allHospitals" :key="hospital.id">
            <td style="vertical-align: top; padding: 10px !important; width: 34%">
              <InputField
                type="toggle"
                :value="isHospitalEnabled(hospital)"
                :label="hospital.name"
                :id="'HospitalsAndRoles::isHospitalEnabled::' + hospital.id"
                @input="value => setHospitalEnabled(hospital.id, value)"
                :disabled="readonly"
              />
            </td>
            <td style="vertical-align: top; padding: 10px !important; width: 33%">
              <EnabledRolesEditor
                v-if="isHospitalEnabled(hospital)"
                :roles="!readonly ? null : getHospitalRoles(hospital.id)"
                :enabledRoles="getHospitalRoles(hospital.id)"
                :displayRoles="roles"
                :readonly="readonly"
                :administratorRoleReadOnly="
                  member.id === settings.currentSession.user.id &&
                    !settings.hasRole('system_administrator')
                "
                :systemAdministratorRoleReadOnly="member.id === settings.currentSession.user.id"
              />
            </td>
            <td style="vertical-align: top; padding: 10px !important; width: 33%">
              <div v-if="isHospitalEnabled(hospital)">
                <div class="h-margin-bottom-s">
                  <InputField
                    type="toggle"
                    :value="isPatientsAllowed(hospital.id)"
                    :id="'HospitalsAndRoles::isPatientsAllowed::' + hospital.id"
                    label="Allow patients"
                    @input="value => setPatientsAllowed(hospital.id, value)"
                    :disabled="readonly"
                  />
                  <small style="margin-left: 4em" class="text-muted">
                    <strong>Hint:</strong> only applicable to nurse role
                  </small>
                </div>

                <div class="h-margin-bottom-s">
                  <InputField
                    type="toggle"
                    :value="isRpmAllowed(hospital.id)"
                    :id="'HospitalsAndRoles::isRpmAllowed::' + hospital.id"
                    label="RPM enabled"
                    @input="value => setRpmAllowed(hospital.id, value)"
                    :disabled="readonly"
                  />
                </div>

                <div class="h-margin-bottom-s">
                  <InputField
                    type="toggle"
                    :value="isRtmAllowed(hospital.id)"
                    :id="'HospitalsAndRoles::isRtmAllowed::' + hospital.id"
                    label="RTM enabled"
                    @input="value => setRtmAllowed(hospital.id, value)"
                    :disabled="readonly"
                  />
                </div>
              </div>
            </td>
          </tr>
        </tbody>

        <tbody v-else>
          <tr>
            <td colspan="1000" class="text-center" style="vertical-align: inherit">No hospitals</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import settings from '@/settings.js';
import InputField from '@/components/InputField';
import EnabledRolesEditor from './EnabledRolesEditor';

export default {
  props: ['allHospitals', 'readonly', 'member'],

  data() {
    return {
      settings,
      roles: [],
    };
  },

  methods: {
    async getRolesList() {
      try {
        this.roles = await this.$api.getRolesList();
      } catch {
        this.roles = [];
      }
    },

    isHospitalEnabled(hospital) {
      return this.member.hospitals.includes(hospital.id);
    },
    setHospitalEnabled(hospitalId, enabled) {
      const index = this.member.hospitals.indexOf(hospitalId);
      if (!enabled) {
        if (index !== -1) {
          this.member.hospitals.splice(index, 1);
          const hospitalDataIndex = this.member.hospitals_data.findIndex(
            h => h.hospital && h.hospital.id === hospitalId,
          );
          if (hospitalDataIndex !== -1) {
            this.member.hospitals_data.splice(hospitalDataIndex, 1);
          }
          const patientIndex = this.member.allow_patient_hospitals.indexOf(hospitalId);
          if (patientIndex !== -1) {
            this.member.allow_patient_hospitals.splice(patientIndex, 1);
          }
          const rpmIndex = this.member.allow_rpm_hospitals.indexOf(hospitalId);
          if (rpmIndex !== -1) {
            this.member.allow_rpm_hospitals.splice(rpmIndex, 1);
          }
          const rtmIndex = this.member.allow_rtm_hospitals.indexOf(hospitalId);
          if (rtmIndex !== -1) {
            this.member.allow_rtm_hospitals.splice(rtmIndex, 1);
          }
        }
      } else if (index === -1) {
        this.member.hospitals.push(hospitalId);
        const hospitalDataExists = this.member.hospitals_data.some(
          h => h.hospital && h.hospital.id === hospitalId,
        );
        if (!hospitalDataExists) {
          this.member.hospitals_data.push({
            hospital: { id: hospitalId },
            roles: [],
          });
        }
      }
      this.$emit('input');
      this.$emit('hospitalEnabledChanged', { hospitalId, enabled });
    },

    getHospitalRoles(hospitalId) {
      let hospitalData = this.member.hospitals_data.find(h => h.hospital.id === hospitalId);
      if (!hospitalData) {
        hospitalData = {
          hospital: {
            id: hospitalId,
          },
          roles: [],
        };
        this.member.hospitals_data.push(hospitalData);
      }
      return hospitalData.roles;
    },

    isPatientsAllowed(hospitalId) {
      return this.member.allow_patient_hospitals.indexOf(hospitalId) !== -1;
    },

    isRpmAllowed(hospitalId) {
      return this.member.allow_rpm_hospitals.indexOf(hospitalId) !== -1;
    },

    isRtmAllowed(hospitalId) {
      return this.member.allow_rtm_hospitals.indexOf(hospitalId) !== -1;
    },

    setPatientsAllowed(hospitalId, allowed) {
      const index = this.member.allow_patient_hospitals.indexOf(hospitalId);
      if (allowed && index === -1) {
        this.member.allow_patient_hospitals.push(hospitalId);
      } else {
        this.member.allow_patient_hospitals.splice(index, 1);
      }
    },

    setRpmAllowed(hospitalId, allowed) {
      const index = this.member.allow_rpm_hospitals.indexOf(hospitalId);
      if (allowed && index === -1) {
        this.member.allow_rpm_hospitals.push(hospitalId);
      } else {
        this.member.allow_rpm_hospitals.splice(index, 1);
      }
    },

    setRtmAllowed(hospitalId, allowed) {
      const index = this.member.allow_rtm_hospitals.indexOf(hospitalId);
      if (allowed && index === -1) {
        this.member.allow_rtm_hospitals.push(hospitalId);
      } else {
        this.member.allow_rtm_hospitals.splice(index, 1);
      }
    },
  },

  mounted() {
    this.allHospitals.sort((a, b) => a.name.localeCompare(b.name));

    this.getRolesList();
  },

  components: {
    InputField,
    EnabledRolesEditor,
  },
};
</script>

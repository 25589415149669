<template>
  <div class="modal fade"
    role="dialog"
    aria-hidden="true"
    ref="modalDialog"
  >

    <div :class="`modal-dialog modal-${modalSize}`">
      <div class="modal-content">

        <div class="modal-header">
          <h5 class="modal-title mt-0">
            <slot name="modal-title">{{ title }}</slot>
          </h5>
          <button
            type="button"
            class="close"
            @click="close"
          >×</button>
        </div>

        <div class="modal-body" :style="{
          'background-color': bodyBackgroundColor,
        }">
          <slot name="modal-body"></slot>
        </div>

        <div class="modal-footer">
          <slot name="modal-footer">
            <button
              type="button"
              class="btn btn-primary waves-effect"
              @click="close"
              :closeDisabled="closeDisabled"
            >
              Close
            </button>
          </slot>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    modalSize: {
      type: String,
      default: 'lg',
    },
    title: {
      type: String,
      default: 'Message',
    },
    closeDisabled: {
      type: Boolean,
      default: false,
    },
    showOnMount: {
      type: Boolean,
      default: false,
    },
    bodyBackgroundColor: {
      type: String,
      default: null,
    },
  },

  methods: {
    show() {
      $(this.$refs.modalDialog.el).modal({
        backdrop: 'static',
        keyboard: false,
      });
      $('.modal-backdrop').last().insertBefore(this.$refs.modalDialog.el);
    },

    close(force) {
      if (this.closeDisabled && (force !== true)) {
        return;
      }
      $(this.$refs.modalDialog.el).modal('hide');
    },

    scrollTop(top) {
      return $(this.$refs.modalDialog.el).scrollTop(top);
    },
  },

  mounted() {
    $(this.$refs.modalDialog.el).appendTo('body');
    $(this.$refs.modalDialog.el).on('hidden.bs.modal', () => {
      if ($('[role=dialog].show').length !== 0) {
        $('body').addClass('modal-open');
      }
      this.$emit('closed');
    });
    if (this.showOnMount) {
      this.show();
    }
  },

  beforeDestroy() {
    $(this.$refs.modalDialog.el).remove();
  },
};
</script>

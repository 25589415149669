<template>
  <div>
    <!-- Page-Title -->
    <div class="row">
      <div class="col-sm-12">
        <div class="page-title-box">
          <div class="float-right">
            <ol class="breadcrumb">
              <li class="breadcrumb-item"><a href="javascript:void(0);">User</a></li>
              <li class="breadcrumb-item"><a href="javascript:void(0);">Profile</a></li>
              <li class="breadcrumb-item active">Change password</li>
            </ol>
          </div>
          <h4 class="page-title">Change password</h4>
        </div><!--end page-title-box-->
      </div><!--end col-->
    </div>
    <!-- end page title end breadcrumb -->

    <div class="row">
      <div class="col-lg-8 mx-auto">
        <div class="card dr-pro-pic">
          <div class="card-body">

            <h4 class="text-center mb-3">Change password</h4>

            <form v-if="!loading" class="form-horizontal form-material mb-0">
              <div class="form-group row">
                <div class="col-md-12">
                  <label class="w-100">
                    Current password:
                    <InputField
                      type="password"
                      v-model="currentPassword"
                      :error="errors.currentPassword"
                      @input="() => { errors.currentPassword=null }"
                    />
                  </label>
                </div>
              </div>

              <div class="form-group row">
                <div class="col-md-12">
                  <label class="w-100">
                    New password:
                    <InputField
                      type="password"
                      v-model="newPassword"
                      :error="errors.newPassword"
                      @input="() => { errors.newPassword=null }"
                    />
                  </label>
                </div>
              </div>

              <div class="form-group row">
                <div class="col-md-12">
                  <label class="w-100">
                    New password repeat:
                    <InputField
                      type="password"
                      v-model="newPasswordRepeat"
                      :error="errors.newPasswordRepeat"
                      @input="() => { errors.newPasswordRepeat=null }"
                    />
                  </label>
                </div>
              </div>

              <div class="text-right">
                <button type="button"
                  class="btn btn-primary waves-effect mr-2"
                  @click="confirm"
                >
                  Confirm
                </button>

                <button
                  type="button"
                  class="btn btn-danger waves-effect"
                  @click="gotToProfilePage"
                >
                  Cancel
                </button>
              </div>

            </form>

            <Loader v-else />

          </div>
        </div>
      </div> <!--end col-->
    </div><!--end row-->
  </div>
</template>


<script>
import settings from '@/settings.js';
import InputField from '@/components/InputField';
import Loader from '@/components/Loader';

export default {
  data() {
    return {
      loading: false,
      newPassword: '',
      newPasswordRepeat: '',
      currentPassword: '',
      errors: {},
    };
  },

  methods: {
    async confirm() {
      if (this.$api.authInfo?.IdToken) {
        return this.confirmCognito();
      }
      return this.confirmFlask();
    },

    async confirmCognito() {
      this.loading = true;
      this.errors = {};

      if (this.newPassword !== this.newPasswordRepeat) {
        this.errors.newPasswordRepeat = 'Passwords do not match.';
        this.loading = false;
        return;
      }

      let response = null;
      try {
        response = await this.$api.post(
          `${settings.COGNITO_GATEWAY_URL}/serverless/change_password`,
          null,
          {
            proposed_password: this.newPassword,
            previous_password: this.currentPassword,
            access_token: this.$api.authInfo.AccessToken,
          },
        );
      } catch (e) {
        response = e;
      }

      if (response.message === 'password changed') {
        $.notify(
          'Password changed.',
          { position: 'top center', className: 'success' },
        );
        this.gotToProfilePage();
      } else {
        $.notify(
          response.data?.message ?? response.message ?? 'Unknown error.',
          { position: 'top center', className: 'error' },
        );
      }
      this.loading = false;
    },

    async confirmFlask() {
      this.loading = true;
      this.errors = {};
      const response = await this.$api.post(
        `${settings.BACKEND_URL}/api/v-nurse/change-password`,
        null,
        {
          newPassword: this.newPassword,
          newPasswordRepeat: this.newPasswordRepeat,
          currentPassword: this.currentPassword,
        },
      );
      if (response.status === 'error') {
        this.errors = response.errors;
      } else if (response.status === 'ok') {
        $.notify(
          'Password changed.',
          { position: 'top center', className: 'success' },
        );
        this.gotToProfilePage();
      } else {
        $.notify(
          'Unknown error.',
          { position: 'top center', className: 'error' },
        );
      }
      this.loading = false;
    },

    gotToProfilePage() {
      this.$router.push({
        name: 'Profile',
      }).catch((e) => {
        // pass
      });
    },
  },

  mounted() {
    window.execAfterInitMenuInterface(() => {
      window.initActiveMenu('left-navigation-link>user>profile', false);
    });
  },

  components: {
    InputField,
    Loader,
  },
};
</script>

<template>
  <div class="h-drawer-max80 h-no-padding">
    <SlottedMessageInDrawer ref="slottedMessage" title="Therapy PT">
      <template v-slot:modal-header>
        <div class="h-drawer-card-header">
          <div class="h-main-drawer-card-header align-items-center h-margin-bottom-l">
            <h3 class="h-h3 h-primary_shade_1">
              <slot name="modal-title">Therapy PT</slot>
            </h3>
            <div class="h-align-items-center">
              <button type="button" class="h-btn h-btn-accent" @click="addEntry">
                <div class="h-align-items-center">
                  <i class=" h-secondary_shade_5 healenticons-plus h-padding-right-s"></i>Add
                  therapy PT entry
                </div>
              </button>

              <button
                type="button"
                class="close h-margin-left-m"
                aria-hidden="true"
                @click="close"
                :disabled="loading"
              >
                <img src="../../../public/assets/images/Collapse-drawer.svg" alt="" />
              </button>
            </div>
          </div>
        </div>
      </template>

      <template v-slot:modal-body>
        <div v-if="!loading" class="h-margin-left-l h-margin-right-l">
          <TherapyPtEditEntriesTable
            ref="therapyPtEntries"
            :clientId="clientId"
            :careProtocolId="careProtocolId"
            :careProtocolMode="careProtocolMode"
            :initialEntries="entries"
            :simple="false"
            :dropDownOverflow="true"
            :allowExpand="true"
            @entriesSaved="entriesSaved"
          />
        </div>
        <Loader v-if="loading" />
      </template>

      <template v-slot:modal-footer>
        <button type="button" class="h-btn h-btn-accent" @click="save" :disabled="loading">
          Save
        </button>
        <button
          type="button"
          class="h-btn h-btn-dangerous h-margin-left-s"
          @click="close"
          :disabled="loading"
        >
          Cancel
        </button>
      </template>
    </SlottedMessageInDrawer>
  </div>
</template>
<style lang="stylus" scoped>
.modal-dialog {
  max-width: 1200px;
}
</style>
<script>
import Loader from '@/components/Loader';
import TherapyPtEditEntriesTable from './edit_entries_table/TherapyPtEditEntriesTable';
import SlottedMessageInDrawer from '@/components/dialogs/SlottedMessageInDrawer';

export default {
  props: ['clientId', 'careProtocolId', 'careProtocolMode'],
  data() {
    return {
      loading: true,
      entries: [],
    };
  },
  computed: {
    myStyles() {
      return {
        height: `${this.height}px`,
      };
    },
  },
  methods: {
    addEntry() {
      this.$refs.therapyPtEntries.addEntry();
    },
    async save() {
      this.loading = true;
      await this.$refs.therapyPtEntries.save();
      this.close(true);
    },
    show(entries) {
      this.loading = false;
      this.$refs.slottedMessage.show();
      this.entries = JSON.parse(JSON.stringify(entries));
    },
    close(force) {
      this.$refs.slottedMessage.close();
      this.$refs.slottedMessage.active = false;
    },
    entriesSaved(entriesToSave, entriesReturned) {
      this.$emit('entriesSaved', entriesToSave, entriesReturned);
    },
  },
  components: {
    Loader,
    TherapyPtEditEntriesTable,
    SlottedMessageInDrawer,
  },
};
</script>

<template>
  <div class="modal fade globalWait"
    role="dialog"
    aria-hidden="true"
    ref="modalDialog"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title mt-0">{{ title }}</h5>
        </div>
        <div class="modal-body pb-4">

          <Loader :message="message" />

        </div>
      </div><!-- /.modal-content -->
    </div><!-- /.modal-dialog -->
  </div><!-- /.modal -->
</template>


<style lang="stylus" scoped>
.modal-body
  background-color white
</style>


<script>
import Loader from '@/components/Loader';

export default {
  props: {
    title: {
      type: String,
      default: 'Waiting...',
    },
    message: {
      type: String,
      default: 'Please wait...',
    },
  },

  data() {
    return {
      timeout: null,
      timeout2: null,
    };
  },

  methods: {
    start() {
      $(this.$refs.modalDialog.el).modal({
        backdrop: 'static',
        keyboard: false,
      });
      $('.modal-backdrop').last().insertBefore(this.$refs.modalDialog.el);
    },

    stop(callback) {
      // when hiding immediately after opening, it's not working
      this.timeout = setTimeout(() => {
        $(this.$refs.modalDialog.el).modal('hide');
        if (callback) {
          this.timeout2 = setTimeout(callback, 500);
        }
      }, 500);
    },
  },

  mounted() {
    $(this.$refs.modalDialog.el).appendTo('body');
    $(this.$refs.modalDialog.el).on('hidden.bs.modal', () => {
      if ($('[role=dialog].show').length !== 0) {
        $('body').addClass('modal-open');
      }
      this.$emit('closed');
    });
  },

  beforeDestroy() {
    $(this.$refs.modalDialog.el).remove();
    if (this.timeout) clearTimeout(this.timeout);
    if (this.timeout2) clearTimeout(this.timeout2);
  },

  components: {
    Loader,
  },
};
</script>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"\n      h-space-between h-row h-padding-top-l h-padding-left-l h-padding-right-l\n    "},[_c('div',[_c('h3',{staticClass:"h-h3 h-primary_shade_1 h-padding-bottom-s d-flex",attrs:{"id":"MealTitle"}},[_vm._v(" "+_vm._s(_vm.recipe.title)+" ")]),_c('ul',{staticClass:"list-unstyled h-general-info recipe-info"},[_c('li',{staticClass:"col-3"},[_c('img',{staticClass:"mr-2",attrs:{"src":"/kitchry_assets/images/icons/globe.svg","alt":"Meal photo","width":"25","height":"25"}}),_vm._v(" "+_vm._s(_vm.recipe.cuisine)+" ")]),_c('li',{staticClass:"col-3"},[_c('img',{staticClass:"mr-2",attrs:{"src":"/kitchry_assets/images/icons/people.svg","alt":"Servings","width":"25","height":"25"}}),_vm._v(" "+_vm._s(_vm.recipe.servings)+" servings ")]),_c('li',{staticClass:"col-3"},[_c('img',{staticClass:"mr-2",attrs:{"src":"/kitchry_assets/images/icons/fork.svg","alt":"Meal photo","width":"25","height":"25"}}),_vm._v(" "+_vm._s(_vm.recipe.kcal.toFixed(1))+" kilocalories ")]),_c('li',{staticClass:"col-3"},[_c('img',{staticClass:"mr-2",attrs:{"src":"/kitchry_assets/images/icons/clock.svg","alt":"Meal photo","width":"25","height":"25"}}),_vm._v(" "+_vm._s(_vm.recipe.cook_time)+" ")])])]),_c('img',{staticClass:"text-right d-block recipeView h-mob-top-l",attrs:{"src":_vm.recipe.image,"alt":"Meal photo"}})]),_c('hr',{staticClass:"h-hr h-margin-l"}),_c('div',{staticClass:"h-row h-padding-left-l h-padding-right-l"},[_c('div',{class:{
        'col-9 h-col-12': _vm.viewer === 'page',
        'col-8 h-col-12': _vm.viewer === 'dialog',
      }},[(_vm.viewer === 'page')?_c('div',[_c('h3',{staticClass:"text-center h-h3 h-primary_shade_1"},[_vm._v("Recipe")])]):_vm._e(),_c('MealMainInfo',{class:{
          'h-padding-right-m': _vm.viewer === 'dialog',
        },attrs:{"recipe":_vm.recipe,"ingredients":_vm.ingredients}})],1),_c('div',{class:{
        'col-3 h-col-12 h-mob-top-l': _vm.viewer === 'page',
        'col-4 h-col-12 h-mob-top-l': _vm.viewer === 'dialog',
      }},[_vm._m(0),_c('MealNutritionFacts',{attrs:{"recipe":_vm.recipe}})],1)])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h3',{staticClass:"text-center h-h3 h-primary_shade_1 h-padding-bottom-s"},[_vm._v(" Nutrition Facts ")])])}]

export { render, staticRenderFns }
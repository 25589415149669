<template>
  <div class="h-bg-gray">
    <div
      v-if="loadingOptions"
      id="headerBox1"
      class="h-padding-left-l h-padding-right-l h-padding-bottom-l h-bg-w text-center text-muted"
      style="font-size: 1.5em"
    >
      Please wait...
    </div>

    <div
      v-if="!loadingOptions"
      id="headerBox2"
      class="h-bg-w h-margin-top-l h-padding-top-l h-padding-left-l
      h-padding-right-l h-padding-bottom-l"
    >
      <div class="h-row h-padding-bottom-l">
        <div
          class="h-col-desktop-3 h-col-mobile-12 h-mobile-margin-top-l h-mobile-margin-left-0"
        >
          <label class="h-label">Hospital:</label>
          <InputField
            name="hospitalSelect"
            type="select"
            v-model="hospitalId"
            :choices="hospitalChoices"
          />
        </div>

        <div
          class="h-col-desktop-3 h-col-mobile-12 h-mobile-margin-top-l
          h-mobile-margin-left-0 h-padding-left-l h-margin-left-l"
        >
          <label class="h-label">Physician:</label>
          <InputField
            type="select"
            :choices="surgeonOptions"
            v-model="surgeonId"
          />
        </div>

        <div
          class="h-col-desktop-3 h-col-mobile-12 h-mobile-margin-top-l
          h-mobile-margin-left-0 h-padding-left-l h-margin-left-l"
        >
          <label class="h-label">Nurse:</label>
          <InputField
            type="select"
            :choices="nurseOptions"
            v-model="nurseId"
          />
        </div>
      </div>

      <div
        class="h-col h-text-right h-mobile-margin-top-l h-mobile-margin-left-0 h-mobile-text-center"
      >
        <button
          type="button"
          class="h-btn h-btn-accent"
          @click="reload"
          :disabled="!hospitalId && false"
        >
          Load widget data
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import InputField from '@/components/InputField';

export default {
  data() {
    return {
      loadingOptions: true,
      hospitals: [],
      hospitalId: null,
      surgeons: [],
      surgeonId: null,
      nurses: [],
      nurseId: null,
    };
  },

  computed: {
    hospitalChoices() {
      const choices = this.hospitals.map((hospital) => {
        return [hospital.id, hospital.name];
      });
      return choices;
    },

    surgeonOptions() {
      const choices = this.surgeons
        .map((surgeon) => {
          if (
            this.hospitalId && (this.hospitalId !== 'true')
            && surgeon.hospital.id !== parseInt(this.hospitalId, 10)
          ) {
            return null;
          }
          return [surgeon.id, surgeon.name];
        })
        .filter(entry => entry !== null);
      choices.unshift(['true', '[ALL]']);
      choices.unshift([null, '--- select provider ---']);

      return choices;
    },

    nurseOptions() {
      const choices = this.nurses
        .map((nurse) => {
          if (this.hospitalId && (this.hospitalId !== 'true')) {
            const foundHospital = nurse.hospitals.find(
              hospital => hospital.id === parseInt(this.hospitalId, 10),
            );
            if (!foundHospital) {
              return null;
            }
          }
          return [nurse.id, nurse.name];
        })
        .filter(entry => entry !== null);
      choices.unshift(['true', '[ALL]']);
      choices.unshift([null, '--- select nurse ---']);

      return choices;
    },
  },

  watch: {
    hospitalId() {
      this.updateFilters('hospitalId');
    },

    nurseId() {
      this.updateFilters('nurseId');
    },

    surgeonId() {
      this.updateFilters('surgeonId');
    },
  },

  methods: {
    updateFilters(source) {
      if (source === 'hospitalId') {
        if (this.hospitalId && (this.hospitalId !== 'true')) {
          const nurse = this.nurses.find(n => n.id === parseInt(this.nurseId, 10));
          if (nurse) {
            const foundHospital = nurse.hospitals.find(
              hospital => hospital.id === parseInt(this.hospitalId, 10),
            );
            if (!foundHospital) {
              this.nurseId = null;
            }
          }

          const surgeon = this.surgeons.find(s => s.id === parseInt(this.surgeonId, 10));
          if (surgeon && surgeon.hospital.id !== parseInt(this.hospitalId, 10)) {
            this.surgeonId = null;
          }
        }
      } else if (source === 'nurseId' && this.nurseId !== null) {
        this.surgeonId = null;
      } else if (source === 'surgeonId' && this.surgeonId !== null) {
        this.nurseId = null;
      }
    },

    reload() {
      const data = {};
      data.hospital_id = this.hospitalId === 'true' ? true : this.hospitalId;
      data.provider_id = this.surgeonId === 'true' ? true : this.surgeonId;
      data.nurse_id = this.nurseId === 'true' ? true : this.nurseId;

      this.$emit('reload', data);
    },
  },

  async mounted() {
    const [hospitals, surgeons, nurses] = await Promise.all([
      this.$api.getHospitalsList(),
      this.$api.searchSeargeon(),
      this.$api.searchNurse(),
    ]);
    this.hospitals = hospitals;
    this.surgeons = surgeons;
    this.nurses = nurses;

    this.loadingOptions = false;
  },

  components: {
    InputField,
  },
};
</script>

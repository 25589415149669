<template>
  <div>
    <div v-if="careProtocolId && !readOnly" class="h-text-right h-padding-right-l">
      <button
        type="button"
        class="h-btn h-btn-safe"
        @click="
          () => {
            $refs.cloneItemsDialog.show(null)
          }
        "
      >
        Clone existing items
      </button>
      <button
        type="button"
        class="h-btn h-btn-accent h-margin-left-s"
        @click="
          () => {
            $refs.editThoughtDialog.show(null)
          }
        "
      >
        <i class="healenticons-plus h-padding-right-xs"></i>New item
      </button>
    </div>
    <div v-if="!loading" class="h1-table">
      <table class="table dataTable h-table-thought">
        <thead class="thead-light">
          <tr>
            <th width="5%" class="text-left" v-if="clientId">Enabled</th>
            <th width="5%" class="text-left">Start date</th>
            <th width="5%" class="text-left">Is after</th>
            <th width="5%" class="text-right">Days before/after</th>
            <th width="5%" class="text-right">Local reminder time</th>
            <th width="5%" class="text-left">Notification content</th>
            <th width="15%" class="text-right" v-if="clientId">Due date</th>
            <th width="15%" class="text-left" v-if="clientId">SMS sent</th>
            <th width="1%" class="text-left" v-if="!readOnly">Action</th>
          </tr>
        </thead>

        <tbody v-if="items.length !== 0">
          <tr v-for="item of items" :key="item.id">
            <td
              class="text-left"
              v-if="clientId"
              :class="{
                'text-danger': !item.notification.enabled
              }"
            >
              {{ item.notification.enabled }}
            </td>
            <td class="text-left">
              {{ item.start_date_type_display }}
            </td>
            <td class="text-left">
              {{ item.post_op ? 'Yes' : 'No' }}
            </td>
            <td class="text-right">
              {{ item.shift_in_days }}
            </td>
            <td class="text-right" data-tolltip="Default is user daily reminder time">
              {{ getItemRemindTime(item.remind_time) }}
            </td>
            <td>
              <TruncateText :data-tooltip="item.content" :text="item.content ? item.content : ''" />
            </td>
            <td class="text-right" v-if="clientId">
              {{ getItemDueDate(item.notification.due_date) }}
            </td>
            <td
              v-if="clientId"
              :class="{
                'text-success': item.notification.is_sent
              }"
            >
              {{ item.notification.is_sent }}
            </td>
            <!-- Care protocol actions -->
            <td v-if="careProtocolId && !readOnly" class="text-center">
              <div>
                <a
                  href="javascript:void(0);"
                  class="mr-2"
                  @click="
                    () => {
                      $refs.editThoughtDialog.show(item)
                    }
                  "
                >
                  <i class="fas fa-edit text-info font-16" title="Edit"></i>
                </a>

                <a
                  href="javascript:void(0);"
                  class="mr-2"
                  @click="
                    () => {
                      $refs.deleteThoughtDialog.show(item)
                    }
                  "
                >
                  <i class="fas fa-trash-alt text-danger font-16" title="Delete"></i>
                </a>
              </div>
            </td>
            <!-- Patient action -->
            <td v-if="clientId" class="text-left">
              <div>
                <a
                  href="javascript:void(0);"
                  class="mr-2"
                  @click="
                    () => {
                      $refs.repeatNotificationDialog.show(item)
                    }
                  "
                >
                  <i class="fas fa-redo-alt text-info font-16" title="Clear history"></i>
                </a>
              </div>
            </td>
          </tr>
        </tbody>

        <tbody v-else>
          <tr>
            <td class="text-center" colspan="100">
              No entries{{
                client
                  ? client.surgeon
                    ? ` added by surgeon ${client.surgeon.name}`
                    : `added by the surgeon`
                  : ''
              }}.
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <Loader v-else />

    <EditThoughtDialog
      v-if="careProtocolId"
      ref="editThoughtDialog"
      :careProtocolId="careProtocolId"
      :doctorId="doctorId"
      @saved="setItems"
    />
    <DeleteThoughtDialog
      v-if="careProtocolId"
      ref="deleteThoughtDialog"
      :careProtocolId="careProtocolId"
      :doctorId="doctorId"
      @deleted="setItems"
    />
    <CloneItemsDialog
      v-if="careProtocolId"
      ref="cloneItemsDialog"
      :intoCareProtocolId="careProtocolId"
      :doctorId="doctorId"
      @copied="setItems"
    />

    <RepeatNotificationDialog
      v-if="clientId"
      ref="repeatNotificationDialog"
      :clientId="clientId"
      @resendEnabled="setItems"
    />
  </div>
</template>

<script>
import moment from 'moment-timezone';
import Loader from '@/components/Loader';
import EditThoughtDialog from './components/EditThoughtDialog';
import DeleteThoughtDialog from './components/DeleteThoughtDialog';
import RepeatNotificationDialog from './components/RepeatNotificationDialog';
import CloneItemsDialog from './components/CloneItemsDialog';
import TruncateText from '@/components/TruncateText';

export default {
  data() {
    return {
      loading: true,
      items: [],
    };
  },

  methods: {
    setItems(items) {
      this.items = items;
    },

    getItemRemindTime(time) {
      if (time === null || time === '') return 'Default';
      return moment(`2000-10-10 ${time}`).format('hh:mm A');
    },

    getItemDueDate(date) {
      if (!date) return '-';
      return date.split(' ')[0];
    },
  },

  async mounted() {
    if (this.careProtocolId) {
      const response = await this.$api.getThoughtsOfTheDay(this.careProtocolId, this.doctorId);
      this.setItems(response.thought_of_the_day_list);
    } else if (this.clientId) {
      const response = await this.$api.getClientThoughtsOfTheDay(this.clientId);
      this.setItems(response.thought_of_the_day_list);
    }
    this.loading = false;
  },

  components: {
    Loader,
    EditThoughtDialog,
    DeleteThoughtDialog,
    RepeatNotificationDialog,
    CloneItemsDialog,
    TruncateText,
  },

  props: {
    careProtocolId: {
      type: Number,
      required: false,
    },
    clientId: {
      type: Number,
      required: false,
    },
    client: {
      type: Object,
      required: false,
    },
    readOnly: {
      type: Boolean,
      required: false,
    },
    doctorId: {
      type: Number,
      required: false,
    },
  },
};
</script>

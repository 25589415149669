<template>
  <div>

    <WidgetFilters @reload="load" />

    <div v-if="widgets.length !== 0" class="h-row insights-loop gap-loop">
      <div
        v-for="widget of widgets"
        :key="widget.title"
        :class="getWidgetWrapperClass(widget)"
      >
        <UpcomingSurgeries
          v-if="widget.type === 'UpcomingSurgeries'"
          ref="upcomingSurgeries"
          class=""
          :dashinfo="dashinfo"
          :loaded="!!dashinfo"
        />
        <SimpleCardNumber
          v-if="widget.type === 'SimpleCardNumber'"
          :loaded="!!dashinfo"
          :iconClass="widget.iconClass"
        >
          <template v-slot:title>
            {{ widget.title
            }}<span
              v-if="widget.unit && widget.showUnitWithTitle"
              class="no-text-transform"
            >
              ({{ widget.unit }})</span
            >
            <i
              v-if="widget.getHelpDialogRef"
              class="fas fa-question-circle h-info-icon h-accent"
              @click="widget.getHelpDialogRef().show()"
              title="Click to see more details"
            ></i>
          </template>
          <template v-slot:content>
            <div
              class="h-margin-top-s"
            >
              <span class="h-h2 h-accent">{{ widget.getSummeryValue() }}</span>

              <small
                v-if="
                  widget.unit &&
                  widget.getSummeryValue() !== '?' &&
                  !widget.showUnitWithTitle
                "
              >
                {{ widget.unit }}
              </small>

              <div
                class="h-h4 h-primary_shade_3 h-margin-top-s"
              >
                <div
                  v-if="widget.getWeekTrend"
                  class="h-h4 h-secondary_shade_1 h-margin-left-xs"
                  style="font-weight: normal;"
                >
                  <small
                    :class="{
                      'h-safe': widget.getWeekTrend() > 0,
                      'h-dangerous': widget.getWeekTrend() < 0,
                    }"
                    v-if="widget.getWeekTrend() !== null"
                  >
                    <i
                      :class="{
                        'fas fa-long-arrow-alt-up': widget.getWeekTrend() > 0,
                        'fas fa-long-arrow-alt-down': widget.getWeekTrend() < 0,
                      }"
                    ></i>
                    Week trend: {{ widget.getWeekTrend() || "?" }}%
                  </small>

                  <span
                    title="Not enough data to calculate trending"
                    class="h-secondary_shade_1"
                    v-if="widget.getWeekTrend() === null"
                  >
                    <i class="fas fa-arrows-alt-v"></i>
                    Week trend: ?
                  </span>
                </div>

                <div
                  v-if="widget.getFullTrend"
                  class="h-h4 h-secondary_shade_1 h-margin-left-xs"
                  style="font-weight: normal;"
                >
                  <small
                    :class="{
                      'h-safe': widget.getFullTrend() > 0,
                      'h-dangerous': widget.getFullTrend() < 0,
                    }"
                    v-if="widget.getFullTrend() !== null"
                  >
                    <i
                      :class="{
                        'fas fa-long-arrow-alt-up': widget.getFullTrend() > 0,
                        'fas fa-long-arrow-alt-down': widget.getFullTrend() < 0,
                      }"
                    ></i>
                    Full trend: {{ widget.getFullTrend() || "?" }}%
                  </small>

                  <span
                    title="Not enough data to calculate trending"
                    class="h-secondary_shade_1"
                    v-if="widget.getFullTrend() === null"
                  >
                    <i class="fas fa-arrows-alt-v"></i>
                    Full trend: ?
                  </span>
                </div>
              </div>
            </div>
          </template>
        </SimpleCardNumber>
      </div>
    </div>

    <!-- WidgetsSelectorDialog -->
    <WidgetsSelectorDialog
      ref="widgetsSelectorDialog"
      :widgets="widgets"
      :allWidgets="allWidgets"
      @toggleSelectedWidget="toggleSelectedWidget"
    />

    <!--- Checklist Completion -->
    <SlottedMessageInDrawer
      ref="checklistCompletionDialog"
      title="Checklist Completion"
    >
      <template v-slot:modal-body>
        <div class="h-max-w-33">
          <div class="h-h3 h-primary_shade_1">
            To calculate checklist completion all active care protocols (care
            protocols currently being done by clients) are taken and items from
            the care protocols that should have been completed by now are used
            in a formular:
          </div>
          <div class="h-h3 h-primary_shade_1 h-margin-top-s">
            <i
              >Number of completed items / All items that should be completed</i
            >
          </div>
        </div>
      </template>
    </SlottedMessageInDrawer>
  </div>
</template>


<script>
import settings from '@/settings.js';

import SimpleCardNumber from './components/SimpleCardNumber';
import UpcomingSurgeries from './components/UpcomingSurgeries';

import SlottedMessageInDrawer from '@/components/dialogs/SlottedMessageInDrawer';
import WidgetsSelectorDialog from './components/WidgetsSelectorDialog';

import WidgetFilters from './WidgetFilters';

export default {
  data() {
    const that = this;
    const allWidgets = [
      {
        type: 'UpcomingSurgeries',
        title: 'Upcoming reviews',
      },
      {
        type: 'SimpleCardNumber',
        title: 'Checklist Completion',
        unit: '%',
        iconClass: 'fas fa-tasks h-accent',
        getHelpDialogRef: () => {
          return that.$refs.checklistCompletionDialog;
        },
        getSummeryValue() {
          return (
            that.dashinfo.care_protocols_metrics.curriculum_completion * 100
          ).toFixed(0);
        },
      },
      {
        type: 'SimpleCardNumber',
        title: 'VISA Completion',
        unit: '%',
        iconClass: 'far fa-calendar-alt h-warning',
        getSummeryValue() {
          return 89;
        },
      },

      {
        type: 'SimpleCardNumber',
        title: 'Shower protocol',
        unit: '%',
        iconClass: 'fa fa-shower h-accent',
        getSummeryValue() {
          return 48;
        },
      },
      {
        type: 'SimpleCardNumber',
        title: 'Readmissions',
        unit: '%',
        iconClass: 'fa fa-exclamation-triangle h-dangerous',
        getSummeryValue() {
          return 6.2;
        },
      },
      {
        type: 'SimpleCardNumber',
        title: 'Length of stays',
        unit: 'days',
        iconClass: 'healenticons-hours-of-sleep h-accent',
        getSummeryValue() {
          return 2.1;
        },
      },
      {
        type: 'SimpleCardNumber',
        title: 'Education quiz score',
        unit: '%',
        iconClass: 'fa fa-book h-accent',
        getSummeryValue() {
          return 83;
        },
      },
      {
        type: 'SimpleCardNumber',
        title: 'Engagement',
        unit: '%',
        iconClass: 'fa fa-smile h-accent',
        getSummeryValue() {
          return 86;
        },
      },
    ];

    return {
      dashinfo: null,
      widgets: this.getSelectedWidgets(allWidgets),
      addedWidgets: [...allWidgets],
      allWidgets,
    };
  },

  methods: {
    getSelectedWidgets(allWidgets) {
      let widgets = [];
      const selectedDashboardWidgets = window.localStorage.getItem(
        'selectedDashboardWidgets',
      );
      if (selectedDashboardWidgets) {
        widgets = allWidgets.filter((widget) => {
          return selectedDashboardWidgets.indexOf(widget.title) !== -1;
        });
      }
      return widgets;
    },

    getWidgetWrapperClass(widget) {
      if (this.allWidgets.length === 1) {
        return 'h-col-desktop-12 h-col-mobaile-12';
      }
      const classes = [];

      switch (widget.type) {
        case 'UpcomingSurgeries':
          classes.push('h-card-100');
          break;
        case 'SimpleCardNumber':
          classes.push('h-card-w');
          break;
      }

      return `${classes.join(' ')}`;
    },

    showSelectWidgetsDialog() {
      this.$refs.widgetsSelectorDialog.show();
    },

    toggleSelectedWidget(widget) {
      if (this.widgets.indexOf(widget) !== -1) {
        const index = this.widgets.indexOf(widget);
        this.widgets.splice(index, 1);
      } else {
        this.widgets.push(widget);
        this.widgets = this.allWidgets.filter((widgetsEntry) => {
          return this.widgets.indexOf(widgetsEntry) !== -1;
        });
      }

      this.$nextTick(() => {
        if (this.$refs.upcomingSurgeries) {
          this.$refs.upcomingSurgeries.forEach((upcomingSurgeries) => {
            upcomingSurgeries.forceRedraw();
          });
        }
      });

      window.localStorage.setItem(
        'selectedDashboardWidgets',
        JSON.stringify(this.widgets.map(widgetsEntry => widgetsEntry.title)),
      );
    },

    async load(payload) {
      const response = await this.$api.post(
        `${settings.BACKEND_URL}/api/v-nurse/checklist/get-widgets`,
        null,
        payload ?? {},
      );
      this.allWidgets = [...this.addedWidgets];
      response.data.forEach((widget) => {
        this.allWidgets.push({
          type: 'SimpleCardNumber',
          title: widget.title,
          iconClass: {
            Wellness: 'fas fa-spa h-dangerous',
            Assessment: 'fas fa-notes-medical h-accent',
          }[widget.type],
          getSummeryValue() {
            return widget.summeryValue !== null
              ? widget.summeryValue.toFixed(1)
              : '?';
          },
          getFullTrend() {
            return widget.fullTrend !== null
              ? widget.fullTrend.toFixed(1)
              : widget.fullTrend;
          },
          getWeekTrend() {
            return widget.weekTrend !== null
              ? widget.weekTrend.toFixed(1)
              : widget.weekTrend;
          },
          unit: widget.unit,
          showUnitWithTitle: true,
        });
      });
      this.widgets = this.getSelectedWidgets(this.allWidgets);
    },
  },

  async mounted() {
    this.dashinfo = await this.$api.dashboard();
    this.$nextTick(() => {
      if (this.$refs.upcomingSurgeries) {
        this.$refs.upcomingSurgeries.forEach((upcomingSurgeries) => {
          upcomingSurgeries.forceRedraw();
        });
      }
    });
  },

  components: {
    WidgetsSelectorDialog,
    SimpleCardNumber,
    UpcomingSurgeries,
    SlottedMessageInDrawer,
    WidgetFilters,
  },
};
</script>

<template>
  <div class="">
    <!-- START: APPOINTMENTS -->
    <div class="h-dashboard">
      <div class="h-col-desktop-12 h-col-mobile-12">
        <div v-if="currentTab === 'tabStatistics'">
          <div class="h-row">
            <div class="h-col-desktop-11 h-col-mobile-11">
              <h5 class="h-h3 h-primary_shade_1">Insights</h5>
            </div>
            <div class="h-col-desktop-1 h-col-mobile-1">
              <i
                class="healenticons-bxs-dashboard"
                title="Select widgets"
                style="cursor: pointer"
                @click="showSelectWidgetsDialog"
              ></i>
            </div>
            <div class="h-col-desktop-12 h-col-mobile-12">
              <hr class="h-hr h-margin-top-s" />
            </div>
          </div>
          <Widgets ref="widgets" />
        </div>
        <div v-if="currentTab === 'tabPatientActivity'">
          <ClientsActivity />
        </div>
        <div v-if="currentTab === 'tabAppointments'">
          <div class="h-row">
            <div class="h-col-desktop-12 h-col-mobile-12">
              <h5 class="h-h3 h-primary_shade_1">Appointments</h5>
            </div>
            <div class="h-col-desktop-12 h-col-mobile-12">
              <hr class="h-hr h-margin-top-s" />
            </div>
          </div>
          <AppointmentsCalendar ref="appointmentCalendar" />
        </div>
        <div v-if="currentTab === 'tabSurgeries'">
          <div class="h-row">
            <div class="h-col-desktop-12 h-col-mobile-12">
              <h5 class="h-h3 h-primary_shade_1">Surgeries</h5>
            </div>
            <div class="h-col-desktop-12 h-col-mobile-12">
              <hr class="h-hr h-margin-top-s" />
            </div>
          </div>
          <Surgeries />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';

import ClientsActivity from './main_tables/clients_activity/ClientsActivity';
import AppointmentsCalendar from '@/components/appointments_calendar/AppointmentsCalendar';
import Surgeries from './main_tables/Surgeries';

import Widgets from './widgets/Widgets';
import eventBus from '../../../event-bus';

export default {
  name: 'Dashboard',

  data() {
    return {
      moment,
      tabs: [
        // { title: "Insights", value: "tabStatistics" },
        { title: 'Patient Log', value: 'tabPatientActivity' },
        { title: 'Appointments', value: 'tabAppointments' },
      ],
      currentTab: 'tabPatientActivity',
    };
  },

  methods: {
    showSelectWidgetsDialog() {
      this.$refs.widgets.showSelectWidgetsDialog();
      const activeTabValue = 'tabStatistics';
      eventBus.$emit('setActiveTab', { activeTabValue });
    },
    handleClick(newTab) {
      this.currentTab = newTab;
    },
  },

  async mounted() {
    window.execAfterInitMenuInterface(() => {
      window.initActiveMenu('left-navigation-link>hospital>dashboard');
    });
    const payload = {
      tabInfo: this.tabs,
      activeTab: localStorage.lastSelectedTabInDashboard
        ? localStorage.lastSelectedTabInDashboard
        : this.currentTab,
      updateCurrentTab: true,
    };
    eventBus.$emit('sendingTabArrayInfo', { payload });

    eventBus.$on(
      'tabSelectedInTopBar',
      (event) => {
        this.currentTab = event.newTab;
      },
      this,
    );
  },

  beforeDestroy() {
    localStorage.lastSelectedTabInDashboard = this.currentTab;
    const payload = {
      tabInfo: [],
      activeTab: '',
      updateCurrentTab: false,
    };
    eventBus.$emit('sendingTabArrayInfo', { payload });
    eventBus.$offOwner(this);
  },

  components: {
    AppointmentsCalendar,
    Surgeries,
    ClientsActivity,
    Widgets,
  },
};
</script>

<template>
  <!-- <div class="modal fade"
    role="dialog"
    aria-hidden="true"
    ref="modalDialog"
  > -->
  <div class="h-drawer-max79">
    <Drawer :direction="'right'" :exist="true" ref="modalDialog">
      <div class="h-drawer-card">
        <div class="h-drawer-card-header">
          <div class="h-main-drawer-card-header h-margin-bottom-l">
            <h3 class="h-h3 h-primary_shade_1">
              <slot name="modal-title">Create new item</slot>
            </h3>
            <button
              type="button"
              class="close"
              aria-hidden="true"
              @click="close"
              :disabled="loading"
            >
              <!-- <font-awesome-icon
                    :icon="['fas', 'sign-out-alt']"
                    style="height: 1.5vw; width: 1.5vw; color: #c3c4cb"
                  /> -->
              <img src="/assets/images/Collapse-drawer.svg" alt="" />
            </button>
          </div>
        </div>
        <div class="h-drawer-card-body">
          <div v-if="!loading">
            <label class="h-label"> New item name </label>

            <input
              type="text"
              v-model="name"
              class="h-input"
              :class="{ 'is-invalid': errors.name }"
              :maxlength="300"
            />
            <div class="h-h6 h-secondary_shade_1">
                Max length: 300 ({{
                  300 - (name ? name.length : 0)
                }}
                left)
            </div>
            <div class="invalid-feedback" v-if="errors.name">
              {{ errors.name }}
            </div>
          </div>

          <Loader v-else />
        </div>

        <div class="h-drawer-card-footer">
          <button
            type="button"
            class="h-btn h-btn-accent"
            @click="createItem"
            :disabled="loading"
          >
            Create
          </button>

          <button
            type="button"
            class="h-btn h-btn-dangerous h-margin-left-s"
            @click="close"
            :disabled="loading"
          >
            Cancel
          </button>
        </div>
      </div>
    </Drawer>
  </div>
  <!-- </div> -->
  <!-- /.modal -->
</template>


<script>
import Loader from '@/components/Loader';

export default {
  props: ['protocolId'],

  data() {
    return {
      loading: false,
      name: '',
      errors: {},
    };
  },

  watch: {
    name() {
      this.errors.name = null;
    },
  },

  methods: {
    show() {
      this.$refs.modalDialog.open();
      this.$refs.modalDialog.active = true;
      this.name = '';
      this.loading = false;
    },

    close(force) {
      if (!force && this.loading) return;
      this.$refs.modalDialog.close();
      this.$refs.modalDialog.active = false;
    },

    async createItem() {
      this.loading = true;
      const response = await this.$api.createCareProtocolItem({
        protocolId: this.protocolId,
        name: this.name,
      });
      if (response.status === 'error') {
        this.loading = false;
        this.errors = response.errors;
      } else {
        this.$emit('itemsUpdated', response.care_protocol_items);
        this.close(true);
      }
    },
  },

  components: {
    Loader,
  },
};
</script>

<template>
  <div class="h-drawer-max79">
    <SlottedMessageInDrawer ref="slottedMessage" title="Start background job">
      <template v-slot:modal-body>
        <label v-if="!loading" style="font-weight: normal">
          Start background job for calculating report <strong>{{ reportCode }}</strong>?
        </label>
       <Loader v-if="loading"/>

      </template>

      <template v-slot:modal-footer>
        <button
          type="button"
          class="h-btn h-btn-accent"
          @click="confirm"
        >
          Confirm
        </button>
        <button
          type="button"
          class="h-btn h-btn-dangerous h-margin-left-s"
          @click="close"
        >
          Cancel
        </button>
      </template>
    </SlottedMessageInDrawer>
  </div>
</template>


<style lang="stylus" scoped>
.modal-dialog {
  max-width: 1200px;
}
</style>


<script>
import settings from '@/settings.js';
import SlottedMessageInDrawer from '@/components/dialogs/SlottedMessageInDrawer';
import Loader from '@/components/Loader';

export default {
  props: ['reportCode', 'month', 'hospitalId', 'monthStart', 'monthEnd'],
  data() {
    return {
      loading: false,

    };
  },
  methods: {
    show() {
      this.loading = false;
      this.$refs.slottedMessage.show();
    },

    close() {
      this.$refs.slottedMessage.close();
      this.$refs.slottedMessage.active = false;
      this.loading = true;
    },

    async confirm() {
      this.close();
      if (this.reportCode != 'monthly') {
        $.notify('You will receive an email once reports are recalculated', {
          position: 'top center',
          className: 'info',
        });
      } else {
        $.notify('Report is being calculated', {
          position: 'top center',
          className: 'info',
        });
      }

      this.$emit('startProcessing');
      try {
        const response = await this.$api.get(
          `${settings.BACKEND_URL}/api/v-nurse/reports/start-job`,
          {
            reportCode: this.reportCode,
            month: this.month,
            monthStart: this.monthStart,
            monthEnd: this.monthEnd,
            hospitalId: this.hospitalId,
          },
        );
        if (response.job_id) {
          this.$emit('endProcessing');
          await this.pollJobStatus(response.job_id);
        } else {
          $.notify('The reports were successfully recalculated.', {
            position: 'top center',
            className: 'success',
          });
          this.$emit('endProcessing');
        }
      } catch (error) {
        console.error('Error:', error);
        $.notify('There was an error recalculating the report.', {
          position: 'top center',
          className: 'error',
        });
        this.$emit('endProcessing');
      }
    },

    async pollJobStatus(jobId) {
      const interval = setInterval(async () => {
        try {
          const statusResponse = await this.$api.get(
            `${settings.BACKEND_URL}/api/v-nurse/reports/job-status`,
            {
              job_id: jobId,
            },
          );
          if (statusResponse.status === 'done') {
            clearInterval(interval);
            this.$emit('endProcessing');
            $.notify('Reports were successfully recalculated.', {
              position: 'top center',
              className: 'success',
            });
          } else if (statusResponse.status === 'failed') {
            clearInterval(interval);
            $.notify('There was an error recalculating the report.', {
              position: 'top center',
              className: 'error',
            });
          }
        } catch (error) {
          console.error('Error:', error);
          clearInterval(interval);
          $.notify('Error fetching job status.', {
            position: 'top center',
            className: 'error',
          });
        }
      }, 30000); // Poll every 30 seconds
    },
  },

  components: {
    SlottedMessageInDrawer,
    Loader,
  },
};
</script>

<template>
  <div>
    <div class="h-padding-bottom-l">
      <div
        class="
        h-margin-bottom-l h-space-between h-align-items-center
      "
      >
        <h3 class="h-h3 h-primary_shade_1 ">Edit Onboarding Screen</h3>

        <div>
          <button v-if="hasChanges" class="h-btn h-btn-accent" @click="saveChanges">
            Save Changes
          </button>
          <button
            v-if="hasChanges"
            class="h-btn h-btn-dangerous h-margin-left-s"
            @click="discardChanges"
          >
            Discard Changes
          </button>
        </div>
      </div>

      <InputField
        id="json-textarea"
        type="textarea"
        v-model="onboardingScreenJSON"
        class="textarea-editor h-margin-right-s"
        @input="clearError"
      ></InputField>
      <h5 v-if="error" class="error h-padding-top-s">
        {{ error }}
      </h5>
    </div>
  </div>
</template>

<script>
import InputField from '@/components/InputField';

export default {
  props: {
    careProtocolId: Number,
    initialOnboardingScreen: Object,
  },
  data() {
    return {
      originalJSON: JSON.stringify(this.initialOnboardingScreen, null, 2),
      onboardingScreenJSON: JSON.stringify(this.initialOnboardingScreen, null, 2),
      error: '',
    };
  },
  computed: {
    hasChanges() {
      return this.originalJSON !== this.onboardingScreenJSON;
    },
  },
  methods: {
    formatJsonString(json) {
      return JSON.stringify(json, null, 2);
    },
    loadAndFormatData(onboardingScreen) {
      this.onboardingScreenJSON = this.formatJsonString(onboardingScreen);
    },
    async saveChanges() {
      try {
        const parsedData = JSON.parse(this.onboardingScreenJSON);
        const response = await this.$api.updateOnboardingScreen(this.careProtocolId, parsedData);
        if (response.status === 'success') {
          this.originalJSON = this.onboardingScreenJSON;
          this.$emit('update:onboardingScreen', parsedData);
          $.notify('Onboarding screen updated successfully!', {
            position: 'top center',
            className: 'success',
          });
        } else {
          $.notify('Failed to update onboarding screen.', {
            position: 'top center',
            className: 'error',
          });
        }
      } catch (e) {
        this.error = `Invalid JSON format: ${e.message}. Please correct and try again.`;
      }
    },
    discardChanges() {
      this.onboardingScreenJSON = this.originalJSON;
      this.error = '';
    },
    clearError() {
      this.error = '';
    },
  },
  watch: {
    initialOnboardingScreen(newVal) {
      this.onboardingScreenJSON = JSON.stringify(newVal, null, 2);
      this.originalJSON = this.onboardingScreenJSON;
    },
  },
  components: {
    InputField,
  },
};
</script>

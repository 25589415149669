<template v-slot:modal-body>
  <Drawer :direction="'right'" :exist="true" ref="modalDialog">
    <div class="h-drawer-max79">
      <div class="h-drawer-card">
        <div class="h-drawer-card-header h-align-items-center h-space-between h-padding-bottom-l">
          <h5 class="h-h3 h-primary_shade_1">
            {{data.id? 'Edit Nudging recording': 'Create Nudging recording'}}
          </h5>
          <div>
            <button
              type="button"
              class="close"
              aria-hidden="true"
              @click="close"
              :disabled="loading"
            >
              <img src="../../../../public/assets/images/Collapse-drawer.svg" alt="" />
            </button>
          </div>
        </div>
        <div class="h-drawer-card-body">
          <div class="h-margin-top-l">
            <label class="h-label">Hospital</label>
            <InputField
              name="hospital"
              type="select"
              v-model="data.hospital_id"
              :choices="hospitalChoices"
              :error="errors.hospital_id"
              :class="{ 'has-error': errors.recording }"
              @change="onChangeHospital(data.hospital_id)"
            />
          </div>

          <div class="h-margin-top-l">
            <label class="h-label">Surgeon</label>
            <InputField
              name="surgeon"
              type="select"
              v-model="data.surgeon_id"
              :choices="surgeonChoices"
              :error="errors.surgeon_id"
              :class="{ 'has-error': errors.recording }"
              @input="() => {errors.surgeon_id = null}"
            />
          </div>

          <div class="h-margin-top-l">
            <label class="h-label">Day (2-5)</label>
            <InputField
              name="selected_day"
              type="select"
              v-model="data.trigger"
              :choices="triggerOptions"
              :error="errors.trigger"
              :class="{ 'has-error': errors.recording }"
              @input="() => {errors.trigger = null}"
            />
          </div>
          <div class="h-margin-top-l">
            <label class="h-label">Recording link</label>
            <InputField
              id="recording"
              type="text"
              v-model="data.recording"
              :error="errors.recording"
              :class="{ 'has-error': errors.recording }"
              @input="() => { errors.recording = null }"
            />
          </div>
          <div class="h-margin-top-l" v-if="!data.id">
            <InputField
              type="checkbox"
              id="enable_for_all"
              label="Enable for all patients"
              v-model="data.enable_for_all"
              dense
            />
          </div>
        </div>

        <div class="h-drawer-card-footer">
          <button
            type="button"
            class="h-btn h-btn-accent"
            @click="submit"
            :disabled="loading"
          >
            {{data.id? 'Update': 'Create'}}
          </button>
        </div>
      </div>
    </div>
  </Drawer>
</template>

<script>
import Joi from 'joi';
import InputField from '@/components/InputField';

export default {
  name: 'EditNudgingConfig',

  components: {
    InputField,
  },

  props: {
    hospitals: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      loading: false,
      data: {},
      surgeons: [],
      triggerOptions: [2, 3, 4, 5].map(i => ([`day_${i}`, `${i}`])),
      errors: {},
      schema: Joi.object({
        id: Joi.number().integer().optional(),
        surgeon_id: Joi.number().integer().required(),
        trigger: Joi.any()
          .allow('day_2', 'day_3', 'day_4', 'day_5').required(),
        recording: Joi.string().uri().required(),
        hospital_id: Joi.number().integer().optional(),
        enable_for_all: Joi.bool().optional(),
      }).unknown(),
    };
  },

  computed: {
    hospitalChoices() {
      const choices = this.hospitals.map((hospital) => {
        return [hospital.id, hospital.name];
      });
      choices.unshift([null, '--- select hospital ---']);
      return choices;
    },

    surgeonChoices() {
      const choices = this.surgeons.map((item) => {
        return [item.id, item.name];
      });
      choices.unshift([null, '--- select surgeon ---']);
      return choices;
    },
  },

  methods: {
    show(data) {
      this.data = data;
      if (data.id) {
        this.surgeons.push({ id: data.surgeon_id, name: data.surgeon_name });
      } else {
        this.data = {};
        this.surgeons = [];
      }
      this.$refs.modalDialog.open();
      this.$refs.modalDialog.active = true;
      this.loading = false;
    },

    close(force) {
      this.$refs.modalDialog.close(force);
      this.$refs.modalDialog.active = false;
      this.data = {};
    },

    async onChangeHospital(hospitalId) {
      if (!hospitalId) return;
      this.errors.hospital_id = null;
      try {
        const params = { hospitalId };
        const resp = await this.$api.getHospitalStuff(params);
        this.surgeons = resp.staff;
      } catch (e) {
        this.surgeons = [];
      }
    },

    async submit() {
      const { error, value } = await this.schema.validate(this.data);
      if (error) {
        $.notify(error.toString(), { position: 'top center', className: 'error' });
        return;
      }
      this.$emit('onSubmit', value);
      this.close();
    },
  },
};
</script>

<template>
  <div>
    <span @click="showSparklineChartDialog" class="cursor-pointer">
      <Chart :options="chartOptions" />
    </span>
    <SparklineChartDialog
      v-if="renderDialog"
      ref="chartDialog"
      :options="chartOptions"
      @closed="chartDialogClosed"
    />
  </div>
</template>

<script>
import { Chart } from 'highcharts-vue';
import Highcharts from 'highcharts';
import SparklineChartDialog from './components/SparklineChartDialog';

export default {
  props: {
    options: {
      type: Object,
      required: true,
    },
  },

  data() {
    const defaultOptions = {
      chart: {
        backgroundColor: null,
        borderWidth: 0,
        type: 'area',
        margin: [0, 0, 0, 0],
        width: 120,
        height: 20,
        style: {
          overflow: 'visible',
        },

        // small optimalization, saves 1-2 ms each sparkline
        skipClone: true,
      },
      title: {
        text: '',
      },
      credits: {
        enabled: false,
      },
      xAxis: {
        labels: {
          enabled: false,
        },
        title: {
          text: null,
        },
        startOnTick: false,
        endOnTick: false,
        tickPositions: [],
      },
      yAxis: {
        labels: {
          enabled: false,
        },
        title: {
          text: null,
        },
        endOnTick: false,
        startOnTick: false,
        tickPositions: [],
      },
      legend: {
        enabled: false,
      },
      tooltip: {
        hideDelay: 0,
        outside: true,
        shared: true,
        formatter() {
          const pointsData = this.points.map((entry) => {
            return `
                <b>${entry.x}</b><br/>
                ${entry.series.name}: ${entry.y}
              `;
          });
          return `
              ${pointsData.join('<br />')}
            `;
        },
      },
      plotOptions: {
        series: {
          animation: false,
          lineWidth: 1,
          lineColor: '#2b5adc',

          shadow: false,
          states: {
            hover: {
              lineWidth: 1,
            },
          },
          marker: {
            radius: 0,

            states: {
              hover: {
                radius: 0,
              },
            },
          },
          fillOpacity: 0,
        },
        column: {
          negativeColor: '#910000',
          borderColor: 'silver',
        },
      },
    };

    return {
      renderDialog: false,
      chartOptions: Highcharts.merge(defaultOptions, this.options),
    };
  },

  methods: {
    showSparklineChartDialog() {
      this.renderDialog = true;
      this.$nextTick(() => {
        this.$refs.chartDialog.show();
      });
    },

    chartDialogClosed() {
      this.renderDialog = false;
    },
  },

  components: {
    Chart,
    SparklineChartDialog,
  },
};
</script>

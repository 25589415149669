<template>
  <div class="h-drawer-calender">
    <Drawer ref="AppointmentsCalendar" :direction="'right'" :exist="true">
      <div class="h-drawer-card">
        <div class="h-drawer-card-header">
          <div class="h-main-drawer-card-header h-margin-bottom-l">
            <h3 class="h-h3 h-primary_shade_1">Appointments Calendar</h3>
            <button
              type="button"
              class="close"
              aria-hidden="true"
              @click="close"
              :disabled="loading"
            >
              <img src="/assets/images/Collapse-drawer.svg" alt="" />
            </button>
          </div>
        </div>
        <div v-if="!loading" class="h-drawer-card-body h-no-footer h-padding-0">
          <AppointmentsCalendar ref="appointmentCalendar" />
          <Loader v-if="loading" />
        </div>
      </div>
    </Drawer>
  </div>
</template>

<script>
import Loader from '@/components/Loader';
import AppointmentsCalendar from '@/components/appointments_calendar/AppointmentsCalendar';

export default {
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    show() {
      this.loading = false;
      this.$refs.AppointmentsCalendar.open();
    },

    close(force) {
      this.$refs.AppointmentsCalendar.close();
    },
  },

  components: {
    Loader,
    AppointmentsCalendar,
  },
};
</script>

<template>
  <div class="h-bg-w h-padding-top-l h-margin-top-l">
    <div class="h-margin-left-l h-margin-right-l h-align-items-center h-space-between">
      <h3 class="h-h3 h-primary_shade_1">Intake Assessment</h3>
    </div>
    <div class="h1-table">
      <table class="table dataTable checklist mt-3 xs-title-content h-table-max-15">
        <thead class="thead-light">
          <tr>
            <th style="width: 70%"><span>Name</span></th>
            <th class="h-text-right" style="width: 5%"><span>Score</span></th>
            <th class="h-text-right" style="width: 5%"><span>Submitted</span></th>
          </tr>
        </thead>

        <tbody v-if="rpmRtmAssessment || riskAssessment || healthRiskAssessment">
          <tr class="cursor-pointer" v-if="healthRiskAssessment" @click="showDialog('HEALTH_RISK')">
            <td>{{ 'Health Risk Assessment' }}</td>
            <td class="h-text-right">{{ healthRiskAssessment.score }}</td>
            <td class="h-text-right">{{ healthRiskAssessment.submitted_on || '-' }}</td>
          </tr>
          <tr class="cursor-pointer" v-if="riskAssessment">
            <td @click="showDialog('RISK')">{{ 'OSORD Risk Assessment' }}</td>
            <td class="h-text-right">
              {{ riskAssessment.osord_value }}/{{ riskAssessment.medd_value }}
            </td>
            <td class="h-text-right">{{ riskAssessment.submitted_on }}</td>
          </tr>
          <tr class="cursor-pointer" v-if="rpmRtmAssessment">
            <td @click="showDialog('RPM_RTM')">{{ 'RPM/RTM Stratification Assessment' }}</td>
            <td class="h-text-right">{{ '-' }}</td>
            <td class="h-text-right">{{ rpmRtmAssessment.submitted_on }}</td>
          </tr>
        </tbody>

        <tbody v-else>
          <tr>
            <td colspan="1000" class="h-h4 h-secondary_shade_1 h-text-center">No records</td>
          </tr>
        </tbody>
      </table>
    </div>
    <QuestionsDialog ref="questionsDialog" @saved="getClientIntakeInfo" :canEdit="true" />
    <RiskAssessmentDialog
      ref="riskAssessmentDialog"
      :client="client"
      @update="getClientIntakeInfo"
    />
    <RPMRTMAssessmentDialog
      ref="rpmRTMAssessmentDialog"
      :client="client"
      @update="getClientIntakeInfo"
    />
  </div>
</template>

<script>
import QuestionsDialog from '@/components/assesment_client/components/QuestionsDialog';
import RiskAssessmentDialog from '@/components/assesment_client/components/RiskAssessmentDialog';
import RPMRTMAssessmentDialog from '@/components/assesment_client/components/RPMRTMAssessmentDialog';

export default {
  name: 'IntakeInfo',

  components: {
    QuestionsDialog,
    RiskAssessmentDialog,
    RPMRTMAssessmentDialog,
  },

  props: {
    client: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      rpmRtmAssessment: null,
      riskAssessment: null,
      healthRiskAssessment: null,
    };
  },

  methods: {
    async showDialog(type) {
      if (type === 'HEALTH_RISK') {
        await this.$refs.questionsDialog.show(this.client.id);
      }
      if (type === 'RISK') {
        await this.$refs.riskAssessmentDialog.show('OSORD Risk Assessment', this.riskAssessment);
      }
      if (type === 'RPM_RTM') {
        await this.$refs.rpmRTMAssessmentDialog.show(
          'RPM/RTM Stratification Assessment',
          this.rpmRtmAssessment,
        );
      }
    },
    async getClientIntakeInfo() {
      const {
        rpm_rtm_assessment: rpmRtmAssessment,
        risk_assessment: riskAssessment,
        health_risk_assessment: healthRiskAssessment,
      } = await this.$api.getClientIntakeAssessment({ client_id: this.client.id });
      this.rpmRtmAssessment = rpmRtmAssessment;
      this.riskAssessment = riskAssessment;
      this.healthRiskAssessment = healthRiskAssessment;
    },
  },

  async mounted() {
    await this.getClientIntakeInfo();
  },
};
</script>

<template>
  <div class="MainInterface-wrapper">
    <LeftNav />
    <div id="content">
      <TopBar class="MainInterface-TopBar" />
      <div class="MainInterface-RouterView">
        <router-view :key="JSON.stringify($route.params)" />
      </div>
    </div>
    <ModalMessageStack />
  </div>
</template>

<style lang="stylus" scoped>
.MainInterface-wrapper {
  display: flex;
  flex-direction: row;
}

#content {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  overflow: hidden;
}

.MainInterface-TopBar {
  flex: 0;
  flex-grow: 0;
}

.MainInterface-RouterView {
  flex: 1;
  flex-grow: 1;
  overflow-y: hidden;
  overflow-x: hidden;
}
</style>

<script>
import TopBar from './TopBar';
import LeftNav from './LeftNav';
import ModalMessageStack from './ModalMessageStack';

export default {
  name: 'MainInterface',

  mounted() {
    window.initMenuInterface(this);
    $('#h-menu').on('click', (e) => {
      $('.h-mobile-nav').toggleClass('active');
      $('.h-nav-overlay').toggleClass('active');
    });
    $('.h-acridon-btn').on('click', (e) => {
      $('.h-acridon-btn').toggleClass('h-expand');
    });
    $('.h-acridon-btn1').on('click', (e) => {
      $('.h-acridon-btn1').toggleClass('h-expand');
    });
    $('.h-nav-overlay').on('click', (e) => {
      $('.h-mobile-nav').removeClass('active');
      $('.h-nav-overlay').removeClass('active');
    });
    $('.h-sidemenu-itme a[href]:not([href^="#"])').on('click', (e) => {
      $('.h-mobile-nav').removeClass('active');
      $('.h-nav-overlay').removeClass('active');
    });
  },

  destroyed() {
    window.deinitMenuInterface();
  },

  components: {
    TopBar,
    LeftNav,
    ModalMessageStack,
  },
};
</script>

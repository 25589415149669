<template>
  <div class="h-margin-top-l h-margin-bottom-l">
    <h3 class="header-title h-h3 h-primary_shade_1 h-margin-left-l">
      Current reports
    </h3>

    <div v-if="!loading">
      <div v-if="clientReportsList.length === 0" class="text-muted">
        No reports.
      </div>

      <div v-else class="h1-table">
        <table class="table dataTable reports-table ttd" ref="table">
          <thead class="thead-light">
            <tr>
              <th class="w-1 text-nowrap">#</th>
              <th class="w-1 text-nowrap">ID</th>
              <th class="w-1 text-nowrap">Name</th>
              <th class="w-1 text-nowrap">Email</th>
              <th>Pending reports</th>
            </tr>
          </thead>

          <tbody>
            <tr
              v-for="(clientReports, index) of clientReportsList"
              :key="clientReports.client.id"
            >
              <td class="w-1 text-nowrap">{{ index + 1 }}</td>
              <td class="w-1 text-nowrap">#{{ clientReports.client.id }}</td>
              <td class="w-1 text-nowrap">
                <router-link
                  :to="{
                    name: 'PatientProfile',
                    params: { id: clientReports.client.id },
                  }"
                >
                  <img
                    :src="
                      clientReports.client.profile ||
                      '/assets/images/users/patient-pro.png'
                    "
                    class="thumb-sm rounded-circle mr-2"
                  />
                  {{ clientReports.client.name || "-" }}
                </router-link>
              </td>
              <td class="w-1 text-nowrap">
                {{ clientReports.client.email || "-" }}
              </td>
              <td>
                <ul class="reports-list">
                  <li
                    v-for="reportParams of clientReports.report_params_list"
                    :key="`${clientReports.client.id}::${reportParams.report_start_date}`"
                  >
                    <i
                      v-if="
                        getDownloadStatus(
                          clientReports,
                          reportParams.report_end_date
                        )
                      "
                      class="font-16 mr-1"
                      :class="
                        getDownloadStatus(
                          clientReports,
                          reportParams.report_end_date
                        )
                      "
                      title="Downloaded"
                    ></i>
                    <a
                      :href="getReportLink(clientReports.client, reportParams)"
                      @click="
                        (e) =>
                          downloadReport(e, clientReports.client, reportParams)
                      "
                    >
                      {{ formatDate(reportParams.report_start_date) }} -
                      {{ formatDate(reportParams.report_end_date) }}
                    </a>
                  </li>
                </ul>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <Loader v-else />
  </div>
</template>


<style lang="stylus" scoped>
.reports-list {
  margin-bottom: 0;
  padding-left: 1em;
}

.reports-table td {
  vertical-align: top;
}
</style>


<script>
import moment from 'moment';
import settings from '@/settings.js';
import Loader from '@/components/Loader';

export default {
  data() {
    return {
      loading: true,
      clientReportsList: [],
      downloadedReports: {},
      timeout: null,
    };
  },

  methods: {
    formatDate(d) {
      return moment(d).format('MM/DD/YYYY');
    },

    getReportLink(client, params) {
      return (
        `${settings.BACKEND_URL}/api/v-nurse/client/progress-report`
        + `?id=${client.id}&date-start=${params.report_start_date}`
        + `&date-end=${params.report_end_date}&save=true`
      );
    },

    async downloadReport(e, client, reportParams) {
      e.preventDefault();

      if (!(client.id in this.downloadedReports)) {
        this.downloadedReports[client.id] = [];
      }
      const index = this.downloadedReports[client.id].indexOf(
        reportParams.report_end_date,
      );
      if (index === -1) {
        this.downloadedReports[client.id].push(reportParams.report_end_date);
        this.$forceUpdate();
      }

      await this.$api.downloadFile(this.getReportLink(client, reportParams));

      this.$notificationBar.checkPendingReports();
    },

    getDownloadStatus(clientReports, reportEndDate) {
      if (clientReports.client.id in this.downloadedReports) {
        const thisReport = this.downloadedReports[clientReports.client.id].find(
          (date) => {
            return date === reportEndDate;
          },
        );
        if (thisReport) {
          return 'fas fa-check-square';
        }
        const prevReport = this.downloadedReports[clientReports.client.id].find(
          date => date > reportEndDate,
        );
        if (prevReport) {
          return 'far fa-check-square';
        }
      }
      return '';
    },
  },

  async mounted() {
    this.clientReportsList = await this.$api.get(
      `${settings.BACKEND_URL}/api/v-nurse/client/get-pending-progress-reports`,
    );
    this.loading = false;

    this.timeout = setTimeout(() => {
      $(this.$refs.table).DataTable({
        dom:
          '<"h-row h-space-between h-align-item-center"<"h-show-bt h-margin-left-l header-title"><"h-d-flex h-table-bt"fB>>'
          + '<"h-row"<"h-col-desktop-12 h-col-mobile-12"tr>><"h-row h-space-between h-align-item-center h-padding-bottom-l"lip>',
        buttons: {
          buttons: [
            {
              extend: 'collection',
              className: 'ExportIcon',
              buttons: ['copy', 'excel', 'csv', 'print'],
            },
          ],
          dom: {
            button: {
              className: 'h-btn',
            },
          },
        },
        columnDefs: [
          {
            orderable: false,
            searchable: false,
            targets: 4,
          },
        ],
      });
    }, 0);
  },

  beforeDestroy() {
    if (this.timeout) clearTimeout(this.timeout);
  },

  components: {
    Loader,
  },
};
</script>

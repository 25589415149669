<template>
  <div class="h-drawer-max79350">
    <Drawer :direction="'right'" :exist="true" ref="modalDialog">
      <div class="h-drawer-card">
        <div class="h-drawer-card-header">
          <div class="h-main-drawer-card-header h-margin-bottom-l">
            <h3 class="h-h3 h-primary_shade_1">
              <slot name="modal-title">Delete Campaign</slot>
            </h3>
            <button
              type="button"
              class="close"
              aria-hidden="true"
              @click="close"
              :disabled="loading"
            >
              <img src="/assets/images/Collapse-drawer.svg" alt="" />
            </button>
          </div>
        </div>
        <div class="h-drawer-card-body">
          <div v-if="!loading">
            <h4 class="h-h4 h-primary_shade_1">
              Are you sure you want to delete "{{
                this.campaignToDelete.title
              }}" campaign?
            </h4>
          </div>
          <Loader v-else />
        </div>

        <div class="h-drawer-card-footer">
          <button
            type="button"
            class="h-btn h-btn-accent"
            @click="confirmDeletion"
            :disabled="loading"
          >
            Confirm
          </button>

          <button
            type="button"
            class="h-btn h-btn-dangerous h-margin-left-s"
            @click="close"
            :disabled="loading"
          >
            Cancel
          </button>
        </div>
      </div>
    </Drawer>
  </div>
</template>

<script>
import Loader from '@/components/Loader';

export default {
  data() {
    return {
      loading: false,
      campaignToDelete: null,
    };
  },

  methods: {
    addNewCadence() {
      this.cadences.push({
        when: null,
        number: '',
        unit: null,
        channel: null,
        content: '',
      });
    },
    deleteCadence(index) {
      this.cadences.splice(index, 1);
    },
    addNewCampaign(campaignData) {
      this.campaigns.push(campaignData);
    },
    openDrawer(campaign) {
      this.campaignToDelete = campaign;
      this.loading = true;
      this.error = '';
      this.$refs.modalDialog.open();
      this.$refs.modalDialog.active = true;
      $(this.$refs.modalDialog.el).modal({
        backdrop: 'static',
        keyboard: false,
      });
      $('.modal-backdrop')
        .last()
        .insertBefore(this.$refs.modalDialog.el);
      this.loading = false;
    },

    close(force) {
      this.$refs.modalDialog.close();
      this.$refs.modalDialog.active = false;
      if (!force && this.loading) return;
      $(this.$refs.modalDialog.el).modal('hide');
      this.campaignToDelete = null;
    },

    confirmDeletion() {
      this.loading = true;
      this.$emit('delete-campaign', this.campaignToDelete);
      this.close(true);
    },
  },

  components: {
    Loader,
  },
};
</script>

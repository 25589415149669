<template>
  <div>
    <div class="h-space-between h-align-items-center">
      <h3 class="h-h3 h-primary_shade_1">Phone list</h3>
      <div class="h-d-flex">
        <button
          style="width: max-content"
          class="h-btn h-btn-accent h-margin-left-m h-align-items-center"
          @click="addPhone"
          v-show="isOpen"
        >
          <i
            style="line-height: 1; font-size: 10rem"
            class="h-secondary_shade_5 healenticons-plus h-btn-with-icon h-padding-right-s"
          ></i
          >Add Phone
        </button>
        <button
          style="width: max-content"
          class="h-btn h-btn-accent h-margin-left-m h-align-items-center"
          @click="$emit('toggleSection')"
        >
          <i
            :class="[
              'h-arrow-down',
              'h-secondary_shade_5',
              'healenticons-arrow-ios-forward-fill',
              'h-btn-with-icon',
              { up: isOpen }
            ]"
          ></i
          >{{ isOpen ? "Collapse" : "Expand" }}
        </button>
      </div>
    </div>

    <transition name="slide">
      <div v-show="isOpen" class="h1-table h-w-100">
        <table class="table dataTable h-noborder-input h-margin-bottom-0">
          <thead class="thead-light">
            <tr>
              <th width="200">Type</th>
              <th width="250">Phone</th>
              <th>Extra</th>
              <th>Description</th>
              <th class="text-right w-1">Action</th>
            </tr>
          </thead>

          <tbody v-if="phones.length !== 0">
            <tr v-for="phone of phones" :key="getPhoneId(phone)" :phone="phone">
              <td>
                <InputField
                  type="select"
                  :choices="[
                    ['office', 'Office'],
                    ['mobile', 'Mobile']
                  ]"
                  v-model="phone.phone_type"
                  :error="getPhoneErrors(phone).phone_type"
                  @change="clearPhoneError(phone, 'phone_type')"
                />
              </td>
              <td>
                <InputField
                  type="text"
                  class="phone"
                  placeholder="Enter phone number"
                  v-model="phone.phone_number"
                  :error="getPhoneErrors(phone).phone_number"
                  @change="clearPhoneError(phone, 'phone_number')"
                  @input="formatPhoneNumber"
                />
              </td>
              <td>
                <InputField
                  type="text"
                  v-model="phone.extra"
                  placeholder="Enter extra"
                  :error="getPhoneErrors(phone).extra"
                  @change="clearPhoneError(phone, 'extra')"
                />
              </td>
              <td>
                <InputField
                  type="text"
                  v-model="phone.description"
                  placeholder="Enter description"
                  :error="getPhoneErrors(phone).description"
                  @change="clearPhoneError(phone, 'description')"
                />
              </td>
              <td>
                <i
                  class="fas fa-trash-alt text-danger font-16 cursor-pointer"
                  title="Delete"
                  @click="
                    () => {
                      removePhone(phone);
                    }
                  "
                ></i>
              </td>
            </tr>
          </tbody>

          <tbody v-else>
            <tr>
              <td colspan="5" class="text-center">No phones</td>
            </tr>
          </tbody>
        </table>
      </div>
    </transition>
  </div>
</template>

<script>
import InputField from '@/components/InputField';

export default {
  data() {
    return {
      lastPhoneId: 0,
    };
  },

  methods: {
    clearPhoneError(phone, field) {
      const errors = this.getPhoneErrors(phone);
      errors[field] = null;
    },
    formatPhoneNumber(event) {
      event.target.value = event.target.value.replace(/[^+0-9]/g, '');
    },
    addPhone() {
      this.lastPhoneId += 1;
      let uiId = `uiId:${this.lastPhoneId}`;
      // eslint-disable-next-line no-loop-func
      while (this.phones.find(phone => phone.uiId === uiId)) {
        this.lastPhoneId += 1;
        uiId = `uiId:${this.lastPhoneId}`;
      }
      this.phones.push({
        uiId,
        type: '',
        number: '',
        extra: '',
        description: '',
      });
      $(document).ready(() => {
        $('.phone > input').keyup(function () {
          this.value = this.value.replace(/[^+0-9]/g, '');
        });
      });
    },

    getPhoneId(phone) {
      if (phone.id) return phone.id;
      return phone.uiId;
    },

    getPhoneErrors(phone) {
      if (!this.errors) return {};
      return this.errors[this.getPhoneId(phone)] || {};
    },

    removePhone(phone) {
      this.phones.splice(this.phones.indexOf(phone), 1);
      if (this.errors) {
        if (this.errors[this.getPhoneId(phone)]) {
          delete this.errors[this.getPhoneId(phone)];
        }
      }
    },
  },
  mounted() {
    $(document).ready(() => {
      $('.phone > input').keyup(() => {
        this.value = this.value.replace(/[^+0-9]/g, '');
      });
    });
  },
  components: {
    InputField,
  },

  props: {
    errors: {
      type: Object,
      required: true,
    },
    phones: {
      type: Array,
      required: true,
    },
    isOpen: {
      type: Boolean,
      required: true,
    },
  },
};
</script>

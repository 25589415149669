<template>
  <main class="login">
    <link href="/assets/css/index.css" rel="stylesheet" type="text/css" />

    <div class="h-row">
      <div class="h-col-desktop-8 bg-accent h-hide-mobile">
        <div id="login_animation">
          <lottie-animation path="./assets/js/lottie_files/login.json" />
        </div>
      </div>
      <div class="h-col-desktop-4 h-col-mobile-12 h-d-flex">
        <div class="login-card">
          <h1 class="h-h1 h-primary_shade_1 h-text-center">Reset Password</h1>
          <p
            v-if="!loading && !recoverCreated"
            class="h-h3 h-secondary_shade_1 h-text-center h-margin-top-s"
          >
            Enter your Email and instructions will be sent to you!
          </p>
          <!--end auth-logo-text-->
          <div v-if="!loading && !recoverCreated">
            <form class="form-horizontal auth-form h-margin-top-xl">
              <div class="form-group">
                <label for="email" class="h-label">Email</label>
                <div class="input-group">
                  <span class="auth-form-icon">
                    <i class="dripicons-user"></i>
                  </span>
                  <input
                    type="text"
                    class="h-input"
                    :class="{ 'is-invalid': errors.email }"
                    id="email"
                    placeholder="Enter email"
                    v-model="email"
                  />
                  <div class="invalid-feedback" v-if="errors.email">
                    {{ errors.email }}
                  </div>
                </div>
              </div>
              <!--end form-group-->

              <div class="form-group h-row">
                <div class="h-col-desktop-12 h-col-mobile-12 h-margin-top-l">
                  <button
                    class="h-btn h-btn-accent h-btn-full"
                    type="button"
                    @click="createPasswordRestore"
                  >
                    Reset
                  </button>
                </div>
                <!--end col-->
              </div>
              <!--end form-group-->
            </form>
            <!--end form-->

            <div
              class="
                h-col-desktop-12 h-col-mobile-12 h-text-center h-margin-top-l
              "
            >
              <p class="h-h6 h-bold h-secondary_shade_1">
                Remember It?
                <router-link
                  :to="{ name: 'Login' }"
                  class="h-h6 h-bold h-accent"
                >
                  Sign in here
                </router-link>
              </p>
            </div>
          </div>

          <Loader v-if="loading" />
          <div v-if="!loading && recoverCreated" class="h-text-center">
            <h4 class="h-h4 h-primary_shade_2 h-margin-top-s">
              A link to reset your password was sent to the email.
            </h4>

            <div class="">
              <h4 class="h-h4 h-primary_shade_2">
                Go to
                <router-link :to="{ name: 'Login' }" class="text-primary">
                  login
                </router-link>
              </h4>
            </div>
          </div>
        </div>
        <!--end card-->
      </div>
      <!--end auth-page-->
    </div>
  </main>
</template>

<style lang="stylus" scoped>
.account-body {
  background-color: transparent;
  padding-top: 2em;
  padding-bottom: 4em;
}
</style>

<script>
import Loader from './components/Loader';

export default {
  name: 'RecoverPassword',

  data() {
    return {
      loading: false,

      email: '',
      errors: {},

      recoverCreated: false,
    };
  },

  methods: {
    async createPasswordRestore() {
      this.loading = true;
      const result = await this.$api.createPasswordRestore({
        email: this.email,
        restore_link_template:
          window.location.origin
          + this.$router.resolve({
            name: 'ChangePassword',
            params: {
              code: ':code',
            },
          }).href,
      });

      this.loading = false;
      this.errors = result.errors || {};
      if (result.status === 'ok') {
        this.recoverCreated = true;
      }
    },
  },

  components: {
    Loader,
  },
};
</script>

<!-- test -->
<template>
  <div>
    <div
      class="
        h-padding-top-l
        h-padding-left-l
        h-padding-right-l
        h-space-between
        h-align-items-center
      "
    >
      <h5 class="h-h3 h-primary_shade_1">Medication Use</h5>
      <button v-if="editMode" type="button" class="h-btn h-btn-accent" @click="createEntry">
        <div class="h-align-items-center">
          <i class=" h-secondary_shade_5 healenticons-plus h-padding-right-s"></i>Add
        </div>
      </button>
    </div>
    <div>
      <div v-if="!initialLoading">
        <div v-if="medicationUseEntries && medicationUseEntries.length">
          <div v-if="!editMode">
            <label class="h-label"> Select submission: </label>
            <select v-model="selectedOption" class="h-input">
              <option v-for="option of medicationUseOptions" :key="option" :value="option">
                {{ option }}
              </option>
            </select>
          </div>
          <div v-if="!loading">
            <Entry
              :medicationUseEntries="medicationUseEntries"
              :editMode="editMode"
              @edit="editEntry"
              @showDeleteEntryDialog="showDeleteEntryDialog"
            />
          </div>
          <Loader v-else />
        </div>
        <div
          v-else
          class="
            h-h4
            h-secondary_shade_1
            h-text-center h-padding-top-l h-padding-bottom-l
          "
        >
          {{ !editMode ? 'No submitted forms.' : 'Empty list.' }}
        </div>
      </div>
      <Loader v-else />
    </div>

    <MedicationUseEntryDialog ref="medicationUseEntryDialog" @save="saveEntry" />
    <DeleteMedicationUseEntryDialog
      ref="deleteMedicationUseEntryDialog"
      @deleteItem="deleteEntry"
    />
  </div>
</template>

<script>
import moment from 'moment';
import settings from '@/settings.js';
import Loader from '@/components/Loader';
import Entry from './Entry';
import MedicationUseEntryDialog from './MedicationUseEntryDialog';
import DeleteMedicationUseEntryDialog from './DeleteMedicationUseEntryDialog';

export default {
  props: ['client', 'editMode'],

  data() {
    return {
      medicationUseList: [],
      medicationUseEntries: [],
      medicationUseOptions: [],
      medicationUseKeyToIds: [],
      selectedOption: null,
      loading: true,
      initialLoading: true,
    };
  },

  watch: {
    selectedOption() {
      this.load();
    },

    editMode() {
      this.load();
    },
  },

  methods: {
    getDate(d) {
      return moment
        .utc(d)
        .local()
        .format('MM/DD/YYYY');
    },

    processResponse(response) {
      this.medicationUseList = response.medication_use_list;
      this.medicationUseOptions = response.all;
      this.medicationUseKeyToIds = {};

      this.medicationUseEntries = [];
      let odd = false;
      this.medicationUseList.forEach((medicationUse) => {
        medicationUse.entries.forEach((entry) => {
          entry.created_at = medicationUse.created_at;
          entry.odd = odd;
          this.medicationUseEntries.push(entry);
        });
        odd = !odd;
      });

      if (!this.medicationUseOptions?.length) {
        this.selectedOption = null;
      } else if (this.medicationUseOptions.indexOf(this.selectedOption) === -1) {
        this.selectedOption = this.medicationUseOptions[0];
      }
      this.loading = false;
      this.initialLoading = false;
    },

    async load() {
      this.loading = true;
      if (!this.editMode) {
        const response = await this.$api.get(
          `${settings.BACKEND_URL}/api/v-nurse/medication-use/get`,
          {
            client_id: this.client.id,
            monthDate: this.selectedOption,
          },
        );
        this.processResponse(response);
      } else {
        const response = await this.$api.get(
          `${settings.BACKEND_URL}/api/v-nurse/medication-use-template/get`,
          {
            client_id: this.client.id,
          },
        );
        this.processResponse(response);
      }
    },

    showDeleteEntryDialog(entry) {
      this.$refs.deleteMedicationUseEntryDialog.show(entry);
    },

    editEntry(entry) {
      this.$refs.medicationUseEntryDialog.show(entry);
    },

    createEntry() {
      this.$refs.medicationUseEntryDialog.show();
    },

    async saveEntry(entry) {
      this.loading = true;
      const response = await this.$api.post(
        entry.id
          ? `${settings.BACKEND_URL}/api/v-nurse/medication-use-template/update`
          : `${settings.BACKEND_URL}/api/v-nurse/medication-use-template/create`,
        null,
        {
          client_id: this.client.id,
          monthDate: this.selectedOption,
          ...entry,
        },
      );
      this.loading = false;
      if (response.status === 'error') {
        this.$refs.medicationUseEntryDialog.setErrors(response.errors);
      } else {
        this.processResponse(response);
        this.$refs.medicationUseEntryDialog.close(true);
      }
    },

    async deleteEntry(entry) {
      this.loading = true;
      const response = await this.$api.post(
        `${settings.BACKEND_URL}/api/v-nurse/medication-use-template/delete`,
        null,
        {
          client_id: this.client.id,
          monthDate: this.selectedOption,
          ...entry,
        },
      );
      this.loading = false;
      this.processResponse(response);
      this.$refs.deleteMedicationUseEntryDialog.close(true);
    },
  },

  async mounted() {
    await this.load();
  },

  components: {
    Loader,
    Entry,
    MedicationUseEntryDialog,
    DeleteMedicationUseEntryDialog,
  },
};
</script>

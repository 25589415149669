<template>
  <div class="h-drawer-max79">
    <Drawer :direction="'right'" :exist="true" ref="modalDialog">
      <div class="h-drawer-card">
        <div class="h-drawer-card-header">
          <div class="h-main-drawer-card-header h-margin-bottom-l">
            <h3 class="h-h3 h-primary_shade_1">Download all reports</h3>
            <button
              type="button"
              class="close"
              aria-hidden="true"
              @click="close"
              :disabled="loading"
            >
              <img src="/assets/images/Collapse-drawer.svg" alt="" />
            </button>
          </div>
        </div>
        <div class="h-drawer-card-body">
          <div :class="{ 'd-none': loading }">
            <div v-for="item in downloadOrders" :key="item.created_at">
              <h3 class="h-h4 h-primary_shade_1 h-margin-bottom-xs">
                <!-- Requested on: -->
                Order requested on: {{ item.created_at }}
                <!--  Id: {{ item.id }} -->
              </h3>
              <h5 class="h-h5 h-primary_shade_1">Hospital selected: {{ item.hospital_name }}</h5>
              <!-- <div class="h-h4 h-primary_shade_1 h-margin-bottom-s">
                Processing ended on: {{ item.processing_end_time }}
              </div> -->

              <div class="h-space-between h-align-items-center h-margin-bottom-m">
                <h5 class="h-h5 h-primary_shade_1" v-if="item.processing_start_time != null">
                  <span class="h-accent">{{ item.reports_processed }}</span>
                  <span> of {{ item.reports_to_process }}</span>
                  <span v-if="item.reports_skipped > 0"> Reports Processed, </span>
                  <span v-else> Reports Processed </span>
                  <span v-if="item.reports_skipped > 0">{{ item.reports_skipped }} Skipped </span>
                  <span class="h-dangerous">({{ item.reports_failed }} Error)</span>
                </h5>
                <h5 class="h-h5 h-primary_shade_1" v-else>
                  Order processing has not started yet
                </h5>
                <div class="report-btn">
                  <a click="" v-if="item.reports_failed > 0">
                    <img src="../../../public/assets/images/download-log.svg" alt="report" />
                  </a>
                  <a
                    class="h-margin-left-s"
                    @click="downloadClick(item.id)"
                    v-if="item.finished_processing"
                  >
                    <img src="../../../public/assets/images/download_report.svg" alt="report" />
                  </a>
                </div>
              </div>
              <div class="h-progress h-margin-top-m h-margin-bottom-m">
                <div
                  class="progressBar"
                  v-bind:style="{ width: item.percent_completed + '%' }"
                ></div>
              </div>
            </div>
            <div class="row"></div>
          </div>
          <Loader v-if="loading" />
        </div>
        <div class="h-drawer-card-footer">
          <button type="button" class="h-btn h-btn-accent" @click="close" :disabled="loading">
            Close
          </button>
        </div>
      </div>
    </Drawer>
  </div>
</template>

<script>
import Loader from '@/components/Loader';
import eventBus from '../../event-bus';

export default {
  data() {
    return {
      loading: false,
      errors: {},
      downloadOrders: [],
      downloadOrdersCount: 0,
    };
  },
  methods: {
    show(meetings = null) {
      this.loading = false;

      const payload = [];

      if (this.client) {
        payload.clientId = this.client.id;
      }

      this.$api.getDownloadAllReportsOrders(payload).then((response) => {
        this.downloadOrdersCount = response.data.length;
        this.downloadOrders = response.data;
        eventBus.$emit('refreshReportOrdersCounter');
      });

      this.errors = {};
      this.errors.client = null;

      this.$refs.modalDialog.open();
      this.$refs.modalDialog.active = true;
      this.loading = false;
    },
    close(force) {
      if (!force && this.loading) return;
      this.$refs.modalDialog.close();
      this.$refs.modalDialog.active = false;
    },
    async downloadClick(reportOrder) {
      const response = await this.$api.getDownloadAllReportsOrdersGetPDF(reportOrder);
    },
  },
  async mounted() {
    const $api = this.$api;
    const that = this;

    const localThis = this;
  },
  components: {
    Loader,
  },
  props: {
    client: {
      type: Object,
    },
  },
};
</script>

<template>
  <div
    class="display-table-cell video-notes-column position-relative"
    :class="{ 'video-notes-column-full-width': visible }"
  >
    <div class="display-table h-100 w-100" :class="{ 'd-none': !visible }">
      <div class="display-table-row w-100">
        <div class="counter-text-align-right">{{ formattedTimerValue }}</div>
      </div>
      <div class="display-table-row">
        <div class="display-table-cell CKEditor-cell">
          <div class="h-ck100 h-toolbar h-drawer-maxfull h-edu">
            <CKEditor
              v-model="editorData"
              :config="editorConfig"
              :editor="editor"
              ref="CKEditor"
            ></CKEditor>
          </div>
        </div>
      </div>

      <div class="display-table-row h-100">
        <div class="display-table-cell therapyPt-cell">
          <TherapyPtEditEntriesTable
            ref="therapyPtEntries"
            :clientId="meetingData.client.id"
          />
        </div>
      </div>

      <div class="display-table-row">
        <div class="display-table-cell">
          <div class="video-notes-commands">
            <button
              ref="saveButton"
              type="button"
              class="btn btn-primary btn-xs w-100"
              @click="saveNotes"
            >
              <i class="fas fa-save mr-2"></i>
              Save
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="hider" @click="toggleVisible">
      {{ visible ? "&lt;" : "&gt;" }}
    </div>
  </div>
</template>


<style lang="stylus" scoped>
.CKEditor-cell {
  height: 400px;
}

.CKEditor-hidden {
  visibility: hidden;
}

.therapyPt-cell {
  border: 1px solid silver;
}

.video-notes-column-full-width {
  width: 750px !important;
}

.video-notes-column {
  background-color: white;
  border-right: 2px solid silver;
  z-index: 3;
  width: 3px;
  overflow: visible;

  .video-notes-commands {
    padding: 5px;
    text-align: center;
  }
}

.hider {
  position: absolute;
  left: 100%;
  top: 50%;
  transform: translate(0, -50%);
  border: 3px solid silver;
  font-size: 4em;
  padding: 2px 1px;
  color: #666;
  background-color: #ddd;
  border-top-right-radius: 50% !important;
  border-bottom-right-radius: 50% !important;
  cursor: pointer;
}

.hider:hover {
  color: #777;
  background-color: #eee;
}

.hider:active {
  color: #444;
}

@media screen and (max-width: 1000px) {
  .video-notes-column {
    display: none !important;
  }
}

.counter-text-align-right {
  text-align: right;
}
</style>


<script>
import CKEditor from '@ckeditor/ckeditor5-vue2';
import * as moment from 'moment';
import * as customEditor from '../../../../public/assets/js/ckeditor';
import TherapyPtEditEntriesTable from '@/components/therapy_pt/edit_entries_table/TherapyPtEditEntriesTable';
import 'moment-duration-format';

export default {
  props: ['meetingData'],

  data() {
    const that = this;
    return {
      adjustSize: () => {},
      appointmentTimeCounter: this.meetingData.note.appointmentTimeUsed
        ? this.meetingData.note.appointmentTimeUsed
        : 0,
      timerInterval: 0,
      visible: true,
      editorData: this.meetingData.note ? this.meetingData.note.text : '',
      editor: customEditor,
      editorConfig: {
        fontFamily: {
          options: [
            'default',
            'Arial, Helvetica, sans-serif',
            'Courier New, Courier, monospace',
            'Georgia, serif',
            'Lucida Sans Unicode, Lucida Grande, sans-serif',
            'Tahoma, Geneva, sans-serif',
            'Times New Roman, Times, serif',
            'Trebuchet MS, Helvetica, sans-serif',
            'Verdana, Geneva, sans-serif',
          ],
        },
        toolbar: {
          items: [
            'heading',
            '|',
            'alignment',
            '|',
            'bold',
            'italic',
            'strikethrough',
            'underline',
            'subscript',
            'superscript',
            '|',
            'link',
            '|',
            'bulletedList',
            'numberedList',
            'todoList',
            '-',
            'fontfamily',
            'fontsize',
            'fontColor',
            'fontBackgroundColor',
            '|',
            'code',
            'codeBlock',
            '|',
            'insertTable',
            '|',
            'outdent',
            'indent',
            '|',
            'uploadImage',
            'blockQuote',
            '|',
            'undo',
            'redo',
          ],
          shouldNotGroupWhenFull: true,
        },
        language: 'en',
        image: {
          toolbar: [
            'imageTextAlternative',
            'imageStyle:full',
            'imageStyle:side',
          ],
        },
        table: {
          contentToolbar: ['tableColumn', 'tableRow', 'mergeTableCells'],
        },
        licenseKey: '',
        wordCount: {
          onUpdate: (stats) => {
            this.charactersLength = stats.characters;
          },
        },
        resize_enabled: false,
        on: {
          instanceReady(evt) {
            const adjustSize = () => {
              evt.editor.resize(
                '100%',
                $(window).height() / 1.5
                  - $('.video-notes-commands').outerHeight(),
              );
            };
            adjustSize();
            that.adjustSize = adjustSize;

            $(window).on('resize', adjustSize);
          },
        },
      },
      timeout: null,
    };
  },
  beforeDestroy() {
    if (this.timerInterval) {
      clearInterval(this.timerInterval);
    }
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
  },
  async mounted() {
    this.timerInterval = setInterval(() => {
      this.appointmentTimeCounter += 1;
      if (!this.appointmentTimeCounter) clearInterval(this.timerInterval);
    }, 1000);
  },
  computed: {
    formattedTimerValue() {
      return moment
        .duration(this.appointmentTimeCounter, 'seconds')
        .format('mm:ss', { trim: false });
    },
  },
  methods: {
    toggleVisible() {
      $('.CKEditor').addClass('CKEditor-hidden');
      this.visible = !this.visible;
      if (this.visible) {
        this.timeout = setTimeout(() => {
          this.adjustSize();
          $('.CKEditor').removeClass('CKEditor-hidden');
        }, 0);
      }
    },

    async saveNotes() {
      const oldLabel = $(this.$refs.saveButton).html();
      $(this.$refs.saveButton).text('Saving ...').prop('disabled', true);
      await this.$refs.therapyPtEntries.save();
      await this.$api.saveMeetingNote({
        meeting_id: this.meetingData.id,
        text: this.editorData,
        appointmentTimeUsed: this.appointmentTimeCounter,
      });
      $(this.$refs.saveButton).text('Saved successfully');
      this.timeout = setTimeout(() => {
        $(this.$refs.saveButton).html(oldLabel).prop('disabled', false);
      }, 2000);
    },
  },

  components: {
    CKEditor: CKEditor.component,
    TherapyPtEditEntriesTable,
  },
};
</script>

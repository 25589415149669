<template>
  <tr>
    <td class="h-text-center">{{ index + 1 }}</td>
    <td>
      <InputField
        type="select"
        v-model="medicationUse.medication_type"
        :error="errors.medication_type"
        @change="errors.medication_type = null"
        :choices="medicationTypes"
      />
    </td>
    <td>
      <InputField
        type="text"
        v-model="medicationUse.name"
        :error="errors.name"
        @change="errors.name = null"
      />
    </td>
    <td>
      <InputField
        type="number"
        v-model="medicationUse.times_a_term"
        :error="errors.times_a_term"
        @change="errors.times_a_term = null"
        :min="1"
      />
    </td>
    <td>
      <InputField
        type="select"
        v-model="medicationUse.term"
        :error="errors.term"
        @change="errors.term = null"
        :choices="terms"
      />
    </td>
    <td>
      <InputField
        type="number"
        v-model="medicationUse.amount"
        :error="errors.amount"
        @change="errors.amount = null"
        :min="0"
      />
    </td>
    <td>
      <InputField
        type="select"
        v-model="medicationUse.amount_unit"
        :error="errors.amount_unit"
        @change="errors.amount_unit = null"
        :choices="items"
      />
    </td>
    <td>
      <InputField
        type="textarea"
        v-model="medicationUse.comment"
        :error="errors.comment"
        @change="errors.comment = null"
      />
    </td>
    <td class="h-text-center">
      <i
        class="fas fa-trash-alt h-dangerous cursor-pointer"
        title="Remove"
        @click="$emit('remove', medicationUse)"
      ></i>
    </td>
  </tr>
</template>

<script>
import InputField from '@/components/InputField';

export default {
  data() {
    return {
      errors: JSON.parse(JSON.stringify(this.serverErrors)),
      medicationTypes: [
        'Over-the-counter pain killers (Tylenol, Advil, etc.)',
        'Prescribed narcotics/opioids',
        'Corticosteroids (for e.g. cortisone, steroid injections)',
        'Hyaluronic acid shots (for e.g. euflexxa)',
      ],
      terms: ['a day', 'a week', 'a month'],
      items: ['mg', 'g', 'kg', 'ml', 'l'],
    };
  },

  watch: {
    serverErrors() {
      this.errors = JSON.parse(JSON.stringify(this.serverErrors));
    },
  },

  mounted() {
    this.medicationUse.medication_type =
      this.medicationUse.medication_type || this.medicationTypes[0];
    this.medicationUse.term = this.medicationUse.term || this.terms[0];
    this.medicationUse.amount_unit = this.medicationUse.amount_unit || this.items[0];
  },

  props: {
    serverErrors: {
      type: Object,
      default: () => ({}),
    },
    index: {
      type: Number,
      required: true,
    },
    medicationUse: {
      type: Object,
    },
  },

  components: {
    InputField,
  },
};
</script>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"h-space-between h-align-items-center"},[_c('h3',{staticClass:"h-h3 h-primary_shade_1"},[_vm._v("Location list")]),_c('div',{staticClass:"h-d-flex"},[_c('button',{directives:[{name:"show",rawName:"v-show",value:(_vm.isOpen),expression:"isOpen"}],staticClass:"h-btn h-btn-accent h-margin-left-m h-align-items-center",staticStyle:{"width":"max-content"},on:{"click":_vm.addLocation}},[_c('i',{staticClass:"h-secondary_shade_5 healenticons-plus h-btn-with-icon h-padding-right-s",staticStyle:{"line-height":"1","font-size":"10rem"}}),_vm._v("Add Location ")]),_c('button',{staticClass:"h-btn h-btn-accent h-margin-left-m h-align-items-center",staticStyle:{"width":"max-content"},on:{"click":function($event){return _vm.$emit('toggleSection')}}},[_c('i',{class:[
            'h-arrow-down',
            'h-secondary_shade_5',
            'healenticons-arrow-ios-forward-fill',
            'h-btn-with-icon',
            { up: _vm.isOpen } ]}),_vm._v(_vm._s(_vm.isOpen ? "Collapse" : "Expand")+" ")])])]),_c('transition',{attrs:{"name":"slide"}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isOpen),expression:"isOpen"}],staticClass:"h1-table h-w-100"},[_c('table',{staticClass:"table dataTable h-noborder-input h-margin-bottom-0"},[_c('thead',{staticClass:"thead-light"},[_c('tr',[_c('th',{attrs:{"width":"250"}},[_vm._v("Location")]),_c('th',{staticClass:"text-right w-1"},[_vm._v("Action")])])]),(_vm.locations.length !== 0)?_c('tbody',_vm._l((_vm.locations),function(location){return _c('tr',{key:_vm.getLocationId(location),attrs:{"location":location}},[_c('td',[_c('InputField',{attrs:{"type":"text","placeholder":"Enter location","error":_vm.getLocationErrors(location).name},on:{"change":function($event){return _vm.clearLocationError(location, 'name')}},model:{value:(location.name),callback:function ($$v) {_vm.$set(location, "name", $$v)},expression:"location.name"}})],1),_c('td',[_c('i',{staticClass:"fas fa-trash-alt text-danger font-16 cursor-pointer",attrs:{"title":"Delete"},on:{"click":function () {
                    _vm.removeLocation(location);
                  }}})])])}),0):_c('tbody',[_c('tr',[_c('td',{staticClass:"text-center",attrs:{"colspan":"4"}},[_vm._v("No locations")])])])])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }
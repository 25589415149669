<template>
  <div id="video-meeting-wrapper" class="display-table">
    <div v-if="!globalLoading && !this.globalError" class="display-table-row h-100">
      <div class="display-table-cell">

        <div class="localVideoHolder-wrapper">
          <VideoHolder
            ref="localVideoHolder"
            :user="localUser"
          />
        </div>

        <div class="video-mainScreen-wrapper display-table w-100 h-100">
          <div class="display-table-row">
            <MeetingNotes
              v-if="($route.params.side === 'staff') && this.meetingData"
              :meetingData="this.meetingData"
            />

            <div class="display-table-cell position-relative">
              <VideoHolder
                ref="remoteVideoHolder"
                :user="remoteUser"
              />

              <Controls
                :mode="mode"
                :remoteUser="remoteUser"
                :localUser="localUser"
                :connectedUsers="connectedUsers"
                :meetingData="meetingData"
                :audioMuted="audioMuted"
                :videoMuted="videoMuted"
                :videoEnabled="videoEnabled"
                :audioEnabled="audioEnabled"
                @joinVideo="joinVideo"
                @leaveVideo="leaveVideo"
                @toggleMicrophone="toggleMicrophone"
                @toggleVideo="toggleVideo"
                @notifyUserAboutCall="notifyUserAboutCall"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-else class="display-table-row h-100">
      <div class="display-table-cell valign-middle">
        <Loader v-if="globalLoading" color="secondary" textColor="white" class="globalLoader" />
        <div v-else>
          <p class="globalError mb-0" v-html="globalError"></p>
          <div v-if="globalMissingDevices" class="text-center">
            <button
              type="button"
              class="btn btn-xl btn-primary p-2 px-3 mt-3"
              @click="globalCheckAvailableDevices()"
            >
              Check available devices again
            </button>
          </div>
          <p v-if="globalShowCopyLink" class="globalError mt-3">
            Please copy paste the link into another browser:
            <br />
            {{ getPageHref() }}
            <br />
            <button
              ref="copyLinkButton"
              type="button" class="btn btn-xl btn-primary p-2 px-3 mt-3 copy-link"
              :data-clipboard-text="getPageHref()"
            >
              Copy link to clipboard
            </button>
          </p>
        </div>
      </div>
    </div>

    <SimpleMessage
      ref="messageDialog"
    />

    <MobileCallDialog
      ref="mobileCallDialog"
      v-if="meetingData"
      :meetingCode="meetingData.code"
      :user="remoteUser"
    />
  </div>
</template>


<style lang="stylus">
.video-meeting-controls
  height 100px
  position relative

#video-meeting-wrapper
  background-color black
  height 100%
  width 100%
  overflow hidden

  .video-mainScreen-wrapper
    position absolute
    left 0
    right 0
    bottom 0
    top 0
    background-color black

    video
      left 0px

  .localVideoHolder-wrapper
    position absolute
    right 20px
    top 20px
    z-index 1
    border 2px solid silver
    width 20%
    max-width 230px
    min-width 120px
    height 35%
    max-height 150px
    min-height 150px

  .globalLoader
    p
      font-size 1.5em

    .spinner-border
      width 100px !important
      height 100px !important

  .globalError
    color white
    text-align center
    font-size 1.5em

.tooltip
  .arrow::before
    border-top-color white !important
  .tooltip-inner
    background-color white !important
    color black

</style>


<script>
import crypto from 'crypto';
import AgoraRTC from 'agora-rtc-sdk';
import ClipboardJS from 'clipboard';

import Loader from '@/components/Loader';
import SimpleMessage from '@/components/dialogs/SimpleMessage';

import videoSettings from './utils/videoSettings';
import VideoHolder from './components/VideoHolder';
import Controls from './components/Controls';
import MobileCallDialog from './components/MobileCallDialog';

import MeetingNotes from './components/MeetingNotes';

import UserStatus from './utils/UserStatus';


export default {
  data() {
    const that = this;
    return {
      globalLoading: true,
      globalError: null,
      globalMissingDevices: false,
      globalShowCopyLink: false,
      meetingData: null,

      videoEnabled: null,
      audioEnabled: null,
      timeoutCheckStreamHandler: null,

      userStatus: new UserStatus(
        this.$route.query['meeting-code'],
        parseInt(this.$route.query['user-id'], 10),
      ),

      mode: videoSettings.constants.VIDEO_MODE_INITIAL,
      audioMuted: true,
      videoMuted: true,

      rtcAppId: '99b5b960925940fcb8536d79a7d6fbdc',
      rtcClient: null,
      rtcChannel: this.$route.query['meeting-code'],
      rtcParamUuid: null,
      rtcLocalStream: null,

      connectedUsers: [],
      timeout: null,
    };
  },

  computed: {
    remoteUser() {
      return this.$route.params.side === 'staff'
        ? this.meetingData.client
        : this.meetingData.staff;
    },

    localUser() {
      return this.$route.params.side === 'staff'
        ? this.meetingData.staff
        : this.meetingData.client;
    },
  },

  methods: {
    userJoined(uid) {
      const existingUser = this.connectedUsers.find((connectedUser) => {
        return connectedUser === uid;
      });

      if (!existingUser) {
        if (this.meetingData.staff.id.toString() === uid) {
          this.connectedUsers = [this.meetingData.staff, ...this.connectedUsers];
        } else if (this.meetingData.client.id.toString() === uid) {
          this.connectedUsers = [this.meetingData.client, ...this.connectedUsers];
        }
      }
    },
    userLeft(uid) {
      const user = this.connectedUsers.find((connectedUser) => {
        return connectedUser.id.toString() === uid;
      });
      if (user) {
        this.connectedUsers.splice(this.connectedUsers.indexOf(user), 1);
        this.$forceUpdate();
      }
    },

    joinVideo() {
      this.mode = videoSettings.constants.VIDEO_MODE_JOINING;
      this.rtcClient = AgoraRTC.createClient({ mode: 'rtc', codec: 'vp8' });
      this.rtcClient.setEncryptionMode('aes-128-xts');
      this.rtcClient.setEncryptionSecret(
        crypto.createHash('sha256').update(this.rtcChannel).digest('hex'),
      );

      this.rtcClient.init(
        this.rtcAppId,
        () => {
          this.rtcClient.join(null, this.rtcChannel, this.localUser.id, (uid) => {
            this.rtcParamUuid = uid;

            this.rtcLocalStream = AgoraRTC.createStream({
              streamID: this.rtcParamUuid,
              audio: true,
              video: true,
              screen: false,
            });

            this.rtcLocalStream.init(() => {
              this.$refs.localVideoHolder.setStream(this.rtcClient, this.rtcLocalStream);
              if (!this.audioMuted) {
                this.$refs.localVideoHolder.muteAudio();
              }
              if (!this.videoMuted) {
                this.$refs.localVideoHolder.muteVideo();
              }
              this.$refs.localVideoHolder.play(this.startCheckStreamProcess);
              this.userStatus.userJoined();
              this.userJoined(uid);
              this.mode = videoSettings.constants.VIDEO_MODE_IN_MEETING;

              this.rtcClient.publish(this.rtcLocalStream, (err) => {
                // unknown error
                this.$refs.messageDialog.show(
                  'General error',
                  `Publish local stream failed: ${JSON.stringify(err)}`,
                  false,
                );
                console.error('Publish local stream failed', err);
              });
            }, (err) => {
              this.rtcClient.leave();
              this.$refs.localVideoHolder.userLeft();
              if (err.msg === 'NotAllowedError') {
                this.$refs.messageDialog.show(
                  'Permission denied',
                  `Access to video/audio device not allowed.
                  <br /><br /> If you use Chrome browser:
                  <ol>
                    <li>Go to settings;</li>
                    <li>Select "Additional settings" &gt; "Privacy and security";</li>
                    <li>Select "Site Settings";</li>
                    <li>Select "Camera";</li>
                    <li>Remove this site from list of blocked sites.</li>
                  </ol>
                  `,
                  null,
                  true,
                );
              } else if (err.msg === 'NotFoundError') {
                this.$refs.messageDialog.show(
                  'Hardware device not found',
                  'Failed to connect to your video/audio device. '
                  + 'Please make sure that they are plugged in.',
                );
              } else if (err.msg === 'NotReadableError') {
                if (err.info.indexOf('audio') !== -1) {
                  this.$refs.messageDialog.show(
                    'Microphone not available',
                    `Microphone not available. Please make sure it is anabled and
                    not in use by other applications.`,
                  );
                } else if (err.info.indexOf('video') !== -1) {
                  this.$refs.messageDialog.show(
                    'Camera not available',
                    `Camera not available. Please make sure it is anabled and
                    not in use by other applications.`,
                  );
                } else {
                  // unknown error
                  this.$refs.messageDialog.show(
                    'Source not readable',
                    `${err.info}.`,
                  );
                }
              } else {
                this.$refs.messageDialog.show(
                  // unknown error
                  'General error',
                  `Init local stream failed: ${JSON.stringify(err)}`,
                  false,
                );
              }
              console.error('Init local stream failed', err);
              this.mode = videoSettings.constants.VIDEO_MODE_INITIAL;
            });
          }, (err) => {
            this.$refs.messageDialog.show(
              // unknown error
              'General error',
              `Client join failed: ${JSON.stringify(err)}`,
              false,
            );
            console.error('Client join failed', err);
          });
        }, (err) => {
          this.$refs.messageDialog.show(
            // unknown error
            'General error',
            `Client init failed: ${JSON.stringify(err)}`,
            false,
          );
          console.error('Client init failed', err);
        },
      );

      const that = this;
      this.rtcClient.on('stream-added', (evt) => {
        const remoteStream = evt.stream;
        const uid = remoteStream.getId();
        this.userJoined(uid);
        if (uid !== that.rtcParamUuid) {
          that.rtcClient.subscribe(remoteStream, (err) => {
            // unknown error
            that.$refs.messageDialog.show(
              'General error',
              `Stream subscribe failed: ${JSON.stringify(err)}`,
              false,
            );
            console.error('Stream subscribe failed', err);
          });
        }
      });

      this.rtcClient.on('stream-subscribed', (evt, a) => {
        const remoteStream = evt.stream;
        that.$refs.remoteVideoHolder.setStream(that.rtcClient, remoteStream);
        that.$refs.remoteVideoHolder.play();
      });

      this.rtcClient.on('peer-leave', (evt) => {
        const remoteStream = evt.stream;
        if (remoteStream.isPlaying()) {
          remoteStream.stop();
        }
        if (that.$refs.remoteVideoHolder.stream === remoteStream) {
          that.$refs.messageDialog.show(
            'Call ended',
            `${this.remoteUser.name} left the meeting.`,
          );
          that.$refs.remoteVideoHolder.userLeft();
          this.userLeft(remoteStream.getId());
          // --- //
          this.leaveVideo(); // currently one-on-one videos
        }
      });
    },

    leaveVideo() {
      if (this.timeoutCheckStreamHandler) {
        clearTimeout(this.timeoutCheckStreamHandler);
        this.timeoutCheckStreamHandler = null;
      }
      this.videoEnabled = null;
      this.audioEnabled = null;

      this.mode = videoSettings.constants.VIDEO_MODE_INITIAL;

      this.rtcClient.leave(() => {
        this.$refs.localVideoHolder.userLeft();
        this.$refs.remoteVideoHolder.userLeft();
        this.userStatus.userLeft();

        this.connectedUsers.forEach((uid) => {
          this.userLeft(uid);
        });
      }, (err) => {
        // unknown error
        this.$refs.messageDialog.show(
          'General error',
          `Channel leave failed: ${JSON.stringify(err)}`,
          false,
        );
        console.error('Channel leave failed', err);
      });
    },

    toggleMicrophone() {
      this.audioMuted = !this.audioMuted;
      if (this.audioMuted) {
        this.$refs.localVideoHolder.unmuteAudio();
      } else {
        this.$refs.localVideoHolder.muteAudio();
      }
    },

    toggleVideo() {
      this.videoMuted = !this.videoMuted;
      if (this.videoMuted) {
        this.$refs.localVideoHolder.unmuteVideo();
      } else {
        this.$refs.localVideoHolder.muteVideo();
      }
    },

    globalCheckAvailableDevices(doWait) {
      // doWait is used only for better user experience

      this.globalLoading = true;
      this.timeout = setTimeout(() => {
        const that = this;
        AgoraRTC.getDevices((items) => {
          // checking missing devices
          const itemKinds = items.map(item => item.kind);
          const missingHardwareDevices = [];
          if (itemKinds.indexOf('audioinput') === -1) {
            missingHardwareDevices.push('Audio Input');
          }
          if (itemKinds.indexOf('videoinput') === -1) {
            missingHardwareDevices.push('Video Input');
          }

          if (missingHardwareDevices.length !== 0) {
            that.globalError = `Failed to detect devices for: ${
              missingHardwareDevices.join(', ')}. `
              + '<br / > Please make sure that they are plugged in and enabled.';
          } else {
            that.globalError = null;
          }

          that.globalMissingDevices = !!missingHardwareDevices.length;
          that.globalLoading = false;
        });
      }, (doWait || (doWait === undefined)) ? 2000 : 0);
    },

    startCheckStreamProcess() {
      if (this.timeoutCheckStreamHandler) {
        clearTimeout(this.timeoutCheckStreamHandler);
        this.timeoutCheckStreamHandler = null;
      }

      if (!this.rtcLocalStream) return;
      if (!this.rtcLocalStream.isPlaying()) return;

      this.videoEnabled = !this.rtcLocalStream.getVideoTrack().muted;
      this.audioEnabled = !this.rtcLocalStream.getAudioTrack().muted;

      this.timeoutCheckStreamHandler = setTimeout(this.startCheckStreamProcess, 1000);
    },

    getPageHref() {
      return window.location.href;
    },

    notifyUserAboutCall() {
      this.$refs.mobileCallDialog.show();
    },
  },

  async mounted() {
    if (!AgoraRTC.checkSystemRequirements()) {
      this.globalError = 'Your browser is not compatible with this video API. ';
      this.globalShowCopyLink = true;
      this.globalLoading = false;
      this.timeout = setTimeout(() => {
        const cb = new ClipboardJS('#video-meeting-wrapper .copy-link');
        cb.on('success', (e) => {
          this.$refs.messageDialog.show(
            'Link copied',
            'Url link copied to clipboard. '
            + 'Use ctrl+v to insert it into another browser addres bar.',
          );
        });
      }, 0);
      return;
    }

    // eslint-disable-next-line
    Waves.init();
    this.meetingData = await this.$api.getMeetingByCode(
      this.$route.params.side,
      this.$route.query['meeting-code'],
      parseInt(this.$route.query['user-id'], 10),
    );
    this.globalCheckAvailableDevices(false);
    this.globalLoading = false;
  },

  beforeDestroy() {
    if (this.timeout) clearTimeout(this.timeout);
    if (this.timeoutCheckStreamHandler) clearTimeout(this.timeoutCheckStreamHandler);
  },

  components: {
    VideoHolder,
    Controls,
    Loader,
    MeetingNotes,
    SimpleMessage,
    MobileCallDialog,
  },
};
</script>

let lastId = 0;


export default class TimeParts {
  MINUTE = 60;

  HOUR = 60 * this.MINUTE;

  DAY = 24 * this.HOUR;

  WEEK = 7 * this.DAY;

  constructor(totalSeconds, options) {
    lastId += 1;
    this.id = lastId;

    this.setTotalSeconds(totalSeconds);
    this.options = options || {};
  }

  callOnChange() {
    if (this.options.onChange) {
      this.options.onChange(this);
    }
  }

  setTotalSeconds(totalSeconds) {
    let secondsRemaind = totalSeconds || 0;

    this.weeks = Math.trunc(secondsRemaind / this.WEEK);
    secondsRemaind -= this.weeks * this.WEEK;

    this.days = Math.trunc(secondsRemaind / this.DAY);
    secondsRemaind -= this.days * this.DAY;

    this.hours = Math.trunc(secondsRemaind / this.HOUR);
    secondsRemaind -= this.hours * this.HOUR;

    this.minutes = Math.trunc(secondsRemaind / this.MINUTE);
    secondsRemaind -= this.minutes * this.MINUTE;
  }

  setWeeks(weeks) {
    weeks = parseInt(weeks, 10);
    if (weeks >= 0) {
      this.weeks = weeks;
    }
    this.callOnChange();
  }

  setDays(days) {
    days = parseInt(days, 10);
    if ((days <= 6) && (days >= 0)) {
      this.days = days;
    }
    this.callOnChange();
  }

  setHours(hours) {
    hours = parseInt(hours, 10);
    if ((hours <= 23) && (hours >= 0)) {
      this.hours = hours;
    }
    this.callOnChange();
  }

  getTotalSeconds() {
    return this.weeks * this.WEEK + this.days * this.DAY + this.hours * this.HOUR
      + this.minutes * this.MINUTE;
  }

  compare(a, b) {
    if (a.getTotalSeconds() > b.getTotalSeconds()) return 1;
    if (a.getTotalSeconds() < b.getTotalSeconds()) return -1;
    return 0;
  }

  clone() {
    return new TimeParts(this.getTotalSeconds());
  }

  toString() {
    return [
      this.weeks ? `${this.weeks} weeks` : null,
      this.days ? `${this.days} days` : null,
      this.hours ? `${this.hours} hours` : null,
    ].filter((value) => { return value !== null; }).join(', ');
  }
}

<template>
  <div class="h-margin-l">
    <div v-if="!loading">
      <div v-if="recipe" class="h-meal-tabs">
        <Tabs
          v-model="section"
          :options="[
            ['MealViewer', 'View recipe'],
            ['MealEditor', 'Edit recipe'],
          ]"
        />
        <div
          id="OpenNewTab"
          v-if="viewer === 'dialog'"
          class="h-padding-left-s h-padding-right-l h-padding-top-s OpenNewTab"
        >
          <a :href="recipeUrl" target="_blank">
            <i class="far fa-window-restore"></i>
          </a>
        </div>

        <MealViewer
          v-if="section === 'MealViewer'"
          :viewer="viewer"
          :recipe="recipe"
          :ingredients="ingredients"
        />

        <MealEditor
          :viewer="viewer"
          v-if="section === 'MealEditor'"
          :recipe="recipe"
          :ingredients="ingredients"
          @scrollTop="scrollTop"
          @recipeSaved="recipeSaved"
        />
      </div>

      <div v-else>
        <h1 class="text-center">Recipe not found</h1>
      </div>
    </div>

    <Loader v-else />
  </div>
</template>


<script>
import Loader from '@/components/Loader';
import Tabs from '@/components/Tabs';
import MealViewer from './meal_viewer/MealViewer';
import MealEditor from './meal_editor/MealEditor';

export default {
  props: {
    recipeId: {
      type: Number,
    },
    viewer: {
      type: String,
    },
  },

  data() {
    return {
      loading: true,
      section: 'MealViewer',
      recipe: null,
      ingredients: [],
    };
  },

  computed: {
    recipeUrl() {
      return this.$router.resolve({
        name: 'Recipe',
        params: {
          recipeId: this.recipeId,
        },
      }).href;
    },
  },

  methods: {
    scrollTop() {
      this.$emit('scrollTop');
    },

    recipeSaved(recipe, ingredients) {
      this.recipe = recipe;
      this.ingredients = ingredients;
      this.$emit('recipeSaved', this.recipe, this.ingredients);
    },
  },

  async mounted() {
    this.loading = true;
    try {
      const response = await this.$api.getRecipe(this.recipeId);
      this.recipe = response.recipe;
      this.ingredients = response.ingredients;
      this.$emit('recipeSet', this.recipe, this.ingredients);
    } catch {
      this.recipe = null;
      this.ingredients = null;
      this.$emit('recipeSet', null, null);
    }
    this.loading = false;
  },

  components: {
    Loader,
    Tabs,
    MealViewer,
    MealEditor,
  },
};
</script>

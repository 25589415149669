class EventKeeper {
  constructor() {
    this.eventListenerList = [];
    this.OnList = [];
  }

  $addEventListener(owner, eventName, element, func, options) {
    this.eventListenerList.push({
      eventName, func, element, owner, options,
    });
    element.addEventListener(eventName, func, options);
  }

  $on(owner, eventName, element, selector, func) {
    this.eventListenerList.push({
      eventName, selector, func, element, owner,
    });
    $(element).on(eventName, selector, func);
  }

  $offOwner(owner) {
    this.eventListenerList = this.eventListenerList.filter((eventListener) => {
      if (eventListener.owner === owner) return true;
      eventListener.element.removeEventListener(
        eventListener.eventName,
        eventListener.func,
      );
      return false;
    });

    this.OnList = this.OnList.filter((on) => {
      if (on.owner === owner) return true;
      $(on.element).off(on.eventName, on.selector, on.func);
      return false;
    });
  }
}

export default new EventKeeper();

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"h1-table"},[_c('table',{staticClass:"table dataTable h-noborder-input h-input-w-100"},[_vm._m(0),(_vm.timePartsList.length !== 0)?_c('tbody',_vm._l((_vm.timePartsList),function(timeParts){return _c('tr',{key:timeParts.id},[_c('td',[_c('div',{staticStyle:{"display":"inline-flex","align-items":"center"}},[_c('input',{staticClass:"h-input",attrs:{"type":"number","step":"1","min":"0","max":"47"},domProps:{"value":timeParts.weeks},on:{"change":function (e) {
                    _vm.setWeeks(timeParts, e.target.value);
                  }}}),_vm._v(" Weeks ")])]),_c('td',[_c('div',{staticStyle:{"display":"inline-flex","align-items":"center"}},[_c('input',{staticClass:"h-input",attrs:{"type":"number","step":"1","min":"0","max":"6"},domProps:{"value":timeParts.days},on:{"change":function (e) {
                    _vm.setDays(timeParts, e.target.value);
                  }}}),_vm._v(" Days ")])]),_c('td',[_c('div',{staticStyle:{"display":"inline-flex","align-items":"center"}},[_c('input',{staticClass:"h-input",attrs:{"type":"number","step":"1","min":"0","max":"23"},domProps:{"value":timeParts.hours},on:{"change":function (e) {
                    _vm.setHours(timeParts, e.target.value);
                  }}}),_vm._v(" Hours ")])]),_c('td',{staticClass:"h-text-center"},[_c('a',{attrs:{"href":"javascript:void(0);"},on:{"click":function () {
                  _vm.removeEntry(timeParts);
                }}},[_c('i',{staticClass:"fas fa-trash-alt h-dangerous font-16",attrs:{"title":"Delete"}})])])])}),0):_c('tbody',[_vm._m(1)])])]),_c('div',{staticClass:"h-margin-top-s h-margin-right-l h-text-right"},[_c('button',{staticClass:"h-btn h-btn-accent h-btn-height20",attrs:{"type":"button"},on:{"click":_vm.addEntry}},[_c('i',{staticClass:"mdi mdi-plus-circle-outline mr-2"}),_vm._v("Add entry ")])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('thead',{staticClass:"thead-light"},[_c('tr',[_c('th',{staticClass:"h-text-center",attrs:{"width":"10%"}},[_vm._v("Weeks")]),_c('th',{staticClass:"h-text-center",attrs:{"width":"10%"}},[_vm._v("Days")]),_c('th',{staticClass:"h-text-center",attrs:{"width":"10%"}},[_vm._v("Hours")]),_c('th',{staticClass:"h-text-center",attrs:{"width":"1%"}},[_vm._v("Action")])])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('tr',[_c('td',{staticClass:"text-center",attrs:{"colspan":"4"}},[_vm._v("<NO_ENTRIES>")])])}]

export { render, staticRenderFns }
import Vue from 'vue';
import crypto from 'crypto';
import settings from '@/settings.js';


export default {
  publicKeys: {},

  async init() {
    if (this.virgilCrypto) return;

    // eslint-disable-next-line
    await VirgilCrypto.initCrypto();

    // eslint-disable-next-line
    this.virgilCrypto = new VirgilCrypto.VirgilCrypto();
  },

  prepareKeyMaterial(inputStr) {
    if (inputStr === null) {
      window.localStorage.removeItem('virgilKeyMaterial');
      return;
    }
    const maretrial = btoa(
      crypto.createHash('sha256').update(inputStr).digest('hex'),
    );

    window.localStorage.setItem('virgilKeyMaterial', maretrial);
  },

  getKeyMaterial() {
    return window.localStorage.getItem('virgilKeyMaterial');
  },

  getUserIdentity(user) {
    let userForIdentity = user;
    if (!userForIdentity) {
      userForIdentity = settings.currentSession.user;
    }
    return userForIdentity.id.toString();
  },

  genKeys() {
    if (this.getKeyMaterial() === null) {
      window.location = '/session-expired';
      return '';
    }
    if (!this.keyPair) {
      this.keyPair = this.virgilCrypto.generateKeysFromKeyMaterial(this.getKeyMaterial());
    }
    return this.keyPair;
  },

  async ensurePublicKey() {
    await this.init();

    const keyPair = this.genKeys();
    const identity = this.getUserIdentity();

    await Vue.prototype.$api.saveUserPublicKey({
      publicKey: this.virgilCrypto.exportPublicKey(keyPair.publicKey).toString('base64'),
    });
    this.publicKeys[identity] = keyPair.publicKey;
    return this.publicKeys[identity];
  },

  async getUserPublicKeys(user) {
    await this.init();

    const identity = this.getUserIdentity(user);
    if (this.publicKeys[identity]) {
      return this.publicKeys[identity];
    }

    const response = await Vue.prototype.$api.getUserPublicKeys({
      userId: user.id,
    });

    this.publicKeys[identity] = response.public_keys.map((rawPubKey) => {
      return this.virgilCrypto.importPublicKey(rawPubKey);
    });
    return this.publicKeys[identity];
  },

  async preloadUserPublicKeys(userList) {
    const result = [];
    // eslint-disable-next-line
    for (const user of userList) {
      result.push(await this.getUserPublicKeys(user));
    }
    return result;
  },

  encodeMessageForUser(user, message) {
    const privateKey = this.genKeys().privateKey;
    const publicKeys = this.publicKeys[this.getUserIdentity(user)];
    const encryptedMessage = this.virgilCrypto.signThenEncrypt(
      message, privateKey, publicKeys,
    );
    return encryptedMessage.toString('base64');
  },

  decodeMessageFromUser(user, message) {
    const privateKey = this.genKeys().privateKey;
    const publicKeys = this.publicKeys[this.getUserIdentity(user)];

    try {
      return {
        content: this.virgilCrypto.decrypt(
          message, privateKey, publicKeys,
        ).toString(),
        status: 'success',
      };
    } catch (e) {
      console.log(e);
      return {
        content: 'Failed to decode',
        status: 'error',
      };
    }
  },

  decodeMessage(message) {
    return this.decodeMessageFromUser(
      message.from_user, message.content,
    );
  },
};

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.careProtocolId && !_vm.readOnly)?_c('div',{staticClass:"h-text-right h-padding-right-l"},[_c('button',{staticClass:"h-btn h-btn-safe",attrs:{"type":"button"},on:{"click":function () {
          _vm.$refs.cloneItemsDialog.show(null)
        }}},[_vm._v(" Clone existing items ")]),_c('button',{staticClass:"h-btn h-btn-accent h-margin-left-s",attrs:{"type":"button"},on:{"click":function () {
          _vm.$refs.editThoughtDialog.show(null)
        }}},[_c('i',{staticClass:"healenticons-plus h-padding-right-xs"}),_vm._v("New item ")])]):_vm._e(),(!_vm.loading)?_c('div',{staticClass:"h1-table"},[_c('table',{staticClass:"table dataTable h-table-thought"},[_c('thead',{staticClass:"thead-light"},[_c('tr',[(_vm.clientId)?_c('th',{staticClass:"text-left",attrs:{"width":"5%"}},[_vm._v("Enabled")]):_vm._e(),_c('th',{staticClass:"text-left",attrs:{"width":"5%"}},[_vm._v("Start date")]),_c('th',{staticClass:"text-left",attrs:{"width":"5%"}},[_vm._v("Is after")]),_c('th',{staticClass:"text-right",attrs:{"width":"5%"}},[_vm._v("Days before/after")]),_c('th',{staticClass:"text-right",attrs:{"width":"5%"}},[_vm._v("Local reminder time")]),_c('th',{staticClass:"text-left",attrs:{"width":"5%"}},[_vm._v("Notification content")]),(_vm.clientId)?_c('th',{staticClass:"text-right",attrs:{"width":"15%"}},[_vm._v("Due date")]):_vm._e(),(_vm.clientId)?_c('th',{staticClass:"text-left",attrs:{"width":"15%"}},[_vm._v("SMS sent")]):_vm._e(),(!_vm.readOnly)?_c('th',{staticClass:"text-left",attrs:{"width":"1%"}},[_vm._v("Action")]):_vm._e()])]),(_vm.items.length !== 0)?_c('tbody',_vm._l((_vm.items),function(item){return _c('tr',{key:item.id},[(_vm.clientId)?_c('td',{staticClass:"text-left",class:{
              'text-danger': !item.notification.enabled
            }},[_vm._v(" "+_vm._s(item.notification.enabled)+" ")]):_vm._e(),_c('td',{staticClass:"text-left"},[_vm._v(" "+_vm._s(item.start_date_type_display)+" ")]),_c('td',{staticClass:"text-left"},[_vm._v(" "+_vm._s(item.post_op ? 'Yes' : 'No')+" ")]),_c('td',{staticClass:"text-right"},[_vm._v(" "+_vm._s(item.shift_in_days)+" ")]),_c('td',{staticClass:"text-right",attrs:{"data-tolltip":"Default is user daily reminder time"}},[_vm._v(" "+_vm._s(_vm.getItemRemindTime(item.remind_time))+" ")]),_c('td',[_c('TruncateText',{attrs:{"data-tooltip":item.content,"text":item.content ? item.content : ''}})],1),(_vm.clientId)?_c('td',{staticClass:"text-right"},[_vm._v(" "+_vm._s(_vm.getItemDueDate(item.notification.due_date))+" ")]):_vm._e(),(_vm.clientId)?_c('td',{class:{
              'text-success': item.notification.is_sent
            }},[_vm._v(" "+_vm._s(item.notification.is_sent)+" ")]):_vm._e(),(_vm.careProtocolId && !_vm.readOnly)?_c('td',{staticClass:"text-center"},[_c('div',[_c('a',{staticClass:"mr-2",attrs:{"href":"javascript:void(0);"},on:{"click":function () {
                    _vm.$refs.editThoughtDialog.show(item)
                  }}},[_c('i',{staticClass:"fas fa-edit text-info font-16",attrs:{"title":"Edit"}})]),_c('a',{staticClass:"mr-2",attrs:{"href":"javascript:void(0);"},on:{"click":function () {
                    _vm.$refs.deleteThoughtDialog.show(item)
                  }}},[_c('i',{staticClass:"fas fa-trash-alt text-danger font-16",attrs:{"title":"Delete"}})])])]):_vm._e(),(_vm.clientId)?_c('td',{staticClass:"text-left"},[_c('div',[_c('a',{staticClass:"mr-2",attrs:{"href":"javascript:void(0);"},on:{"click":function () {
                    _vm.$refs.repeatNotificationDialog.show(item)
                  }}},[_c('i',{staticClass:"fas fa-redo-alt text-info font-16",attrs:{"title":"Clear history"}})])])]):_vm._e()])}),0):_c('tbody',[_c('tr',[_c('td',{staticClass:"text-center",attrs:{"colspan":"100"}},[_vm._v(" No entries"+_vm._s(_vm.client ? _vm.client.surgeon ? (" added by surgeon " + (_vm.client.surgeon.name)) : "added by the surgeon" : '')+". ")])])])])]):_c('Loader'),(_vm.careProtocolId)?_c('EditThoughtDialog',{ref:"editThoughtDialog",attrs:{"careProtocolId":_vm.careProtocolId,"doctorId":_vm.doctorId},on:{"saved":_vm.setItems}}):_vm._e(),(_vm.careProtocolId)?_c('DeleteThoughtDialog',{ref:"deleteThoughtDialog",attrs:{"careProtocolId":_vm.careProtocolId,"doctorId":_vm.doctorId},on:{"deleted":_vm.setItems}}):_vm._e(),(_vm.careProtocolId)?_c('CloneItemsDialog',{ref:"cloneItemsDialog",attrs:{"intoCareProtocolId":_vm.careProtocolId,"doctorId":_vm.doctorId},on:{"copied":_vm.setItems}}):_vm._e(),(_vm.clientId)?_c('RepeatNotificationDialog',{ref:"repeatNotificationDialog",attrs:{"clientId":_vm.clientId},on:{"resendEnabled":_vm.setItems}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div v-if="client && client.insurance_info" class="h1-table overflow-auto">
    <table v-if="client.insurance_info.length" class="dataTable table h-noborder-input">
      <thead class="thead-light">
        <tr>
          <th width="5%">Name</th>
          <th width="5%" class="text-nowrap h-text-center">Action</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(ii, index) of client.insurance_info" :key="index">
          <td>
            <InputField v-model="ii.id" type="select" :choices="getInputFieldOptions" dense />
          </td>
          <td class="text-nowrap h-padding-left-s h-padding-right-s h-text-center">
            <a href="javascript:void(0)" @click="removeRow(ii)">
              <i class="fas fa-trash-alt text-danger font-16" title="Delete"></i>
            </a>
          </td>
        </tr>
      </tbody>
    </table>
    <div
      v-if="client.insurance_info.length === 0"
      class="h-text-center h-h5 h-secondary_shade_1 h-bold"
    >
      No entries.
    </div>

    <div class="text-center h-text-center h-margin-top-l">
      <button
        type="button"
        class="btn btn-xs btn-success h-btn h-btn-accent h-btn-with-icon"
        @click="newRow"
      >
        <i
          style="line-height: 1; font-size: 10rem;"
          class=" h-secondary_shade_5 healenticons-plus h-padding-right-s"
        ></i
        >Add Insurance Info Row
      </button>
    </div>
  </div>
</template>

<script>
import settings from '@/settings.js';
import InputField from '@/components/InputField';

export default {
  data() {
    return {
      options: [],
    };
  },

  computed: {
    getInputFieldOptions() {
      const result = this.options.map(option => [option.id, option.name]);
      result.unshift([null, '--- select ---']);
      return result;
    },
  },

  methods: {
    newRow() {
      this.client.insurance_info.push({});
    },

    removeRow(ii) {
      const index = this.client.insurance_info.indexOf(ii);
      this.client.insurance_info.splice(index, 1);
    },
  },

  async mounted() {
    const response = await this.$api.get(`${settings.BACKEND_URL}/api/v-nurse/insurance_info/list`);
    this.options = response.data;
  },

  components: {
    InputField,
  },

  props: {
    client: {
      type: Object,
      required: true,
    },
  },
};
</script>

<template>
  <div>
    <div
      class="
        h-padding-top-l
        h-padding-left-l
        h-padding-right-l
        h-space-between
        h-align-items-center
      "
    >
      <h5 class="h-h3 h-primary_shade_1">Medication Doses</h5>
    </div>
    <div>
      <div v-if="!loading">
        <div
          v-if="Object.keys(response.medication_doses).length"
        >
          <MedicationDosesTable
            :groups="response.groups"
            :medicationDoses="response.medication_doses"
          />
        </div>
        <div
          v-else
          class="
            h-h4
            h-secondary_shade_1
            h-text-center h-padding-top-l h-padding-bottom-l
          "
        >
          {{ !editMode ? "No submitted forms." : "Empty list." }}
        </div>
      </div>
      <Loader v-else :useLoaderClass="false" padding="2.5vw" />
    </div>
  </div>
</template>


<script>
import settings from '@/settings.js';
import Loader from '@/components/Loader';
import MedicationDosesTable from './MedicationDosesTable';

export default {
  data() {
    return {
      loading: true,
      response: null,
      editMode: false,
    };
  },

  async mounted() {
    this.response = await this.$api.get(
      `${settings.BACKEND_URL}/api/v-nurse/medication-doses/get`,
      {
        client_id: this.client.id,
      },
    );
    this.loading = false;
  },

  components: {
    Loader,
    MedicationDosesTable,
  },

  props: ['client'],
};
</script>

export const osardQuestions = [
  {
    sections: [
      {
        section: 'Section 1',
        questions: [
          {
            question:
              '2. Select any medical condition you had in the last 6 months',
            options: [
              {
                id: 'A',
                name: 'Q1',
                section: 0,
                optionText:
                  'Substance use disorder (abuse or dependence)',
                explanatoryText:
                  'Includes opioids, antidepressants,sedatives/anxiolytics, alcohol, amphetamines, cannabis, cocaine, hallucinogens',
                resetSection: false,
                value: 25,
              },
              {
                id: 'B',
                name: 'Q2',
                section: 0,
                optionText: 'Bipolar disorder or schizophrenia',
                explanatoryText: '',
                resetSection: false,
                value: 10,
              },
              {
                id: 'C',
                name: 'Q3',
                section: 0,
                optionText:
                  'Stroke (cerebrovascular accident, CVA) or other cerebrovascular disease',
                explanatoryText: '',
                resetSection: false,
                value: 9,
              },
              {
                id: 'D',
                name: 'Q4',
                section: 0,
                optionText:
                  'Chronic kidney disease with clinically significant renal impairment',
                explanatoryText: '',
                resetSection: false,
                value: 8,
              },
              {
                id: 'E',
                name: 'Q5',
                section: 0,
                optionText: 'Heart failure',
                explanatoryText: '',
                resetSection: false,
                value: 7,
              },
              {
                id: 'F',
                name: 'Q6',
                section: 0,
                optionText:
                  'Non-malignant pancreatic disease (e.g., acute or chronic pancreatitis)',
                explanatoryText: '',
                resetSection: false,
                value: 7,
              },
              {
                id: 'G',
                name: 'Q7',
                section: 0,
                optionText: 'Chronic pulmonary disease',
                explanatoryText:
                  '(e.g., emphysema, chronic bronchitis, asthma, pneumoconiosis, asbestosis)',
                resetSection: false,
                value: 5,
              },
              {
                id: 'H',
                name: 'Q8',
                section: 0,
                optionText: 'Chronic headache (e.g., migraine)',
                explanatoryText: '',
                resetSection: false,
                value: 5,
              },
              {
                id: 'I',
                name: 'Q9',
                section: 0,
                optionText: 'None of the above',
                explanatoryText: '',
                resetSection: true,
                value: 0,
              },
            ],
          },
        ],
      },
      {
        section: 'Section 2',
        questions: [
          {
            question:
              '3. Select which medications do you consume from the following',
            options: [
              {
                id: 'A',
                name: 'Q10',
                section: 1,
                optionText:
                  'Fentanyl (e.g., transdermal or transmucosal immediate-release products)',
                explanatoryText: '',
                resetSection: false,
                value: 13,
              },
              {
                id: 'B',
                name: 'Q11',
                section: 1,
                optionText: 'Morphine',
                explanatoryText: '',
                resetSection: false,
                value: 11,
              },
              {
                id: 'C',
                name: 'Q12',
                section: 1,
                optionText: 'Methadone',
                explanatoryText: '',
                resetSection: false,
                value: 10,
              },
              {
                id: 'D',
                name: 'Q13',
                section: 1,
                optionText: 'Hydromorphone',
                explanatoryText: '',
                resetSection: false,
                value: 7,
              },
              {
                id: 'E',
                name: 'Q14',
                section: 1,
                optionText:
                  'An extended-release or long-acting (ER/LA) formulation of any prescription opioid, including the above',
                explanatoryText: '',
                resetSection: false,
                value: 5,
              },
              {
                id: 'F',
                name: 'Q15',
                section: 1,
                optionText:
                  'Prescription Benzodiazepines or Other Sedating Medications (alprazolam, lorazepam, Diazepam, Gabapentin, Lyrica, Depakote)',
                explanatoryText:
                  'Medications used for anxiety, seizures, nerve pain, and mood stabilizers',
                resetSection: false,
                value: 9,
              },
              {
                id: 'G',
                name: 'Q16',
                section: 1,
                optionText:
                  'A prescription antidepressant (e.g., fluoxetine, citalopram, venlafaxine, amitriptyline)',
                explanatoryText: '',
                resetSection: false,
                value: 8,
              },
              {
                id: 'H',
                name: 'Q17',
                section: 1,
                optionText: 'None of the above',
                explanatoryText: '',
                resetSection: true,
                value: 0,
              },
            ],
          },
        ],
      },
    ],
  },
];


function getOsardOptionByName(name) {
  osardQuestions.forEach((topSection) => {
    topSection.sections.forEach((section) => {
      section.questions.forEach((question) => {
        question.options.forEach((option) => {
          if (option.name === name) {
            return option;
          }
          return null;
        });
      });
    });
  });
}


export const medicationInfo = [
  {
    id: 'A',
    medication: 'Buprenorphine patch',
    quantity: 0,
    strength: 0,
    multiplier: 12.6,
  },
  {
    id: 'B',
    medication: 'Buprenorphine tab or film',
    quantity: 0,
    strength: 0,
    multiplier: 10,
  },
  {
    id: 'C',
    medication: 'Butorphanol (Stadol)',
    quantity: 0,
    strength: 0,
    multiplier: 7,
  },
  {
    id: 'D',
    medication: 'Codeine',
    quantity: 0,
    strength: 0,
    multiplier: 0.15,
  },
  {
    id: 'E',
    medication: 'Fentanyl transdermal (in mcg/hr)',
    quantity: 0,
    strength: 0,
    multiplier: 2.4,
  },
  {
    id: 'F',
    medication: 'Hydrocodone',
    quantity: 0,
    strength: 0,
    multiplier: 1,
  },
  {
    id: 'G',
    medication: 'Hydromorphone',
    quantity: 0,
    strength: 0,
    multiplier: 4,
  },
  {
    id: 'H',
    medication: 'Meperidine',
    quantity: 0,
    strength: 0,
    multiplier: 0.1,
  },
  {
    id: 'I',
    medication: 'Methadone',
    quantity: 0,
    strength: 0,
    multiplier: 1,
  },
  {
    id: 'J',
    medication: 'Morphine',
    quantity: 0,
    strength: 0,
    multiplier: 1,
  },
  {
    id: 'K',
    medication: 'Oxycodone',
    quantity: 0,
    strength: 0,
    multiplier: 1.5,
  },
  {
    id: 'L',
    medication: 'Oxymorphone',
    quantity: 0,
    strength: 0,
    multiplier: 3,
  },
  {
    id: 'M',
    medication: 'Tapentadol IR',
    quantity: 0,
    strength: 0,
    multiplier: 0.4,
  },
  {
    id: 'N',
    medication: 'Tramadol',
    quantity: 0,
    strength: 0,
    multiplier: 0.1,
  },
];


function getMedicationInfoEntryById(id) {
  return medicationInfo.find((medicationInfoEntry) => {
    return medicationInfoEntry.id === id;
  });
}


export function getSummeryValues(surveyAnswers) {
  // osordScore
  let osordScore = 0;
  surveyAnswers.osordSection.forEach((section) => {
    section.sections.forEach((subsection) => {
      subsection.questions.forEach((question) => {
        question.selectedOptions.forEach((optionName) => {
          const option = getOsardOptionByName(optionName);
          osordScore += option.value;
        });
      });
    });
  });

  // MEDD
  let meddScore = 0;
  surveyAnswers.meddSection.forEach((medEntry, index) => {
    const medicationInfoEntry = getMedicationInfoEntryById(medEntry.id);
    let score = (
      parseFloat(medEntry.quantity || 0)
      * parseFloat(medEntry.strength || 0)
      * medicationInfoEntry.multiplier
    );

    if (index === 8) { // first 9 entries
      if (score < 21) {
        score *= 4;
      } else if (score > 20 && score < 41) {
        score *= 8;
      } else if (score > 40 && score < 61) {
        score *= 10;
      } else {
        score *= 12;
      }
    }

    meddScore += score;
  });

  // percScore & adjustments
  let percScore = 0;
  if (meddScore > 50) {
    osordScore += 7;
  }
  if (osordScore > 25 && osordScore < 42) {
    percScore = 55;
  }
  if (osordScore > 41) {
    percScore = 83;
  }

  return {
    osord: osordScore,
    medd: meddScore,
    perc: percScore,
  };
}

export const rpmrtmStratificationQuestions = [
  {
    sections: [
      {
        section: 'Section 0',
        questions: [
          {
            question:
              '2. Does the pt take Lyrica or Gabapentin along with one or more of the following: Diabetes, Heart Disease, Arrhythmia, COPD, Asthma, Sleep Apnea, age over 65, take more than one CNS depressant?',
            options: [
              {
                id: 'A',
                name: 'Q01',
                section: 0,
                optionText: 'Yes',
                explanatoryText: '',
                resetSection: true,
                value: 25,
                groupName: 'Q0',
                shouldTriggerPatientCreation: true,
              },
              {
                id: 'B',
                name: 'Q02',
                section: 0,
                optionText: 'No',
                explanatoryText: '',
                resetSection: true,
                value: 10,
                groupName: 'Q0',
                shouldTriggerPatientCreation: false,
              },
            ],
            selectedAnswer: false,
            program: 'RPM',
          },
        ],
      },
      {
        section: 'Section 1',
        questions: [
          {
            question:
              '3. Does the pt have an intrathecal pain pump with opioid medications or take a long acting opioid or have an MME greater than 50?',
            options: [
              {
                id: 'A',
                name: 'Q1',
                section: 0,
                optionText: 'Yes',
                explanatoryText: '',
                resetSection: true,
                value: 25,
                groupName: 'Q1',
                shouldTriggerPatientCreation: true,
              },
              {
                id: 'B',
                name: 'Q2',
                section: 0,
                optionText: 'No',
                explanatoryText: '',
                resetSection: true,
                value: 10,
                groupName: 'Q1',
                shouldTriggerPatientCreation: false,
              },
            ],
            selectedAnswer: false,
            program: 'RPM',
          },
        ],
      },
      {
        section: 'Section 2',
        questions: [
          {
            question:
              '4. Does the Pt take daily opioid medications for their pain other than intrathecal?',
            options: [
              {
                id: 'A',
                name: 'Q10',
                section: 1,
                optionText: 'Yes',
                explanatoryText: '',
                resetSection: false,
                value: 13,
                groupName: 'Q2',
                shouldTriggerPatientCreation: false,
              },
              {
                id: 'B',
                name: 'Q11',
                section: 1,
                optionText: 'No',
                explanatoryText: '',
                resetSection: false,
                value: 11,
                groupName: 'Q2',
                shouldTriggerPatientCreation: false,
              },
            ],
            selectedAnswer: false,
            program: 'RPM',
          },
        ],
      },
      {
        section: 'Section 3',
        questions: [
          {
            question:
              '5. Does the Pt take more than one mind altering medications such as Benzodiazepines, Neuroleptics, Barbiturates, Anticonvulsants, Sleep Aids, SSRI or CNS Depressants?',
            options: [
              {
                id: 'A',
                name: 'Q20',
                section: 2,
                optionText: 'Yes',
                explanatoryText: '',
                resetSection: false,
                value: 13,
                groupName: 'Q3',
                shouldTriggerPatientCreation: false,
              },
              {
                id: 'B',
                name: 'Q21',
                section: 2,
                optionText: 'No',
                explanatoryText: '',
                resetSection: false,
                value: 11,
                groupName: 'Q3',
                shouldTriggerPatientCreation: false,
              },
            ],
            selectedAnswer: false,
            program: 'RPM',
          },
        ],
      },
      {
        section: 'Section 4',
        questions: [
          {
            question:
              '6. Does the Pt have a history of Chronic Pulmonary Disease, Heart Disease, Stroke, Sleep Apnea, Bipolar Disorder/Schizophrenia/Substance Abuse?',
            options: [
              {
                id: 'A',
                name: 'Q30',
                section: 3,
                optionText: 'Yes',
                explanatoryText: '',
                resetSection: false,
                value: 13,
                groupName: 'Q4',
                shouldTriggerPatientCreation: true,
              },
              {
                id: 'B',
                name: 'Q31',
                section: 3,
                optionText: 'No',
                explanatoryText: '',
                resetSection: false,
                value: 11,
                groupName: 'Q4',
                shouldTriggerPatientCreation: false,
              },
            ],
            selectedAnswer: false,
            program: 'RPM',
          },
        ],
      },
      {
        section: 'Section 5',
        questions: [
          {
            question:
              '7. Does the Pt receive TENS therapy?',
            options: [
              {
                id: 'A',
                name: 'Q050',
                section: 5,
                optionText: 'Yes',
                explanatoryText: '',
                resetSection: false,
                value: 13,
                groupName: 'Q06',
                shouldTriggerPatientCreation: true,
              },
              {
                id: 'B',
                name: 'Q051',
                section: 5,
                optionText: 'No',
                explanatoryText: '',
                resetSection: false,
                value: 11,
                groupName: 'Q06',
                shouldTriggerPatientCreation: false,
              },
            ],
            selectedAnswer: false,
            program: 'RTM',
          },
        ],
      },
      {
        section: 'Section 6',
        questions: [
          {
            question:
              '8. Does the pt have any of the following conditions?',
            options: [
              {
                id: 'A',
                name: 'Q40',
                section: 4,
                optionText: 'Yes',
                explanatoryText: '',
                resetSection: false,
                value: 13,
                groupName: 'Q5',
                shouldTriggerPatientCreation: true,
              },
              {
                id: 'B',
                name: 'Q41',
                section: 4,
                optionText: 'No',
                explanatoryText: '',
                resetSection: false,
                value: 11,
                groupName: 'Q5',
                shouldTriggerPatientCreation: false,
              },
            ],
            selectedAnswer: false,
            program: 'RTM',
          },
        ],
      },
      {
        section: 'Section 7',
        questions: [
          {
            question:
              '9. Does the patient have a spinal cord stimulator?',
            options: [
              {
                id: 'A',
                name: 'Q50',
                section: 5,
                optionText: 'Yes',
                explanatoryText: '',
                resetSection: false,
                value: 13,
                groupName: 'Q6',
                shouldTriggerPatientCreation: true,
              },
              {
                id: 'B',
                name: 'Q51',
                section: 5,
                optionText: 'No',
                explanatoryText: '',
                resetSection: false,
                value: 11,
                groupName: 'Q6',
                shouldTriggerPatientCreation: false,
              },
            ],
            selectedAnswer: false,
            program: 'RTM',
          },
        ],
      },
      {
        section: 'Section 8',
        questions: [
          {
            question:
              '10. Does the pt take oral medications for pain?',
            options: [
              {
                id: 'A',
                name: 'Q60',
                section: 6,
                optionText: 'Yes',
                explanatoryText: '',
                resetSection: false,
                value: 13,
                groupName: 'Q7',
                shouldTriggerPatientCreation: true,
              },
              {
                id: 'B',
                name: 'Q61',
                section: 6,
                optionText: 'No',
                explanatoryText: '',
                resetSection: false,
                value: 11,
                groupName: 'Q7',
                shouldTriggerPatientCreation: false,
              },
            ],
            selectedAnswer: false,
            program: 'RTM',
          },
        ],
      },
      {
        section: 'Section 9',
        questions: [
          {
            question:
              '11. Does the pt require an at home Exercise Regimen?',
            options: [
              {
                id: 'A',
                name: 'Q70',
                section: 7,
                optionText: 'Yes',
                explanatoryText: '',
                resetSection: false,
                value: 13,
                groupName: 'Q8',
                shouldTriggerPatientCreation: true,
              },
              {
                id: 'B',
                name: 'Q71',
                section: 7,
                optionText: 'No',
                explanatoryText: '',
                resetSection: false,
                value: 11,
                groupName: 'Q8',
                shouldTriggerPatientCreation: false,
              },
            ],
            selectedAnswer: false,
            program: 'RTM',
          },
        ],
      },
    ],
    qualifiesForRPM: 'No',
    qualifiesForRTM: 'No',
    medicationEfficiencyQuestionnaireOnceWeekly: 'No',
    medicationComplianceDaily: 'No',
    shouldPhysicalActivityMonitoringAdded: 'No',
    shouldStepCounterBeEnabled: 'Yes',
    careProtocol: 'None',
    selectedCondition: 'None',
  },
];

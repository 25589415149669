var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"wrapper",staticClass:"controls-wrapper"},[(_vm.showInitialControls)?_c('div',[_c('button',{staticClass:"btn btn-success icon-button icon-button-text waves-effect",attrs:{"type":"button","disabled":this.mode === _vm.videoSettings.constants.VIDEO_MODE_JOINING},on:{"click":function($event){return _vm.$emit('joinVideo')}}},[(this.mode === _vm.videoSettings.constants.VIDEO_MODE_INITIAL)?_c('span',[_c('i',{staticClass:"fas fa-phone mr-2"}),_vm._v(" Join Meeting ")]):_c('span',[_vm._v(" Joining ... ")])])]):_vm._e(),(_vm.showInMeetingControls)?_c('div',[(_vm.audioDeviceWorking)?_c('button',{staticClass:"btn icon-button waves-effect",class:{
        'btn-info': !_vm.audioMuted,
        'btn-primary': _vm.audioMuted,
      },attrs:{"type":"button","title":"Enable/disable microphone"},on:{"click":function($event){return _vm.$emit('toggleMicrophone')}}},[_c('i',{staticClass:"fas",class:{
          'fa-microphone-slash': !_vm.audioMuted,
          'fa-microphone': _vm.audioMuted,
        }})]):_c('button',{staticClass:"btn btn-danger icon-button waves-effect btn-outline-dashed tlp tlp-show",attrs:{"type":"button","title":"Microphone not capturing audio","data-placement":"top"},on:{"click":_vm.showAudioProblem}},[_c('i',{staticClass:"fas fa-microphone-slash"})]),_c('button',{staticClass:"btn btn-danger icon-button waves-effect",attrs:{"type":"button","title":"Leave meeting"},on:{"click":function($event){return _vm.$emit('leaveVideo')}}},[_c('i',{staticClass:"fas fa-phone-slash"})]),(_vm.videoDeviceWorking)?_c('button',{staticClass:"btn icon-button waves-effect",class:{
        'btn-info': !_vm.videoMuted,
        'btn-primary': _vm.videoMuted,
      },attrs:{"type":"button","title":"Enable/disable video"},on:{"click":function($event){return _vm.$emit('toggleVideo')}}},[_c('i',{staticClass:"fas",class:{
          'fa-video-slash': !_vm.videoMuted,
          'fa-video': _vm.videoMuted,
        }})]):_c('button',{staticClass:"btn btn-danger icon-button waves-effect btn-outline-dashed",attrs:{"type":"button","title":"Camera not capturing video"},on:{"click":_vm.showVideoProblem}},[_c('i',{staticClass:"fas fa-video-slash"})]),(_vm.showSendCallNotification)?_c('button',{staticClass:"btn btn-success icon-button waves-effect",attrs:{"type":"button","disabled":!_vm.sendNotificationEnabled,"title":"Notify user about meeting (can be sent once in 30 sec)"},on:{"click":_vm.notifyUserAboutCall}},[_c('i',{staticClass:"fas fa-bell"})]):_vm._e()]):_vm._e(),_c('SimpleMessage',{ref:"messageDialog"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div class="h-card">
    <div class="h-row h-space-between">
      <div class="h-card-maxtext">
        <span class="h-h3 h-primary_shade_1">
          <slot name="title">
            {{ title }}
          </slot>
        </span>
        <div>
          <span v-if="loaded" class="h-h2 h-primary_shade_1">
            <slot name="content">
              <span v-if="isContentHtml" v-html="content"></span>
              <span v-else>{{ content }}</span>
            </slot>
          </span>

          <span v-else>
            <div
              class="spinner-border text-primary mr-2"
              role="status"
              :style="{ width: '2.500vw', height: '2.500vw' }"
            ></div>
          </span>
        </div>
      </div>
      <div class="h-card-icon">
        <i :class="iconClass"></i>
      </div>
    </div>
    <!--end card-body-->
  </div>
  <!--end card-->
</template>
<script>
export default {
  name: 'SimpleCardNumber',
  props: {
    title: {
      type: String,
    },
    iconClass: {
      type: String,
    },
    loaded: {
      type: Boolean,
      default: true,
    },
    content: {
      type: String,
    },
    isContentHtml: {
      type: Boolean,
    },
  },
};
</script>

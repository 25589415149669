<template>
  <ul class="nav nav-pills nav-justified" role="tablist">
    <li v-for="option of processedOptions" :key="option[0]" class="nav-item">
      <a
        class="nav-link"
        :class="{ active: value === option[0] }"
        role="tab"
        href="javascript:void(0)"
        @click="select(option)"
      >
        {{ option[1] }}
      </a>
    </li>
  </ul>
</template>

<script>
export default {
  props: {
    options: {
      type: Array,
    },
    value: {
      type: String,
    },
  },

  data() {
    const processedOptions = this.options.map((option) => {
      if (typeof option === 'string') {
        return [option, option];
      }
      if (option.length === 1) {
        return [option[0], option[0]];
      }
      return option;
    });

    return {
      processedOptions,
    };
  },

  methods: {
    select(option) {
      this.$emit('input', option[0]);
    },
  },
};
</script>

<template>
  <div>
    <div class="h-margin-l h-padding-top-l h-bg-w">
      <div class="col-lg-12 h1-table">
        <table ref="table" class="table dataTable fullTable">
          <thead class="thead-light">
          <tr>
            <th>Id</th>
            <th width="">Name</th>
            <th width="20%">Type</th>
            <th style="width: 40%" class="noTooltip">
              <InputField
                class="hdr"
                name="hospitalSelect"
                type="select"
                :choices="hospitalChoices"
                @change="event => onChangeInput(event, 'hospital_id')"
                v-model="selectedHospital"
              />
            </th>
            <th width="5%">Action</th>
          </tr>
          </thead>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import settings from '@/settings';
import InputField from '@/components/InputField';

export default {
  name: 'Forms',
  data() {
    return {
      selectedHospital: 0,
      hospitals: [],
      columnFilters: {
        hospital_id: 0,
      },
    };
  },
  computed: {
    hospitalChoices() {
      const choices = this.hospitals.map(hospital => [hospital.id, hospital.name]);
      choices.unshift([0, 'Facility']);
      return choices;
    },
  },
  methods: {
    addNewForm() {
      this.$router.push({ name: 'NewForm' });
    },
    async getHospitalList() {
      try {
        this.hospitals = await this.$api.getHospitalsList();
      } catch (e) {
        this.hospitals = [];
      }
    },
    onChangeInput(data, fieldName) {
      this.columnFilters[fieldName] = data.selectedOption.value;
      this.dataTable.ajax.reload();
    },
  },
  async mounted() {
    await this.getHospitalList();
    window.execAfterInitMenuInterface(() => {
      window.initActiveMenu('left-navigation-link>settings>staff>all-members');
    });
    const that = this;
    this.dataTable = $(this.$refs.table).DataTable({
      processing: true,
      serverSide: true,
      order: [[0, 'asc']],
      ajax: (data, callback, tableSettings) => {
        this.$api.getForms({
          ...data,
          columnFilters: this.columnFilters,
        }).then((response) => {
          tableSettings.json = response;
          callback(response);
        });
      },
      dom:
        '<"h-row h-space-between h-align-item-center"<"h-show-bt h-margin-left-l header-title"><"h-d-flex h-table-bt" fB>>' +
        '<""<""tr>><"h-row h-space-between h-align-item-center h-padding-bottom-l"lip>',
      buttons: {
        buttons: [
          {
            text: 'Add New Form',
            action(e, dt, node, config) {
              that.addNewForm();
            },
            className: 'h-btn h-btn-accent addnewpatioint AddNewStaffMember',
          },
        ],
        dom: {
          button: {
            className: 'h-btn',
          },
        },
      },
      columns: [
        {
          name: 'id',
          searchable: false,
          orderable: true,
          render: data => (data ? that.$strUtils.escapeHtml(data) : null),
        },
        {
          name: 'name',
          searchable: true,
          orderable: true,
          render: data => (data ? that.$strUtils.escapeHtml(data) : null),
        },
        {
          name: 'type',
          searchable: true,
          orderable: true,
          render: data => (data ? that.$strUtils.escapeHtml(data) : null),
        },
        {
          name: 'hospital__name',
          orderable: false,
          searchable: true,
          render: data => (data ? that.$strUtils.escapeHtml(data) : null),
        },
        {
          name: 'action',
          searchable: false,
          orderable: false,
          render: (data, type, row, meta) => {
            const rowIndex = meta.settings.json.data.indexOf(row);
            const activated = meta.settings.json.metadata[rowIndex].activated;
            if (activated) {
              return '';
            }
            return `
              <div class="text-center">
                <a href="javascript:void(0);" class="EditForm" data-id="${row[0]}">
                  <i class='fas fa-edit text-info font-16' title='Edit'></i>
                </a>
              </div>
            `;
          },
        },
      ],
    });
    $(this.$refs.table).on('click', '.EditForm', async (e) => {
      const id = parseInt(
        $(e.target)
          .closest('a')
          .attr('data-id'),
        10,
      );
      await this.$router.push({
        name: 'FormBuilder',
        params: {
          formId: id,
        },
      });
    });
    if (settings.getInviteRoles().length === 0) {
      $('.AddNewStaffMember').css('display', 'none');
    }
    $(document).ready(() => {
      const sel = $('.h-hospitals').text();
      $('.h-hospitals').html(sel.split(',').join('</br>'));
    });
  },
  components: {
    InputField,
  },
};
</script>

<style scoped>

</style>

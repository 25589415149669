<template>
  <div>
    <!-- START: GLOBAL ROW -->
    <div class="h-padding-l" v-dragscroll.y="false">
      <h3 class="h-h3 h-primary_shade_1 h-padding-left-l h-padding-bottom-l">
        Select hospital:
        <InputField
          type="select"
          :choices="hospitalChoices"
          style="min-width:20em;display:inline-block;"
          v-model="selectedHospitalId"
        />
      </h3>
      <CareProtocolSelector
        ref="careProtocolSelector"
        :protocolId="protocolId"
        :selectedHospitalId="selectedHospitalId"
        :careProtocolChoices="careProtocolChoices"
        :hospitalChoices="hospitalChoices"
        @protocolIdSelected="onProtocolIdSelected"
        @protocolCreated="onProtocolUpdated"
        @protocolIdDeleted="protocolIdDeleted"
      />
      <CareProtocolTabbed
        ref="careProtocolTabbed"
        :protocolId="protocolId"
        :hospitalChoices="hospitalChoices"
        @protocolUpdated="onProtocolUpdated"
      />
    </div>
  </div>
</template>

<script>
import settings from '@/settings.js';
import CareProtocolSelector from './CareProtocolSelector';
import CareProtocolTabbed from '../../settings/care_protocols/CareProtocolTabbed';
import InputField from '@/components/InputField';

export default {
  data() {
    return {
      selectedHospitalId: null,
      protocolId: null,
      hospitalChoices: [],
      careProtocolChoices: [],
    };
  },

  watch: {
    selectedHospitalId(value) {
      this.getHospitalCareProtocols(value);
    },
  },

  methods: {
    async getHospitalList() {
      try {
        const hospitals = await this.$api.getHospitalsList();
        this.hospitalChoices = hospitals.map(h => [h.id, h.name]);
        this.selectedHospitalId = hospitals.length > 0 ? hospitals[0].id : null;
        this.hospitalChoices.unshift([0, '--- global ---']);
      } catch (e) {
      }
    },

    async getHospitalCareProtocols(hospitalId) {
      try {
        const { care_protocols: careProtocols } = await this.$api.getHospitalCareProtocols({
          hospitalID: hospitalId || 0,
          withCareProtocolSpecificIndicator: true,
        });
        this.careProtocolChoices = careProtocols.map(c => [c.id, c.name]);
        this.protocolId = careProtocols.length > 0 ? careProtocols[0].id : null;
        this.onProtocolIdSelected(this.protocolId);
      } catch (e) {
      }
    },

    onProtocolIdSelected(protocolId) {
      this.protocolId = protocolId;
    },

    async onProtocolUpdated(protocol) {
      await this.getHospitalCareProtocols(this.selectedHospitalId);
      this.$refs.careProtocolSelector.onProtocolUpdated(protocol.id);
      await this.$nextTick();
      this.$refs.careProtocolTabbed.reload();
    },

    async protocolIdDeleted(protocolId) {
      const protocolsResponse = await this.$api.getCareProtocols();
      settings.careProtocols = protocolsResponse.care_protocols;
      await this.getHospitalCareProtocols();
    },
  },

  beforeMount() {
    window.execAfterInitMenuInterface(() => {
      window.initActiveMenu('left-navigation-link>hospital>dashboard');
    });
  },

  mounted() {
    this.getHospitalList();
    if (this.$refs.careProtocolTabbed) {
      this.$refs.careProtocolTabbed.reload();
    }
  },

  components: {
    CareProtocolSelector,
    CareProtocolTabbed,
    InputField,
  },
};
</script>

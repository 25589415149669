<template>
  <div class="h-bg-w h-padding-top-l h-padding-bottom-l">
    <h3 class="h-h3 h-primary_shade_1 h-padding-left-l h-margin-bottom-m">{{ 'Assessment' }}</h3>
    <h4 v-if="items.length === 0" class="h-h4 h-secondary_shade_1 h-text-center">
      No assessment assigned
    </h4>
    <div class="h-d-flex">
      <div class="h-w-100 h-d-assessment-items">
        <template v-for="(item, index) in items">
          <div class="h-w50 h-item-border-right" :key="index">
            <div class="h-padding-left-l h-padding-right-l  h-margin-top-s h-margin-bottom-s">
              <AssessmentCard :item="item" :key="index" @viewAssessment="viewAssessment" />
            </div>
          </div>
        </template>
      </div>
    </div>
    <FilteredAssessmentDialog ref="assessmentDialog" :client="client" />
  </div>
</template>

<script>
import AssessmentCard from './components/AssessmentCard';
import FilteredAssessmentDialog from './components/FilteredAssessmentDialog';

export default {
  data() {
    return {
      items: [],
    };
  },

  methods: {
    async getAssessmentsInsights() {
      try {
        const { insights } = await this.$api.getClientAssessmentsInsights({
          client_id: this.client.id,
        });
        this.items = insights;
      } catch (e) {
        // todo error catch and show message
        this.items = [];
      }
    },

    viewAssessment(item) {
      this.$refs.assessmentDialog.show(item);
    },
  },

  async mounted() {
    await this.getAssessmentsInsights();
  },

  components: {
    AssessmentCard,
    FilteredAssessmentDialog,
  },
  props: {
    client: {
      type: Object,
      required: true,
    },
  },
};
</script>

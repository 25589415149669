<template>
  <!-- <SlottedMessage
    ref="slottedMessage"
    title="Assesments"
    :closeDisabled="loading"
    class="content-wrapper dropDownOverflow"
  > -->
  <div class="h-drawer-max500">
    <Drawer :direction="'right'" :exist="true" ref="modalDialog">
      <div class="h-drawer-card">
        <div class="h-drawer-card-header">
          <div class="h-main-drawer-card-header h-margin-bottom-l">
            <h3 class="h-h3 h-primary_shade_1">Assessments</h3>
            <button
              type="button"
              class="close"
              aria-hidden="true"
              @click="close"
              :disabled="loading"
            >
              <!-- <font-awesome-icon
                    :icon="['fas', 'sign-out-alt']"
                    style="height: 1.5vw; width: 1.5vw; color: #c3c4cb"
                  /> -->
              <img src="/assets/images/Collapse-drawer.svg" alt="" />
            </button>
          </div>
        </div>
        <div class="h-drawer-card-body h-padding-0">
          <div v-if="!loading">
            <div v-if="isTemplateMode" class="h-text-right h-padding-l">
              <button type="button" class="h-btn h-btn-accent" @click="addItem">
                <i class="mdi mdi-plus-circle-outline"></i> Add entry
              </button>
            </div>
            <div class="h1-table">
              <table
                class="
                  table
                  dataTable
                  h-noborder-input h-input-w-100 h-pt-table h-asses
                "
              >
                <thead class="thead-light">
                  <tr>
                    <th class="text-center w-1 text-nowrap">
                      Start date
                    </th>
                    <th class="text-center w-1 text-nowrap">
                      <div class="h-d-flex h-align-items-center">
                        <span>Is after</span>
                        <i
                          class="
                          h-font20 h-line-height-1
                          h-bold h-accent healenticons-with-alerts
                          h-cursor-pointer h-padding-left-xs"
                          data-tooltip="Post sergery (post operation)"
                        ></i>
                      </div>
                    </th>
                    <th class="text-center w-1 text-nowrap">
                      Days before/after
                    </th>
                    <th class="text-center">Name</th>
                    <th class="text-center w-1 text-nowrap">
                      Repeat every X days
                    </th>
                    <th v-if="careProtocolMode"></th>
                  </tr>
                </thead>

                <tbody v-for="item of items" :key="item.KEY">
                  <Row
                    :item="item"
                    ref="tableRow"
                    :careProtocolMode="careProtocolMode"
                    @remove="removeItem"
                  />
                </tbody>

                <tbody v-if="items.length === 0">
                  <tr>
                    <td colspan="1000" class="text-center">No entries</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <Loader v-else />
        </div>

        <div class="h-drawer-card-footer">
          <button type="button" class="h-btn h-btn-accent" @click="save" :disabled="loading">
            Save
          </button>

          <button
            type="button"
            class="h-btn h-btn-dangerous h-margin-left-s"
            @click="close"
            :disabled="loading"
          >
            Close
          </button>
        </div>
      </div>
    </Drawer>
  </div>
</template>

<script>
import Loader from '@/components/Loader';
import Row from './Row';

export default {
  data() {
    return {
      loading: false,
      items: [],
      height: 800,
    };
  },

  methods: {
    generateKey() {
      return this.$uuid.v4();
    },

    addItem() {
      this.items.push({
        KEY: this.generateKey(),
      });
    },

    removeItem(item) {
      this.items.splice(this.items.indexOf(item), 1);
      this.$forceUpdate();
    },

    show(items) {
      this.$refs.modalDialog.open();
      this.$refs.modalDialog.active = true;
      this.items = JSON.parse(JSON.stringify(items));
      this.items.forEach((item) => {
        item.KEY = this.generateKey();
      });
      this.loading = false;
      // this.$refs.slottedMessage.show();
    },

    async save() {
      this.loading = true;
      const items = [];
      this.$refs.tableRow.forEach((row) => {
        if (row.itemValue.assesment) {
          items.push(row.itemValue);
        }
      });
      const cpAssesments = (
        await this.$api.saveCpAssesments({
          careProtocolId: this.careProtocolId,
          cpAssesments: items,
        })
      ).cp_assesments;
      this.$emit('save', cpAssesments);
      this.close(true);
    },

    close(force) {
      this.$refs.modalDialog.close();
      this.$refs.modalDialog.active = false;
      // this.$refs.slottedMessage.close(force);
    },
  },

  components: {
    Loader,
    Row,
  },
  computed: {
    myStyles() {
      return {
        height: `${this.height}px`,
      };
    },
  },

  props: {
    careProtocolId: {
      type: Number,
      required: true,
    },
    isTemplateMode: {
      type: Boolean,
      required: false,
    },
    careProtocolMode: {
      type: Boolean,
      required: false,
    },
  },
};
</script>

<template>
  <!-- <SlottedMessage
    ref="slottedMessage"
    modalSize="xl"
    title="Thought Editor"
    :closeDisabled="loading"
  > -->
  <div class="h-drawer-max79">
    <Drawer :direction="'right'" :exist="true" ref="modalDialog">
      <div class="h-drawer-card">
        <div class="h-drawer-card-header">
          <div class="h-main-drawer-card-header h-margin-bottom-l">
            <h3 class="h-h3 h-primary_shade_1">Thought of the Day</h3>
            <button
              type="button"
              class="close"
              aria-hidden="true"
              @click="close"
              :disabled="loading"
            >
              <!-- <font-awesome-icon
                    :icon="['fas', 'sign-out-alt']"
                    style="height: 1.5vw; width: 1.5vw; color: #c3c4cb"
                  /> -->
              <img src="/assets/images/Collapse-drawer.svg" alt="" />
            </button>
          </div>
        </div>
        <div class="h-drawer-card-body">
          <div v-if="!loading">
            <div class="h-margin-bottom-l">
              <label class="h-label">Days before/after</label>
              <InputField
                type="number"
                v-model="item.shift_in_days"
                :step="1"
                :error="errors.shift_in_days"
                @input="
                  () => {
                    errors.shift_in_days = null;
                  }
                "
              />
            </div>

            <div class="h-margin-bottom-l">
              <label class="h-label">Start date type</label>
                <InputField
                  type="select"
                  v-model="item.start_date_type"
                  :choices="[
                    ['START_TYPE_PROTOCOL', 'Care protocol date'],
                    ['START_TYPE_CLIENT_PLAN', 'Client plan start'],
                    ['START_TYPE_SURGERY_DATE', 'Surgery date'],
                  ]"
                />
            </div>

            <div class="h-margin-bottom-l">
              <label class="h-label">Is after</label>
              <InputField
                type="checkbox"
                checkboxSwitchType="success"
                id="editThoughtDialog-postOp"
                v-model="item.post_op"
                style="margin-bottom: 12rem"
              />
              <div
                class="text-muted"
                v-if="item.start_date_type === 'START_TYPE_PROTOCOL'"
              >
                <small>
                  <strong>Note:</strong>
                  When turned off and care protocol start date type is not "Surgery date",
                  thought of the day will be shown on first date regardless of "Days before/after"
                  value.
                </small>
              </div>
            </div>

            <div class="h-margin-bottom-l">
              <label class="h-label">Local reminder time</label>
              <InputField
                type="time"
                id="editThoughtDialog-remind_time"
                v-model="item.remind_time"
                style="margin-bottom: 2rem"
              />
              <div
                class="text-muted"
              >
                <small>
                  <strong>Note:</strong>
                  If empty user daily reminder time (set in user settings in mobile app)
                  will be used.
                </small>
              </div>
            </div>

            <div class="h-margin-bottom-l">
              <label class="h-label">
                Push notification content
              </label>
              <InputField
                type="textarea"
                v-model="item.notification_content"
                :error="errors.notification_content"
                :maxlength="200"
                :rows="5"
                @input="
                  () => {
                    errors.notification_content = null;
                  }
                "
              />
              <div class="h-h6 h-secondary_shade_1">
                Max length: 200 ({{
                  200 - (item.notification_content ? item.notification_content.length : 0)
                }}
                left)
              </div>
            </div>
            <div class="h-margin-bottom-l">
              <label class="h-label">Message content</label>
              <InputField
                type="textarea"
                v-model="item.content"
                :error="errors.content"
                :rows="10"
                :maxlength="400"
                @input="
                  () => {
                    errors.content = null;
                  }
                "
              />
              <div class="h-h6 h-secondary_shade_1">
                Max length: 400 ({{
                  400 - (item.content ? item.content.length : 0)
                }}
                left)
              </div>
            </div>
          </div>
          <Loader v-else />
        </div>

        <div class="h-drawer-card-footer">
          <button
            type="button"
            class="h-btn h-btn-accent"
            @click="save"
            :disabled="loading"
          >
            Save
          </button>

          <button
            type="button"
            class="h-btn h-btn-dangerous h-margin-left-s"
            @click="close"
            :disabled="loading"
          >
            Close
          </button>
        </div>
      </div>
    </Drawer>
  </div>
</template>


<script>
import Loader from '@/components/Loader';
import InputField from '@/components/InputField';

export default {
  data() {
    return {
      loading: false,
      item: this.genEmptyItem(),
      errors: {},
    };
  },
  computed: {
    myStyles() {
      return {
        height: `${this.height}px`,
      };
    },
  },

  methods: {
    genEmptyItem() {
      return {
        start_date_type: 'START_TYPE_PROTOCOL',
        care_protocol_id: this.careProtocolId,
        post_op: true,
        shift_in_days: 0,
        content: '',
        notification_content: '',
      };
    },

    show(item) {
      this.$refs.modalDialog.open();
      this.$refs.modalDialog.active = true;
      this.loading = false;
      if (!item) {
        this.item = this.genEmptyItem();
      } else {
        this.item = { ...item };
      }
      // this.$refs.slottedMessage.show();
    },

    async save() {
      this.loading = true;
      try {
        this.item.shift_in_days = parseInt(this.item.shift_in_days, 10);
      } catch (e) {}
      const response = await this.$api.saveThoughtOfTheDay(
        this.careProtocolId,
        this.doctorId,
        this.item,
      );
      if (response.status === 'error') {
        this.errors = response.errors;
        this.loading = false;
      } else {
        this.$emit('saved', response.thought_of_the_day_list);
        this.close(true);
      }
    },

    close(force) {
      this.$refs.modalDialog.close();
      this.$refs.modalDialog.active = false;
      // this.$refs.slottedMessage.close(force);
    },

    async confirm() {
      this.loading = true;
    },
  },

  components: {
    Loader,
    InputField,
  },

  props: {
    careProtocolId: {
      type: Number,
      required: true,
    },
    doctorId: {
      type: Number,
      required: false,
    },
  },
};
</script>

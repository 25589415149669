<template>
  <main class="login">
    <div class="h-row">
      <div class="h-col-desktop-8 bg-accent h-hide-mobile">
        <div id="login_animation">
          <lottie-animation path="./assets/js/lottie_files/login.json" />
        </div>
      </div>
      <div class="h-col-desktop-4 h-col-mobile-12 h-d-flex">
        <div class="login-card" style="margin-top: 5em">
          <h1 class="h-h1 h-primary_shade_1 h-text-center">Let's Get Started</h1>
          <h3 class="h-h3 h-secondary_shade_1 h-text-center h-margin-top-s">Sign in to continue</h3>

          <div v-if="!loading" class="h-margin-top-l">
            <form class="form-horizontal auth-form">
              <div v-if="mode === MODE_CREDENTIALS">
                <div class="form-group h-row">
                  <div class="h-col-desktop-12 h-col-mobile-12">
                    <label for="email" class="h-label">Email</label>
                    <div class="input-group">
                      <span class="auth-form-icon">
                        <i class="healenticons-emailalt"></i>
                      </span>
                      <input
                        type="text"
                        class="h-input"
                        :class="{ 'is-invalid': errors.email }"
                        id="email"
                        placeholder="Enter email"
                        v-model="email"
                      />
                      <div class="invalid-feedback" v-if="errors.email">
                        <p>{{ errors.email }}</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="form-group h-row">
                  <div class="h-col-desktop-12 h-col-mobile-12 h-margin-top-l">
                    <label for="userpassword" class="h-label">Password</label>
                    <div class="input-group">
                      <span class="auth-form-icon">
                        <i class="dripicons-lock"></i>
                      </span>
                      <input
                        type="password"
                        class="h-input"
                        :class="{ 'is-invalid': errors.password }"
                        id="userpassword"
                        placeholder="Enter password"
                        v-model="password"
                      />
                      <div class="invalid-feedback" v-if="errors.password">
                        <p>{{ errors.password }}</p>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="form-group h-row">
                  <div class="h-col-desktop-12 h-col-mobile-12 h-margin-top-l">
                    <button
                      class="h-btn h-btn-accent h-btn-full"
                      type="button"
                      @click="sendCredentials"
                    >
                      Login
                    </button>
                  </div>
                </div>

                <div class="form-group h-row">
                  <div class="h-col-desktop-12 h-col-mobile-12 h-text-center h-margin-top-l">
                    <router-link :to="{ name: 'RecoverPasswordCognito' }">
                      <span class="h-h6 h-bold h-secondary_shade_1"> Forgot password?</span>
                    </router-link>
                  </div>
                </div>
              </div>

              <div v-if="mode === MODE_CODE">
                <div class="form-group h-row">
                  <div class="h-col-desktop-12 h-col-mobile-12">
                    <div class="text-center h-margin-bottom-l">
                      <img :src="authQrCodeSource" width="200">
                    </div>
                  </div>
                </div>

                <div class="form-group h-row">
                  <div class="h-col-desktop-12 h-col-mobile-12">
                    <label for="code" class="h-label">Authantication code</label>
                    <div class="input-group">
                      <span class="auth-form-icon">
                        <i class="dripicons-lock"></i>
                      </span>
                      <input
                        type="text"
                        class="h-input"
                        :class="{ 'is-invalid': errors.code }"
                        id="code"
                        placeholder="Enter code"
                        v-model="code"
                      />
                      <div class="invalid-feedback" v-if="errors.code">
                        <p>{{ errors.code }}</p>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="form-group h-row">
                  <div class="h-col-desktop-12 h-col-mobile-12 h-margin-top-l">
                    <button class="h-btn h-btn-accent h-btn-full" type="button" @click="sendCode">
                      Enter
                    </button>
                  </div>
                </div>

                <div class="form-group h-row">
                  <div class="h-col-desktop-12 h-col-mobile-12 h-margin-top-l">
                    <button
                      class="h-btn h-btn-dangerous h-btn-full"
                      type="button"
                      @click="returnToCredentials"
                    >
                      Return
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>

          <Loader v-else />
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import settings from '@/settings.js';
import virgilHelper from '@/utils/virgil_helper';
import Loader from './components/Loader';

const MODE_CREDENTIALS = 'credentials';
const MODE_CODE = 'code';

export default {
  name: 'Login',

  data() {
    return {
      MODE_CREDENTIALS,
      MODE_CODE,

      loading: false,
      mode: MODE_CREDENTIALS,

      email: '',
      password: '',
      code: '',

      errors: {},

      authInfo: {},
      authQrCodeSource: null,
    };
  },

  watch: {
    email() {
      this.errors.email = null;
    },

    password() {
      this.errors.password = null;
    },

    code() {
      this.errors.code = null;
    },
  },

  methods: {
    async enter(response) {
      settings.currentSession = response.current_session_data;

      virgilHelper.prepareKeyMaterial(response.current_session_data.user.id.toString());
      await virgilHelper.ensurePublicKey();

      if (settings.currentSession.user.show_no_phone) {
        this.$router
          .push({
            name: 'Profile',
          })
          .catch((err) => {});
      } else if (settings.currentSession.user.email.match('^support')) {
        this.$router
          .push({
            name: 'RPMRTMStratification',
          })
          .catch((err) => {});
      } else if (settings.isBiller()) {
        this.$router
          .push({
            name: 'Documents',
          })
          .catch((err) => {});
      } else {
        this.$router
          .push({
            name: 'index',
          })
          .catch((err) => {});
      }
    },

    async sendCredentials() {
      this.loading = true;

      const result = await this.$api.post(
        `${settings.COGNITO_GATEWAY_URL}/auth/login`,
        null,
        {
          username: this.email,
          password: this.password,
        },
      );

      this.errors = result.errors || {};
      if (result.message) {
        this.loading = false;
        this.errors.email = result.message;
        return;
      }

      this.authInfo = result;
      this.mode = MODE_CODE;

      if (this.authInfo.ChallengeName === 'MFA_SETUP') {
        this.authQrCodeSource = this.authInfo.qr;
      } else if (this.authInfo.ChallengeName === 'NEW_PASSWORD_REQUIRED') {
        const qrResponse = await this.$api.post(
          `${settings.COGNITO_GATEWAY_URL}/auth/change-password`,
          null,
          {
            username: this.email,
            password: this.password,
            session: result.session,
          },
        );

        this.authInfo = qrResponse;
        this.authQrCodeSource = qrResponse.qr;
      }

      this.loading = false;
    },

    returnToCredentials() {
      this.errors = {};
      this.authQrCodeSource = null;
      this.mode = MODE_CREDENTIALS;
      this.code = '';
      this.authInfo = {};
      this.$api.setAuthInfo({});
    },

    async sendCode() {
      this.loading = true;
      if (!this.authQrCodeSource) {
        await this.sendCodeAndLogin();
      } else {
        await this.sendCodeAndRelogin();
      }
    },

    async sendCodeAndRelogin() {
      const response = await this.$api.post(
        `${settings.COGNITO_GATEWAY_URL}/auth/verifymfa`,
        null,
        {
          session: this.authInfo.session,
          mfa_code: this.code,
        },
      );

      this.errors = response.errors || {};
      if (response.message) {
        this.loading = false;
        this.errors.code = response.message;
        return;
      }

      this.returnToCredentials();
      this.loading = false;
    },

    async sendCodeAndLogin() {
      const result = await this.$api.post(
        `${settings.COGNITO_GATEWAY_URL}/auth/mfa`,
        null,
        {
          username: this.authInfo.username,
          session: this.authInfo.session,
          mfa_code: this.code,
        },
      );

      this.errors = result.errors || {};
      if (result.message) {
        this.loading = false;
        this.errors.code = result.message;
        return;
      }

      this.$api.setAuthInfo(result);

      const currentSessionDataResponse = await this.$api.get(
        `${settings.BACKEND_URL}/api/v-nurse/cognito/current-session-data`,
      );

      this.errors = currentSessionDataResponse.errors || {};
      if (currentSessionDataResponse.status === 'error') {
        this.returnToCredentials();
        this.errors = currentSessionDataResponse.errors || {};
        this.loading = false;
        return;
      }

      await this.enter(currentSessionDataResponse);
    },
  },

  components: {
    Loader,
  },
};
</script>

<template>
  <div class="h1-table">
    <h5 class="h-h3 h-primary_shade_1 h-padding-left-l h-padding-top-l">
      Care protocol: {{ client.care_protocol.name }}
    </h5>

    <table class="table dataTable h-table-w-space h-table-PatientCareProtocol">
      <thead class="thead-light">
        <tr>
          <th style="width: 1%"></th>
          <th style="width:55%">
            <span class="table-title-bold">Item</span>
          </th>
          <th style="width: 5%">
            <span class="table-title-bold"> Status </span>
          </th>
          <th style="width: 15%" class="h-text-right">
            <span class="table-title-bold"> Start Date </span>
          </th>
          <th style="width: 15%" class="h-text-right">
            <span class="table-title-bold"> Due Date </span>
          </th>
          <th style="width: 10%" class="text-left">
            <span class="table-title-bold"> Action </span>
          </th>
        </tr>
      </thead>
      <tbody v-for="item of availableCareProtocolItems" :key="item.id">
        <tr>
          <td>
            <i
              class="font-16 expand-row"
              :class="{
                'fas fa-plus-square text-success': !item.EXPANDED,
                'fas fa-minus-square text-danger': item.EXPANDED
              }"
              title="Expand/collapse"
              @click="toggleCareProtocolExpanded(item)"
            ></i>
          </td>
          <td>
            <TruncateText :data-tooltip="item.name" :text="item.name ? item.name : ''" />
          </td>
          <td>
            <span
              class="badge"
              :class="`badge-soft-${getStatusBadgeClass(item)}`"
              :title="
                item.status_change_datetime ? `Updated at ${item.status_change_datetime}` : null
              "
            >
              {{ getStatusText(item) }}
            </span>
          </td>
          <td class="h-text-right">
            {{ item_start_date(item) | formatDate }}
          </td>
          <td class="h-text-right">
            {{ item_due_date(item) | formatDate }}
          </td>
          <td class="text-left">
            <div>
              <a
                href="javascript:void(0);"
                @click="
                  () => {
                    $refs.editItemDialog.show(item)
                  }
                "
              >
                <i class="fas fa-pen font-16 mr-3 cursor-pointer" title="Edit"></i>
              </a>
            </div>
          </td>
        </tr>

        <tr v-if="item.EXPANDED" class="careProtocol-educationRow">
          <td colspan="100">
            <div class="text-center">
              <strong>
                <h4 class="h-padding-top-m h-padding-bottom-m" style="text-wrap:pretty ;">
                  Education: {{ item.name }}
                </h4>
              </strong>
            </div>
            <EducationList
              :collectionId="item.learn_collection_id"
              :readOnly="true"
              :dense="true"
            />
          </td>
        </tr>
      </tbody>

      <tbody v-if="availableCareProtocolItems.length === 0">
        <tr>
          <td colspan="100" class="text-center">No entries</td>
        </tr>
      </tbody>
    </table>

    <EditItemDialog ref="editItemDialog" @updated="emitItemUpdated" />
  </div>
</template>

<script>
import moment from 'moment-timezone';
import EditItemDialog from './EditItemDialog';
import EducationList from '@/components/education/education_list/EducationList';
import TruncateText from '@/components/TruncateText';

export default {
  props: ['client'],
  filters: {
    formatDate(date) {
      if (!date) {
        return 'No data';
      }
      return moment(date).format('MMM D, YYYY');
    },
  },
  methods: {
    getStatusBadgeClass(item) {
      return (
        {
          Done: 'success',
          'In Progress': 'warning',
          'Not Started': 'info',
          Delayed: 'danger',
          'Follow-up Required': 'danger',
        }[this.getStatusText(item)] || 'info'
      );
    },

    getStatusText(item) {
      if (!item.due_datetime) {
        return 'Due date not set';
      }
      return item.status;
    },

    emitItemUpdated(items) {
      items.forEach((item) => {
        const matchItem = this.client.care_protocol_items.find((thisItem) => {
          return thisItem.id === item.id;
        });
        console.log('rtr', matchItem);

        if (matchItem) {
          item.EXPANDED = matchItem.EXPANDED;
        }
      });
      this.client.care_protocol_items = items;
      this.$forceUpdate();
    },

    item_start_date(item) {
      if (!item.start_datetime) return null;
      return item.start_datetime.split(' ')[0];
    },

    item_due_date(item) {
      if (!item.due_datetime) return null;
      return item.due_datetime.split(' ')[0];
    },

    toggleCareProtocolExpanded(entry) {
      entry.EXPANDED = !entry.EXPANDED;
      this.$forceUpdate();
    },
  },

  computed: {
    availableCareProtocolItems() {
      return this.client.care_protocol_items.filter((item) => {
        return item.enabled_for_surgeon;
      });
    },
  },

  components: {
    EditItemDialog,
    EducationList,
    TruncateText,
  },
};
</script>

import Vue from 'vue';
import UUID from 'vue-uuid';

import CKEditor from '@ckeditor/ckeditor5-vue2';
import VueDragscroll from 'vue-dragscroll';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faUserSecret,
  faTachometerAlt,
  faUsers,
  faComments,
  faFileAlt,
  faCog,
  faSignOutAlt,
  faArrowCircleLeft,
  faGripHorizontal,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import VueConfirmDialog from 'vue-confirm-dialog';
import BlockUI from 'vue-blockui';
import VuePhoneNumberInput from 'vue-phone-number-input';
import 'vue-phone-number-input/dist/vue-phone-number-input.css';

import LottieAnimation from 'lottie-vuejs'; // import lottie-vuejs
import Cleave from 'cleave.js';
import Drawer from '@/components/Drawer/Drawer';
import ProfileDrawer from '@/components/Drawer/ProfileDrawer';
import Breadcrums from '@/plugins/breadcrums';
import NotificationBar from '@/plugins/notificationBar';
import GlobalCon from '@/plugins/global_con';
import StrUtils from '@/plugins/str_utils';
import DateUtils from '@/plugins/date_utils';
import App from './App';
import API from '@/plugins/api';
import router from './router';
import './index.css';
// eslint-disable-next-line import/order
import { FormBuilder } from 'vue-formio';

Vue.directive('cleave', {
  inserted: (el, binding) => {
    el.cleave = new Cleave(el, binding.value || {});
  },
  update: (el) => {
    const event = new Event('input', { bubbles: true });
    el.dataset.cleaveTimeout = setTimeout(() => {
      el.value = el.cleave.properties.result;
      el.dispatchEvent(event);
    }, 100);
  },
  unbind: (el) => {
    if (el.dataset.cleaveTimeout) {
      clearTimeout(el.dataset.cleaveTimeout);
      delete el.dataset.cleaveTimeout;
    }
  },
});
Vue.use(LottieAnimation); // add lottie-animation to your global scope

library.add(
  faUserSecret,
  faTachometerAlt,
  faUsers,
  faComments,
  faFileAlt,
  faCog,
  faSignOutAlt,
  faArrowCircleLeft,
  faGripHorizontal,
);

Vue.component('font-awesome-icon', FontAwesomeIcon);
Vue.component('vue-phone-number-input', VuePhoneNumberInput);

Vue.use(VueConfirmDialog);
Vue.component('vue-confirm-dialog', VueConfirmDialog.default);

Vue.config.productionTip = false;

Vue.use(API);
Vue.use(DateUtils);
Vue.use(StrUtils);
Vue.use(UUID);
Vue.use(GlobalCon);
Vue.use(NotificationBar);
Vue.use(Breadcrums);
Vue.use(CKEditor);
Vue.use(BlockUI);
Vue.component('formbuilder', FormBuilder);
Vue.component('Drawer', Drawer);
Vue.component('ProfileDrawer', ProfileDrawer);
Vue.use(VueDragscroll);

new Vue({
  router,
  render: h => h(App),
}).$mount('#app');

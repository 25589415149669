<template>
  <div class="h-drawer-max79">
    <SlottedMessageInDrawer ref="slottedMessage" title="Send to EMR">
      <template v-slot:modal-body>
        <label v-if="!loading" style="font-weight: normal">
          <div v-if="rowMetadata.name">
            Send all "<strong>{{ rowMetadata.name }}</strong
            >" reports to EMR?
          </div>
          <div v-else>
            Send all reports to EMR?
            <!-- <br />
            (already schedules or pushed reports will not be overwritten,
            use individual push buttons to overwrite) -->
          </div>
          <br />
          <div v-if="rowMetadata.hospital__name">
            <strong>Hospital:</strong> {{ rowMetadata.hospital__name }}
            <br />
          </div>
          <strong>Date range:</strong>
          {{ rowMetadata.report.date_start }}...{{
            rowMetadata.report.date_end
          }}
        </label>
        <Loader v-if="loading" />
      </template>

      <template v-slot:modal-footer>
        <button
          type="button"
          class="h-btn h-btn-accent"
          @click="confirm"
          :disabled="loading"
        >
          Confirm
        </button>
        <button
          type="button"
          class="h-btn h-btn-dangerous h-margin-left-s"
          @click="close"
          :disabled="loading"
        >
          Cancel
        </button>
      </template>
    </SlottedMessageInDrawer>
  </div>
</template>


<style lang="stylus" scoped>
.modal-dialog {
  max-width: 1200px;
}
</style>


<script>
import settings from '@/settings.js';
import SlottedMessageInDrawer from '@/components/dialogs/SlottedMessageInDrawer';
import Loader from '@/components/Loader';

export default {
  data() {
    return {
      loading: true,
      rowMetadata: null,
    };
  },
  methods: {
    show(rowMetadata) {
      this.loading = false;
      this.rowMetadata = rowMetadata;
      this.$refs.slottedMessage.show();
    },

    close() {
      this.$refs.slottedMessage.close();
      this.$refs.slottedMessage.active = false;
    },

    async confirm() {
      this.confirmHospitalReport();
    },

    async confirmHospitalReport() {
      this.loading = true;

      let response;
      response = await this.$api.post(
        `${settings.BACKEND_URL}/api/v-nurse/client/all/send_hospital_reports_to_emr`,
        null,
        {
          hospitalId: this.rowMetadata.hospitalId,
          reportDateStart: this.rowMetadata.report.date_start,
          reportDateEnd: this.rowMetadata.report.date_end,
          reportCode: this.rowMetadata.reportCode || 'hospital',
        },
      );
      if (response.status === 'ok') {
        if (response.job_id) {
          $.notify('Reports were being sent to EMR in the background.', {
            position: 'top center',
            className: 'info',
          });
          await this.pollJobStatus(response.job_id);
        } else {
          $.notify('Request returned with no job id', {
            position: 'top center',
            className: 'error',
          });
        }
      } else {
        $.notify('Send to EMR failed', {
          position: 'top center',
          className: 'error',
        });
      }

      // if (response.status !== 'ok' || response.result === false) {
      //   $.notify(response.message, {
      //     position: 'top center',
      //     className: 'error',
      //   });
      // } else {
      //   if (this.rowMetadata.id) {
      //     this.$emit('reportPushStatusChanged', this.rowMetadata);
      //   } else {
      //     this.$emit('allReportsPushStatusChanged');
      //   }
      //   $.notify('Sending all reports in progress', {
      //     position: 'top center',
      //     className: 'success',
      //   });
      // }
      this.close();
    },
    async pollJobStatus(jobId) {
      const interval = setInterval(async () => {
        try {
          const statusResponse = await this.$api.get(
            `${settings.BACKEND_URL}/api/v-nurse/send-to-emr/job-status`,
            {
              job_id: jobId,
            },
          );
          if (statusResponse.status === 'done') {
            clearInterval(interval);
            if (this.rowMetadata.id) {
              this.$emit('reportPushStatusChanged', this.rowMetadata);
            } else {
              this.$emit('allReportsPushStatusChanged');
            }
            $.notify('Reports were successfully sent to EMR.', {
              position: 'top center',
              className: 'success',
            });
          } else if (statusResponse.status === 'FAILED') {
            clearInterval(interval);
            $.notify('There was an error sending the reports to EMR', {
              position: 'top center',
              className: 'error',
            });
          }
        } catch (error) {
          console.error('Error:', error);
          clearInterval(interval);
          $.notify('Error fetching job status.', {
            position: 'top center',
            className: 'error',
          });
        }
      }, 30000); // Poll every 30 seconds
    },
  },

  components: {
    SlottedMessageInDrawer,
    Loader,
  },
};
</script>

import moment from 'moment-timezone';


class DateUtils {
  utcOffsetMinutes = (new Date()).getTimezoneOffset();

  moment = moment;

  toUtcDate(date) {
    return moment(date).utc().format('YYYY-MM-DD');
  }

  toUtcTime(date, format = 'hh:mm A') {
    return moment(date).utc().format(format);
  }

  toUtcDatetime(date) {
    return moment(date).utc().format('YYYY-MM-DD HH:mm:ss');
  }

  toLocalDate(date) {
    if (!date) return '-';
    return moment.utc(date).local().format('YYYY-MM-DD');
  }

  toLocalTime(date, format = 'hh:mm A') {
    return moment.utc(date).local().format(format);
  }

  toLocalDatetime(date) {
    if (!date) return '-';
    return moment.utc(date).local().format('YYYY-MM-DD HH:mm:ss');
  }

  getCurrentLocalDatetime() {
    return moment();
  }

  moveTimeToDateStart(date) {
    return moment(moment(date).format('YYYY-MM-DD')).format('YYYY-MM-DD HH:mm:ss');
  }

  moveTimeToDateEnd(date) {
    return moment(
      `${moment(date).format('YYYY-MM-DD')} 23:59:59`,
    ).format('YYYY-MM-DD HH:mm:ss');
  }

  formatDate(date) {
    return date ? date.format('YYYY-MM-DD') : '';
  }
}

export default {
  install: (Vue) => {
    // eslint-disable-next-line
    Vue.prototype.$dateUtils = new DateUtils();
  },
};

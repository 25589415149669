<template>
  <div class="h-drawer-max79">
    <Drawer :direction="'right'" :exist="true" ref="modalDialog">
      <div class="h-drawer-card">
        <div class="h-drawer-card-header">
          <div class="h-main-drawer-card-header h-margin-bottom-l">
            <h3 class="h-h3 h-primary_shade_1">
              <slot name="modal-title">Delete care protocol</slot>
            </h3>
            <button
              type="button"
              class="close"
              aria-hidden="true"
              @click="close"
              :disabled="loading"
            >
              <img src="/assets/images/Collapse-drawer.svg" alt="" />
            </button>
          </div>
        </div>
        <div class="h-drawer-card-body">
          <div v-if="!loading">
            <label v-if="!this.error">
              Do you really want to delete the care protocol?
              <div v-if="hospitalId === 0" class="h-dangerous">
                Deleting global protocol will removed this protocol from all hospitals.
              </div>
            </label>

            <label v-else class="text-danger">
              ERROR: {{ this.error }}
            </label>
          </div>

          <Loader v-else />
        </div>

        <div class="h-drawer-card-footer">
          <button
            type="button"
            class="h-btn h-btn-dangerous"
            @click="confirm"
            :disabled="loading"
          >
            Delete
          </button>

          <button
            type="button"
            class="h-btn h-btn-accent h-margin-left-s"
            @click="close"
            :disabled="loading"
          >
            Cancel
          </button>
        </div>
      </div>
    </Drawer>
  </div>
</template>


<script>
import settings from '@/settings.js';
import Loader from '@/components/Loader';

export default {
  data() {
    return {
      loading: false,
      protocolId: null,
      hospitalId: null,
      error: '',
    };
  },

  methods: {
    show(protocolId, hospitalId) {
      this.error = '';
      this.protocolId = parseInt(protocolId, 10);
      this.hospitalId = parseInt(hospitalId, 10);
      this.$refs.modalDialog.open();
      this.$refs.modalDialog.active = true;
      this.loading = false;
      $(this.$refs.modalDialog.el).modal({
        backdrop: 'static',
        keyboard: false,
      });
      $('.modal-backdrop').last().insertBefore(this.$refs.modalDialog.el);
    },

    close(force) {
      this.$refs.modalDialog.close();
      this.$refs.modalDialog.active = false;
      if (!force && this.loading) return;
      $(this.$refs.modalDialog.el).modal('hide');
    },

    async confirm() {
      this.loading = true;
      const response = await this.$api.post(
        `${settings.BACKEND_URL}/api/v-nurse/care-protocols/delete`,
        null, {
          protocol_id: this.protocolId,
          hospital_id: this.hospitalId,
        },
      );
      if (response.status === 'error') {
        this.loading = false;
        this.error = response.message;
      } else {
        this.$emit('protocolIdDeleted', this.protocolId);
        this.close(true);
      }
    },
  },

  components: {
    Loader,
  },
};
</script>

<template>
  <div>
    <div v-for="modalData of modalDataList" :key="modalData.KEY">
      <!-- simple message -->
      <SlottedMessage
        ref="modal"
        v-if="modalData.type === 'simple'"
        v-bind="modalData"
        @closed="modalClosed(modalData)"
        :showOnMount="true"
      >
        <template v-slot:modal-body>
          <div v-if="modalData.message" class="pre-wrap">{{ modalData.message }}</div>
          <span v-else class="text-muted">&lt;No message&gt;</span>
        </template>
      </SlottedMessage>

      <!-- call -->
      <SlottedMessage
        ref="modal"
        v-if="modalData.type === 'call'"
        v-bind="modalData"
        modalSize="md"
        title="Incoming call..."
        @closed="modalClosed(modalData)"
        :showOnMount="true"
      >
        <template v-slot:modal-body>
          <div class="text-center">
            <h3 class="mt-0">Incoming call...</h3>
            <img
              :src="modalData.from_user.avatar || '/assets/images/users/user-8.jpg'"
              alt="User"
              class="rounded-circle thumb-xl align-self-center mt-3 mb-4"
            >
            <br />
            <strong class="calling-name">
              {{ modalData.from_user.name }}
            </strong>
          </div>
        </template>

        <template v-slot:modal-footer>
          <div class="footer-wrapper">
            <button
              type="button"
              class="btn btn-lg btn-success waves-effect"
              @click="goToCall(modalData)"
            >
              Go to call
            </button>

            <button
              type="button"
              class="btn btn-lg btn-danger waves-effect"
              @click="closeModalByKey(modalData.KEY)"
            >
              Cancel
            </button>
          </div>
        </template>

      </SlottedMessage>
    </div>
  </div>
</template>


<style lang="stylus" scoped>
.calling-name
  font-size 120%

.footer-wrapper
  width 100%
  text-align center

  button
    margin-left 0.8em
    margin-right 0.8em
    min-width 10em
</style>


<script>
import SlottedMessage from '@/components/dialogs/SlottedMessage';

let lastId = 0;
export default {
  data() {
    return {
      modalDataList: [],
    };
  },

  methods: {
    modalClosed(modalData) {
      this.modalDataList = this.modalDataList.filter((modelDataListEntry) => {
        return modelDataListEntry !== modalData;
      });
    },

    addModal(modalData) {
      lastId += 1;
      modalData.KEY = `ModalMessageStack-component:${lastId}`;
      if (!modalData.type) {
        modalData.type = 'simple';
      }
      this.modalDataList = [...this.modalDataList, modalData];
    },

    getModalByKey(key) {
      return this.$refs.modal.find((modal) => {
        return modal.$attrs.KEY === key;
      });
    },

    closeModalByKey(key) {
      const modal = this.getModalByKey(key);
      if (modal) {
        modal.close();
      }
    },

    goToCall(modalData) {
      window.open(modalData.url, '_blank');
      this.closeModalByKey(modalData.KEY);
    },
  },

  mounted() {
    this.$globalCon.setModalMessageStack(this);
  },

  destroyed() {
    this.$globalCon.setModalMessageStack(null);
  },

  components: {
    SlottedMessage,
  },
};
</script>

<template>
  <div class="content-wrapper" :class="{ dropDownOverflow: dropDownOverflow }">
    <!-- <div v-if="!loading"> -->
    <div>
      <div class="h1-table">
        <table class="table dataTable h-noborder-input h-input-w-100 h-pt-table h-table-pt">
          <thead class="thead-light">
            <tr v-if="simple">
              <th colspan="1000">Therapy PT</th>
            </tr>
            <tr>
              <th style="width: 2.5%" v-if="allowExpand"></th>
              <th class="h-text-right" style="width: 5%">
                <div class="h-d-flex h-align-items-center">
                  <span>SDA</span>
                  <i
                    class="
                    h-font20 h-line-height-1
                    h-bold h-accent healenticons-with-alerts
                    h-cursor-pointer h-padding-left-xs"
                    data-tooltip="Start day after"
                  ></i>
                </div>
              </th>
              <th class="h-text-right" style="width: 5%">
                <div class="h-d-flex h-align-items-center">
                  <span>EDA</span>
                  <i
                    class="
                    h-font20 h-line-height-1
                    h-bold h-accent healenticons-with-alerts
                    h-cursor-pointer h-padding-left-xs"
                    data-tooltip="End day after"
                  ></i>
                </div>
              </th>
              <th class="h-text-left" style="width: 20%">Category</th>
              <th class="h-text-left" style="width: 25%">Title</th>
              <th class="h-text-right" style="width: 5%">Sets</th>
              <th class="h-text-right" style="width: 5%">Hold</th>
              <th class="h-text-right" style="width: 5%">Reps.</th>
              <th class="h-text-left" style="width: 5%">Side</th>
              <th class="h-text-right" style="width: 5%">Times per day</th>
              <th class="h-text-right" style="width: 5%">Every X days</th>
              <th style="width: 2.5%"></th>
            </tr>
          </thead>
          <tbody v-for="entry of entries" :key="entry.KEY">
            <TherapyRow
              :ref="'mainRow:' + entry.KEY"
              :entry="entry"
              :physicalPTs="physicalPts"
              :allowExpand="allowExpand"
              @remove="removeEntry"
              @toggleExpand="toggleExpandEntry"
            />
            <TherapyRowInfo
              :ref="'infoRow:' + entry.KEY"
              :class="{ 'd-none': !entry.EXPANDED }"
              :careProtocolMode="careProtocolMode"
              :therapyPt="entry.therapy_pt"
              @setTherapyPtFieldValue="setTherapyPtFieldValue"
            />
          </tbody>

          <tbody v-if="entries.length === 0">
            <tr>
              <td colspan="1000" class="text-center h-h4 h-secondary_shade_1">
                No entries
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<style lang="stylus" scoped></style>

<script>
import TherapyRow from './components/TherapyRow';
import TherapyRowInfo from './components/TherapyRowInfo';

export default {
  props: {
    clientId: {
      type: Number,
      required: false,
    },
    careProtocolId: {
      type: Number,
      required: false,
    },
    initialEntries: {
      type: Array,
      default: () => {
        return null;
      },
    },
    simple: {
      type: Boolean,
      default: true,
    },
    allowExpand: {
      type: Boolean,
      default: true,
    },
    dropDownOverflow: {
      type: Boolean,
      default: false,
    },
    careProtocolMode: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      loading: true,
      entries: [],
      physicalPts: [],
    };
  },

  watch: {
    initialEntries() {
      this.setEntries(this.initialEntries);
    },
  },

  methods: {
    generateKey() {
      return this.$uuid.v4();
    },

    addEntry() {
      this.entries.push({
        KEY: this.generateKey(),
        EXPANDED: false,
        start_days_after: 0,
        end_days_after: 0,
        times_per_day: 1,
        every_x_days: 1,
        therapy_pt: {},
      });
      this.$forceUpdate();
    },

    removeEntry(entry) {
      this.entries.splice(this.entries.indexOf(entry), 1);
      this.$forceUpdate();
    },

    toggleExpandEntry(entry) {
      entry.EXPANDED = !entry.EXPANDED;
      this.$forceUpdate();
    },

    setTherapyPtFieldValue(therapyPt, fieldName, value) {
      const parts = fieldName.split('.');
      const lastPart = parts[parts.length - 1];
      parts.pop();
      let cursor = therapyPt;

      parts.forEach((key) => {
        if (therapyPt[key] === undefined) {
          therapyPt[key] = {};
        }
        cursor = therapyPt[key];
      });
      cursor[lastPart] = value;

      this.entries.forEach((entry) => {
        this.$refs[`infoRow:${entry.KEY}`][0].$forceUpdate();
      });
    },

    async save() {
      this.loading = true;
      const response = await this.$api.setTherapyPtEntries(
        {
          clientId: this.clientId,
          careProtocolId: this.careProtocolId,
        },
        {
          therapy_pt_entries: this.entries,
        },
      );
      this.$emit('entriesSaved', this.entries, response.therapy_pt_entries);
      this.loading = false;
    },

    async reloadEntries() {
      this.loading = true;
      this.setEntries(
        (
          await this.$api.getTherapyPtEntries({
            clientId: this.clientId,
            careProtocolId: this.careProtocolId,
          })
        ).therapy_pt_entries,
      );
      this.loading = false;
    },

    setEntries(entries) {
      this.entries = JSON.parse(JSON.stringify(entries));
      const therapyPtRegistry = {};

      this.entries.forEach((entry) => {
        entry.KEY = this.generateKey();
        entry.EXPANDED = false;

        if (entry.therapy_pt) {
          therapyPtRegistry[entry.therapy_pt.id] = entry.therapy_pt;
        }
      });

      this.entries.forEach((entry) => {
        if (entry.therapy_pt) {
          entry.therapy_pt = therapyPtRegistry[entry.therapy_pt.id];
        }
      });
    },

    async getPhysicalPTs() {
      try {
        const res = await this.$api.getTherapyPtOptions({});
        this.physicalPts = res.therapy_pt_options;
      } catch (e) {
        this.physicalPts = [];
      }
    },
  },

  async mounted() {
    if (this.initialEntries === null) {
      await this.reloadEntries();
    } else {
      this.setEntries(this.initialEntries);
      this.loading = false;
    }
    await this.getPhysicalPTs();
  },

  components: {
    TherapyRow,
    TherapyRowInfo,
  },
};
</script>

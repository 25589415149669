<template>
  <div>
    <!-- Page-Title -->
    <div class="h-add-patient h-mobile-padding-left-l h-mobile-padding-right-l">
      <div class="h-col-mobile-12 h-margin-auto">
        <div>
          <div :class="{ 'd-none': loading }">
            <form class="h-form-btn">
              <div id="EditPatient">
                <div class="step-content">
                  <div class="step-tab-panel" data-step="step1">
                    <section>
                      <div class="h-row">
                        <div class="w-100 h-col-mobile-12 h-margin-bottom-l">
                          <label class="h-label"> Status: </label>
                          <InputField
                            type="select"
                            v-model="if_inactive_reason_id"
                            :choices="inactivePatientReasons" />
                        </div>

                        <div class="w-100 h-col-mobile-12 h-margin-bottom-l">
                          <label class="h-label"> Consent: </label>
                          <InputField
                            type="select"
                            v-model="consent_id"
                            :choices="consent" />
                        </div>

                        <div class="w-100 h-col-mobile-12 h-margin-bottom-l">
                          <label class="h-label">
                            Comments
                          </label>
                          <InputField
                            type="textarea"
                            v-model="client.outstanding_issues_or_concerns"
                            :error="errors.outstanding_issues_or_concerns"
                            @input="() => {
                            errors.outstanding_issues_or_concerns = null;
                          }" />
                        </div>
                      </div>
                    </section>
                  </div>
                </div>
                <div class="h-step-fix-footer">
                  <div class="h-col-mobile-12 h-margin-auto h-btn-container">
                    <div class="h-text-right">
                      <button type="button" class="h-btn h-btn-accent" @click="save">
                        Save
                      </button>
                      <button type="button" class="h-btn h-btn-dangerous h-margin-left-s" @click="cancel" v-if="this.client.id">
                        Close
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>

          <div v-if="loading" class="card-body">
            <Loader />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style>
.has-error {
  border-color: red;
}
</style>
<script>
import Loader from '@/components/Loader';
import InputField from '@/components/InputField';
import eventBus from '../../../event-bus';

export default {
  name: 'EditPatient',
  props: {
    defaultClient: {
      type: Object,
      required: true,
    },
  },
  data() {
    const clientCopy = JSON.parse(JSON.stringify(this.defaultClient));
    return {
      client: clientCopy,
      errors: {},
      formWasSaved: null,
      loading: true,
      if_inactive_reason_id: null, // Ensure this property is defined
      inactivePatientReasons: [[null, '']],
      consent_id: null,
      consent: [[null, ''], [1, 'consent1'], [2, 'consent2'], [3, 'consent3'], [4, 'consent4']],
    };
  },

  methods: {
    async save() {
      if (this.if_inactive_reason_id === 0) {
        $.notify('Please select a reason why this patient is set to inactive', {
          position: 'top center',
          className: 'error',
        });
        return;
      }
      this.loading = true;
      this.errors = {};
      this.formWasSaved = null;
      const if_inactive_reason_id = this.if_inactive_reason_id;
      const outstanding_issues_or_concerns = this.client.outstanding_issues_or_concerns;
      const assignedNurseId = this.client.assigned_nurse.id;
      const careProtocolId = this.client.care_protocol.id;
      const surgeonId = this.client.surgeon.id;
      const email = this.client.email ? this.client.email.trim() : '';

      const payload = {
        ...this.client,
        email,
        assigned_nurse_id: assignedNurseId,
        care_protocol_id: careProtocolId,
        surgeon_id: surgeonId,
        if_inactive_reason_id,
        outstanding_issues_or_concerns,
      };

      const response = await this.$api.upsertClient(payload);

      if (response.status === 'ok') {
        $.notify('Status successfully updated.', {
          position: 'top center',
          className: 'success',
        });
        this.cancel();
      } else {
        this.loading = false;
        $.notify('Failed to update status.', {
          position: 'top center',
          className: 'error',
        });
        console.error('Error updating Status:', response.errors);
      }
    },

    cancel() {
      eventBus.$emit('CloseEditStatus', {});
    },

    setClient(loadedClient) {
      this.client = loadedClient;
      if (this.client.email) {
        if (this.client.email.indexOf('@nomail.healent.com') !== -1) {
          this.client.email = '';
        }
      }
      if (this.client.if_inactive_reason_id) {
        this.if_inactive_reason_id = this.client.if_inactive_reason_id;
      }
    },
  },

  async mounted() {
    const response = await this.$api.getClientInactiveReasons();
    response.inactive_reasons.forEach((entry) => {
      const row = [entry.id, entry.reason_description];
      this.inactivePatientReasons.push(row);
    });

    this.setClient(this.defaultClient);
    this.loading = false;
  },

  components: {
    Loader,
    InputField,
  },
};
</script>

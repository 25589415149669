<template>
  <div class="h-drawer-max79 MedicationUseEntry">
    <SlottedMessageInDrawer ref="slottedMessage" title="Medication use entry">
      <template v-slot:modal-body>
        <div v-if="localEntry" :class="{ 'd-none': loading }">
          <div class="h-margin-bottom-l h-margin-bottom-m">
            <label class="h-label">Medication type: </label>
            <InputField
              type="select"
              v-model="localEntry.medication_type"
              :error="errors.medication_type"
              @change="errors.medication_type = null"
              :choices="medicationTypes"
            />
          </div>

          <div class="h-margin-bottom-l h-margin-bottom-m">
            <label class="h-label">Name: </label>
            <InputField
              type="text"
              v-model="localEntry.name"
              :error="errors.name"
              @change="errors.name = null"
            />
          </div>

          <!-- <div
            class="h-margin-bottom-l h-margin-bottom-m"
          >
            <label class="h-label">Term: </label>
            <InputField
              type="select"
              v-model="localEntry.term"
              :error="errors.term"
              @change="errors.term = null"
              :choices="terms"
            />
          </div> -->

          <div class="h-margin-bottom-l h-margin-bottom-m">
            <label class="h-label">
              Doses:
            </label>
            <div>
              <div
                class="d-flex align-items-center mbp"
                v-for="doseNumber of [1, 2, 3, 4, 5, 6, 7, 8]"
                :key="doseNumber"
              >
                <InputField
                  class="flex-grow-1"
                  type="checkbox"
                  :label="`Dose ${doseNumber}`"
                  v-model="localEntry.data.doses[doseNumber.toString()]"
                  :value="localEntry.data.doses[doseNumber.toString()]"
                  :id="`checkbox-dose-${doseNumber}`"
                />
              </div>
            </div>
          </div>
          <br />

          <div class="h-margin-bottom-l h-margin-bottom-m">
            <label class="h-label">
              Reminder times:
            </label>
            <div v-if="localEntry.data && localEntry.data.times">
              <div
                class="d-flex align-items-center mbp"
                v-for="(entry, index) of localEntry.data.times"
                :key="index"
              >
                <InputField
                  class="flex-grow-1"
                  type="time"
                  v-model="entry.value"
                  :error="errors.data.times[entry.uuid]"
                  @change="errors.data.times[entry.uuid] = null"
                />
                <i
                  class="fas fa-trash-alt text-danger trash-icon"
                  title="Delete"
                  @click="deleteTime(index)"
                ></i>
              </div>
            </div>

            <button type="button" class="h-btn h-btn-accent btn-xs" @click="addTime">
              Add
            </button>
          </div>

          <div class="h-margin-bottom-l h-margin-bottom-m">
            <label class="h-label">"Times" per dose: </label>
            <InputField
              type="number"
              v-model="localEntry.times_a_term"
              :error="errors.times_a_term"
              @change="errors.times_a_term = null"
              :min="1"
            />
          </div>

          <div class="h-margin-bottom-l h-margin-bottom-m">
            <label class="h-label">Amount per "time": </label>
            <InputField
              type="number"
              v-model="localEntry.amount"
              :error="errors.amount"
              @change="errors.amount = null"
              :min="0"
            />
          </div>

          <div class="h-margin-bottom-l h-margin-bottom-m">
            <label class="h-label">Amount unit: </label>
            <InputField
              type="select"
              v-model="localEntry.amount_unit"
              :error="errors.amount_unit"
              @change="errors.amount_unit = null"
              :choices="items"
            />
          </div>

          <div class="h-margin-bottom-l h-margin-bottom-m">
            <label class="h-label">Comment: </label>
            <InputField
              type="textarea"
              v-model="localEntry.comment"
              :error="errors.comment"
              @change="errors.comment = null"
            />
          </div>
        </div>
        <Loader v-if="loading" />
      </template>

      <template v-slot:modal-footer>
        <button type="button" class="h-btn h-btn-accent" @click="save" :disabled="loading">
          Save
        </button>
        <button
          type="button"
          class="h-btn h-btn-dangerous h-margin-left-s"
          @click="close"
          :disabled="loading"
        >
          Cancel
        </button>
      </template>
    </SlottedMessageInDrawer>
  </div>
</template>

<style lang="stylus" scoped>
.modal-dialog
  max-width: 1200px

.mbp
  margin-bottom: 4rem

.trash-icon
  font-size: 10rem
  margin-left: 10rem
  cursor: pointer
</style>

<script>
import moment from 'moment';
import Loader from '@/components/Loader';
import SlottedMessageInDrawer from '@/components/dialogs/SlottedMessageInDrawer';
import InputField from '@/components/InputField';

export default {
  props: ['entry'],
  data() {
    return {
      localEntry: null,
      errors: {
        data: {
          times: {},
          doses: [],
        },
      },
      loading: true,
      medicationTypes: [
        'Over-the-counter pain killers (Tylenol, Advil, etc.)',
        'Prescribed narcotics/opioids',
        'Corticosteroids (for e.g. cortisone, steroid injections)',
        'Hyaluronic acid shots (for e.g. euflexxa)',
      ],
      terms: ['a day', 'a week', 'a month'],
      items: ['mg', 'g', 'kg', 'ml', 'l'],
    };
  },
  methods: {
    createdAtToLocal(d) {
      return moment
        .utc(d)
        .local()
        .format('YYYY-MM-DD HH:mm:ss');
    },

    createdAtToUtc(d) {
      return moment(d)
        .utc()
        .format('YYYY-MM-DD HH:mm:ss');
    },

    async save() {
      this.loading = true;
      if (this.localEntry.id) {
        this.localEntry.created_at = this.createdAtToUtc(
          `${this.localEntry.created_at_date} ${this.localEntry.created_at_time}`,
        );
      }
      this.$emit('save', this.localEntry);
    },

    show(entry) {
      this.localEntry = JSON.parse(
        JSON.stringify(
          entry || {
            medication_type: this.medicationTypes[0],
            term: this.terms[0],
            amount_unit: this.items[0],
          },
        ),
      );

      this.localEntry.data = this.localEntry.data || {};
      this.localEntry.data.times = this.localEntry.data.times || [];
      this.localEntry.data.times.forEach((e) => {
        e.uuid = this.$uuid.v4();
      });
      this.localEntry.data.doses = this.localEntry.data.doses || {};

      if (this.localEntry.created_at) {
        const createdAtParts = this.createdAtToLocal(this.localEntry.created_at).split(' ');
        this.localEntry.created_at_date = createdAtParts[0];
        this.localEntry.created_at_time = createdAtParts[1];
      }
      this.loading = false;
      this.$refs.slottedMessage.show();
    },

    close(force) {
      this.$refs.slottedMessage.close(force);
      this.$refs.slottedMessage.active = false;
    },

    setErrors(errors) {
      this.errors = errors;
      this.errors.data = this.errors.data || {};
      this.errors.data.times = this.errors.data.times || {};
      this.loading = false;
    },

    addTime() {
      this.localEntry.data = this.localEntry.data || {};
      this.localEntry.data.times = this.localEntry.data.times || [];
      this.localEntry.data.times.push({
        uuid: this.$uuid.v4(),
        value: null,
      });
      this.localEntry = {
        ...this.localEntry,
      };
    },

    deleteTime(index) {
      this.localEntry.data.times.splice(index, 1);
      this.localEntry = {
        ...this.localEntry,
      };
    },
  },
  components: {
    Loader,
    SlottedMessageInDrawer,
    InputField,
  },
};
</script>

<template>
  <div class="main">
    <div
      :class="{'dropzone-container': true, 'dropzone-container-active': isDragging,
       'dropzone-container-error': error}"
      @dragover="dragover"
      @dragleave="dragleave"
      @drop="drop"
    >
      <input
        type="file"
        multiple
        name="file"
        id="fileInput"
        class="hidden-input"
        @change="onChange"
        ref="file"
        accept=".pdf"
      />
      <label for="fileInput" :class="{'file-label': true, 'file-label-error': error}">
        <div v-if="file">{{file.name}}</div>
        <div v-else>+ Upload pdf</div>
      </label>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DropFile',
  props: {
    error: {
      type: null,
    },
  },
  data() {
    return {
      isDragging: false,
      file: null,
    };
  },
  methods: {
    onChange() {
      console.log(this.$refs.file.files[0].type);
      if (this.$refs.file.files[0].type.indexOf('pdf') === -1) {
        return;
      }
      this.file = this.$refs.file.files[0];
      this.$emit('setFile', this.file);
    },
    dragover(e) {
      e.preventDefault();
      this.isDragging = true;
    },
    dragleave() {
      this.isDragging = false;
    },
    drop(e) {
      e.preventDefault();
      this.$refs.file.files = e.dataTransfer.files;
      this.onChange();
      this.file.error = false;
      this.isDragging = false;
    },
  },
};
</script>
<style scoped>
.main {
  display: flex;
  flex-grow: 1;
  align-items: center;
  height: 100vh;
  justify-content: center;
  text-align: center;
}

.dropzone-container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4rem;
  transform: translateY(-30%);
  width: 250px;
  height: 250px;
  background: white;
  border: 1px dashed #A1A3B3;
  border-radius: 10px;
}
.dropzone-container-active {
  background: #c1c0c0;
}

.dropzone-container-error {
  background: #ffe2e9;
  border: 1px dashed #ee4266;
}

.hidden-input {
  opacity: 0;
  overflow: hidden;
  position: absolute;
  width: 1px;
  height: 1px;
}

.file-label {
  font-size: 15px;
  display: block;
  cursor: pointer;
  color: #A1A3B3;
}

.file-label-error {
  color: #ee4266;
}

.preview-container {
  display: flex;
  margin-top: 2rem;
}

.preview-card {
  display: flex;
  border: 1px solid #a2a2a2;
  padding: 5px;
  margin-left: 5px;
}

.preview-img {
  width: 50px;
  height: 50px;
  border-radius: 5px;
  border: 1px solid #a2a2a2;
  background-color: #a2a2a2;
}
</style>

<template>
  <div class="account-body">
    <div class="row">
      <div class="col-12 align-self-center">
        <div class="auth-page">
          <div class="card auth-card shadow-lg">
            <div class="card-body">
              <div class="px-3">
                <div class="text-center auth-logo-text">
                  <h4 class="mt-0 mb-3 mt-4 text-danger">Session Expired</h4>
                  <p class="mb-0 my-4">Please relogin</p>
                </div> <!--end auth-logo-text-->

                <div>

                  <div class="form-group mb-3 row">
                    <div class="col-12 mt-2">
                      <button
                        class="btn btn-primary btn-round btn-block waves-effect waves-light"
                        type="button"
                        @click="goToLoginPage"
                      >
                        Go to login page
                      </button>
                    </div><!--end col-->
                  </div> <!--end form-group-->

                </div>
              </div><!--end /div-->
            </div><!--end card-body-->
          </div><!--end card-->
        </div><!--end auth-page-->
      </div><!--end col-->
    </div><!--end row-->
  </div>
</template>


<style lang="stylus" scoped>
.account-body
  background-color transparent
  padding-top 2em
  padding-bottom 4em
</style>

<script>
import settings from '@/settings.js';

export default {
  methods: {
    goToLoginPage() {
      this.$router.push({
        name: 'Login',
      });
    },
  },

  async mounted() {
    if (this.$route.query.expire !== 'false') {
      await this.$api.get(
        `${settings.BACKEND_URL}/api/v-nurse/logout`,
      );
      this.$router.push({
        name: 'SessionExpired',
        query: {
          expire: 'false',
        },
      });
    }
  },
};

</script>

<template>
  <div class="h-drawer-max500 h-no-padding">
    <SlottedMessageInDrawer ref="slottedMessage" :title="entry ? entry.date : ''">
      <template v-slot:modal-body>
        <div
          v-if="entry"
          class="h1-table h-MedicationDoseDateDialog h-margin-left-l h-margin-right-l"
        >
          <table ref="table" class="table dataTable">
            <thead class="thead-light">
              <tr>
                <th style="width: 5%;" class="text-center">Dose</th>
                <th style="width: 5%;">Medication</th>
                <th style="width: 5%;" class="text-center">Time of submission</th>
                <th style="width: 5%;" class="text-center">Status</th>
              </tr>
            </thead>

            <tbody>
              <tr v-for="(row, index) of rows" :key="index">
                <td class="text-center">
                  <i
                    v-if="row.diffAmounts"
                    class="mdi mdi-information mr-2 text-danger"
                    title="Amount differs from prescription"
                  ></i>
                  {{ row.dose }}
                </td>
                <td>
                  <span v-if="row.entry">
                    {{ row.entry.times_a_term }}x{{ row.entry.amount }}{{ row.entry.amount_unit }}
                  </span>
                  <span v-else> {{ row.times_a_term }}x{{ row.amount }}{{ row.amount_unit }} </span>
                  {{ row.name }}
                </td>
                <td class="text-center">{{ getSubmissionTimestamp(row) }}</td>
                <td class="text-center">{{ row.status }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </template>

      <template v-slot:modal-footer>
        <button type="button" class="h-btn h-btn-dangerous h-margin-left-s" @click="close">
          Close
        </button>
      </template>
    </SlottedMessageInDrawer>
  </div>
</template>

<script>
import _ from 'lodash';
import moment from 'moment';
import SlottedMessageInDrawer from '@/components/dialogs/SlottedMessageInDrawer';

export default {
  data() {
    return {
      entry: null,
      initialized: false,
    };
  },

  computed: {
    rows() {
      const result = [...this.entry.notSubmitted, ...this.entry.notTaken, ...this.entry.taken];
      return _.sortBy(result, ['dose', 'status']);
    },
  },

  methods: {
    close(force) {
      this.entry = null;
      this.$refs.slottedMessage.close(force);
      this.$refs.slottedMessage.active = false;
    },

    async show(entry) {
      this.entry = entry;
      this.$refs.slottedMessage.show();
      await this.$nextTick();
      if (!this.initialized) {
        this.initialized = true;
        $(this.$refs.table).DataTable({
          searching: false,
          scrollY: 100,
          dom:
            "<'row'<'col-sm-12'tr>>" +
            '<"h-table-footer h-row h-space-between h-align-items-center h-padding-top-l"lp>',
        });
      }
    },

    getSubmissionTimestamp(entry) {
      const usage = entry.usages?.find(
        usageEntry => usageEntry.use_date === this.entry.date && entry.dose === usageEntry.dose,
      );
      if (!usage) return '';
      return moment
        .utc(usage.created_at)
        .local()
        .format('h:mm a');
    },
  },
  components: {
    SlottedMessageInDrawer,
  },
};
</script>

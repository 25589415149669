<template>
  <div>
    <div>
      <label class="h-label">{{ fileIndex }}. Select file to upload [{{status}}]</label>
      <InputField
        type="file"
        v-model="file"
        :error="errors.file"
        @change="() => { errors.file = null; this.uploaded = false }"
        :disabled="loading"
      />
    </div>

    <div class="h-margin-top-l">
      <label class="h-label">Hospital</label>
      <InputField
        name="hospital"
        type="select"
        v-model="hospitalId"
        :choices="hospitalChoices"
        :error="errors.hospital_id"
        @input="() => {errors.hospital_id = null}"
        :disabled="loading"
      />
    </div>

    <div class="h-margin-top-l">
      <label class="h-label">Report type</label>
      <InputField
        name="report_type"
        type="select"
        v-model="reportType"
        :choices="['--select--', 'Billing', 'Hospital']"
        :error="errors.report_type"
        @input="() => {errors.report_type = null}"
        :disabled="loading"
      />
    </div>

    <div class="h-margin-top-l">
      <label class="h-label">Report period:</label>
      <input
        id="reportDate"
        type="month"
        v-model="reportDate"
        class="h-input"
        required
        :disabled="loading"
      />
    </div>
  </div>
</template>

<script>
import moment from 'moment-timezone';
import InputField from '@/components/InputField';

export default {
  data() {
    return {
      loading: false,
      file: null,
      reportType: '--select--',
      uploaded: false,
      hospitalId: null,
      reportDate: moment().startOf('month').format('YYYY-MM'),
      errors: {},
    };
  },

  computed: {
    hospitalChoices() {
      const choices = this.hospitals.map((hospital) => {
        return [hospital.id, hospital.name];
      });
      choices.unshift([null, '--- select hospital ---']);
      return choices;
    },

    status() {
      if (this.loading) {
        return 'Loading';
      }
      if (this.uploaded) {
        return 'Uploaded';
      }
      return 'New';
    },

    isPreventingLoad() {
      return !this.file || !this.hospitalId || !this.reportDate || this.reportType === '--select--';
    },
  },

  methods: {
    reset() {
      this.loading = false;
      this.file = null;
      this.uploaded = false;
      this.hospitalId = null;
      this.reportDate = moment().startOf('month').format('YYYY-MM');
      this.reportType = '--select--';
      this.errors = {};
    },

    async upload() {
      if (!this.file || !this.hospitalId || !this.reportDate) return;
      const filename = this.file.name;
      this.loading = true;
      const payload = {
        file: this.file,
        report_type: this.reportType,
        hospital_id: this.hospitalId,
        report_month: moment(this.reportDate).startOf('month').format('YYYY-MM-DD'),
      };
      try {
        $.notify(
          `File uploaded started: ${filename}`,
          { position: 'top center', className: 'info' },
        );
        await this.$api.uploadBillingDocument(payload);
        this.uploaded = true;
        $.notify(
          `File uploaded: ${filename}`,
          { position: 'top center', className: 'success' },
        );
        this.$emit('uploadSuccess');
        this.reset();
      } catch (e) {
        $.notify(
          `Failed to upload file: ${filename}`,
          { position: 'top center', className: 'error' },
        );
      }
    },
  },

  components: {
    InputField,
  },

  props: {
    hospitals: {
      type: Array,
      required: true,
    },
    fileIndex: {
      type: Number,
      required: true,
    },
  },
};
</script>

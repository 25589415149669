<template>
  <div class="h-bg-gray h-col-desktop-10 h-col-mobaile-12">
    <div
      v-if="loadingOptions"
      id="headerBox1"
      class="h-padding-left-l h-padding-right-l h-padding-bottom-l h-bg-w text-center text-muted"
      style="font-size: 1.5em"
    >
      Please wait...
    </div>
    <div v-if="!loadingOptions" id="headerBox" class="h-padding-left-l h-bg-w">
      <div class="h-row">
        <div
          class="h-col h-col-mobile-12 h-mobile-margin-top-l
          h-mobile-margin-left-0 h-border-right-0"
        >
          <label class="h-label">Date start: </label>
          <input type="date" v-model="dateStart" class="h-input" />
        </div>

        <div
          class="h-col h-col-mobile-12 h-mobile-margin-top-l h-mobile-margin-left-0 h-border-left-0"
        >
          <label class="h-label">Date end:</label>
          <input type="date" v-model="dateEnd" class="h-input" />
        </div>

        <div
          class="h-col-desktop-2 h-col-mobile-12 h-mobile-margin-top-l
          h-mobile-margin-left-0 h-padding-left-l h-margin-left-l"
        >
          <label class="h-label">Hospital:</label>
          <InputField
            name="hospitalSelect"
            type="select"
            v-model="hospitalId"
            :choices="hospitalChoices"
            :error="errors.hospital_id"
            @input="
              () => {
                errors.hospital_id = null
              }
            "
          />
        </div>

        <div
          class="h-col-desktop-2 h-col-mobile-12 h-mobile-margin-top-l
          h-mobile-margin-left-0 h-padding-left-l h-margin-left-l"
        >
          <label class="h-label">Physician:</label>
          <InputField
            type="select"
            :choices="surgeonOptions"
            v-model="surgeonId"
            :error="errors.surgeon_id"
            @input="
              () => {
                errors.surgeon_id = null
              }
            "
          />
        </div>
        <div
          class="h-col-desktop-2 h-col-mobile-12 h-mobile-margin-top-l
          h-mobile-margin-left-0 h-padding-left-l h-margin-left-l"
        >
          <label class="h-label">Nurse:</label>
          <InputField
            type="select"
            :choices="nurseOptions"
            v-model="nurseId"
            :error="errors.nurse_id"
            @input="
              () => {
                errors.nurse_id = null
              }
            "
          />
        </div>
        <div
          class="h-col-desktop-2 h-col-mobile-12 h-mobile-margin-top-l
          h-mobile-margin-left-0 h-margin-left-l h-mobile-margin-left-0"
          style="
            display: flex;
            flex-direction: row;
            margin-top: 15rem;
            align-items: center;
            justify-content: space-between;
          "
        >
          <InputField
            type="checkbox"
            id="StatisticReport:aggregate"
            label="Aggregate"
            v-model="aggregate"
          />
          <div class="h-icon-tooltip">
            <img
              src="../../../../../../public/assets/images/Down report.svg"
              class="h-report-icon h-padding-left-s"
              @click="reload"
              :class="{ 'h-disabled': !hospitalId && false }"
            />
            <label class="h-margin-top-l"> Load report </label>
          </div>
        </div>
      </div>
    </div>
    <SelectorDialogInDrawer
      ref="toggleColumnsDialog"
      :allOptions="allTableColumns"
      :selectedOptions="visibleTableColumns"
      :getOptionKey="
        option => {
          return option.name
        }
      "
      :getOptionText="
        option => {
          return option.text
        }
      "
      windowTitle="Toggle table columns"
      listTitle="Table table columns to show"
      @toggleOption="toggleColumnVisible"
    />
  </div>
</template>

<script>
import moment from 'moment-timezone';
import settings from '@/settings.js';
import InputField from '@/components/InputField';
import SelectorDialogInDrawer from '@/components/dialogs/SelectorDialogInDrawer';

const TABLE_COLUMNS_KEY = 'TABLE_COLUMNS_KEY';

export default {
  data() {
    return {
      loadingOptions: true,
      errors: {},
      hospitals: [],
      hospitalId: 'true',
      surgeons: [],
      surgeonId: null,
      nurses: [],
      nurseId: null,
      dateStart: moment().startOf('month').format('YYYY-MM-DD'),
      dateEnd: moment().endOf('month').format('YYYY-MM-DD'),
      aggregate: true,
      metrics: [],
    };
  },

  computed: {
    hospitalChoices() {
      const choices = this.hospitals.map((hospital) => {
        return [hospital.id, hospital.name];
      });
      choices.sort((a, b) => a[1].localeCompare(b[1]));
      choices.unshift(['true', '[ALL]']);
      choices.unshift([null, '--- select hospital ---']);
      return choices;
    },

    surgeonOptions() {
      const choices = this.surgeons
        .map((surgeon) => {
          if (
            this.hospitalId
            && this.hospitalId !== 'true'
            && surgeon.hospital.id !== parseInt(this.hospitalId, 10)
          ) {
            return null;
          }
          return [surgeon.id, surgeon.name];
        })
        .filter(entry => entry !== null);
      choices.sort((a, b) => a[1].localeCompare(b[1]));
      choices.unshift(['true', '[ALL]']);
      choices.unshift([null, '--- select provider ---']);

      return choices;
    },

    nurseOptions() {
      const choices = this.nurses
        .map((nurse) => {
          if (this.hospitalId && this.hospitalId !== 'true') {
            const foundHospital = nurse.hospitals.find(
              hospital => hospital.id === parseInt(this.hospitalId, 10),
            );
            if (!foundHospital) {
              return null;
            }
          }
          return [nurse.id, nurse.name];
        })
        .filter(entry => entry !== null);
      choices.sort((a, b) => a[1].localeCompare(b[1]));

      choices.unshift(['true', '[ALL]']);
      choices.unshift([null, '--- select nurse ---']);

      return choices;
    },
  },

  watch: {
    hospitalId() {
      this.updateFilters('hospitalId');
    },

    nurseId() {
      this.updateFilters('nurseId');
    },

    surgeonId() {
      this.updateFilters('surgeonId');
    },

    aggregate() {
      if (this.hospitalId === 'true') this.hospitalId = null;
      if (this.nurseId === 'true') this.nurseId = null;
      if (this.surgeonId === 'true') this.surgeonId = null;
    },
  },

  methods: {
    updateFilters(source) {
      if (source === 'hospitalId') {
        if (this.hospitalId && this.hospitalId !== 'true') {
          const nurse = this.nurses.find(n => n.id === parseInt(this.nurseId, 10));
          if (nurse) {
            const foundHospital = nurse.hospitals.find(
              hospital => hospital.id === parseInt(this.hospitalId, 10),
            );
            if (!foundHospital) {
              this.nurseId = null;
            }
          }

          const surgeon = this.surgeons.find(s => s.id === parseInt(this.surgeonId, 10));
          if (surgeon && surgeon.hospital.id !== parseInt(this.hospitalId, 10)) {
            this.surgeonId = null;
          }
        }
      } else if (source === 'nurseId' && this.nurseId !== null) {
        this.surgeonId = null;
      } else if (source === 'surgeonId' && this.surgeonId !== null) {
        this.nurseId = null;
      }
    },

    reload() {
      $('#statistic-report-table').ajax.reload();
    },

    toggleColumnVisible(column, visible) {
      if (visible) {
        this.visibleTableColumns.push(column);
      } else {
        const index = this.visibleTableColumns.indexOf(column);
        this.visibleTableColumns.splice(index, 1);
      }

      localStorage.setItem(TABLE_COLUMNS_KEY, JSON.stringify(this.visibleTableColumns));

      const dtColumn = this.dataTable.column(this.allTableColumns.indexOf(column));
      dtColumn.visible(!dtColumn.visible());
    },
  },

  beforeDestroy() {
    if (this.dataTable) {
      this.dataTable.destroy(true);
      this.dataTable = null;
    }
  },

  async mounted() {
    const [hospitals, surgeons, nurses, metrics] = await Promise.all([
      this.$api.getHospitalsList(),
      this.$api.searchSeargeon(),
      this.$api.searchNurse(),
      this.$api.get(`${settings.BACKEND_URL}/api/v-nurse/project-statistics/metrics`),
    ]);
    this.hospitals = hospitals;
    this.surgeons = surgeons;
    this.nurses = nurses;
    this.metrics = metrics.metrics.filter(m => !m.name.startsWith('__'));

    this.loadingOptions = false;

    await this.$forceUpdate();

    // --- START: DATATABLE ---

    // --- END: DATATABLE ---
  },

  components: {
    InputField,
    SelectorDialogInDrawer,
  },
};
</script>

<template>
  <div>
    <div
      class="
        h-padding-left-l
        h-padding-right-l
        h-space-between
        h-align-items-center
        h-mob-learn
      "
    >
      <div>
        <h3 class="h-h3 h-primary_shade_1 h-align-items-center">
          Learn Module
        </h3>
      </div>
      <div class="h-d-flex h-align-items-center align-items-center w-300">
        <input
          v-if="collection.learns.length > 0"
          type="search"
          placeholder="Search"
          class="h-input "
          v-model="textSearch"
          @input="handleInput()"
        />
        <div v-if="!readOnly && isTemplateMode" class="h-margin-left-m">
          <button
            type="button"
            class="h-btn h-btn-safe"
            @click="
              () => {
                $refs.addEducationEntryDialog.show()
              }
            "
          >
            Clone existing items
          </button>
          <button
            type="button"
            class="h-btn h-btn-accent h-margin-left-m"
            @click="
              () => {
                $refs.educationEntryDialog.show(null, collectionId, false)
              }
            "
          >
            <i class="healenticons-plus h-padding-right-xs"></i>New education
          </button>
        </div>
        <div v-if="!readOnly && !isTemplateMode" class="h-margin-left-m">
          <button
            type="button"
            class="h-btn h-btn-accent"
            @click="saveDoctorChanges"
            :disabled="!doctorHasChanges"
          >
            Save changes
          </button>
        </div>

        <div
          v-if="collection.learns.length > 0"
          class="h-relative h-line-height-1 dropdown h-add-dropdown"
        >
          <a
            class="dropdown-toggle arrow-none"
            data-toggle="dropdown"
            href="#"
            role="button"
            aria-haspopup="false"
            aria-expanded="false"
            style="background: white"
          >
            <i class="h-h3  h-secondary_shade_1 healenticons-download h-margin-left-m"> </i>
          </a>
          <div ref="collectionDropdown" class="dropdown-menu assessment-dropdown"></div>
        </div>
      </div>
    </div>
    <div :class="{ hidden: loading }" class="h1-table">
      <table :id="collectionId" ref="table" class="table dataTable" :class="{ 'table-sm': dense }">
        <thead class="thead-light">
          <tr>
            <th width="5%" v-if="!readOnly && !isTemplateMode" class="text-center">
              On/Off
            </th>
            <th width="5%" v-if="hospitalMode">Primary Tag</th>
            <th width="5%">Title</th>
            <th width="5%">Subtitle</th>
            <!--             <th>Source</th>
 -->
            <th width="5%">Type</th>
            <th width="5%">Category</th>
            <!--             <th>Description</th>
 -->
            <th width="5%">Order</th>
            <th width="5%" v-if="!readOnly && isTemplateMode" class="text-right">
              Action
            </th>
          </tr>
        </thead>
        <tbody v-if="collection.learns.length > 0">
          <tr v-for="learn of collection.learns" :key="learn.id">
            <td v-if="!readOnly && !isTemplateMode" class="text-center">
              <InputField
                :id="`careProtocolItem-doctorEducationItem::${learn.id}`"
                type="checkbox"
                checkboxSwitchType="success"
                v-model="learn.enabled"
                @change="
                  () => {
                    doctorHasChanges = true
                  }
                "
              />
            </td>
            <td v-if="hospitalMode">
              {{ learn.primary_tag }}
            </td>
            <td>
              <a
                v-if="learn.item_type === 'video' && readOnly && isTemplateMode"
                :href="learn.link"
                target="_blank"
              >
                {{ learn.title }}
              </a>
              <a
                v-else
                href="javascript:void(0);"
                @click="
                  () => {
                    const readOnly = readOnly || !isTemplateMode
                    $refs.educationEntryDialog.show(learn.id, collectionId, readOnly)
                  }
                "
              >
                {{ learn.title }}
              </a>
            </td>
            <td>{{ learn.sub_title }}</td>
            <!-- <td>{{ learn.source }}</td> -->
            <td>
              <a v-if="learn.item_type === 'video'" :href="learn.link" target="_blank">
                {{ learn.item_type }}
              </a>
              <span v-else>
                {{ learn.item_type }}
              </span>
            </td>
            <td>{{ learn.category }}</td>
            <!--             <td>
              <input :id="`${learn.id}`" type="checkbox" class="h-read-more" />
              <label :for="`${learn.id}`">
                <span class="h-learn-description">{{ learn.description }}</span>
              </label>
            </td> -->
            <td>{{ learn.order }}</td>
            <td v-if="!readOnly && isTemplateMode" class="text-right text-nowrap">
              <a
                href="javascript:void(0);"
                class="mr-2"
                @click="
                  () => {
                    const readOnly = readOnly || !isTemplateMode
                    $refs.educationEntryDialog.show(learn.id, collectionId, readOnly)
                  }
                "
              >
                <i class="fas fa-edit text-info font-16" title="Edit"></i>
              </a>

              <a
                href="javascript:void(0);"
                class="mr-2"
                @click="
                  () => {
                    $refs.deleteEducationEntryDialog.show(collectionId, learn)
                  }
                "
              >
                <i class="fas fa-trash-alt text-danger font-16" title="Delete"></i>
              </a>
            </td>
          </tr>
        </tbody>

        <tbody v-else>
          <tr>
            <td class="text-center" colspan="100">No entries</td>
          </tr>
        </tbody>
      </table>
    </div>

    <Loader v-if="loading" />

    <AddEducationEntryDialog
      v-if="!readOnly"
      ref="addEducationEntryDialog"
      :collectionId="collectionId"
      @entryAdded="setCollection"
    />

    <DeleteEducationEntryDialog
      v-if="!readOnly"
      ref="deleteEducationEntryDialog"
      :collectionId="collectionId"
      @entryDeleted="setCollection"
    />

    <EducationEntryDialog
      ref="educationEntryDialog"
      @entryUpdated="setCollection"
      :hospitalMode="hospitalMode"
    />

    <GlobalWait ref="globalWait" title="Saving education list" message="Saving education list..." />
  </div>
</template>

<script>
import Loader from '@/components/Loader';
import GlobalWait from '@/components/GlobalWait';
import InputField from '@/components/InputField';
import AddEducationEntryDialog from './AddEducationEntryDialog';
import DeleteEducationEntryDialog from './DeleteEducationEntryDialog';
import EducationEntryDialog from '@/components/education/education_entry/EducationEntryDialog';

export default {
  props: ['collectionId', 'readOnly', 'dense', 'isTemplateMode', 'doctor', 'hospitalMode'],

  data() {
    return {
      loading: true,
      collection: { learns: [] },
      doctorHasChanges: false,
      allTableColumns: [],
      visibleTableColumns: [],
      dataTable: null,
      textSearch: '',
    };
  },

  methods: {
    async setCollection(collection) {
      this.collection = collection;
      if (this.hospitalMode) {
        this.collection.learns.sort((a, b) => {
          if (this.hospitalMode) {
            const aPrimaryTag = a.primary_tag || '';
            const bPrimaryTag = b.primary_tag || '';
            if (aPrimaryTag > bPrimaryTag) return 1;
            if (aPrimaryTag < bPrimaryTag) return -1;
          }

          if (a.order > b.order) return 1;
          if (a.order < b.order) return -1;

          if (a.title > b.title) return 1;
          if (a.title < b.title) return -1;

          return 0;
        });
      }
      this.loading = true;
      await this.$nextTick();
      this.loading = false;
      await this.$nextTick();
      this.processDataTable();
    },

    async saveDoctorChanges() {
      this.$refs.globalWait.start();
      const disabledItems = this.collection.learns
        .filter((item) => {
          return item.enabled === false;
        })
        .map((item) => {
          return item.id;
        });
      this.collection = await this.$api.updateEducationCollection(
        this.collectionId,
        this.doctor.id,
        { disabledItems },
      );
      this.doctorHasChanges = false;
      this.$refs.globalWait.stop();
    },

    processDataTable() {
      if (this.dataTable) return;
      this.dataTable = $(this.$refs.table).DataTable({
        searching: true,
        scrollY: 100,
        dom: '<""<""tr>><"h-row h-space-between h-align-item-center h-padding-bottom-l"lip>',
        buttons: ['copy', 'excel', 'csv', 'print'],
      });
      this.dataTable
        .buttons()
        .container()
        .appendTo($(this.$refs.collectionDropdown));
    },
    search() {
      if (this.dataTable) {
        this.dataTable.search(this.textSearch).draw();
      } else {
        console.error('DataTable not initialized!');
      }
    },
    handleInput() {
      if (!this.textSearch) {
        this.clearSearch();
      } else {
        this.search();
      }
    },
    clearSearch() {
      this.textSearch = '';
      if (this.dataTable) {
        this.dataTable.search('').draw();
      }
    },
  },

  beforeDestroy() {
    if (this.dataTable) {
      this.dataTable.destroy(true);
      this.dataTable = null;
    }
  },

  async mounted() {
    if (!this.collectionId) {
      this.loading = false;
      return;
    }

    const collection = await this.$api.getEducationCollection(
      this.collectionId,
      this.doctor ? this.doctor.id : null,
    );
    this.setCollection(collection);

    this.loading = false;
  },

  components: {
    Loader,
    GlobalWait,
    InputField,
    AddEducationEntryDialog,
    DeleteEducationEntryDialog,
    EducationEntryDialog,
  },
};
</script>

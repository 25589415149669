var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:_vm.getWrapperClass()},[(
      ['text', 'password', 'search', 'date', 'tel', 'time', 'datetime', 'number'].indexOf(
        _vm.type
      ) !== -1
    )?_c('input',{staticClass:"form-control h-input",class:Object.assign({}, {'is-invalid': _vm.error,
      'form-control-sm': _vm.dense},
      _vm.classExtra),attrs:{"id":_vm.id,"type":_vm.type,"disabled":_vm.disabled,"allowPasting":true,"step":_vm.step,"min":_vm.min,"max":_vm.max,"maxlength":_vm.maxlength,"readonly":_vm.readonly,"placeholder":_vm.placeholder,"name":_vm.name,"autocomplete":_vm.autocomplete},domProps:{"value":_vm.value},on:{"input":_vm.onInput,"change":_vm.onChange,"keydown":_vm.onKeyDown,"keyup":_vm.onKeyUp,"keypress":_vm.onKeyPress,"paste":_vm.onPaste}}):_vm._e(),(_vm.type === 'toggle')?_c('div',{staticClass:"h-d-flex h-align-items-center h-margin-bottom-m h-space-between"},[_c('label',{staticClass:"h-cursor-pointer h-text-label h-d-flex h-align-items-center h-space-between h-w-100"},[_vm._v(_vm._s(_vm.label)+" "),(_vm.type === 'toggle')?_c('label',{staticClass:"h-toggle",attrs:{"for":_vm.id}},[(_vm.type === 'toggle')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.value),expression:"value"}],staticClass:"h-input",class:Object.assign({}, {'is-invalid': _vm.error,
            'form-control-sm': _vm.dense},
            _vm.classExtra),attrs:{"id":_vm.id,"type":"checkbox","disabled":_vm.disabled,"readonly":_vm.readonly},domProps:{"value":_vm.value,"checked":_vm.value,"checked":Array.isArray(_vm.value)?_vm._i(_vm.value,_vm.value)>-1:(_vm.value)},on:{"input":_vm.onInput,"change":[function($event){var $$a=_vm.value,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=_vm.value,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.value=$$a.concat([$$v]))}else{$$i>-1&&(_vm.value=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.value=$$c}},_vm.onChange]}}):_vm._e(),_c('span',{staticClass:"h-slider"})]):_vm._e()])]):_vm._e(),(_vm.type === 'radio')?_c('div',{staticClass:"h-d-flex h-align-items-center h-margin-bottom-m h-space-between"},[_c('h4',{staticClass:"h-text-label"},[_vm._v(_vm._s(_vm.label))]),(_vm.type === 'radio')?_c('label',{staticClass:"h-toggle",attrs:{"for":_vm.id}},[(_vm.type === 'radio')?_c('input',{staticClass:"h-inputddd",class:Object.assign({}, {'is-invalid': _vm.error,
          'form-control-sm': _vm.dense},
          _vm.classExtra),attrs:{"id":_vm.id,"type":"checkbox","disabled":_vm.disabled,"readonly":_vm.readonly},domProps:{"value":_vm.value,"checked":_vm.value},on:{"input":_vm.onInput,"change":_vm.onChange}}):_vm._e(),_c('span',{staticClass:"h-slider"})]):_vm._e()]):_vm._e(),_c('div',{staticClass:"h-custom-checkbox"},[(_vm.type === 'checkbox')?_c('input',{staticClass:"custom-control-input",class:Object.assign({}, {'is-invalid': _vm.error,
        'form-control-sm': _vm.dense},
        _vm.classExtra),attrs:{"id":_vm.id,"type":"checkbox","disabled":_vm.disabled,"readonly":_vm.readonly},domProps:{"value":_vm.value,"checked":_vm.value},on:{"input":_vm.onInput,"change":_vm.onChange}}):_vm._e(),(_vm.type === 'checkbox')?_c('label',{staticClass:"custom-control-label cursor-pointer",class:_vm.labelClass,attrs:{"for":_vm.id}},[_vm._v(_vm._s(_vm.label))]):_vm._e()]),(_vm.type === 'select')?_c('select',{ref:"theElement",staticClass:"form-control h-input",class:Object.assign({}, {'is-invalid': _vm.error,
      'form-control-sm': _vm.dense,
      'select2 select2-multiple': _vm.multiple},
      _vm.classExtra),attrs:{"id":_vm.id,"disabled":_vm.disabled,"multiple":_vm.multiple,"readonly":_vm.readonly},on:{"input":_vm.onInput,"change":_vm.onChange,"click":function($event){$event.stopPropagation();return _vm.onClick.apply(null, arguments)}}},_vm._l((_vm.processedChoices),function(choice){return _c('option',{key:choice.value,domProps:{"value":choice.value,"selected":_vm.isOptionSelected(choice.value)}},[_vm._v(" "+_vm._s(choice.title)+" ")])}),0):_vm._e(),(_vm.type === 'file')?_c('div',{staticClass:"form-group form-group-file h-input-file",class:{
      'is-invalid': _vm.error
    }},[_c('div',{staticClass:"custom-file"},[_c('input',{staticClass:"custom-file-input",class:{ 'is-invalid': _vm.error },attrs:{"type":"file","id":_vm.id,"disabled":_vm.disabled,"readonly":_vm.readonly,"accept":_vm.accept},on:{"input":_vm.onInput,"change":_vm.onChange}}),_c('label',{staticClass:"custom-file-label form-control",attrs:{"for":_vm.id}},[_vm._v(" "+_vm._s(_vm.getFileLabel())+" ")])])]):_vm._e(),(_vm.type === 'textarea')?_c('textarea',{staticClass:"form-control h-input",class:Object.assign({}, {'is-invalid': _vm.error,
      'form-control-sm': _vm.dense,
      'padding-right-0': _vm.paddingRight},
      _vm.classExtra),attrs:{"rows":_vm.rows,"id":_vm.id,"disabled":_vm.disabled,"readonly":_vm.readonly,"maxlength":_vm.maxlength,"placeholder":_vm.placeholder},domProps:{"value":_vm.value},on:{"input":_vm.onInput,"change":_vm.onChange}}):_vm._e(),(_vm.error && _vm.error !== true)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(_vm.error)+" ")]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div class="search-table-pos">
    <div class="search-container">
      <input
        id="customSearchBox"
        type="search"
        placeholder="Type, then click search"
        class="h-input search-input" />
      <button id="searchTrigger" class="search-icon">
        <i class="healenticons-search" data-tooltip="Click to search"></i>
      </button>
    </div>
    <div class="h-row">
      <div class="table-responsive h1-table">
        <table id="search-patients" class="table search-table " ref="table">
          <thead class="thead-light">
            <tr>
              <th style="width: 5%" class="AllPatients-tooltop h-text-left">
                <span class="table-title-bold">ID</span>
              </th>
              <th style="width: 100%" class="AllPatients-tooltop">
                <span class="table-title-bold">Patient name</span>
              </th>
              <th style="width: 5%" class="AllPatients-tooltop table-title-bold">
                <span class="table-title-bold">Phone</span>
              </th>
              <th style="width: 5%" class="AllPatients-tooltop table-title-bold">
                <span class="table-title-bold">Hospital</span>
              </th>
              <th style="width: 5%" class="AllPatients-tooltop table-title-bold">
                <span class="table-title-bold">tag</span>
              </th>
            </tr>
            <!--end tr-->
          </thead>
        </table>
      </div>
    </div>
    <!--end card-body-->
    <PatientProfileInDrawer ref="patientProfileDrawer" :key="client.id" />
  </div>
  <!--end card-->
</template>

<script>
import eventBus from '../../event-bus';
import PatientProfileInDrawer from '../../views/surgery_navigator/all_patients/components/PatientProfileInDrawer';

export default {
  data() {
    return {
      client: {},
    };
  },
  beforeDestroy() {
    if (this.dataTable) {
      this.dataTable.destroy(true);
      this.dataTable = null;
    }
  },
  async mounted() {
    const that = this;

    const allColumns = [
      {
        name: 'id',
        searchable: false,
        render: data => (data ? `#${that.$strUtils.escapeHtml(data)}` : null),
        className: 'text-nowrap',
      },
      {
        name: 'name',
        searchable: true,
        render: (data, type, row, meta) => {
          const rowIndex = meta.settings.json.data.indexOf(row);
          let photoLink = null;
          if (meta.settings.json.metadata[rowIndex]) {
            photoLink = meta.settings.json.metadata[rowIndex].avatar_url;
          }
          if (!photoLink) {
            photoLink = '/assets/images/users/patient-pro.png';
          }
          const path = this.$router.resolve({
            name: 'PatientProfile',
            params: {
              id: row[0],
            },
          });

          let classToBeApplied =
            'h-icon healenticons-tag cursor-pointer h-account-tag h-tag-accent ';
          if (meta.settings.json.metadata[rowIndex]) {
            if (meta.settings.json.metadata[rowIndex].is_archived) {
              classToBeApplied += 'isArchivedClass';
            }

            if (meta.settings.json.metadata[rowIndex].pending_activation) {
              classToBeApplied += ' isPendingClass';
            }

            if (
              meta.settings.json.metadata[rowIndex].pending_activation === false &&
              meta.settings.json.metadata[rowIndex].is_archived === false
            ) {
              classToBeApplied += 'isActiveClass';
            }
          }
          return `
            <a href="javascript:void(0);" class="OpenProfileInDrawer h-search-header removeClass "  data-id="${row[0]
            }">
              - ${that.$strUtils.escapeHtml(data)}
            </a>
          `;
        },
        className: 'text-nowrap',
      },
      {
        name: 'phone',
        searchable: false,
        orderable: false,
        render: (data, type, row, meta) => {
          const path = this.$router.resolve({
            name: 'PatientProfile',
            params: {
              id: row[0],
            },
          });
          return `
            <a href="javascript:void(0);" class="OpenProfileInDrawer h-search-header removeClass "  data-id="${row[0]
            }">
              ${that.$strUtils.escapeHtml(data)}
            </a>
          `;
        },
      },
      {
        name: 'hospital__name',
        searchable: false,
        orderable: false,
        render: (data, type, row, meta) => {
          const path = this.$router.resolve({
            name: 'PatientProfile',
            params: {
              id: row[0],
            },
          });
          return `
            <a href="javascript:void(0);" class="OpenProfileInDrawer h-search-header removeClass "  data-id="${row[0]
            }">
              ${that.$strUtils.escapeHtml(data)}
            </a>
          `;
        },
      },
      {
        name: 'name',
        searchable: true,
        render: (data, type, row, meta) => {
          const rowIndex = meta.settings.json.data.indexOf(row);
          const path = this.$router.resolve({
            name: 'PatientProfile',
            params: {
              id: row[0],
            },
          });
          let classToBeApplied =
            'h-icon healenticons-tag cursor-pointer h-account-tag h-tag-accent ';
          if (meta.settings.json.metadata[rowIndex]) {
            if (meta.settings.json.metadata[rowIndex].is_archived) {
              classToBeApplied += 'isArchivedClass';
            }

            if (meta.settings.json.metadata[rowIndex].pending_activation) {
              classToBeApplied += ' isPendingClass';
            }

            if (
              meta.settings.json.metadata[rowIndex].pending_activation === false &&
              meta.settings.json.metadata[rowIndex].is_archived === false
            ) {
              classToBeApplied += 'isActiveClass';
            }
          }
          return `
            <a href="javascript:void(0);" class="OpenProfileInDrawer h-search-header removeClass "  data-id="${row[0]}">
              <div class="h-search-tag-con">
                <i class="${classToBeApplied}"></i>
              </div>
            </a>
          `;
        },
        className: 'h-tag-icon',
      },
    ];

    this.dataTable = $('#search-patients').DataTable({
      processing: true,
      serverSide: true,
      searching: true,
      order: [[0, 'desc']],
      ajax: (data, callback, tableSettings) => {
        const searchTerm = $('#customSearchBox').val();
        if (!searchTerm) {
          callback({ data: [] });
          console.log("returning", searchTerm);
          return;
        } this.$api.searchClients({
          ...data,
          search: { value: searchTerm }
        })
          .then((response) => {
            this.allPatientsCount = response.allCount;
            tableSettings.json = response;
            callback(response);
          });
      },
      pageLength: 10000,
      dom: '<""<""><"">><""<""tr>>',
      columns: allColumns.filter((column) => {
        if (this.isAdmin) return true;
        return !column.__adminOnly;
      }),
    });

    $('#searchTrigger').on('click', function () {
      const searchTerm = $('#customSearchBox').val();
      that.dataTable.search(searchTerm).draw();
    });

    $(this.$refs.table).on('click', '.OpenProfileInDrawer', async (e) => {
      const id = $(e.target)
        .closest('a')
        .attr('data-id');
      await this.$refs.patientProfileDrawer.show(id);
    });
  },
  components: {
    PatientProfileInDrawer,
  },
};
</script>

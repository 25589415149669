<template>
  <div>
    <h3 class="h-text-center h-h3 h-primary_shade_1">Send an invite</h3>

    <div v-if="!loading">
      <div class="alert alert-dismissible alert-success border-0" v-if="lastResultMessage">
        {{ lastResultMessage }}

        <button type="button" class="close" @click="lastResultMessage = ''">
          <i class="mdi mdi-close"></i>
        </button>
      </div>

      <form class="h-row h-padding-top-l">
        <div
          class="h-input-w49 h-desktop-margin-right-2 h-col-mobile-12 h-margin-bottom-l"
          :class="{ 'has-error': errors.name }"
        >
          <label for="inviteName" class="h-label">Name</label>
          <input
            type="text"
            class="h-input"
            :class="{ 'is-invalid': errors.name }"
            id="inviteName"
            v-model="name"
          />
          <div v-if="errors.name" class="invalid-feedback">
            {{ errors.name }}
          </div>
        </div>

        <div
          class="h-input-w49 h-col-mobile-12 h-margin-bottom-l"
          :class="{ 'has-error': errors.email }"
        >
          <label for="inviteEmail" class="h-label">User email</label>
          <input
            type="email"
            class="h-input"
            data-validate="true"
            :class="{ 'is-invalid': errors.email }"
            id="inviteEmail"
            v-model="email"
          />
          <div id="notanEmail" class="invalid-feedback">The entered email is not accepted</div>
          <div v-if="errors.email" class="invalid-feedback">
            {{ errors.email }}
          </div>
        </div>

        <div
          class="h-input-w49 h-desktop-margin-right-2 h-col-mobile-12 h-margin-bottom-l"
          :class="{ 'has-error': errors.name }"
        >
          <label for="invitePhone" class="h-label">Phone (+12345678900)</label>
          <input
            type="text"
            class="h-input"
            :class="{ 'is-invalid': errors.phone }"
            id="invitePhone"
            v-model="phone"
          />
          <div v-if="errors.phone" class="invalid-feedback">
            {{ errors.phone }}
          </div>
        </div>

        <div
          class="h-input-w49 h-col-mobile-12 h-margin-bottom-l"
          :class="{ 'has-error': errors.email }"
        >
          <label for="inviteHospital" class="h-label">Hospital</label>
          <select
            class="h-input"
            :class="{ 'is-invalid': errors.hospital }"
            id="inviteHospital"
            v-model="hospital"
          >
            <option value="">--- Select hospital ---</option>
            <option v-for="hospital of hospitals" :key="hospital.id" :value="hospital">
              {{ hospital.name }}
            </option>
          </select>
          <div v-if="errors.hospital" class="invalid-feedback">
            {{ errors.hospital }}
          </div>
        </div>

        <div
          class="h-input-w49 h-desktop-margin-right-2 h-col-mobile-12 h-margin-bottom-l"
          :class="{ 'has-error': errors.role }"
        >
          <label for="inviteRole" class="h-label">Assign role:</label>
          <select
            class="h-input"
            :class="{ 'is-invalid': errors.role }"
            id="inviteRole"
            v-model="role"
          >
            <option value="">--- Select role ---</option>
            <option v-for="role of availableInviteRoles" :key="role" :value="role">
              {{ getRoleDisplayName(role) }}
            </option>
          </select>
          <div v-if="errors.role" class="invalid-feedback">
            {{ errors.role }}
          </div>
        </div>

        <div
          class="h-input-w49 h-col-mobile-12 h-margin-bottom-l"
          :class="{
            'has-error': errors.registration_code
          }"
        >
          <label for="inviteRegistrationCode" class="h-label"> Registration code </label>
          <input
            type="text"
            class="h-input"
            :class="{ 'is-invalid': errors.registration_code }"
            id="inviteRegistrationCode"
            v-model="registrationCode"
          />
          <div v-if="errors.registration_code" class="invalid-feedback">
            {{ errors.registration_code }}
          </div>
        </div>

        <div class="h-w-100 h-text-center">
          <button type="button" class="h-btn h-btn-accent" @click="sendInvitation">
            Send invitation
          </button>
        </div>
      </form>
    </div>

    <Loader v-else />
  </div>
</template>

<script>
import Loader from '@/components/Loader';
import settings from '@/settings.js';
import strUtils from '@/utils/str_utils';
import eventBus from '../../../../event-bus';
import eventKeeper from '../../../../eventKeeper';

export default {
  name: 'InviteUser',

  data() {
    return {
      loading: true,
      name: '',
      email: '',
      phone: '',
      role: '',
      hospital: '',
      registrationCode: '',
      roles: [],
      hospitals: [],
      currentSession: settings.currentSession,

      errors: {},
      lastResultMessage: '',
    };
  },

  watch: {
    name() {
      this.errors.name = null;
    },

    email() {
      this.errors.email = null;
    },

    phone() {
      this.errors.phone = null;
    },

    role() {
      this.errors.role = null;
    },

    hospital() {
      this.errors.hospital = null;
    },

    registration_code() {
      this.errors.registration_code = null;
    },
  },

  computed: {
    availableInviteRoles() {
      return settings.getInviteRoles();
    },
  },

  methods: {
    getRoleDisplayName(role) {
      const roleToDisplay = {};
      this.roles.forEach((entry) => {
        roleToDisplay[entry.name] = entry.display_name;
      });
      return strUtils.underscoreCaseToName(roleToDisplay[role]);
    },

    async sendInvitation() {
      this.loading = true;
      this.errors = {};
      const payload = {
        name: this.name,
        email: this.email,
        phone: this.phone,
        hospital_id: this.hospital.id || null,
        role_name: this.role || '',
        registration_code: this.registrationCode,
        register_link_template:
          window.location.origin
          + this.$router.resolve({
            name: 'Register',
            params: {
              code: ':code',
            },
          }).href,
      };
      const result = await this.$api.createUserInvite(payload);
      this.errors = result.errors || {};

      this.lastResultMessage = '';
      if (result.status === 'ok') {
        if (result.invitation_updated === true) {
          this.lastResultMessage = `Account "${this.email}" UPDATED.`;
        } else if (result.invitation_updated === false) {
          this.lastResultMessage = `Account "${this.email}" CREATED.`;
        }

        this.name = '';
        this.email = '';
        this.phone = '';
        this.hospital = '';
        this.role = '';
        this.registrationCode = '';
      }

      this.loading = false;
    },
  },

  beforeDestroy() {
    eventBus.$offOwner(this);
    eventKeeper.$offOwner(this);
  },

  mounted() {
    this.$api.getHospitalsList().then((hospitals) => {
      this.hospitals = hospitals.sort((a, b) => a.name.localeCompare(b.name));

      this.$api.getRolesList().then((roles) => {
        this.roles = roles;
        this.loading = false;
      });
    });
    $(document).ready(() => {
      const validateEmail = (email) => {
        const pattern = /[a-z0-9._%+-]+@[a-z0-9.-]+\.([a-zA-Z]){2,}$/;
        let isEmailValid = false;
        if (email.match(pattern) != null) {
          isEmailValid = true;
        }
        return isEmailValid;
      };
      eventKeeper.$on(this, 'keyup', document, '#inviteEmail', (event) => {
        const keypressed = event.which;
        const inputVal = $(this).val();
        let isSuccess = true;
        if (keypressed === 9) {
          isSuccess = validateEmail(inputVal);
          if (!isSuccess) {
            $(this).focus();
          }
        }
      });

      eventKeeper.$on(this, 'focusout', document, '#inviteEmail', () => {
        const inputVal = $(this).val();
        const isSuccess = validateEmail(inputVal);

        if (!isSuccess) {
          $('#inviteEmail').focus();
          $('#inviteEmail').addClass('is-invalid');
          $('#notanEmail').css('display', 'block');
        } else {
          $('#inviteEmail').removeClass('is-invalid');
          $('#notanEmail').css('display', 'none');
        }
      });

      eventKeeper.$on(this, 'keyup', document, '#invitePhone', () => {
        this.value = this.value.replace(/[^+0-9]/g, '');
      });
    });
  },

  components: {
    Loader,
  },
};
</script>

<template v-slot:modal-body>
  <Drawer :direction="'right'" :exist="true" ref="modalDialog">
    <div class="h-drawer-max79">
      <div class="h-drawer-card">
        <div class="h-drawer-card-header h-align-items-center h-space-between h-padding-bottom-l">
          <h5 class="h-h3 h-primary_shade_1">Upload Billing Document</h5>
          <div>
            <button
              type="button"
              class="close"
              aria-hidden="true"
              @click="close"
              :disabled="loading"
            >
              <img src="../../../../../public/assets/images/Collapse-drawer.svg" alt="" />
            </button>
          </div>
        </div>
        <div class="h-drawer-card-body">
          <div v-for="index in fileCount" :key="index">
            <UploadFilePart
              ref="uploadFilePart"
              :hospitals="hospitals"
              :fileIndex="index"
              @uploadSuccess="uploadSuccess"
            />
            <hr style="margin-top: 25px; margin-bottom: 25px">
          </div>

          <div class="text-center">
            <button
              type="button"
              class="h-btn h-btn-safe"
              @click="incFileCount"
              :disabled="loading"
              style="margin-right: 10px"
            >
              More files
            </button>

            <button
              type="button"
              class="h-btn h-btn-dangerous"
              @click="decFileCount"
              :disabled="loading || (fileCount <= 1)"
            >
              Less files
            </button>
          </div>
        </div>
        <div class="h-drawer-card-footer">
          <button
            type="button"
            class="h-btn h-btn-accent"
            @click="upload"
            :disabled="loading || uploadFilePartLoading"
          >
            Upload files
          </button>
        </div>
      </div>
    </div>
  </Drawer>
</template>

<script>
import UploadFilePart from './UploadFilePart';

export default {
  data() {
    return {
      loading: true,
      fileCount: 1,
    };
  },

  computed: {
    hospitalChoices() {
      const choices = this.hospitals.map((hospital) => {
        return [hospital.id, hospital.name];
      });
      choices.unshift([null, '--- select hospital ---']);
      return choices;
    },

    uploadFilePartLoading() {
      return !!this.$refs.uploadFilePart.find(
        uploadFilePart => uploadFilePart.loading,
      );
    },
  },

  methods: {
    incFileCount() {
      this.fileCount += 1;
    },

    decFileCount() {
      this.fileCount -= 1;
    },

    uploadSuccess() {
      this.$emit('uploadSuccess');
    },

    show() {
      this.$refs.modalDialog.open();
      this.$refs.modalDialog.active = true;
      this.loading = false;
      this.fileCount = 1;
      this.$forceUpdate();
      this.$refs.uploadFilePart.forEach(
        uploadFilePart => uploadFilePart.reset(),
      );
    },

    close(force) {
      this.$refs.modalDialog.close(force);
      this.$refs.modalDialog.active = false;
    },

    async upload() {
      if (this.uploadFilePartLoading) return;
      const loadPrevented = !!this.$refs.uploadFilePart.find(
        uploadFilePart => uploadFilePart.isPreventingLoad,
      );
      if (loadPrevented) {
        $.notify(
          'Please fill in all required fealds.',
          { position: 'top center', className: 'error' },
        );
        return;
      }

      const promiseList = [];
      this.$refs.uploadFilePart.forEach((uploadFilePart) => {
        promiseList.push(
          uploadFilePart.upload(),
        );
      });
      await Promise.all(promiseList);
      this.close();
    },
  },

  components: {
    UploadFilePart,
  },

  props: {
    hospitals: {
      type: Array,
      required: true,
    },
  },
};
</script>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"h-drawer-max79"},[_c('Drawer',{ref:"modalDialog",attrs:{"direction":'right',"exist":true}},[_c('div',{staticClass:"h-drawer-card"},[_c('div',{staticClass:"h-drawer-card-header"},[_c('div',{staticClass:"h-main-drawer-card-header h-margin-bottom-l"},[_c('h3',{staticClass:"h-h3 h-primary_shade_1"},[_vm._v("Thought of the Day")]),_c('button',{staticClass:"close",attrs:{"type":"button","aria-hidden":"true","disabled":_vm.loading},on:{"click":_vm.close}},[_c('img',{attrs:{"src":"/assets/images/Collapse-drawer.svg","alt":""}})])])]),_c('div',{staticClass:"h-drawer-card-body"},[(!_vm.loading)?_c('div',[_c('div',{staticClass:"h-margin-bottom-l"},[_c('label',{staticClass:"h-label"},[_vm._v("Days before/after")]),_c('InputField',{attrs:{"type":"number","step":1,"error":_vm.errors.shift_in_days},on:{"input":function () {
                  _vm.errors.shift_in_days = null;
                }},model:{value:(_vm.item.shift_in_days),callback:function ($$v) {_vm.$set(_vm.item, "shift_in_days", $$v)},expression:"item.shift_in_days"}})],1),_c('div',{staticClass:"h-margin-bottom-l"},[_c('label',{staticClass:"h-label"},[_vm._v("Start date type")]),_c('InputField',{attrs:{"type":"select","choices":[
                  ['START_TYPE_PROTOCOL', 'Care protocol date'],
                  ['START_TYPE_CLIENT_PLAN', 'Client plan start'],
                  ['START_TYPE_SURGERY_DATE', 'Surgery date'] ]},model:{value:(_vm.item.start_date_type),callback:function ($$v) {_vm.$set(_vm.item, "start_date_type", $$v)},expression:"item.start_date_type"}})],1),_c('div',{staticClass:"h-margin-bottom-l"},[_c('label',{staticClass:"h-label"},[_vm._v("Is after")]),_c('InputField',{staticStyle:{"margin-bottom":"12rem"},attrs:{"type":"checkbox","checkboxSwitchType":"success","id":"editThoughtDialog-postOp"},model:{value:(_vm.item.post_op),callback:function ($$v) {_vm.$set(_vm.item, "post_op", $$v)},expression:"item.post_op"}}),(_vm.item.start_date_type === 'START_TYPE_PROTOCOL')?_c('div',{staticClass:"text-muted"},[_c('small',[_c('strong',[_vm._v("Note:")]),_vm._v(" When turned off and care protocol start date type is not \"Surgery date\", thought of the day will be shown on first date regardless of \"Days before/after\" value. ")])]):_vm._e()],1),_c('div',{staticClass:"h-margin-bottom-l"},[_c('label',{staticClass:"h-label"},[_vm._v("Local reminder time")]),_c('InputField',{staticStyle:{"margin-bottom":"2rem"},attrs:{"type":"time","id":"editThoughtDialog-remind_time"},model:{value:(_vm.item.remind_time),callback:function ($$v) {_vm.$set(_vm.item, "remind_time", $$v)},expression:"item.remind_time"}}),_c('div',{staticClass:"text-muted"},[_c('small',[_c('strong',[_vm._v("Note:")]),_vm._v(" If empty user daily reminder time (set in user settings in mobile app) will be used. ")])])],1),_c('div',{staticClass:"h-margin-bottom-l"},[_c('label',{staticClass:"h-label"},[_vm._v(" Push notification content ")]),_c('InputField',{attrs:{"type":"textarea","error":_vm.errors.notification_content,"maxlength":200,"rows":5},on:{"input":function () {
                  _vm.errors.notification_content = null;
                }},model:{value:(_vm.item.notification_content),callback:function ($$v) {_vm.$set(_vm.item, "notification_content", $$v)},expression:"item.notification_content"}}),_c('div',{staticClass:"h-h6 h-secondary_shade_1"},[_vm._v(" Max length: 200 ("+_vm._s(200 - (_vm.item.notification_content ? _vm.item.notification_content.length : 0))+" left) ")])],1),_c('div',{staticClass:"h-margin-bottom-l"},[_c('label',{staticClass:"h-label"},[_vm._v("Message content")]),_c('InputField',{attrs:{"type":"textarea","error":_vm.errors.content,"rows":10,"maxlength":400},on:{"input":function () {
                  _vm.errors.content = null;
                }},model:{value:(_vm.item.content),callback:function ($$v) {_vm.$set(_vm.item, "content", $$v)},expression:"item.content"}}),_c('div',{staticClass:"h-h6 h-secondary_shade_1"},[_vm._v(" Max length: 400 ("+_vm._s(400 - (_vm.item.content ? _vm.item.content.length : 0))+" left) ")])],1)]):_c('Loader')],1),_c('div',{staticClass:"h-drawer-card-footer"},[_c('button',{staticClass:"h-btn h-btn-accent",attrs:{"type":"button","disabled":_vm.loading},on:{"click":_vm.save}},[_vm._v(" Save ")]),_c('button',{staticClass:"h-btn h-btn-dangerous h-margin-left-s",attrs:{"type":"button","disabled":_vm.loading},on:{"click":_vm.close}},[_vm._v(" Close ")])])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }
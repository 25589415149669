<template>
  <div class="h-drawer-max79350">
    <Drawer :direction="'right'" :exist="true" ref="modalDialog">
      <div class="h-drawer-card">
        <div class="h-drawer-card-header">
          <div class="h-main-drawer-card-header h-margin-bottom-l">
            <h3 class="h-h3 h-primary_shade_1">Edit Item</h3>
            <button
              type="button"
              class="close"
              aria-hidden="true"
              @click="close"
              :disabled="loading"
            >
              <!-- <font-awesome-icon
                    :icon="['fas', 'sign-out-alt']"
                    style="height: 1.5vw; width: 1.5vw; color: #c3c4cb"
                  /> -->
              <img src="/assets/images/Collapse-drawer.svg" alt="" />
            </button>
          </div>
        </div>
        <div class="h-drawer-card-body h-padding-0">
          <div v-if="!loading && item">
            <div
              class="
                h-text-center h-h3
                h-primary_shade_1
                h-margin-bottom-l h-margin-top-l
              "
            >
              <strong>{{ item.name }}</strong>
            </div>
            <div class="h-margin-left-l h-margin-right-l">
              <div class="h-margin-bottom-l">
                <label class="h-label" for="checklist-item-name"> Name </label>
                <input
                  type="text"
                  v-model="name"
                  class="h-input"
                  :class="{ 'is-invalid': errors.name }"
                  id="checklist-item-name"
                />
                <div class="invalid-feedback" v-if="errors.name">
                  {{ errors.name }}
                </div>
              </div>

              <div class="h-margin-bottom-l">
                <label class="h-label" for="checklist-item-action">
                  Action
                </label>
                <input
                  type="text"
                  v-model="action"
                  class="h-input"
                  :class="{ 'is-invalid': errors.action }"
                  id="checklist-item-action"
                />
                <div class="invalid-feedback" v-if="errors.action">
                  {{ errors.action }}
                </div>
              </div>

              <div class="h-margin-bottom-l">
                <label class="h-label" for="checklist-item-video_link">
                  Video link
                </label>
                <input
                  type="text"
                  v-model="video_link"
                  class="h-input"
                  :class="{ 'is-invalid': errors.video_link }"
                  id="checklist-item-video_link"
                />
                <div class="invalid-feedback" v-if="errors.video_link">
                  {{ errors.video_link }}
                </div>
              </div>

              <div class="h-margin-bottom-l">
                <label class="h-label" for="checklist-item-url_link">
                  Url link
                </label>
                <input
                  type="text"
                  v-model="url_link"
                  class="h-input"
                  :class="{ 'is-invalid': errors.url_link }"
                  id="checklist-item-url_link"
                />
                <div class="invalid-feedback" v-if="errors.url_link">
                  {{ errors.url_link }}
                </div>
              </div>

              <div class="h-margin-bottom-l">
                <label class="h-label">
                  Description
                </label>
                <div class="h-ck100 h-toolbar h-drawer-maxfull h-edu">
                  <CKEditor
                    v-model="description"
                    :config="editorConfig"
                    :editor="editor"
                  ></CKEditor>
                </div>
                <div class="invalid-feedback" v-if="errors.description">
                  {{ errors.description }}
                </div>
              </div>
              <div class="h-align-items-center">
                <span class="h-h4 h-primary_shade_1 h-margin-right-s">Is appointment type</span>
                <input
                  type="checkbox"
                  style="height: 10rem; width: 10rem"
                  :id="`surgeryChecklistIsAppointmentType-${item.id}`"
                  v-model="item.is_appointment_type"
                />
                <label class="" :for="`surgeryChecklistIsAppointmentType-${item.id}`"></label>
              </div>
            </div>

            <div class="h-margin-top-xl">
              <div class="h-h4 h-primary_shade_1 h-margin-bottom-s h-margin-left-l">
                Start date
                <span class="h-h6 h-secondary_shade_1 h-margin-left-s"
                  >(Based on surgery date)</span
                >
              </div>
              <TimePartsSingleEditor :timeParts="item.start_seconds_before" />
            </div>

            <div class="h-margin-top-xl">
              <div class="h-h4 h-primary_shade_1 h-margin-bottom-s h-margin-left-l">
                Due date
                <span class="h-h6 h-secondary_shade_1 h-margin-left-s"
                  >(Based on surgery date)</span
                >
              </div>
              <TimePartsSingleEditor :timeParts="item.due_seconds_before" />
            </div>

            <div class="h-margin-top-xl">
              <div class="h-h4 h-primary_shade_1 h-margin-bottom-s h-margin-left-l">
                Staff alerts
                <span class="h-h6 h-secondary_shade_1 h-margin-left-s"
                  >(Based on surgery date)</span
                >
              </div>
              <TimePartsMultiEditor :timePartsList="item.staff_alerts" />
            </div>

            <div class="h-margin-top-xl">
              <div class="h-h4 h-primary_shade_1 h-margin-bottom-s h-margin-left-l">
                Patient Reminders
                <span class="h-h6 h-secondary_shade_1 h-margin-left-s"
                  >(Based on surgery date)</span
                >
              </div>
              <TimePartsMultiEditor :timePartsList="item.reminders" />
            </div>
            <div class="h-align-items-center h-margin-top-l h-margin-left-l">
              <span class="h-h4 h-primary_shade_1 h-margin-right-s">Active</span>
              <input
                type="checkbox"
                style="height: 10rem; width: 10rem"
                :id="`surgeryChecklistActive-${item.id}`"
                v-model="item.active"
              />
              <label class="" :for="`surgeryChecklistActive-${item.id}`"></label>
            </div>

            <div class="h-align-items-center h-margin-top-s h-margin-left-l">
              <span class="h-h4 h-primary_shade_1 h-margin-right-s">Notifications only</span>
              <input
                type="checkbox"
                style="height: 10rem; width: 10rem"
                :id="`is_notifications_only-${item.id}`"
                v-model="item.is_notifications_only"
              />
              <label class="" :for="`is_notifications_only-${item.id}`"></label>
            </div>

            <div class="h-align-items-center h-margin-top-s h-margin-left-l">
              <span class="h-h4 h-primary_shade_1 h-margin-right-s">Post-surgery</span>
              <input
                type="checkbox"
                style="height: 10rem; width: 10rem"
                :id="`surgeryChecklistPostSurgery-${item.id}`"
                v-model="item.post_surgery"
              />
              <label class="" :for="`surgeryChecklistPostSurgery-${item.id}`"></label>
            </div>
            <div
              class="
                h-h6
                h-primary_shade_1
                h-margin-top-s h-margin-left-l h-margin-bottom-l
              "
            >
              <strong>Note:</strong>
              The dates based on surgery date are set before the surgery date (for pre-surgery
              items) or after the surgery date (for post-surgery items).
            </div>
          </div>

          <Loader v-else />
        </div>

        <div class="h-drawer-card-footer">
          <button type="button" class="h-btn h-btn-accent" @click="saveItem" :disabled="loading">
            Save
          </button>

          <button
            type="button"
            class="h-btn h-btn-dangerous h-margin-left-l"
            @click="close"
            :disabled="loading"
          >
            Cancel
          </button>
        </div>
      </div>
    </Drawer>
  </div>
  <!-- /.modal -->
</template>

<style lang="stylus" scoped>
.timeParts-label {
  text-align: center;
  padding-top: 3em;
}

.row-info {
  margin-bottom: 1em;
}
</style>

<script>
import CKEditor from '@ckeditor/ckeditor5-vue2';
import * as customEditor from '../../../../../../../public/assets/js/ckeditor';
import Loader from '@/components/Loader';
import TimeParts from '@/components/time_parts/TimeParts';
import TimePartsSingleEditor from '@/components/time_parts/TimePartsSingleEditor';
import TimePartsMultiEditor from '@/components/time_parts/TimePartsMultiEditor';

export default {
  data() {
    return {
      loading: false,
      item: null,
      name: '',
      description: '',
      video_link: '',
      url_link: '',
      action: '',
      errors: {},
      editor: customEditor,
      editorConfig: {
        toolbar: {
          items: [
            'heading',
            '|',
            'bold',
            'italic',
            'link',
            'bulletedList',
            'numberedList',
            '|',
            'alignment',
            'fontColor',
            'blockQuote',
            '|',
            'imageInsert',
            'mediaEmbed',
            'insertTable',
            'horizontalLine',
            'undo',
            'redo',
          ],
          shouldNotGroupWhenFull: true,
        },
      },
    };
  },

  watch: {
    name() {
      this.errors.name = null;
    },
  },

  methods: {
    validateDates() {
      if (this.item.post_surgery) {
        if (this.item.start_seconds_before >= this.item.due_seconds_before) {
          $.notify('Due date must be after the start date for post-surgery items.', {
            position: 'top center',
            className: 'error',
          });
          return false;
        }
      } else if (this.item.start_seconds_before <= this.item.due_seconds_before) {
        $.notify('Start date must be after the due date for pre-surgery items.', {
          position: 'top center',
          className: 'error',
        });
        return false;
      }
      return true;
    },

    show(item) {
      this.$refs.modalDialog.open();
      this.$refs.modalDialog.active = true;
      this.loading = false;
      this.item = this.cloneItem(item);
      this.name = this.item.name;
      this.description = this.item.description || '';
      this.video_link = this.item.video_link;
      this.url_link = this.item.url_link;
      this.action = this.item.action;
      console.log(this.item.post_surgery);
    },

    cloneItem(item) {
      return {
        id: item.id,
        name: item.name,
        active: item.active,
        description: item.description,
        video_link: item.video_link,
        url_link: item.url_link,
        action: item.action,
        is_appointment_type: item.is_appointment_type,
        is_notifications_only: item.is_notifications_only,
        post_surgery: item.post_surgery,
        start_seconds_before: new TimeParts(item.start_seconds_before.getTotalSeconds()),
        due_seconds_before: new TimeParts(item.due_seconds_before.getTotalSeconds()),
        staff_alerts: item.staff_alerts.map((entry) => {
          return new TimeParts(entry.getTotalSeconds());
        }),
        reminders: item.reminders.map((entry) => {
          return new TimeParts(entry.getTotalSeconds());
        }),
      };
    },

    close(force) {
      if (!force && this.loading) return;
      this.$refs.modalDialog.close();
    },

    async saveItem() {
      if (!this.validateDates()) {
        return;
      }
      this.loading = true;

      // fron-end item to back-end item
      const backendItem = {
        name: this.name,
        description: this.description,
        video_link: this.video_link,
        url_link: this.url_link,
        action: this.action,
        is_appointment_type: this.item.is_appointment_type,
        is_notifications_only: this.item.is_notifications_only,
        start_seconds_before: this.item.start_seconds_before.getTotalSeconds() || null,
        due_seconds_before: this.item.due_seconds_before.getTotalSeconds() || null,
        staff_alerts: this.item.staff_alerts
          .map((entry) => {
            return entry.getTotalSeconds();
          })
          .filter((totalSeconds) => {
            return totalSeconds !== 0;
          }),
        reminders: this.item.reminders
          .map((entry) => {
            return entry.getTotalSeconds();
          })
          .filter((totalSeconds) => {
            return totalSeconds !== 0;
          }),
        active: this.item.active,
        post_surgery: this.item.post_surgery,
      };

      const response = await this.$api.updateCareProtocolItem(this.item.id, backendItem);
      if (response.status === 'error') {
        this.loading = false;
        this.errors = response.errors;
      } else {
        this.$emit('itemsUpdated', response.care_protocol_items);
        this.close(true);
      }
    },
  },

  components: {
    Loader,
    TimePartsSingleEditor,
    TimePartsMultiEditor,
    CKEditor: CKEditor.component,
  },
};
</script>

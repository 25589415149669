
    <template v-slot:modal-body>
  <div class="h-drawer-max79">
    <Drawer :direction="'right'" :exist="true" ref="modalDialog">
      <div class="h-drawer-card">
        <div
          class="
            h-drawer-card-header
            h-align-items-center
            h-space-between
            h-padding-bottom-l
          "
        >
          <h5 class="h-h3 h-primary_shade_1">Delete file</h5>
          <div>
            <button
              type="button"
              class="close"
              aria-hidden="true"
              @click="close"
              :disabled="loading"
            >
              <!-- <font-awesome-icon
                    :icon="['fas', 'sign-out-alt']"
                    style="height: 1.5vw; width: 1.5vw; color: #c3c4cb"
                  /> -->
              <img
                src="../../../../../../public/assets/images/Collapse-drawer.svg"
                alt=""
              />
            </button>
          </div>
        </div>
        <div class="h-drawer-card-body">
          <div class="h-h4 h-primary_shade_1" v-if="!loading && document">
            Do you really want to delete "{{ document.display_filepath }}"?
          </div>
        </div>
        <div class="h-drawer-card-footer">
          <button
            type="button"
            class="h-btn h-btn-accent"
            @click="confirm"
            :disabled="loading"
          >
            Delete
          </button>
        </div>
      </div>
    </Drawer>
  </div>
</template>


<script>
import settings from '@/settings.js';

export default {
  data() {
    return {
      loading: true,
      document: null,
    };
  },

  methods: {
    show(document) {
      this.$refs.modalDialog.open();
      this.$refs.modalDialog.active = true;
      this.document = document;
      this.loading = false;
      // this.$refs.slottedMessage.show();
    },

    close(force) {
      // this.$refs.slottedMessage.close(force);
      this.$refs.modalDialog.close(force);
      this.$refs.modalDialog.active = false;
    },

    async confirm() {
      this.loading = true;
      const response = await this.$api.post(
        `${settings.BACKEND_URL}/api/v-nurse/client/file/delete`,
        null,
        {
          id: this.document.id,
        },
      );
      this.$emit('setDocuments', response.documents);
      this.close(true);
    },
  },

  components: {
  },
};
</script>

<template>
  <tr>
    <td
      v-if="allowExpand"
      style="
        padding-left: 5rem !important;
        text-align: center;
        padding-right: 5rem !important;
      "
    >
      <i
        class="font-16 expand-row"
        :class="{
          'fas fa-plus-square text-success h-accent': !entry.EXPANDED,
          'fas fa-minus-square text-danger h-dangerous': entry.EXPANDED
        }"
        data-tooltip="Expand/collapse"
        @click="
          $emit('toggleExpand', entry)
          $forceUpdate(true)
        "
      ></i>
    </td>
    <td class="text-right">
      <input type="number" min="0" class="h-input" v-model="entry.start_days_after" />
    </td>
    <td class="text-right">
      <input type="number" min="0" class="h-input" v-model="entry.end_days_after" />
    </td>
    <td class="position-relative">
      <select id="category" class="h-input" v-model="category" @change="onChangeCategory(category)">
        <option v-for="category of categoryList" :key="category.value" :value="category.value">
          {{ category.title }}
        </option>
      </select>
    </td>
    <td class="position-relative">
      <select
        id="title"
        class="h-input"
        v-model="entry.therapy_pt"
        @change="onChangePT(entry.therapy_pt)"
      >
        <option key="item-null" :value="{}">
          {{ '---select---' }}
        </option>
        <option v-for="pt of filteredPTs" :key="pt.id" :value="pt">
          {{ pt.title }}
        </option>
      </select>
    </td>
    <td class="h-text-right">
      <input type="number" min="0" class="h-input" v-model="entry.sets" />
    </td>
    <td class="h-text-right">
      <input type="number" min="0" class="h-input" v-model="entry.hold" />
    </td>
    <td class="h-text-right">
      <input type="number" min="0" class="h-input" v-model="entry.repetition" />
    </td>
    <td class="h-text-left">
      <input type="text" class="h-input" v-model="entry.side" />
    </td>
    <td class="h-text-right">
      <input type="number" min="0" class="h-input" v-model="entry.times_per_day" />
    </td>
    <td class="h-text-right">
      <input type="number" min="0" class="h-input" v-model="entry.every_x_days" />
    </td>
    <td class="h-text-left">
      <i
        class="fas fa-trash-alt text-danger h-dangerous font-16"
        title="Remove"
        @click="$emit('remove', entry)"
      ></i>
    </td>
  </tr>
</template>

<style lang="stylus" scoped>
td:last-child i {
  cursor: pointer;
}

.expand-row {
  cursor: pointer;
}
</style>

<script>
import { PT_CATEGORY_LIST } from '@/utils/constants';

export default {
  props: ['entry', 'disabled', 'allowExpand', 'physicalPTs'],

  data() {
    return {
      categoryList: PT_CATEGORY_LIST,
      filteredPTs: [],
      category: this.entry.therapy_pt.category,
    };
  },

  watch: {
    physicalPTs() {
      this.onChangeCategory(this.category);
    },
  },

  methods: {
    onChangeCategory(category) {
      this.filteredPTs = this.physicalPTs.filter(i => (category ? i.category === category : i));
      return true;
    },

    onChangePT(pt) {
      this.category = pt.category;
      this.entry.sets = pt.start_days_after || this.entry.start_days_after;
      this.entry.sets = pt.end_days_after || this.entry.end_days_after;
      this.entry.sets = pt.sets || this.entry.sets;
      this.entry.hold = pt.hold || this.entry.hold;
      this.entry.repetition = pt.repetition || this.entry.repetition;
      this.entry.side = pt.side || this.entry.side;
      this.entry.times_per_day = pt.times_per_day || this.entry.times_per_day;
      this.entry.every_x_days = pt.every_x_days || this.entry.every_x_days;
    },
  },

  mounted() {
    this.onChangeCategory(this.category);
  },
};
</script>

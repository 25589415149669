<template>
  <div ref="wrapper">
    <h3 class="text-center mt-3"></h3>

    <div class="text-center mb-2 mt-2">
      <DateSelector @dateTimeChanged="dateTimeChanged" />
    </div>

    <table class="table w-100" ref="table">
      <thead class="thead-light">
        <tr>
          <th>Patient</th>
          <th>Age</th>
          <th>Email</th>
          <th>Date</th>
          <th>Care Protocol</th>
          <th>Surgeon</th>
          <th class="text-right">Action</th>
        </tr>
      </thead>
    </table>
  </div>
</template>

<script>
import moment from 'moment-timezone';
import DateSelector from '@/components/DateSelector';

export default {
  data() {
    const dateStart = moment();
    const dateEnd = moment(dateStart);
    return {
      dataTable: null,
      dateStart,
      dateEnd,
    };
  },

  methods: {
    reloadDataTable() {
      this.dataTable.ajax.reload();
    },

    dateTimeChanged(value) {
      this.dateStart = moment(value);
      this.dateEnd = moment(value);
      this.reloadDataTable();
    },
  },

  beforeDestroy() {
    if (this.dataTable) {
      this.dataTable.destroy(true);
      this.dataTable = null;
    }
  },

  async mounted() {
    const that = this;
    this.dataTable = $(this.$refs.table).DataTable({
      processing: true,
      serverSide: true,
      order: [
        [2, 'asc'],
        [0, 'asc'],
      ],
      ajax: (data, callback, tableSettings) => {
        const payload = JSON.parse(JSON.stringify(data));
        payload.surgeryDate = this.dateStart.format('YYYY-MM-DD');

        this.$api.getClients(payload).then((response) => {
          tableSettings.json = response;
          callback(response);
        });
      },
      columns: [
        {
          name: 'name',
          render: (data, type, row, meta) => {
            const rowIndex = meta.settings.json.data.indexOf(row);
            let photoLink = meta.settings.json.metadata[rowIndex].avatar_url;
            if (!photoLink) {
              photoLink = '/assets/images/users/patient-pro.png';
            }
            const path = this.$router.resolve({
              name: 'PatientProfile',
              params: {
                id: meta.settings.json.metadata[rowIndex].id,
              },
            });
            return `
              <a href="${path.href}" class="router-link">
                <img
                  src='${photoLink}'
                  alt='${that.$strUtils.escapeHtml(data)}'
                  class='thumb-sm rounded-circle mr-2'
                >
                ${that.$strUtils.escapeHtml(data)}
              </a>
            `;
          },
        },
        {
          name: 'age',
          searchable: false,
          render: data => (data ? that.$strUtils.escapeHtml(data) : null),
        },
        {
          name: 'email',
          render: (data, type, row, meta) => {
            if (data) {
              if (data.indexOf('@nomail.healent.com') !== -1) {
                return '';
              }
            }
            return data;
          },
        },
        {
          name: 'surgery_date',
          searchable: false,
          render: data => (data ? that.$strUtils.escapeHtml(data) : null),
        },
        {
          name: 'care_protocol__name',
          searchable: false,
          orderable: false,
          render: data => (data ? that.$strUtils.escapeHtml(data) : null),
        },
        {
          name: 'surgeon__name',
          searchable: false,
          orderable: false,
          render: data => (data ? that.$strUtils.escapeHtml(data) : null),
        },
        {
          name: 'action',
          searchable: false,
          orderable: false,
          render: (data, type, row, meta) => {
            const rowIndex = meta.settings.json.data.indexOf(row);
            const path = this.$router.resolve({
              name: 'EditPatientInfoTab',
              params: {
                id: meta.settings.json.metadata[rowIndex].id,
              },
            });
            return `
              <div class="text-right">
                <a href="${path.href}" class='mr-2 router-link'>
                  <i class='fas fa-edit text-info font-16' title='Edit'></i>
                </a>
              </div>
            `;
          },
        },
      ],
    });
  },

  components: {
    DateSelector,
  },
};
</script>

<template>
  <div class="h-drawer-max79">
    <Drawer
      ref="DeleteAppointmentDialog"
      :direction="'right'"
      :exist="true"
      title="Delete Appointment"
    >
      <div class="h-drawer-card">
        <div class="h-drawer-card-header">
          <div class="h-main-drawer-card-header h-margin-bottom-l">
            <h3 class="h-h3 h-primary_shade_1">Delete Appointment</h3>
            <button
              type="button"
              class="close"
              aria-hidden="true"
              @click="close"
              :disabled="loading"
            >
              <!-- <font-awesome-icon
                    :icon="['fas', 'sign-out-alt']"
                    style="height: 1.5vw; width: 1.5vw; color: #c3c4cb"
                  /> -->
              <img src="/assets/images/Collapse-drawer.svg" alt="" />
            </button>
          </div>
        </div>
        <div class="h-drawer-card-body">
          <div v-if="!loading" class="h-h4 h-primary_shade_1">
            Do you really want to <strong>delete</strong> the appointment?
          </div>

          <Loader v-if="loading" />
        </div>

        <div class="h-drawer-card-footer">
          <button type="button" class="h-btn h-btn-dangerous" @click="doDelete" :disabled="loading">
            Delete
          </button>

          <button
            type="button"
            class="h-btn h-btn-accent h-margin-left-l"
            @click="close"
            :disabled="loading"
          >
            Cancel
          </button>
        </div>
      </div>
    </Drawer>
  </div>
</template>

<script>
import Loader from '@/components/Loader';
import eventBus from '../../event-bus';

export default {
  data() {
    return {
      loading: false,
      meetingId: null,
    };
  },

  methods: {
    async doDelete() {
      this.loading = true;
      this.$refs.DeleteAppointmentDialog.close();
      await this.$api.deleteMeeting(this.meetingId);
      this.$emit('appointmentDeleted', this.meetingId);
      eventBus.$emit('refreshAppointmentsList');
      this.loading = false;
    },

    show(meetingId) {
      this.loading = false;
      this.meetingId = meetingId;
      this.$refs.DeleteAppointmentDialog.open(meetingId);
    },

    close(force) {
      if (!force && this.loading) return;
      this.$refs.DeleteAppointmentDialog.close();
      if (force) {
        $('.modal')
          .not($(this))
          .each(() => {
            // this.$refs.DeleteAppointmentDialog.close()
          });
      }
    },
  },

  components: {
    Loader,
  },
};
</script>

<template>
  <div class="h-padding-l">
    <div class="h-bg-w h-padding-l">
      <MealEditor
        viewer="page"
        :recipe="recipe"
        :ingredients="ingredients"
        @recipeSaved="recipeSaved"
        @scrollTop="scrollTop"
      />
    </div>
  </div>
</template>


<script>
import MealEditor from './components/meal_editor/MealEditor';

export default {
  data() {
    return {
      recipe: {
        recipe_id: null,
        image:
          'https://res.cloudinary.com/kitchry/image/upload/v1539944560/recipes/no-photo.png',
        total_servings: 1,
        tags: '',
      },
      ingredients: [],
    };
  },

  methods: {
    scrollTop() {
      $('html').scrollTop(0);
    },

    recipeSaved(recipe, ingredients) {
      this.recipe = recipe;
      this.ingredients = ingredients;
      this.$router.push({
        name: 'Recipe',
        params: {
          recipeId: recipe.recipe_id,
        },
      });
    },
  },

  mounted() {
    window.execAfterInitMenuInterface(() => {
      window.initActiveMenu('left-navigation-link>settings>recipe-page', false);
    });
  },

  components: {
    MealEditor,
  },
};
</script>

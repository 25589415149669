class NotificationBar {
  constructor() {
    this.component = null;
  }

  setComponent(component) {
    this.component = component;
  }

  checkPendingReports() {
    if (this.component) {
      this.component.checkPendingReports();
    }
  }
}

export default {
  install: (Vue) => {
    // eslint-disable-next-line
    Vue.prototype.$notificationBar = new NotificationBar();
  },
};

<template>
  <div class="h-drawer-max79350">
    <SlottedMessageInDrawer ref="slottedMessage" title="CCM protocols">
      <template v-slot:modal-body>
        <div v-if="localEntry" :class="{ 'd-none': loading }">
          <div
            class="h-margin-bottom-l h-margin-bottom-m"
          >
            <label class="h-label">Problem: </label>
            <InputField
              type="text"
              v-model="localEntry.problem"
              :error="errors.problem"
              @change="errors.problem = null"
            />
          </div>

          <div
            class="h-margin-bottom-l h-margin-bottom-m"
          >
            <label class="h-label">Trigger answers: </label>

            <div v-if="localEntry.question_answers.length">
              <div
                 v-for="(group, index) of localEntry.question_answers"
                :key="group.uuid"
              >
                <div class="text-center">
                  <label class="h-label" style="margin-top: 5rem">Trigger group: </label>
                </div>
                <div
                  class="d-flex align-items-center mbp"
                  style="align-items: flex-start !important;"
                >
                  <div class="flex-grow-1 mbp">
                    <div
                      v-if="group.answers.length === 0"
                      class="text-center"
                      style="color: var(--primary_shade_1);"
                    >
                      Empty
                    </div>
                    <div
                      class="align-items-center"
                      v-for="(answer, answerIndex) of group.answers"
                      :key="answer.uuid"
                    >
                      <div
                        class="d-flex align-items-center mbp"
                      >
                        <div style="width: 150rem">
                          <InputField
                            :classExtra="{ 'height-auto': true }"
                            type="select"
                            v-model="answer.question_id"
                            @change="errors.question_answers[answer.uuid] = null"
                            style="margin-right: 5rem"
                            :choices="questionChoices"
                          />
                        </div>
                        <InputField
                          class="flex-grow-1"
                          :classExtra="{ 'height-auto': true }"
                          type="select"
                          v-model="answer.answer_key"
                          :error="errors.question_answers[answer.uuid]"
                          @change="errors.question_answers[answer.uuid] = null"
                          :choices="answerKeys[answer.question_id] || [{
                            value: null,
                            title: '--- select ---',
                          }]"
                        />
                        <div>
                          <i
                            class="fas fa-trash-alt text-primary trash-icon"
                            title="Delete"
                            @click="delQuestionAnswersAnswer(group, answerIndex)"
                          ></i>
                        </div>
                      </div>
                    </div>

                    <div
                      style="color: var(--primary_shade_1); margin-top: 5rem"
                      class="text-center"
                    >
                      <button
                        type="button"
                        class="h-btn h-btn-accent btn-xs"
                        @click="addQuestionAnswersAnswer(group)"
                      >
                        Add answer
                      </button>
                    </div>
                  </div>

                  <i
                    class="fas fa-trash-alt text-danger trash-icon"
                    title="Delete"
                    @click="delQuestionAnswersGroup(index)"
                  ></i>
                </div>
              </div>
            </div>
            <button
              type="button"
              class="h-btn h-btn-accent btn-xs"
              @click="addQuestionAnswersGroup()"
            >
              Add trigger group
            </button>
          </div>

          <div
            class="h-margin-bottom-l h-margin-bottom-m"
          >
            <label class="h-label">Desired outcomes: </label>

            <div v-if="localEntry.desired_outcomes.length">
              <div
                class="d-flex align-items-center mbp"
                v-for="(outcome, index) of localEntry.desired_outcomes"
                :key="outcome.uuid"
              >
                <InputField
                  class="flex-grow-1"
                  :classExtra="{ 'height-auto': true }"
                  type="textarea"
                  v-model="outcome.value"
                  :error="errors.desired_outcomes[outcome.uuid]"
                  :rows="4"
                  @change="errors.desired_outcomes[outcome.uuid] = null"
                />
                <i
                  class="fas fa-trash-alt text-danger trash-icon"
                  title="Delete"
                  @click="delDesiredOutcome(index)"
                ></i>
              </div>
            </div>
            <button
              type="button"
              class="h-btn h-btn-accent btn-xs"
              @click="addDesiredOutcome()"
            >
              Add desired outcome
            </button>
          </div>

          <div
            class="h-margin-bottom-l h-margin-bottom-m"
          >
            <label class="h-label">Nursing interventions: </label>

            <div v-if="localEntry.nursing_interventions.length">
              <div
                class="d-flex align-items-center mbp"
                v-for="(intervention, index) of localEntry.nursing_interventions"
                :key="intervention.uuid"
              >
                <InputField
                  class="flex-grow-1"
                  :classExtra="{ 'height-auto': true }"
                  type="textarea"
                  v-model="intervention.value"
                  :error="errors.nursing_interventions[intervention.uuid]"
                  :rows="4"
                  @change="errors.nursing_interventions[intervention.uuid] = null"
                />
                <i
                  class="fas fa-trash-alt text-danger trash-icon"
                  title="Delete"
                  @click="delNursingIntervention(index)"
                ></i>
              </div>
            </div>
            <button
              type="button"
              class="h-btn h-btn-accent btn-xs"
              @click="addNursingIntervention()"
            >
              Add nursing intervention
            </button>
          </div>

          <button
            type="button"
            class="h-btn h-btn-accent"
            @click="save"
            :disabled="loading"
          >
            Save
          </button>
        </div>
        <Loader v-if="loading" />
      </template>

      <template v-slot:modal-footer>
        <button
          type="button"
          class="h-btn h-btn-accent"
          @click="save"
          :disabled="loading"
        >
          Save
        </button>
        <button
          type="button"
          class="h-btn h-btn-dangerous h-margin-left-s"
          @click="close"
          :disabled="loading"
        >
          Cancel
        </button>
      </template>
    </SlottedMessageInDrawer>
  </div>
</template>

<style lang="stylus" scoped>
.modal-dialog
  max-width: 1200px

.trash-icon
  font-size: 10rem
  margin-left: 10rem
  cursor: pointer

.mbp
  margin-bottom: 4rem
</style>

<script>
import settings from '@/settings.js';
import Loader from '@/components/Loader';
import SlottedMessageInDrawer from '@/components/dialogs/SlottedMessageInDrawer';
import InputField from '@/components/InputField';

function getEmptyErrors() {
  return {
    desired_outcomes: {},
    nursing_interventions: {},
    question_answers: {},
  };
}

export default {
  data() {
    return {
      localEntry: null,
      errors: getEmptyErrors(),
      loading: false,
    };
  },

  computed: {
    questionChoices() {
      const result = this.questions.map((question) => {
        return {
          value: question.id.toString(),
          title: question.question,
        };
      });
      result.unshift({
        value: null,
        title: '--- select ---',
      });
      return result;
    },

    answerKeys() {
      const result = {};
      this.questions.forEach((question) => {
        result[question.id] = question.answers.map((answer) => {
          return {
            value: answer.key,
            title: answer.value,
          };
        });
        result[question.id].unshift({
          value: null,
          title: '--- select ---',
        });
      });
      return result;
    },
  },

  methods: {
    async save() {
      this.loading = true;
      const response = await this.$api.post(
        `${settings.BACKEND_URL}/api/v-nurse/microprotocols/upsert`,
        null,
        {
          data: this.localEntry,
        },
      );
      this.loading = false;
      if (response.status === 'ok') {
        this.errors = getEmptyErrors();
        this.$emit('setItems', response.microprotocols);
        this.close(true);
      } else {
        this.errors = Object.assign(getEmptyErrors(), response.errors);
        console.log('errors', this.errors);
      }
    },

    show(entry) {
      this.errors = getEmptyErrors();
      this.localEntry = JSON.parse(JSON.stringify(entry));

      this.localEntry.desired_outcomes = this.localEntry.desired_outcomes ?? [];
      this.localEntry.desired_outcomes.forEach((item) => {
        item.uuid = item.uuid ?? this.$uuid.v4();
      });

      this.localEntry.nursing_interventions = this.localEntry.nursing_interventions ?? [];
      this.localEntry.nursing_interventions.forEach((item) => {
        item.uuid = item.uuid ?? this.$uuid.v4();
      });

      this.localEntry.question_answers = this.localEntry.question_answers ?? [];
      this.localEntry.question_answers.forEach((item) => {
        item.uuid = item.uuid ?? this.$uuid.v4();
      });

      this.loading = false;
      this.$refs.slottedMessage.show();
    },

    close(force) {
      this.$refs.slottedMessage.close(force);
      this.$refs.slottedMessage.active = false;
    },

    addDesiredOutcome() {
      this.localEntry.desired_outcomes.push({
        uuid: this.$uuid.v4(),
      });
      this.localEntry = { ...this.localEntry };
    },

    delDesiredOutcome(index) {
      this.localEntry.desired_outcomes.splice(index, 1);
      this.localEntry = { ...this.localEntry };
    },

    addNursingIntervention() {
      this.localEntry.nursing_interventions.push({
        uuid: this.$uuid.v4(),
      });
      this.localEntry = { ...this.localEntry };
    },

    delNursingIntervention(index) {
      this.localEntry.nursing_interventions.splice(index, 1);
      this.localEntry = { ...this.localEntry };
    },

    addQuestionAnswersGroup() {
      this.localEntry.question_answers.push({
        uuid: this.$uuid.v4(),
        answers: [],
      });
      this.localEntry = { ...this.localEntry };
    },

    delQuestionAnswersGroup(index) {
      this.localEntry.question_answers.splice(index, 1);
      this.localEntry = { ...this.localEntry };
    },

    addQuestionAnswersAnswer(group) {
      group.answers.push({
        uuid: this.$uuid.v4(),
        question_id: null,
        answer_key: null,
      });
      this.localEntry = { ...this.localEntry };
    },

    delQuestionAnswersAnswer(group, answerIndex) {
      group.answers.splice(answerIndex, 1);
      this.localEntry = { ...this.localEntry };
    },
  },
  components: {
    Loader,
    SlottedMessageInDrawer,
    InputField,
  },
  props: ['questions'],
};
</script>

<template>
  <div>
    <div v-if="!loading">
      <div v-if="careProtocolChoices.length !== 0" class="row filters-wrapper d-print-none">
        <div class="col-md-6 col-sm-12">
          <label class="w-100">
            Care protocol:
            <InputField
              type="select"
              :choices="careProtocolChoices"
              v-model="selectedCareProtocolId"
            />
          </label>
        </div>
      </div>

      <div v-if="selectedCareProtocol" class="selectedCareProtocol-wrapper globalTopMargin">
        <h4 class="text-center">
          <span class="text-muted">PT Compliance:</span>
          {{ selectedCareProtocol.name }}
        </h4>
        <table
          class="table compliance-table mb-0"
          :class="{ 'table-hover': selectedCareProtocol.items.length !== 0 }"
        >
          <thead class="thead-light">
            <tr>
              <th class="text-center w-1 text-nowrap">
                <div class="h-d-flex h-align-items-center">
                  <span>SDA</span>
                  <i
                    class="
                    h-font20 d-print-none
                    h-line-height-1 h-bold h-accent
                    healenticons-with-alerts h-cursor-pointer h-padding-left-xs"
                    data-tooltip="Start day after"
                  ></i>
                </div>
              </th>
              <th class="text-center w-1 text-nowrap">
                <div class="h-d-flex h-align-items-center">
                  <span>EDA</span>
                  <i
                    class="
                    h-font20 d-print-none
                    h-line-height-1 h-bold h-accent
                    healenticons-with-alerts h-cursor-pointer h-padding-left-xs"
                    data-tooltip="End day after"
                  ></i>
                </div>
              </th>
              <th>Title</th>
              <th class="text-right">Compliance</th>
            </tr>
          </thead>

          <tbody v-if="selectedCareProtocol.items.length !== 0">
            <tr v-for="entry of selectedCareProtocol.items" :key="entry.id">
              <td class="text-center">
                {{ entry.start_days_after }}
              </td>
              <td class="text-center">
                {{ entry.end_days_after }}
              </td>
              <td>
                {{ entry.therapy_pt.title }}
              </td>
              <td :class="getComplianceClass(entry.compliance) + ' text-right'">
                {{ entry.compliance !== null ? (100 * entry.compliance).toFixed(1) + '%' : '-' }}
              </td>
            </tr>
          </tbody>

          <tbody v-else>
            <tr>
              <td class="text-center" colspan="100">No entries found</td>
            </tr>
          </tbody>
        </table>

        <hr class="mt-0" />

        <div v-if="selectedCareProtocol.items.length !== 0" class="text-center mt-4">
          <button type="button" class="btn btn-primary print-button d-print-none" @click="print">
            Print
          </button>
        </div>
      </div>

      <div v-if="!selectedCareProtocol" class="text-center text-muted globalTopMargin">
        <hr />
        Care protocol not selected.
        <hr />
      </div>

      <div v-if="careProtocolChoices.length === 0" class="text-center text-muted globalTopMargin">
        <hr />
        No protocols found
        <hr />
      </div>
    </div>

    <div v-else class="globalTopMargin">
      <Loader />
    </div>
  </div>
</template>

<style lang="stylus" scoped>
.globalTopMargin {
  margin-top: 3em;
}

@media print {
  .globalTopMargin {
    margin-top: 0 !important;
  }
}

.selectedCareProtocol-wrapper {
  margin-bottom: 3em;

  table, hr {
    max-width: 800px;
    margin: 0;
    margin-left: auto;
    margin-right: auto;
  }
}

.print-button {
  width: 200px;
}
</style>

<script>
import Loader from '@/components/Loader';
import InputField from '@/components/InputField';

export default {
  props: ['filterValues'],

  data() {
    return {
      loading: true,
      compliance: [],
      selectedCareProtocolId: this.filterValues.selectedCareProtocolId,
    };
  },

  watch: {
    selectedCareProtocolId() {
      this.$emit('updateFilterValues', {
        selectedCareProtocolId: parseInt(this.selectedCareProtocolId, 10),
      });
    },
  },

  computed: {
    careProtocolChoices() {
      const result = this.compliance.map((careProtocol) => {
        return [careProtocol.id, careProtocol.name];
      });
      result.unshift([null, '--- Select care protocol ---']);
      return result;
    },

    selectedCareProtocol() {
      const result = this.compliance.find((careProtocol) => {
        return careProtocol.id === parseInt(this.selectedCareProtocolId, 10);
      });
      return result;
    },
  },

  methods: {
    getComplianceClass(compliance) {
      if (compliance === null) {
        return '';
      }
      if (100 * compliance < 50) {
        return 'text-danger';
      }
      if (100 * compliance < 75) {
        return 'text-warning';
      }

      return 'text-success';
    },

    print() {
      print();
    },
  },

  async mounted() {
    this.compliance = (await this.$api.compliancePt()).result;
    this.loading = false;
  },

  components: {
    Loader,
    InputField,
  },
};
</script>

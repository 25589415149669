<template>
  <div>
    <div
      class="h-padding-top-l h-padding-left-l h-padding-right-l
      h-space-between h-align-items-center "
    >
      <h5 class="h-h3 h-primary_shade_1">
        CCM Protocols
      </h5>
      <div>
        <button
          type="button"
          class="h-btn h-btn-accent h-margin-right-m h-btn-60"
          @click="EditMicroprotocols"
        >
          Edit
        </button>
        <button type="button" class="h-btn h-btn-accent" @click="manageMicroprotocols">
          Manage
        </button>
      </div>
    </div>

    <div v-if="!loading" class="h1-table h-read-only">
      <table class="table dataTable h-noborder-input h-ccm-table" style="display: table">
        <thead class="thead-light">
          <tr>
            <th style="width: 5%;">Problem</th>
            <th style="width: 45%;">Desired outcomes</th>
            <th style="width: 45%;">Nursing interventions</th>
            <th style="width: 5%;">Status</th>
          </tr>
        </thead>

        <tbody v-if="microprotocols.length !== 0">
          <tr v-for="microprotocol of microprotocols" :key="microprotocol.id">
            <td>
              {{ microprotocol.problem }}
            </td>

            <td>
              <span v-if="!microprotocol.visible_desired_outcomes.length">-</span>
              <ul v-else style="list-style: none">
                <li
                  v-for="(desired_outcome, index) of microprotocol.visible_desired_outcomes"
                  :key="desired_outcome.uuid"
                >
                  <div
                    class="read-only"
                    :class="{
                      'section-with-switcher':
                        index !== microprotocol.visible_desired_outcomes.length - 1
                    }"
                  >
                    <InputField
                      :id="
                        `microprotocols::${microprotocol.id}
                      ::desired_outcome::${desired_outcome.uuid}`
                      "
                      type="checkbox"
                      v-model="microprotocol.marked_desired_outcomes[desired_outcome.uuid]"
                      :disabled="loading"
                      :label="desired_outcome.value"
                      labelClass="white-space-pre-wrap"
                      @change="hasChanges = true"
                      class="h-margin-bottom-s h-margin-top-s read-only"
                    />
                    <InputField
                      type="select"
                      v-model="microprotocol.desired_outcomes_statuses[desired_outcome.uuid]"
                      :disabled="loading"
                      @change="hasChanges = true"
                      :dense="true"
                      :choices="['Not started', 'In progress', 'Completed']"
                    />
                  </div>
                </li>
              </ul>
            </td>

            <td>
              <span v-if="!microprotocol.visible_nursing_interventions.length">-</span>
              <ul v-else style="list-style: none">
                <li
                  v-for="nursing_intervention of microprotocol.visible_nursing_interventions"
                  :key="nursing_intervention.uuid"
                >
                  <div class="h-margin-bottom-s read-only">
                    <InputField
                      :id="
                        `microprotocols::${microprotocol.id}
                      ::nursing_intervention::${nursing_intervention.uuid}`
                      "
                      type="checkbox"
                      v-model="
                        microprotocol.marked_nursing_interventions[nursing_intervention.uuid]
                      "
                      :disabled="loading"
                      :label="nursing_intervention.value"
                      labelClass="white-space-pre-wrap"
                      @change="hasChanges = true"
                    />
                  </div>
                </li>
              </ul>
            </td>
            <td style="min-width: 50rem" class="read-only">
              <InputField
                type="select"
                v-model="microprotocol.status"
                :disabled="loading"
                @change="hasChanges = true"
                :dense="true"
                :choices="[[null, '<no_value>'], 'Not started', 'In progress', 'Completed']"
                class="h-margin-bottom-s"
              />
            </td>
          </tr>
        </tbody>

        <tbody v-else>
          <tr>
            <td class="text-center" colspan="100">No entries</td>
          </tr>
        </tbody>
      </table>
    </div>

    <Loader v-else />

    <MicroprotocolsManagerDialog
      ref="microprotocolsManagerDialog"
      :allMicroprotocols="allMicroprotocols"
      :clientMicroprotocols="microprotocols"
      :selectedMicroprotocols="microprotocols"
      :clientId="clientId"
      @save="setMicroprotocols"
    />
    <EditMicroprotocols ref="EditMicroprotocols" :clientId="clientId" />
  </div>
</template>

<style scoped>
.section-with-switcher {
  padding-bottom: 5rem;
  margin-bottom: 10rem;
  border-bottom: 1px solid silver;
}
</style>

<script>
import settings from '@/settings.js';
import Loader from '@/components/Loader';
import MicroprotocolsManagerDialog from './MicroprotocolsManagerDialog';
import EditMicroprotocols from './EditMicroprotocols';
import InputField from '@/components/InputField';
import eventBus from '@/event-bus';

export default {
  data() {
    return {
      loading: true,
      microprotocols: [],
      allMicroprotocols: [],
      hasChanges: false,
    };
  },

  components: {
    Loader,
    MicroprotocolsManagerDialog,
    EditMicroprotocols,
    InputField,
  },

  methods: {
    async load() {
      this.loading = true;
      const response = await this.$api.get(
        `${settings.BACKEND_URL}/api/v-nurse/microprotocols/client`,
        {
          client_id: this.clientId,
        },
      );
      this.microprotocols = response.microprotocols ?? [];

      const allMicroprotocolsResponse = await this.$api.get(
        `${settings.BACKEND_URL}/api/v-nurse/microprotocols`,
      );
      this.allMicroprotocols = allMicroprotocolsResponse.microprotocols;
      this.loading = false;
    },

    manageMicroprotocols() {
      this.$refs.microprotocolsManagerDialog.show();
    },
    EditMicroprotocols() {
      this.$refs.EditMicroprotocols.show();
    },
    setMicroprotocols(microprotocols) {
      this.microprotocols = microprotocols ?? [];
      this.hasChanges = false;
    },

    async saveOutcomeInterventionMarks() {
      this.loading = true;
      const microprotocolMarks = {};
      this.microprotocols.forEach((microprotocol) => {
        microprotocolMarks[microprotocol.id] = {
          marked_desired_outcomes: microprotocol.marked_desired_outcomes,
          marked_nursing_interventions: microprotocol.marked_nursing_interventions,
          desired_outcomes_statuses: microprotocol.desired_outcomes_statuses,
          status: microprotocol.status,
        };
      });
      const response = await this.$api.post(
        `${settings.BACKEND_URL}/api/v-nurse/microprotocols/client/set-marks`,
        null,
        {
          data: {
            client_id: this.clientId,
            microprotocol_marks: microprotocolMarks,
          },
        },
      );
      this.setMicroprotocols(response.microprotocols);
      this.loading = false;
    },
    async reload() {
      await this.load();
    },
  },

  async mounted() {
    await this.load();
    eventBus.$on('CloseEditCCM', this.reload, this);
  },

  props: {
    clientId: {
      type: Number,
      required: false,
    },
  },
};
</script>

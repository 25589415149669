<template>
  <div v-if="careProtocol">
    <!-- Page-Title -->

    <!-- end page title end breadcrumb -->

    <div class="h-margin-bottom-l">
      <div class="h-bg-w h-padding-top-l h-padding-right-l h-padding-left-l">
        <div v-if="!loading">
          <div class="h-space-between h-align-items-center">
            <h3 class="h-h3 h-primary_shade_1">Care protocol details</h3>
            <div v-if="isTemplateMode" class="h-h3">
              <i
                class="healenticons-edit h-accent h-cursor-pointer"
                title="Edit"
                @click="showEditCareProtocolDialog"
              ></i>
            </div>
          </div>
          <div class="h1-table">
            <table class="table dataTable h-table-v h-info-table">
              <tr>
                <th>Name:</th>
                <td>
                  {{ careProtocol.name }}
                </td>
              </tr>

              <tr>
                <th>Start date type:</th>
                <td>
                  {{ strUtils.underscoreCaseToName(careProtocol.start_date_type) }}
                </td>
              </tr>

              <tr>
                <th>Top title header:</th>
                <td>
                  <span v-if="careProtocol.tile_header">
                    {{ careProtocol.tile_header }}
                  </span>
                  <span v-else class="text-muted"> &lt;None&gt; </span>
                </td>
              </tr>

              <tr>
                <th>Top title description:</th>
                <td>
                  <span v-if="careProtocol.tile_description">
                    {{ careProtocol.tile_description }}
                  </span>
                  <span v-else class="text-muted"> &lt;None&gt; </span>
                </td>
              </tr>

              <tr>
                <th>Top title link:</th>
                <td>
                  <span v-if="careProtocol.top_title_link">
                    {{ careProtocol.top_title_link }}
                  </span>
                  <span v-else class="text-muted"> &lt;None&gt; </span>
                </td>
              </tr>

              <tr>
                <th>Send daily notifications:</th>
                <td>
                  {{ careProtocol.send_daily_notifications }}
                </td>
              </tr>

              <tr>
                <th>Send weekly notifications:</th>
                <td>
                  {{ careProtocol.send_weekly_notifications }}
                </td>
              </tr>

              <tr>
                <th>Hospital specific:</th>
                <td>
                  {{ careProtocol.hospital ? careProtocol.hospital.name : careProtocol.is_global }}
                </td>
              </tr>
            </table>
          </div>
        </div>

        <Loader v-else />
      </div>
    </div>
    <div class="h-margin-bottom-l">
      <div class="h-bg-w h-padding-top-l h-padding-right-l h-padding-left-l">
        <div v-if="!loading">
          <OnboardingScreenEditor
            :careProtocolId="careProtocol.id"
            :initial-onboarding-screen="careProtocol.onboarding_screen"
            @update:onboardingScreen="handleOnboardingScreenUpdate"
          ></OnboardingScreenEditor>
        </div>
        <Loader v-else />
      </div>
    </div>
    <div v-if="!loading">
      <!-- Care protocol Items -->
      <div class="h-bg-w h-margin-bottom-l h-padding-bottom-l h-padding-top-l">
        <CareProtocolItems
          :careProtocol="careProtocol"
          :isTemplateMode="isTemplateMode"
          :doctor="doctor"
        />
        <AvailableOptionBadges
          :options="getEditableOptions(true, true, false)"
          class="h-text-center"
          @click="showCpItemsBadges"
        />
      </div>

      <!-- Care protocol education -->
      <!--       <div class="h-bg-w h-margin-bottom-l h-padding-bottom-l h-padding-top-l">
        <EducationList
          :collectionId="careProtocol.learn_collection_id"
          :isTemplateMode="isTemplateMode"
          :doctor="doctor"
        />
        <AvailableOptionBadges
          :options="getEditableOptions(true, true, false)"
          class="h-text-center"
          @click="$refs.cpEducationBadges.show"
        />
      </div> -->

      <!-- Therapy PT -->
      <div class="h-bg-w h-margin-bottom-l h-padding-bottom-l h-padding-top-l">
        <TherapyPt
          :careProtocolId="careProtocol.id"
          :careProtocolMode="true"
          :isTemplateMode="isTemplateMode"
          :doctor="doctor"
        />
        <AvailableOptionBadges
          :options="getEditableOptions(true, true, false)"
          class="h-text-center"
          @click="showCpEducationBadges"
        />
      </div>

      <!-- Assessment -->
      <div class="h-bg-w h-margin-bottom-l h-padding-bottom-l h-padding-top-l">
        <Assesment
          :careProtocolId="careProtocol.id"
          :doctorId="parseInt(this.doctorId, 10)"
          :isTemplateMode="isTemplateMode"
          :careProtocolMode="true"
        />
        <AvailableOptionBadges
          :options="getEditableOptions(true, true, false)"
          class="h-text-center"
          @click="showCpEducationBadges"
        />
      </div>

      <!-- HealthStatus -->
      <div
        v-if="!doctor"
        class="
          h-bg-w h-margin-bottom-l h-padding-bottom-l h-padding-top-l h-relative
        "
      >
        <h3
          class="
            h-report-table-title h-report-table-title h-h3
            h-primary_shade_1
            h-padding-left-l
          "
        >
          Health status
          <span class="h-h6 h-margin-left-s">
            <span v-if="doctor" class="h-secondary_shade_1">
              [Doctor: {{ doctor.display_name }}]
            </span>
            <span v-else class="h-secondary_shade_1"> [Template] </span>
          </span>
        </h3>
        <HealthStatus :holder="careProtocol" :cpMode="true" />
      </div>

      <!-- Thought of the day -->
      <div
        class="
          h-bg-w h-margin-bottom-l h-padding-bottom-l h-padding-top-l h-relative
        "
      >
        <h3
          class="
            h-report-table-title h-report-table-title h-h3
            h-primary_shade_1
            h-padding-left-l
          "
        >
          Thoughts of the Day
          <span class="h-h6 h-margin-left-s">
            <span v-if="doctor" class="h-secondary_shade_1">
              [Doctor: {{ doctor.display_name }}]
            </span>
            <span v-else class="h-secondary_shade_1"> [Template] </span>
          </span>
        </h3>
        <ThoughtOfTheDay
          :careProtocolId="careProtocol.id"
          :doctorId="parseInt(this.doctorId, 10)"
        />
        <AvailableOptionBadges
          :options="getEditableOptions(true, true, false)"
          class="pb-3 text-center"
          @click="showCpThoughtForTheDayBadges"
        />
      </div>
    </div>

    <!-- isDoctor-note -->
    <!-- <div class="isDoctor-note" v-if="!isTemplateMode">
      You're editing care protocol of
      <span v-if="doctor">
        <u>Dr. {{ doctor.display_name }}</u
        >.
      </span>
    </div>
    <div class="isDoctor-note template" v-else>
      You're editing an organization level template
    </div> -->

    <EditCareProtocolDialog
      ref="editCareProtocolDialog"
      :defaultCareProtocol="careProtocol"
      :hospitalChoices="hospitalChoices"
      @protocolUpdated="protocolUpdated"
    />

    <SlottedMessageInDrawer ref="cpItemsBadges" title="Edit options">
      <template v-slot:modal-body>
        <div class="h-h3 h-primary_shade_1">
          <strong>Surgery Checklist editting:</strong>
        </div>
        <ul class="h-h6 h-margin-left-m h-margin-top-s h-primary_shade_1">
          <li class="h-margin-bottom-s">
            In care protocol template care protocol items can be fully editted.
          </li>
          <li class="h-margin-bottom-s">
            In doctor care protocol items can only be switched on and off.
          </li>
          <li class="h-margin-bottom-s">
            Item addition and removal in templates automatically propogated to all doctors. New
            items automatically enabled in doctor care protocols.
          </li>
        </ul>
      </template>
    </SlottedMessageInDrawer>

    <SlottedMessageInDrawer ref="cpEducationBadges" title="Edit options">
      <template v-slot:modal-body>
        <div class="h-h3 h-primary_shade_1">
          <strong>Care Protocol Education editting:</strong>
        </div>
        <ul class="h-h6 h-margin-left-m h-margin-top-s h-primary_shade_1">
          <li class="h-margin-bottom-s">
            In care protocol template care protocol education items can be fully editted.
          </li>
          <li class="h-margin-bottom-s">
            In doctor care protocol items can only be switched on and off.
          </li>
          <li class="h-margin-bottom-s">
            Item addition and removal in templates automatically propogated to all doctors. New
            items automatically enabled in doctor care protocols.
          </li>
        </ul>
      </template>
    </SlottedMessageInDrawer>

    <SlottedMessageInDrawer ref="cpTherapyPtBadges" title="Edit options">
      <template v-slot:modal-body>
        <div class="h-h3 h-primary_shade_1">
          <strong>Therapy PT editing:</strong>
        </div>
        <ul class="h-h6 h-margin-left-m h-margin-top-s h-primary_shade_1">
          <li class="h-margin-bottom-s">
            In care protocol template therapy PT items can be fully editted.
          </li>
          <li class="h-margin-bottom-s">
            In doctor care protocol items can only be switched on and off.
          </li>
          <li class="h-margin-bottom-s">
            In pations' care protocols start time, end time, and title not edditable, everything
            else can be changed. Item addition and removal not allowed.
          </li>
          <li class="h-margin-bottom-s">
            Item addition and removal in templates automatically propogated to all doctors. New
            items automatically enabled in doctor care protocols.
          </li>
        </ul>
      </template>
    </SlottedMessageInDrawer>

    <SlottedMessageInDrawer ref="cpThoughtForTheDayBadges" title="Edit options">
      <template v-slot:modal-body>
        <div class="h-h3 h-primary_shade_1">
          <strong>Thought for the day:</strong>
        </div>
        <ul class="h-h6 h-margin-left-m h-margin-top-s h-primary_shade_1">
          <li class="h-margin-bottom-s">
            In care protocol template items can be fully editted.
          </li>
          <li class="h-margin-bottom-s">
            In doctor care protocol items can be fully editted.
          </li>
          <li class="h-margin-bottom-s">
            Templated and doctor thoughts of the day are independant, but you can use "Clone
            existing item" button to import/export thoughts for the day from other care protocols.
          </li>
        </ul>
      </template>
    </SlottedMessageInDrawer>

    <SlottedMessageInDrawer ref="cpAssesmentBadges" title="Edit options">
      <template v-slot:modal-body>
        <div class="h-h3 h-primary_shade_1">
          <strong>Assessment:</strong>
        </div>
        <ul class="h-h6 h-margin-left-m h-margin-top-s h-primary_shade_1">
          <li class="h-margin-bottom-s">
            In care protocol template items can be fully editted.
          </li>
          <li class="h-margin-bottom-s">
            In doctor care protocol items can be fully editted.
          </li>
          <li class="h-margin-bottom-s">
            Item addition and removal in templates automatically propogated to all doctors. New
            items automatically enabled in doctor care protocols.
          </li>
        </ul>
      </template>
    </SlottedMessageInDrawer>
  </div>
</template>

<style lang="stylus" scoped></style>

<script>
import strUtils from '@/utils/str_utils';
import Loader from '@/components/Loader';
import CareProtocolItems from './components/care_protocol_items/CareProtocolItems';
import ThoughtOfTheDay from '@/components/thought_of_the_day/ThoughtOfTheDay';
import EditCareProtocolDialog from './components/EditCareProtocolDialog';
import SlottedMessageInDrawer from '@/components/dialogs/SlottedMessageInDrawer';
import TherapyPt from '@/components/therapy_pt/TherapyPt';
import Assesment from '@/components/assesment/Assesment';
import AvailableOptionBadges from '@/components/AvailableOptionBadges';
import HealthStatus from '@/components/health_status/HealthStatus';
import OnboardingScreenEditor from './components/OnboardingScreenEditor';

export default {
  props: ['protocolId', 'doctorId', 'hospitalChoices'],
  data() {
    return {
      strUtils,
      loading: false,
      careProtocol: null,
      doctor: null,
    };
  },

  computed: {
    isTemplateMode() {
      return !this.doctorId;
    },
  },

  beforeMount() {},

  watch: {
    protocolId() {
      this.reload();
    },

    async $route(to, from) {
      await this.reload();
    },
  },

  methods: {
    handleOnboardingScreenUpdate(updatedScreen) {
      this.careProtocol.onboarding_screen = updatedScreen;
    },
    getEditableOptions(template, doctor, option) {
      return [
        {
          text: 'Template',
          enabled: template,
          hint: template ? 'Editable at template level' : 'Not editable at template level',
        },
        {
          text: 'Doctor',
          enabled: doctor,
          hint: doctor ? 'Editable at doctor level' : 'Not editable at doctor level',
        },
        {
          text: 'Patient',
          enabled: option,
          hint: option ? 'Editable at patient level' : 'Not editable at patient level',
        },
      ];
    },

    async protocolUpdated(careProtocol) {
      await this.reload(careProtocol);
      this.$emit('protocolUpdated', this.careProtocol);
    },

    async reload(careProtocol) {
      if (!this.protocolId) return;
      if (!this.doctorId) {
        this.doctor = null;
      }
      if (!careProtocol) {
        this.loading = true;

        window.execAfterInitMenuInterface(() => {
          if (this.isTemplateMode) {
            window.initActiveMenu(`left-navigation-link>care_protocols>${this.protocolid}`);
          } else {
            window.initActiveMenu('left-navigation-link>settings>staff>all-members', false);
          }
        });

        this.careProtocol = await this.$api.getCareProtocol(this.protocolId, this.doctorId);

        if (this.careProtocol.user) {
          this.doctor = this.careProtocol.user;
        }
      } else {
        this.careProtocol = careProtocol;
      }

      this.loading = false;
    },

    showEditCareProtocolDialog() {
      this.$refs.editCareProtocolDialog.show(this.careProtocol);
    },

    showCpEducationBadges() {
      this.$refs.cpEducationBadges.show();
    },

    showCpThoughtForTheDayBadges() {
      this.$refs.cpThoughtForTheDayBadges.show();
    },

    showCpItemsBadges() {
      this.$refs.cpItemsBadges.show();
    },
  },

  components: {
    Loader,
    EditCareProtocolDialog,
    CareProtocolItems,
    ThoughtOfTheDay,
    TherapyPt,
    AvailableOptionBadges,
    Assesment,
    HealthStatus,
    SlottedMessageInDrawer,
    OnboardingScreenEditor,
  },
};
</script>

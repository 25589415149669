<template>
  <div class="h-drawer-max79">
    <Drawer :direction="'right'" :exist="true" ref="modalDialog">
      <div class="h-drawer-card">
        <div class="h-drawer-card-header">
          <div class="h-main-drawer-card-header h-margin-bottom-l">
            <h3 class="h-h3 h-primary_shade_1">Delete item</h3>
            <button
              type="button"
              class="close"
              aria-hidden="true"
              @click="close"
              :disabled="loading"
            >
              <img src="/assets/images/Collapse-drawer.svg" alt="" />
            </button>
          </div>
        </div>
        <div class="h-drawer-card-body">
          <div v-if="!loading">
            <h4 class="h-h4 h-primary_shade_1 h-margin-bottom-l">
              Do you really want to
              <strong class="h-dangerous">delete</strong> this item?
            </h4>
          </div>

          <Loader v-else />
        </div>

        <div class="h-drawer-card-footer">
          <button
            type="button"
            class="h-btn h-btn-accent"
            @click="deleteItem"
            :disabled="loading"
          >
            Delete
          </button>

          <button
            type="button"
            class="h-btn h-btn-dangerous h-margin-left-s"
            @click="close"
            :disabled="loading"
          >
            Cancel
          </button>
        </div>
      </div>
    </Drawer>
  </div>
  <!-- /.modal -->
</template>


<script>
import settings from '@/settings.js';
import Loader from '@/components/Loader';

export default {
  data() {
    return {
      loading: false,
      question: null,
    };
  },

  methods: {
    show(question) {
      this.$refs.modalDialog.open();
      this.$refs.modalDialog.active = true;
      this.loading = false;
      this.question = question;
    },

    close(force) {
      this.$refs.modalDialog.close(force);
      this.$refs.modalDialog.active = false;
    },

    async deleteItem() {
      this.loading = true;
      const response = await this.$api.post(
        `${settings.BACKEND_URL}/api/v-nurse/microprotocols/question/delete`,
        null,
        {
          data: this.question,
        },
      );
      this.loading = false;
      if (response.status === 'ok') {
        this.errors = {
          answers: {},
        };
        this.$emit('del', response.questions);
        this.close(true);
      }
    },
  },

  components: {
    Loader,
  },
};
</script>

<template>
  <SlottedMessageInDrawer ref="SlottedMessageInDrawer" title="Chart Details" @closed="emitClosed">
    <template v-slot:modal-body>
      <div class="h-padding-left-l h-padding-right-l">
        <h3 class="h-h3 h-primary_shade_1 h-margin-top-l h-margin-bottom-l h-text-center">
          {{ chartOptions.series[0].name }}
        </h3>
        <div style="width: 100%;">
          <Chart :options="chartOptions" />
        </div>
      </div>
    </template>
  </SlottedMessageInDrawer>
</template>

<script>
import { Chart } from 'highcharts-vue';
import Highcharts from 'highcharts';
import SlottedMessageInDrawer from '@/components/dialogs/SlottedMessageInDrawer';

export default {
  props: {
    options: {
      type: Object,
      required: true,
    },
  },

  data() {
    const options = JSON.parse(JSON.stringify(this.options));
    const xAxis = options.xAxis;
    delete options.xAxis;
    delete options.yAxis;
    delete options.chart.margin;

    return {
      chartOptions: Highcharts.merge(options, {
        chart: {
          height: 350,
          width: 750,
          margin: [20, 20, 50, 30],
          skipClone: false,
        },
        title: {
          text: null,
        },
        credits: {
          enabled: false,
        },
        accessibility: {
          keyboardNavigation: {
            seriesNavigation: {
              mode: 'serialize',
            },
          },
        },
        xAxis: {
          categories: xAxis.categories,
          crosshair: xAxis.crosshair,
          lineColor: '#152145',
          lineWidth: 2,
        },
        yAxis: {
          min: 0,
          gridLineDashStyle: 'dash',
          gridLineWidth: 1.5,
          gridLineColor: '#EEEFF5',
          title: {
            text: null,
          },
        },
        plotOptions: {
          area: {
            dataLabels: {
              enabled: true,
              verticalAlign: 'bottom',
              style: {
                fontWeight: 'normal',
              },
            },
          },
        },
      }),
    };
  },

  methods: {
    show() {
      this.$refs.SlottedMessageInDrawer.show();
      console.log(this.chartOptions);
    },

    close() {
      this.$refs.SlottedMessageInDrawer.close();
    },

    emitClosed() {
      this.$emit('closed');
    },
  },

  components: {
    SlottedMessageInDrawer,
    Chart,
  },
};
</script>

<template>
  <div>
    <div class="h-table-card h-margin-l">
      <div class="h1-table h-padding-top-l">
        <table id="PatientsActivity" ref="table" class="table dataTable w-100 h-table-log">
          <thead class="thead-light">
            <tr class="activity-header">
              <th style="width: 5%">Staff name</th>
              <th style="width: 5%">Day (2-5)</th>
              <th style="width: 5%">Recording link</th>
              <th style="width: 5%">Action</th>
            </tr>
          </thead>
        </table>
      </div>
    </div>
    <EditNudgingConfig ref="edit" :hospitals="hospitals" @onSubmit="onSubmit" />
  </div>
</template>

<style lang="stylus" scoped></style>

<script>
import Vue from 'vue';
import EditNudgingConfig from './EditNudgingConfig';

export default {
  name: 'NudgingConfig',

  components: {
    EditNudgingConfig,
  },

  data() {
    return {
      dataTable: null,
      hospitals: [],
    };
  },

  methods: {
    initTable() {
      const self = this;

      const allColumns = [
        {
          name: 'surgeon_name',
          data: 'surgeon_name',
          searchable: false,
          orderable: false,
        },
        {
          name: 'trigger',
          data: 'trigger',
          searchable: false,
          orderable: true,
          render: (data) => {
            const arr = data.toString().split('_');
            if (arr.length > 1) {
              return arr[1];
            }
            return '-';
          },
        },
        {
          name: 'recording',
          data: 'recording',
          searchable: false,
          orderable: true,
        },
        {
          name: 'action',
          searchable: false,
          orderable: false,
          render: (data, type, row) => {
            return `
            <div class="text-right">
              <a href="javascript:void(0);" class="mr-2 btn-delete" data-id="${row.id}">
                <i class="fas fa-regular fa-trash-alt text-danger font-16" title="remove"></i>
              </a>
              <a href="javascript:void(0);" class="btn-edit" data-id="${row.id}">
                <i class='fas fa-edit text-info font-16' title='Edit'></i>
              </a>
            </div>
          `;
          },
        },
      ];

      this.dataTable = $(this.$refs.table).DataTable({
        order: [[1, 'desc']],
        ordering: true,
        processing: true,
        serverSide: true,
        pageLength: 100,
        ajax: (data, callback, tableSettings) => {
          const column = data.columns[data.order[0].column].name;
          const order = `${data.order[0].dir === 'desc' ? '-' : ''}${column}`;
          const search = data.search.value;

          this.$api
            .getNudgingRecordingList({
              start: data.start,
              length: data.length,
              order,
              search,
            })
            .then((response) => {
              tableSettings.json = response;
              callback(response);
            });
        },

        searching: true,
        searchDelay: 1000,
        dom:
          '<"h-row h-space-between h-align-item-center"<"h-show-bt h-margin-left-l header-title">' +
          '<"h-d-flex h-table-bt"fB>>' +
          '<""<""tr>><"h-row h-space-between h-align-item-center h-padding-bottom-l"lip>',
        buttons: {
          buttons: [
            {
              text: 'Add',
              action() {
                self.openEdit();
              },
              className: 'h-btn h-btn-accent addnewpatioint',
            },
          ],
          dom: {
            button: {
              className: 'h-btn',
            },
          },
        },
        columns: allColumns.filter((column) => {
          if (this.isAdmin) return true;
          return !column.__adminOnly;
        }),
      });
      $('div.header-title').html("<h3 class='h-h3 h-primary_shade_1'>Nudging recordings</h3>");

      // Handle click events on the edit and delete buttons
      $(this.$refs.table).on('click', '.btn-edit', (e) => {
        const id = $(e.target)
          .closest('a')
          .attr('data-id');
        this.openEdit(id);
      });

      $(this.$refs.table).on('click', '.btn-delete', (e) => {
        const id = $(e.target)
          .closest('a')
          .attr('data-id');
        this.deleteItem(id);
      });
    },

    async getHospitalList() {
      try {
        this.hospitals = await this.$api.getHospitalsList();
      } catch (e) {
        this.hospitals = [];
      }
    },

    openEdit(id) {
      const data = id
        ? this.dataTable
          .data()
          .toArray()
          .find(i => i.id.toString() === id)
        : { enable_for_all: true, trigger: 'day_2' };
      this.$refs.edit.show(data);
    },

    reloadDataTable() {
      this.dataTable.ajax.reload();
    },

    async onSubmit(data) {
      try {
        if (data.id) {
          await this.$api.updateNudgingRecording(data.id, data);
        } else {
          await this.$api.createNudgingRecording(data);
        }
        this.reloadDataTable();
        $.notify(data.id ? 'Updated successfully' : 'Created successfully', {
          position: 'top center',
          className: 'success',
        });
      } catch (e) {
        $.notify(e.toString(), { position: 'top center', className: 'error' });
      }
    },

    deleteItem(id) {
      Vue.$confirm({
        title: 'Are you sure?',
        message: 'Do you really want to delete this item?',
        button: {
          yes: 'Delete',
          no: 'No',
        },
        callback: async (confirm) => {
          if (confirm) {
            await this.$api.deleteNudgingRecording(id);
            this.reloadDataTable();
          }
        },
      });
    },
  },

  mounted() {
    window.execAfterInitMenuInterface(() => {
      window.initActiveMenu('left-navigation-link>hospital>settings');
    });

    this.initTable();
    this.getHospitalList();
  },
};
</script>

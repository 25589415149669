<template>
  <div class="h-drawer-max79">
    <SlottedMessageInDrawer
      ref="slottedMessage"
      title="Select widgets"
      class="h-drawer-scroll"
    >
      <template v-slot:modal-body>
        <div class="widgetListWrapper">
          <div>
            <InputField
              v-for="widget of allWidgets"
              :key="widget.title"
              :id="`widgetsSelector-${widget.title}`"
              type="toggle"
              :value="isWidgetSelected(widget)"
              :label="widget.title"
              @change="toggleWidget(widget)"
            />
          </div>
        </div>
      </template>

      <template v-slot:modal-footer>
        <button type="button" class="h-btn h-btn-accent" @click="confirm">
          Confirm
        </button>
      </template>
    </SlottedMessageInDrawer>
  </div>
</template>


<script>
import SlottedMessageInDrawer from '@/components/dialogs/SlottedMessageInDrawer';
import InputField from '@/components/InputField';

export default {
  props: ['allWidgets', 'widgets'],

  methods: {
    show() {
      this.$refs.slottedMessage.show();
    },

    close() {
      this.$refs.slottedMessage.close();
    },

    isWidgetSelected(widget) {
      return this.widgets.indexOf(widget) !== -1;
    },

    toggleWidget(widget) {
      this.$emit('toggleSelectedWidget', widget);
    },

    async confirm() {
      this.close();
    },
  },

  components: {
    SlottedMessageInDrawer,
    InputField,
  },
};
</script>

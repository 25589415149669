<template>
  <div class="room-list-wrapper">
    <ul class="room-list chat-list" :class="{ 'new-list': mode === 'new' }">
      <li
        v-for="room in sortedRooms"
        :key="room.id"
        @click="selectRoom(room)"
        :class="{ selected: room === selectedRoom }"
        :room-id="room.id"
      >
        <span
          class="media"
          :class="{
            'new-messages': room.hasUnseenMessages
          }"
        >
          <div class="media-body">
            <div class="room-card">
              <h5 :title="room.name" class="h-h5 h-primary_shade_1">
                {{ room.short_name }}
                <span v-if="hasDraft(room.id)" class="h-dangerous"> - Draft</span>
              </h5>
            </div>
            <div>
              <a
                href="javascript:void(0);"
                class="OpenProfileInDrawer h-profile-icon h-h3"
                :data-id="room.client_id"
              >
                <i class="healenticons-icon-metro-profile" data-tooltip="Open profile"></i>
              </a>

              <span>
                <span
                  v-if="room.hasUnseenMessages"
                  class="badge badge-danger float-right mt-1 h-margin-left-l"
                  title="New messages"
                >
                  <b>NEW:</b> {{ latestMessageReceivedAt(room) }}
                </span>
                <span
                  v-if="!room.hasUnseenMessages"
                  class="badge badge-info float-right mt-1 h-margin-left-l"
                  title="Latest message date"
                >
                  {{ latestMessageReceivedAt(room) }}
                </span> </span
              ><!-- original: date of last message -->
            </div>
          </div>
        </span>
      </li>

      <li v-if="rooms.length === 0" class="h-secondary_shade_1 h-h4 h-margin-top-l h-text-center">
        {{ noEntriesMessage }}
      </li>
    </ul>
    <PatientProfileInDrawer ref="PatientProfileInDrawer" :key="client.id" />
  </div>
</template>

<style lang="stylus" scoped>
.room-list-wrapper {
  padding: 5rem;
}

.room-list {
  list-style-type: none;
  padding: 0;

  li.text-muted {
    cursor: default;
  }
}

.chat-list {
  height: auto !important;

  .badge {
    color: white !important;
     width: 70rem;
     font: normal normal 500 7rem/10rem "Roboto", sans-serif !important;
     height: 10rem;
     display: flex;
     justify-content: center;
     align-items: center;
  }
}

.media-body {
  display: block !important;
}

.room-name {
  word-break: break-all;
  padding-left: 5rem;
}
</style>

<script>
import moment from 'moment';
import PatientProfileInDrawer from '../../../all_patients/components/PatientProfileInDrawer';

export default {
  data() {
    return {
      client: {},
    };
  },
  computed: {
    sortedRooms() {
      if (this.rooms && this.rooms.length > 0) {
        const roomsCopy = this.rooms.slice();

        roomsCopy.sort((a, b) => {
          const aHasDraft = localStorage.getItem(`draftMessage_${a.id}`) !== null;
          const bHasDraft = localStorage.getItem(`draftMessage_${b.id}`) !== null;

          if (aHasDraft && !bHasDraft) return -1;
          if (!aHasDraft && bHasDraft) return 1;

          const dateA = new Date(a.latestMessageReceivedAt || a.latestMessageAt);
          const dateB = new Date(b.latestMessageReceivedAt || b.latestMessageAt);
          return dateB - dateA;
        });

        return roomsCopy;
      }
      return this.rooms;
    },
  },

  methods: {
    selectRoom(room) {
      this.$emit('roomSelected', room);
    },
    hasDraft(roomId) {
      return localStorage.getItem(`draftMessage_${roomId}`) !== null;
    },
    latestMessageReceivedAt(room) {
      if (!room.latestMessageReceivedAt) return 'Never';
      return moment(this.$dateUtils.toLocalDatetime(room.latestMessageReceivedAt)).format(
        'MMM Do, HH:mm',
      );
    },
  },
  async mounted() {
    $('.OpenProfileInDrawer').on('click', async (e) => {
      const id = $(e.currentTarget).data('id');
      await this.$refs.PatientProfileInDrawer.show(id);
    });
  },

  beforeDestroy() {
    $('.OpenProfileInDrawer').off('click');
  },

  props: {
    rooms: {
      type: Array,
      required: true,
    },
    selectedRoom: {
      required: true,
    },
    draftRoomIds: {
      type: Array,
      default: () => [], // Provide a default value to ensure it's always an array
    },
    mode: {
      type: String,
    },
    noEntriesMessage: {
      type: String,
    },
  },

  components: {
    PatientProfileInDrawer,
  },
};
</script>

<template>
<div>
  <div class="videoStreamWrapper" :id="videoElementId">
    <div
      ref="videoPlaceholderWrapper"
      class="display-table w-100 h-100"
    >
      <div class="display-table-row">
        <div class="display-table-cell valign-middle">
          <img
            src="/kitchry_assets/images/user.png"
            class="videoPlaceholder"
          />
        </div>
      </div>
    </div>

    <p
      ref="videoTextInfo"
      class="videoTextInfo mb-0"
    >
      {{ user.name || "No name" }}
    </p>
  </div>

  <VideoIconsHolder
    v-if="!user.is_local && (stream !== null)"
    :videoMuted="videoMuted"
    :audioMuted="audioMuted"
  />
</div>
</template>


<style lang="stylus" scoped>
.videoStreamWrapper
  position absolute
  left 0px
  right 0px
  top 0px
  bottom 0px
  background-color black

  video
      left 0px

  > div:last-child
    position absolute !important
    top 0px

  .videoTextInfo
    position absolute
    margin 0px
    top 5px
    left 5px
    margin-right 5px
    color white
    z-index 1
    border 1px solid white
    padding 0px 5px;
    background-color black
    display none

  img.videoPlaceholder
    height: 20%
    margin auto
    display block

</style>

<script>
import VideoIconsHolder from './VideoIconsHolder';

let lastId = 0;

export default {
  props: ['user'],

  data() {
    lastId += 1;
    return {
      videoMuted: null,
      audioMuted: null,

      client: null,
      stream: null,
      videoElementId: `video-sctream-output-${lastId}`,
    };
  },

  methods: {
    setStream(client, stream) {
      if (this.stream && (this.stream !== stream)) {
        this.userLeft();
      }
      this.client = client;
      this.stream = stream;
    },

    play(onAfterPlay) {
      this.stream.play(this.videoElementId);
      if (onAfterPlay) onAfterPlay();

      $(this.$refs.videoTextInfo).show();
      $(this.$refs.videoPlaceholderWrapper).hide();

      if (this.user.is_local) {
        this.videoMuted = this.stream.userMuteVideo;
        this.audioMuted = this.stream.userMuteAudio;
      } else {
        this.videoMuted = this.stream.peerMuteVideo;
        this.audioMuted = this.stream.peerMuteAudio;
      }

      this.client.on('mute-audio', (evt) => {
        if (evt.uid === this.stream.getId()) {
          this.audioMuted = true;
        }
      });
      this.client.on('unmute-audio', (evt) => {
        if (evt.uid === this.stream.getId()) {
          this.audioMuted = false;
        }
      });

      this.client.on('mute-video', (evt) => {
        if (evt.uid === this.stream.getId()) {
          this.videoMuted = true;
        }
      });
      this.client.on('unmute-video', (evt) => {
        if (evt.uid === this.stream.getId()) {
          this.videoMuted = false;
        }
      });
    },

    muteVideo() {
      this.stream.muteVideo();
    },

    unmuteVideo() {
      this.stream.unmuteVideo();
    },

    muteAudio() {
      this.stream.muteAudio();
    },

    unmuteAudio() {
      this.stream.unmuteAudio();
    },

    userLeft() {
      if (this.stream) {
        if (this.stream.isPlaying()) {
          this.stream.stop(this.videoElementId);
        }
        this.stream.close();
      }

      this.stream = null;
      this.videoMuted = null;
      this.audioMuted = null;

      $(this.$refs.videoTextInfo).hide();
      $(this.$refs.videoPlaceholderWrapper).show();
    },
  },

  components: {
    VideoIconsHolder,
  },
};
</script>

<template>
  <div>
    <div class='h-add-patient
        h-padding-top-l
        h-mobile-padding-left-l
        h-mobile-padding-right-l'>
      <div class='h-col-desktop-8 h-col-screen-m h-col-mobile-12 h-margin-auto'>
        <div>
<!--         <div
          class="h-col-desktop-4 h-margin-bottom-l h-padding-top-l h-primary_shade_1"
        >
          <InputField
            v-if="hospital"
            type="select"
            v-model="hospitalId"
            :choices="hospitalChoices"
            dense
            :disabled="loading"
          />
        </div> -->

        <div v-if="!loading">
          <h3 class="h-h3 h-primary_shade_1 h-text-center h-margin-bottom-l">
            Import Appointments
          </h3>
          <form class="h-row">
            <div class=" h-input-w49
              h-desktop-margin-right-2
              h-col-mobile-12
              h-margin-bottom-l"
            >
              <label class="h-label">
                CSV file
              </label>
              <InputField
                type="file"
                v-model="csvFile"
                :error="errors.csvFile"
                @input="() => { errors.csvFile=null; }"
              />
            </div>

            <div class="h-input-w49 h-col-mobile-12 h-margin-bottom-l">
              <label class="h-label">
                Provider
              </label>
              <InputField
                type="select"
                :choices="surgeonOptions"
                v-model="surgeonId"
                :error="errors.surgeon_id"
                @input="() => { errors.surgeon_id=null }"
              />
            </div>
          </form>

          <p class="h-h4 h-primary_shade_1">
            CSV file import only supports data exported from ATHENA with the following format:
            <ol class="h-margin-top-m h-margin-left-m">
              <li class="h-margin-top-s"><strong>apptdate</strong> - date of appointment</li>
              <li class="h-margin-top-s"><strong>apptstarttime</strong> - time of appointment</li>
              <li class="h-margin-top-s"><strong>patient name</strong> - name of patient</li>
              <li class="h-margin-top-s"><strong>patient email</strong> - email of patient</li>
              <li class="h-margin-top-s"><strong>patient mobile no</strong>
                - mobile no of patient
              </li>
            </ol>
          </p>

            <div class="h-text-center h-margin-top-l">
              <button
                type="button"
                class="h-btn h-btn-accent"
                @click="uploadFile"
              >
                Upload file
              </button>
            </div>
          </div>

          <div v-else class="card-body">
            <Loader />
          </div>

        </div>
      </div>
    </div>

    <SimpleMessage
      title="Appointments imported"
      :text="`All appointments successfully imported and patients have been
        notified through text and emails.`"
      ref="appointmentsImported"
    />

  </div>
</template>


<script>
import Loader from '@/components/Loader';
import InputField from '@/components/InputField';
import SimpleMessage from '@/components/dialogs/SimpleMessage';

export default {
  data() {
    return {
      loading: true,
      errors: {},
      csvFile: null,
      surgeonId: null,
      surgeonOptions: [],
      hospitalId: null,
      hospital: null,
      hospitalsMap: {},
      hospitalChoices: [],
    };
  },

  watch: {
    hospitalId() {
      this.hospital = this.hospitalsMap[this.hospitalId];
      this.loadHospital();
    },
  },

  methods: {
    async uploadFile() {
      this.loading = true;
      this.errors = {};

      const payload = {
        surgeonId: this.surgeonId,
        csvFile: this.csvFile,
        utc_offset: this.$dateUtils.utcOffsetMinutes,
      };
      const response = await this.$api.importAppointments(payload);
      console.log(response);

      if (response.status === 'ok') {
        this.$refs.appointmentsImported.show();
      } else {
        this.errors = response.errors;
      }

      this.loading = false;
    },

    async loadHospital() {
      this.loading = true;

      const surgeonsData = await this.$api.searchSeargeon({
        hospital_id: this.hospitalId,
      });
      this.surgeonOptions = surgeonsData.map((surgeon) => {
        return {
          value: surgeon.id,
          title: surgeon.name,
        };
      });
      this.surgeonOptions.unshift([null, '--- Select Provider ---']);

      this.loading = false;
    },
  },

  async mounted() {
    window.execAfterInitMenuInterface(() => {
      window.initActiveMenu(
        'left-navigation-link>settings>import-appointments',
      );
    });

    const hospitals = await this.$api.getHospitalsList();
    this.hospitalChoices = hospitals
      .sort((a, b) => a.name.localeCompare(b.name))
      .map((hospital) => {
        this.hospitalsMap[hospital.id] = hospital;

        return {
          value: hospital.id,
          title: hospital.name,
        };
      });
    this.hospitalId = hospitals[0].id;
    this.onHospitalSwitch();
    this.loading = false;
  },

  components: {
    Loader,
    InputField,
    SimpleMessage,
  },
};
</script>

<template>
  <div class="h-drawer-max500">
    <Drawer :direction="'right'" :exist="true" ref="modalDialog">
      <div class="h-drawer-card">
        <div class="h-drawer-card-header">
          <div class="h-main-drawer-card-header h-margin-bottom-l">
            <h3 class="h-h3 h-primary_shade_1">Add Education/Learning Entries</h3>
            <button
              type="button"
              class="close"
              aria-hidden="true"
              @click="close"
              :disabled="loading"
            >
              <!-- <font-awesome-icon
                    :icon="['fas', 'sign-out-alt']"
                    style="height: 1.5vw; width: 1.5vw; color: #c3c4cb"
                  /> -->
              <img src="/assets/images/Collapse-drawer.svg" alt="" />
            </button>
          </div>
        </div>
        <div class="h-drawer-card-body h-padding-left-0 h-padding-right-0">
          <div class="" :class="{ 'd-none': loading }">
            <div
              class="h-space-between h-align-items-center h-margin-bottom-l
              h-padding-left-l h-padding-right-l"
              ref="searchInputsWrapper"
            >
              <div class="h-row">
                <div class="w-33">
                  <label class="h-label"> Hospital </label>
                  <select class="h-input" v-model="selectedHospital" :disabled="!protocolsLoaded">
                    <option value="">---Select hospital---</option>
                    <option v-for="hospital of hospitals" :key="hospital.id" :value="hospital.id">
                      {{ hospital.name }}
                    </option>
                  </select>
                </div>

                <div class="w-33 h-margin-left-l">
                  <label class="h-label"> Protocol </label>

                  <select class="h-input" v-model="selectedProtocol">
                    <option :value="noProtocol.id">
                      {{ noProtocol.name }}
                    </option>
                    <option v-if="!protocolsLoaded" :value="-1">---Protocols not loaded---</option>
                    <option v-for="protocol of protocols" :key="protocol.id" :value="protocol.id">
                      {{ protocol.name }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="h-margin-top-l">
                <input
                  ref="textSearch"
                  placeholder="Search"
                  type="search"
                  class="h-input"
                  @input="search"
                />
              </div>
            </div>
            <div class="d-inline h-primary_shade_1 h-padding-left-l h-padding-right-l">
              Total selected:
              <strong>{{ selectedEntries.length }}</strong> (<a
                href="javascript:void(0)"
                @click="clearSelection"
                >unselect all</a
              >)
            </div>
            <div class="h1-table">
              <table
                ref="learnsTable"
                class="table dataTable h-noborder-input h-table-wrap"
                style="max-height: calc(100vh - 215rem); min-height: calc(100vh - 215rem)"
              >
                <thead class="thead-light">
                  <tr>
                    <th width="5%" class="h-text-center">
                      Select<br />
                      <a href="javascript:void(0)" @click="selectAll">All</a> /
                      <a href="javascript:void(0)" @click="selectNothing">Nothing</a>
                    </th>
                    <th width="5%">Hospitals</th>
                    <th width="5%">Protocols</th>
                    <th width="5%">Title</th>
                    <th width="5%">Subtitle</th>
                    <th width="5%">Source</th>
                    <th width="5%">Type</th>
                    <th width="5%">Category</th>
                    <th width="5%">Order</th>
                  </tr>
                </thead>
              </table>
            </div>
          </div>

          <Loader v-if="loading" />
        </div>

        <div class="h-drawer-card-footer">
          <button type="button" class="h-btn h-btn-accent" @click="addEntries" :disabled="loading">
            Clone
          </button>

          <button
            type="button"
            class="h-btn h-btn-dangerous h-margin-left-s"
            @click="close"
            :disabled="loading"
          >
            Cancel
          </button>
        </div>
      </div>
    </Drawer>
  </div>
</template>

<script>
import Loader from '@/components/Loader';

let dialogId = 0;

export default {
  props: ['collectionId'],

  data() {
    dialogId += 1;
    return {
      loading: false,
      selectedEntries: [],
      hospitals: [],
      protocols: [],
      protocolsLoaded: true,
      selectedHospital: '',
      selectedProtocol: -1,
      noProtocol: {
        id: -1,
        name: '<Hospital Learn Module (no protocol)>',
      },
      dataTable: null,
      dialogId,
    };
  },

  watch: {
    selectedHospital(value) {
      console.log('selectedHospital', value);
      this.selectedProtocol = -1;
      this.protocols = [];
      this.dataTable.column(2).search(this.selectedProtocol);
      this.dataTable.column(1).search(value).draw();

      if (value) {
        this.protocolsLoaded = false;
        this.loadProtocols();
      } else {
        this.protocolsLoaded = true;
      }
    },

    selectedProtocol(value) {
      this.dataTable.column(2).search(value).draw();
    },
  },

  methods: {
    search(e) {
      this.dataTable.search(e.target.value).draw();
    },

    selectAll() {
      $(this.$refs.learnsTable)
        .find('input[type=checkbox]')
        .each((i, entry) => {
          const entryId = parseInt($(entry).attr('data-entryId'), 10);
          const index = this.selectedEntries.indexOf(entryId);
          if (index === -1) {
            this.selectedEntries.push(entryId);
          }
        });
      $(this.$refs.learnsTable).find('input[type=checkbox]').prop('checked', true);
    },

    selectNothing() {
      $(this.$refs.learnsTable)
        .find('input[type=checkbox]')
        .each((i, entry) => {
          const entryId = parseInt($(entry).attr('data-entryId'), 10);
          const index = this.selectedEntries.indexOf(entryId);
          if (index !== -1) {
            this.selectedEntries.splice(index, 1);
          }
          console.log();
        });
      $(this.$refs.learnsTable).find('input[type=checkbox]').prop('checked', false);
    },

    clearSelection() {
      this.selectedEntries = [];
      $(this.$refs.learnsTable).find('input[type=checkbox]').prop('checked', false);
    },

    async loadProtocols() {
      this.protocols = (await this.$api.getCareProtocols(this.selectedHospital)).care_protocols;

      this.protocolsLoaded = true;
    },

    show() {
      this.$refs.modalDialog.open();
      this.$refs.modalDialog.active = true;
      this.loading = false;
      this.selectedEntries = [];

      this.$refs.textSearch.value = '';
      this.selectedHospital = '';
      this.selectedProtocol = -1;

      this.dataTable.column(1).search(this.selectedHospital);
      this.dataTable.column(2).search(this.selectedProtocol);
      this.dataTable.search('').draw();
    },

    close(force) {
      this.$refs.modalDialog.close(force);
      this.$refs.modalDialog.active = false;
    },

    async addEntries() {
      if (this.selectedEntries.length !== 0) {
        this.loading = true;

        const collection = await this.$api.addEducationEntries(
          this.collectionId,
          this.selectedEntries,
        );
        this.$emit('entryAdded', collection);
      }

      this.close(true);
    },
  },

  async mounted() {
    this.hospitals = await this.$api.getHospitalsList();
    this.hospitals.sort((a, b) => a.name.localeCompare(b.name));

    const that = this;
    this.dataTable = $(this.$refs.learnsTable).DataTable({
      processing: true,
      serverSide: true,
      order: [[3, 'asc']],
      ajax: (data, callback, tableSettings) => {
        this.$api.getEducationEntries(data).then((response) => {
          tableSettings.json = response;
          callback(response);
        });
      },
      dom: "<'h-row h-space-between h-align-item-center'<'h-show-bt h-margin-left-l header-title'><'h-d-flex h-table-bt'>><'h-row'<'h-col-desktop-12 h-col-mobile-12'tr>><'h-row h-space-between h-align-item-center h-padding-bottom-l' lip>",

      columns: [
        {
          name: 'id',
          searchable: false,
          orderable: false,
          render: (data) => {
            return `
              <div class="text-center">
                <div class="h-checkbox">
                  <input
                    type="checkbox"
                    class="custom-control-input"
                    id="educationEntryDialog::${dialogId}::entryId::${data}"
                    ${that.selectedEntries.indexOf(data) !== -1 ? 'checked' : ''}
                    data-entryId="${data}"
                  >
                  <label
                    class="custom-control-label cursor-pointer"
                    for="educationEntryDialog::${dialogId}::entryId::${data}"></label>
                </div>
              </div>
            `;
          },
        },
        {
          name: 'hospitals',
          orderable: false,
          searchable: false,
          render: data => (data ? that.$strUtils.escapeHtml(data) : null),
        },
        {
          name: 'protocols',
          orderable: false,
          searchable: false,
          render: data => (data ? that.$strUtils.escapeHtml(data) : null),
        },
        {
          name: 'title',
          searchable: true,
          render: data => (data ? that.$strUtils.escapeHtml(data) : null),
        },
        {
          name: 'sub_title',
          searchable: true,
          render: data => (data ? that.$strUtils.escapeHtml(data) : null),
        },
        {
          name: 'source',
          searchable: true,
          render: data => (data ? that.$strUtils.escapeHtml(data) : null),
        },
        {
          name: 'item_type',
          searchable: true,
          render: data => (data ? that.$strUtils.escapeHtml(data) : null),
        },
        {
          name: 'category',
          searchable: true,
          render: data => (data ? that.$strUtils.escapeHtml(data) : null),
        },
        {
          name: 'order',
          searchable: true,
          render: data => (data ? that.$strUtils.escapeHtml(data) : null),
        },
      ],
    });

    const searchInputs = $(this.$refs.searchInputsWrapper);
    $(this.$refs.modalDialog.el).find('.search-panel').append(searchInputs);
    searchInputs.removeClass('d-none');

    $(this.$refs.learnsTable).on('click', 'input[type=checkbox]', (e) => {
      const entryId = parseInt($(e.target).attr('data-entryId'), 10);
      const index = that.selectedEntries.indexOf(entryId);
      if (index === -1) {
        that.selectedEntries.push(entryId);
      } else {
        that.selectedEntries.splice(index, 1);
      }
    });
  },

  beforeDestroy() {
    if (this.dataTable) {
      this.dataTable.destroy(true);
      this.dataTable = null;
    }
  },

  components: {
    Loader,
  },
};
</script>

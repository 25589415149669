<template>
<div class="remoteIconsWrapper">
  <i v-if="audioMuted" class="icon fas fa-microphone-slash"></i>
  <i v-if="videoMuted" class="icon fas fa-video-slash"></i>
</div>

</template>


<style lang="stylus" scoped>
.remoteIconsWrapper
  position absolute
  left 1em
  top 3em
  background-color transparent

.icon
  background-color black
  color white
  border 2px solid silver
  font-size 150%
  border-radius 100%
  padding 15px
  display block
  margin-bottom 10px
</style>


<script>
export default {
  props: ['videoMuted', 'audioMuted'],
};
</script>

<template>
  <div class="h-bg-gray">
    <div class="h1-table h-margin-top-l h-padding-top-l h-bg-w">
      <table
        id="hospital-report-table"
        class="table dataTable TableReports dtr-inline collapsed"
        ref="table"
      >
        <thead class="thead-light">
          <tr>
            <th>ID</th>
            <th width="15%">Hospital</th>
            <th width="15%">Location</th>
            <th width="15%">Provider</th>
            <th width="10%">Patient</th>
            <th width="10%">Patient MRN</th>
            <th width="10%">Care provider</th>
            <th>Patient Status</th>
            <th>Mental Health Time</th>
            <th width="10%">Days on service</th>
            <th>Days with readings</th>
            <th>Alerts Sent</th>
            <th>Appointments count</th>
            <th>Appointments time</th>
            <th>Compliance %</th>
            <th>Create to Activate (days)</th>
            <th>Activate to Track (days)</th>
            <th>Create to Track (days)</th>
            <th>Nudge Effectiveness (%)</th>
            <th>Nudge Count (days)</th>
            <th>ICD-10 Secondary</th>
            <th>ICD-10 Primary</th>
            <th>Service Date</th>
            <th>Insurance Info</th>
            <th>Pain Score %</th>
            <th>SCS</th>
            <th>Progress Report</th>
          </tr>
        </thead>
      </table>
    </div>

    <SelectorDialogInDrawer
      ref="toggleColumnsDialog"
      :allOptions="allTableColumns"
      :selectedOptions="visibleTableColumns"
      :getOptionKey="
        option => {
          return option.name
        }
      "
      :getOptionText="
        option => {
          return option.text
        }
      "
      windowTitle="Toggle table columns"
      listTitle="Table table columns to show"
      @toggleOption="toggleColumnVisible"
    />
    <DownloadAllReportsDrawer ref="downloadAllReportsDrawer" />
    <StartTaskDialog
      ref="startTaskDialog"
      reportCode="hospital"
      :month="getMonth"
      :hospitalId="this.hospitalId"
      v-on:startProcessing="startProcessing"
      v-on:endProcessing="endProcessing"
    />
    <SftpPushDialog ref="sftpPushDialog" @reportPushStatusChanged="reportPushStatusChanged" />
    <PatientProfileInDrawer ref="PatientProfileInDrawer" :key="client.id" />
  </div>
</template>

<style>
div.loading {
  font-family: 'Roboto', sans-serif;
  font-weight: bold;
  color: #2b5adc;
}
</style>

<script>
import moment from 'moment-timezone';
import StartTaskDialog from '../StartTaskDialog';
import settings from '@/settings.js';
import SelectorDialogInDrawer from '@/components/dialogs/SelectorDialogInDrawer';
import eventBus from '../../../../../event-bus';
import DownloadAllReportsDrawer from '@/components/reports/DownloadAllReportsDrawer';
import SftpPushDialog from './SftpPushDialog';
import PatientProfileInDrawer from '../../../all_patients/components/PatientProfileInDrawer';

const TABLE_COLUMNS_KEY = 'HospitalReport_columnKeys';

export default {
  data() {
    return {
      client: {},
      dataTable: null,
      allTableColumns: [],
      visibleTableColumns: [],
      errors: {},
      hospitals: [],
      surgeons: [],
      surgeonId: null,
      hospitalId: null,
      reportDateStart: moment()
        .startOf('month')
        .format('YYYY-MM'),
      reportDateEnd: moment()
        .endOf('month')
        .format('YYYY-MM'),
      skipEmptyReports: true,
      showOnlyEligible: false,
      msg: 'Report is being downloaded now',
      html: '<i class="fa fa-cog fa-spin fa-3x fa-fw"></i>',
      downloadingReport: false,
      downloadingLongNumberOfReports: false,
      displayReportDownloadingMsgBox: false,
      openDownloadDialogToQueryForStats: false,
      longReportDownloadJobThreshold: 3,
      datatableProcessingDone: false,
      singleReportDownload: false,
      defaultDialogBoxTitle: 'Report is being generated',
      response: null,
    };
  },

  computed: {
    hospitalChoices() {
      const choices = this.hospitals.map((hospital) => {
        return [hospital.id, hospital.name];
      });
      choices.unshift([null, '--- select hospital ---']);
      return choices;
    },
    getMonth() {
      if (this.reportDateStart) {
        return moment(this.reportDateStart).month() + 1;
      }
      return moment().month() + 1;
    },
  },

  methods: {
    startProcessing() {
      this.datatableProcessingDone = false;
      this.dataTable.processing(true);
    },

    endProcessing() {
      this.datatableProcessingDone = true;
      this.reload();
    },

    reload() {
      this.dataTable.ajax.reload();
    },

    async sendToAthena(clientId, dateStart, dateEnd) {
      const progressReportUrl = `${settings.BACKEND_URL}/api/v-nurse/client/progress-report/send-to-athena` +
        `?id=${clientId}&date-start=${dateStart}` +
        `&date-end=${dateEnd}`;

      this.$api.post(progressReportUrl).then((response) => {
        console.log(response);
        if (response.status === 'ok') {
          $.notify('Progress report sent to Athena', {
            position: 'top center',
            className: 'success',
          });
        } else {
          const error = response.message ? `: ${response.message}` : '';
          $.notify(`Failed to send progress report to Athena - ${error}`, {
            position: 'top center',
            className: 'error',
          });
        }
      });

      const bhiReportUrl = `${settings.BACKEND_URL}/api/v-nurse/client/bhi-report/send-to-athena-s3` +
        `?id=${clientId}&date-start=${dateStart}` +
        `&date-end=${dateEnd}`;

      this.$api.post(bhiReportUrl).then((response) => {
        console.log(response);
        if (response.status === 'ok') {
          $.notify('BHI report sent to Athena', {
            position: 'top center',
            className: 'success',
          });
        } else {
          const error = response.message ? `: ${response.message}` : '';
          $.notify(`Failed to send BHI report to Athena - ${error}`, {
            position: 'top center',
            className: 'error',
          });
        }
      });
    },

    async downloadPatientReport(clientId, dateStart, dateEnd) {
      const url =
        `${settings.BACKEND_URL}/api/v-nurse/client/progress-report` +
        `?id=${clientId}&date-start=${dateStart}` +
        `&date-end=${dateEnd}`;

      this.downloadingReport = true;
      this.singleReportDownload = true;
      this.displayReportDownloadingMsgBox = true;
      this.msg = 'Report is being downloaded now...';
      this.$api.downloadFile(url).then((response) => {
        this.downloadingReport = false;
        eventBus.$emit('refreshReportOrdersCounter');
      });
    },

    async downloadBHIReport(clientId, dateStart, dateEnd) {
      const url = `${settings.BACKEND_URL}/api/v-nurse/client/bhi-report-from-s3` +
        `?client_id=${clientId}&date-start=${dateStart}` +
        `&date-end=${dateEnd}`;

      this.downloadingReport = true;
      this.singleReportDownload = true;
      this.displayReportDownloadingMsgBox = true;
      this.msg = 'BHI Report is being downloaded now...';

      try {
        const response = await this.$api.downloadFile(url);
        eventBus.$emit('refreshReportOrdersCounter');
      } catch (error) {
        console.log(error);
        $.notify('Please recalculate BHI reports', {
          position: 'top center',
          className: 'error',
        });
      } finally {
        this.downloadingReport = false;
      }
      // this.$api.downloadFile(url).then((response) => {
      //   this.downloadingReport = false;
      //   eventBus.$emit('refreshReportOrdersCounter');
      // });
    },

    async downloadAllReports() {
      if (!this.datatableProcessingDone) {
        $.notify('Report list is still being populated.', {
          position: 'top center',
          className: 'error',
        });
        return;
      }

      if (this.downloadingReport) {
        $.notify('There is a download operation in progress.', {
          position: 'top center',
          className: 'error',
        });
        return;
      }

      const selectedMonth = $('#reportDateStart').val();
      const dateStart = moment(selectedMonth)
        .startOf('month')
        .format('YYYY-MM-DD');
      const dateEnd = moment(selectedMonth)
        .endOf('month')
        .format('YYYY-MM-DD');

      const hospitalIdValue = this.hospitalId != null ? this.hospitalId : '';
      const surgeonIdValue = this.surgeonId != null ? this.surgeonId : '';

      const url = `${settings.BACKEND_URL}/api/v-nurse/all-clients/progress-report`;
      const payload = {
        'date-start': dateStart,
        'date-end': dateEnd,
        ext: 'zip',
        'skip-empty': this.skipEmptyReports,
        'download-in-background': true,
        'show-eligible': this.showOnlyEligible,
        'hospital-id': hospitalIdValue,
        'surgeon-id': surgeonIdValue,
      };

      this.defaultDialogBoxTitle = 'Report is being generated';
      this.displayReportDownloadingMsgBox = true;
      this.msg = 'Reports are being downloaded now.';
      this.singleReportDownload = false;

      if (this.singleReportDownload) {
        this.downloadingReport = true;
        this.$api.downloadFile(url, payload).then((response) => {
          eventBus.$emit('refreshReportOrdersCounter');
        });
      } else {
        await this.$api.scheduleDownloadOrder(url, payload).then((response) => {
          if (response.status === 'error') {
            this.defaultDialogBoxTitle = 'There is already a report request to process';
          } else eventBus.$emit('refreshReportOrdersCounter');
        });
      }
    },

    toggleColumnVisible(column, visible) {
      if (visible) {
        this.visibleTableColumns.push(column);
      } else {
        const index = this.visibleTableColumns.indexOf(column);
        this.visibleTableColumns.splice(index, 1);
      }

      localStorage.setItem(TABLE_COLUMNS_KEY, JSON.stringify(this.visibleTableColumns));

      const dtColumn = this.dataTable.column(this.allTableColumns.indexOf(column));
      dtColumn.visible(!dtColumn.visible());
    },

    msgClose() {
      this.displayReportDownloadingMsgBox = false;
    },

    recalculateReport() {
      this.$refs.startTaskDialog.show();
    },

    reportPushStatusChanged(rowMetadata) {
      this.reload();
    },
  },

  async mounted() {
    const that = this;
    this.dataTable = $('#hospital-report-table').DataTable({
      processing: true,
      serverSide: true,
      responsive: false,
      lengthMenu: [5000, 10000, 20000, 30000, 50000],
      pageLength: 5000,
      order: [[5, 'desc']],
      ajax: (data, callback, tableSettings) => {
        this.datatableProcessingDone = false;
        data.surgeonId = this.surgeonId;
        data.hospitalId = this.hospitalId;
        data.reportDateStart = moment(this.reportDateStart)
          .startOf('month')
          .format('YYYY-MM-DD');
        data.reportDateEnd = moment(this.reportDateEnd)
          .endOf('month')
          .format('YYYY-MM-DD');
        data.showOnlyEligible = this.showOnlyEligible;

        this.$api
          .post(`${settings.BACKEND_URL}/api/v-nurse/reports/hospital_summary`, null, data)
          .then((response) => {
            this.response = response;
            this.datatableProcessingDone = true;
            callback(response);
          });
      },
      dom:
        '<"h-row h-space-between h-align-item-center"<"h-show-bt h-margin-left-l header-title"><"h-d-flex h-table-bt"fB>>' +
        '<"h-row"<"h-col-desktop-12 h-col-mobile-12"tr>><"h-row h-space-between h-align-item-center h-padding-bottom-l"lip>',
      buttons: {
        buttons: [
          {
            extend: 'collection',
            className: 'ExportIcon',
            buttons: ['copy', 'excel', 'csv', 'print'],
          },
          {
            text: 'Change columns',
            action(e, dt, node, config) {
              that.$refs.toggleColumnsDialog.show();
            },
            className: 'h-btn-icon',
          },
        ],
        dom: {
          button: {
            className: 'h-btn',
          },
        },
      },
      columns: [
        {
          name: 'id',
          searchable: false,
          render: (data) => {
            return data ? `#${that.$strUtils.escapeHtml(data)}` : null;
          },
        },
        {
          name: 'hospital__name',
        },
        {
          name: 'location__name',

          searchable: true,
          orderable: true,
          render: data => (data ? that.$strUtils.escapeHtml(data) : '-'),
        },
        {
          name: 'surgeon__name',
        },
        {
          name: 'name',
          render: (data, type, row, meta) => {
            return `
            <a href="javascript:void(0);" class="OpenProfileInDrawer"  data-id="${row[0]}">
              ${that.$strUtils.escapeHtml(data)}
            </a>
          `;
          },
          className: 'text-nowrap',
        },
        {
          name: 'mrn',
          searchable: false,
          orderable: true,
        },
        {
          name: 'assigned_nurse',
          searchable: false,
          orderable: true,
        },
        {
          name: 'active_patient',
          searchable: false,
          orderable: true,
        },
        {
          name: 'mental_health_time',
          searchable: false,
          orderable: true,
        },
        {
          name: 'days_on_service',
          searchable: false,
          orderable: true,
        },
        {
          name: 'data_count',
          searchable: false,
          orderable: true,
        },
        {
          name: 'alerts_sent',
          searchable: false,
          orderable: true,
        },
        {
          name: 'total_appointments_count',
          searchable: false,
          orderable: true,
        },
        {
          name: 'total_appointments_time',
          searchable: false,
          orderable: true,
        },
        {
          name: 'compliance',
          searchable: false,
          orderable: true,
        },
        {
          name: 'create_to_activate',
          searchable: false,
          orderable: true,
        },
        {
          name: 'activate_to_track',
          searchable: false,
          orderable: true,
        },
        {
          name: 'create_to_track',
          searchable: false,
          orderable: true,
        },
        {
          name: 'nudge_effectiveness',
          searchable: false,
          orderable: true,
        },
        {
          name: 'nudge_count',
          searchable: false,
          orderable: true,
        },
        {
          name: 'secondary_diagnosis',
          searchable: false,
          render: (data, type, row, meta) => {
            if (!data) return '';
            return data.map(entry => `- ${entry}`).join('<br />');
          },
        },
        {
          name: 'primary_diagnosis',
          searchable: false,
          render: (data, type, row, meta) => {
            if (!data) return '';
            return data.map(entry => `- ${entry}`).join('<br />');
          },
        },
        {
          name: 'created_at',
          searchable: false,
          render: (data) => {
            const date = moment(data);
            return date.format('MMM D, YYYY');
          },
        },
        {
          name: 'insurance_info',
          searchable: false,
          orderable: true,
          render: (data, type, row, meta) => {
            if (!data) return '';
            const raw = data.map(ii => `- ${ii.name}`).join('<br />');
            return `<div class="pre-wrap">${raw}</div>`;
          },
        },
        {
          name: 'pain_score_trend',
          searchable: false,
        },
        {
          name: 'scs',
          searchable: true,
        },
        {
          name: 'Actions',
          searchable: false,
          data: null,
          render: (row) => {
            const uploadAthenaButton = `<button class="h-btn h-btn-safe h-table-btn send-athena-btn" style="margin-left: 5px" data-id="${row[0]}">Send to Athena</button>`;
            const bhiReportButton = `<button class="h-btn h-btn-accent h-table-btn bhi-report-btn" style="margin-left: 5px" data-id="${row[0]}">BHI Report</button>`;

            return `${'<button class="h-btn h-btn-accent h-table-btn edit-placeholder">RTM-RPM Report</button>'}
            ${bhiReportButton}${uploadAthenaButton}`;
          },
        },
      ],
    });

    $('div.header-title').html(
      "<h3 class='h-h3 h-primary_shade_1'>Provider report<span id='iconExpend' class='ExpendIcon h-margin-left-s'></span></h3>",
    );
    $(document).ready(() => {
      $('#iconExpend').click(() => {
        $('#iconExpend').toggleClass('CollapseIcon');
        $('.TableReports').toggleClass('Expended');
        $('#headerInput').toggleClass('d-none');
        $('#headerBox').toggleClass('d-none');
      });
    });
    this.allTableColumns = this.dataTable.context[0].aoColumns.map((aoColumn, index) => {
      return {
        text: $(this.dataTable.column(index).header()).text(),
        name: aoColumn.name,
      };
    });
    this.visibleTableColumns = JSON.parse(localStorage.getItem(TABLE_COLUMNS_KEY) || '[]');
    if (this.visibleTableColumns.length === 0) {
      this.visibleTableColumns = this.allTableColumns.slice();
    } else {
      this.visibleTableColumns = this.allTableColumns.filter((columnExists) => {
        const keep = this.visibleTableColumns.find(
          columnVis => columnVis.name === columnExists.name,
        );
        if (!keep) {
          const dtColumn = this.dataTable.column(this.allTableColumns.indexOf(columnExists));
          dtColumn.visible(false);
        }
        return keep;
      });
    }

    $('#hospital-report-table tbody').on('click', '.bhi-report-btn', function onBHIReport() {
      if (
        $(this)
          .closest('tr')
          .prev('tr')
          .hasClass('dt-hasChild')
      ) {
        const parentRow = $(this)
          .closest('tr')
          .prev('tr')[0];
        const cells = parentRow.cells;
        const clientId = cells[0].innerText.substring(1);
        const selectedMonth = $('#reportDateStart').val();
        const dateStart = moment(selectedMonth)
          .startOf('month')
          .format('YYYY-MM-DD');
        const dateEnd = moment(selectedMonth)
          .endOf('month')
          .format('YYYY-MM-DD');
        that.downloadBHIReport(clientId, dateStart, dateEnd);
      } else {
        console.log(this);
        const parentRow = $(this).closest('tr')[0];
        const cells = parentRow.cells;
        const clientId = cells[0].innerText.substring(1);
        const selectedMonth = $('#reportDateStart').val();
        const dateStart = moment(selectedMonth)
          .startOf('month')
          .format('YYYY-MM-DD');
        const dateEnd = moment(selectedMonth)
          .endOf('month')
          .format('YYYY-MM-DD');
        that.downloadBHIReport(clientId, dateStart, dateEnd);
      }
    });

    $('#hospital-report-table tbody').on('click', '.edit-placeholder', function onDownload() {
      if (
        $(this)
          .closest('tr')
          .prev('tr')
          .hasClass('dt-hasChild')
      ) {
        const parentRow = $(this)
          .closest('tr')
          .prev('tr')[0];
        const cells = parentRow.cells;
        const clientId = cells[0].innerText.substring(1);
        const selectedMonth = $('#reportDateStart').val();
        const dateStart = moment(selectedMonth)
          .startOf('month')
          .format('YYYY-MM-DD');
        const dateEnd = moment(selectedMonth)
          .endOf('month')
          .format('YYYY-MM-DD');
        that.downloadPatientReport(clientId, dateStart, dateEnd);
      } else {
        console.log(this);
        const parentRow = $(this).closest('tr')[0];
        const cells = parentRow.cells;
        const clientId = cells[0].innerText.substring(1);
        const selectedMonth = $('#reportDateStart').val();
        const dateStart = moment(selectedMonth)
          .startOf('month')
          .format('YYYY-MM-DD');
        const dateEnd = moment(selectedMonth)
          .endOf('month')
          .format('YYYY-MM-DD');
        that.downloadPatientReport(clientId, dateStart, dateEnd);
      }
    });

    $('#hospital-report-table tbody').on('click', '.send-athena-btn', function sendAthena() {
      if (
        $(this)
          .closest('tr')
          .prev('tr')
          .hasClass('dt-hasChild')
      ) {
        const parentRow = $(this)
          .closest('tr')
          .prev('tr')[0];
        const cells = parentRow.cells;
        const clientId = cells[0].innerText.substring(1);
        const selectedMonth = $('#reportDateStart').val();
        const dateStart = moment(selectedMonth)
          .startOf('month')
          .format('YYYY-MM-DD');
        const dateEnd = moment(selectedMonth)
          .endOf('month')
          .format('YYYY-MM-DD');
        that.sendToAthena(clientId, dateStart, dateEnd);
      } else {
        console.log(this);
        const parentRow = $(this).closest('tr')[0];
        const cells = parentRow.cells;
        const clientId = cells[0].innerText.substring(1);
        const selectedMonth = $('#reportDateStart').val();
        const dateStart = moment(selectedMonth)
          .startOf('month')
          .format('YYYY-MM-DD');
        const dateEnd = moment(selectedMonth)
          .endOf('month')
          .format('YYYY-MM-DD');
        that.sendToAthena(clientId, dateStart, dateEnd);
      }
    });

    $('#hospital-report-table tbody').on('click', '.push-to-server', function pushToServer() {
      const clientId = parseInt($(this).data('id'), 10);
      const metadata = that.response.metadata.find(entry => entry.id === clientId);
      that.$refs.sftpPushDialog.show(metadata);
    });

    this.hospitals = await this.$api.getHospitalsList();
    this.surgeons = await this.$api.searchSeargeon();

    eventBus.$on(
      'downloadFileOperationEnded',
      (event) => {
        this.downloadingReport = false;
        this.displayReportDownloadingMsgBox = false;
      },
      this,
    );

    eventBus.$on(
      'hospitalReportFilterChanged',
      (event) => {
        if (this.surgeonId !== null) {
          this.surgeonId = null;
        }
        if (event.reportDateStart) {
          this.reportDateStart = event.reportDateStart;
        }
        this.reportDateEnd = moment(this.reportDateStart)
          .endOf('month')
          .format('YYYY-MM-DD');
        this.hospitalId = event.hospitalId;
        this.showOnlyEligible = event.showOnlyEligible;
        this.dataTable.ajax.reload();
      },
      this,
    );
    $(this.$refs.table).on('click', '.OpenProfileInDrawer', async (e) => {
      const id = $(e.target)
        .closest('a')
        .attr('data-id');
      await this.$refs.PatientProfileInDrawer.show(id);

      /*       await this.$router.push({
        name: 'PatientDetail',
        params: { id },
      }); */
    });
    /*     $(this.$refs.table).on('click', '.OpenProfileInDrawer', async (e) => {
      const id = $(e.target)
        .closest('a')
        .attr('data-id');
      const currentURL = window.location.href;
      const routePath = currentURL.split('#')[1];

      // Remove the patientId parameter from the query string
      const updatedURL = currentURL.replace(/(\?|&)patientId=\d+/, '');

      // Construct the new URL with the patientId parameter at the end
      const newURL = `${updatedURL}${updatedURL.includes('?') ? '&' : '?'}patientId=${id}`;

      // Update the URL in the browser
      window.history.replaceState({}, '', newURL);

      this.client = await this.$api.getClient(id);
      that.$refs.PatientProfileInDrawer.show(this.client);
    }); */
    eventBus.$on(
      'profile-closed',
      () => {
        const currentURL = window.location.href;

        // Remove the patientId parameter from the query string
        const updatedURL = currentURL.replace(/(\?|&)patientId=\d+/, '');

        // Update the URL without reloading the page
        window.history.replaceState({}, '', updatedURL);
      },
      this,
    );
    eventBus.$on(
      'profile-drawer-closed',
      () => {
        const currentURL = window.location.href;

        // Remove the patientId parameter from the query string
        const updatedURL = currentURL.replace(/(\?|&)patientId=\d+/, '');

        // Update the URL without reloading the page
        window.history.replaceState({}, '', updatedURL);
      },
      this,
    );
  },
  beforeDestroy() {
    eventBus.$offOwner(this);
    if (this.dataTable) {
      this.dataTable.destroy(true);
      this.dataTable = null;
    }
  },
  components: {
    SelectorDialogInDrawer,
    DownloadAllReportsDrawer,
    StartTaskDialog,
    SftpPushDialog,
    PatientProfileInDrawer,
  },
};
</script>

<template>
  <div>
    <div
      class="h-add-patient
        h-padding-l"
    >
      <div class="">
        <div>
          <div v-if="!loading">
            <h3 class="h-h3 h-primary_shade_1 h-text-left h-margin-bottom-l">
              Import Patients
            </h3>
            <form class="h-row">
              <div
                class=" h-input-w49
                h-desktop-margin-right-2
                h-col-mobile-12
                h-margin-bottom-l"
              >
                <label class="h-label">
                  CSV file
                </label>
                <InputField
                  type="file"
                  accept=".csv"
                  :error="errors.csvFile"
                  v-model="csvFile"
                  @change="csvSelected"
                  @input="
                    () => {
                      errors.csvFile = null
                    }
                  "
                />
              </div>
            </form>

            <div class="h1-table h-padding-bottom-l" v-if="validRows.length > 0">
              <div class="h-d-flex h-align-items-center h-space-between">
                <h5 class="h-h3 h-safe">Valid Rows ({{ validRows.length }})</h5>
                <button
                  type="button"
                  class="h-btn h-btn-safe"
                  :disabled="validRows.length === 0"
                  @click="uploadFile"
                >
                  Import Patients
                </button>
              </div>

              <table class="table dataTable import-patient h-table-l-td">
                <thead class="thead-light">
                  <tr>
                    <th style="width: 5%;">Status</th>
                    <th style="width: 5%;">Row Number</th>
                    <th style="width: 5%;" v-for="(header, index) of expectedHeaders" :key="index">
                      {{ header.title }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(row, index) in validRows" :key="index">
                    <td :class="row.status === 'Failed' ? 'h-dangerous' : 'h-safe'">
                      {{ row.status }}
                    </td>
                    <td>{{ row.index }}</td>
                    <td v-for="(header, index) in expectedHeaders" :key="index">
                      {{ row[header.title] }}
                      <h5
                        v-if="row.errors && row.errors[header.dataName]"
                        class="error-message h-dangerous"
                      >
                        {{ row.errors[header.dataName] }}
                      </h5>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div class="h1-table h-padding-bottom-l" v-if="invalidRows.length > 0">
              <h5 class="h-h3 h-dangerous">Invalid Rows ({{ invalidRows.length }})</h5>
              <table class="table dataTable import-patient h-table-l-td">
                <thead class="thead-light">
                  <tr>
                    <th style="width: 5%;">Status</th>
                    <th style="width: 5%;">Row Number</th>
                    <th style="width: 5%;" v-for="(header, index) of expectedHeaders" :key="index">
                      {{ header.title }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(row, index) in invalidRows" :key="index">
                    <td class="h-dangerous">{{ row.status }}</td>
                    <td>{{ row.index }}</td>
                    <td
                      v-for="(header, index) of expectedHeaders"
                      :key="index"
                      :class="{ 'h-dangerous': row.error.includes(header.title) }"
                    >
                      {{ row[header.title] }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="h-h4 h-primary_shade_1">
              CSV file import only supports data exported with the following format:
              <ol class="h-margin-top-m h-margin-left-m">
                <li v-for="(header, index) of expectedHeaders" :key="index" class="h-margin-top-s">
                  {{ `${header.title}: (${header.type}) ${header.description}` }}
                </li>
              </ol>
            </div>
          </div>
          <div v-else class="card-body">
            <Loader />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { parse } from 'csv-parse';
import Loader from '@/components/Loader';
import InputField from '@/components/InputField';

export default {
  data() {
    return {
      loading: false,
      errors: {},
      csvFile: null,
      validRows: [],
      invalidRows: [],
      care_protocols: [],
      expectedHeaders: [
        {
          dataName: 'hospital_id',
          title: 'Hospital',
          type: 'Integer',
          regx: /^\d+$/,
          description: 'Hospital Id',
        },
        {
          dataName: 'name',
          title: 'Name',
          type: 'String',
          regx: /^[a-zA-Z0-9 .'-]+$/,
          description: 'Patient Name',
        },
        {
          dataName: 'date_of_birth',
          title: 'Date of Birth',
          type: 'Date',
          regx: /^\d{2}\/\d{2}\/\d{4}$/,
          description: 'Patient date of birth formatted MM/DD/YYYY',
        },
        {
          dataName: 'phone',
          title: 'Phone Number',
          type: 'Phone',
          regx: /^\(\d{3}\) \d{3}-\d{4}$/,
          description: 'Patient Phone Number',
        },
        {
          dataName: 'gender',
          title: 'Gender',
          type: 'String',
          regx: ['Male', 'Female'],
          description: 'Male/Female',
        },
        {
          dataName: 'height',
          title: 'Height',
          type: 'Integer',
          regx: /^\d+(\.\d+)?$/,
          description: 'Patient Height(Inch)',
        },
        {
          dataName: 'weight',
          title: 'Weight',
          type: 'Integer',
          regx: /^\d+(\.\d+)?$/,
          description: 'Patient Weight(Pound)',
        },
        {
          dataName: 'surgeon_id',
          title: 'Physician',
          type: 'Integer',
          regx: /^\d+$/,
          description: 'Surgeon Id',
        },
        {
          dataName: 'care_protocol_name',
          title: 'Care Protocol',
          type: 'String',
          regx: [],
          description: 'CareProtocol Name',
        },
        {
          dataName: 'plan_start_date',
          title: 'Plan Start Date',
          type: 'Date',
          regx: /^\d{2}\/\d{2}\/\d{4}$/,
          description: 'Plan Start Date formatted MM/DD/YYYY',
        },
        {
          dataName: 'plan_end_date',
          title: 'Plan End Date',
          type: 'Date',
          regx: /^\d{2}\/\d{2}\/\d{4}$/,
          description: 'Plan End Date formatted MM/DD/YYYY',
        },
        {
          dataName: 'monitoring_program',
          title: 'Monitoring Program',
          type: 'String',
          regx: ['RPM', 'RTM', 'CCM', 'PCM'],
          description: 'CCM/PCM/RPM/RTM',
        },
        {
          dataName: 'assigned_nurse_id',
          title: 'Assigned Care Provider',
          type: 'Integer',
          regx: /^\d+$/,
          description: 'CareProvider Id',
        },
        {
          dataName: 'status',
          title: 'Status',
          type: 'String',
          regx: ['active', 'pending'],
          description: 'active/pending',
        },
      ],
    };
  },

  watch: {},

  methods: {
    csvSelected(event) {
      const fileInput = event.target;
      const file = fileInput.files[0];
      if (!file) return;

      if (!file.name.endsWith('.csv')) {
        this.showMsg('Only csv format is allowed.');
        return;
      }
      fileInput.value = '';

      this.validRows = [];
      this.invalidRows = [];

      const reader = new FileReader();
      reader.onload = (e) => {
        const contents = e.target.result;
        this.parseCSV(contents);
      };
      reader.readAsText(file);
    },

    parseCSV(contents) {
      parse(
        contents,
        {
          columns: true,
          skip_empty_lines: true,
        },
        (err, data) => {
          this.validRows = [];
          this.invalidRows = [];

          if (err || data.length === 0) {
            this.showMsg('Parsing csv file error.');
            return;
          }
          const isValidHeader = this.validateHeader(Object.keys(data[0]));
          if (!isValidHeader) {
            this.showMsg('Some required headers do not exist in the selected csv file.');
            return;
          }

          data.forEach((row, index) => {
            const checked = this.validateFields(row, index);
            if (checked.error.length === 0) {
              this.validRows.push(checked);
            } else {
              this.invalidRows.push(checked);
            }
          });
        },
      );
    },

    validateHeader(headers) {
      const expectedHeaders = this.expectedHeaders.map(header => header.title);
      return expectedHeaders.every(h => headers.includes(h));
    },

    validateFields(row, index) {
      let valid = true;
      row = { ...row, error: [], status: 'Ready to add' };
      this.expectedHeaders.forEach((header) => {
        let check;
        if (!row[header.title]) valid = false;
        if (['Gender', 'Monitoring Program', 'Care Protocol', 'Status'].includes(header.title)) {
          check = header.regx.includes(row[header.title]);
        } else {
          check = header.regx.test(row[header.title]);
        }
        if (!check) {
          valid = false;
          row.error.push(header.title);
        }
      });
      row = { ...row, index: index + 1 };
      if (!valid) {
        row = { ...row, status: 'Invalid' };
      }
      return row;
    },

    showMsg(msg, error = true) {
      $.notify(msg, {
        position: 'top center',
        className: error ? 'error' : 'success',
      });
    },

    convertDateFormat(dateString) {
      const [month, day, year] = dateString.split('/');
      const dateObject = new Date(`${year}-${month}-${day}`);

      if (!isNaN(dateObject.getTime())) {
        return dateObject.toISOString().split('T')[0];
      }
      return null;
    },
    async uploadFile() {
      this.errors = {};
      if (this.validRows.length === 0) return;
      this.loading = true;
      for (const row of this.validRows) {
        try {
          const careProtocolId = this.care_protocols.find(
            protocol => protocol.name === row['Care Protocol'],
          ).id;
          const payload = {
            hospital_id: Number(row.Hospital),
            location_id: 0,
            name: row.Name,
            phone: `+1${row['Phone Number']
              .replace('(', '')
              .replace(')', '')
              .replace(' ', '')
              .replace('-', '')}`,
            date_of_birth: this.convertDateFormat(row['Date of Birth']),
            gender: row.Gender.toLowerCase(),
            height: Number(row.Height),
            weight: Number(row.Weight),
            surgeon_id: Number(row.Physician),
            assigned_nurse_id: Number(row['Assigned Care Provider']),
            physiotherapist_id: null,
            care_protocol_id: careProtocolId,
            removePhoto: false,
            plan_start_date: this.convertDateFormat(row['Plan Start Date']),
            plan_end_date: this.convertDateFormat(row['Plan End Date']),
            diagnoses: [],
            is_archived: false,
            pending_activation: false,
            medication_use_template: [],
            monitoring_program: row['Monitoring Program'],
            if_inactive_reason_id: null,
            is_traced: false,
            pain_categories: [],
            invite_client: false,
            assessments_off: 'No',
            status: row.Status === 'active',
          };
          const response = await this.$api.upsertClient(payload);
          if (response.status === 'ok') {
            row.status = 'Success';
          } else {
            row.status = 'Failed';
            row.errors = response.errors || { general: 'Error uploading data' };
          }
        } catch (e) {
          row.status = 'Failed';
          row.errors = { general: 'Network or server error' };
        }
      }
      this.loading = false;
    },
  },

  async mounted() {
    const resp = await this.$api.getCareProtocols();
    this.care_protocols = resp.care_protocols || [];
    const careProtocolNames = this.care_protocols.map(protocol => protocol.name);
    this.expectedHeaders.find(header => header.title === 'Care Protocol').regx = careProtocolNames;
    window.execAfterInitMenuInterface(() => {
      window.initActiveMenu('left-navigation-link>settings>import-csv');
    });
  },

  components: {
    Loader,
    InputField,
  },
};
</script>

<template>
  <div>
    <div v-if="visible">
      <div class="row">
        <div class="col-lg-6 col-md-12 oneChartWrapper">
          <highcharts
            ref="chart1"
            :options="chartOptionsReadings"
          />
        </div>

        <div class="col-lg-6 col-md-12 oneChartWrapper">
          <highcharts
            ref="chart2"
            :options="chartOptionsMinutes"
          />
        </div>
      </div>
    </div>
  </div>
</template>


<style lang="stylus" scoped>
.oneChartWrapper
  padding-bottom 1.5em
  padding-top 1.5em
</style>

<script>
import { Chart } from 'highcharts-vue';
import settings from '@/settings.js';

const READING_CODES = 'x99454 | x98977';
const MINUTE_CODES = 'x99457 | x99458 | x98980 | x98981 | x99490';

export default {
  data() {
    const that = this;
    return {
      visible: false,
      chartOptionsReadings: {
        credits: {
          enabled: false,
        },
        chart: {
          type: 'bar',
          height: 220,
          marginLeft: 150,
          marginRight: 150,
        },
        title: {
          text: READING_CODES,
        },
        xAxis: {
          categories: [],
        },
        yAxis: {
          min: 0,
          allowDecimals: false,
          stackLabels: {
            enabled: true,
            formatter() {
              const index = parseInt(this.base.split(',')[1], 10);
              const lt = that.chartOptionsReadings.series[0].data[index];
              const gt = that.chartOptionsReadings.series[1].data[index];
              const total = lt + gt;
              if (total === 0) return 'N/A';
              return `${(gt * 100 / total).toFixed(1)}%`;
            },
          },
        },
        legend: {
          reversed: true,
        },
        plotOptions: {
          series: {
            stacking: 'normal',
            dataLabels: {
              enabled: true,
            },
          },
          bar: {
            pointWidth: 25,
          },
        },
        series: [{
          name: '< 16 readings',
          data: [],
          color: '#d8503f',
        }, {
          name: '>= 16 readings',
          data: [],
          color: '#5383ec',
        }],
      },

      chartOptionsMinutes: {
        credits: {
          enabled: false,
        },
        chart: {
          type: 'bar',
          height: 220,
          marginLeft: 150,
          marginRight: 150,
        },
        title: {
          text: MINUTE_CODES,
        },
        xAxis: {
          categories: [
            'Nurse A',
            'Nurse B',
          ],
        },
        yAxis: {
          min: 0,
          allowDecimals: false,
          stackLabels: {
            enabled: true,
            formatter() {
              const index = parseInt(this.base.split(',')[1], 10);
              const lt = that.chartOptionsMinutes.series[0].data[index];
              const gt = that.chartOptionsMinutes.series[1].data[index];
              const total = lt + gt;
              if (total === 0) return 'N/A';
              return `${(gt * 100 / total).toFixed(1)}%`;
            },
          },
        },
        legend: {
          reversed: true,
        },
        plotOptions: {
          series: {
            stacking: 'normal',
            dataLabels: {
              enabled: true,
            },
          },
          bar: {
            pointWidth: 25,
          },
        },
        series: [{
          name: '< 20 minutes',
          data: [5, 12],
          color: '#d8503f',
        }, {
          name: '>=  20 minutes',
          data: [4, 10],
          color: '#5383ec',
        }],
      },
    };
  },

  methods: {
    async load() {
      try {
        const data = {
          date_start: this.dateStart,
          date_end: this.dateEnd,
        };
        if (this.hospitalId) {
          data.hospital_id = this.hospitalId;
        }
        const response = await this.$api.get(
          `${settings.BACKEND_URL}/api/v-nurse/reports/get-coding-opinion-by-nurse`,
          data,
        );
        const categories = [];
        this.chartOptionsReadings.xAxis.categories = categories;
        this.chartOptionsMinutes.xAxis.categories = categories;

        this.chartOptionsReadings.series[0].data = [];
        this.chartOptionsReadings.series[1].data = [];
        this.chartOptionsMinutes.series[0].data = [];
        this.chartOptionsMinutes.series[1].data = [];

        this.chartOptionsReadings.chart.height = 190 + 32 * response.data.length;
        this.chartOptionsMinutes.chart.height = this.chartOptionsReadings.chart.height;

        response.data.forEach((entry) => {
          categories.push(entry.nurse.name);

          this.chartOptionsReadings.series[0].data.push(entry.readings_chart_lt);
          this.chartOptionsReadings.series[1].data.push(entry.readings_chart_gte);

          this.chartOptionsMinutes.series[0].data.push(entry.minutes_chart_lt);
          this.chartOptionsMinutes.series[1].data.push(entry.minutes_chart_gte);
        });

        const hospitalName = response.hospital
          ? response.hospital.name
          : 'All hospitals';

        this.chartOptionsReadings.title.text =
          `From ${this.dateStart} to ${this.dateEnd}<br />${READING_CODES}`;
        this.chartOptionsReadings.title.text =
          `${hospitalName}<br />${this.chartOptionsReadings.title.text}`;

        this.chartOptionsMinutes.title.text =
          `From ${this.dateStart} to ${this.dateEnd}<br />${MINUTE_CODES}`;
        this.chartOptionsMinutes.title.text =
          `${hospitalName}<br />${this.chartOptionsMinutes.title.text}`;

        if (response.data.length) {
          this.visible = true;
        }
        this.$refs.chart1.chart.redraw();
        this.$refs.chart2.chart.redraw();
      } catch (e) {
        // todo show error message
        console.log(e);
      }
    },
  },

  async mounted() {
    await this.load();
  },

  watch: {
    dateStart() {
      this.load();
    },

    dateEnd() {
      this.load();
    },

    hospitalId() {
      this.load();
    },
  },

  props: {
    dateStart: {
      type: String,
      required: false,
    },
    dateEnd: {
      type: String,
      required: false,
    },
    hospitalId: {
      type: String,
      required: false,
    },
  },

  components: {
    highcharts: Chart,
  },
};
</script>

import io from 'socket.io-client';
import settings from '@/settings.js';


class GlobalCon {
  SECTION_MAIN_SITE = 'SECTION_MAIN_SITE';

  SECTION_WEB_VIDEO_MEETING = 'WEB_VIDEO_MEETING';

  SECTION_WEB_CHAT = 'SECTION_WEB_CHAT';

  constructor() {
    this.section = null;
    this.socket = null;
    this.modalMessageStack = null;

    this.observers = {};
  }

  setSection(section) {
    if (this.section === section) {
      return;
    }
    this.section = section;

    if (!this.section) {
      this.disconnect();
      return;
    }

    this.ensureSocketSetup();
    const data = {
      section: this.section,
    };
    this.socket.emit('update', data);
  }

  setModalMessageStack(modalMessageStack) {
    this.modalMessageStack = modalMessageStack;
  }

  disconnect() {
    if (!this.socket) {
      return;
    }
    this.socket.disconnect();
    this.socket = null;
  }

  ensureSocketSetup() {
    if (this.socket) {
      return;
    }
    this.socket = io(`${settings.BACKEND_URL}/web-socket/global-con`, { withCredentials: true });

    // --- notification ---
    this.subscribe('notification', (data) => {
      data.options = Object.assign({ position: 'top center', className: 'info' }, data.options);
      $.notify(data.message, data.options);
    });

    // --- modal ---
    this.subscribe('modal', (data) => {
      if (!this.modalMessageStack) {
        return;
      }
      this.modalMessageStack.addModal(data);
    });

    // --- call ---
    this.subscribe('call', (data) => {
      if (!this.modalMessageStack) {
        return;
      }
      this.modalMessageStack.addModal(data);
    });

    // --- call_dial_process ---
    // <subscribed/unsubscribed separately>
  }

  __ensureEventSubscription(eventName) {
    if (this.observers[eventName] === undefined) {
      this.observers[eventName] = [];
      this.socket.on(eventName, data => this.__handleEvent(eventName, data));
    }
  }

  __handleEvent(eventName, data) {
    this.observers[eventName].forEach((handleFunc) => {
      handleFunc(data);
    });
  }

  subscribe(eventName, handler) {
    this.__ensureEventSubscription(eventName);
    if (this.observers[eventName].indexOf(handler) === -1) {
      this.observers[eventName].push(handler);
    }
  }

  unsubscribe(eventName, handler) {
    this.__ensureEventSubscription(eventName);
    const index = this.observers[eventName].indexOf(handler);
    if (index !== -1) {
      this.observers[eventName].splice(index, 1);
    }
  }
}

export default {
  install: (Vue) => {
    // eslint-disable-next-line
    Vue.prototype.$globalCon = new GlobalCon();
  },
};

<template>
  <div>
    <div class="h-table-card h-margin-l">
      <div class="h1-table h-padding-top-l">
        <table id="PatientsActivity" ref="table" class="table dataTable w-100  h-Documents">
          <thead class="thead-light">
            <tr class="activity-header">
              <th class="h-text-right" style="width: 5%">Report month</th>
              <th style="width: 20%">Hospital</th>
              <th style="width: 15%">Type</th>
              <th class="h-text-right" style="width: 5%">Created Date</th>
              <th class="h-text-right" style="width: 5%">Publish Date</th>
              <th style="width: 45%">Title</th>
              <th style="width: 5%">Action</th>
            </tr>
          </thead>
        </table>
      </div>
    </div>
    <UploadBillingDocument ref="uploader" :hospitals="hospitals" @uploadSuccess="reloadDataTable" />
  </div>
</template>

<style lang="stylus" scoped></style>

<script>
import moment from 'moment';
import Vue from 'vue';
import UploadBillingDocument from './components/UploadBillingDocument';

export default {
  data() {
    return {
      dataTable: null,
      hospitals: [],
    };
  },

  methods: {
    initTable() {
      const self = this;

      const allColumns = [
        {
          name: 'report_month',
          data: 'report_month',
          className: 'h-text-right',
          searchable: true,
          render: data => (data ? moment.utc(data).format('MMMM, YYYY') : null),
        },
        {
          name: 'hospital_name',
          data: 'hospital_name',
          searchable: true,
        },
        {
          name: 'report_type',
          data: 'report_type',
          searchable: true,
        },
        {
          name: 'created_at',
          data: 'created_at',
          searchable: false,
          className: 'h-text-right',
          render: data => (data
            ? moment
              .utc(data)
              .local()
              .format('DD MMMM, YYYY HH:mm')
            : null),
        },
        {
          name: 'updated_at',
          data: 'updated_at',
          searchable: false,
          className: 'h-text-right',
          render: data => (data
            ? moment
              .utc(data)
              .local()
              .format('DD MMMM, YYYY HH:mm')
            : null),
        },
        {
          name: 'name',
          data: 'name',
          searchable: true,
        },
        {
          name: 'action',
          searchable: false,
          orderable: false,
          render: (data, type, row) => {
            return `
            <div class="text-left">
              <a
                href="javascript:void(0);"
                class="mr-2 btn-download"
                data-id="${row.id}"
              >
                <i class="fas fa-download text-info font-16" data-tooltip="Download"></i>
              </a>
            </div>
          `;
          },
        },
      ];

      this.dataTable = $(this.$refs.table).DataTable({
        order: [[1, 'desc']],
        ordering: true,
        processing: true,
        serverSide: true,
        pageLength: 100,
        ajax: (data, callback, tableSettings) => {
          const column = data.columns[data.order[0].column].name;
          const order = `${data.order[0].dir === 'desc' ? '-' : ''}${column}`;
          const search = data.search.value;

          this.$api
            .getBillingDocuments({
              start: data.start,
              length: data.length,
              order,
              search,
            })
            .then((response) => {
              tableSettings.json = response;
              callback(response);
            });
        },

        searching: true,
        searchDelay: 1000,
        dom:
          '<"h-row h-space-between h-align-item-center"<"h-show-bt h-margin-left-l header-title">' +
          '<"h-d-flex h-table-bt"fB>>' +
          '<""<""tr>><"h-row h-space-between h-align-item-center h-padding-bottom-l"lip>',
        buttons: {
          buttons: [
            {
              text: 'Upload',
              action() {
                self.upload();
              },
              className: 'h-btn h-btn-accent addnewpatioint',
            },
          ],
          dom: {
            button: {
              className: 'h-btn',
            },
          },
        },
        columns: allColumns.filter((column) => {
          if (this.isAdmin) return true;
          return !column.__adminOnly;
        }),
      });
      $('div.header-title').html("<h3 class='h-h3 h-primary_shade_1'>Documents</h3>");

      // Handle click events on the edit and delete buttons
      $(this.$refs.table).on('click', '.btn-download', (e) => {
        const id = $(e.target)
          .closest('a')
          .attr('data-id');
        this.download(id);
      });

      $(this.$refs.table).on('click', '.btn-delete', (e) => {
        const id = $(e.target)
          .closest('a')
          .attr('data-id');
        this.deleteItem(id);
      });
    },

    async getBillingHospitals() {
      try {
        this.hospitals = await this.$api.getBillingHospitals();
      } catch (e) {
        console.log(e);
        this.hospitals = [];
      }
    },

    upload() {
      this.$refs.uploader.show();
    },

    reloadDataTable() {
      this.dataTable.ajax.reload();
    },

    async download(id) {
      const data = this.dataTable.data().toArray();
      const document = data.find(i => i.id === Number(id));
      await this.$api.downloadBillingDocument(document.id, document.name);
    },

    deleteItem(id) {
      Vue.$confirm({
        title: 'Are you sure?',
        message: 'Do you really want to delete this document?',
        button: {
          yes: 'Delete',
          no: 'No',
        },
        callback: async (confirm) => {
          if (confirm) {
            await this.$api.deleteBillingDocument(id);
            this.reloadDataTable();
          }
        },
      });
    },
  },

  mounted() {
    window.execAfterInitMenuInterface(() => {
      window.initActiveMenu('left-navigation-link>hospital>documents');
    });

    this.initTable();
    this.getBillingHospitals();
  },

  beforeDestroy() {
    if (this.dataTable) {
      this.dataTable.destroy(true);
      this.dataTable = null;
    }
  },

  components: {
    UploadBillingDocument,
  },
};
</script>


    <template v-slot:modal-body>
  <div class="h-drawer-max79">
    <Drawer :direction="'right'" :exist="true" ref="modalDialog">
      <div class="h-drawer-card">
        <div
          class="
            h-drawer-card-header
            h-align-items-center
            h-space-between
            h-padding-bottom-l
          "
        >
          <h5 class="h-h3 h-primary_shade_1">Rename file</h5>
          <div>
            <button
              type="button"
              class="close"
              aria-hidden="true"
              @click="close"
              :disabled="loading"
            >
              <!-- <font-awesome-icon
                    :icon="['fas', 'sign-out-alt']"
                    style="height: 1.5vw; width: 1.5vw; color: #c3c4cb"
                  /> -->
              <img
                src="../../../../../../public/assets/images/Collapse-drawer.svg"
                alt=""
              />
            </button>
          </div>
        </div>
        <div class="h-drawer-card-body">
          <label class="h-label">Rename file to: </label>
          <InputField
            type="text"
            v-model="newFilepath"
            :error="errors.newFilepath"
            @change="errors.newFilepath = null"
          />
        </div>
        <div class="h-drawer-card-footer">
          <button
            type="button"
            class="h-btn h-btn-accent"
            @click="confirm"
            :disabled="loading"
          >
            Rename
          </button>
        </div>
      </div>
    </Drawer>
  </div>
</template>


<script>
import settings from '@/settings.js';
import InputField from '@/components/InputField';

export default {
  data() {
    return {
      loading: true,
      document: null,
      newFilepath: '',
      errors: {},
    };
  },

  methods: {
    show(document) {
      this.$refs.modalDialog.open();
      this.$refs.modalDialog.active = true;
      this.document = document;
      this.newFilepath = document.display_filepath;
      this.loading = false;
      // this.$refs.slottedMessage.show();
    },

    close(force) {
      // this.$refs.slottedMessage.close(force);
      this.$refs.modalDialog.close(force);
      this.$refs.modalDialog.active = false;
    },

    async confirm() {
      this.loading = true;
      const payload = {
        documentId: this.document.id,
        filepath: this.document.display_filepath,
        newFilepath: this.newFilepath,
        clientId: this.document.client_id,
      };

      const response = await this.$api.post(
        `${settings.BACKEND_URL}/api/v-nurse/client/file/rename`,
        null,
        payload,
      );
      this.loading = false;

      if (response.status === 'ok') {
        this.close(true);
        this.$emit('setDocuments', response.documents);
      } else {
        this.errors = response.errors;
      }
    },
  },

  components: {
    InputField,
  },
};
</script>

<template>
  <div id="app" @mouseover="onMouseOver">
    <vue-confirm-dialog></vue-confirm-dialog>
    <router-view />
    <div v-if="tooltipContent" :style="tooltipStyle" class="base-tooltip">
      {{ tooltipContent }}
    </div>
  </div>
</template>

<script>
import _ from 'lodash';

export default {
  name: 'App',
  data() {
    return {
      tooltipContent: '',
      tooltipX: 0,
      tooltipY: 0,
      tooltipWidth: 0,
    };
  },
  computed: {
    tooltipStyle() {
      return {
        position: 'absolute',
        top: `${this.tooltipY}px`,
        left: `${this.tooltipX}px`,
        zIndex: 9999999999999,
      };
    },
  },
  methods: {
    onMouseOver: _.throttle(function onMouseOverHandler(event) {
      const hoveredElement = event.target;
      if (hoveredElement && hoveredElement.closest('.noTooltip')) {
        this.tooltipContent = '';
        return;
      }
      if (hoveredElement && hoveredElement.closest('.ck-editor')) {
        this.tooltipContent = '';
        return;
      }
      const dataTooltip = hoveredElement.getAttribute('data-tooltip');
      const ariaLabel = hoveredElement.getAttribute('aria-label');
      this.tooltipContent = dataTooltip || ariaLabel || '';

      if (this.tooltipContent) {
        this.tooltipX = event.pageX + 10;
        this.tooltipY = event.pageY + 10;

        this.$nextTick(() => {
          const tooltipElement = this.$el.querySelector('.base-tooltip');
          if (tooltipElement) {
            this.tooltipWidth = tooltipElement.offsetWidth;
            const windowWidth = window.innerWidth;
            let leftPosition = this.tooltipX;
            if (leftPosition + this.tooltipWidth > windowWidth) {
              leftPosition = event.clientX - this.tooltipWidth - 10;
            }
            this.tooltipX = Math.max(0, leftPosition);
            tooltipElement.style.left = `${this.tooltipX}px`;
            tooltipElement.style.top = `${this.tooltipY}px`;
          }
        });
      }
    }, 300),
  },
  watch: {
    'currentSession.user.email': function (newEmail) {
      if (newEmail) {
        window.Intercom('update', {
          email: newEmail,
        });
      }
    },
  },
  mounted() {
    window.Intercom('boot', {
      app_id: 'wu63cwxk',
      api_base: 'https://api-iam.intercom.io',
    });
  },
  beforeDestroy() {
    document.body.style.zoom = 1;
  },
};
</script>

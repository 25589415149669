<template v-slot:modal-body>
  <Drawer :direction="'right'" :exist="true" ref="modalDialog">
    <div class="row h-100 h-drawer-max79">
      <div class="col-lg-12">
        <div class="card h-drawer-card">
          <div
            class="card-header h-drawer-card-header h-padding-bottom-l"
            style="background-color: white"
          >
            <div class="row">
              <div class="col-lg-6 text-left">
                <h5 class="h-h3 h-primary_shade_1">Assessments reminder</h5>
              </div>
              <div class="col-lg-6 text-right">
                <button
                  type="button"
                  class="close"
                  aria-hidden="true"
                  @click="close"
                  :disabled="loading"
                >
                  <img src="/assets/images/Collapse-drawer.svg" alt="" />
                </button>
              </div>
            </div>
          </div>
          <div class="card-body h-drawer-card-body">
            <div class="row">
              <div v-if="!loading" class="h-padding-top-l h-padding-left-l h-h4 h-primary_shade_1">
                Send reminder?
              </div>

              <Loader v-else />
            </div>
          </div>
          <div class="h-drawer-card-footer" style="background-color: white">
            <button type="button" class="h-btn h-btn-accent" @click="confirm" :disabled="loading">
              Confirm
            </button>

            <button
              type="button"
              class="h-btn h-btn-dangerous h-margin-left-s"
              @click="close"
              :disabled="loading"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  </Drawer>
</template>

<script>
import Loader from '@/components/Loader';

export default {
  data() {
    return {
      loading: false,
      assessmentId: null,
    };
  },

  methods: {
    show(assessmentId) {
      this.loading = false;
      this.assessmentId = assessmentId;
      this.$refs.modalDialog.open();
      this.$refs.modalDialog.active = true;
    },

    async confirm() {
      this.loading = true;
      try {
        const response = await this.$api.sendClientAssesmentsReminder(this.assessmentId);
        if (response.status === 'ok') {
          $.notify(response.message, {
            position: 'top center',
            className: 'success',
          });
        } else {
          $.notify(response.message, {
            position: 'top center',
            className: 'error',
          });
        }
      } catch (e) {
        $.notify('Something is wrong in server', {
          position: 'top center',
          className: 'error',
        });
      }

      this.close(true);
    },

    close(force) {
      this.$refs.modalDialog.close(force);
      this.$refs.modalDialog.active = false;
    },
  },

  components: {
    Loader,
  },
};
</script>

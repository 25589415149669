<template>
  <div>
    <div
      v-if="recipeList.length === 0"
      class="text-center no-recipes grey--text"
    >
      No recipes to show
    </div>
    <div class="h-recipe">
      <Recipe
        v-for="(recipe, index) of recipeList"
        :key="recipe.recipe_id"
        :recipe="recipe"
        :index="index + startItemNumber"
      />
    </div>
    <div
      class="
        h-space-between h-align-items-center h-margin-left-l h-margin-right-l
      "
    >
      <div v-if="pageValue && pageCount" class="h-h4 h-primary_shade_1">
        Page: {{ pageValue }} of {{ pageCount }}
      </div>
      <div v-if="pageValue && pageCount" class="text-center paginationWrapper">
        <Pagination v-model="pageValue" :length="pageCount" />
      </div>
    </div>
  </div>
</template>


<script>
import Recipe from './Recipe';
import Pagination from '@/components/Pagination';

export default {
  data() {
    return {
      pageValue: this.page,
    };
  },

  watch: {
    page(value) {
      this.pageValue = value;
    },

    pageValue(value) {
      this.$emit('setPage', value);
    },
  },

  computed: {
    startItemNumber() {
      return (this.page - 1) * this.perPage + 1;
    },
  },

  components: {
    Recipe,
    Pagination,
  },

  props: {
    recipeList: {
      type: Array,
      required: true,
    },
    page: {
      type: Number,
    },
    perPage: {
      type: Number,
    },
    pageCount: {
      type: Number,
    },
  },
};
</script>

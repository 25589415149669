<template>
  <div class="h-drawer-max79">
    <SlottedMessageInDrawer
      ref="slottedMessage"
      title="Communication note"
      :closeDisabled="loading"
      modalSize="xl"
    >
      <template v-slot:modal-body>
        <div v-if="!loading && note" class="h1-table">
          <h4 class="h-h4 h-primary_shade_1 h-margin-bottom-l">
            Do you really want to <strong>delete</strong> the note?
          </h4>
          <table class="table dataTable h-table-v">
            <tbody>
              <tr>
                <th>Date:</th>
                <td>{{ note.date }}</td>
              </tr>

              <tr>
                <th>Physician name:</th>
                <td>{{ note.providerName ? note.providerName.trim() : "" }}</td>
              </tr>

              <tr>
                <th>Note:</th>
                <td>
                  <div>
                    {{ note.note ? note.note.trim() : "" }}
                  </div>
                </td>
              </tr>

              <tr>
                <th>
                  Notify
                  {{ client.surgeon ? client.surgeon.name : "Surgeon" }}
                </th>
                <td>{{ note.surgeonNotified }}</td>
              </tr>
            </tbody>
          </table>
        </div>

        <Loader v-else />
      </template>

      <template v-slot:modal-footer>
        <button
          type="button"
          class="h-btn h-btn-accent"
          @click="confirm"
          :disabled="loading"
        >
          Confirm
        </button>

        <button
          type="button"
          class="h-btn h-btn-dangerous h-margin-left-s"
          @click="close"
          :disabled="loading"
        >
          Cancel
        </button>
      </template>
    </SlottedMessageInDrawer>
  </div>
</template>


<script>
import Loader from '@/components/Loader';
import SlottedMessageInDrawer from '@/components/dialogs/SlottedMessageInDrawer';

export default {
  data() {
    return {
      loading: false,
      note: null,
    };
  },

  methods: {
    show(note) {
      this.note = note;
      this.loading = false;
      this.$refs.slottedMessage.show();
    },

    async confirm() {
      this.loading = true;
      const response = await this.$api.deleteClientCommunicationNote({
        client_id: this.client.id,
        communication_entry: this.note,
      });
      this.$emit('update', response.communication_entries);
      this.close(true);
    },

    close(force) {
      this.$refs.slottedMessage.close(force);
    },
  },

  components: {
    Loader,
    SlottedMessageInDrawer,
  },

  props: {
    client: {
      type: Object,
      required: true,
    },
  },
};
</script>

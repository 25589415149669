<template v-slot:modal-body>
  <Drawer :direction="'right'" :exist="true" ref="modalDialog">
    <div class="h-drawer-max79">
      <div class="h-drawer-card">
        <div
          class="
            h-drawer-card-header
            h-align-items-center
            h-space-between
            h-padding-bottom-l
          "
        >
          <h5 class="h-h3 h-primary_shade_1">Upload documents</h5>
          <div>
            <button
              type="button"
              class="close"
              aria-hidden="true"
              @click="close"
              :disabled="loading"
            >
              <!-- <font-awesome-icon
                    :icon="['fas', 'sign-out-alt']"
                    style="height: 1.5vw; width: 1.5vw; color: #c3c4cb"
                  /> -->
              <img src="../../../../../../public/assets/images/Collapse-drawer.svg" alt="" />
            </button>
          </div>
        </div>
        <div class="h-drawer-card-body">
          <div>
            <label class="h-label">Select file to upload </label>
            <InputField
              type="file"
              v-model="file"
              :error="errors.file"
              @change="errors.file = null"
            />
          </div>

          <div class="h-margin-top-l">
            <label class="h-label">
              <InputField
                type="checkbox"
                v-model="useFilepath"
                :error="errors.useFilepath"
                @change="errors.useFilepath = null"
                label="Rename file to"
                id="useFilepath"
              />
            </label>
            <InputField
              type="text"
              v-model="filepath"
              :error="errors.filepath"
              @change="errors.filepath = null"
              :disabled="!useFilepath"
            />
          </div>
        </div>
        <div class="h-drawer-card-footer">
          <button
            type="button"
            class="h-btn h-btn-accent"
            @click="upload"
            :disabled="loading || !file"
          >
            Upload file
          </button>
        </div>
      </div>
    </div>
  </Drawer>
</template>

<script>
import settings from '@/settings.js';
import InputField from '@/components/InputField';

export default {
  data() {
    return {
      loading: true,
      file: null,
      filepath: '',
      useFilepath: false,
      errors: {},
    };
  },

  watch: {
    file(value) {
      if (!this.useFilepath) {
        if (value) {
          this.filepath = value.name;
        } else {
          this.filepath = '';
        }
      }
    },

    useFilepath(value) {
      if (!value) {
        if (this.file) {
          this.filepath = this.file.name;
        } else {
          this.filepath = '';
        }
      }
    },
  },

  methods: {
    show() {
      this.$refs.modalDialog.open();
      this.$refs.modalDialog.active = true;
      this.file = null;
      this.useFilepath = false;
      this.filepath = '';
      this.loading = false;
    },

    close(force) {
      // this.$refs.slottedMessage.close(force);
      this.$refs.modalDialog.close(force);
      this.$refs.modalDialog.active = false;
    },

    async upload() {
      this.loading = true;
      const payload = {
        document: this.file,
        clientId: this.client.id,
        filepath: this.filepath,
      };

      const response = await this.$api.postMultipart(
        `${settings.BACKEND_URL}/api/v-nurse/client/file/upload`,
        null,
        payload,
      );
      this.loading = false;

      if (response.status === 'ok') {
        this.close(true);
        this.$emit('setDocuments', response.documents);
      } else {
        this.errors = response.errors;
      }
    },
  },

  components: {
    InputField,
  },

  props: {
    client: {
      type: Object,
      required: true,
    },
  },
};
</script>

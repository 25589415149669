<template>
  <div>
    <div class="h-table-card h-margin-l">
      <div class="h1-table h-padding-top-l fullscreen">
        <div class="h-d-flex h-space-between h-margin-right-l h-margin-left-l">
          <div>
            <div class="h-d-flex h-align-items-center">
              <h3 class="h-h3 h-primary_shade_1 h-align-items-center h-padding-right-s">
                Patients Alerts
              </h3>
              <selectDropdownFilter
                @applyFilters="applyFilters"
                :initialFilters="filters"
                :resetSignal="resetSignal"
              />
            </div>
            <div class="filter-summary h-d-flex h-padding-top-xs h-padding-right-s">
              <h5 class="h-h5 h-secondary_shade_1 h-align-items-center">
                <span class="h-padding-right-xs">Filtered by:</span>

                <template v-if="filters.alertStatus !== 'any'">
                  <span>{{ filtersText }}</span>
                </template>
                <template v-if="filters.category !== 'any'">
                  <span class="h-padding-right-xs" v-if="filters.alertStatus !== 'any'">, </span>
                  <span>{{ filtersCategory }}</span>
                </template>

                <template v-if="filters.alertStatus === 'any' && filters.category === 'any'">
                  <span class="d-inline-flex">None</span>
                </template>
                <i
                  v-if="filters.alertStatus !== 'any' && filters.category !== 'any'"
                  class="h-cursor-pointer h-line-height-1
                  h-dangerous healenticons-close-circle h-padding-left-s"
                  @click="resetFilters"
                ></i>
              </h5>
            </div>
          </div>
          <div class="h-d-flex h-align-items-center align-items-center w-300">
            <input
              type="search"
              placeholder="Search"
              class="h-input "
              v-model="textSearch"
              @input="handleInput()"
            />
            <div class="h-relative h-line-height-1 dropdown h-add-dropdown">
              <a
                class="dropdown-toggle arrow-none"
                data-toggle="dropdown"
                href="#"
                role="button"
                aria-haspopup="false"
                aria-expanded="false"
                style="background: white"
              >
                <i class="h-h3  h-secondary_shade_1 healenticons-download h-margin-left-m"> </i>
              </a>
              <div ref="collectionDropdown" class="dropdown-menu assessment-dropdown"></div>
            </div>
          </div>
        </div>

        <table
          id="PatientsActivity"
          ref="table"
          class="table dataTable w-100 h-table-log h-table-activity"
        >
          <thead class="thead-light">
            <tr class="activity-header">
              <th style="width: 5%" class="h-d-none">ID</th>
              <th style="width: 5%" class="text-center">Action</th>
              <th style="width: 5%">Date</th>
              <th style="width: 5%">Facility</th>
              <th style="width: 5%">Patient Name</th>
              <th style="width: 5%"><div id="DivStatsSelect"></div></th>
              <th style="width: 5%"><div id="DivCategorySelect"></div></th>
              <th style="width: 5%">Topic</th>
              <th style="width: 5%">Patient action</th>
              <th style="width: 5%">Nurse action</th>
              <th style="width: 5%">Intervention</th>
            </tr>
          </thead>
        </table>
        <PatientProfileInDrawer ref="PatientProfileInDrawer" :key="client.id" />
        <PushNotificationDialog ref="pushNotificationDialog" />
        <EditAlertDialog ref="editAlertDialog" @updated="refresh" />
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment-timezone';
import PushNotificationDialog from './components/PushNotificationDialog';
import EditAlertDialog from './components/EditAlertDialog';
import PatientProfileInDrawer from '../../../all_patients/components/PatientProfileInDrawer';
import eventBus from '../../../../../event-bus';
import selectDropdownFilter from './components/selectDropdownFilter';

export default {
  data() {
    return {
      client: {},
      dataTable: null,
      visibleTableLogColumns: [],
      selectedStatus: '',
      selectedCategory: '',
      textSearch: '',
      resetSignal: false,

      filters: {
        dateStart: moment()
          .subtract(365, 'days')
          .format('YYYY-MM-DD'),
        dateEnd: moment().format('YYYY-MM-DD'),
        alertStatus: 'active',
        category: 'Follow-up Required',
        client: null,
        topic: '',
      },
    };
  },
  computed: {
    filtersText() {
      if (this.filters.alertStatus === 'active') {
        return 'Open';
      }
      if (this.filters.alertStatus === 'wont_fix') {
        return 'Ignored';
      }
      if (this.filters.alertStatus === 'resolved') {
        return 'Resolved';
      }
      return 'All status';
    },
    filtersCategory() {
      if (this.filters.category === 'any' || this.filters.category === '') {
        return 'All category';
      }
      return this.filters.category;
    },
  },
  methods: {
    refresh() {
      this.dataTable.ajax.reload(null, false);
    },
    resetFilters() {
      const reset = {
        dateStart: moment()
          .subtract(365, 'days')
          .format('YYYY-MM-DD'),
        dateEnd: moment().format('YYYY-MM-DD'),
        alertStatus: 'any',
        category: 'any',
        client: null,
        topic: '',
      };
      this.filters = reset;
      this.resetSignal = !this.resetSignal;
      this.refresh();
    },
    applyFilters(filters) {
      this.filters = filters;
      this.refresh();
    },
    changeStatus() {
      this.alertStatus = this.selectedStatus;
      this.refresh();
    },
    search() {
      if (this.dataTable) {
        this.dataTable.search(this.textSearch).draw();
      } else {
        console.error('DataTable not initialized!');
      }
    },
    handleInput() {
      if (!this.textSearch) {
        this.clearSearch();
      } else {
        this.search();
      }
    },
    clearSearch() {
      this.textSearch = '';
      if (this.dataTable) {
        this.dataTable.search('').draw();
      }
    },
  },

  mounted() {
    const that = this;
    const allColumns = [
      {
        name: 'id',
        visible: false,
      },
      {
        name: 'action',
        searchable: false,
        orderable: false,
        render: (data, type, row, meta) => {
          const rowIndex = meta.settings.json.data.indexOf(row);
          const userId = meta.settings.json.metadata[rowIndex].user_id;
          const messagesPath = this.$router.resolve({
            name: 'Chat/userId',
            params: {
              userId,
            },
          });

          return `
              <div class="text-center">
                <a
                  href="javascript:void(0);"
                  class="mr-2 showPushNotificationDialog"
                  data-id="${row[0]}"
                >
                  <i class="fas fa-bell text-primary font-16"></i>
                </a>
                <a
                  href="javascript:void(0);"
                  class="mr-2 showEditAlertDialog"
                  data-id="${row[0]}"
                >
                  <i class="healenticons-edit text-primary font-16"></i>
                </a>
                <a href="${messagesPath.href}" class="mr-2">
                  <i class="fas fa-comment-alt text-primary font-16"></i>
                </a>
              </div>
            `;
        },
      },
      {
        name: 'due_date',
        searchable: false,
        render: data => (data ? moment(data).format('MMM D, YYYY h:mm a') : null),
      },
      {
        name: 'client_facility',
        render: (data, type, row, meta) => {
          const rowIndex = meta.settings.json.data.indexOf(row);
          const clientId = meta.settings.json.metadata[rowIndex].client_id;
          return `
            <a href="javascript:void(0);" class="OpenProfileInDrawer" data-id="${clientId}">
              ${that.$strUtils.escapeHtml(data)}
            </a>
            `;
        },
      },
      {
        name: 'client_name',
        render: (data, type, row, meta) => {
          const rowIndex = meta.settings.json.data.indexOf(row);
          const clientId = meta.settings.json.metadata[rowIndex].client_id;
          return `
            <a href="javascript:void(0);" class="OpenProfileInDrawer" data-id="${clientId}">
              ${that.$strUtils.escapeHtml(data)}
            </a>
            `;
        },
      },
      {
        name: 'alert_status',
        searchable: false,
        orderable: false,
        render: (data, type, row, meta) => {
          const value =
            {
              resolved: 'Resolved',
              active: 'Open',
              wont_fix: 'Ignore',
            }[data] || 'None';
          return value;
        },
      },
      {
        name: 'status',
        searchable: false,
        orderable: false,
        render: (data, type, row, meta) => {
          const badgeClass =
            {
              Done: 'success',
              'In Progress': 'warning',
              'Not Started': 'info',
              Delayed: 'danger',
              'Follow-up Required': 'danger',
            }[data] || 'info';

          return `<span class="badge badge-soft-${badgeClass}">
              ${data}
            </span>`;
        },
      },

      {
        name: 'topic',
        className: 'h-td-wr minwidth300',
        render: (data) => {
          if (!data) return null;
          const truncatedText = data.length > 80 ? `${data.substring(0, 80)}...` : data;
          return `<span data-tooltip="${data}">${truncatedText}</span>`;
        },
      },

      {
        name: 'patient_action',
        searchable: false,

        className: 'h-td-wr minwidth300',
        render: (data) => {
          if (!data) return null;
          const truncatedText = data.length > 80 ? `${data.substring(0, 80)}...` : data;
          return `<span data-tooltip="${data}">${truncatedText}</span>`;
        },
      },
      {
        name: 'nurse_action',
        searchable: false,
        className: 'h-td-wr minwidth300',
        render: (data) => {
          if (!data) return null;
          const truncatedText = data.length > 80 ? `${data.substring(0, 80)}...` : data;
          return `<span data-tooltip="${data}">${truncatedText}</span>`;
        },
      },

      {
        name: 'intervention',
        searchable: false,
        className: 'h-td-wr minwidth300',
        render: (data) => {
          if (!data) return null;
          const truncatedText = data.length > 80 ? `${data.substring(0, 80)}...` : data;
          return `<span data-tooltip="${data}">${truncatedText}</span>`;
        },
      },
    ];
    const dataTable = $(this.$refs.table).DataTable({
      order: [[2, 'desc']],
      ordering: true,
      processing: true,
      serverSide: true,
      pageLength: 100,
      ajax: (data, callback, tableSettings) => {
        const payload = JSON.parse(JSON.stringify(data));
        payload.customFilters = that.filters;

        this.$api.getClientsActivity(payload).then((response) => {
          tableSettings.json = response;
          callback(response);
        });
      },

      searching: true,
      dom:
        '<""<""tr>><"h-row h-space-between h-align-item-center h-padding-bottom-l h-padding-top-l"lip>',
      buttons: {
        buttons: ['copy', 'excel', 'csv', 'print'],
      },

      columns: allColumns.filter((column) => {
        if (this.isAdmin) return true;
        return !column.__adminOnly;
      }),
    });
    this.dataTable = dataTable;
    this.$nextTick(() => {
      $(this.$refs.table).on('init.dt', () => {
        this.dataTable
          .buttons()
          .container()
          .appendTo(this.$refs.collectionDropdown);
      });
    });
    $(this.$refs.table).on('click', '.OpenProfileInDrawer', async (e) => {
      const id = $(e.target)
        .closest('a')
        .attr('data-id');
      await this.$refs.PatientProfileInDrawer.show(id);
    });
    eventBus.$on(
      'alertStatusModified',
      (event) => {
        this.dataTable.ajax.reload(null, false);
      },
      this,
    );
    $(this.$refs.table).on('click', '.showPushNotificationDialog', (e) => {
      const id = $(e.target)
        .closest('a')
        .attr('data-id');
      const data = dataTable.data().toArray();
      const row = data.find((entry) => {
        return entry[0] === id;
      });
      const headers = $(this.$refs.table)
        .find('th')
        .map((index, el) => {
          return $(el)
            .text()
            .trim();
        })
        .toArray();
      headers.unshift('id');
      headers.pop();
      const idParts = id.split('-');
      const resultData = {
        metadata: {
          id: parseInt(idParts[1], 10),
          entryType: idParts[0],
        },
      };
      headers.forEach((header, index) => {
        resultData[header] = row[index];
      });
      that.$refs.pushNotificationDialog.show(resultData);
    });

    $(this.$refs.table).on('click', '.showEditAlertDialog', (e) => {
      const id = $(e.target)
        .closest('a')
        .attr('data-id');
      const data = dataTable.data().toArray();
      const row = data.find((entry) => {
        return entry[0] === id;
      });
      const headers = $(this.$refs.table)
        .find('th')
        .map((index, el) => {
          return $(el)
            .text()
            .trim();
        })
        .toArray();
      headers.unshift('id');
      headers.pop();
      const idParts = id.split('-');
      const resultData = {
        metadata: {
          id: parseInt(idParts[1], 10),
          entryType: idParts[0],
        },
      };
      headers.forEach((header, index) => {
        resultData[header] = row[index];
      });
      that.$refs.editAlertDialog.show(resultData);
    });
    $(document).ready(() => {
      $('#DivStatsSelect').prepend($('#statsSelect'));
      $('#DivCategorySelect').prepend($('#selectCategory'));
    });
  },

  beforeDestroy() {
    if (this.dataTable) {
      this.dataTable.destroy(true);
      this.dataTable = null;
    }
  },

  components: {
    PushNotificationDialog,
    EditAlertDialog,
    PatientProfileInDrawer,
    selectDropdownFilter,
  },
};
</script>

<template>
  <div class="h-drawer-max80">
    <Drawer :direction="'right'" :exist="true" ref="modalDialog">
      <div class="h-drawer-card">
        <div class="h-drawer-card-header">
          <div class="h-main-drawer-card-header align-items-center h-margin-bottom-l">
            <h3 class="h-h3 h-primary_shade_1">Assessments</h3>
            <div class="h-align-items-center">
              <button type="button" class="h-btn h-btn-accent" @click="addItem">
                <div class="h-align-items-center">
                  <i class=" h-secondary_shade_5 healenticons-plus h-padding-right-s"></i>Add entry
                </div>
              </button>
              <button
                type="button"
                class="close h-margin-left-m"
                aria-hidden="true"
                @click="close"
                :disabled="loading"
              >
                <img src="/assets/images/Collapse-drawer.svg" alt="" />
              </button>
            </div>
          </div>
        </div>
        <div class="h-drawer-card-body h-padding-0">
          <div v-if="!loading">
            <div class="h1-table  h-margin-left-l h-margin-right-l">
              <table class="table dataTable h-noborder-input h-input-w-100 h-pt-table h-asses">
                <thead class="thead-light">
                  <tr>
                    <th style="width: 15%;" class=" text-nowrap">
                      Start date
                    </th>
                    <th style="width: 5%;" class=" text-nowrap">
                      <div class="h-d-flex h-align-items-center">
                        <span>Is after</span>
                        <i
                          class="
                          h-font20 h-line-height-1
                          h-bold h-accent healenticons-with-alerts
                          h-cursor-pointer h-padding-left-xs"
                          data-tooltip="Post sergery (post operation)"
                        ></i>
                      </div>
                    </th>
                    <th style="width: 15%" class=" text-nowrap text-right">
                      Days before/after
                    </th>
                    <th style="width: 40%;">Name</th>
                    <th style="width: 15%;" class="text-nowrap text-right">
                      Repeat every X days
                    </th>
                    <th style="width: 5%;"></th>
                  </tr>
                </thead>

                <tbody v-for="item of items" :key="item.KEY">
                  <Row
                    :item="item"
                    ref="tableRow"
                    @remove="removeItem"
                    @update-assessment="handleAssessmentUpdate"
                  />
                </tbody>

                <tbody v-if="items.length === 0">
                  <tr>
                    <td colspan="1000" class="text-center">No entries</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <Loader v-else />
        </div>

        <div class="h-drawer-card-footer">
          <button type="button" class="h-btn h-btn-accent" @click="save" :disabled="loading">
            Save
          </button>

          <button
            type="button"
            class="h-btn h-btn-dangerous h-margin-left-s"
            @click="close"
            :disabled="loading"
          >
            Close
          </button>
        </div>
      </div>
    </Drawer>
  </div>
</template>

<script>
import Joi from 'joi';
import Loader from '@/components/Loader';
import Row from './Row';

const schema = Joi.array().items(
  Joi.object({
    id: Joi.number()
      .integer()
      .optional(),
    assesment_id: Joi.number()
      .integer()
      .required(),
    post_op: Joi.bool().required(),
    repeat_every_x_days: Joi.number()
      .integer()
      .min(0)
      .required(),
    start_date_type: Joi.any().allow(
      'START_TYPE_PROTOCOL',
      'START_TYPE_CLIENT_PLAN',
      'START_TYPE_SURGERY_DATE',
    ),
  }).unknown(),
);

export default {
  name: 'EditClientCPAssessment',

  components: {
    Loader,
    Row,
  },

  props: {
    client: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      loading: false,
      items: [],
      height: 800,
    };
  },

  methods: {
    generateKey() {
      return this.$uuid.v4();
    },

    addItem() {
      this.items.push({
        KEY: this.generateKey(),
        post_op: false,
        assesment_id: null,
      });
    },

    removeItem(item) {
      this.items.splice(this.items.indexOf(item), 1);
      this.$forceUpdate();
    },

    show(items) {
      this.$refs.modalDialog.open();
      this.$refs.modalDialog.active = true;
      this.items = JSON.parse(JSON.stringify(items));
      this.items.forEach((item) => {
        item.KEY = this.generateKey();
      });
      this.loading = false;
    },
    handleAssessmentUpdate({ key, assessmentId }) {
      const itemIndex = this.items.findIndex(item => item.KEY === key);
      if (itemIndex !== -1) {
        this.items[itemIndex].assesment_id = assessmentId;
        this.items = [...this.items];
      }
    },
    async save() {
      console.log('Items before saving:', this.items);

      this.items.forEach((item) => {
        if (item.assesment_id === null) {
          $.notify('Please select an assessment for all items.', {
            position: 'top center',
          });
        }
      });

      const { error } = schema.validate(this.items);
      if (error) {
        console.error('Validation error:', error);

        $.notify(error.toString(), { position: 'top center', className: 'error' });
        return;
      }

      this.loading = true;
      try {
        await this.$api.updateClientCPAssessments(this.client.id, { items: this.items });
        this.loading = false;
        $.notify('Success', { position: 'top center', className: 'success' });
        this.$emit('updated');
        this.close(true);
      } catch (e) {
        this.loading = false;
        $.notify('Error occurs while updating.', { position: 'top center', className: 'error' });
      }
    },

    close() {
      this.$refs.modalDialog.close();
      this.$refs.modalDialog.active = false;
    },
  },

  computed: {
    myStyles() {
      return {
        height: `${this.height}px`,
      };
    },
  },
};
</script>

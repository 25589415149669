<template>
  <div>
    <div class="h-padding-left-l h-padding-right-l"><PatientReportForm /></div>
    <div class="h-margin-top-l"><PendingReports /></div>
  </div>
</template>


<style lang="stylus" scoped>
.input-row {
  margin-top: 1em;
}
</style>


<script>
import PatientReportForm from './PatientReportForm';
import PendingReports from './PendingReports';

export default {
  data() {
    return {};
  },

  components: {
    PatientReportForm,
    PendingReports,
  },
};
</script>

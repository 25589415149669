<template>
  <div>
    <div v-if="!loading">
      <div v-if="careProtocolChoices.length !== 0" class="row filters-wrapper d-print-none">
        <div class="col-md-6 col-sm-12">
          <label class="w-100">
            Care protocol:
            <InputField
              type="select"
              :choices="careProtocolChoices"
              v-model="selectedCareProtocolId"
            />
          </label>
        </div>
      </div>

      <div v-if="selectedCareProtocol" class="selectedCareProtocol-wrapper globalTopMargin">
        <h4 class="text-center">
          <span class="text-muted">Protocol Compliance:</span>
          {{ selectedCareProtocol.name }}
        </h4>
        <table
          class="table compliance-table mb-0"
          :class="{'table-hover': selectedCareProtocol.items.length !== 0}"
        >
          <thead class="thead-light">
            <tr>
              <th class="w-1">
                #
              </th>
              <th>
                Title
              </th>
              <th v-for="status of statusList" :key="status" class="text-right">
                {{ status }}
              </th>
              <th class="text-right">
                Compliance
              </th>
            </tr>
          </thead>

          <tbody v-if="selectedCareProtocol.items.length !== 0">
            <tr v-for="(item, index) of selectedCareProtocol.items" :key="item.id">
              <td>
                {{ index+1 }}
              </td>
              <td>
                {{ item.name }}
              </td>
              <td v-for="status of statusList" :key="status" class="text-right">
                {{ getStatusPercentageText(item, status) }}
              </td>
              <td :class="getComplianceClass(getCompliance(item)) + ' text-right'">
                {{ getCompliance(item) !== null? getCompliance(item).toFixed(1) + '%' : '-' }}
              </td>
            </tr>
          </tbody>

          <tbody v-else>
            <tr>
              <td class="text-center" colspan="100">
                No entries found
              </td>
            </tr>
          </tbody>
        </table>

        <hr class="mt-0" />

        <p class="mt-2">
          <strong>Note:</strong>
          Only client surgery checklist that should have been done by this time are counted.
        </p>

        <div
          v-if="selectedCareProtocol.items.length !== 0"
          class="text-center mt-4"
        >
          <button
            type="button"
            class="btn btn-primary print-button d-print-none"
            @click="print"
          >
            Print
          </button>
        </div>
      </div>

      <div v-if="!selectedCareProtocol" class="text-center text-muted globalTopMargin">
        <hr />
          Care protocol not selected.
        <hr />
      </div>

      <div v-if="careProtocolChoices.length === 0" class="text-center text-muted globalTopMargin">
        <hr />
          No protocols found
        <hr />
      </div>

    </div>

    <div v-else class="globalTopMargin">
      <Loader />
    </div>
  </div>
</template>


<style lang="stylus" scoped>
.globalTopMargin
  margin-top 3em

@media print
  .globalTopMargin
    margin-top 0 !important

.selectedCareProtocol-wrapper
  margin-bottom 3em

  table, hr
    margin 0
    margin-left auto
    margin-right auto

.print-button
  width 200px
</style>


<script>
import Loader from '@/components/Loader';
import TimeParts from '@/components/time_parts/TimeParts';
import InputField from '@/components/InputField';


export default {
  props: ['filterValues'],

  data() {
    return {
      loading: true,
      data: null,
      selectedCareProtocolId: this.filterValues.selectedCareProtocolId,
    };
  },

  watch: {
    selectedCareProtocolId() {
      this.$emit(
        'updateFilterValues',
        {
          selectedCareProtocolId: parseInt(this.selectedCareProtocolId, 10),
        },
      );
    },
  },

  computed: {
    careProtocolChoices() {
      let result = [];
      if (this.data) {
        result = this.data.care_protocol_list.map((careProtocol) => {
          return [careProtocol.id, careProtocol.name];
        });
      }
      result.unshift([null, '--- Select care protocol ---']);
      return result;
    },

    selectedCareProtocol() {
      if (!this.data) {
        return null;
      }
      return this.data.care_protocol_list.find((careProtocol) => {
        return careProtocol.id === parseInt(this.selectedCareProtocolId, 10);
      });
    },

    statusList() {
      if (!this.data) {
        return [];
      }
      return this.data.status_list;
    },
  },

  methods: {
    getComplianceClass(compliance) {
      if (compliance === null) {
        return '';
      }
      if (compliance < 50) {
        return 'text-danger';
      }
      if (compliance < 75) {
        return 'text-warning';
      }

      return 'text-success';
    },

    getStatusPercentageText(item, status) {
      if (item.status === null) {
        return '-';
      }
      return `${(100 * item.status[status] / item.status.All).toFixed(1)}%`;
    },

    getCompliance(item) {
      if (item.status === null) {
        return null;
      }
      return 100 * item.status.Done / item.status.All;
    },

    print() {
      print();
    },
  },

  async mounted() {
    this.data = await this.$api.complianceProtocol();
    this.data.care_protocol_list.forEach((careProtocol) => {
      careProtocol.items.forEach((item) => {
        item.start_seconds_before = new TimeParts(item.start_seconds_before);
        item.due_seconds_before = new TimeParts(item.due_seconds_before);
      });
    });
    this.loading = false;
  },

  components: {
    Loader,
    InputField,
  },
};
</script>

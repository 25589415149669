<template>
  <div class="h-col-desktop-12 h-col-mobaile-12">
    <div
      v-if="client.medication_use_template && client.medication_use_template.length != 0"
      class="h1-table overflow-auto"
    >
      <table class="table dataTable h-noborder-input">
        <thead class="thead-light">
          <tr>
            <th class="text-center text-nowrap">#</th>
            <th class="text-center text-nowrap">Medication type</th>
            <th class="text-center text-nowrap">Name</th>
            <th class="text-center text-nowrap">Times a term</th>
            <th class="text-center text-nowrap">Term</th>
            <th class="text-center text-nowrap">Amount</th>
            <th class="text-center text-nowrap">Amount unit</th>
            <th class="text-center">Comment</th>
            <th class="text-center">Action</th>
          </tr>
        </thead>

        <tbody>
          <MedicationUseRow
            v-for="(medicationUse, index) of client.medication_use_template"
            :key="medicationUse.uuid"
            :index="index"
            :medicationUse="medicationUse"
            @remove="removeRow"
            :serverErrors="errors[medicationUse.uuid]"
          />
        </tbody>
      </table>
    </div>
    <div v-else class="h-text-center h-h5 h-secondary_shade_1 h-bold">
      No entries.
    </div>

    <div class="h-text-center h-margin-top-l">
      <button type="button" class="h-btn h-btn-accent h-btn-with-icon" @click="newRow">
        <i
          style="line-height: 1; font-size: 10rem;"
          class=" h-secondary_shade_5 healenticons-plus h-padding-right-s"
        ></i
        >Add medication
      </button>
    </div>
  </div>
</template>

<script>
import Loader from '@/components/Loader';
import MedicationUseRow from './MedicationUseRow';

export default {
  methods: {
    newRow() {
      this.client.medication_use_template.push({
        uuid: this.$uuid.v4(),
      });
      console.log(this.client.medication_use_template);

      this.$forceUpdate();
    },

    removeRow(row) {
      this.client.medication_use_template.splice(
        this.client.medication_use_template.indexOf(row),
        1,
      );
      this.$forceUpdate();
    },
  },

  async mounted() {
    this.client.medication_use_template.forEach(medicationUse => ({
      ...medicationUse,
      uuid: medicationUse.uuid || this.$uuid.v4(),
    }));
  },

  components: {
    MedicationUseRow,
  },

  props: {
    errors: {
      type: Object,
      default: () => ({}),
    },
    client: {
      type: Object,
      required: true,
    },
  },
};
</script>

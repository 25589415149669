<template>
  <div class="h-patient-height">
    <!-- Page-Title -->
    <div class="h-add-patient h-padding-top-xl h-mobile-padding-left-l h-mobile-padding-right-l">
      <div class="h-col-desktop-8 h-col-screen-m h-col-mobile-12 h-margin-auto">
        <div>
          <div :class="{ 'd-none': loading }">
            <div
              v-if="formWasSaved === false"
              class="alert alert-danger border-0 alert-dismissible mb-4"
              role="alert">
              Failed to save data. {{ errMsg }}
            </div>
            <div
              v-if="formWasSaved === true"
              class="alert alert-success border-0 alert-dismissible mb-4"
              role="alert">
              Physical Therapy data is saved.
            </div>

            <form class="h-form-btn">
              <div class="h-row">
                <div class="h-input-w49 h-desktop-margin-right-2
                  h-col-mobile-12 h-margin-bottom-l">
                  <label class="h-label"> Category:</label>
                  <InputField
                    id="input_category"
                    type="select"
                    :choices="category"
                    v-model="physicalTP.category"
                    :error="errors.category"
                    @input="() => {
            errors.category = null;
          }
            " />
                </div>
                <div
                  class="h-input-w49 h-col-mobile-12 h-margin-bottom-l">
                  <label class="h-label"> Title: </label>
                  <InputField
                    id="input_title"
                    type="text"
                    v-model="physicalTP.title"
                    :error="errors.title"
                    @input="() => {
            errors.title = null;
          }
            " />
                </div>
                <div
                  class="h-input-w49 h-desktop-margin-right-2
                  h-col-mobile-12 h-margin-bottom-l">
                  <label class="h-label"> Description:</label>
                  <InputField
                    id="input_description"
                    type="textarea"
                    v-model="physicalTP.description"
                    :error="errors.description"
                    :paddingRight="true"
                    @input="() => {
            errors.description = null;
          }
            " />
                </div>
                <div class="h-input-w49 h-col-mobile-12 h-margin-bottom-l">
                  <label class="h-label"> Instructions:</label>
                  <InputField
                    id="input_instructions"
                    type="textarea"
                    v-model="physicalTP.instructions"
                    :error="errors.instructions"
                    :paddingRight="true"
                    @input="() => {
            errors.instructions = null;
          }
            " />
                </div>

                <div
                  class="h-input-w49 h-desktop-margin-right-2
                  h-col-mobile-12 h-margin-bottom-l">
                  <label class="h-label"> Warning Text: </label>
                  <InputField
                    id="input_warning_text"
                    type="textarea"
                    v-model="physicalTP.warning_text"
                    :error="errors.warning_text"
                    :paddingRight="true"
                    @input="() => {
            errors.warning_text = null;
          }
            " />
                </div>
                <div class="h-input-w49 h-col-mobile-12 h-margin-bottom-l">
                  <label class="h-label"> Comments:</label>
                  <InputField
                    id="input_comments"
                    type="textarea"
                    v-model="physicalTP.comments"
                    :error="errors.comments"
                    :paddingRight="true"
                    @input="() => {
            errors.comments = null;
          }
            " />
                </div>

                <div
                  class="h-input-w49 h-desktop-margin-right-2
                  h-col-mobile-12 h-margin-bottom-l">
                  <label class="h-label"> Sets:</label>
                  <InputField
                    id="input_sets"
                    type="number"
                    v-model="physicalTP.sets"
                    :min="min"
                    :error="errors.sets"
                    @input="() => {
            errors.sets = null;
          }
            " />
                </div>
                <div class="h-input-w49 h-col-mobile-12 h-margin-bottom-l">
                  <label class="h-label"> Repetition:</label>
                  <InputField
                    id="input_repetition"
                    type="number"
                    v-model="physicalTP.repetition"
                    :min="min"
                    :error="errors.repetition"
                    @input="() => {
            errors.repetition = null;
          }
            " />
                </div>

                <div
                  class="h-input-w49 h-col-mobile-12
                  h-desktop-margin-right-2 h-margin-bottom-l">
                  <label class="h-label"> Hold:</label>
                  <InputField
                    id="input_hold"
                    type="number"
                    :min="min"
                    v-model="physicalTP.hold"
                    :error="errors.hold"
                    @input="() => {
            errors.hold = null;
          }
            " />
                </div>
                <div class="h-input-w49 h-col-mobile-12 h-margin-bottom-l">
                  <label class="h-label"> Side:</label>
                  <InputField
                    id="input_side"
                    type="text"
                    v-model="physicalTP.side"
                    :error="errors.side"
                    @input="() => {
            errors.side = null;
          }
            " />
                </div>

                <div
                  class="h-input-w49 h-col-mobile-12
                  h-desktop-margin-right-2 h-margin-bottom-l">
                  <label class="h-label"> Times per Day:</label>
                  <InputField
                    id="times_per_day"
                    type="number"
                    :min="min"
                    v-model="physicalTP.times_per_day"
                    :error="errors.times_per_day"
                    @input="() => {
            errors.times_per_day = null;
          }
            " />
                </div>
                <div class="h-input-w49 h-col-mobile-12 h-margin-bottom-l">
                  <label class="h-label"> Every x Days:</label>
                  <InputField
                    id="every_x_days"
                    type="number"
                    :min="min"
                    v-model="physicalTP.every_x_days"
                    :error="errors.every_x_days"
                    @input="() => {
            errors.every_x_days = null;
          }
            " />
                </div>

                <div
                  class="h-input-w49 h-col-mobile-12
                  h-desktop-margin-right-2 h-margin-bottom-l">
                  <label class="h-label"> Start Days After:</label>
                  <InputField
                    id="start_days_after"
                    type="number"
                    :min="min"
                    v-model="physicalTP.start_days_after"
                    :error="errors.start_days_after"
                    @input="() => {
            errors.start_days_after = null;
          }
            " />
                </div>
                <div class="h-input-w49 h-col-mobile-12 h-margin-bottom-l">
                  <label class="h-label"> End Days After:</label>
                  <InputField
                    id="end_days_after"
                    type="number"
                    :min="min"
                    v-model="physicalTP.end_days_after"
                    :error="errors.end_days_after"
                    @input="() => {
            errors.end_days_after = null;
          }
            " />
                </div>

                <div
                  class="h-input-w49 h-col-mobile-12
                  h-desktop-margin-right-2 h-margin-bottom-l">
                  <label class="h-label"> Images:</label>
                  <InputField
                    id="image_thumbnail"
                    type="text"
                    v-model="physicalTP.images.thumbnail"
                    placeholder="Input thumbnail image link" />
                </div>
                <div class="h-input-w49 h-col-mobile-12 h-margin-bottom-l">
                  <label class="h-label"> Videos:</label>
                  <InputField
                    id="video_loop"
                    class="h-margin-bottom-m"
                    type="text"
                    v-model="physicalTP.videos.loop"
                    placeholder="Input Loop video link" />
                  <InputField
                    id="video_intro"
                    type="text"
                    v-model="physicalTP.videos.intro"
                    placeholder="Input Intro video link" />
                </div>
              </div>

              <div class="step-footer h-step-fix-footer">
                <div
                  class="h-col-desktop-8 h-col-screen-m
                  h-col-mobile-12 h-margin-auto h-btn-container justify-end">
                  <div class="h-text-right">
                    <button
                      type="button"
                      class="h-btn h-btn-dangerous h-margin-right-s"
                      @click="cancel">
                      Cancel
                    </button>
                    <button type="button" class="h-btn h-btn-accent" @click="submit">
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>

          <div v-if="loading" class="card-body">
            <Loader />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="stylus" scoped>
.justify-end {
  display: flex;
  justify-content flex-end
}
</style>

<script>

import { useVuelidate } from '@vuelidate/core';
import { maxLength, required } from '@vuelidate/validators';
import Loader from '@/components/Loader';
import InputField from '@/components/InputField';

export default {
  name: 'EditPhysicalTherapy',

  data() {
    return {
      loading: false,
      physicalTP: {
        videos: {},
        images: {},
      },
      errors: {
        title: null,
        category: null,
        description: null,
        instructions: null,
        warning_text: null,
        comments: null,
        sets: null,
        repetition: null,
        hold: null,
        side: null,
      },
      formWasSaved: null,
      errMsg: null,
      min: 0,
      extraClass: { 'padding-h-0': true },
      category: [
        { value: null, title: '--- select ---' },
        { value: 'Neck', title: 'Neck' },
        { value: 'Shoulder', title: 'Shoulder' },
        { value: 'Hip/Pelvic', title: 'Hip/Pelvic' },
        { value: 'Fibromyalgia', title: 'Fibromyalgia' },
        { value: 'Rheumatoid Arthritis', title: 'Rheumatoid Arthritis' },
        { value: 'Hand/Wrist', title: 'Hand/Wrist' },
        { value: 'Elbow/Arm', title: 'Elbow/Arm' },
        { value: 'Upper/Mid Back', title: 'Upper/Mid Back' },
        { value: 'General Back', title: 'General Back' },
        { value: 'Foot/Ankle', title: 'Foot/Ankle' },
        { value: 'Migraines', title: 'Migraines' },
        { value: 'Low Back/Buttocks', title: 'Low Back/Buttocks' },
        { value: 'Knee/Leg', title: 'Knee/Leg' },
        { value: 'Lungs', title: 'Lungs' },
        { value: 'preop', title: 'preop' },
        { value: 'spo2', title: 'spo2' },
        { value: 'stroke', title: 'stroke' },
      ],
    };
  },

  setup: () => ({ v$: useVuelidate() }),

  validations() {
    return {
      physicalTP: {
        title: { required, maxLength: maxLength(200) },
        category: { required, maxLength: maxLength(200) },
        description: { required },
        instructions: { required },
        warning_text: { required },
        comments: { required },
        sets: { required },
        repetition: { required },
        hold: { required },
        side: { required, maxLength: maxLength(200) },
      },
    };
  },

  methods: {
    initData() {
      this.physicalTP = { videos: {}, images: {} };
    },

    updateImages(itemList) {
      this.physicalTP.images = itemList.reduce((acc, cur) => {
        acc[cur.key] = cur.value;
        return acc;
      }, {});
    },

    updateVideos(itemList) {
      this.physicalTP.videos = itemList.reduce((acc, cur) => {
        acc[cur.key] = cur.value;
        return acc;
      }, {});
    },

    async submit() {
      const result = await this.v$.$validate();
      if (!result) {
        this.v$.$errors.forEach((e) => {
          this.errors[e.$property] = e.$message;
        });
        return;
      }

      this.loading = true;
      this.errors = {};
      this.formWasSaved = null;

      const payload = {
        ...this.physicalTP,
        images: JSON.stringify(this.physicalTP.images || {}),
        videos: JSON.stringify(this.physicalTP.videos || {}),
      };
      try {
        if (this.physicalTP.id) {
          await this.$api.updatePhysicalPT(this.physicalTP.id, payload);
        } else {
          await this.$api.createPhysicalPT(payload);
        }
        this.formWasSaved = true;
        this.cancel();
      } catch (e) {
        this.errors = e.validate;
        this.errMsg = e.message;
        this.formWasSaved = false;
        this.loading = false;
      }
    },

    cancel() {
      this.$router.push({ name: 'PhysicalTherapy' });
    },
  },

  async mounted() {
    this.loading = true;
    if (this.$route.params.id) {
      try {
        this.physicalTP = await this.$api.getPhysicalPT(this.$route.params.id);
      } catch (e) {
        this.cancel();
      }
    } else {
      this.initData();
    }

    this.loading = false;
  },

  components: {
    Loader,
    InputField,
  },
};
</script>

<template>
  <div>
    <div class="h-margin-l h-padding-top-l h-bg-w">
      <div class="h1-table">
        <table ref="table" class="table dataTable fullTable">
          <thead class="thead-light">
            <tr>
              <th width="20%">Name</th>
              <th width="20%">E-Mail</th>
              <th width="20%">Facility</th>
              <th width="5%">Activated</th>
              <th width="5%">Archived</th>
              <th width="5%">Messaging</th>
              <th width="5%">Action</th>
            </tr>
          </thead>
        </table>
      </div>
    </div>

    <ResendActivationDialog ref="ResendActivationDialog" />
  </div>
</template>

<script>
import strUtils from '@/utils/str_utils';
import settings from '@/settings.js';
import ResendActivationDialog from './components/ResendActivationDialog';

export default {
  name: 'AllMembers',

  methods: {
    createNewMemberInvite() {
      this.$router.push({ name: 'Invite' });
    },
    addMember() {
      this.$router.push({ name: 'Invite' });
    },
  },

  beforeDestroy() {
    if (this.dataTable) {
      this.dataTable.destroy(true);
      this.dataTable = null;
    }
  },

  mounted() {
    window.execAfterInitMenuInterface(() => {
      window.initActiveMenu('left-navigation-link>settings>staff>all-members');
    });

    const that = this;
    this.dataTable = $(this.$refs.table).DataTable({
      processing: true,
      serverSide: true,
      order: [[0, 'asc']],
      ajax: (data, callback, tableSettings) => {
        this.$api.getStaffMembers(data).then((response) => {
          tableSettings.json = response;
          callback(response);
        });
      },
      dom:
        '<"h-row h-space-between h-align-item-center"<"h-show-bt h-margin-left-l header-title"><"h-d-flex h-table-bt" fB>>' +
        '<""<""tr>><"h-row h-space-between h-align-item-center h-padding-bottom-l"lip>',
      buttons: {
        buttons: [
          {
            text: 'Add New Staff Member',
            action(e, dt, node, config) {
              that.addMember();
            },
            className: 'h-btn h-btn-accent addnewpatioint AddNewStaffMember',
          },
          {
            extend: 'collection',
            className: 'ExportIcon',
            buttons: ['copy', 'excel', 'csv', 'print'],
          },
        ],
        dom: {
          button: {
            className: 'h-btn',
          },
        },
      },
      columns: [
        {
          name: 'name',
          render: (data, type, row, meta) => {
            const rowIndex = meta.settings.json.data.indexOf(row);
            let photoLink = meta.settings.json.metadata[rowIndex].avatar_url;
            if (!photoLink) {
              photoLink = '/assets/images/users/patient-pro.png';
            }
            const path = this.$router.resolve({
              name: 'EditStaffMember',
              params: {
                doctorId: meta.settings.json.metadata[rowIndex].id,
              },
            });
            const name = data
              ? that.$strUtils.escapeHtml(data)
              : '<span class="text-muted">&lt;NOT_SET&gt;</span>';
            return `
              <a href="${path.href}" class="router-link">
                <img
                  src='${photoLink}'
                  alt='${that.$strUtils.escapeHtml(data)}'
                  class='thumb-sm rounded-circle mr-2'
                >
                ${name}
              </a>
            `;
          },
        },
        {
          name: 'email',
          render: data => (data ? that.$strUtils.escapeHtml(data) : null),
        },
        {
          name: 'hospitals',
          searchable: true,
          class: 'h-hospitals white-space-normal',
          render: data => (data ? that.$strUtils.escapeHtml(data) : null),
        },
        {
          name: 'nurse_invitation_code',
          searchable: false,
          orderable: true,
          render: (data) => {
            if (data) {
              return '<span class="h-accent">Yes</span>';
            }
            return '<span class="h-dangerous">No</span>';
          },
        },
        {
          name: 'is_archived',
          searchable: false,
          orderable: true,
          render: (data) => {
            if (data) {
              return '<span class="h-accent">Yes</span>';
            }
            return '<span class="h-dangerous">No</span>';
          },
        },
        {
          name: 'allow_communication_messaging',
          searchable: false,
          orderable: true,
          render: (data) => {
            if (data) {
              return '<span class="h-accent">Yes</span>';
            }
            return '<span class="h-dangerous">No</span>';
          },
        },
        {
          name: 'action',
          searchable: false,
          orderable: false,
          render: (data, type, row, meta) => {
            const rowIndex = meta.settings.json.data.indexOf(row);
            const activated = meta.settings.json.metadata[rowIndex].activated;
            if (activated) {
              return '';
            }
            return `
              <div class="text-right">
                <a
                  href="javascript:void(0);"
                  class="mr-2 resend-invite-letter"
                  data-index="${rowIndex}"
                  data-id="${meta.settings.json.metadata[rowIndex].id}"
                >
                  <i class="fas fa-envelope-open-text text-info font-16"
                  title="Resend invitation e-mail"></i>
                </a>
              </div>
            `;
          },
        },
      ],
    });

    $(this.$refs.table).on('click', '.resend-invite-letter', (e) => {
      const id = parseInt(
        $(e.target)
          .closest('a')
          .attr('data-id'),
        10,
      );
      const index = parseInt(
        $(e.target)
          .closest('a')
          .attr('data-index'),
        10,
      );
      const data = this.dataTable.data().toArray();
      const row = data[index];
      that.$refs.ResendActivationDialog.show({
        id,
        name: row[0],
        email: row[1],
      });
    });
    if (settings.getInviteRoles().length === 0) {
      $('.AddNewStaffMember').css('display', 'none');
    }
    $(document).ready(() => {
      const sel = $('.h-hospitals').text();
      $('.h-hospitals').html(sel.split(',').join('</br>'));
    });
  },

  components: {
    ResendActivationDialog,
  },
};
</script>

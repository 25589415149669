<template>
  <tr>
    <th class="w-1 text-nowrap text-right">
      <!-- no update on re-render otherwise -->
      <span class="d-none">{{ getTherapyPtFieldValue() }}</span>

      <strong v-if="!therapyPt || !careProtocolMode" class="col-form-label">
        {{ label }}
      </strong>
      <label v-else class="col-form-label" :for="inputId"> {{ label }}: </label>
    </th>

    <td v-if="!therapyPt || !careProtocolMode" class="text-left">
      <span v-if="!value" class="text-muted"> &lt;No value&gt; </span>
      <a v-else :href="value" target="_blank">{{ value }}</a>
    </td>

    <td v-else>
      <div class="h-table-input-flex h-space-between">
        <input type="text" class="h-input" :id="inputId" v-model="value" />
        <div class="">
          <a class="h-accent" :href="value" target="_blank">View</a>
        </div>
      </div>
    </td>
  </tr>
</template>

<script>
export default {
  props: ['label', 'careProtocolMode', 'therapyPt', 'getTherapyPtFieldValue'],

  data() {
    return {
      inputId: this.$uuid.v4(),
      value: this.getTherapyPtFieldValue(),
    };
  },

  updated() {
    if (this.value !== this.getTherapyPtFieldValue()) {
      this.value = this.getTherapyPtFieldValue();
    }
  },

  watch: {
    value() {
      this.$emit('input', this.value);
    },
  },
};
</script>

<template>
  <div class="demo-app h-margin-left-l h-margin-right-l">
    <div id="calendar" class="demo-app-main">
      <div
        class="h-wrapper h-padding-top-l h-margin-top-l
      h-padding-right-l h-padding-left-l h-padding-bottom-l"
      >
        <div class="h-row">
          <FullCalendar ref="fullCalendar" :options="calendarOptions" class="h-fullcalender">
            <template v-slot:eventContent="arg">
              <div v-if="arg.event.extendedProps['clientId']">
                <b>{{ arg.timeText }}</b>
                <a
                  href="#"
                  class="OpenProfileInDrawer"
                  :data-id="arg.event.extendedProps['clientId']"
                  @click.prevent.stop="openClientProfile(arg.event.extendedProps['clientId'])"
                >
                  {{ arg.event.title }}
                </a>
              </div>
              <div v-else>
                <span>{{ arg.event.title }}</span>
              </div>
            </template>
          </FullCalendar>
        </div>
      </div>
    </div>
    <NewAppointmentDialog
      ref="newAppointmentDialog"
      @appointmentUpdated="reloadDataTable"
      @appointmentDeleted="reloadDataTable"
    />
    <PatientProfileInDrawer ref="PatientProfileInDrawer" :key="client.id" />
  </div>
</template>

<script>
import FullCalendar from '@fullcalendar/vue';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import moment from 'moment-timezone';
import NewAppointmentDialog from '../appointments_support/NewAppointmentDialog';
import PatientProfileInDrawer from '../../views/surgery_navigator/all_patients/components/PatientProfileInDrawer';
import eventBus from '../../event-bus';
import eventKeeper from '../../eventKeeper';

export default {
  components: {
    NewAppointmentDialog,
    FullCalendar, // make the <FullCalendar> tag available
    PatientProfileInDrawer,
  },

  data() {
    const dateStart = moment();
    const dateEnd = moment(dateStart);
    const dates = [];
    return {
      client: {},
      calendarOptions: {
        plugins: [
          dayGridPlugin,
          timeGridPlugin,
          interactionPlugin, // needed for dateClick
        ],
        headerToolbar: {
          left: 'prev,next today',
          center: 'title',
          right: 'dayGridMonth,timeGridWeek,timeGridDay',
        },
        initialView: 'dayGridMonth',
        allDaySlot: false,
        editable: true,
        selectable: true,
        selectMirror: true,
        dayMaxEvents: true,
        weekends: true,
        eventStartEditable: false,
        select: this.handleDateSelect,
        eventClick: this.handleEventClick,
        eventsSet: this.handleEvents,
        datesSet: this.handleDateRangeChanged,
        eventDisplay: 'block',
        /* you can update a remote database when these fire:
        eventAdd:
        eventChange:
        eventRemove:
        */
      },
      currentEvents: [],
      currentDateInfo: [],
    };
  },
  methods: {
    async openClientProfile(clientId) {
      try {
        const id = clientId;
        await this.$refs.PatientProfileInDrawer.show(id);
      } catch (error) {
        console.error(error);
      }
    },

    reloadDataTable() {
      this.handleDateRangeChanged(this.currentDateInfo);
    },
    triggerPatientDetail() {
      const patientsMenu = document.getElementById('PatientsDropDownLink');
      patientsMenu.click();
    },
    handleDateRangeChanged(dateInfo) {
      this.currentDateInfo = dateInfo;
      const startDate = dateInfo.start;
      const endDate = dateInfo.end;

      const data = {
        draw: 5,
        columns: [
          {
            data: 0,
            name: 'client__name',
            searchable: true,
            orderable: true,
            search: { value: '', regex: false },
          },
          {
            data: 1,
            name: 'client__email',
            searchable: true,
            orderable: true,
            search: { value: '', regex: false },
          },
          {
            data: 2,
            name: 'start_date_time_utc',
            searchable: false,
            orderable: true,
            search: { value: '', regex: false },
          },
          {
            data: 3,
            name: 'duration_minutes',
            searchable: false,
            orderable: true,
            search: { value: '', regex: false },
          },
          {
            data: 4,
            name: 'video_client_status',
            searchable: false,
            orderable: false,
            search: { value: '', regex: false },
          },
          {
            data: 5,
            name: 'action',
            searchable: false,
            orderable: false,
            search: { value: '', regex: false },
          },
          {
            data: 6,
            name: 'is_completed',
            searchable: false,
            orderable: false,
            search: { value: '', regex: false },
          },
        ],
        order: [{ column: 0, dir: 'asc' }],
        start: 0,
        length: 150,
        search: { value: '', regex: false },
        datetimeStart: '2021-06-24 07:00:00',
        datetimeEnd: '2021-06-25 06:59:59',
      };
      const payload = JSON.parse(JSON.stringify(data));

      payload.datetimeStart = this.$dateUtils.toUtcDatetime(
        this.$dateUtils.moveTimeToDateStart(startDate),
      );
      payload.datetimeEnd = this.$dateUtils.toUtcDatetime(
        this.$dateUtils.moveTimeToDateEnd(endDate),
      );

      payload.filterOutAutomaticAppointments = true;

      this.$api.getMeetings(payload).then((response) => {
        const calendarApi = dateInfo.view.calendar;
        calendarApi.removeAllEvents();

        const currentDateTime = moment();
        response.data.forEach((entry, index) => {
          entry.pastOrPresent = moment(this.$dateUtils.toLocalDatetime(entry[2])).isAfter(
            currentDateTime,
          );
          calendarApi.addEvent({
            id: response.metadata[index].id,
            title: entry[0],
            start: this.$dateUtils.toLocalDatetime(entry[2]),
            end: this.$dateUtils.toLocalDatetime(entry[2]),
            start_date: moment(this.$dateUtils.toLocalDatetime(entry[2])).format('YYYY-MM-DD'),
            start_time: moment(this.$dateUtils.toLocalDatetime(entry[2])).format('HH:mm'),
            minutes: response.metadata[index].duration_minutes,
            clientId: response.metadata[index].client_id,
            is_completed: entry[6],
            is_past_or_present: entry.pastOrPresent,
            // eslint-disable-next-line no-nested-ternary
            color: entry.pastOrPresent ? '#2b5adc' : entry[6] ? '#1b998b' : '#EE4266 ',
            display: 'block',
            hospitalId: response.metadata[index].hospitalId,
            hospitalName: response.metadata[index].hospitalName,
          });
        });
      });
    },
    handleWeekendsToggle() {
      this.calendarOptions.weekends = !this.calendarOptions.weekends; // update a property
    },
    handleDateSelect(selectInfo) {
      const dateStart = moment(selectInfo.start).format('YYYY-MM-DD');
      const timeStart = moment(selectInfo.start).format('HH:mm');

      this.$refs.newAppointmentDialog.show({
        mode: 'new',
        start_date: dateStart,
        start_time: timeStart,
        is_completed: false,
        only_for_note: false,
      });

      const calendarApi = selectInfo.view.calendar;
      calendarApi.refetchEvents();
    },

    handleEventClick(clickInfo) {
      const targetClassList = clickInfo.el.classList;

      if (targetClassList.contains('OpenProfileInDrawer')) {
        // Clicked on client name element
        const clientId = clickInfo.event.extendedProps.clientId;
        this.openClientProfile(clientId);
      } else {
        // Clicked on appointment element
        const meetingId = clickInfo.event.id;

        if (meetingId === undefined || meetingId === null || meetingId === 'null') {
          $.notify('Selected entry is not associated with an appointment', {
            position: 'top center',
            className: 'error',
          });
          return;
        }

        this.$refs.newAppointmentDialog.show({
          id: meetingId,
          start_date: clickInfo.event.extendedProps.start_date,
          start_time: clickInfo.event.extendedProps.start_time,
          duration_minutes: clickInfo.event.extendedProps.minutes,
          is_completed: clickInfo.event.extendedProps.is_completed,
          is_past_or_present: clickInfo.event.extendedProps.is_past_or_present,
          client: {
            id: clickInfo.event.extendedProps.clientId,
            title: clickInfo.event.title,
          },
        });
      }
    },

    handleEvents(events) {
      this.currentEvents = events;
    },
  },

  async mounted() {
    const $that = this;
    eventKeeper.$on(this, 'shown.bs.tab', document, 'a[data-toggle="tab"]', (e) => {
      const calendarInfo = $that.currentDateInfo.view.calendar;
      calendarInfo.render();
    });
  },

  beforeDestroy() {
    eventBus.$offOwner(this);
    eventKeeper.$offOwner(this);
  },
};
</script>

<style scoped>
a {
  color: white;
}
</style>

<template>
  <div class="h-drawer-max80 h-no-padding">
    <SlottedMessageInDrawer ref="slottedMessage" modalSize="lg" :title="tableData.title">
      <template v-slot:modal-body>
        <div
          class="h1-table h-padding-top-l"
          v-if="riskSurveyData && riskSurveyData.submitted_on_fulldatetime"
        >
          <table class="dataTable h-noborder-input table d-table h-drawer">
            <thead>
              <tr>
                <th>Patient qualifies for</th>
                <th>Care protocol</th>
                <th class="h-text-right">Submitted on</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{{ patientQualifiesForProgram }}</td>
                <td>
                  {{
                    RPMRTMStratificationSection.careProtocol
                      ? RPMRTMStratificationSection.careProtocol
                      : 'None'
                  }}
                </td>
                <td class="h-text-right">{{ riskSurveyData.submitted_on_fulldatetime }}</td>
              </tr>
            </tbody>
          </table>

          <table class="dataTable h-noborder-input table d-table h-drawer">
            <thead>
              <tr>
                <th>QUESTIONS</th>
              </tr>
            </thead>
          </table>
          <table class="dataTable h-noborder-input table d-table h-drawer">
            <tbody>
              <tr v-for="section of RPMRTMStratificationSection.sections" :key="section.section">
                <td
                  style="width: 90%;"
                  v-for="question of section.questions"
                  :key="question.question"
                >
                  {{
                    question.options[0].groupName !== 'Q5'
                      ? question.question
                      : '6. Selected condition:'
                  }}
                </td>
                <td
                  style="width: 10%;"
                  v-for="question of section.questions"
                  :key="question.selectedAnswer"
                >
                  {{
                    question.options[0].groupName !== 'Q5'
                      ? question.selectedAnswer
                      : RPMRTMStratificationSection.selectedCondition || 'None'
                  }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </template>

      <template v-slot:modal-footer>
        <button
          type="button"
          class="h-btn h-btn-accent"
          @click="save"
          :disabled="saving"
          v-if="isRPMRTMEditInDashboardImplemented"
        >
          {{ saving ? 'Saving...' : 'Save' }}
        </button>

        <button type="button" class="h-btn h-btn-dangerous h-margin-left-s" @click="close">
          Close
        </button>
      </template>
    </SlottedMessageInDrawer>
  </div>
</template>

<style lang="css" scoped>
.h1-table table.table td,
.h1-table table.table th {
  white-space: unset;
}

.h1-table table.dataTable td,
.h1-table table.dataTable th {
  white-space: unset;
}
</style>

<script>
import settings from '@/settings.js';
import SlottedMessageInDrawer from '@/components/dialogs/SlottedMessageInDrawer';
import { getSummeryValues } from '@/components/surveys/utils';

export default {
  data() {
    return {
      saving: false,
      tableData: {
        title: '',
        assessmentTitle: '',
      },
      riskSurveyData: {},
      RPMRTMStratificationSection: [],
      RPMQuestions: {},
      RTMQuestions: {},
      patientQualifiesForProgram: '',
      isRPMRTMEditInDashboardImplemented: false,
    };
  },

  methods: {
    getAssessmentByTitle(title) {
      return this.allAssessments.filter((assessment) => {
        return assessment.assessment_title === title;
      });
    },

    async show(title, riskSurveyData) {
      this.saving = false;
      this.tableData = {
        title,
        assessmentTitle: title,
        sections: [],
        tableRows: [],
      };
      this.riskSurveyData = JSON.parse(JSON.stringify(riskSurveyData));
      console.log(this.riskSurveyData);
      const surveyAnswers = this.riskSurveyData.survey_answers;
      this.RPMRTMStratificationSection = surveyAnswers.RPMRTMStratificationSection[0];

      if (this.RPMRTMStratificationSection.qualifiesForRPM === 'Yes') this.patientQualifiesForProgram = 'RPM';
      if (this.RPMRTMStratificationSection.qualifiesForRTM === 'Yes') this.patientQualifiesForProgram = 'RTM';
      await this.$forceUpdate();
      this.$refs.slottedMessage.show();
    },

    toggleOsord(container, optionName, noneOptionName) {
      const index = container.indexOf(optionName);
      if (index === -1) {
        if (optionName === noneOptionName) {
          container.splice(0, container.length);
        } else {
          const noneIndex = container.indexOf(noneOptionName);
          if (noneIndex !== -1) {
            container.splice(noneIndex, 1);
          }
        }
        container.push(optionName);
      } else {
        container.splice(index, 1);
      }

      this.updateSummery();
    },

    updateSummery() {
      const summery = getSummeryValues(this.riskSurveyData.survey_answers);
      this.riskSurveyData.medd_value = summery.medd;
      this.riskSurveyData.osord_value = summery.osord;
    },

    reload() {
      const reloadInfo = {};
      reloadInfo.title = this.tableData.title;
      reloadInfo.assessmentTitle = this.tableData.assessmentTitle;
      this.$emit('reloadChartInfo', reloadInfo);
    },

    close() {
      this.$refs.slottedMessage.close();
    },

    async save() {
      const response = await this.$api.post(
        `${settings.BACKEND_URL}/api/v-nurse/assesments/risk_assessment_answers/update`,
        {
          clientId: this.client.id,
        },
        this.riskSurveyData,
      );
      this.$emit('update', response);
      this.close();
    },
  },

  props: {
    client: {
      type: Object,
    },
  },

  components: {
    SlottedMessageInDrawer,
  },
};
</script>

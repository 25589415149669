<template v-slot:modal-body>
  <Drawer :direction="'right'" :exist="true" ref="modalDialog">
    <div class="h-drawer-max79 SurgeryInfo">
      <div class="h-drawer-card">
        <div class="h-drawer-card-header h-align-items-center h-space-between h-padding-bottom-l">
          <h5 class="h-h3 h-primary_shade_1">
            {{ surgery.id ? 'Edit Order' : 'Create Order' }}
          </h5>
          <div>
            <button
              type="button"
              class="close"
              aria-hidden="true"
              @click="close"
              :disabled="loading"
            >
              <img src="../../../../../../public/assets/images/Collapse-drawer.svg" alt="" />
            </button>
          </div>
        </div>
        <div class="h-drawer-card-body">
          <div>
            <label class="h-label">Care protocol</label>
            <Multiselect
              v-model="surgery.care_protocol_id"
              :options="careProtocolChoices"
              :multiple="false"
              track-by="id"
              label="name"
              :class="{ 'is-error': errors.care_protocol_id }"
              @input="clearError('care_protocol_id')"
            >
              <template v-slot:singleLabel="{ option }">{{ option.name }}</template>
              <template v-slot:option="{ option }">{{ option.name }}</template>
            </Multiselect>
            <div v-if="errors.care_protocol_id" class="error-message">
              {{ errors.care_protocol_id }}
            </div>
          </div>

          <div class="h-margin-top-l">
            <label class="h-label">Start Date:</label>
            <input
              id="surgeryDate"
              type="date"
              v-model="surgery.surgery_date"
              class="h-input"
              required
            />
          </div>
          <div class="h-margin-top-l">
            <label class="h-label">End Date:</label>
            <input
              id="dischargeDate"
              type="date"
              v-model="surgery.discharge_date"
              class="h-input"
              required
            />
          </div>
          <div class="h-margin-top-l">
            <label class="h-label">Surgery Date:</label>
            <input
              id="executionDate"
              type="date"
              v-model="surgery.execution_date"
              class="h-input"
              required
            />
          </div>
        </div>

        <div class="h-drawer-card-footer">
          <button type="button" class="h-btn h-btn-accent" @click="submit" :disabled="loading">
            {{ surgery.id ? 'Update' : 'Create' }}
          </button>
        </div>
      </div>
    </div>
  </Drawer>
</template>

<script>
import { useVuelidate } from '@vuelidate/core';
import { required, helpers } from '@vuelidate/validators';
import Multiselect from 'vue-multiselect';
import 'vue-multiselect/dist/vue-multiselect.min.css';

export default {
  name: 'EditSurgeryInfo',

  components: {
    Multiselect,
  },

  props: {
    surgeryInfo: {
      type: Object,
      required: false,
    },
    careProtocols: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      loading: true,
      surgery: {},
      errors: {},
    };
  },

  setup: () => ({ v$: useVuelidate() }),

  validations() {
    return {
      surgery: {
        care_protocol_id: { required },
        surgery_date: {
          required,
          isSameOrBeforeDischarge: helpers.withMessage(
            'Surgery date must be the same or before the discharge date',
            function validateSurgeryDate() {
              const surgeryDate = new Date(this.surgery.surgery_date);
              const dischargeDate = new Date(this.surgery.discharge_date);
              return surgeryDate <= dischargeDate;
            },
          ),
        },
        discharge_date: { required },
      },
    };
  },

  computed: {
    careProtocolChoices() {
      return this.careProtocols.map(protocol => ({
        id: protocol.id,
        name: protocol.name,
      }));
    },
  },

  methods: {
    clearError(fieldName) {
      this.errors[fieldName] = null;
    },
    show(s) {
      this.surgery = { ...s };
      if (s.care_protocol_id) {
        const protocolId = s.care_protocol_id;
        const careProtocol = this.careProtocols.find(protocol => protocol.id === protocolId);
        if (careProtocol) {
          this.surgery.care_protocol_id = careProtocol;
        }
      }
      this.$refs.modalDialog.open();
      this.loading = false;
    },

    close() {
      this.$refs.modalDialog.close();
    },

    async submit() {
      const result = await this.v$.$validate();
      if (!result) {
        this.v$.$errors.forEach((e) => {
          this.errors[e.$property] = e.$message;
        });
        return;
      }
      if (
        typeof this.surgery.care_protocol_id === 'object' &&
        this.surgery.care_protocol_id !== null
      ) {
        this.surgery.care_protocol_id = this.surgery.care_protocol_id.id;
      }

      this.errors = {};
      this.$emit('onSubmit', this.surgery);
    },
  },
};
</script>

<template>
  <div
    class="factorItem h-d-flex h-align-item-center h-cursor-pointer"
    @click="() => viewAssessment(item)"
  >
    <div
      class="h-d-flex h-w50
"
    >
      <div class="h-fs-icon" :data-tooltip="`${itemAttributes.hint}`">
        <i
          :class="`healenticons-${itemAttributes.class}`"
          :data-tooltip="`${itemAttributes.hint}`"
        ></i>
      </div>
      <div
        class="align-left flex-column h-align-center h-d-flex h-padding-right-xs
"
      >
        <h4
          :class="[
            'h-font20',
            'h-margin-bottom-xxs',
            'h-text-left',
            isNull ? 'h-secondary_shade_1' : 'h-primary_shade_1'
          ]"
          :data-tooltip="`${itemAttributes.hint}`"
        >
          {{ itemAttributes.title }}
        </h4>
        <h6
          :data-tooltip="
            `${
              isNull ? 'No data found in the specified range' : 'Last value provided by the patient'
            }`
          "
          class="h-h4 h-text-left h-secondary_shade_1"
        >
          {{ isNull ? 'No data' : `${formatNumber(item.actual)} score` }}
        </h6>
      </div>
    </div>
    <div class="h-w50 h-d-flex justify-content-end align-items-end">
      <h6
        class="h-h4 h-progress-value"
        :style="`color:${itemAttributes.trendColor} ;`"
        :data-tooltip="
          `${
            notEnoughData
              ? 'No enough data'
              : isNull
              ? 'No data found in the specified range'
              : this.isFullJourney
              ? 'Trend (Change factor of the entire journey)'
              : 'Trend (Comparing the averages of the last 30 days with the 30 days before' +
                ' to determine the change factor)'
          }`
        "
      >
        <span class="h-secondary_shade_1 h-margin-right-xs" v-if="isNull || notEnoughData">
          ?
        </span>
        <span
          v-else-if="item.trend === 0"
          class="h-margin-right-xs"
          :style="`color:${itemAttributes.trendColor}`"
          >--</span
        >
        <i
          v-else-if="item.trend > 0"
          class="healenticons-path-3321-1"
          :style="`color:${itemAttributes.trendColor} ;`"
        ></i>
        <i
          v-else
          class="healenticons-path-3322"
          :style="`color:${itemAttributes.trendColor} ;`"
        ></i>
        {{ isNull ? '--' : formatNumber(item.trend) }}{{ '%' }}
      </h6>
      <AssessmentCardProgress
        :color="itemAttributes.color"
        :value="item.actual || 0"
        :min="item.min || 0"
        :max="item.max || 0"
        :isNull="isNull"
        :notEnoughData="notEnoughData"
      ></AssessmentCardProgress>
    </div>
  </div>
</template>

<script>
import AssessmentCardProgress from './AssessmentCardProgress';

export default {
  methods: {
    viewAssessment(item) {
      this.$emit('viewAssessment', item);
    },
    formatNumber(num) {
      if (num === null || num === undefined) return '--';
      num = Math.abs(num);
      if (Math.floor(num) === num) {
        return num.toString();
      }
      return num.toFixed(2);
    },
  },
  computed: {
    notEnoughData() {
      const { trend } = this.item;
      if (trend === null) return true;
      return false;
    },
    isNull() {
      const {
        actual, max, min, mean, oldest, std, trend, current_id: currentId,
      } = this.item;
      if (currentId === null) return true;
      return (
        actual === 0 &&
        max === 0 &&
        min === 0 &&
        mean === 0 &&
        oldest === 0 &&
        std === 0 &&
        trend === 0
      );
    },
    itemAttributes() {
      const title = this.item.title;
      const className = 'tasks-solid';
      let trendColor = '#A1A3B3';
      let color = '#A1A3B3';
      const hint = this.item.title;
      if (this.item.trend > 0) {
        trendColor = '#2B5ADC';
      } else if (this.item.trend < 0) {
        trendColor = '#EE4266 ';
      } else if (this.item.trend === 0 && !this.isNull) {
        trendColor = '#2B5ADC';
      } else {
        trendColor = '#A1A3B3';
      }
      if (this.item.actual > this.item.mean) {
        color = '#2B5ADC';
      } else {
        color = '#EE4266 ';
      }
      return {
        title,
        class: className,
        color,
        trendColor,
        hint,
      };
    },
  },
  components: {
    AssessmentCardProgress,
  },

  props: {
    item: {
      type: Object,
    },
    isFullJourney: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<template>
  <div>
    <table class="table w-100 table-bordered">
      <tbody>
        <tr>
          <th colspan="1000" class="text-center big-header">Servings</th>
        </tr>
        <tr class="l-1">
          <th class="w-1 text-nowrap">Total Servings:</th>
          <td>{{ recipe.total_servings || "-" }}</td>
        </tr>
        <tr class="l-2">
          <th class="text-nowrap">Serving Size:</th>
          <td>{{ recipe.serving_size || "-" }}</td>
        </tr>

        <tr>
          <th colspan="1000" class="text-center big-header">
            Amount per serving
          </th>
        </tr>

        <tr class="l-1">
          <th class="text-nowrap">Calories:</th>
          <td>{{ formatNumber(recipe.kcal) }} kcal</td>
        </tr>

        <tr class="l-1">
          <th class="text-nowrap">Total Fat:</th>
          <td>{{ formatNumber(recipe.fat_g) }} g</td>
        </tr>
        <tr class="l-2">
          <th class="text-nowrap">Saturated Fat:</th>
          <td>{{ formatNumber(recipe.fat_sat_g) }} g</td>
        </tr>

        <tr class="l-1">
          <th class="text-nowrap">Cholesterol:</th>
          <td>{{ formatNumber(recipe.cholest_g) }} mg</td>
        </tr>

        <tr class="l-1">
          <th class="text-nowrap">Sodium:</th>
          <td>{{ formatNumber(recipe.sodium_mg) }} mg</td>
        </tr>

        <tr class="l-1">
          <th class="text-nowrap">Total Carbohydrate:</th>
          <td>{{ formatNumber(recipe.carb_g) }} g</td>
        </tr>
        <tr class="l-2">
          <th class="text-nowrap">Dietary Fiber:</th>
          <td>{{ formatNumber(recipe.fiber_g) }} g</td>
        </tr>
        <tr class="l-2">
          <th class="text-nowrap">Total Sugars:</th>
          <td>{{ formatNumber(recipe.sugar_g) }} g</td>
        </tr>

        <tr class="l-1">
          <th class="text-nowrap">Protein:</th>
          <td>{{ formatNumber(recipe.protein_g) }} g</td>
        </tr>

        <tr>
          <th colspan="1000" class="text-center big-header">Vitamins</th>
        </tr>

        <tr>
          <th class="text-nowrap">Vitamin D:</th>
          <td>{{ formatNumber(recipe.vit_d_mcg) }} mcg</td>
        </tr>
        <tr>
          <th class="text-nowrap">Calcium:</th>
          <td>{{ formatNumber(recipe.calcium_mg) }} mg</td>
        </tr>
        <tr>
          <th class="text-nowrap">Iron:</th>
          <td>{{ formatNumber(recipe.iron_mg) }} mg</td>
        </tr>
        <tr>
          <th class="text-nowrap">Potassium:</th>
          <td>{{ formatNumber(recipe.potass_mg) }} mg</td>
        </tr>
        <tr>
          <th class="text-nowrap">Phosphorus:</th>
          <td>{{ formatNumber(recipe.phosph_mg) }} mg</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>


<style lang="stylus" scoped>
table {
  td, th {
    padding: 2.5rem 5rem;
    font-size: 8rem;
  }

  th {
    text-align: right;
  }

  .l-1 > th, .l-1 > td {
    font-weight: bold;
  }

  .l-2 > th {
    font-weight: normal;
  }

  .big-header {
    font-size: 12rem;
    padding: 5rem 0px;
  }
}
</style>


<script>
export default {
  props: {
    recipe: {
      type: Object,
    },
  },

  methods: {
    formatNumber(number) {
      return typeof number === 'number' ? number.toFixed(1) : '-';
    },
  },
};
</script>

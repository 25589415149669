<template>
  <div class="h-recipe-filter">
    <div class="searchFilter">
      <SearchFilterDropDown>
        <template v-slot:title>
          <h6
            :class="{
              filterEnabledDropDownHeader: filters.nutritionValues,
            }"
          >
            Nutrition values
          </h6>
        </template>

        <template v-slot:content>
          <div
            v-for="(rangeFilter, index) of RANGE_FILTER_MAPPING"
            :key="rangeFilter.prop"
            class="rangeFilter"
          >
            <label
              :class="{
                'filterEnabled text-bold':
                  filters.nutritionValues &&
                  filters.nutritionValues[rangeFilter.prop],
              }"
            >
              {{ rangeFilter.title }}:
            </label>
            <div class="px-2">
              <input
                ref="rangeFilter"
                :data-prop="rangeFilter.prop"
                type="text"
              />
            </div>
            <hr
              class="rangeFilter-hr"
              v-if="index !== RANGE_FILTER_MAPPING.length - 1"
            />
          </div>
        </template>
      </SearchFilterDropDown>
    </div>

    <div
      v-for="optionFilter of optionFilters"
      :key="optionFilter.prop"
      class="searchFilter"
    >
      <SearchFilterDropDown>
        <template v-slot:title>
          <h6
            :class="{
              filterEnabledDropDownHeader: filters[optionFilter.prop],
            }"
          >
            {{ optionFilter.title }}
          </h6>
        </template>

        <template v-slot:content>
          <SerachFilterOptions
            :options="optionFilter.options"
            v-model="filters[optionFilter.prop]"
            @input="filterOptionChanged(optionFilter.prop, $event)"
          />
        </template>
      </SearchFilterDropDown>
    </div>
  </div>
</template>


<style lang="stylus" scoped>
.rangeFilter {
  margin-top: 0;
}

.rangeFilter:first-child {
  margin-top: 0;
}

.rangeFilter-hr {
  margin-top: 2em;
}

.filterEnabled {
  text-decoration: underline;
}

.filterEnabledDropDownHeader {
  text-decoration: underline;
}

h6 {
  font-size: 1.35em;
}

.searchFilter {
  margin-bottom: 1em;
}
</style>


<script>
import SerachFilterOptions from './SerachFilterOptions';
import SearchFilterDropDown from './SearchFilterDropDown';

const DIET_TYPES = ['Vegetarian', 'Vegan', 'Paleo'];
const MEAL_TYPES = [
  'Breakfast',
  'Morning Snack',
  'Lunch',
  'Evening Snack',
  'Dinner',
];
const DIET_RESTRICTIONS = [
  'Egg',
  'Fish',
  'Wheat',
  'Peanuts',
  'Pork',
  'Beef',
  'Poultry',
  'Shellfish',
  'Soy',
  'Tree Nuts',
  'Milk',
];
const RECIPE_TAGS = [
  '10 or Less Ingredients',
  'Pasta/Grain',
  'Salad',
  'Drink/Smoothie',
  'Soup/Chili',
  'Sides',
  'Slow Cooker',
  'Low-Sodium',
  'Grab-N-Go',
];
const RECIPE_COOKING_TIMES = [
  '<15min',
  '15min-30min',
  '30min-1h',
  '1h-2h',
  '>2h',
];
const RECIPE_PREPARATION_TIMES = [
  '<15min',
  '15min-30min',
  '30min-1h',
  '1h-2h',
  '>2h',
];
const SERVING_SIZES = [1, 2, 3, 4, '>= 5'];
const COOKING_SKILLS = ['Beginner', 'Intermediate', 'Advanced', 'Iron chef'];
const CUISINES = [
  'American',
  'Chinese',
  'French',
  'Greek',
  'Indian',
  'Italian',
  'Mediterranean',
  'Mexican',
  'Spanish',
  'Thai',
  'Other',
];

export default {
  data() {
    return {
      filters: this.value,
      RANGE_FILTER_MAPPING: [
        {
          prop: 'kcal',
          title: 'Kilocalories',
          max: 3500,
        },
        {
          prop: 'carb_g',
          title: 'Carbohydrates, g',
          max: 1000,
        },
        {
          prop: 'protein_g',
          title: 'Protein, g',
          max: 350,
        },
        {
          prop: 'fat_g',
          title: 'Fat, g',
          max: 300,
        },
        {
          prop: 'fiber_g',
          title: 'Fiber, g',
          max: 150,
        },
        {
          prop: 'sugar_g',
          title: 'Sugar, g',
          max: 50,
        },
        {
          prop: 'sodium_mg',
          title: 'Sodium, mg',
          max: 3500,
        },
        {
          prop: 'potass_mg',
          title: 'Potassium, mg',
          max: 4500,
        },
        {
          prop: 'phosph_mg',
          title: 'Phosphorus, mg',
          max: 4000,
        },
      ],
      optionFilters: [
        {
          title: 'Diet type',
          options: DIET_TYPES,
          prop: 'diet_type',
        },
        {
          title: 'Meal type',
          options: MEAL_TYPES,
          prop: 'meal_type',
        },
        {
          title: 'Diet restrictions',
          options: DIET_RESTRICTIONS,
          prop: 'restricted',
        },
        {
          title: 'Recipe tags',
          options: RECIPE_TAGS,
          prop: 'tags',
        },
        {
          title: 'Cooking time',
          options: RECIPE_COOKING_TIMES,
          prop: 'cooking_time',
        },
        {
          title: 'Preparation time',
          options: RECIPE_PREPARATION_TIMES,
          prop: 'preparation_time',
        },
        {
          title: 'Serving size',
          options: SERVING_SIZES,
          prop: 'serving_size',
        },
        {
          title: 'Cooking skill',
          options: COOKING_SKILLS,
          prop: 'skill_level',
        },
        {
          title: 'Cuisine',
          options: CUISINES,
          prop: 'cuisine',
        },
      ],
    };
  },

  methods: {
    filterOptionChanged(prop, value) {
      if (value.length) {
        this.filters[prop] = value;
      } else if (this.filters[prop]) {
        delete this.filters[prop];
      }
      this.filters = { ...this.filters };
      this.$emit('input', this.filters);
    },
  },

  mounted() {
    this.$refs.rangeFilter.forEach((element) => {
      const propName = $(element).attr('data-prop');
      const filterMapping = this.RANGE_FILTER_MAPPING.find((entry) => {
        return entry.prop === propName;
      });
      const that = this;
      $(element).ionRangeSlider({
        type: 'double',
        skin: 'round',
        grid: true,
        min: 0,
        max: filterMapping.max,
        from: 0,
        to: 0,
        onChange(data) {
          if (data.from === 0 && data.to === 0) {
            if (that.filters.nutritionValues) {
              if (that.filters.nutritionValues[propName]) {
                delete that.filters.nutritionValues[propName];
              }
              if (Object.keys(that.filters.nutritionValues).length === 0) {
                delete that.filters.nutritionValues;
              }
            }
          } else {
            if (!that.filters.nutritionValues) {
              that.filters.nutritionValues = {};
            }
            if (!that.filters.nutritionValues[propName]) {
              that.filters.nutritionValues[propName] = {};
            }
            that.filters.nutritionValues[propName].from = data.from;
            that.filters.nutritionValues[propName].to = data.to;
          }
          that.filters = { ...that.filters };
          that.$emit('input', that.filters);
        },
      });
    });
  },

  props: {
    value: {
      type: Object,
    },
  },

  components: {
    SearchFilterDropDown,
    SerachFilterOptions,
  },
};
</script>

<template>
  <!-- <SlottedMessage
    ref="slottedMessage"
    title="Clone thoughts of the day"
    :closeDisabled="loading"
  > -->
  <div class="h-drawer-max79">
    <Drawer :direction="'right'" :exist="true" ref="modalDialog">
      <div class="h-drawer-card">
        <div class="h-drawer-card-header">
          <div class="h-main-drawer-card-header h-margin-bottom-l">
            <h3 class="h-h3 h-primary_shade_1">Clone existing items</h3>
            <button
              type="button"
              class="close"
              aria-hidden="true"
              @click="close"
              :disabled="loading"
            >
              <!-- <font-awesome-icon
                    :icon="['fas', 'sign-out-alt']"
                    style="height: 1.5vw; width: 1.5vw; color: #c3c4cb"
                  /> -->
              <img src="/assets/images/Collapse-drawer.svg" alt="" />
            </button>
          </div>
        </div>
        <div class="h-drawer-card-body">
          <div :class="{ 'd-none': loading }">
            <div class="h-margin-bottom-l">
              <InputField
                type="select"
                v-model="careProtocolId"
                :choices="protocolChoices"
                :error="errors.protocol_id"
                @input="protocolSelected"
              />
            </div>

            <!-- <div class="h-margin-bottom-l">
              <label class="h-label">User (optional)</label>
              <select
                id="cloneThoughtOfTheDay-searchUser"
                :class="{ 'is-invalid': errors.user_id }"
                class="h-input"
                placeholder="Type to search..."
                data-noresults-text="No users found."
                autocomplete="off"
              ></select>
              <div class="invalid-feedback" v-if="errors.user_id">
                {{ errors.user_id }}
              </div>
              <p class="h-h6 h-primary_shade_1 h-margin-top-s">
                If user not selected, hospital template will be used.
              </p>
            </div> -->

            <div class="h-margin-bottom-s">
              <InputField
                type="checkbox"
                label="Replace existing thoughts of the day"
                id="cloneThoughtOfTheDay-replaceExisting"
                v-model="removeExisting"
              />
            </div>

            <div>
              <InputField
                type="checkbox"
                label="Clone from this care protocol"
                id="cloneThoughtOfTheDay-cloneIntoThat"
                v-model="cloneIntoThat"
              />
              <p class="h-h5 h-primary_shade_1 h-margin-top-s">
                If selected clones items into the selected protocol . Otherwise
                copies into current protool (template).
              </p>
            </div>
          </div>
          <Loader v-if="loading" />
        </div>

        <div class="h-drawer-card-footer">
          <button
            type="button"
            class="h-btn"
            :class="{
              'h-btn-safe': !cloneIntoThat,
              'h-btn-dangerous': cloneIntoThat,
            }"
            @click="confirm"
            :disabled="loading || !careProtocolId"
          >
            Clone
            <strong v-if="!cloneIntoThat"><u>INTO</u> THIS</strong>
            <strong v-if="cloneIntoThat"><u>FROM</u> THIS</strong>
          </button>

          <button
            type="button"
            class="h-btn h-btn-accent h-margin-left-s"
            @click="close"
            :disabled="loading"
          >
            Close
          </button>
        </div>
      </div>

      <!-- </SlottedMessage> -->
    </Drawer>
  </div>
</template>

<style lang="stylus" scoped>
.input-row {
  margin-bottom: 1em;
}
</style>

<script>
import settings from '@/settings.js';
import Loader from '@/components/Loader';
import InputField from '@/components/InputField';

export default {
  data() {
    return {
      loading: true,
      hospitals: [],
      hospitalId: null,
      careProtocolId: null,
      careProtocols: [],
      user: null,
      removeExisting: false,
      cloneIntoThat: false,
      errors: {},
    };
  },

  computed: {
    protocolChoices() {
      let choices = [];
      if (this.careProtocols) {
        choices = this.careProtocols.map((protocol) => {
          if (protocol.hospital?.name) {
            return [protocol.id, `${protocol.name} [${protocol.hospital.name}]`];
          }
          return [protocol.id, protocol.name];
        });
      }
      choices.unshift([null, '--- select protocol ---']);
      return choices;
    },
  },

  methods: {
    show() {
      this.$refs.modalDialog.open();
      this.$refs.modalDialog.active = true;
      this.loading = false;

      $('#cloneThoughtOfTheDay-searchUser').val(null).removeClass('is-invalid');
      this.errors = {};
      this.hospitalId = null;
      this.careProtocolId = null;
      this.user = null;
      this.removeExisting = false;
      this.cloneIntoThat = false;

      $('#cloneThoughtOfTheDay-searchUser').prop('disabled', true);

      // this.$refs.slottedMessage.show();
    },

    close(force) {
      this.$refs.modalDialog.close();
      this.$refs.modalDialog.active = false;
      // this.$refs.slottedMessage.close(force);
    },

    hospitalSelected(hospitalId) {
      this.errors.hospital_id = null;
      this.careProtocolId = null;
      this.protocolSelected(null);

      this.user = null;
      this.errors.user_id = null;
      $('#cloneThoughtOfTheDay-searchUser').val(null).removeClass('is-invalid');
      $('#cloneThoughtOfTheDay-searchUser').prop('disabled', !hospitalId);
    },

    protocolSelected(protocolId) {
      this.errors.protocol_id = null;
    },

    async confirm() {
      this.loading = true;

      const userId = this.user ? this.user.id : settings.currentSession.user.id;
      const doctorId = this.doctorId
        ? this.doctorId
        : settings.currentSession.user.id;
      const payload = {
        protocol_id: this.cloneIntoThat
          ? this.intoCareProtocolId
          : this.careProtocolId,
        into_care_protocol_id: this.cloneIntoThat
          ? this.careProtocolId
          : this.intoCareProtocolId,

        owner_id: this.cloneIntoThat ? doctorId : userId,
        into_owner_id: this.cloneIntoThat ? userId : doctorId,

        result_care_protocol_id: this.intoCareProtocolId,

        remove_existing: this.removeExisting,
      };
      const response = await this.$api.copyClientThoughtsOfTheDay(
        this.doctorId || settings.currentSession.user.id,
        payload,
      );
      this.$emit('copied', response.thought_of_the_day_list);
      this.close(true);
    },

    async getCareProtocolList() {
      try {
        const resp = await this.$api.getCareProtocols();
        this.careProtocols = resp.care_protocols;
      } catch (e) {
        this.careProtocols = [];
      }
    },
  },

  async mounted() {
    const that = this;
    $('#cloneThoughtOfTheDay-searchUser').autoComplete({
      minLength: 2,
      events: {
        search(qry, callback, origJQElement) {
          that.$api
            .searchStaffMemberGlobal({
              hospital_id: that.hospitalId,
              protocol_id: that.protocolId,
              remove_existing: that.removeExisting,
              search: qry,
            })
            .then((result) => {
              callback(
                result.map((entry) => {
                  return {
                    id: entry.id,
                    text: entry.name
                      ? `${entry.name} (${entry.email})`
                      : entry.email,
                  };
                }),
              );
            });
        },
      },
    });
    $('#cloneThoughtOfTheDay-searchUser').on(
      'autocomplete.select',
      (evt, item) => {
        $('#cloneThoughtOfTheDay-searchUser').removeClass('is-invalid');
        this.user = item;
        this.errors.user_id = null;
      },
    );

    this.getCareProtocolList();
    this.loading = false;
  },

  props: {
    intoCareProtocolId: {
      type: Number,
      required: true,
    },
    doctorId: {
      type: Number,
      required: false,
    },
  },

  components: {
    Loader,
    InputField,
  },
};
</script>

import Vue from 'vue';


export default class UserStatus {
  constructor(meetingCode, userId) {
    this.meetingCode = meetingCode;
    this.userId = userId;

    this.keepAliveIntervalHandler = null;
    this.keepAliveIntervalTime = 8000;
  }

  clearKeepAlive() {
    if (this.keepAliveIntervalHandler) {
      clearInterval(this.keepAliveIntervalHandler);
    }
    this.keepAliveIntervalHandler = null;
  }

  userJoined() {
    const updateKeepAlive = () => {
      Vue.prototype.$api.meetingKeepAlive(this.meetingCode, this.userId);
    };

    this.clearKeepAlive();
    updateKeepAlive();
    this.keepAliveIntervalHandler = setInterval(
      updateKeepAlive, this.keepAliveIntervalTime,
    );
  }

  userLeft() {
    this.clearKeepAlive();
    Vue.prototype.$api.meetingLeave(this.meetingCode, this.userId);
  }
}

<template>
  <Drawer :direction="'right'" :exist="true" ref="modalDialog">
    <div class="row h-100 h-drawer-max79">
      <div class="col-lg-12">
        <div class="h-drawer-card">
          <div class="h-drawer-card-header">
            <div class="h-main-drawer-card-header h-margin-bottom-l">
              <h3 class="h-h3 h-primary_shade_1">
                Edit care protocol item
              </h3>
              <button
                type="button"
                class="close"
                aria-hidden="true"
                @click="close"
                :disabled="loading"
              >
                <img src="../../../../../../public/assets/images/Collapse-drawer.svg" alt="" />
              </button>
            </div>
          </div>

          <div class="h-drawer-card-body">
            <div v-if="!loading">
              <h4 class="h-h4 h-primary_shade_1 h-margin-bottom-l">
                {{ item.name }}
              </h4>

              <div class="row">
                <div class="col-12">
                  <label class="h-label" for="surgery-item-status">Status:</label>
                  <InputField
                    id="surgery-item-status"
                    type="select"
                    :choices="[
                      'Not Started',
                      'Delayed',
                      'In Progress',
                      'Done',
                      'Follow-up Required'
                    ]"
                    v-model="item.status"
                    :error="errors.status"
                    @change="errors.status = null"
                  />
                </div>
              </div>
              <div class="row" v-if="item.is_appointment_type">
                <div class="col-12">
                  <label class="h-label h-margin-top-l">Appointment:</label>
                  <div class="row">
                    <div class="col-6 h-border-right-0">
                      <InputField
                        type="date"
                        :error="!!errors.appointment_datetime"
                        v-model="appointmentDate"
                        @change="errors.appointment_datetime = null"
                      />
                    </div>
                    <div class="col-6 h-border-left-0">
                      <InputField
                        type="time"
                        :error="!!errors.appointment_datetime"
                        v-model="appointmentTime"
                        @change="errors.appointment_datetime = null"
                      />
                    </div>
                  </div>

                  <div
                    class="invalid-feedback d-block text-center"
                    v-if="errors.appointment_datetime"
                  >
                    {{ errors.appointment_datetime }}
                  </div>
                </div>
              </div>

              <br />
            </div>

            <Loader v-else />
          </div>

          <div class="h-drawer-card-footer" style="background-color: white">
            <button type="button" class="h-btn h-btn-accent" @click="save" :disabled="loading">
              Update
            </button>
            <button
              type="button"
              class="h-btn h-btn-dangerous h-margin-left-s"
              @click="close"
              :disabled="loading"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  </Drawer>
</template>

<script>
import InputField from '@/components/InputField';
import Loader from '@/components/Loader';

export default {
  name: 'EditItemDialog',

  data() {
    return {
      loading: false,
      oldItem: null,
      appointmentDate: null,
      appointmentTime: null,
      item: {},
      errors: {},
    };
  },

  methods: {
    show(item) {
      this.$refs.modalDialog.open();
      this.$refs.modalDialog.active = true;
      this.loading = false;

      this.errors = {};
      this.loading = false;
      this.oldItem = item;
      this.item = JSON.parse(JSON.stringify(item));

      if (item.start_datetime) {
        this.item.start_date = this.item.start_datetime.split(' ')[0];
      }
      if (item.due_datetime) {
        this.item.due_date = this.item.due_datetime.split(' ')[0];
      }
      this.appointmentDate = null;
      this.appointmentTime = null;
      if (this.item.appointment_datetime) {
        const parts = this.item.appointment_datetime.split(' ');
        this.appointmentDate = parts[0];
        this.appointmentTime = parts[1];
      }

      $(this.$refs.modalDialog.el).modal({
        backdrop: 'static',
        keyboard: false,
      });
      $('.modal-backdrop')
        .last()
        .insertBefore(this.$refs.modalDialog.el);
    },

    close(force) {
      if (!force && this.loading) return;
      // $(this.$refs.modalDialog.el).modal("hide");
      this.$refs.modalDialog.close();
      this.$refs.modalDialog.active = false;
    },

    async save() {
      this.loading = true;
      if (!this.appointmentDate || !this.appointmentTime) {
        this.item.appointment_datetime = null;
      } else {
        this.item.appointment_datetime = `${this.appointmentDate} ${this.appointmentTime}`;
      }

      const response = await this.$api.updateClientCareProtocolItem(this.item.id, this.item);
      if (response.status === 'error') {
        this.loading = false;
        this.errors = response.errors;
      } else {
        this.$emit('updated', response.care_protocol_items);
        this.close(true);
      }
    },
  },

  components: {
    InputField,
    Loader,
  },
};
</script>

<template>
  <div>
    <div class="h-margin-bottom-l">
      <button type="button" class="h-btn h-btn-accent" @click="save">
        Save changes
      </button>
    </div>
  </div>
</template>


<script>
export default {
  methods: {
    save() {
      this.$emit('save');
    },
  },
};
</script>

<template>
  <div class="h-bg-w h-margin-top-l h-padding-top-l">
    <div class="h-align-items-center h-space-between">
      <div class="h-margin-left-l">
        <h5 class="h-h3 h-primary_shade_1">
          Documents (<span class="h-h4 h-accent">{{ currentDocumentsCount }}</span
          >)
        </h5>
      </div>
      <div class="h-h4 h-accent h-margin-right-l">
        <button type="button" class="h-btn h-btn-accent" @click="upload">
          <div class="h-align-items-center">
            <i class=" h-secondary_shade_5 fas fa-upload fa-l h-padding-right-s"></i>Upload
          </div>
        </button>
      </div>
    </div>

    <div v-if="!loading" class="h1-table h-margin-bottom-l">
      <table class="table dataTable checklist mt-3 xs-title-content h-table-max-15">
        <thead class="thead-light">
          <tr>
            <th class="text-left" width="60%"><span class="table-title-bold">Name</span></th>
            <th class="text-right"><span class="table-title-bold">Creation date</span></th>
            <th class="text-right"><span class="table-title-bold">Size</span></th>
            <th class="w-1 text-nowrap xs-title text-left">
              <span class="table-title-bold">Action</span>
            </th>
          </tr>
        </thead>

        <tbody v-if="documents.length !== 0">
          <tr v-for="document of documents" :key="document.id">
            <td style="width: 60px">
              <a :href="getDownloadUrl(document)" target="_blank">
                <span style="color: black">{{ document.display_filepath }}</span>
              </a>
            </td>
            <td class="text-right">
              <span>
                {{ $dateUtils.toLocalDatetime(document.created_at) | formatDateWithH }}
              </span>
            </td>
            <td class="text-right">
              <span>
                {{ getReadableFileSizeString(document.size) }}
              </span>
            </td>
            <td class="w-1 text-nowrap h-action-td">
              <i
                class="fas fa-pen font-16 mr-3 cursor-pointer"
                style="color: black"
                title="rename"
                @click="renameFile(document)"
              ></i>
              <i
                class="fas fa-trash-alt text-danger font-16 cursor-pointer h-margin-left-s"
                title="Delete"
                @click="deleteFile(document)"
              ></i>
            </td>
          </tr>
        </tbody>

        <tbody v-if="documents.length === 0">
          <tr>
            <td colspan="1000" class="text-center">No entries</td>
          </tr>
        </tbody>
      </table>
    </div>

    <Loader v-else />

    <UploadFileDiloag ref="uploadFileDiloag" :client="client" @setDocuments="setDocuments" />
    <DeleteFileDialog ref="deleteFileDialog" @setDocuments="setDocuments" />
    <RenameFileDialog ref="renameFileDialog" @setDocuments="setDocuments" />
  </div>
</template>

<style scoped lang="stylus">
.edit-button {
  min-width: 10em;

  .doc-text-title {
    font-family: 'Roboto', sans-serif;
  }
}
</style>

<script>
import moment from 'moment-timezone';
import settings from '@/settings.js';
import Loader from '@/components/Loader';
import UploadFileDiloag from './UploadFileDiloag';
import DeleteFileDialog from './DeleteFileDialog';
import RenameFileDialog from './RenameFileDialog';

export default {
  data() {
    return {
      loading: true,
      currentDocumentsCount: 0,
      documents: [],
    };
  },
  filters: {
    formatDateWithH(date) {
      if (!date) {
        return 'No data';
      }
      return moment(date).format('MMM D, YYYY h:mm a');
    },
  },
  methods: {
    upload() {
      this.$refs.uploadFileDiloag.show();
    },

    setDocuments(documents) {
      this.documents = documents;
    },

    getReadableFileSizeString(fileSizeInBytes) {
      if (fileSizeInBytes === null) return '';
      let i = -1;
      const byteUnits = [' kB', ' MB', ' GB', ' TB', 'PB', 'EB', 'ZB', 'YB'];
      do {
        fileSizeInBytes /= 1024;
        i += 1;
      } while (fileSizeInBytes > 1024);

      return Math.max(fileSizeInBytes, 0.1).toFixed(1) + byteUnits[i];
    },

    getDownloadUrl(document) {
      return `${settings.BACKEND_URL}/api/v-nurse/client/file/download/${document.id}`;
    },

    deleteFile(document) {
      this.$refs.deleteFileDialog.show(document);
    },

    renameFile(document) {
      this.$refs.renameFileDialog.show(document);
    },
  },

  async mounted() {
    const response = await this.$api.post(
      `${settings.BACKEND_URL}/api/v-nurse/client/file/documents`,
      null,
      {
        clientId: this.client.id,
      },
    );
    this.documents = response.documents;
    this.currentDocumentsCount = this.documents.length;
    this.loading = false;
  },
  beforeUpdate() {
    this.currentDocumentsCount = this.documents.length;
  },
  components: {
    Loader,
    UploadFileDiloag,
    DeleteFileDialog,
    RenameFileDialog,
  },

  props: {
    client: {
      type: Object,
      required: true,
    },
  },
};
</script>

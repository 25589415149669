<template>
  <div class="h-drawer-max79">
    <Drawer ref="Settings" :direction="'right'" :exist="true">
      <div class="h-drawer-card">
        <div class="h-drawer-card-header">
          <div class="h-main-drawer-card-header h-margin-bottom-l">
            <h3 class="h-h3 h-primary_shade_1">Add new</h3>
            <button
              type="button"
              class="close"
              aria-hidden="true"
              @click="close"
              :disabled="loading"
            >
              <img src="/assets/images/Collapse-drawer.svg" alt="" />
            </button>
          </div>
        </div>
        <div class="h-drawer-card-body h-padding-0">
          <div v-if="!loading">
            <div id="RPMRTMStratification" class="h-plus-item">
              <router-link
                id="RPMRTMStratificationClick"
                data-tooltip="Alt+R"
                target="_blank"
                :to="{ name: 'RPMRTMStratification' }"
              >
                <i class=" healenticons-reports"></i>
                <h4 class="h-h4 h-primary_shade_1">
                  RPM/RTM Stratification
                </h4>
              </router-link>
            </div>
            <div class="h-plus-item h-hide-on-tool">
              <router-link id="PatientClick" data-tooltip="Alt+P" :to="{ name: 'AddPatient' }">
                <i class=" healenticons-icon-metro-profile"></i>
                <h4 class="h-h4 h-primary_shade_1">
                  Patient
                </h4>
              </router-link>
            </div>
            <div class="h-plus-item h-hide-on-tool">
              <a href="#" id="AppointmentClick" data-tooltip="Alt+A" @click="appointmentsDrawer">
                <i class=" healenticons-calendar-fill"></i>
                <h4 class="h-h4 h-primary_shade_1">
                  Appointment
                </h4>
              </a>
            </div>
            <div class="h-plus-item h-hide-on-tool">
              <router-link id="HospitalClick" data-tooltip="Alt+H" :to="{ name: 'Invite' }">
                <i class=" healenticons-avatar-solid"></i>
                <h4 class="h-h4 h-primary_shade_1">
                  Provider
                </h4>
              </router-link>
            </div>
          </div>

          <Loader v-if="loading" />
        </div>

        <div class="h-drawer-card-footer">
          <button type="button" class="h-btn h-btn-dangerous " @click="close" :disabled="loading">
            Close
          </button>
        </div>
      </div>
    </Drawer>
    <AppointmentsDrawer ref="AppointmentsDrawer" />
  </div>
</template>

<script>
import Loader from '@/components/Loader';
import AppointmentsDrawer from './AppointmentDrawer';

export default {
  data() {
    return {
      loading: false,
    };
  },
  watch: {
    $route() {
      this.close();
    },
  },
  methods: {
    show() {
      this.loading = false;
      this.$refs.Settings.open();
    },

    close(force) {
      this.$refs.Settings.close();
    },
    isRouteActive(routeName) {
      return this.$route.name === routeName;
    },
    appointmentsDrawer() {
      this.$refs.AppointmentsDrawer.show();
    },
  },

  components: {
    Loader,
    AppointmentsDrawer,
  },
};
</script>

<template>
  <section v-if="enabled">
    <aside class="sidebar" :style="style" ref="element">
      <slot></slot>
    </aside>
    <div class="overlay" id="drawer_overlay" ref="overlay" @click="close"></div>
  </section>
</template>
<script>
import eventBus from '../../event-bus';

export default {
  props: ['direction', 'exist'],
  data() {
    return {
      auto_speed: '0.3s',
      manual_speed: '0.03s',
      threshold: 20,
      startTime: null,
      startPos: null,
      translate: null,
      active: false,
      visible: true,
    };
  },
  computed: {
    element() {
      return this.$refs.element;
    },
    overlay() {
      return this.$refs.overlay;
    },
    enabled() {
      return this.exist === true;
    },
    style() {
      if (this.direction === 'right') {
        if (this.$attrs.styles) {
          return 'transform:translate3d(100%,0,0);right:0;width: 45.25vw;';
        }
        return 'transform:translate3d(100%,0,0);right:0;';
      }
      return 'transform:translate3d(-100%,0,0);left:0;';
    },
  },
  beforeDestroy() {
    eventBus.$offOwner(this);
  },
  mounted() {
    eventBus.$on(
      'uncompleted-appointment-edit-begin',
      (event) => {
        this.appointment_being_edited = true;
      },
      this,
    );
    eventBus.$on(
      'uncompleted-appointment-edit-stop',
      (event) => {
        this.appointment_being_edited = false;
      },
      this,
    );
  },
  methods: {
    overlayOpacity(opacity) {
      this.overlay.style.opacity = opacity;
      if (opacity > 0) {
        this.overlay.style.zIndex = 99999;
      }
    },
    open() {
      this.translate = 0;
      this.$refs.overlay.style.display = 'block';
      this.element.style.transform = `translate3d(${this.translate},0,0)`;
      this.element.style.transitionDuration = this.auto_speed;
      this.overlayOpacity(1);
      this.element.classList.add('active');
      this.active = false;
      eventBus.$emit('drawer-opened', {});
    },
    close() {
      if (this.direction === 'left') {
        this.translate = `-${this.element.offsetWidth}px`;
      } else {
        this.translate = `${this.element.offsetWidth}px`;
      }
      this.element.style.transform = `translate3d(${this.translate},0,0)`;
      this.element.style.transitionDuration = this.auto_speed;
      this.overlayOpacity(0);
      this.$refs.overlay.style.display = 'none';
      this.element.classList.remove('active');
      this.active = false;
      eventBus.$emit('drawer-closed', {});
    },
  },
};
</script>
<style scoped>
div.overlay {
  position: fixed;
  z-index: -999;
  left: 0px;
  top: 0px;
  background: rgba(11, 10, 12, 0.35);
  opacity: 0;
  width: 100%;
  height: 100%;
  transition: opacity 0.3s ease;
}
aside.sidebar {
  z-index: 999999;
  position: fixed;
  will-change: transform;
  height: 100%;
  top: 0px;
  transition: transform 0.3s ease;
  background: #fff;
  overflow-y: hidden;
  overflow-x: hidden;
  word-wrap: break-word;
  right: -100% !important;
}
aside.sidebar.active {
  right: 0 !important;
}
</style>

<template>
  <SlottedMessageInDrawer
    ref="slottedMessage"
    title="Send push notification"
    :closeDisabled="loading"
  >
    <template v-slot:modal-body>
      <div v-if="!loading && data" class="h-table">
        <h3 class="h-h3 h-primary_shade_1">Send push notificaiton to mobile device?</h3>
        <table class="h-notification">
          <tbody>
            <tr>
              <th>Patient name:</th>
              <td>{{ data['Patient Name'] }}</td>
            </tr>
            <tr>
              <th>Topic:</th>
              <td>{{ data['Topic'] }}</td>
            </tr>
            <tr>
              <th>Due On:</th>
              <td>{{ data['Due On'] | formatDate }}</td>
            </tr>
          </tbody>
        </table>
      </div>

      <Loader v-if="loading" />
    </template>

    <template v-slot:modal-footer>
      <div class="row">
        <button type="button" class="h-btn h-btn-accent" @click="confirm" :disabled="loading">
          Send
        </button>

        <button
          type="button"
          class="h-btn h-btn-dangerous h-margin-left-s"
          @click="close"
          :disabled="loading"
        >
          Close
        </button>
      </div>
    </template>
  </SlottedMessageInDrawer>
</template>

<style lang="stylus" scoped>
table {
  margin-top: 1em;
  margin-bottom: 2em;

  th {
    padding-right: 5rem;
    text-align: left;
  }
}
</style>

<script>
import moment from 'moment-timezone';
import SlottedMessageInDrawer from '@/components/dialogs/SlottedMessageInDrawer';
import Loader from '@/components/Loader';

export default {
  props: [],

  data() {
    return {
      data: null,
      loading: false,
    };
  },
  filters: {
    formatDate(date) {
      if (!date) {
        return '-';
      }
      return moment(date).format('MMM D, YYYY h:mm a');
    },
  },
  methods: {
    async show(data) {
      this.loading = false;
      this.data = JSON.parse(JSON.stringify(data));
      const payload = {
        id: this.data.metadata.id,
        alert_type: this.data.metadata.entryType,
      };
      const clientAlertData = await this.$api.getClientAlert(payload);
      if (clientAlertData && clientAlertData.status === 'ok') {
        this.data = { ...this.data, ...clientAlertData.alert };
      }
      this.$refs.slottedMessage.show();
    },

    close(force) {
      this.$refs.slottedMessage.close(force);
    },

    async confirm() {
      this.loading = true;
      const result = await this.$api.alertSendNotification(this.data);
      this.close(true);

      if (result.status === 'ok') {
        $.notify(result.message, {
          position: 'top center',
          className: 'success',
        });
      } else if (result.status === 'error') {
        $.notify(result.message, {
          position: 'top center',
          className: 'error',
        });
      } else {
        $.notify('Unknown error.', {
          position: 'top center',
          className: 'error',
        });
      }
    },
  },

  components: {
    SlottedMessageInDrawer,
    Loader,
  },
};
</script>

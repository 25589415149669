<template>
  <div class="h-drawer-max79">
    <Drawer :direction="'right'" :exist="true" ref="modalDialog">
      <div class="h-drawer-card">
        <slot name="modal-header">
          <div class="h-drawer-card-header">
            <div class="h-main-drawer-card-header h-margin-bottom-l">
              <h3 class="h-h3 h-primary_shade_1">
                <slot name="modal-title">{{ title }}</slot>
              </h3>
              <button
                type="button"
                class="close"
                aria-hidden="true"
                @click="close"
                :disabled="loading"
              >
                <img src="../../../public/assets/images/Collapse-drawer.svg" alt="" />
              </button>
            </div>
          </div>
        </slot>
        <div class="h-drawer-card-body">
          <slot name="modal-body"> </slot>
        </div>

        <div class="h-drawer-card-footer">
          <slot name="modal-footer"> </slot>
        </div>
      </div>
    </Drawer>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
    };
  },
  props: {
    row: {
      type: Object,
    },
    modalSize: {
      type: String,
      default: 'lg',
    },
    title: {
      type: String,
      default: 'Message',
    },
    closeDisabled: {
      type: Boolean,
      default: false,
    },
    showOnMount: {
      type: Boolean,
      default: false,
    },
    bodyBackgroundColor: {
      type: String,
      default: null,
    },
  },

  methods: {
    show() {
      this.$refs.modalDialog.open();
      this.$refs.modalDialog.active = true;
      this.loading = false;
      /*
      $(this.$refs.modalDialog.el).modal({
        backdrop: 'static',
        keyboard: false,
      });
      $('.modal-backdrop').last().insertBefore(this.$refs.modalDialog.el);
      */
    },

    close(force) {
      if (this.closeDisabled && force !== true) {
        return;
      }
      this.$refs.modalDialog.close();
      this.$refs.modalDialog.active = false;
    },

    scrollTop(top) {
      return $(this.$refs.modalDialog.el).scrollTop(top);
    },
  },

  mounted() {
    /*
    $(this.$refs.modalDialog.el).appendTo('body');
    $(this.$refs.modalDialog.el).on('hidden.bs.modal', () => {
      if ($('[role=dialog].show').length !== 0) {
        $('body').addClass('modal-open');
      }
      this.$emit('closed');
    });
    if (this.showOnMount) {
      this.show();
    } */
  },

  beforeDestroy() {
    $(this.$refs.modalDialog.el).remove();
  },
};
</script>

<style lang="stylus" scoped>
@import url('https://fonts.googleapis.com/css?family=Quicksand');

.tabs-with-content {
  border: 0.052vw solid #e6e6e6;
  background-color: #f0f4f9;
  margin-left: -0.521vw;
  margin-right: -0.521vw;
  padding-left: 0.521vw;
  padding-right: 0.521vw;
  padding-top: 0.521vw;
}

h5 {
  font-family: Quicksand;
  font-weight: 600;
  font-size: 1.25vw;
  color: #152145;
  letter-spacing: 0;
  letter-spacing: 0;
  margin-left: 0px;
  margin-right: 0px;
  padding-left: 0px;
  padding-right: 0px;
  padding-top: 0px;
  margin: 0px;
}

.tab-text {
  font-family: Quicksand;
  font-weight: Medium;
  font-size: 0.729vw;
  color: #152145;
}

.table-title-bold {
  font-family: Quicksand;
  font-weight: 900;
  font-size: 0.781vw;
  color: #152145;
}
</style>

<template>
  <tr>
    <th class="text-nowrap text-left">
      <!-- no update on re-render otherwise -->
      <span class="d-none">{{ getTherapyPtFieldValue() }}</span>

      <span v-if="!therapyPt || !careProtocolMode" class="h-padding-right-s">
        {{ label }}
      </span>
      <label v-else class="h-label" :for="inputId"> {{ label }}: </label>
    </th>

    <td v-if="!therapyPt || !careProtocolMode" class="text-left">
      <span v-if="!value" class="h-secondary_shade_1"> &lt;No value&gt; </span>
      <span>{{ value }}</span>
    </td>

    <td v-else>
      <div class="row mx-0">
        <input
          v-if="!inputType || inputType === 'text'"
          type="text"
          class="h-input"
          :id="inputId"
          v-model="value"
        />
        <textarea
          v-if="inputType === 'textarea'"
          class="h-input"
          :id="inputId"
          v-model="value"
        />
      </div>
    </td>
  </tr>
</template>


<script>
export default {
  props: [
    'label',
    'inputType',
    'careProtocolMode',
    'therapyPt',
    'getTherapyPtFieldValue',
  ],

  data() {
    return {
      inputId: this.$uuid.v4(),
      value: this.getTherapyPtFieldValue(),
    };
  },

  updated() {
    if (this.value !== this.getTherapyPtFieldValue()) {
      this.value = this.getTherapyPtFieldValue();
    }
  },

  watch: {
    value() {
      this.$emit('input', this.value);
    },
  },
};
</script>


<template v-slot:modal-body>
  <div class="h-drawer-max79">
    <Drawer :direction="'right'" :exist="true" ref="modalDialog">
      <div class="h-drawer-card">
        <div class="h-drawer-card-header">
          <div class="h-main-drawer-card-header h-margin-bottom-l">
            <h3 class="h-h3 h-primary_shade_1">Care protocol</h3>
            <button
              type="button"
              class="close"
              aria-hidden="true"
              @click="close"
              :disabled="loading"
            >
              <img src="/assets/images/Collapse-drawer.svg" alt="" />
            </button>
          </div>
        </div>
        <div class="h-drawer-card-body">
          <div v-if="!loading">
            <div class="h-margin-bottom-l">
              <label
                class="h-label"
                for="editCareProtocolDialog-topTileDescription"
              >
                Name
              </label>
              <InputField
                id="editCareProtocolDialog-topTileDescription"
                type="text"
                v-model="name"
                :error="errors.name"
                @input="
                  () => {
                    errors.name = null;
                  }
                "
              />
            </div>

            <div class="h-margin-bottom-l">
              <label class="h-label">
                Hospital
              </label>
              <InputField
                id="editCareProtocolDialog-hospital"
                type="select"
                v-model="hospitalId"
                :error="errors.hospitalId"
                @input="
                  () => {
                    errors.hospitalId = null;
                  }
                "
                :choices="
                [[null, '--- select hospital ---'], ...hospitalChoices] ||
                [[null, '--- select hospital ---'],]"
              />
            </div>

            <div class="h-margin-bottom-l">
              <label class="h-label" for="editCareProtocolDialog-startdateType">
                Start date type
              </label>
              <InputField
                id="editCareProtocolDialog-startdateType"
                type="select"
                v-model="startDateType"
                :error="errors.startDateType"
                @input="
                  () => {
                    errors.startDateType = null;
                  }
                "
                :choices="[
                  [null, '--- select start date type ---'],
                  ['surgery_date', 'Surgery date'],
                  ['user_confirmed_at', 'User confirmed at'],
                ]"
              />
            </div>

            <div class="h-margin-bottom-l">
              <label class="h-label" for="editCareProtocolDialog-topTileHeader">
                Top title header
              </label>
              <InputField
                id="editCareProtocolDialog-topTileHeader"
                type="text"
                v-model="topTileHeader"
                :error="errors.topTileHeader"
                @input="
                  () => {
                    errors.topTileHeader = null;
                  }
                "
              />
            </div>

            <div class="h-margin-bottom-l">
              <label
                class="h-label"
                for="editCareProtocolDialog-topTileDescription"
              >
                Top title description
              </label>
              <InputField
                id="editCareProtocolDialog-topTileDescription"
                type="text"
                v-model="topTileDescription"
                :error="errors.topTileDescription"
                @input="
                  () => {
                    errors.topTileDescription = null;
                  }
                "
              />
            </div>

            <div class="h-margin-bottom-l">
              <label class="h-label" for="editCareProtocolDialog-topTileLink">
                Top title link
              </label>
              <InputField
                id="editCareProtocolDialog-topTileLink"
                type="text"
                v-model="topTileLink"
                :error="errors.topTileLink"
                @input="
                  () => {
                    errors.topTileLink = null;
                  }
                "
              />
            </div>

            <div class="h-margin-bottom-s">
              <InputField
                type="checkbox"
                v-model="sendDailyNotifications"
                :error="errors.sendDailyNotifications"
                @input="
                  () => {
                    errors.sendDailyNotifications = null;
                  }
                "
                id="editCareProtocolDialog-sendDailyNotifications"
                label="Send daily notifications"
              />
            </div>

            <div class="">
              <InputField
                type="checkbox"
                v-model="sendWeeklyNotifications"
                :error="errors.sendWeeklyNotifications"
                @input="
                  () => {
                    errors.sendWeeklyNotifications = null;
                  }
                "
                id="editCareProtocolDialog-sendWeeklyNotifications"
                label="Send weekly notifications"
              />
            </div>
          </div>
          <Loader v-if="loading" />
        </div>

        <div class="h-drawer-card-footer">
          <button
            type="button"
            class="h-btn h-btn-accent"
            @click="save"
            :disabled="loading"
          >
            Save
          </button>

          <button
            type="button"
            class="h-btn h-btn-dangerous h-margin-left-s"
            @click="close"
            :disabled="loading"
          >
            Close
          </button>
        </div>
      </div>
    </Drawer>
  </div>
</template>


<script>
import InputField from '@/components/InputField';
import Loader from '@/components/Loader';

export default {
  props: ['defaultCareProtocol', 'hospitalChoices', 'selectedHospitalId'],

  data() {
    return {
      careProtocol: null,
      hospitalId: this.selectedHospitalId,
      name: '',
      startDateType: null,
      topTileHeader: '',
      topTileDescription: '',
      topTileLink: '',
      sendDailyNotifications: null,
      sendWeeklyNotifications: null,
      loading: false,
      errors: {},
    };
  },

  watch: {
    selectedHospitalId(value) {
      this.hospitalId = value;
    },
  },

  methods: {
    show(careProtocol) {
      this.loading = false;
      this.careProtocol = careProtocol ?? this.defaultCareProtocol;
      this.hospitalId = this.selectedHospitalId || this.careProtocol?.hospital_id;
      this.name = this.careProtocol.name;
      this.startDateType = this.careProtocol.start_date_type;
      this.topTileHeader = this.careProtocol.tile_header;
      this.topTileDescription = this.careProtocol.tile_description;
      this.topTileLink = this.careProtocol.top_title_link;
      this.sendDailyNotifications = this.careProtocol.send_daily_notifications;
      this.sendWeeklyNotifications = this.careProtocol.send_weekly_notifications;
      this.errors = {};
      this.$refs.modalDialog.open();
      this.$refs.modalDialog.active = true;
    },

    close(force) {
      this.$refs.modalDialog.close(force);
      this.$refs.modalDialog.active = false;
    },

    async save() {
      if (!this.hospitalId) {
        this.errors.hospitalId = 'This field is required';
        $.notify('Select hospital first', {
          position: 'top center',
          className: 'error',
        });
        return;
      }
      this.loading = true;
      const response = await this.$api.updateCareProtocol(
        this.careProtocol.id ?? 0,
        null,
        {
          hospitalId: Number(this.hospitalId),
          name: this.name ?? '',
          startDateType: this.startDateType ?? '',
          topTileHeader: this.topTileHeader ?? '',
          topTileDescription: this.topTileDescription ?? '',
          topTileLink: this.topTileLink ?? '',
          sendDailyNotifications: this.sendDailyNotifications ?? false,
          sendWeeklyNotifications: this.sendWeeklyNotifications ?? false,
        },
      );
      if (response.status === 'error') {
        this.errors = response.errors;
        this.loading = false;
        return;
      }
      this.close(true);
      this.$emit('protocolUpdated', response.care_protocol);
    },
  },

  components: {
    InputField,
    Loader,
  },
};
</script>

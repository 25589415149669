<template>
  <div class="w-100 chatRoomSelector-wrapper">
    <div class="top-part">
      <div
        v-if="rooms"
        class="
          h-margin-bottom-l h-margin-top-l h-margin-left-l h-margin-right-l
        "
      >
        <InputField
          type="search"
          v-model="searchText"
          class="h-force-w-100"
          placeholder="Search patient..."
          dense
        />
      </div>
    </div>

    <div class="chat-list-wrapper h-inherit" v-if="rooms">
      <div>
        <RoomList
          v-if="!searchText"
          :rooms="rooms"
          :selectedRoom="selectedRoom"
          :key="draftRoomIds.join(',')"
          :draftRoomIds="draftRoomIds"
          @roomSelected="selectRoom"
          mode="normal"
          :noEntriesMessage="noEntriesMessage"
        />

        <div v-else>
          <RoomList
            :rooms="foundRooms"
            :selectedRoom="selectedRoom"
            :key="draftRoomIds.join(',')"
            :draftRoomIds="draftRoomIds"
            @roomSelected="newRoom"
            mode="new"
            :noEntriesMessage="noEntriesMessage"
          />
        </div>
      </div>
    </div>

    <Loader v-else />
  </div>
</template>

<script>
import Loader from '@/components/Loader';
import InputField from '@/components/InputField';
import RoomList from './RoomList';

export default {
  data() {
    return {
      searchText: '',
      searchLoading: false,
      foundRooms: [],
    };
  },

  computed: {
    noEntriesMessage() {
      if (this.searchText.length >= 2) {
        if (this.searchLoading) {
          return 'Loading...';
        }
        return 'Nothing found';
      }
      if (this.searchText.length === 1) {
        return 'Search text too short';
      }
      if (this.searchText.length >= 2) {
        return 'Nothing found';
      }
      return 'No entries';
    },
  },

  watch: {
    searchText() {
      if (this.searchText.length >= 2) {
        this.searchLoading = true;
        this.$api.searchChatRoom(this.searchText).then((result) => {
          this.searchLoading = false;
          this.foundRooms = result;
        });
      } else {
        this.foundRooms = [];
      }
    },
  },

  methods: {
    selectRoom(room) {
      this.$emit('roomSelected', room);
    },

    newRoom(room) {
      this.searchText = '';
      this.$emit('newRoom', room);
    },
  },

  components: {
    Loader,
    InputField,
    RoomList,
  },

  props: ['rooms', 'selectedRoom', 'draftRoomIds'],
};
</script>

<template>
  <div id="ORAssessment" class="RPMAssessment">
    <!-- Page-Title -->
    <div v-if="displayNoAssignedNursesMsgBox" class="MSG-loading">
      <div class="download-container">
        <img
          src="../../../public/assets/images/NoAssignedNurse.svg"
          alt="No Assigned Nurse"
          class="d-block h-margin-bottom-l"
        />
        <h3 class="h-h3 h-primary_shade_1">{{ msgBoxTitle }}</h3>
        <h5 class="h-h5 h-primary_shade_1 h-margin-top-s">
          {{ msgBoxExplanation }}
        </h5>

        <button class="h-btn h-btn-accent h-margin-top-l" @click="msgClose">
          Ok
        </button>
      </div>
    </div>
    <div class="h-add-patient h-padding-margin-xl h-mobile-padding-left-l h-mobile-padding-right-l">
      <div class="h-col-screen-75 h-col-mobile-12 h-margin-auto">
        <div class="" style="position: relative">
          <div class="" :class="{ 'd-none': loading }">
            <div
              v-if="formWasSaved === false"
              class="alert-mob alert alert-danger border-0 alert-dismissible alert-abs"
              role="alert"
            >
              Failed to save data. Please view the form to see errors.
            </div>
            <section class="Welcome-step text-center">
              <div class="h-welcom-center h-margin-bottom-l h-margin-top-l">
                <img
                  src="../../../public/assets/images/rtmassasment.svg"
                  alt="Opiods_risk"
                  class="h-survey-img h-margin-auto d-block h-margin-bottom-xm"
                />
                <h1 class="h-h3 h-primary_shade_1 h-text-center h-margin-bottom-s">
                  RPM/RTM Stratification
                </h1>
                <h4 class="h-font20 h-primary_shade_1 h-text-center h-margin-bottom-xm">
                  Please take this
                  <span class="h-accent">2 min</span> survey to help us identify your
                  <span class="h-dangerous">RPM/RTM Stratification</span>
                </h4>
                <span
                  class="h-d-block w-25 h-margin-auto h-margin-bottom-xm h-border-bottom"
                ></span>
                <section>
                  <div class="h-margin-bottom-l">
                    <h3 class=" h-font20 h-primary_shade_1 h-text-center h-margin-bottom-m">
                      Are you currently being monitored remotely by any other providers?
                      <h3 class="h-h4 h-secondary_shade_1">
                        (ie pt who have a Dexcom or other 24/7 glucose monitoring system or those
                        with cardiac monitoring)
                      </h3>
                    </h3>
                    <div class="h-d-flex justify-content-center yesNo50">
                      <div
                        class="h-custom-radio-btn h-margin-right-l"
                        :class="[{ 'h-radio-gray': !startPageQuestion.isMonitoredRemotely }]"
                      >
                        <div class="h-custom-checkbox">
                          <input
                            type="radio"
                            id="isMonitoredRemotelyYes"
                            class="custom-control-input"
                            value="yes"
                            v-model="startPageQuestion.isMonitoredRemotely"
                          />
                          <label
                            for="isMonitoredRemotelyYes"
                            class="custom-control-label cursor-pointer"
                            >Yes</label
                          >
                        </div>
                      </div>

                      <div
                        class="h-custom-radio-btn"
                        :class="[{ 'h-radio-gray': !startPageQuestion.isMonitoredRemotely }]"
                      >
                        <div class="h-custom-checkbox">
                          <input
                            type="radio"
                            id="isMonitoredRemotelyNo"
                            class="custom-control-input"
                            value="false"
                            v-model="startPageQuestion.isMonitoredRemotely"
                          />
                          <label
                            for="isMonitoredRemotelyNo"
                            class="custom-control-label cursor-pointer"
                            >No</label
                          >
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="h-margin-bottom-l">
                    <h3 class=" h-font20 h-primary_shade_1 h-text-center h-margin-bottom-m">
                      Do you have an android or apple smartphone that is older than 5 years old?
                    </h3>
                    <div class="h-d-flex justify-content-center yesNo50">
                      <div
                        class="h-custom-radio-btn h-margin-right-l"
                        :class="[{ 'h-radio-gray': !startPageQuestion.isOldSmartphone }]"
                      >
                        <div class="h-custom-checkbox">
                          <input
                            type="radio"
                            id="isOldSmartphoneYes"
                            class="custom-control-input"
                            value="yes"
                            v-model="startPageQuestion.isOldSmartphone"
                          />
                          <label
                            for="isOldSmartphoneYes"
                            class="custom-control-label cursor-pointer"
                            >Yes</label
                          >
                        </div>
                      </div>

                      <div
                        class="h-custom-radio-btn"
                        :class="[{ 'h-radio-gray': !startPageQuestion.isOldSmartphone }]"
                      >
                        <div class="h-custom-checkbox">
                          <input
                            type="radio"
                            id="isOldSmartphoneNo"
                            class="custom-control-input"
                            value="false"
                            v-model="startPageQuestion.isOldSmartphone"
                          />
                          <label for="isOldSmartphoneNo" class="custom-control-label cursor-pointer"
                            >No</label
                          >
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="h-margin-bottom-xm">
                    <h3 class=" h-font20 h-primary_shade_1 h-text-center h-margin-bottom-m">
                      Do you have commercial or medicare insurance other than the ones below?
                      <h3 class="h-h4 h-secondary_shade_1">
                        (verify with practice which policies they are accepting for RPM/RTM)
                      </h3>
                    </h3>
                    <div class="h-d-flex justify-content-center yesNo50">
                      <div
                        class="h-custom-radio-btn h-margin-right-l"
                        :class="[{ 'h-radio-gray': !startPageQuestion.isCommercialInsurance }]"
                      >
                        <div class="h-custom-checkbox">
                          <input
                            type="radio"
                            id="radioisCommercialInsuranceYes"
                            class="custom-control-input"
                            value="yes"
                            v-model="startPageQuestion.isCommercialInsurance"
                          />
                          <label
                            for="radioisCommercialInsuranceYes"
                            class="custom-control-label cursor-pointer"
                            >Yes</label
                          >
                        </div>
                      </div>

                      <div
                        class="h-custom-radio-btn"
                        :class="[{ 'h-radio-gray': !startPageQuestion.isCommercialInsurance }]"
                      >
                        <div class="h-custom-checkbox">
                          <input
                            type="radio"
                            id="radioisCommercialInsuranceNo"
                            class="custom-control-input"
                            value="false"
                            v-model="startPageQuestion.isCommercialInsurance"
                          />
                          <label
                            for="radioisCommercialInsuranceNo"
                            class="custom-control-label cursor-pointer"
                            >No</label
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                </section>

                <div style="height:16rem">
                  <h4
                    class="h-h4 h-dangerous h-margin-bottom-s h-text-center"
                    v-if="NotEligibleText"
                  >
                    Not eligible for RPP/RTM
                  </h4>
                </div>

                <button
                  type="button"
                  id="startBtn"
                  class="step-btn h-btn h-margin-bottom-l h-btn-gray"
                  :class="{
                    'h-btn-accent': !NotEligibleBtn
                  }"
                  :disabled="NotEligibleBtn"
                >
                  Continue
                </button>
              </div>
            </section>
            <form v-if="showForm">
              <div class="step-app-rpm h-hide" id="AddSurvey">
                <div class="step-content">
                  <div class="step-tab-panel" data-step="step1">
                    <section>
                      <h3 class="h-h3 h-primary_shade_1 h-margin-bottom-l h-text-center">
                        1. Enter patient information
                      </h3>
                      <div class="h-row h-margin-bottom-xl justify-content-center">
                        <div
                          class="h-input-w49 h-desktop-margin-right-2
                          h-col-mobile-12 h-margin-bottom-l"
                        >
                          <label class="h-label"> Facility: </label>
                          <HospitalSelector v-model="hospitalId" />
                        </div>

                        <div
                          class="h-input-w50 h-col-mobile-12 h-margin-bottom-xl
                          vue-phone-number-input"
                        >
                          <label class="h-label">Patient Name: </label>
                          <InputField
                            id="input_name"
                            type="text"
                            v-model="client.name"
                            :error="errors.name"
                            @input="
                              () => {
                                errors.name = null
                              }
                            "
                          />
                          <p id="inputNameError1" class="h-h5 h-dangerous h-margin-top-s h-hide">
                            Please enter the name
                          </p>
                        </div>
                      </div>
                      <div class="h-row h-margin-bottom-xl justify-content-center">
                        <div
                          class="h-input-w49 h-desktop-margin-right-2
                          h-col-mobile-12 h-margin-bottom-l"
                        >
                          <label class="h-label"> Phone +1 (234) 567-8900:</label>
                          <VuePhoneNumberInput
                            v-model="client.phone"
                            @update="updateFormattedPhone"
                          />
                          <div style="margin-top: 8px">
                            <InputField
                              label="Accepts SMS"
                              type="checkbox"
                              id="send_sms"
                              v-model="client.send_sms"
                            />
                          </div>
                        </div>

                        <div class="h-input-w50 h-col-mobile-12 h-margin-bottom-l">
                          <label class="h-label"> Date of birth:</label>
                          <input
                            id="DateOfBirthw"
                            v-cleave="{
                              date: true,
                              dateMin: '1900-01-01',
                              dateMax: '2300-01-01',
                              datePattern: ['m', 'd', 'Y']
                            }"
                            class="form-control h-input"
                            name="birthday"
                            type="text"
                            placeholder="mm/dd/yyyy"
                            v-model="client.date_of_birth"
                            :error="errors.date_of_birth"
                            @input="
                              () => {
                                errors.date_of_birth = null
                              }
                            "
                          />
                          <div class="invalid-feedback notDateOfBirth" style="display: none">
                            The entered date is not accepted
                          </div>
                        </div>
                      </div>
                      <div class="h-row h-margin-bottom-xl justify-content-center">
                        <div
                          class="h-input-w49 h-desktop-margin-right-2
                          h-col-mobile-12 h-margin-bottom-l"
                        >
                          <label class="h-label"> Physician: </label>
                          <InputField
                            type="select"
                            :choices="surgeonOptions"
                            v-model="client.surgeon_id"
                            :error="errors.surgeon_id"
                            @input="
                              () => {
                                errors.surgeon_id = null
                              }
                            "
                          />
                          <p id="inputNameError2" class="h-h5 h-dangerous h-margin-top-s h-hide">
                            Please enter patient provider
                          </p>
                        </div>

                        <div class="h-input-w50 h-col-mobile-12 h-margin-bottom-l">
                          <label class="h-label">
                            Assigned care provider:
                          </label>
                          <InputField
                            type="select"
                            :choices="nurseOptions"
                            v-model="client.assigned_nurse_id"
                            @change="assignNurse(client.assigned_nurse_id)"
                          />
                        </div>
                      </div>
                      <div class="h-row h-margin-bottom-xl justify-content-center">
                        <div
                          class="h-input-w49 h-desktop-margin-right-2
                            h-col-mobile-12 h-margin-bottom-l"
                        >
                          <label class="h-label"> Height (inches): </label>
                          <InputField
                            type="number"
                            v-model="client.height"
                            :error="errors.height"
                            @input="
                              () => {
                                errors.height = null
                              }
                            "
                          />
                          <p id="inputNameError3" class="h-h5 h-dangerous h-margin-top-s h-hide">
                            Please enter patient height
                          </p>
                        </div>

                        <div class="h-input-w50 h-col-mobile-12 h-margin-bottom-l">
                          <label class="h-label"> Weight (lbs): </label>
                          <InputField
                            type="number"
                            v-model="client.weight"
                            :error="errors.weight"
                            @input="
                              () => {
                                errors.weight = null
                              }
                            "
                          />
                          <p id="inputNameError4" class="h-h5 h-dangerous h-margin-top-s h-hide">
                            Please enter patient weight
                          </p>
                        </div>
                      </div>
                    </section>
                  </div>

                  <div class="step-tab-panel h-w75" data-step="step2">
                    <section>
                      <h3 class="h-font20 h-primary_shade_1 h-margin-bottom-l">
                        {{ rpmrtmStratificationQuestions[0].sections[0].questions[0].question }}
                      </h3>

                      <div
                        v-for="(option, index) in rpmrtmStratificationQuestions[0].sections[0]
                          .questions[0].options"
                        :key="option.name"
                        class="h-survey-checkbox h-margin-bottom-m"
                      >
                        <input
                          :id="option.name"
                          :name="option.groupName"
                          type="radio"
                          v-model="sectionSelectedOptions[0][index]"
                          @change="
                            logExclusiveAnswers(
                              $event,
                              option.section,
                              option.name,
                              option.value,
                              option.resetSection,
                              option.groupName,
                              option.shouldTriggerPatientCreation
                            )
                          "
                        />
                        <label :for="option.name">
                          <span>{{ option.id }}</span>
                          <p>{{ option.optionText }}</p>
                        </label>
                        <h6
                          v-if="option.explanatoryText.length > 0"
                          class="h-h6 h-secondary_shade_1 h-margin-top-s"
                        >
                          {{ option.explanatoryText }}
                        </h6>
                      </div>
                    </section>
                  </div>

                  <div class="step-tab-panel h-w75" data-step="step3">
                    <section>
                      <h3 class="h-font20 h-primary_shade_1 h-margin-bottom-l">
                        {{ rpmrtmStratificationQuestions[0].sections[1].questions[0].question }}
                      </h3>

                      <div
                        v-for="(option, index) in rpmrtmStratificationQuestions[0].sections[1]
                          .questions[0].options"
                        :key="option.name"
                        class="h-survey-checkbox h-margin-bottom-m"
                      >
                        <input
                          :id="option.name"
                          :name="option.groupName"
                          type="radio"
                          v-model="sectionSelectedOptions[1][index]"
                          @change="
                            logExclusiveAnswers(
                              $event,
                              option.section,
                              option.name,
                              option.value,
                              option.resetSection,
                              option.groupName,
                              option.shouldTriggerPatientCreation
                            )
                          "
                        />
                        <label :for="option.name">
                          <span>{{ option.id }}</span>
                          <p>{{ option.optionText }}</p>
                        </label>
                        <h6
                          v-if="option.explanatoryText.length > 0"
                          class="h-h6 h-secondary_shade_1 h-margin-top-s"
                        >
                          {{ option.explanatoryText }}
                        </h6>
                      </div>
                    </section>
                  </div>

                  <div class="step-tab-panel h-w75" data-step="step4">
                    <section>
                      <h3 class="h-font20 h-primary_shade_1 h-margin-bottom-l">
                        {{ rpmrtmStratificationQuestions[0].sections[2].questions[0].question }}
                      </h3>

                      <div
                        v-for="(option, index) in rpmrtmStratificationQuestions[0].sections[2]
                          .questions[0].options"
                        :key="option.name"
                        class="h-survey-checkbox h-margin-bottom-m"
                      >
                        <input
                          :id="option.name"
                          :name="option.groupName"
                          type="radio"
                          v-model="sectionSelectedOptions[2][index]"
                          @change="
                            logExclusiveAnswers(
                              $event,
                              option.section,
                              option.name,
                              option.value,
                              option.resetSection,
                              option.groupName,
                              option.shouldTriggerPatientCreation
                            )
                          "
                        />
                        <label :for="option.name">
                          <span>{{ option.id }}</span>
                          <p>{{ option.optionText }}</p>
                        </label>
                        <h6
                          v-if="option.explanatoryText.length > 0"
                          class="h-h6 h-secondary_shade_1 h-margin-top-s"
                        >
                          {{ option.explanatoryText }}
                        </h6>
                      </div>
                    </section>
                  </div>

                  <div class="step-tab-panel h-w75" data-step="step5">
                    <section>
                      <h3 class="h-font20 h-primary_shade_1 h-margin-bottom-l">
                        {{ rpmrtmStratificationQuestions[0].sections[3].questions[0].question }}
                      </h3>

                      <div
                        v-for="(option, index) in rpmrtmStratificationQuestions[0].sections[3]
                          .questions[0].options"
                        :key="option.name"
                        class="h-survey-checkbox h-margin-bottom-m"
                      >
                        <input
                          :id="option.name"
                          :name="option.groupName"
                          type="radio"
                          v-model="sectionSelectedOptions[3][index]"
                          @change="
                            logExclusiveAnswers(
                              $event,
                              option.section,
                              option.name,
                              option.value,
                              option.resetSection,
                              option.groupName,
                              option.shouldTriggerPatientCreation
                            )
                          "
                        />
                        <label :for="option.name">
                          <span>{{ option.id }}</span>
                          <p>{{ option.optionText }}</p>
                        </label>
                        <h6
                          v-if="option.explanatoryText.length > 0"
                          class="h-h6 h-secondary_shade_1 h-margin-top-s"
                        >
                          {{ option.explanatoryText }}
                        </h6>
                      </div>
                    </section>
                  </div>

                  <div class="step-tab-panel h-w75" data-step="step6">
                    <section>
                      <h3 class="h-font20 h-primary_shade_1 h-margin-bottom-l">
                        {{ rpmrtmStratificationQuestions[0].sections[4].questions[0].question }}
                      </h3>

                      <div
                        v-for="(option, index) in rpmrtmStratificationQuestions[0].sections[4]
                          .questions[0].options"
                        :key="option.name"
                        class="h-survey-checkbox h-margin-bottom-m"
                      >
                        <input
                          :id="option.name"
                          :name="option.groupName"
                          type="radio"
                          v-model="sectionSelectedOptions[5][index]"
                          @change="
                            logExclusiveAnswers(
                              $event,
                              option.section,
                              option.name,
                              option.value,
                              option.resetSection,
                              option.groupName,
                              option.shouldTriggerPatientCreation
                            )
                          "
                        />
                        <label :for="option.name">
                          <span>{{ option.id }}</span>
                          <p>{{ option.optionText }}</p>
                        </label>
                        <h6
                          v-if="option.explanatoryText.length > 0"
                          class="h-h6 h-secondary_shade_1 h-margin-top-s"
                        >
                          {{ option.explanatoryText }}
                        </h6>
                      </div>
                    </section>
                  </div>

                  <div class="step-tab-panel h-w75" data-step="step7">
                    <section>
                      <h3 class="h-font20 h-primary_shade_1 h-margin-bottom-l">
                        {{ rpmrtmStratificationQuestions[0].sections[5].questions[0].question }}
                      </h3>

                      <div
                        v-for="(option, index) in rpmrtmStratificationQuestions[0].sections[5]
                          .questions[0].options"
                        :key="option.name"
                        class="h-survey-checkbox h-margin-bottom-m"
                      >
                        <input
                          :id="option.name"
                          :name="option.groupName"
                          type="radio"
                          v-model="sectionSelectedOptions[5][index]"
                          @change="
                            logExclusiveAnswers(
                              $event,
                              option.section,
                              option.name,
                              option.value,
                              option.resetSection,
                              option.groupName,
                              option.shouldTriggerPatientCreation
                            )
                          "
                        />
                        <label :for="option.name">
                          <span>{{ option.id }}</span>
                          <p>{{ option.optionText }}</p>
                        </label>
                        <h6
                          v-if="option.explanatoryText.length > 0"
                          class="h-h6 h-secondary_shade_1 h-margin-top-s"
                        >
                          {{ option.explanatoryText }}
                        </h6>
                      </div>
                    </section>
                  </div>

                  <div class="step-tab-panel h-w75" data-step="step7.1">
                    <section>
                      <h3 class="h-font20 h-primary_shade_1 h-margin-bottom-l">
                        {{ rpmrtmStratificationQuestions[0].sections[6].questions[0].question }}
                      </h3>

                      <div class="h-survey-checkbox h-margin-bottom-m">
                        <InputField
                          type="select"
                          :choices="[
                            { value: null, title: '--- select ---' },
                            'Spondylosis',
                            'Degenerative Disk Disease',
                            'Neuropathic Pain',
                            'Spinal Stenosis',
                            'None of the above'
                          ]"
                          v-model="rtmSelectedDisease"
                          @change="conditionChange($event)"
                        />
                      </div>
                    </section>
                  </div>

                  <div class="step-tab-panel h-w75" data-step="step8">
                    <section>
                      <h3 class="h-font20 h-primary_shade_1 h-margin-bottom-l">
                        {{ rpmrtmStratificationQuestions[0].sections[7].questions[0].question }}
                      </h3>

                      <div
                        v-for="(option, index) in rpmrtmStratificationQuestions[0].sections[7]
                          .questions[0].options"
                        :key="option.name"
                        class="h-survey-checkbox h-margin-bottom-m"
                      >
                        <input
                          :id="option.name"
                          :name="option.groupName"
                          type="radio"
                          v-model="sectionSelectedOptions[7][index]"
                          @change="
                            logExclusiveAnswers(
                              $event,
                              option.section,
                              option.name,
                              option.value,
                              option.resetSection,
                              option.groupName,
                              option.shouldTriggerPatientCreation
                            )
                          "
                        />
                        <label :for="option.name">
                          <span>{{ option.id }}</span>
                          <p>{{ option.optionText }}</p>
                        </label>
                        <h6
                          v-if="option.explanatoryText.length > 0"
                          class="h-h6 h-secondary_shade_1 h-margin-top-s"
                        >
                          {{ option.explanatoryText }}
                        </h6>
                      </div>
                    </section>
                  </div>

                  <div class="step-tab-panel h-w75" data-step="step9">
                    <section>
                      <h3 class="h-font20 h-primary_shade_1 h-margin-bottom-l">
                        {{ rpmrtmStratificationQuestions[0].sections[8].questions[0].question }}
                      </h3>

                      <div
                        v-for="(option, index) in rpmrtmStratificationQuestions[0].sections[8]
                          .questions[0].options"
                        :key="option.name"
                        class="h-survey-checkbox h-margin-bottom-m"
                      >
                        <input
                          :id="option.name"
                          :name="option.groupName"
                          type="radio"
                          v-model="sectionSelectedOptions[8][index]"
                          @change="
                            logExclusiveAnswers(
                              $event,
                              option.section,
                              option.name,
                              option.value,
                              option.resetSection,
                              option.groupName,
                              option.shouldTriggerPatientCreation
                            )
                          "
                        />
                        <label :for="option.name">
                          <span>{{ option.id }}</span>
                          <p>{{ option.optionText }}</p>
                        </label>
                        <h6
                          v-if="option.explanatoryText.length > 0"
                          class="h-h6 h-secondary_shade_1 h-margin-top-s"
                        >
                          {{ option.explanatoryText }}
                        </h6>
                      </div>
                    </section>
                  </div>

                  <div class="step-tab-panel h-w75" data-step="step9.1">
                    <section>
                      <h3 class="h-font20 h-primary_shade_1 h-margin-bottom-l">
                        {{ rpmrtmStratificationQuestions[0].sections[9].questions[0].question }}
                      </h3>

                      <div
                        v-for="(option, index) in rpmrtmStratificationQuestions[0].sections[9]
                          .questions[0].options"
                        :key="option.name"
                        class="h-survey-checkbox h-margin-bottom-m"
                      >
                        <input
                          :id="option.name"
                          :name="option.groupName"
                          type="radio"
                          v-model="sectionSelectedOptions[9][index]"
                          @change="
                            logExclusiveAnswers(
                              $event,
                              option.section,
                              option.name,
                              option.value,
                              option.resetSection,
                              option.groupName,
                              option.shouldTriggerPatientCreation
                            )
                          "
                        />
                        <label :for="option.name">
                          <span>{{ option.id }}</span>
                          <p>{{ option.optionText }}</p>
                        </label>
                        <h6
                          v-if="option.explanatoryText.length > 0"
                          class="h-h6 h-secondary_shade_1 h-margin-top-s"
                        >
                          {{ option.explanatoryText }}
                        </h6>
                      </div>
                    </section>
                  </div>

                  <div class="step-tab-panel step10" data-step="step10">
                    <section id="LOWscore" ref="LowScoreSection">
                      <div class="text-center">
                        <div class="text-center h-margin-bottom-s">
                          <i
                            v-if="shouldPatientBeCreated"
                            class="h-accent h-finish-icon fas fa-check-circle"
                          ></i>
                          <i
                            v-if="!shouldPatientBeCreated"
                            class="h-dangerous h-finish-icon fas fa-times-circle"
                          ></i>
                        </div>
                        <h2
                          v-if="shouldPatientBeCreated"
                          style="line-height: 1.1"
                          class="h-font20 h-primary_shade_1 h-text-center h-margin-bottom-l"
                        >
                          Patient has qualified for remote monitoring based on the results shown
                          below.
                        </h2>
                        <h2
                          v-if="!shouldPatientBeCreated"
                          style="line-height: 1.1"
                          class="h-font20 h-primary_shade_1 h-text-center h-margin-bottom-l"
                        >
                          Patient has not qualified for remote monitoring based on the results shown
                          below.
                        </h2>
                        <div>
                          <table class="h-results-table">
                            <tr>
                              <td>
                                <h4 class="h-h4 h-primary_shade_1">
                                  Patient qualifies for RPM
                                </h4>
                              </td>
                              <td>
                                <h4
                                  class="h-h4 h-accent"
                                  :class="{
                                    'h-dangerous': !this.shouldPatientQualifyForRPM
                                  }"
                                >
                                  {{ this.shouldPatientQualifyForRPM ? 'Yes' : 'No' }}
                                </h4>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <h4 class="h-h4 h-primary_shade_1">
                                  A Patient chart will be created
                                </h4>
                              </td>
                              <td>
                                <h4
                                  class="h-h4 h-accent"
                                  :class="{
                                    'h-dangerous': !this.shouldPatientBeCreated
                                  }"
                                >
                                  {{ this.shouldPatientBeCreated ? 'Yes' : 'No' }}
                                </h4>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <h4 class="h-h4 h-primary_shade_1">
                                  RTM questions displayed?
                                </h4>
                              </td>
                              <td>
                                <h4
                                  class="h-h4 h-accent"
                                  :class="{
                                    'h-dangerous': !this.shouldRTMQuestionsComeNext
                                  }"
                                >
                                  {{ this.shouldRTMQuestionsComeNext ? 'Yes' : 'No' }}
                                </h4>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <h4 class="h-h4 h-primary_shade_1">
                                  Patient qualifies for RTM
                                </h4>
                              </td>
                              <td>
                                <h4
                                  class="h-h4 h-accent"
                                  :class="{
                                    'h-dangerous': !this.shouldPatientQualifyForRTM
                                  }"
                                >
                                  {{ this.shouldPatientQualifyForRTM ? 'Yes' : 'No' }}
                                </h4>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <h4 class="h-h4 h-primary_shade_1">
                                  Order physical activity
                                </h4>
                              </td>
                              <td>
                                <h4
                                  class="h-h4 h-accent"
                                  :class="{
                                    'h-dangerous': !this.shouldPhysicalActivityMonitoringAdded
                                  }"
                                >
                                  {{ this.shouldPhysicalActivityMonitoringAdded ? 'Yes' : 'No' }}
                                </h4>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <h4 class="h-h4 h-primary_shade_1">
                                  Uses step counter with 1500 steps daily goal
                                </h4>
                              </td>
                              <td>
                                <h4
                                  class="h-h4 h-accent"
                                  :class="{
                                    'h-dangerous': !this.shouldStepCounterBeEnabled
                                  }"
                                >
                                  {{ this.shouldStepCounterBeEnabled ? 'Yes' : 'No' }}
                                </h4>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <h4 class="h-h4 h-primary_shade_1">
                                  The Pt receive TENS therapy
                                </h4>
                              </td>
                              <td>
                                <h4
                                  class="h-h4 h-accent"
                                  :class="{
                                    'h-dangerous': !this.isTensTherapyReceived
                                  }"
                                >
                                  {{ this.isTensTherapyReceived ? 'Yes' : 'No' }}
                                </h4>
                              </td>
                            </tr>
                            <tr v-if="this.rtmSelectedDisease">
                              <td>
                                <h4 class="h-h4 h-primary_shade_1">
                                  Patient condition
                                </h4>
                              </td>
                              <td>
                                <h4 class="h-h4 h-primary_shade_1">
                                  {{ this.rtmSelectedDisease }}
                                </h4>
                              </td>
                            </tr>
                            <tr v-if="this.selectedCareProtocol">
                              <td>
                                <h4 class="h-h4 h-primary_shade_1">
                                  Selected protocol
                                </h4>
                              </td>
                              <td>
                                <h4 class="h-h4 h-primary_shade_1">
                                  {{ this.selectedCareProtocol }}
                                </h4>
                              </td>
                            </tr>
                            <tr v-if="assignedNurseProgramDiscrepancyFixInProgress">
                              <td>
                                <h4 class="h-h4 h-primary_shade_1">
                                  Assigned Care provider
                                </h4>
                              </td>
                              <td class="h-padding-0 h-custom-select-border-0">
                                <InputField
                                  type="select"
                                  :choices="nurseOptions"
                                  v-model="selectedNurse"
                                  @change="assignNurse(selectedNurse)"
                                />
                              </td>
                            </tr>
                          </table>
                        </div>
                        <div v-if="shouldPatientBeCreated" class="h-d-flex justify-content-center">
                          <div class="h-margin-top-l">
                            <h3 class=" h-font20 h-primary_shade_1 h-text-center h-margin-bottom-m">
                              Turn on all default patient reported outcome surveys
                            </h3>
                            <div class="h-d-flex justify-content-center">
                              <div
                                :class="{
                                  'h-margin-right-l': index === 0,
                                  'h-radio-gray': !assessments_off_selected
                                }"
                                class="h-custom-radio-btn"
                                v-for="(option, index) in options"
                                :key="index"
                              >
                                <div class="h-custom-checkbox">
                                  <input
                                    type="radio"
                                    :id="index"
                                    :value="option"
                                    class="custom-control-input"
                                    v-model="assessments_off_selected"
                                  />
                                  <label :for="index" class="custom-control-label cursor-pointer">{{
                                    option
                                  }}</label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                  </div>
                </div>

                <div class="step-footer">
                  <div class="h-text-center h-2btn-gap-l h-margin-top-l">
                    <button
                      id="restartBtn2"
                      v-if="isThisFinalScreen"
                      type="button"
                      :disabled="shouldRestartButtonBeDisabled"
                      :class="{
                        'disable-input': shouldRestartButtonBeDisabled
                      }"
                      class="h-btn h-btn-dangerous"
                      @click="restartHandler"
                    >
                      Restart
                    </button>
                    <button
                      id="CreateAccountBtn"
                      type="button"
                      class="h-btn h-btn-accent"
                      v-if="shouldPatientBeCreated"
                      :disabled="shouldCreateButtonBeDisabled || assessments_off_selected === null"
                      :class="{
                        'h-disabled-button':
                          shouldCreateButtonBeDisabled || assessments_off_selected === null
                      }"
                      @click="showAgreementPopup"
                    >
                      Continue
                    </button>
                  </div>

                  <div class="h-text-center h-2btn-gap-l">
                    <button
                      v-if="!isThisFinalScreen"
                      id="restartBtn"
                      type="button"
                      class="step-btn h-btn h-btn-dangerous"
                      @click="restartHandler"
                    >
                      Restart
                    </button>
                    <button
                      v-if="!isThisFinalScreen"
                      type="button"
                      id="nextBtn"
                      :disabled="shouldNextButtonBeDisabled"
                      :class="{
                        'h-disabled-button': shouldNextButtonBeDisabled
                      }"
                      @click="nextHandler"
                      class="step-btn h-btn h-btn-accent"
                    >
                      Next
                    </button>
                  </div>
                  <div v-if="!isThisFinalScreen">
                    <ul class="step-steps h-margin-top-l">
                      <li data-step-target="step1">Step 1</li>
                      <li data-step-target="step2">Step 2</li>
                      <li data-step-target="step3">Step 3</li>
                      <li data-step-target="step4">Step 4</li>
                      <li data-step-target="step5">Step 5</li>
                      <li data-step-target="step6">Step 6</li>
                      <li data-step-target="step7">Step 7</li>
                      <li data-step-target="step7.1">Step 8</li>
                      <li data-step-target="step8">Step 9</li>
                      <li data-step-target="step9">Step 10</li>
                      <li data-step-target="step9.1">Step 11</li>
                      <li data-step-target="step10">Step 12</li>
                    </ul>
                  </div>
                </div>
              </div>
            </form>
          </div>

          <div v-if="loading" class="card-body loader-h">
            <Loader />
          </div>
        </div>
      </div>
    </div>

    <div v-if="showAgreement" class="popup-agreement" :class="{ 'd-none': loading }">
      <div class="popup-agreement-content">
        <Consent />
        <InputField
          label="I agree"
          type="checkbox"
          id="is_Accepted"
          class="h-margin-left-l h-margin-bottom-l h-margin-top-l"
          v-model="is_Accepted"
          @click="isAccepted"
        />

        <div class="text-center justify-content-center">
          <!-- <button
                    type="button"
                    id="closeCreatePatient"
                    class="h-btn h-btn-dangerous w-auto"
        @click="HideAgreementPopup"
                  >
                    Close
              </button> -->
          <button
            type="button"
            id="CreatePatient"
            class="h-btn h-btn-accent w-auto min120 h-margin-left-l"
            :disabled="!this.is_Accepted"
            :class="{ 'disable-input': !this.is_Accepted }"
            @click="SaveBtn"
          >
            Create Patient Account
          </button>
          <div class="h-mob-120">Patients</div>
        </div>
      </div>
    </div>
    <div v-if="confirmation" :class="{ 'd-none': loading }" class="confirmation text-center">
      <div class="h-confirmation-center">
        <i v-if="saveOperationErrors" class="h-dangerous h-finish-icon fas fa-times-circle"></i>
        <i v-else class="h-accent h-confirmation-icon fas fa-check-circle"></i>
        <h4 class="h-h2 h-primary_shade_1 h-text-center h-margin-bottom-xl">
          {{
            saveOperationErrors
              ? 'An error ocurred while saving the patient record'
              : 'New patient account successfully created'
          }}
        </h4>
        <button
          type="button"
          id="fBtn"
          class="step-btn h-btn h-btn-accent h-margin-left-s"
          @click="finishBtn"
        >
          Finish
        </button>
      </div>
    </div>
  </div>
</template>

<style>
body {
  overflow-x: hidden;
}
</style>

<script>
import Vue from 'vue';
import moment from 'moment';
import VuePhoneNumberInput from 'vue-phone-number-input';
import settings from '@/settings.js';
import Loader from '@/components/Loader';
import InputField from '@/components/InputField';
import 'vue-phone-number-input/dist/vue-phone-number-input.css';
import { rpmrtmStratificationQuestions } from './utils';
import Consent from './Consent';
import HospitalSelector from '@/components/HospitalSelector';

export default {
  name: 'RPMRTMStratification',

  data() {
    return {
      startPageQuestion: {
        isMonitoredRemotely: null,
        isOldSmartphone: null,
        isCommercialInsurance: null,
      },

      hospitalId: null,

      questionAnswers: [],
      sectionSelectedOptions: [[], []],

      rpmrtmStratificationQuestions,

      displayNoAssignedNursesMsgBox: false,

      surgeonId: null,
      surgeonOptions: [],

      assignedNurseId: null,

      nurseOptions: [],

      physiotherapistId: null,
      physiotherapistOptions: [],
      newPatient: true,
      loading: true,

      client: {
        name: '',
        phone: '',
        send_sms: true,
        date_of_birth: '',
        height: '',
        weight: '',
        surgeon_id: '',
        assigned_nurse_id: null,
        care_protocol: {
          id: null,
        },
      },
      formattedPhone: '',
      savedEmail: null,
      errors: {},
      clientSet: false,
      photoUrl: '',
      removePhoto: false,
      genderChoices: [['Female', 'Female']],

      accountStatusChoices: [
        ['Pending activation', 'Pending activation'],
        ['Active', 'Active'],
        ['Inactive', 'Inactive'],
      ],
      accountStatus: null,
      careProtocols: [],
      formWasSaved: null,
      moment,
      showNurseSelector: settings.hasRole('nurse_administrator'),
      msgBoxTitle: 'No care provider',
      msgBoxExplanation:
        'Current hospital does not have care providers so this assessment can�t be completed now. Please assign at least one nurse that has allowed patients.',
      msgBoxDialogShouldCloseWindow: true,
      steps_api: null,
      shouldPatientBeCreated: false,
      shouldPatientReceiveMedEfficacyWeeklyMedComplianceDaily: false,
      shouldPatientQualifyForRPM: false,
      shouldPatientQualifyForRTM: false,
      shouldNextQuestionBeDisplayed: true,
      shouldPhysicalActivityMonitoringAdded: false,
      shouldStepCounterBeEnabled: true,
      isTensTherapyReceived: false,
      shouldAssessmentEndAfterThisQuestion: false,
      shouldRTMQuestionsComeNext: false,
      rtmSelectedDisease: null,
      monitoring_program: 'RPM',
      selectedCareProtocol: '',
      isThisFinalScreen: false,
      questionsAnswered: [],
      currentQuestion: -1,
      currentQuestionAnswered: true,
      is_Accepted: false,
      showAgreement: false,
      confirmation: false,
      showForm: true,
      selectedNurse: null,
      assignedNurseProgramDiscrepancy: false,
      assignedNurseProgramDiscrepancyFixInProgress: false,
      nurseData: null,
      saveOperationErrors: false,
      assessments_off_selected: null,
      options: ['Yes', 'No'],
    };
  },

  created() {
    this.sectionSelectedOptions = new Array(10).fill(0).map(() => new Array(2).fill(0));
    this.questionsAnswered = new Array(8).fill(false);
  },
  async beforeMount() {
    const response = await this.$api.getAssignedNursesInCurrentHospital();
    if (!response.assigned_nurses_in_current_hospital) {
      this.displayNoAssignedNursesMsgBox = true;
    }
  },

  async mounted() {
    await this.onRouteChange();
    $('#AddSurvey').steps({});
    this.steps_api = $('#AddSurvey')
      .steps({})
      .data('plugin_Steps');
    $(document).ready(() => {
      $('#startBtn').on('click', () => {
        $('.Welcome-step').addClass('h-hide');
        $('#AddSurvey').removeClass('h-hide');
      });
      $('#DateOfBirth').inputmask();
    });
  },

  watch: {
    async $route(to, from) {
      // await this.onRouteChange();
    },

    accountStatus() {
      if (this.accountStatus === 'Active') {
        this.client.pending_activation = false;
        this.client.is_archived = false;
      }

      if (this.accountStatus === 'Inactive') {
        this.client.is_archived = true;
        this.client.pending_activation = false;
      }
      if (this.accountStatus === 'Pending activation') {
        this.client.pending_activation = true;
        this.client.is_archived = false;
      }
    },

    hospitalId() {
      this.onRouteChange(this.hospitalId);
    },
  },
  computed: {
    NotEligibleText() {
      return (
        this.startPageQuestion.isMonitoredRemotely === 'yes' ||
        this.startPageQuestion.isOldSmartphone === 'yes' ||
        this.startPageQuestion.isCommercialInsurance === 'yes'
      );
    },
    NotEligibleBtn() {
      return (
        this.startPageQuestion.isMonitoredRemotely === 'yes' ||
        this.startPageQuestion.isOldSmartphone === 'yes' ||
        this.startPageQuestion.isCommercialInsurance === 'yes' ||
        this.startPageQuestion.isMonitoredRemotely === null ||
        this.startPageQuestion.isOldSmartphone === null ||
        this.startPageQuestion.isCommercialInsurance === null
      );
    },

    shouldListOfNursesBeDisplayedOnFinalPage() {
      return this.assignedNurseProgramDiscrepancyFixInProgress;
    },
    shouldCreateButtonBeDisabled() {
      return this.selectedNurse == null && this.assignedNurseProgramDiscrepancy;
    },
    pageTitle() {
      return this.$route.meta.title;
    },
    isAdmin() {
      return settings.hasRole('system_administrator');
    },
    shouldRestartButtonBeDisabled() {
      return !(this.currentQuestion >= 0);
    },
    shouldNextButtonBeDisabled() {
      const nameLength = this.client.name ? this.client.name.length : 0;
      const phoneLength = this.client.phone ? this.client.phone.length : 0;

      const dateOfBirthLength = moment(this.client.date_of_birth).format('mm-dd-yyyy')
        ? moment(this.client.date_of_birth).format('mm-dd-yyyy').length
        : 0;

      const weightLength = this.client.weight ? this.client.weight.length : 0;
      const heightLength = this.client.height ? this.client.height.length : 0;

      const providerLength = this.client.surgeon_id ? this.client.surgeon_id : 0;

      const disableButtons = !(
        nameLength > 0 &&
        phoneLength >= 10 &&
        dateOfBirthLength > 0 &&
        weightLength > 0 &&
        heightLength > 0 &&
        providerLength > 0 &&
        this.currentQuestionAnswered
      );

      return disableButtons;
    },
  },

  methods: {
    assignNurse(id) {
      this.selectedNurse = this.nurseOptions.filter(nurse => nurse.value === Number(id));
      this.client.assigned_nurse_id = id;
      if (this.assignedNurseProgramDiscrepancy) this.assignedNurseProgramDiscrepancy = false;
    },
    isAccepted() {
      if (this.is_Accepted === true) {
        this.is_Accepted = false;
      } else {
        this.is_Accepted = true;
      }
    },
    showAgreementPopup() {
      this.showAgreement = true;
      this.showForm = false;
    },
    /* HideAgreementPopup(){
  this.showAgreement=false;
  this.showForm=true;
}, */
    conditionChange(event) {
      if (event.target.value === '') {
        this.currentQuestionAnswered = false;
      } else {
        this.currentQuestionAnswered = true;
      }
    },
    moveFromQuestionToQuestion(start, end) {
      for (let step = start; step < end; step += 1) {
        this.steps_api.next();
      }
    },
    restartHandler() {
      Vue.$confirm({
        title: 'Restart',
        message: 'This will restart the assessment',
        button: {
          yes: 'Yes',
          no: 'No',
        },
        callback: async (confirm) => {
          if (confirm) this.$router.go();
        },
      });
    },
    async nextHandler() {
      const currentQuestion = this.steps_api.getStepIndex();
      this.currentQuestion = currentQuestion;

      if (this.currentQuestion === 0) {
        const dateReceived = moment(this.client.date_of_birth).format('YYYY-MM-DD');
        if (dateReceived === 'Invalid date') {
          this.isDisabled = true;

          $.notify(`${this.client.date_of_birth} is an invalid date`, {
            position: 'top center',
            className: 'error',
          });
          return;
        }
      }

      if (this.currentQuestion >= 0) this.currentQuestionAnswered = false;

      let question0 = false;
      let question1 = false;
      let question2 = false;
      let question3 = false;
      let question4 = false;
      let question5 = false;
      let question6 = false;
      let question7 = false;
      let question8 = false;
      let question9 = false;

      const Q01 = this.questionAnswers.find(q => q.id === 'Q01');
      const Q02 = this.questionAnswers.find(q => q.id === 'Q02');

      if (Q01) {
        question0 = true;
      }

      if (Q02) {
        question0 = false;
      }

      const Q1 = this.questionAnswers.find(q => q.id === 'Q1');
      const Q2 = this.questionAnswers.find(q => q.id === 'Q2');

      if (Q1) {
        question1 = true;
      }

      if (Q2) {
        question1 = false;
      }

      const Q10 = this.questionAnswers.find(q => q.id === 'Q10');
      const Q11 = this.questionAnswers.find(q => q.id === 'Q11');

      if (Q10) {
        question2 = true;
      }

      if (Q11) {
        question2 = false;
      }

      const Q20 = this.questionAnswers.find(q => q.id === 'Q20');
      const Q21 = this.questionAnswers.find(q => q.id === 'Q21');

      if (Q20) {
        question3 = true;
      }

      if (Q21) {
        question3 = false;
      }

      const Q30 = this.questionAnswers.find(q => q.id === 'Q30');
      const Q31 = this.questionAnswers.find(q => q.id === 'Q31');

      if (Q30) {
        question4 = true;
      }

      if (Q31) {
        question4 = false;
      }

      const Q050 = this.questionAnswers.find(q => q.id === 'Q050');
      const Q051 = this.questionAnswers.find(q => q.id === 'Q051');

      if (Q050) {
        question5 = true;
      }

      if (Q051) {
        question5 = false;
      }

      const Q40 = this.questionAnswers.find(q => q.id === 'Q40');
      const Q41 = this.questionAnswers.find(q => q.id === 'Q41');

      if (Q40) {
        question6 = true;
      }

      if (Q41) {
        question6 = false;
      }

      const Q50 = this.questionAnswers.find(q => q.id === 'Q50');
      const Q51 = this.questionAnswers.find(q => q.id === 'Q51');

      if (Q50) {
        question7 = true;
        this.SCS = true;
      }

      if (Q51) {
        question7 = false;
      }

      const Q60 = this.questionAnswers.find(q => q.id === 'Q60');
      const Q61 = this.questionAnswers.find(q => q.id === 'Q61');

      if (Q60) {
        question8 = true;
      }

      if (Q61) {
        question8 = false;
      }

      const Q70 = this.questionAnswers.find(q => q.id === 'Q70');
      const Q71 = this.questionAnswers.find(q => q.id === 'Q71');

      if (Q70) {
        question9 = true;
      }

      if (Q71) {
        question9 = false;
      }

      // QUESTION 1. Does the pt take Lyrica or Gabapentin along with one or more of the...
      if (currentQuestion === 1) {
        if (question0) {
          this.shouldPatientBeCreated = true;
          this.shouldPatientQualifyForRPM = true;
          this.shouldPatientReceiveMedEfficacyWeeklyMedComplianceDaily = true;
          this.shouldNextQuestionBeDisplayed = false;
          this.shouldAssessmentEndAfterThisQuestion = true;
        }
      }

      // QUESTION 2. Does the pt have an intrathecal pain pump with opioid medications...

      // QUESTION 3. Does the Pt take daily opioid medications for their pain other than...
      if (currentQuestion === 3) {
        if (question1) {
          if (question2) {
            this.shouldPatientBeCreated = true;
            this.shouldPatientQualifyForRPM = true;
            this.shouldPatientReceiveMedEfficacyWeeklyMedComplianceDaily = true;
            this.shouldNextQuestionBeDisplayed = false;
            this.shouldAssessmentEndAfterThisQuestion = true;
          } else {
            this.shouldPatientBeCreated = true;
            this.shouldPatientQualifyForRPM = true;
            this.shouldPatientReceiveMedEfficacyWeeklyMedComplianceDaily = false;
            this.shouldNextQuestionBeDisplayed = false;
            this.shouldAssessmentEndAfterThisQuestion = true;
          }
        } else if (!question2) {
          this.shouldPatientBeCreated = true;
          this.shouldPatientQualifyForRPM = false;
          this.shouldPatientQualifyForRTM = true;
          this.shouldPatientReceiveMedEfficacyWeeklyMedComplianceDaily = false;
          this.shouldNextQuestionBeDisplayed = false;
          this.shouldRTMQuestionsComeNext = true;
        }
      }

      // QUESTION 4. Does the Pt take more than one mind altering medications...
      if (currentQuestion === 4) {
        if (question3) {
          this.shouldPatientBeCreated = true;
          this.shouldPatientQualifyForRPM = true;
          this.shouldPatientReceiveMedEfficacyWeeklyMedComplianceDaily = true;
          this.shouldNextQuestionBeDisplayed = false;
          this.shouldAssessmentEndAfterThisQuestion = true;
        }
      }

      // QUESTION 5. Does the Pt have a history of Chronic Pulmonary Disease...
      if (currentQuestion === 5) {
        if (question4) {
          this.shouldPatientBeCreated = true;
          this.shouldPatientQualifyForRPM = true;
          this.shouldPatientReceiveMedEfficacyWeeklyMedComplianceDaily = true;
          this.shouldNextQuestionBeDisplayed = false;
          this.shouldAssessmentEndAfterThisQuestion = true;
        } else {
          this.shouldPatientReceiveMedEfficacyWeeklyMedComplianceDaily = false;
          this.shouldNextQuestionBeDisplayed = false;
          this.shouldPatientQualifyForRPM = false;
          this.shouldPatientBeCreated = false;
          this.shouldRTMQuestionsComeNext = true;
        }
      }
      console.log(currentQuestion, this.isTensTherapyReceived, question5, this.selectedNurse);
      // QUESTION 6 (RTM). Does the Pt receive TENS therapy?
      if (currentQuestion === 6) {
        this.shouldPatientQualifyForRTM = true;
        this.shouldNextQuestionBeDisplayed = true;
        this.shouldPatientBeCreated = true;
        this.isTensTherapyReceived = question5;
      }

      // QUESTION 7 (RTM). Does the pt have any of the following conditions?
      if (currentQuestion === 7) {
        if (question6) {
          this.shouldPatientQualifyForRTM = true;
          this.shouldNextQuestionBeDisplayed = true;
          this.shouldPatientBeCreated = true;
        }

        if (!question6) {
          this.shouldNextQuestionBeDisplayed = true;
        }
      }

      // QUESTION 8 (RTM)
      if (currentQuestion === 8) {
        if (question8) {
          this.shouldPatientQualifyForRTM = true;
          this.shouldNextQuestionBeDisplayed = false;
          this.shouldPatientBeCreated = true;
          this.shouldAssessmentEndAfterThisQuestion = true;
        }

        if (!question7) {
          this.shouldPatientQualifyForRTM = false;
          this.shouldPatientBeCreated = false;
          this.shouldNextQuestionBeDisplayed = true;
        }
      }

      // QUESTION 9 (RTM)
      if (currentQuestion === 9) {
        if (question8) {
          this.shouldPatientReceiveMedEfficacyWeeklyMedComplianceDaily = true;
          this.shouldNextQuestionBeDisplayed = true;
          this.shouldPatientQualifyForRTM = true;
          this.shouldPatientBeCreated = true;
        }

        if (!question8) {
          this.shouldPatientReceiveMedEfficacyWeeklyMedComplianceDaily = false;
          this.shouldNextQuestionBeDisplayed = true;
        }
      }

      // QUESTION 10 (RTM)
      if (currentQuestion === 10) {
        if (question9) {
          this.shouldNextQuestionBeDisplayed = true;
          this.shouldPhysicalActivityMonitoringAdded = true;
          this.shouldPatientQualifyForRTM = true;
          this.shouldPatientBeCreated = true;
        }

        if (!question9) {
          this.shouldNextQuestionBeDisplayed = true;
          this.shouldPhysicalActivityMonitoringAdded = false;
        }
      }

      if (this.shouldPatientQualifyForRPM) this.monitoring_program = 'RPM';

      if (this.shouldPatientQualifyForRTM) this.monitoring_program = 'RTM';

      if (this.shouldNextQuestionBeDisplayed) this.steps_api.next();

      if (this.shouldRTMQuestionsComeNext) {
        this.shouldRTMQuestionsComeNext = false;
        Vue.$confirm({
          title: 'RPM/RTM Qualification',
          message:
            'Based on the answers, the patient does not qualify for RPM. Do you wish to continue to assess the patient for RTM?',
          button: {
            yes: 'Yes',
            no: 'No',
          },
          callback: async (confirm) => {
            if (confirm) {
              this.moveFromQuestionToQuestion(currentQuestion, 6);
            } else {
              this.shouldStepCounterBeEnabled = false;
              this.moveFromQuestionToQuestion(currentQuestion, 11);
            }
          },
        });
      }

      if (this.shouldAssessmentEndAfterThisQuestion) {
        this.moveFromQuestionToQuestion(currentQuestion, 11);
      }

      this.currentQuestion = currentQuestion;

      if (this.steps_api.getStepIndex() === 11) {
        this.rpmrtmStratificationQuestions[0].sections[0].questions[0].selectedAnswer = question0
          ? 'Yes'
          : 'No';
        this.rpmrtmStratificationQuestions[0].sections[1].questions[0].selectedAnswer = question1
          ? 'Yes'
          : 'No';
        this.rpmrtmStratificationQuestions[0].sections[2].questions[0].selectedAnswer = question2
          ? 'Yes'
          : 'No';
        this.rpmrtmStratificationQuestions[0].sections[3].questions[0].selectedAnswer = question3
          ? 'Yes'
          : 'No';
        this.rpmrtmStratificationQuestions[0].sections[4].questions[0].selectedAnswer = question4
          ? 'Yes'
          : 'No';
        this.rpmrtmStratificationQuestions[0].sections[5].questions[0].selectedAnswer = question5
          ? 'Yes'
          : 'No';
        this.rpmrtmStratificationQuestions[0].sections[6].questions[0].selectedAnswer = question6
          ? 'Yes'
          : 'No';
        this.rpmrtmStratificationQuestions[0].sections[7].questions[0].selectedAnswer = question7
          ? 'Yes'
          : 'No';
        this.rpmrtmStratificationQuestions[0].sections[8].questions[0].selectedAnswer = question8
          ? 'Yes'
          : 'No';
        this.rpmrtmStratificationQuestions[0].sections[9].questions[0].selectedAnswer = question9
          ? 'Yes'
          : 'No';
        this.rpmrtmStratificationQuestions[0].qualifiesForRPM = this.shouldPatientQualifyForRPM
          ? 'Yes'
          : 'No';
        this.rpmrtmStratificationQuestions[0].qualifiesForRTM = this.shouldPatientQualifyForRTM
          ? 'Yes'
          : 'No';
        this.rpmrtmStratificationQuestions[0].medicationEfficiencyQuestionnaireOnceWeekly = this
          .shouldPatientReceiveMedEfficacyWeeklyMedComplianceDaily
          ? 'Yes'
          : 'No';
        this.rpmrtmStratificationQuestions[0].medicationComplianceDaily = this
          .shouldPatientReceiveMedEfficacyWeeklyMedComplianceDaily
          ? 'Yes'
          : 'No';
        this.rpmrtmStratificationQuestions[0].shouldPhysicalActivityMonitoringAdded = this
          .shouldPhysicalActivityMonitoringAdded
          ? 'Yes'
          : 'No';
        this.rpmrtmStratificationQuestions[0].shouldStepCounterBeEnabled = this
          .shouldStepCounterBeEnabled
          ? 'Yes'
          : 'No';
        this.rpmrtmStratificationQuestions[0].selectedCondition = this.rtmSelectedDisease;

        this.rpmrtmStratificationQuestions[0].isTensTherapyReceived = this.isTensTherapyReceived;

        this.isThisFinalScreen = true;

        if (this.monitoring_program === 'RPM' && this.selectedNurse != null) {
          if (!this.selectedNurse[0].isRPM) {
            this.assignedNurseProgramDiscrepancy = true;
            this.assignedNurseProgramDiscrepancyFixInProgress = true;
            this.selectedNurse = null;

            this.nurseOptions = this.nurseData
              .filter(nurse => nurse.allow_rpm)
              .map((nurse) => {
                let name = nurse.name;
                const marks = [];
                if (nurse.allow_rpm) marks.push('RPM');
                if (nurse.allow_rtm) marks.push('RTM');
                if (marks.length) {
                  name = `${nurse.name} (${marks.join(', ')})`;
                }
                return {
                  value: nurse.id,
                  title: name,
                  isRPM: nurse.allow_rpm,
                  isRTM: nurse.allow_rtm,
                };
              });
            this.nurseOptions.unshift([null, '--- Auto ---']);
          }
        }

        if (this.monitoring_program === 'RTM' && this.selectedNurse != null) {
          console.log('this.selectedNurse', this.selectedNurse);
          if (!this.selectedNurse[0].isRTM) {
            this.assignedNurseProgramDiscrepancy = true;
            this.assignedNurseProgramDiscrepancyFixInProgress = true;
            this.selectedNurse = null;

            this.nurseOptions = this.nurseData
              .filter(nurse => nurse.allow_rtm)
              .map((nurse) => {
                let name = nurse.name;
                const marks = [];
                if (nurse.allow_rpm) marks.push('RPM');
                if (nurse.allow_rtm) marks.push('RTM');
                if (marks.length) {
                  name = `${nurse.name} (${marks.join(', ')})`;
                }
                return {
                  value: nurse.id,
                  title: name,
                  isRPM: nurse.allow_rpm,
                  isRTM: nurse.allow_rtm,
                };
              });
            this.nurseOptions.unshift([null, '--- Auto ---']);
          }
        }

        if (this.assignedNurseProgramDiscrepancy) {
          Vue.$confirm({
            title: 'RPM/RTM Qualification',
            message:
              'Based on the patient program qualification you need to select the care provider accordingly',
            button: {
              yes: 'Ok',
            },
          });
        }

        if (this.shouldPatientBeCreated) {
          if (this.shouldPatientQualifyForRPM || this.shouldPatientQualifyForRTM) {
            this.selectedCareProtocol = this.shouldPatientQualifyForRPM ? 'RPM-General' : 'RTM-';

            if (this.shouldPatientQualifyForRPM) {
              this.selectedCareProtocol += '-SpO2';
            }

            if (this.shouldPatientQualifyForRTM) {
              this.selectedCareProtocol +=
                this.rtmSelectedDisease !== 'None of the above'
                  ? this.rtmSelectedDisease.replaceAll(' ', '_')
                  : 'Default';
            }

            this.selectedCareProtocol += this
              .shouldPatientReceiveMedEfficacyWeeklyMedComplianceDaily
              ? '-Medications'
              : '-No_Medications';

            if (this.shouldPatientQualifyForRTM) {
              this.selectedCareProtocol += this.shouldPhysicalActivityMonitoringAdded
                ? '-PT'
                : '-No_PT';
            }

            if (this.shouldPatientQualifyForRPM) {
              this.selectedCareProtocol += '-No_PT';
            }

            this.rpmrtmStratificationQuestions[0].careProtocol = this.selectedCareProtocol;
          }
        }
      }
    },
    msgClose() {
      this.displayNoAssignedNursesMsgBox = false;
      if (this.msgBoxDialogShouldCloseWindow) window.close();
    },
    finishBtn() {
      this.$router.go();
    },
    /*     async assessments_off_selected () {
      if (this.assessments_off_selected) {
        assessments_off_selected = false
      } else {
        assessments_off_selected = true
      }
    }, */
    async SaveBtn() {
      console.log('saving');
      this.loading = true;
      await this.save();
      console.log(this.client.date_of_birth);
      console.log('final format');
      console.log(moment(this.client.date_of_birth).format('YYYY-MM-DD'));
      this.showAgreement = false;
      this.loading = false;
    },
    async updateFormattedPhone(info) {
      this.formattedPhone = info.formattedNumber;
    },
    logExclusiveAnswers(
      e,
      section,
      question,
      value,
      resetSection = false,
      groupName = '',
      shouldTriggerPatientCreation = false,
    ) {
      this.questionAnswers = this.questionAnswers.filter(item => item.groupName !== groupName);

      const index = this.questionAnswers.findIndex(entry => entry.id === e.target.id);

      if (index === -1) {
        this.questionAnswers.push({
          id: e.target.id,
          checked: e.target.checked,
          section,
          question,
          value,
          groupName,
          shouldPatientBeCreated: shouldTriggerPatientCreation,
        });
      } else {
        this.questionAnswers[index].checked = e.target.checked;
      }

      this.questionAnswers.forEach((q, i) => {
        this.sectionSelectedOptions[section][i] = q.id === e.target.id;
      });

      this.questionsAnswered[this.currentQuestion] = true;
      this.currentQuestionAnswered = true;
    },
    logAnswers(e, section, question, value, resetSection = false) {
      if (resetSection && e.target.checked) {
        this.sectionSelectedOptions[section] = [];
        this.rpmrtmStratificationQuestions[0].sections[section].questions[0].options.forEach(
          (option) => {
            this.sectionSelectedOptions[section].push(option.resetSection);
          },
        );

        this.questionAnswers = this.questionAnswers.filter(item => item.section !== section);
      } else {
        // eslint-disable-next-line no-restricted-syntax
        for (const [idx, option] of this.rpmrtmStratificationQuestions[0].sections[
          section
        ].questions[0].options.entries()) {
          if (option.resetSection) {
            this.sectionSelectedOptions[section][idx] = false;
            const elementToReset = this.questionAnswers.findIndex(
              element => element.id === option.name,
            );
            if (elementToReset >= 0) {
              this.questionAnswers[elementToReset].checked = false;
            }
          }
        }
      }

      const index = this.questionAnswers.findIndex(entry => entry.id === e.target.id);
      if (index === -1) {
        this.questionAnswers.push({
          id: e.target.id,
          checked: e.target.checked,
          section,
          question,
          value,
        });
      } else {
        this.questionAnswers[index].checked = e.target.checked;
      }
    },
    async save() {
      this.errors = {};
      this.formWasSaved = false;

      if (this.newPatient) {
        this.client.pending_activation = true;
      }
      const surveyAnswers = {
        RPMRTMStratificationSection: this.rpmrtmStratificationQuestions,
      };
      const payload = {
        hospital_id: this.hospitalId,
        name: this.client.name,
        email: '',
        phone: this.formattedPhone,
        send_sms: this.client.send_sms || this.client.send_sms === undefined,
        date_of_birth: moment(this.client.date_of_birth).format('YYYY-MM-DD'),
        gender: 'Female',
        height: this.client.height,
        weight: this.client.weight,
        surgeon_id: this.client.surgeon_id,
        assigned_nurse_id: this.selectedNurse ? this.selectedNurse[0].value : null,
        program: this.client.program,
        physiotherapist_id: null,
        care_protocol_id: 24,
        plan_start_date: '2022-04-30',
        plan_end_date: '2100-07-31',
        diagnoses: [],
        is_archived: false,
        pending_activation: true,
        medication_use_template: '',
        care_protocol_received: this.selectedCareProtocol,
        monitoring_program: this.monitoring_program,
        assessment_type: 'RPM_RTM',
        osord_value: 0,
        medd_value: 0,
        rtm_rpm_stratification_survey: true,
        risk_tool_answers: surveyAnswers,
        scs: this.SCS,
        assessments_off: this.assessments_off_selected,
      };

      const response = await this.$api.upsertClientWith_RPM_RTM_Survey(payload);

      if (response.status === 'ok') {
        this.formWasSaved = true;
        this.loading = false;
        this.confirmation = true;
        this.saveOperationErrors = false;
      } else {
        this.formWasSaved = false;
        this.errors = response.errors;
        this.loading = false;
        this.confirmation = true;
        this.saveOperationErrors = true;
      }
    },

    cancel() {
      this.$router.push({
        name: 'Dashboard',
      });
    },

    async setClient(loadedClient) {
      // this.client = loadedClient;
      this.client = {
        name: 'name',
        phone: '+12345567677',
        send_sms: true,
        date_of_birth: '2010-10-10',
        height: '70',
        weight: '80',
        surgeon_id: 3047,
        assigned_nurse_id: null,
        care_protocol: {
          id: null,
        },
      };
      if (this.client.email) {
        if (this.client.email.indexOf('@nomail.healent.com') !== -1) {
          this.client.email = '';
        }
      }
      //   this.photoUrl = this.client.profile;
      this.surgeonId = this.client.surgeon ? this.client.surgeon.id : null;
      this.assignedNurseId = this.client.assigned_nurse ? this.client.assigned_nurse.id : null;
      this.physiotherapistId = this.client.physiotherapist ? this.client.physiotherapist.id : null;
      // this.removePhoto = false;

      if (this.client.care_protocol) {
        delete this.client.care_protocol.name;
      } else {
        this.client.care_protocol = { id: null };
      }

      if (loadedClient.is_archived) {
        this.accountStatus = 'Inactive';
      } else {
        this.accountStatus = 'Active';
      }

      if (loadedClient.pending_activation) this.accountStatus = 'Pending activation';

      if (!loadedClient.id) {
        if (settings.hasRole('surgeon', true)) {
          this.surgeonId = settings.currentSession.user.id;
        }
        if (settings.hasRole('physiotherapist', true)) {
          this.physiotherapistId = settings.currentSession.user.id;
        }
      }
    },

    async onRouteChange(switchHospitalToId) {
      if (this.$route.params.id) {
        window.execAfterInitMenuInterface(() => {
          window.initActiveMenu('left-navigation-link>hospital>patients>add-patient', false);
        });
        this.setClient(await this.$api.getClient(this.$route.params.id));
      } else {
        window.execAfterInitMenuInterface(() => {
          window.initActiveMenu('left-navigation-link>hospital>patients>add-patient');
        });
        this.setClient({});
        this.newPatient = true;
        // this.accountStatus === 'Pending activation';
      }

      this.hospitalId = switchHospitalToId || this.client.hospital_id;
      if (!this.hospitalId) {
        const hospitals = await this.$api.getHospitalsList();
        this.hospitalId = hospitals[0].id;
      }
      const hospitalId = this.hospitalId;

      const responseCareProtocols = await this.$api.get(
        `${settings.BACKEND_URL}/api/v-nurse/care-protocol/hospital`,
        {
          hospitalID: hospitalId,
        },
      );
      const careProtocols = responseCareProtocols.care_protocols.map((protocol) => {
        return [protocol.id, protocol.name];
      });
      careProtocols.unshift([null, '']);
      this.careProtocols = careProtocols;

      const surgeonsData = await this.$api.searchSeargeon({
        hospital_id: hospitalId,
      });
      this.surgeonOptions = surgeonsData.map((surgeon) => {
        return {
          value: surgeon.id,
          title: surgeon.name,
        };
      });
      this.surgeonOptions.unshift([null, '--- Select Provider ---']);

      this.nurseData = await this.$api.searchNurse({
        hospital_id: hospitalId,
      });

      this.nurseOptions = this.nurseData.map((nurse) => {
        let name = nurse.name;
        const marks = [];
        if (nurse.allow_rpm) marks.push('RPM');
        if (nurse.allow_rtm) marks.push('RTM');
        if (marks.length) {
          name = `${nurse.name} (${marks.join(', ')})`;
        }
        return {
          value: nurse.id,
          title: name,
          isRPM: nurse.allow_rpm,
          isRTM: nurse.allow_rtm,
        };
      });
      this.nurseOptions.unshift([null, '--- Auto ---']);

      const physiotherapistData = await this.$api.searchPhysiotherapist({
        hospital_id: hospitalId,
      });
      this.physiotherapistOptions = physiotherapistData.map((physiotherapist) => {
        return {
          value: physiotherapist.id,
          title: physiotherapist.name,
        };
      });
      this.physiotherapistOptions.unshift([null, '--- Select physiotherapist ---']);

      this.loading = false;
    },
  },
  components: {
    Loader,
    InputField,
    VuePhoneNumberInput,
    Consent,
    HospitalSelector,
  },
};
</script>

<template>
  <div class="h-bg-gray h-col-desktop-10 h-col-mobaile-12">
    <div
      v-if="loadingOptions"
      id="headerBox1"
      class="h-padding-left-l h-padding-right-l h-padding-bottom-l h-bg-w text-center text-muted"
      style="font-size: 1.5em"
    >
      Please wait...
    </div>
    <div v-if="displayReportDownloadingMsgBox" class="MSG-loading">
      <div class="download-container">
        <div class="downloadAnim">
          <lottie-animation path="./assets/js/lottie_files/GeneratingReport.json" />
        </div>

        <h3 class="h-h3 h-primary_shade_1">{{ defaultDialogBoxTitle }}</h3>
        <h5 v-if="longJobToDownload" class="h-h5 h-primary_shade_1 h-margin-top-s">
          This may take several minutes. You can keep working and track the generating progress from
          the upper bar of the application
        </h5>
        <h5 v-if="openDownloadDialogToQueryForStats" class="h-h5 h-primary_shade_1 h-margin-top-s">
          <span class="h-accent">159</span> of 488 Reports Processed,
          <span class="h-dangerous">(15 Error)</span>
        </h5>
        <button class="h-btn h-btn-accent h-margin-top-l" @click="msgClose">Ok</button>
      </div>
    </div>
    <div v-if="!loadingOptions" id="headerBox" class="h-padding-left-l h-bg-w">
      <div class="h-row">
        <div
          class="h-col-mobile-12 h-mobile-margin-top-l
            h-mobile-margin-left-0 h-border-right-0"
        >
          <label class="h-label">Month start: </label>
          <input id="monthStart" type="Month" v-model="monthStart" class="h-input" />
        </div>

        <div
          class="h-col-mobile-12
                  h-mobile-margin-top-l
                  h-mobile-margin-left-0
                  h-border-left-0"
        >
          <label class="h-label">Month end:</label>
          <input id="monthEnd" type="Month" v-model="monthEnd" class="h-input" />
        </div>
        <div>
          <button
            class="h-btn h-btn-accent h-margin-top-l h-margin-left-l
                h-mobile-margin-top-l h-mobile-margin-left-0"
            @click="generateReport">
            Refresh report
          </button>
        </div>
        <div
          class="h-2btn-flex h-col h-text-right h-mobile-margin-top-l
            h-mobile-margin-left-0 h-mobile-text-center"
        >
            <div class="h-icon-tooltip">
            <img
              src="../../../../../../public/assets/images/Refresh report.svg"
              class="h-report-icon h-padding-left-s"
              @click="recalculateReport('monthly')" />
            <label class="h-margin-top-l"> Recalculate monthly report </label>
          </div>
        </div>
      </div>
    </div>
    <TaskStatus reportCode="hospital" />

    <SelectorDialogInDrawer
      ref="toggleColumnsDialog"
      :allOptions="allTableColumns"
      :selectedOptions="visibleTableColumns"
      :getOptionKey="
        option => {
          return option.name;
        }
      "
      :getOptionText="
        option => {
          return option.text;
        }
      "
      windowTitle="Toggle table columns"
      listTitle="Table table columns to show"
      @toggleOption="toggleColumnVisible"
    />

    <DownloadAllReportsDrawer ref="downloadAllReportsDrawer" />
    <StartTaskDialog
      ref="startTaskDialog"
      reportCode="monthly"
      :month="getMonth"
      :monthStart="monthStart"
      :monthEnd="monthEnd"
      :hospitalId="this.hospitalId"
      v-on:startProcessing="startProcessing"
      v-on:endProcessing="endProcessing"
    />
  </div>
</template>

<style>
div.loading {
  font-family: "Roboto", sans-serif;
  font-weight: bold;
  color: #2b5adc;
}
</style>

<script>
import moment from 'moment';
import TaskStatus from '../TaskStatus';
import StartTaskDialog from '../StartTaskDialog';
import settings from '@/settings.js';
import SelectorDialogInDrawer from '@/components/dialogs/SelectorDialogInDrawer';
import eventBus from '../../../../../event-bus';
import DownloadAllReportsDrawer from '@/components/reports/DownloadAllReportsDrawer';

const TABLE_COLUMNS_KEY = 'TABLE_COLUMNS_KEY';
export default {
  data() {
    return {
      loadingOptions: true,
      errors: {},
      hospitals: [],
      hospitalId: null,
      metrics: [],
      clientIdsToIncludeInZipDownload: [],
      monthStart: moment()
        .startOf('month')
        .format('YYYY-MM'),
      monthEnd: moment()
        .endOf('month')
        .format('YYYY-MM'),
      showOnlyEligible: false,
      msg: 'Report is being downloaded now',
      html: '<i class="fa fa-cog fa-spin fa-3x fa-fw"></i>',
      downloadingReport: false,
      downloadingLongNumberOfReports: false,
      displayReportDownloadingMsgBox: false,
      openDownloadDialogToQueryForStats: false,
      longReportDownloadJobThreshold: 3,
      datatableProcessingDone: false,
      singleReportDownload: false,
      defaultDialogBoxTitle: 'Report is being generated',
      allTableColumns: [],
      visibleTableColumns: [],
      loading: true,
    };
  },

  computed: {
    reportListIsEmpty() {
      return this.clientIdsToIncludeInZipDownload <= 0;
    },
    hospitalChoices() {
      const choices = this.hospitals.map((hospital) => {
        return [hospital.id, hospital.name];
      });
      choices.sort((a, b) => a[1].localeCompare(b[1]));
      choices.unshift([null, '--- select hospital ---']);
      return choices;
    },
    allDownloadEnabled() {
      return this.monthStart && this.monthEnd && this.clientIdsToIncludeInZipDownload.length > 0;
    },
    getMonth() {
      if (this.reportDateStart) {
        return moment(this.reportDateStart).month() + 1;
      }
      return moment().month() + 1;
    },
  },

  watch: {
    monthStart() {
      const monthStart = this.monthStart;
      eventBus.$emit('reportDateStartChanged', { monthStart });
    },

    monthEnd() {
      const monthEnd = this.monthEnd;
      eventBus.$emit('reportDateEndChanged', { monthEnd });
    },

    showOnlyEligible() {
      this.reload();
    },
  },

  methods: {
    startProcessing() {
      this.datatableProcessingDone = false;
      this.$emit('startProcessing');
    },

    endProcessing() {
      this.datatableProcessingDone = true;
      this.$emit('endProcessing');
    },

    allReportsPushStatusChanged() {
      this.$emit('allReportsPushStatusChanged');
    },

    async scheduleSftpReportPush() {
      if (!this.datatableProcessingDone) {
        $.notify('Report list is still being populated.', {
          position: 'top center',
          className: 'error',
        });
      }
    },

    toggleColumnVisible(column, visible) {
      if (visible) {
        this.visibleTableColumns.push(column);
      } else {
        const index = this.visibleTableColumns.indexOf(column);
        this.visibleTableColumns.splice(index, 1);
      }

      localStorage.setItem(TABLE_COLUMNS_KEY, JSON.stringify(this.visibleTableColumns));

      const dtColumn = this.dataTable.column(this.allTableColumns.indexOf(column));
      dtColumn.visible(!dtColumn.visible());
    },

    msgClose() {
      this.displayReportDownloadingMsgBox = false;
    },

    recalculateReport() {
      this.$refs.startTaskDialog.show();
    },

    recalculateReport(reportCode) {
      this.startTaskDialogReportCode = reportCode;
      this.$refs.startTaskDialog.show();
    },

    generateReport() {
      eventBus.$emit('monthlyReportRefresh', { monthStart });
    },
  },

  async mounted() {
    const that = this;
    window.test = this;

    const [hospitals, metrics] = await Promise.all([
      this.$api.getHospitalsList(),
      this.$api.get(`${settings.BACKEND_URL}/api/v-nurse/reports/monthly_hospital_metrics`),
    ]);
    this.metrics = metrics;
    this.hospitals = hospitals;
    this.loadingOptions = false;
    await this.$forceUpdate();

    eventBus.$on(
      'downloadFileOperationEnded',
      (event) => {
        this.downloadingReport = false;
        this.displayReportDownloadingMsgBox = false;
      },
      this,
    );
  },
  beforeDestroy() {
    eventBus.$offOwner(this);
  },
  components: {
    SelectorDialogInDrawer,
    DownloadAllReportsDrawer,
    TaskStatus,
    StartTaskDialog,
  },
};
</script>

<template>
  <div>
    <div v-if="effectiveRoles.length !== 0">
      <div class="entry-wrapper" v-for="role of effectiveRoles" :key="role">
        <div class="h-margin-bottom-s">
          <InputField
            type="toggle"
            :value="isRoleEnabled(role)"
            :label="getRoleDisplay(role)"
            :id="'StaffMemberEnabledRoles::' + index + '::' + role"
            @input="value => setRoleEnabled(role, value)"
            :disabled="readonly || disabledRole(role)"
          />
        </div>

        <!-- <div v-if="role === 'nurse_administrator'">
          One such role per hospital, if assinged to another user,
          they will be the only user with this role for the hospitals.
        </div> -->
      </div>
      <div v-if="errors" class="h-dangerous">
        {{ errors }}
      </div>
    </div>
    <div class="list-none text-muted" v-else>No roles available.</div>
  </div>
</template>

<script>
import strUtils from '@/utils/str_utils';
import InputField from '@/components/InputField';

let lastIndex = 0;
function getNextIndex() {
  lastIndex += 1;
  return lastIndex;
}

export default {
  props: [
    'roles',
    'enabledRoles',
    'readonly',
    'errors',
    'administratorRoleReadOnly',
    'systemAdministratorRoleReadOnly',
    'displayRoles',
  ],

  data() {
    return {
      initialEnabledRoles: JSON.parse(JSON.stringify(this.enabledRoles)),
      index: getNextIndex(),
    };
  },

  computed: {
    effectiveRoles() {
      if (this.roles !== null) return this.roles;
      return this.displayRoles.map(entry => entry.name);
    },
  },

  methods: {
    getRoleDisplay(role) {
      return strUtils.underscoreCaseToName(this.getRoleDisplayName(role));
    },

    isRoleEnabled(role) {
      return !!this.enabledRoles.find(enabledRole => role === enabledRole);
    },

    setRoleEnabled(role, enabled) {
      const index = this.enabledRoles.indexOf(role);
      if (!enabled) {
        if (index !== -1) {
          this.enabledRoles.splice(index, 1);
        }
      } else if (index === -1) {
        this.enabledRoles.push(role);
      }
      this.$emit('input');
    },

    disabledRole(role) {
      if (role === 'nurse_administrator') {
        const initialEnabled = !!this.initialEnabledRoles.find(r => r === 'nurse_administrator');
        if (initialEnabled) {
          return true;
        }
      }

      if (role === 'system_administrator' && this.systemAdministratorRoleReadOnly) {
        return true;
      }
      return role === 'nurse_administrator' && this.administratorRoleReadOnly;
    },

    getRoleDisplayName(role) {
      const displayRole = this.displayRoles.find(dRole => dRole.name === role);
      if (displayRole) return displayRole.display_name;
      return role;
    },
  },

  components: {
    InputField,
  },
};
</script>

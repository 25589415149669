<template>
  <div class="h-drawer-max79">
    <SlottedMessageInDrawer
      ref="slottedMessage"
      title="Edit record"
      :row="this.row"
      :closeDisabled="loading"
    >
      <template v-slot:modal-body>
        <div v-if="!loading" class="h-margin-l">
          <label class="h-label">New value</label>
          <InputField
            :type="inputType"
            :step="inputStep"
            :min="inputMin"
            :max="inputMax"
            placeholder="New value"
            class="h-input"
            @input="validateInputValue"
            v-model="newValue"
            :allowPasting="false"
            @keypress="keymonitor"
          />
        </div>
        <div v-if="!loading" class="h-margin-left-l h-margin-right-l">
          <label class="h-label">Comment</label>
          <textarea
            type="text"
            class="h-input"
            v-model="NewComment"
            :allowPasting="true"
            @input="isUserEditing = true"
            @blur="handleBlur"
          />
        </div>
        <Loader v-else />
      </template>

      <template v-slot:modal-footer>
        <button
          type="button"
          class="h-btn h-btn-accent"
          @click="updateRecord"
          :disabled="updateButtonDisabled"
        >
          Update
        </button>

        <button
          type="button"
          class="h-btn h-btn-dangerous h-margin-left-s"
          @click="close"
          :disabled="loading"
        >
          Cancel
        </button>
      </template>
    </SlottedMessageInDrawer>
  </div>
</template>

<script>
import settings from '@/settings.js';
import Loader from '@/components/Loader';
import SlottedMessageInDrawer from '@/components/dialogs/SlottedMessageInDrawer';
import InputField from '@/components/InputField';

export default {
  data() {
    return {
      loading: true,
      row: null,
      e: null,
      dataTable: null,
      anId: null,
      newValue: null,
      NewComment: null,
      oldValue: null,
      oldComment: null,
      isUserEditing: false,
    };
  },
  computed: {
    updateButtonDisabled() {
      return this.newValue?.length <= 0 || this.loading;
    },
    inputType() {
      return this.anId.title === 'Quality of sleep' ? 'number' : 'text';
    },
    inputStep() {
      return this.anId.title === 'Quality of sleep' ? 1 : 0.01;
    },
    inputMin() {
      return this.anId.title === 'Quality of sleep' ? 1 : 0;
    },
    inputMax() {
      return this.anId.title === 'Quality of sleep'
        ? 5
        : Number.MAX_SAFE_INTEGER;
    },
  },
  watch: {
    NewComment(newVal, oldVal) {
      if (!this.isUserEditing && newVal !== oldVal) {
        this.NewComment = this.processValue(newVal);
      }
    },
  },
  methods: {
    validateInputValue() {
      const painScore = parseInt(this.newValue, 10);
      switch (this.anId.title) {
        case 'Quality of sleep':
          if (
            this.newValue < 1 ||
            this.newValue > 5 ||
            !Number.isInteger(parseFloat(this.newValue))
          ) {
            this.newValue = '';
            $.notify(
              'Please enter a valid value between 1 to 5 for Quality of sleep',
              {
                position: 'top center',
                className: 'error',
              },
            );
          }
          break;
        case 'Pain Score':
          if (painScore < 1 || painScore > 10 || isNaN(painScore)) {
            this.newValue = '';
            $.notify(
              'Please enter a valid value between 1 and 10 for Pain Score',
              {
                position: 'top center',
                className: 'error',
              },
            );
          }
          break;

        default:
          if (!isFinite(this.newValue) || parseFloat(this.newValue) < 0) {
            this.newValue = '';
            $.notify('Please enter a valid value', {
              position: 'top center',
              className: 'error',
            });
          }
      }
    },

    handleBlur() {
      this.isUserEditing = false;
    },
    processValue(value) {
      if (!value) return '';
      const strValue = value.toString();
      value = strValue.replace(/from-mobile|\[from-mobile/g, '').trim();
      const re = /\[(.*?)\]/g;
      value = value.replace(re, (_match, p1) => `\n${p1}\n`);
      return value.trim();
    },
    keymonitor(evt) {
      evt = evt || window.event;
      const charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        evt.preventDefault();
      } else {
        return true;
      }
      return false;
    },
    async show(e, row, dataTable, anId) {
      this.loading = false;
      this.row = row;
      this.e = e;
      this.anId = anId;
      this.dataTable = dataTable;
      this.newValue = row.row[1];
      this.NewComment = row.row[2];
      this.oldValue = row.row[1];
      this.oldComment = row.row[2];
      this.$refs.slottedMessage.show();
    },

    close(force) {
      this.$refs.slottedMessage.close(force);
    },

    async updateRecord() {
      if (this.newValue === 0 || this.newValue === null) {
        $.notify('Please enter a valid value', {
          position: 'top center',
          className: 'error',
        });
        return;
      }
      if (!this.newValue) {
        $.notify('Please enter a valid value', {
          position: 'top center',
          className: 'error',
        });
        return;
      }
      this.loading = true;

      // Check if NewComment is empty or null, if it is, set it to "-"
      const commentToEncode = this.NewComment ? this.NewComment : '-';

      const encodedComment = encodeURIComponent(commentToEncode);

      const response = await this.$api.get(
        `${settings.BACKEND_URL}/api/v-nurse/checklist/update-record/${this.row.record.id}/${this.newValue}/${encodedComment}`,
      );
      if (response.status === 'ok') {
        this.$emit('shouldReload');
      } else {
        $.notify('Failed to update the record', {
          position: 'top center',
          className: 'error',
        });
      }
      this.close(true);
    },
  },

  beforeDestroy() {
    if (this.dataTable) {
      this.dataTable.destroy(true);
      this.dataTable = null;
    }
  },

  components: {
    Loader,
    SlottedMessageInDrawer,
    InputField,
  },

  props: ['record'],
};
</script>

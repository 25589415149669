<template>
  <div>
    <div class="h-padding-l" v-dragscroll.y="false">
      <div class="h-d-flex">
        <div class="h-margin-right-l h-margin-bottom-l">
          <label class="h-label"> Assessment Name: </label>
          <InputField
            v-model="new_assessment_name"
            type="text"
            style="min-width: 200rem; display: inline-block"
            :error="errors.assessment_name"
            @input="
              () => {
                errors.assessment_name = null
              }
            "
          />
          <button
            type="button"
            class="h-btn h-btn-accent h-margin-left-s"
            @click="createNewAssessment"
          >
            Create Assessment
          </button>
        </div>

        <div>
          <label class="h-label"> Select Assessment: </label>
          <InputField
            type="select"
            :choices="this.assessmentsChoices"
            v-model="assessmentId"
            style="min-width: 200rem; display: inline-block"
          />

          <button
            type="button"
            class="h-btn h-btn-dangerous h-margin-left-s"
            @click="deleteSelectedAssessment"
          >
            <i class="fas fa-trash-alt font-16 mr-4"></i> Delete Assessment
          </button>
        </div>
      </div>
      <div class="h-h3 h-primary_shade_1">
        <!--         <div v-if="numberOfQuestions > 0"></div>
 -->
        <label class="h-label"> Questions: </label>
        <InputField
          id="json-textarea"
          type="textarea"
          v-model="selectedAssessmentQuestionsJSON"
          class="textarea-editor"
          :error="errors.selectedAssessmentQuestions"
          @input="
            () => {
              errors.selectedAssessmentQuestions = null
            }
          "
        />

        <button
          type="button"
          class="h-btn h-btn-accent h-margin-top-l"
          @click="saveCurrentQuestionsJSON"
        >
          Save Questions
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import Vue from 'vue';
import settings from '@/settings.js';
import InputField from '@/components/InputField';

export default {
  data() {
    return {
      assessmentId: null,
      assessment: null,
      assessmentsMap: {},
      assessmentsChoices: [],
      selectedAssessment: null,
      selectedAssessmentQuestions: [],
      selectedAssessmentQuestionsJSON: '',
      numberOfQuestions: 0,
      errors: {},
      new_assessment_name: '',
    };
  },

  methods: {
    onAssessmentSwitch() {
      this.assessment = this.assessmentsMap[this.assessmentId];
      this.numberOfQuestions = 0;

      if (this.assessment.questions && this.assessment.questions.length > 0) {
        this.numberOfQuestions = this.assessment.questions.length;
        this.selectedAssessmentQuestions = this.assessment.questions;
      } else {
        this.selectedAssessmentQuestions = [];
      }

      this.selectedAssessmentQuestionsJSON = JSON.stringify(
        this.selectedAssessmentQuestions,
        null,
        2,
      );
    },
    async getAssesments() {
      const response = await this.$api.getAssesments();
      const assessments = response.assesments;
      this.assessmentsChoices = assessments.map((assessment) => {
        this.assessmentsMap[assessment.id] = assessment;

        return {
          value: assessment.id,
          title: assessment.title,
        };
      });
      this.assessmentId = assessments[0].id;
    },
    async deleteSelectedAssessment() {
      Vue.$confirm({
        title: 'Delete Assessment',
        message: `This will delete the assessment called  "${this.assessment.title}" . Are you sure? `,
        button: {
          yes: 'Yes',
          no: 'No',
        },
        callback: async (confirm) => {
          if (confirm) {
            const payload = {
              assessment_id: this.assessment.id,
            };

            const response = await this.$api.deleteAssessment(payload);
            if (response.status === 'success') {
              $.notify('The assessment was successfully deleted.', {
                position: 'top center',
                className: 'success',
              });
              await this.getAssesments();
            } else {
              $.notify('An error occurred while deleting the assessment.', {
                position: 'top center',
                className: 'error',
              });
            }
          }
        },
      });
    },
    async createNewAssessment() {
      if (!this.new_assessment_name.length > 0) {
        return;
      }

      Vue.$confirm({
        title: 'Create Assessment',
        message: `This will create a new assessment called  "${this.new_assessment_name}" . Are you sure? `,
        button: {
          yes: 'Yes',
          no: 'No',
        },
        callback: async (confirm) => {
          if (confirm) {
            const payload = {
              new_assessment_name: this.new_assessment_name,
            };

            const response = await this.$api.createEmptyAssessment(payload);

            if (response.status === 'success') {
              $.notify('The assessment was successfully created.', {
                position: 'top center',
                className: 'success',
              });
              this.new_assessment_name = '';
              await this.getAssesments();
            } else {
              $.notify('An error occurred while creating the assessment.', {
                position: 'top center',
                className: 'error',
              });
            }
          }
        },
      });
    },
    saveCurrentQuestionsJSON() {
      const questionArray = [];
      let questionData = null;

      try {
        questionData = JSON.parse(this.selectedAssessmentQuestionsJSON);
      } catch (err) {
        $.notify('Questions information contains incorrect JSON', {
          position: 'top center',
          className: 'error',
        });
        return;
      }

      const questionKeys = Object.keys(questionData);
      questionKeys.forEach((questionKey) => {
        questionArray.push(questionData[questionKey]);
      });

      Vue.$confirm({
        title: 'Save Questions',
        message: 'This will replace the current questions. Are you sure?',
        button: {
          yes: 'Yes',
          no: 'No',
        },
        callback: async (confirm) => {
          if (confirm) {
            const payload = {
              assessment_id: this.assessmentId,
              new_questions: questionArray,
            };

            const response = await this.$api.updateAssessmentQuestions(payload);
            if (response.status === 'success') {
              $.notify('The assessment was successfully updated.', {
                position: 'top center',
                className: 'success',
              });
              const savedAssessment = this.assessment;
              await this.getAssesments().then(() => {
                this.assessmentId = savedAssessment.id;
              });
            } else {
              $.notify('An error occurred while updating the assessment.', {
                position: 'top center',
                className: 'error',
              });
            }
          }
        },
      });
    },
  },
  watch: {
    assessmentId() {
      this.onAssessmentSwitch();
    },
  },
  beforeMount() {
    window.execAfterInitMenuInterface(() => {
      window.initActiveMenu('left-navigation-link>hospital>dashboard');
    });
  },

  async mounted() {
    await this.getAssesments();
  },

  components: {
    InputField,
  },
};
</script>

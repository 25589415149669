import Vue from 'vue';
import router from '../router';
import eventBus from '../event-bus';

class Breadcrums {
  constructor() {
    this.backlink = new Vue(
      {
        data: {
          title: '',
          destination: '',
          routerTo: {},
        },
      },
    );
  }

  getFlatRoutes(_result = null, _routes = null) {
    const result = _result || [];
    (_routes || router.options.routes).forEach(
      (r) => {
        result.push(r);
        if (r.children) {
          this.getFlatRoutes(result, r.children);
        }
      },
    );
    return result;
  }

  getRouterByName(name) {
    return this.getFlatRoutes().find(r => r.name === name);
  }

  processNavigationTo(to) {
    eventBus.$emit('specialBreadcrumbTextEvent', { });

    if (to.meta.collapseSettings) eventBus.$emit('resetAndCollapseSettings', {});

    if (to.meta.shouldDisplayLeftArrow) {
      this.backlink.title = '';
      this.backlink.destination = to.meta.parent;
    } else {
      this.backlink.title = to.meta.title;
    }

    this.backlink.routerTo = null;
  }
}

export default {
  install: (vue) => {
    vue.prototype.$breadcrums = new Breadcrums();
  },
};

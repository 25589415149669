<template>
  <div>
    <h3 class="h-text-center h-h3 h-primary_shade_1">Multiple Members</h3>
    <form class="h-padding-top-l">
      <h3 class="h-h4 h-primary_shade_1 h-margin-bottom-s">CSV file</h3>
      <div
        class="form-group form-group-file h-input-file"
        :class="{ 'has-error': errors.csvFile }"
      >
        <div class="custom-file">
          <input type="file" class="custom-file-input" id="csvFile" />
          <label class="custom-file-label" for="csvFile">Choose file</label>
        </div>
      </div>
    </form>
    <div class="h-h4 h-primary_shade_1 h-margin-top-l">
      CSV file must contain the following columns:
      <ol class="h-margin-left-m h-margin-top-s h-margin-bottom-s">
        <li>Name</li>
        <li>Provider NPI</li>
        <li>Email</li>
        <li>Role</li>
      </ol>

      <p>
        <strong>NOTE:</strong>
        Invitations to complete the account activation process will be sent to
        each user.
      </p>
    </div>
    <div class="h-text-center h-margin-top-l">
      <button type="button" class="h-btn h-btn-accent">Upload file</button>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      errors: {},
    };
  },
};
</script>

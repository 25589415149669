import Vue from 'vue';

const ingredientToRecipeMapping = {
  Energ_Kcal: 'kcal',
  Lipid_Tot: 'fat_g',
  FA_Sat: 'fat_sat_g',
  FA_Mono: 'fat_trans_g',
  Cholestrl: 'cholest_g',
  Vit_D_mcg: 'vit_d_mcg',
  Calcium: 'calcium_mg',
  Iron: 'iron_mg',
  Potassium: 'potass_mg',
  Phosphorus: 'phosph_mg',
  Carbohydrt: 'carb_g',
  Protein: 'protein_g',
  Fiber_TD: 'fiber_g',
  Sugar_Tot: 'sugar_g',
  Sodium: 'sodium_mg',
};


export default {
  calcIngredientsNutritionFacts: function calcIngredientsNutritionFacts(ingredients) {
    const total = {};
    ingredients.forEach((ingredient) => {
      ingredient.qty_g = ingredient.qty * ingredient.usda.units_and_grams[ingredient.unit];

      Object.entries(ingredient.usda).forEach((usdaData) => {
        const [key, value] = usdaData;
        if (typeof value !== 'number') {
          return;
        }
        if (total[key] === undefined) {
          total[key] = 0;
        }
        total[key] += ingredient.qty_g * (value / 100);
      });
    });
    return total;
  },

  assignNutritionFacts: function assignNutritionFacts(
    recipe, ingredients,
  ) {
    const totalServings = recipe.total_servings;
    Object.entries(this.calcIngredientsNutritionFacts(ingredients)).forEach(
      (nutritionFact) => {
        const [key, value] = nutritionFact;
        Vue.set(recipe, ingredientToRecipeMapping[key], value / totalServings);
      },
    );
  },
};

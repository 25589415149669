<template>
  <nav class="d-inline-block h-nav-pagination">
    <ul class="pagination">
      <li
        class="page-item"
        :class="{
          disabled: value === 1,
        }"
      >
        <a
          class="page-link"
          href="#"
          tabindex="-1"
          @click="(e) => goToPage(e, value - 1)"
          >Previous</a
        >
      </li>

      <li
        v-for="pageNumber of pageNumbers"
        :key="pageNumber"
        class="page-item"
        :class="{
          active: pageNumber === value,
        }"
      >
        <a
          v-if="pageNumber !== null"
          class="page-link"
          href="#"
          @click="(e) => goToPage(e, pageNumber)"
        >
          {{ pageNumber }}
          <span class="sr-only" v-if="pageNumber === value">(current)</span>
        </a>
        <span v-else class="gap"> ... </span>
      </li>

      <li
        class="page-item"
        :class="{
          disabled: value === length,
        }"
        @click="(e) => goToPage(e, value + 1)"
      >
        <a class="page-link" href="#">Next</a>
      </li>
    </ul>
  </nav>
</template>


<script>
export default {
  computed: {
    pageNumbers() {
      if (this.length < 10) {
        const result = [];
        for (let i = 0; i < length; i += 1) {
          result.push(i + 1);
        }
        return result;
      }

      const result = [
        1,
        2,
        3,
        this.length - 3,
        this.length - 2,
        this.length - 1,
        this.length,
      ];

      for (let i = this.value - 2; i < this.value + 2; i += 1) {
        if (i >= 0 && i < this.length) {
          if (result.indexOf(i + 1) === -1) {
            result.push(i + 1);
          }
        }
      }

      result.sort((a, b) => {
        return a - b;
      });

      const numbers = [];
      result.forEach((num) => {
        if (num !== 1 && numbers[numbers.length - 1] !== num - 1) {
          numbers.push(null);
        }
        numbers.push(num);
      });
      return numbers;
    },
  },

  methods: {
    goToPage(e, num) {
      if (num === this.value) return;
      e.preventDefault();
      this.$emit('input', num);
    },
  },

  props: {
    value: {
      type: Number,
      required: true,
    },
    length: {
      type: Number,
      required: true,
    },
  },
};
</script>

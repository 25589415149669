<template>
  <tr>
    <td colspan="100" class="entryInfo-cell">
      <div class="table-responsive entryInfo-wrapper h1-table">
        <table class="table dataTable">
          <tbody>
            <tr v-if="careProtocolMode">
              <td colspan="2" class="h-dangerous h-text-center">
                Changing this will change Therapy PT itself, not its selected
                entry
              </td>
            </tr>

            <RowInfoText
              label="Category"
              inputType="text"
              :careProtocolMode="careProtocolMode"
              :therapyPt="therapyPt"
              :getTherapyPtFieldValue="
                () => {
                  return getTherapyPtFieldValue('category');
                }
              "
              @input="
                (v) => {
                  setTherapyPtFieldValue('category', v);
                }
              "
            />

            <RowInfoText
              label="Description"
              inputType="textarea"
              :careProtocolMode="careProtocolMode"
              :therapyPt="therapyPt"
              :getTherapyPtFieldValue="
                () => {
                  return getTherapyPtFieldValue('description');
                }
              "
              @input="
                (v) => {
                  setTherapyPtFieldValue('description', v);
                }
              "
            />

            <RowInfoText
              label="Instructions"
              inputType="textarea"
              :careProtocolMode="careProtocolMode"
              :therapyPt="therapyPt"
              :getTherapyPtFieldValue="
                () => {
                  return getTherapyPtFieldValue('instructions');
                }
              "
              @input="
                (v) => {
                  setTherapyPtFieldValue('instructions', v);
                }
              "
            />

            <RowInfoText
              label="Warning text"
              inputType="textarea"
              :careProtocolMode="careProtocolMode"
              :therapyPt="therapyPt"
              :getTherapyPtFieldValue="
                () => {
                  return getTherapyPtFieldValue('warning_text');
                }
              "
              @input="
                (v) => {
                  setTherapyPtFieldValue('warning_text', v);
                }
              "
            />

            <RowInfoText
              label="Comments"
              inputType="textarea"
              :careProtocolMode="careProtocolMode"
              :therapyPt="therapyPt"
              :getTherapyPtFieldValue="
                () => {
                  return getTherapyPtFieldValue('comments');
                }
              "
              @input="
                (v) => {
                  setTherapyPtFieldValue('comments', v);
                }
              "
            />

            <RowInfoLink
              label="Tumbnail"
              :careProtocolMode="careProtocolMode"
              :therapyPt="therapyPt"
              :getTherapyPtFieldValue="
                () => {
                  return getTherapyPtFieldValue('images.thumbnail');
                }
              "
              @input="
                (v) => {
                  setTherapyPtFieldValue('images.thumbnail', v);
                }
              "
            />

            <RowInfoLink
              label="Loop video"
              :careProtocolMode="careProtocolMode"
              :therapyPt="therapyPt"
              :getTherapyPtFieldValue="
                () => {
                  return getTherapyPtFieldValue('videos.loop');
                }
              "
              @input="
                (v) => {
                  setTherapyPtFieldValue('videos.loop', v);
                }
              "
            />

            <RowInfoLink
              label="Intro video"
              :careProtocolMode="careProtocolMode"
              :therapyPt="therapyPt"
              :getTherapyPtFieldValue="
                () => {
                  return getTherapyPtFieldValue('videos.intro');
                }
              "
              @input="
                (v) => {
                  setTherapyPtFieldValue('videos.intro', v);
                }
              "
            />
          </tbody>
        </table>
      </div>
    </td>
  </tr>
</template>


<style lang="stylus" scoped>
table {
  td, th {
    border: 0;
  }
}

.entryInfo-cell {
  padding-top: 0;
  border-top: 0;
}

.entryInfo-wrapper {
  width: 100%;
  padding: 5px;
}

.command-icon {
  cursor: pointer;
}

hr {
  border-color: #e2e6e9;
  border-style: dashed;
}
</style>


<script>
import RowInfoLink from './RowInfoLink';
import RowInfoText from './RowInfoText';

export default {
  props: ['therapyPt', 'careProtocolMode'],

  methods: {
    getTherapyPtFieldValue(fieldName) {
      if (!this.therapyPt) {
        return null;
      }
      let cursor = this.therapyPt;
      // eslint-disable-next-line
      for (const key of fieldName.split(".")) {
        if (!cursor[key]) {
          return null;
        }
        cursor = cursor[key];
      }
      return cursor;
    },

    setTherapyPtFieldValue(fieldName, value) {
      this.$emit('setTherapyPtFieldValue', this.therapyPt, fieldName, value);
    },
  },

  components: {
    RowInfoLink,
    RowInfoText,
  },
};
</script>

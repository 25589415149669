<template>
  <div>
    <div
      class="
        h-padding-left-l h-padding-right-l h-space-between h-align-items-center
      "
    >
      <h3 class="h-h3 h-primary_shade_1">Surgery Checklist</h3>

      <div>
        <div v-if="!readOnly && isTemplateMode">
          <button
            type="button"
            class="h-btn h-btn-safe"
            @click="
              () => {
                $refs.cloneItemsDialog.show();
              }
            "
          >
            Clone existing items
          </button>
          <button
            type="button"
            class="h-btn h-btn-accent h-margin-left-s"
            @click="
              () => {
                $refs.createItemDialog.show();
              }
            "
          >
            <i class="healenticons-plus h-padding-right-xs"></i>New item
          </button>
        </div>
        <div v-if="!readOnly && !isTemplateMode">
          <button
            type="button"
            class="h-btn h-btn-accent"
            @click="saveDoctorChanges"
            :disabled="!doctorHasChanges"
          >
            Save changes
          </button>
        </div>
      </div>
    </div>
    <div class="h1-table">
      <table class="table dataTable">
        <thead class="thead-light">
          <tr>
            <th v-if="!readOnly && !isTemplateMode" class="text-center">
              On/Off
            </th>
            <th></th>
            <th class="text-right w-1">#</th>
            <th>Name</th>
            <th>Start date</th>
            <th>Due date</th>
            <th>Staff alert</th>
            <th>Reminders</th>
            <th class="text-center">Type</th>
            <th class="text-center">Active</th>
            <th class="text-center">Notifications only</th>
            <th class="text-right" v-if="!readOnly && isTemplateMode">
              Action
            </th>
          </tr>
        </thead>

        <tbody v-for="(item, index) of careProtocolItems" :key="item.id">
          <tr :class="{ 'text-muted': !item.active }">
            <td v-if="!readOnly && !isTemplateMode" class="text-center">
              <InputField
                :id="`careProtocolItem-doctorSwitch::${item.id}`"
                type="checkbox"
                checkboxSwitchType="success"
                v-model="item.enabled"
                @change="
                  () => {
                    doctorHasChanges = true;
                  }
                "
              />
            </td>
            <td>
              <i
                class="font-16 expand-row"
                :class="{
                  'fas fa-plus-square text-success': !item.EXPANDED,
                  'fas fa-minus-square text-danger': item.EXPANDED,
                }"
                title="Expand/collapse"
                @click="toggleCareProtocolExpanded(item)"
              ></i>
            </td>
            <td class="text-right">
              {{ index + 1 }}
            </td>
            <td>
              <big
                v-if="
                  item.active &&
                  (!item.start_seconds_before.getTotalSeconds() ||
                    !item.due_seconds_before.getTotalSeconds())
                "
              >
                <IconTooltip
                  iconClass="mdi mdi-alert-outline alert-icon text-danger"
                  title="Start date and due date are
                        required for auto assigning surgery items to clients."
                />
              </big>
              {{ item.name }}
            </td>
            <td>
              {{ item.start_seconds_before.toString() || "-" }}
            </td>
            <td>
              {{ item.due_seconds_before.toString() || "-" }}
            </td>
            <td>
              <ol class="timeParts" v-if="item.staff_alerts.length !== 0">
                <li v-for="(entry, index) of item.staff_alerts" :key="index">
                  {{ entry.toString() }}
                </li>
              </ol>
              <span v-else>-</span>
            </td>
            <td>
              <ol class="timeParts" v-if="item.reminders.length !== 0">
                <li v-for="(entry, index) of item.reminders" :key="index">
                  {{ entry.toString() }}
                </li>
              </ol>
              <span v-else>-</span>
            </td>
            <td class="text-center">
              <span
                :class="{
                  'text-blue': item.active && item.post_surgery,
                }"
              >
                {{ item.post_surgery ? "Post-surgery" : "Pre-surgery" }}
              </span>
            </td>
            <td class="text-center">
              <span
                :class="{
                  'text-success': item.active,
                  'text-danger': !item.active,
                }"
              >
                {{ item.active ? "Yes" : "No" }}
              </span>
            </td>
            <td class="text-center">
              <span
                :class="{
                  'text-success': item.is_notifications_only,
                  'text-danger': !item.is_notifications_only,
                }"
              >
                {{ item.is_notifications_only ? "Yes" : "No" }}
              </span>
            </td>
            <td class="text-right" v-if="!readOnly && isTemplateMode">
              <a
                href="javascript:void(0);"
                class="mr-2"
                @click="
                  () => {
                    $refs.editItemDialog.show(item);
                  }
                "
              >
                <i class="fas fa-edit text-info font-16" title="Edit"></i>
              </a>

              <a
                href="javascript:void(0);"
                class="mr-2"
                @click="
                  () => {
                    $refs.deleteItemDialog.show(item);
                  }
                "
              >
                <i
                  class="fas fa-trash-alt text-danger font-16"
                  title="Delete"
                ></i>
              </a>
            </td>
          </tr>

          <tr v-if="item.EXPANDED">
            <td colspan="100" class="no-border-top careProtocol-educationRow">
              <div class="text-center mb-3">
                <strong>
                  <big> Care protocol item education: {{ item.name }} </big>
                </strong>
              </div>

              <EducationList
                :collectionId="item.learn_collection_id"
                :readOnly="readOnly || !isTemplateMode"
                :dense="true"
              />
            </td>
          </tr>
        </tbody>

        <tbody v-if="careProtocolItems.length === 0">
          <tr>
            <td class="text-center" colspan="100">No entries</td>
          </tr>
        </tbody>
      </table>

      <CreateItemDialog
        ref="createItemDialog"
        :protocolId="careProtocol.id"
        @itemsUpdated="setItems"
      />
      <CloneItemsDialog
        ref="cloneItemsDialog"
        :protocolId="careProtocol.id"
        @itemsUpdated="setItems"
      />
      <EditItemDialog ref="editItemDialog" @itemsUpdated="setItems" />
      <DeleteItemDialog ref="deleteItemDialog" @itemsUpdated="setItems" />

      <GlobalWait
        ref="globalWait"
        title="Saving enabled care protocl items"
        message="Saving enabled care protocl items..."
      />
    </div>
  </div>
</template>


<style lang="stylus" scoped>
.timeParts {
  list-style-type: upper-roman;
  margin-bottom: 0px;

  li {
    padding-left: 7px;
  }
}

table.main {
  tbody {
    border-top: 0px !important;

    td {
      vertical-align: top;

      .expand-row {
        cursor: pointer;
      }
    }

    td.no-border-top {
      border-top: 0px;
    }

    tr.careProtocol-educationRow {
      border: 1px solid #f0f0f0;
      background-color: #fbfcfe;
    }
  }
}
</style>


<script>
import TimeParts from '@/components/time_parts/TimeParts';

import GlobalWait from '@/components/GlobalWait';
import InputField from '@/components/InputField';
import IconTooltip from '@/components/IconTooltip';
import CreateItemDialog from './components/CreateItemDialog';
import CloneItemsDialog from './components/CloneItemsDialog';
import EditItemDialog from './components/EditItemDialog';
import DeleteItemDialog from './components/DeleteItemDialog';
import EducationList from '@/components/education/education_list/EducationList';

export default {
  data() {
    return {
      careProtocolItems: [],
      doctorHasChanges: false,
    };
  },

  methods: {
    setItems(items) {
      items.forEach((item) => {
        const matchItem = this.careProtocolItems.find((thisItem) => {
          return thisItem.id === item.id;
        });
        if (matchItem) {
          item.EXPANDED = matchItem.EXPANDED;
        }
      });

      this.careProtocolItems = items;

      this.careProtocolItems.forEach((item) => {
        item.start_seconds_before = new TimeParts(item.start_seconds_before);
        item.due_seconds_before = new TimeParts(item.due_seconds_before);
        item.staff_alerts = item.staff_alerts.map((secondsBefore) => {
          return new TimeParts(secondsBefore);
        });
        item.reminders = item.reminders.map((secondsBefore) => {
          return new TimeParts(secondsBefore);
        });

        item.staff_alerts.sort(TimeParts.compare);
        item.reminders.sort(TimeParts.compare);
      });
    },

    toggleCareProtocolExpanded(entry) {
      entry.EXPANDED = !entry.EXPANDED;
      this.$forceUpdate();
    },

    async saveDoctorChanges() {
      this.$refs.globalWait.start();
      const disabledItems = this.careProtocolItems
        .filter((item) => {
          return item.enabled === false;
        })
        .map((item) => {
          return item.id;
        });
      const response = await this.$api.updateCareProtocol(
        this.careProtocol.id,
        this.doctor.id,
        { disabledItems },
      );
      this.setItems(response.care_protocol.care_protocol_items);
      this.doctorHasChanges = false;
      this.$refs.globalWait.stop();
    },
  },

  mounted() {
    this.setItems(this.careProtocol.care_protocol_items);
  },

  components: {
    IconTooltip,
    GlobalWait,
    InputField,
    CreateItemDialog,
    CloneItemsDialog,
    EditItemDialog,
    DeleteItemDialog,
    EducationList,
  },

  props: {
    careProtocol: {
      type: Object,
      required: true,
    },
    readOnly: {
      type: Boolean,
      required: false,
    },
    isTemplateMode: {
      type: Boolean,
      required: false,
    },
    doctor: {
      required: false,
    },
  },
};
</script>

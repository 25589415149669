<template>
  <div class="h-d-flex h-align-item-center">
    <div class="nudging" @click="toggleAutomaticNudging">
      <div
        class="nudging-icon h-margin-right-m"
        :class="{ 'nudging-active': automaticNudging }"
      ></div>
      <span class="tooltiptext">Automatic reminders are {{ automaticNudging ? 'on' : 'off' }}</span>
    </div>
    <div class="filter-list h-dropdown ">
      <img src="../../../../../../../public/assets/images/SortByDate.svg" class="h-filter-icon" />
      <div class="h-d-flex filter-log-list h-dropdown-content">
        <label>
          <input type="radio" name="filter" class="h-card-input active" @click="days7" />
          Last 7 days
        </label>
        <label>
          <input type="radio" name="filter" class="h-card-input" @click="days30" />
          Last 30 days
        </label>
        <label>
          <input type="radio" name="filter" class="h-card-input" @click="lastYear" />
          Last year
        </label>
        <label>
          <input type="radio" name="filter" class="h-card-input" @click="allTime" />
          All-time
        </label>
      </div>
    </div>
    <div class="filter-list h-dropdown">
      <img
        src="../../../../../../../public/assets/images/Filter.svg"
        class="h-margin-left-m h-filter-icon"
      />
      <div class="h-d-flex filter-log-list h-dropdown-content">
        <label>
          <input
            data-val="any"
            type="radio"
            name="filter"
            class="h-card-input"
            @click="allTopics"
          />
          All
        </label>

        <label>
          <input data-val="any" type="radio" name="filter" class="h-card-input" @click="allOpen" />
          Open
        </label>
        <label>
          <input
            data-val="any"
            type="radio"
            name="filter"
            class="h-card-input"
            @click="allIgnore"
          />
          Ignored
        </label>
        <label>
          <input
            data-val="any"
            type="radio"
            name="filter"
            class="h-card-input"
            @click="allResolved"
          />
          Resolved
        </label>
        <label v-for="category in this.categories" :key="category.id">
          <input
            type="radio"
            name="filter"
            class="h-card-input"
            @click="
              () => {
                handleCategoryChange(category)
              }
            "
          />
          {{ category }}
        </label>
        <label>
          <input
            data-val="Login"
            type="radio"
            name="filter"
            class="h-card-input"
            @click="loginFuller"
          />
          Login
        </label>
        <label>
          <input
            data-val="Oximeter"
            type="radio"
            name="filter"
            class="h-card-input"
            @click="oximeterReadings"
          />
          Oximeter readings alerts
        </label>
        <label>
          <input
            data-val="Login"
            type="radio"
            name="filter"
            class="h-card-input"
            @click="planEndDate"
          />
          Plan end date
        </label>
        <label>
          <input
            data-val="Login"
            type="radio"
            name="filter"
            class="h-card-input"
            @click="stepsReading"
          />
          Steps reading
        </label>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment-timezone';
import settings from '@/settings.js';

export default {
  data() {
    return {
      automaticNudging: false,
      SetCategory: 'Follow-up Required',
      SetAlertStatus: 'active',
      dateStart: moment()
        .subtract(7, 'days')
        .format(),
      dateEnd: moment().format(),
      alertStatus: 'any',
      category: 'any',
      clientId: this.client.id,
      topic: '',
      categories: null,
    };
  },
  async mounted() {
    const resp = await this.$api.get(
      `${settings.BACKEND_URL}/api/v-nurse/client/get-automatic-nudging/${this.clientId}`,
    );
    this.automaticNudging = resp.automaticNudging;
    const response = await this.$api.getClientAlertsCategories();
    this.categories = response.categories;
  },
  methods: {
    toggleAutomaticNudging() {
      const newAutomaticNudging = !this.automaticNudging;
      const params = {
        clientId: this.clientId,
        automaticNudging: newAutomaticNudging,
      };
      this.$api
        .post(`${settings.BACKEND_URL}/api/v-nurse/client/set-automatic-nudging`, null, params)
        .then((response) => {
          if (response.status === 'ok') {
            this.automaticNudging = newAutomaticNudging;
          } else {
            console.log(response);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    allTopics() {
      this.$emit('applyFilters', {
        alertStatus: '',
        category: 'any',
        topic: '',
      });
      this.SetAlertStatus = '';
    },
    allOpen() {
      this.$emit('applyFilters', {
        alertStatus: 'active',
        category: 'any',
        topic: '',
      });
      this.SetAlertStatus = '';
    },
    allIgnore() {
      this.$emit('applyFilters', {
        alertStatus: 'wont_fix',
        category: 'any',
        topic: '',
      });
      this.SetAlertStatus = '';
    },
    allResolved() {
      this.$emit('applyFilters', {
        alertStatus: 'resolved',
        category: 'any',
        topic: '',
      });
      this.SetAlertStatus = '';
    },
    handleCategoryChange(category) {
      this.$emit('applyFilters', {
        alertStatus: '',
        category,
        topic: '',
      });
      this.SetAlertStatus = '';
    },
    oximeterReadings() {
      this.$emit('applyFilters', {
        alertStatus: '',
        category: '',
        topic: 'Oximeter readings',
      });
      this.SetAlertStatus = '';
    },
    loginFuller() {
      this.$emit('applyFilters', {
        alertStatus: '',
        category: '',
        topic: 'Login',
      });
      this.SetAlertStatus = '';
    },
    stepsReading() {
      this.$emit('applyFilters', {
        alertStatus: '',
        category: '',
        topic: 'steps',
      });
      this.SetAlertStatus = '';
    },
    opioidRiskAssessment() {
      this.$emit('applyFilters', {
        alertStatus: '',
        category: '',
        topic: 'risk',
      });
      this.SetAlertStatus = '';
    },
    planEndDate() {
      this.$emit('applyFilters', {
        alertStatus: '',
        category: '',
        topic: 'plan end date',
      });
      this.SetAlertStatus = '';
    },
    days7() {
      this.$emit('applyFilters', {
        dateStart: moment()
          .subtract(7, 'days')
          .format(),
      });
    },
    days30() {
      this.$emit('applyFilters', {
        dateStart: moment()
          .subtract(30, 'days')
          .format(),
      });
    },
    lastYear() {
      this.$emit('applyFilters', {
        dateStart: moment()
          .subtract(365, 'days')
          .format(),
      });
    },
    allTime() {
      this.$emit('applyFilters', {
        dateStart: moment()
          .subtract(50000, 'days')
          .format(),
      });
    },
  },
  props: {
    initialFilters: Object,
    client: Object,
  },

  components: {},
};
</script>

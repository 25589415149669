<template>
  <div>
    <div class="h-align-items-center h-space-between">
      <div>
        <h5 class="h-h3 h-primary_shade_1">Phones list</h5>
      </div>
      <div class="h-h4 h-accent">
        <i
          @click="addPhone"
          class="healenticons-plus h-accent h-cursor-pointer"
          title="New phone number"
        ></i>
      </div>
    </div>

    <div class="h1-table">
      <table class="table dataTable h-noborder-input">
        <thead class="thead-light">
          <tr>
            <th width="200">Type</th>
            <th width="250">Phone</th>
            <th>Extra</th>
            <th class="text-right w-1">Action</th>
          </tr>
        </thead>

        <tbody v-if="phones.length !== 0">
          <tr v-for="phone of phones" :key="getPhoneId(phone)" :phone="phone">
            <td>
              <InputField
                type="select"
                :choices="[
                  [null, ''],
                  ['office', 'Office'],
                  ['mobile', 'Mobile'],
                ]"
                v-model="phone.phone_type"
                :error="getPhoneErrors(phone).phone_type"
                @change="clearPhoneError(phone, 'phone_type')"
              />
            </td>
            <td>
              <InputField
                type="text"
                class="phone"
                v-model="phone.phone_number"
                :error="getPhoneErrors(phone).phone_number"
                @change="clearPhoneError(phone, 'phone_number')"
              />
            </td>
            <td>
              <InputField
                type="text"
                v-model="phone.extra"
                :error="getPhoneErrors(phone).extra"
                @change="clearPhoneError(phone, 'extra')"
              />
            </td>
            <td class="text-right">
              <a
                style="display: inline-grid"
                href="javascript:void(0);"
                @click="
                  () => {
                    removePhone(phone);
                  }
                "
              >
                <i
                  class="fas fa-trash-alt h-dangerous font-16"
                  title="Delete"
                ></i>
              </a>
            </td>
          </tr>
        </tbody>

        <tbody v-else>
          <tr>
            <td colspan="4" class="text-center" style="vertical-align: inherit">
              No phones
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import InputField from '@/components/InputField';

export default {
  props: ['phones', 'errors'],

  data() {
    return {
      lastPhoneId: 0,
    };
  },

  methods: {
    clearPhoneError(phone, field) {
      const errors = this.getPhoneErrors(phone);
      errors[field] = null;
    },

    addPhone() {
      this.lastPhoneId += 1;
      this.phones.push({
        __localId: `local-${this.lastPhoneId}`,
        type: '',
        number: '',
        extra: '',
      });
      $(document).ready(() => {
        $('.phone > input').keyup(() => {
          this.value = this.value.replace(/[^+0-9]/g, '');
        });
      });
    },

    getPhoneId(phone) {
      if (phone.id) return phone.id;
      return phone.__localId;
    },

    getPhoneErrors(phone) {
      if (!this.errors) return {};
      return this.errors[this.getPhoneId(phone)] || {};
    },

    removePhone(phone) {
      this.phones.splice(this.phones.indexOf(phone), 1);
      if (this.errors) {
        if (this.errors[this.getPhoneId(phone)]) {
          delete this.errors[this.getPhoneId(phone)];
        }
      }
    },
  },
  mounted() {
    $(document).ready(() => {
      $('.phone > input').keyup(() => {
        this.value = this.value.replace(/[^+0-9]/g, '');
      });
    });
  },
  components: {
    InputField,
  },
};
</script>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"h-margin-top-l h-margin-bottom-l"},[_c('h3',{staticClass:"header-title h-h3 h-primary_shade_1 h-margin-left-l"},[_vm._v(" Current reports ")]),(!_vm.loading)?_c('div',[(_vm.clientReportsList.length === 0)?_c('div',{staticClass:"text-muted"},[_vm._v(" No reports. ")]):_c('div',{staticClass:"h1-table"},[_c('table',{ref:"table",staticClass:"table dataTable reports-table ttd"},[_vm._m(0),_c('tbody',_vm._l((_vm.clientReportsList),function(clientReports,index){return _c('tr',{key:clientReports.client.id},[_c('td',{staticClass:"w-1 text-nowrap"},[_vm._v(_vm._s(index + 1))]),_c('td',{staticClass:"w-1 text-nowrap"},[_vm._v("#"+_vm._s(clientReports.client.id))]),_c('td',{staticClass:"w-1 text-nowrap"},[_c('router-link',{attrs:{"to":{
                  name: 'PatientProfile',
                  params: { id: clientReports.client.id },
                }}},[_c('img',{staticClass:"thumb-sm rounded-circle mr-2",attrs:{"src":clientReports.client.profile ||
                    '/assets/images/users/patient-pro.png'}}),_vm._v(" "+_vm._s(clientReports.client.name || "-")+" ")])],1),_c('td',{staticClass:"w-1 text-nowrap"},[_vm._v(" "+_vm._s(clientReports.client.email || "-")+" ")]),_c('td',[_c('ul',{staticClass:"reports-list"},_vm._l((clientReports.report_params_list),function(reportParams){return _c('li',{key:((clientReports.client.id) + "::" + (reportParams.report_start_date))},[(
                      _vm.getDownloadStatus(
                        clientReports,
                        reportParams.report_end_date
                      )
                    )?_c('i',{staticClass:"font-16 mr-1",class:_vm.getDownloadStatus(
                        clientReports,
                        reportParams.report_end_date
                      ),attrs:{"title":"Downloaded"}}):_vm._e(),_c('a',{attrs:{"href":_vm.getReportLink(clientReports.client, reportParams)},on:{"click":function (e) { return _vm.downloadReport(e, clientReports.client, reportParams); }}},[_vm._v(" "+_vm._s(_vm.formatDate(reportParams.report_start_date))+" - "+_vm._s(_vm.formatDate(reportParams.report_end_date))+" ")])])}),0)])])}),0)])])]):_c('Loader')],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('thead',{staticClass:"thead-light"},[_c('tr',[_c('th',{staticClass:"w-1 text-nowrap"},[_vm._v("#")]),_c('th',{staticClass:"w-1 text-nowrap"},[_vm._v("ID")]),_c('th',{staticClass:"w-1 text-nowrap"},[_vm._v("Name")]),_c('th',{staticClass:"w-1 text-nowrap"},[_vm._v("Email")]),_c('th',[_vm._v("Pending reports")])])])}]

export { render, staticRenderFns }
<template>
  <div class="h-drawer-max79">
    <Drawer :direction="'right'" :exist="true" ref="modalDialog">
      <div class="h-drawer-card">
        <div class="h-drawer-card-header">
          <div class="h-main-drawer-card-header h-margin-bottom-l">
            <h3 class="h-h3 h-primary_shade_1">Delete item</h3>
            <button
              type="button"
              class="close"
              aria-hidden="true"
              @click="close"
              :disabled="loading"
            >
              <!-- <font-awesome-icon
                    :icon="['fas', 'sign-out-alt']"
                    style="height: 1.5vw; width: 1.5vw; color: #c3c4cb"
                  /> -->
              <img src="/assets/images/Collapse-drawer.svg" alt="" />
            </button>
          </div>
        </div>
        <div class="h-drawer-card-body">
          <div v-if="!loading && item" class="h-h4 h-primary_shade_1">
            Do you really want to <strong>delete</strong> item:
            <u>{{ item.name }}</u> ?
          </div>

          <Loader v-else />
        </div>

        <div class="h-drawer-card-footer">
          <button
            type="button"
            class="h-btn h-btn-dangerous"
            @click="deleteItem"
            :disabled="loading"
          >
            Delete
          </button>

          <button
            type="button"
            class="h-btn h-btn-accent h-margin-left-s"
            @click="close"
            :disabled="loading"
          >
            Cancel
          </button>
        </div>
      </div>
    </Drawer>
  </div>

  <!-- /.modal -->
</template>


<script>
import Loader from '@/components/Loader';

export default {
  data() {
    return {
      loading: false,
      item: null,
    };
  },

  methods: {
    show(item) {
      this.$refs.modalDialog.open();
      this.$refs.modalDialog.active = true;
      this.loading = false;
      this.item = item;
      $(this.$refs.modalDialog.el).modal({
        backdrop: 'static',
        keyboard: false,
      });
      $('.modal-backdrop').last().insertBefore(this.$refs.modalDialog.el);
    },

    close(force) {
      if (!force && this.loading) return;
      this.$refs.modalDialog.close();
      this.$refs.modalDialog.active = false;
    },

    async deleteItem() {
      this.loading = true;
      const response = await this.$api.deleteCareProtocolItem(this.item.id);
      this.$emit('itemsUpdated', response.care_protocol_items);
      this.close(true);
    },
  },

  components: {
    Loader,
  },
};
</script>

class strUtils {
  escapeHtml(html) {
    const text = document.createTextNode(html || '-');
    const p = document.createElement('p');
    p.appendChild(text);
    return p.innerHTML;
  }
}

export default {
  install: (Vue) => {
    // eslint-disable-next-line
    Vue.prototype.$strUtils = new strUtils();
  },
};

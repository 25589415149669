<template>
  <tr>
    <td>
      <InputField
        type="select"
        v-model="itemValue.start_date_type"
        :choices="[
          ['START_TYPE_PROTOCOL', 'Care protocol date'],
          ['START_TYPE_CLIENT_PLAN', 'Client plan start'],
          ['START_TYPE_SURGERY_DATE', 'Surgery date']
        ]"
      />
    </td>
    <td>
      <InputField
        type="checkbox"
        checkboxSwitchType="success"
        :id="itemValue.KEY"
        v-model="itemValue.post_op"
      />
    </td>
    <td>
      <input
        type="number"
        min="0"
        class="h-input"
        v-model="itemValue.shift_in_days"
        :disabled="!careProtocolMode"
      />
    </td>
    <td style="position: relative">
      <select
        v-if="careProtocolMode"
        :id="componentId"
        class="h-input"
        placeholder="Type to search..."
        data-noresults-text="No entries found."
        autocomplete="off"
        :disabled="!careProtocolMode"
      ></select>
      <input v-else type="text" class="h-input" :value="itemValue.title" disabled="disabled" />
    </td>
    <td class="text-center">
      <input
        type="number"
        min="0"
        class="h-input"
        v-model="itemValue.repeat_every_x_days"
        :disabled="!careProtocolMode"
      />
    </td>
    <td v-if="careProtocolMode">
      <i
        class="fas fa-trash-alt text-danger font-16"
        title="Remove"
        @click="$emit('remove', item)"
      ></i>
    </td>
  </tr>
</template>

<style lang="stylus" scoped>
td:last-child i {
  cursor: pointer;
}

.expand-row {
  cursor: pointer;
}

table td {
  border-top-color: #dee2e6;
}
</style>

<script>
import InputField from '@/components/InputField';

export default {
  data() {
    const itemValue = JSON.parse(JSON.stringify(this.item));
    return {
      itemValue,
    };
  },

  computed: {
    componentId() {
      return `assesmentRow-${this.item.KEY}`;
    },
  },

  mounted() {
    if (this.careProtocolMode) {
      const that = this;
      $(`#${this.componentId}`).autoComplete({
        minLength: 2,
        events: {
          search(qry, callback, origJQElement) {
            that.$api.getAssesments({ search: qry }).then((result) => {
              result.assesments.forEach((item) => {
                item.text = item.title;
              });
              callback(result.assesments);
            });
          },
        },
      });
      $(`#${this.componentId}`).autoComplete(
        'set',
        this.itemValue.assesment ? this.itemValue.assesment.title : '',
      );

      $(`#${this.componentId}`).on('autocomplete.select', (evt, item) => {
        if (!item.id) {
          return;
        }
        if (this.itemValue.title === item.title) {
          return;
        }
        this.itemValue.assesment = item;
        this.$forceUpdate();
      });
    }
  },

  components: {
    InputField,
  },

  props: {
    item: {
      type: Object,
      required: true,
    },
    careProtocolMode: {
      type: Boolean,
      required: false,
    },
  },
};
</script>

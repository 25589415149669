<template>
  <div class="h-card">
    <div ref="cardBody" class="h-card-body">
      <h4 class="h-h3 h-primary_shade_1 h-margin-bottom-m">
        <slot name="title">Upcoming surgeries</slot>
      </h4>

      <Chart ref="chart" :options="chartOptions" v-if="loaded" />

      <div v-else class="text-center">
        <div
          class="spinner-border text-primary"
          role="status"
          :style="{ marginTop: '1.5em' }"
        ></div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="stylus">
.card-body {
  padding-bottom: 0px;

  .h5-toggle {
    font-family: Quicksand;
    font-weight: bold;
    color: #152145;
  }
}
</style>

<script>
import { Chart } from 'highcharts-vue';

export default {
  name: 'SimpleCardNumber',
  props: ['loaded', 'dashinfo'],

  computed: {
    chartOptions() {
      return {
        chart: {
          type: 'column',
          height: 90,
        },
        title: {
          text: null,
        },
        xAxis: {
          categories: this.dashinfo.upcoming_surgeries,
          crosshair: true,
          labels: {
            formatter() {
              return this.value.day_of_week_short;
            },
          },
        },
        yAxis: {
          min: 0,
          title: {
            text: null, // 'Surgery count'
          },
        },
        tooltip: {
          headerFormat: `
            <span style="font-size:10px">
              {point.key.surgery_date}
              <span class="text-muted">({point.key.day_of_week})</span>
            </span> 
            <table>
          `,
          pointFormat: `
            <tr>
              <td style="color:{series.color};padding:0">{series.name}:&nbsp; </td>
              <td style="padding:0"><b>{point.y}</b></td>
            </tr>
          `,
          footerFormat: '</table>',
          shared: true,
          useHTML: true,
          outside: true,
        },
        plotOptions: {
          column: {
            pointPadding: 0.2,
            borderWidth: 0,
          },
        },
        series: [
          {
            name: 'Surgeries',
            data: this.dashinfo.upcoming_surgeries.map((entry) => {
              return entry.count;
            }),
          },
        ],
        legend: {
          enabled: false,
        },
        credits: {
          enabled: false,
        },
      };
    },
  },

  methods: {
    forceRedraw() {
      if (this.$refs.chart) {
        const chart = this.$refs.chart.chart;
        chart.setSize($(this.$refs.cardBody).width(), chart.chartHeight);
      }
    },
  },

  mounted() {
    window.execAfterShowMenuTrigger.push(this.forceRedraw);
    $(window).on('resize', this.forceRedraw);
  },

  destroyed() {
    $(window).off('resize', this.forceRedraw);
    window.execAfterShowMenuTrigger.splice(
      window.execAfterShowMenuTrigger.indexOf(this.forceRedraw),
      1,
    );
  },

  components: {
    Chart,
  },
};
</script>

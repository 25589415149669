class EventBass {
  constructor() {
    this.map = {};
    this.documentEventTracker = {};
  }

  $on(eventName, eventFunction, owner = null) {
    if (owner === null) {
      console.warn(`${eventName} owner=null`);
    }

    this.map[eventName] = this.map[eventName] || [];
    const index = this.map[eventName].find((entry) => {
      return (entry.eventFunction === eventFunction) && (entry.owner === owner);
    });
    if (index !== -1) {
      this.map[eventName].push({
        eventFunction,
        owner,
      });
    }
  }

  $off(eventName, eventFunction = undefined, owner = null) {
    const eventFunctions = this.map[eventName] || [];
    if (eventFunctions.length === 0) return;

    this.map[eventName] = this.map[eventName].filter((entry) => {
      if (entry.owner !== owner) return true;
      if ((eventFunction === undefined) || (eventFunction === entry.eventFunction)) {
        return false;
      }
      return true;
    });
  }

  $offOwner(owner) {
    Object.keys(this.map).forEach((eventName) => {
      const eventFunctions = this.map[eventName] || [];
      if (eventFunctions.length === 0) return;

      this.map[eventName] = this.map[eventName].filter((entry) => {
        return (entry.owner !== owner);
      });
    });
  }

  $emit(eventName, event) {
    const eventMap = this.map[eventName] || [];
    eventMap.forEach((entry) => {
      entry.eventFunction(event);
    });
  }
}

export default new EventBass();

<template>
  <div
    class=" background-a"
    :class="{rolledDown: rolledDownValue}"
  >
    <div
      class="content-piece my-0 header"
      @click="rolledDownValue = !rolledDownValue"
    >
      <i
        class="fa float-right rolledDownIndicator"
        :class="{
          'fa-chevron-down': !rolledDownValue,
          'fa-chevron-up': rolledDownValue,
        }"
      ></i>

      <slot name="title"></slot>

    </div>

    <div :class="{'d-none': !rolledDownValue}">
      <hr class="my-0" />
      <div class="content-piece content-piece-slot background-b">
        <slot name="content"></slot>
      </div>
    </div>
  </div>
</template>


<style lang="stylus" scoped>
.wrapper
  border 1px solid #dee2ed
  border-radius 4px
  margin-bottom 15px

  .header
    cursor pointer
    padding-top 4px
    padding-bottom 4px
    padding-left 1.5em

.rolledDownIndicator
  font-size 110%
  margin 12px
  margin-top: 1em

.wrapper.rolledDown
  margin-bottom 20px

.content-piece
  padding-left 1em
  padding-right 1em

.content-piece.header
  padding: 0.1rem 1rem
  padding-right: 0.35rem
  margin-top: 1em
  cursor: pointer

.content-piece-slot
  padding-bottom 1.5em
  padding-top 1em

.background-a
  background-color #f3f6f7

.background-b
  background-color white
</style>


<script>
export default {
  data() {
    return {
      rolledDownValue: this.rolledDown,
    };
  },

  props: {
    title: {
      type: String,
    },
    rolledDown: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<template>
  <div class="h-drawer-max80 h-no-padding">
    <SlottedMessageInDrawer ref="slottedMessage" :title="'Health risk assessment'">
      <template v-slot:modal-body>
        <div v-if="clientId" class="h1-table h-padding-top-l">
          <table class="dataTable h-noborder-input table d-table h-drawer">
            <thead class="thead-light">
              <tr>
                <th class="text-nowrap">Order</th>
                <th>Question</th>
                <th>Answers</th>
              </tr>
            </thead>

            <tbody v-if="!loading">
              <tr v-for="question of questions" :key="question.id">
                <td class="text-center">{{ question.order }}</td>
                <td class="text-left" style="white-space: normal">
                  {{ question.question }}
                </td>
                <td>
                  <InputField
                    v-if="!question.is_multiselect"
                    type="select"
                    :classExtra="{ 'height-auto': true }"
                    :choices="
                      answerChoiceMap[question.id] || [
                        {
                          value: null,
                          title: '--- select ---'
                        }
                      ]
                    "
                    @change="
                      () => {
                        hasChange = true
                      }
                    "
                    v-model="answerMap[question.id]"
                    :disabled="!canEdit"
                  />
                  <div v-else>
                    <div
                      v-for="answer in question.answers"
                      :key="answer.key"
                      style="margin-top: 3px; margin-bottom: 3px"
                    >
                      <InputField
                        v-if="Object.keys(answerMap).length > 0"
                        :id="`answer::${question.id}:${answer.key}`"
                        type="checkbox"
                        :label="`${answer.key} - ${answer.value}`"
                        @change="
                          () => {
                            hasChange = true
                          }
                        "
                        v-model="answerMap[question.id][answer.key]"
                        :disabled="!canEdit"
                      />
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>

            <tbody v-else>
              <tr>
                <td colspan="1000" class="text-center">Loading...</td>
              </tr>
            </tbody>
          </table>

          <p
            style="
              color: var(--primary_shade_1);
              font-size: 8rem;
              margin-top: 4rem;
              margin-left: 15rem;
            "
            v-if="!loading && !hasChange"
          >
            <strong>Total score:</strong> {{ totalScore }}
          </p>
        </div>
      </template>

      <template v-slot:modal-footer>
        <button
          v-if="canEdit"
          type="button"
          class="h-btn h-btn-accent"
          @click="save"
          :disabled="saving"
        >
          {{ saving ? 'Saving...' : 'Save' }}
        </button>

        <button type="button" class="h-btn h-btn-dangerous h-margin-left-s" @click="close">
          Close
        </button>
      </template>
    </SlottedMessageInDrawer>
  </div>
</template>

<script>
import SlottedMessageInDrawer from '@/components/dialogs/SlottedMessageInDrawer';
import InputField from '@/components/InputField';

export default {
  data() {
    return {
      loading: false,
      saving: false,
      questions: [],
      totalScore: 0,
      hasChange: false,
      answerMap: {},
      clientId: null,
    };
  },

  computed: {
    answerChoiceMap() {
      const result = {};
      this.questions.forEach((question) => {
        result[question.id] = question.answers.map(answer => ({
          value: answer.key,
          title: answer.value,
        }));
        result[question.id].unshift({
          value: null,
          title: '--- select ---',
        });
      });
      return result;
    },
  },

  methods: {
    async getClientQuestions(clientId) {
      try {
        const { questions, score } = await this.$api.getClientQuestions({ client_id: clientId });
        this.questions = questions;
        this.totalScore = score;
      } catch (e) {
        this.questions = [];
      }
    },

    async show(clientId) {
      this.saving = false;
      this.clientId = clientId;

      await this.getClientQuestions(clientId);

      this.hasChange = false;
      this.$refs.slottedMessage.show();
      this.answerMap = {};
      this.questions.forEach((question) => {
        if (question.is_multiselect) {
          this.answerMap[question.id] = {};
          question.answers.forEach((answer) => {
            this.answerMap[question.id][answer.key] = !!question.client_answer?.find(
              answerEntry => answerEntry.key === answer.key,
            );
          });
        } else {
          const theAnswer = question.client_answer.length > 0 ? question.client_answer[0] : null;
          this.answerMap[question.id] = theAnswer ? theAnswer.key : null;
        }
      });
    },

    close(force) {
      this.$refs.slottedMessage.close(force);
      this.$refs.slottedMessage.active = false;
    },

    async save() {
      const qSubmit = {};
      Object.keys(this.answerMap).forEach((questionIdStr) => {
        const questionId = parseInt(questionIdStr, 10);
        const question = this.questions.find(q => q.id === questionId);
        if (!question) return;
        const entry = {
          answers: [],
          question: {
            id: question.id,
            order: question.order,
            question: question.question,
          },
        };
        qSubmit[question.order] = entry;
        if (typeof this.answerMap[questionIdStr] === 'string') {
          entry.answers = question.answers.filter((answerEntry) => {
            return answerEntry.key === this.answerMap[questionIdStr];
          });
        } else {
          entry.answers = question.answers.filter((answerEntry) => {
            if (this.answerMap[questionIdStr]) {
              return this.answerMap[questionIdStr][answerEntry.key];
            }
            return null;
          });
        }
      });
      try {
        this.saving = true;
        await this.$api.updateClientQuestion({
          data: {
            clientId: this.clientId,
            answers: qSubmit,
            updateMicroprotocols: false,
          },
        });
        this.saving = false;
        this.close(true);
        this.$emit('saved');
      } catch (e) {
        console.log(e);
      }
    },
  },

  props: {
    canEdit: {
      type: Boolean,
      default: true,
    },
  },

  components: {
    SlottedMessageInDrawer,
    InputField,
  },
};
</script>

<template>
  <div class="h1-table h-medication-doss" style="color: black">
    <table ref="table" class="table dataTable ">
      <thead class="thead-light">
        <tr>
          <th style="width: 20%;">Date</th>
          <th style="width: 70%;">Compliance, %</th>
          <th style="width: 10%;" class="text-center">Action</th>
        </tr>
      </thead>

      <tbody v-if="rows.length !== 0">
        <tr v-for="row of rows" :key="row.date">
          <td>
            <i
              v-if="row.diffAmounts"
              class="mdi mdi-information mr-2 text-danger"
              data-tooltip="Amounts differ from prescription"
            ></i>
            {{ row.date }}
          </td>
          <td>{{ row.compliance }}</td>
          <td class="text-center">
            <a
              href="javascript:void(0);"
              class="mr-2 resend-invite-letter ${isConfirmedClass}"
              data-id="${row[0]}"
            >
              <i
                class="far fa-eye text-info font-16"
                title="View details"
                @click="viewDetails(row)"
              ></i>
            </a>
          </td>
        </tr>
      </tbody>

      <tbody v-else>
        <tr>
          <td colspan="1000" class="text-muted text-center">No entries</td>
        </tr>
      </tbody>
    </table>

    <MedicationDoseDateDialog ref="medicationDoseDateDialog" />
  </div>
</template>

<script>
import moment from 'moment';
import MedicationDoseDateDialog from './MedicationDoseDateDialog';

export default {
  computed: {
    templateDoses() {
      return this.groups['a day'] ?? {};
    },

    dates() {
      if (Object.keys(this.medicationDoses).length === 0) return [];
      const firstDate = Object.keys(this.medicationDoses).sort()[0];

      function getShiftedDay(shift) {
        return moment()
          .startOf('day')
          .subtract(shift, 'days');
      }
      const result = [];
      for (let shift = 0; shift < 31; shift += 1) {
        const date = getShiftedDay(shift);
        const key = date.format('YYYY-MM-DD');
        result.push(key);
        if (key === firstDate) {
          break;
        }
      }
      return result;
    },

    rows() {
      const result = [];
      this.dates.forEach((date) => {
        const entry = {
          date,
          compliance: 0,
          doses: this.medicationDoses[date] ?? [],
          taken: [],
          notTaken: [],
          notSubmitted: [],
          diffAmounts: false,
        };
        result.push(entry);
        for (let i = 1; i <= 8; i += 1) {
          // eslint-disable-next-line no-continue
          if (!this.templateDoses[i]) continue;
          const thisDoseMedications = entry.doses.filter(dose => dose.dose === i);

          const notSubmitted = this.templateDoses[i].filter((templateDose) => {
            return !thisDoseMedications.find((thisDoseMedication) => {
              return thisDoseMedication.name === templateDose.name;
            });
          });

          const taken = this.templateDoses[i]
            .map((templateDose) => {
              const takenEntry = thisDoseMedications.find((thisDoseMedication) => {
                return (
                  thisDoseMedication.name === templateDose.name && !thisDoseMedication.not_taken
                );
              });
              if (!takenEntry) return undefined;
              const r = {
                ...templateDose,
                entry: takenEntry,
                diffAmounts:
                  takenEntry.times_a_term !== templateDose.times_a_term ||
                  takenEntry.amount !== templateDose.amount,
              };
              if (r.diffAmounts) {
                entry.diffAmounts = r.diffAmounts;
              }
              return r;
            })
            .filter(takenEntry => !!takenEntry);

          const notTaken = this.templateDoses[i].filter((templateDose) => {
            return !!thisDoseMedications.find((thisDoseMedication) => {
              return thisDoseMedication.name === templateDose.name && thisDoseMedication.not_take;
            });
          });
          entry.taken = [
            ...entry.taken,
            ...taken.map(usage => ({ ...usage, dose: i, status: 'Taken' })),
          ];
          entry.notTaken = [
            ...entry.notTaken,
            ...notTaken.map(usage => ({ ...usage, dose: i, status: 'Not token' })),
          ];
          entry.notSubmitted = [
            ...entry.notSubmitted,
            ...notSubmitted.map(usage => ({
              ...usage,
              dose: i,
              status: 'Not submitted',
            })),
          ];
        }

        const total = entry.taken.length + entry.notTaken.length + entry.notSubmitted.length;
        if (total) {
          entry.compliance = ((100 * entry.taken.length) / total).toFixed(0);
        }
      });
      return result;
    },
  },

  methods: {
    viewDetails(row) {
      this.$refs.medicationDoseDateDialog.show(row);
    },
  },

  mounted() {
    $(this.$refs.table).DataTable({
      searching: false,
      scrollY: 100,
    });
  },

  props: ['groups', 'medicationDoses'],

  components: {
    MedicationDoseDateDialog,
  },
};
</script>

<template>
  <div class="wrapper">
    <button
      type="button"
      class="btn btn-info"
      @click="previousDate()"
    >&lt;</button>

    <span class="mx-3 dateDisplay">{{ curDate.format(format) }}</span>

    <button
      type="button"
      class="btn btn-info"
      @click="nextDay()"
    >&gt;</button>
  </div>
</template>

<style lang="stylus" scoped>
.wrapper
  display inline-block
</style>

<style lang="stylus" scoped>
.dateDisplay
  display inline-block
  width 10em
</style>

<script>
import moment from 'moment-timezone';


export default {
  data() {
    return {
      curDate: !this.startDate
        ? moment()
        : moment(this.startDate),
    };
  },

  methods: {

    nextDay() {
      this.curDate = this.curDate.add(this.step);
      this.$emit('dateTimeChanged', this.curDate);
      this.$forceUpdate();
    },

    previousDate() {
      this.curDate.subtract(this.step);
      this.$emit('dateTimeChanged', this.curDate);
      this.$forceUpdate();
    },
  },

  props: {
    step: {
      type: Object,
      default: () => {
        return {
          days: 1,
        };
      },
    },
    format: {
      type: String,
      default: 'LL',
    },
    startDate: {
      type: Object,
    },
  },
};
</script>

<template>
  <div
    class="text-wrap-pretty"
    ref="textContainer"
    :class="{ 'single-line': isSingleLine, 'truncate-text': !isSingleLine }"
  >
    {{ text }}
  </div>
</template>

<script>
export default {
  props: {
    text: String,
  },
  data() {
    return {
      isSingleLine: true,
    };
  },
  mounted() {
    this.checkLines();
  },
  methods: {
    checkLines() {
      const lineHeight = parseFloat(getComputedStyle(this.$refs.textContainer).lineHeight);
      const containerHeight = this.$refs.textContainer.clientHeight;
      this.isSingleLine = containerHeight <= lineHeight;
    },
  },
};
</script>

<template>
  <div class="h-last-left justify-content-between d-flex w-100 h-j-bet flex-wrap">
    <div v-for="category in categories" :key="category.name">
      <div
        class="h-pin-btn"
        :class="{
          'h-pin-btn-accent': isCategoryOn(category.name)
        }"
        @click="toggleCategory(category.name)"
      >
        <img class="pin-img" :src="category.url" alt="" /><span>{{ category.name }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      selectedCategories: JSON.parse(JSON.stringify(this.value || [])),
      categories: [
        {
          url:
            'https://res.cloudinary.com/kitchry/image/upload/v1659116932/Pain%20Score%20Body%20Parts/Neck-min.png',
          name: 'Neck',
        },
        {
          url:
            'https://res.cloudinary.com/kitchry/image/upload/v1659116930/Pain%20Score%20Body%20Parts/Shoulder-min.png',
          name: 'Shoulder',
        },
        {
          url:
            'https://res.cloudinary.com/kitchry/image/upload/v1659116930/Pain%20Score%20Body%20Parts/HIPPELVIC-min.png',
          name: 'Hip/Pelvic',
        },
        {
          url:
            'https://res.cloudinary.com/kitchry/image/upload/v1659116929/Pain%20Score%20Body%20Parts/HANDWRIST-min.png',
          name: 'Hand/Wrist',
        },
        {
          url:
            'https://res.cloudinary.com/kitchry/image/upload/v1664478911/Pain%20Score%20Body%20Parts/Arms.png',
          name: 'Elbow/Arm',
        },
        {
          url:
            'https://res.cloudinary.com/kitchry/image/upload/v1659116931/Pain%20Score%20Body%20Parts/UPPERMID_BACK-min.png',
          name: 'Upper/Mid back',
        },
        {
          url:
            'https://res.cloudinary.com/kitchry/image/upload/v1659116929/Pain%20Score%20Body%20Parts/GENERALBACK-min.png',
          name: 'General Back',
        },
        {
          url:
            'https://res.cloudinary.com/kitchry/image/upload/v1659116929/Pain%20Score%20Body%20Parts/FOOTANKLE-min.png',
          name: 'Foot/Ankle',
        },
        {
          url:
            'https://res.cloudinary.com/kitchry/image/upload/v1659116931/Pain%20Score%20Body%20Parts/LOWBACKBUTTOCKS-min.png',
          name: 'Low Back/Buttocks',
        },
        {
          url:
            'https://res.cloudinary.com/kitchry/image/upload/v1659116929/Pain%20Score%20Body%20Parts/KNEELEG-min.png',
          name: 'Knee/Leg',
        },
      ],
    };
  },

  watch: {
    selectedCategories(value) {
      this.$emit(
        'input',
        value.map(category => category),
      );
    },
  },

  methods: {
    isCategoryOn(categoryName) {
      return this.selectedCategories.find(category => category === categoryName);
    },

    toggleCategory(categoryName) {
      if (this.isCategoryOn(categoryName)) {
        this.selectedCategories = this.selectedCategories.filter(item => item !== categoryName);
      } else {
        const foundCategory = this.categories.find(cat => cat.name === categoryName);
        this.selectedCategories = [...this.selectedCategories, foundCategory.name];
      }
    },
  },

  props: {
    value: {
      type: Array,
      required: true,
    },
  },
};
</script>

<template>
  <div class="h-margin-l h-padding-top-l h-padding-bottom-l h-bg-w h-full-card">
    <SearchRecipeContainer />
  </div>
</template>


<script>
import SearchRecipeContainer from './components/SearchRecipeContainer';

export default {
  data() {
    return {
      filters: {},
    };
  },

  mounted() {
    window.execAfterInitMenuInterface(() => {
      window.initActiveMenu('left-navigation-link>settings>recipe-page', false);
    });
  },

  components: {
    SearchRecipeContainer,
  },
};
</script>

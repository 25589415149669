<template>
  <div class="h-padding-top-l h-padding-left-l h-padding-right-l">
    <div class="h-bg-w h-padding-top-l h-margin-bottom-l">
      <div class="h-card-body">
        <div class="h-row">
          <div class="col-lg-12 mt-2">
            <div class="table-responsive h1-table">
              <table id="Physical-Therapy" class="table fullTable" ref="table">
                <thead class="thead-light">
                  <tr>
                    <th style="width: 5%" class="AllPatients-tooltop h-text-left">
                      <span class="table-title-bold">Id</span>
                    </th>
                    <th style="width: 5%" class="AllPatients-tooltop">
                      <span class="table-title-bold">Title</span>
                    </th>
                    <th style="width: 5%" class="AllPatients-tooltop">
                      <span class="table-title-bold">SDA</span>
                    </th>
                    <th style="width: 5%" class="AllPatients-tooltop">
                      <span class="table-title-bold">EDA</span>
                    </th>
                    <th style="width: 5%" class="AllPatients-tooltop">
                      <span class="table-title-bold">Category</span>
                    </th>
                    <th style="width: 5%" class="AllPatients-tooltop">
                      <span class="table-title-bold">Sets</span>
                    </th>
                    <th style="width: 5%" class="AllPatients-tooltop">
                      <span class="table-title-bold">Hold</span>
                    </th>
                    <th style="width: 5%" class="AllPatients-tooltop">
                      <span class="table-title-bold">Reps</span>
                    </th>
                    <th style="width: 5%" class="AllPatients-tooltop">
                      <span class="table-title-bold">Side</span>
                    </th>
                    <th style="width: 5%" class="AllPatients-tooltop">
                      <span class="table-title-bold">Times Per Day</span>
                    </th>
                    <th style="width: 5%" class="AllPatients-tooltop">
                      <span class="table-title-bold">Every X Days</span>
                    </th>
                    <th style="width: 5%" class="AllPatients-tooltop">
                      <span class="table-title-bold">Description</span>
                    </th>
                    <th style="width: 5%" class="text-right AllPatients-tooltop">
                      <span class="table-title-bold">Action</span>
                    </th>
                  </tr>
                  <!--end tr-->
                </thead>
              </table>
            </div>
          </div>
        </div>
      </div>
      <!--end card-body-->
    </div>
    <!--end card-->

    <SelectorDialogInDrawer
      ref="toggleColumnsDialog"
      class="h-hide-id"
      :allOptions="allTableColumns"
      :selectedOptions="visibleTableColumns"
      :getOptionKey="
        option => {
          return option.name;
        }
      "
      :getOptionText="
        option => {
          return option.text;
        }
      "
      windowTitle="Toggle table columns"
      listTitle="Table table columns to show"
      @toggleOption="toggleColumnVisible"
    />
  </div>
</template>

<style scoped lang="stylus"></style>

<script>
import settings from '@/settings.js';
import SelectorDialogInDrawer from '@/components/dialogs/SelectorDialogInDrawer';
import eventBus from '../../../event-bus';
import eventKeeper from '../../../eventKeeper';

const TABLE_COLUMNS_KEY = 'PT_columnKeys';

export default {
  data() {
    return {
      dataTable: null,
      allTableColumns: [],
      visibleTableColumns: [],
    };
  },

  computed: {
    isAdmin() {
      return settings.hasRole('system_administrator');
    },
  },

  methods: {
    handleClick(newTab) {
      this.currentTab = newTab;
    },

    getStatusBadgeClass(data) {
      return (
        {
          'Low Risk': 'success',
          'Medium Risk': 'warning',
          'High Risk': 'danger',
        }[this.getStatusText(data)] || 'info'
      );
    },

    getStatusText(data) {
      if (data < 6) {
        return 'High Risk';
      }
      if (data > 9) {
        return 'Low Risk';
      }
      return 'Medium Risk';
    },

    toggleColumnVisible(column, visible) {
      if (visible) {
        this.visibleTableColumns.push(column);
      } else {
        const index = this.visibleTableColumns.indexOf(column);
        this.visibleTableColumns.splice(index, 1);
      }

      localStorage.setItem(TABLE_COLUMNS_KEY, JSON.stringify(this.visibleTableColumns));

      const dtColumn = this.dataTable.column(this.allTableColumns.indexOf(column));
      dtColumn.visible(!dtColumn.visible());
    },
  },

  async mounted() {
    window.execAfterInitMenuInterface(() => {
      window.initActiveMenu('left-navigation-link>hospital>patients>Physical Therapy');
    });

    const that = this;

    const allColumns = [
      {
        name: 'id',
        searchable: true,
        render: data => (data ? `#${that.$strUtils.escapeHtml(data)}` : null),
        className: 'text-nowrap',
      },
      {
        name: 'title',
        searchable: true,
        render: (data, type, row, meta) => {
          const path = this.$router.resolve({
            name: 'EditPhysicalTherapy',
            params: {
              id: row[0],
            },
          });
          return `
            <a href="${path.href}" class="router-link">
              ${that.$strUtils.escapeHtml(data)}
            </a>
          `;
        },
        className: 'text-nowrap',
      },
      {
        name: 'start_days_after',
        searchable: false,
        render: data => (data ? that.$strUtils.escapeHtml(data) : null),
        className: 'text-nowrap',
      },
      {
        name: 'end_days_after',
        searchable: false,
        render: data => (data ? that.$strUtils.escapeHtml(data) : null),
        className: 'text-nowrap',
      },
      {
        name: 'category',
        searchable: true,
        orderable: true,
      },
      {
        name: 'sets',
        searchable: false,
        render: data => (data ? that.$strUtils.escapeHtml(data) : null),
        className: 'text-nowrap',
      },
      {
        name: 'hold',
        searchable: false,
        render: data => (data ? that.$strUtils.escapeHtml(data) : null),
        className: 'text-nowrap',
      },
      {
        name: 'repetition',
        searchable: false,
        render: data => (data ? that.$strUtils.escapeHtml(data) : null),
        className: 'text-nowrap',
      },
      {
        name: 'side',
        searchable: false,
        render: data => (data ? that.$strUtils.escapeHtml(data) : null),
        className: 'text-nowrap',
      },
      {
        name: 'times_per_day',
        searchable: false,
        render: data => (data ? that.$strUtils.escapeHtml(data) : null),
        className: 'text-nowrap',
      },
      {
        name: 'every_x_days',
        searchable: false,
        render: data => (data ? that.$strUtils.escapeHtml(data) : null),
        className: 'text-nowrap',
      },
      {
        name: 'description',
        searchable: true,
        render: data => (data ? `#${that.$strUtils.escapeHtml(data)}` : null),
        className: 'text-nowrap',
      },
      {
        name: 'action',
        searchable: false,
        orderable: false,
        render: (data, type, row, meta) => {
          const rowIndex = meta.settings.json.data.indexOf(row);
          // console.log(`Row index: ${rowIndex}
          // ${JSON.stringify(meta.settings.json.metadata[rowIndex])} `);
          const path = this.$router.resolve({
            name: 'EditPhysicalTherapy',
            params: {
              id: meta.settings.json.metadata[rowIndex]
                ? meta.settings.json.metadata[rowIndex].id
                : 0,
            },
          });
          const isConfirmedClass =
            meta.settings.json.metadata[rowIndex] &&
            meta.settings.json.metadata[rowIndex].is_confirmed
              ? 'd-none'
              : '';

          return `
            <div class="text-right">
              <a
                href="javascript:void(0);"
                class="mr-2 resend-invite-letter ${isConfirmedClass}"
                data-id="${row[0]}"
              >
                <i class="fas fa-envelope-open-text text-info font-16"
                title="Resend Welcome SMS"></i>
              </a>
              <a href="${path.href}" class='mr-2 router-link'>
                <i class='fas fa-edit text-info font-16' title='Edit'></i>
              </a>
            </div>
          `;
        },
      },
    ];

    this.dataTable = $('#Physical-Therapy').DataTable({
      // orderMulti: false,
      processing: true,
      serverSide: true,
      lengthMenu: [5, 10, 20, 50, 100, 200, 500, 1000],
      pageLength: 25,
      searching: true,
      stateSave: true,
      stateSaveParams(s, data) {
        data.search.search = undefined;
      },

      order: [[0, 'desc']],
      searchDelay: 1000,
      ajax: (data, callback, tableSettings) => {
        this.$api.getTherapyPtTable({ ...data }).then((response) => {
          console.log('=====PT table data=====>', response);
          // response.therapy_pt_options.forEach((entry) => {
          //  console.log(`Entry in table: ${JSON.stringify(entry)}`);
          // });
          tableSettings.json = response;
          callback(response);
        });
      },
      dom:
        '<"h-row h-space-between h-align-item-center"<"h-show-bt h-margin-left-l header-title"><"h-d-flex h-table-bt"fB>>' +
        '<""<""tr>><"h-row h-space-between h-align-item-center h-padding-bottom-l"lip>',
      buttons: {
        buttons: [
          {
            text: 'Add new PT',
            action(e, dt, node, config) {
              that.$router.push({ name: 'AddPhysicalTherapy' });
            },
            className: 'h-btn h-btn-accent addnewpatioint',
          },
          {
            extend: 'collection',
            className: 'ExportIcon',
            buttons: ['copy', 'excel', 'csv', 'print'],
          },
          {
            text: 'Change columns',
            action(e, dt, node, config) {
              that.$refs.toggleColumnsDialog.show();
            },
            className: 'h-btn-icon',
          },
        ],
        dom: {
          button: {
            className: 'h-btn',
          },
        },
      },
      columns: allColumns.filter((column) => {
        if (this.isAdmin) return true;
        return !column.__adminOnly;
      }),
    });

    $('div.header-title').html("<h3 class='h-h3 h-primary_shade_1'>Physical therapy</h3>");
    eventKeeper.$on(this, 'click', document, '.FilterItem', () => {
      if ($(this).hasClass('active')) {
        $(this).removeClass('active');
      } else {
        $('.FilterItem').removeClass('active');
        $(this).addClass('active');
      }
    });
    this.allTableColumns = this.dataTable.context[0].aoColumns.map((aoColumn, index) => ({
      text: $(this.dataTable.column(index).header()).text(),
      name: aoColumn.name,
    }));
    this.visibleTableColumns = JSON.parse(localStorage.getItem(TABLE_COLUMNS_KEY) || '[]');
    if (this.visibleTableColumns.length === 0) {
      this.visibleTableColumns = this.allTableColumns.slice();
    } else {
      this.visibleTableColumns = this.allTableColumns.filter((exists) => {
        const keep = this.visibleTableColumns.find(item => item.name === exists.name);
        if (!keep) {
          const dtColumn = this.dataTable.column(this.allTableColumns.indexOf(exists));
          dtColumn.visible(false);
        }
        return keep;
      });
    }

    $(this.$refs.table).on('click', '.resend-invite-letter', (e) => {
      const id = $(e.target)
        .closest('a')
        .attr('data-id');
      const data = this.dataTable.data().toArray();
      const row = data.find((entry) => {
        return entry[0] === parseInt(id, 10);
      });
      const headers = $(this.$refs.table)
        .find('th')
        .map((index, el) => $(el)
          .text()
          .trim())
        .toArray();
      const resultData = {};
      headers.forEach((header, index) => {
        resultData[header] = row[index];
      });
      resultData.id = parseInt(id, 10);
      that.$refs.ResendActivationCodeDialog.show(resultData);
    });

    $(this.$refs.table).on('click', '.show-dataTable-value', (e) => {
      const value = $(e.target)
        .closest('a')
        .attr('data-value');
      this.$refs.simpleMessage.show('Message', value);
    });

    $('.AllPatients-tooltop').tooltip();
  },

  beforeDestroy() {
    eventBus.$offOwner(this);
    eventKeeper.$offOwner(this);
    if (this.dataTable) {
      this.dataTable.destroy(true);
      this.dataTable = null;
    }
  },

  components: {
    SelectorDialogInDrawer,
  },
};
</script>
